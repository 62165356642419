
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import DemoServerProcessJob from './DemoServerProcessJob'


/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/DemoServerProcessQueue')


/* =========================================
      COMPONENTS
-------------------------------------- */

const DemoServerProcessQueue = (props) => {
    const queue = props.queue

    const queueName = props.queue.name
    const queueJobs = props.queue.jobs || []

    // REFACTOR: move to parent container
    // const id = `DemoServerProcessQueue-${queueName}`

    const id = props.id
    const className = props.className

    const hidden = !!props.hidden

    return (
        <div className={classnames('DemoServerProcessQueue', className, {hidden})}
            id={id}

            data-queue-name={queueName}
            data-queue-jobs-count={queueJobs.length}
        >
            <div className={classnames('DemoServerProcessQueue-header')}>
                <div className={classnames('DemoServerProcessQueue-header-title')}>
                    { queueName } videos
                </div>
            </div>

            <div className={classnames('DemoServerProcessQueue-content')}>
                {
                    queueJobs.map((job) => {
                        const jobID = job.id || encodeURIComponent(job.uri || `${job}`)

                        const key = `DemoServerProcessQueue-${queueName}-DemoServerProcessJob-${jobID}`

                        return (
                            <DemoServerProcessJob key={key} queue={queue} job={job} />
                        )
                    })
                }
            </div>
        </div>
    )
}

DemoServerProcessQueue.propTypes = {
    queue: PropTypes.object,
    hidden: PropTypes.bool,
}


DemoServerProcessQueue.defaultProps = {
    // queue: undefined,
    hidden: false,
}


/* =========================================
      EXPORT
-------------------------------------- */

export default DemoServerProcessQueue
