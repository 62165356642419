
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import shallowEqual from 'react-fast-compare'


/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/DemoPlayer/LensVideoFrameTrackingTimeline')


/* =========================================
      CONSTANTS
-------------------------------------- */

const DEFAULT_TRACK_MIN = 0
const DEFAULT_TRACK_MAX = 30


/* =========================================
      COMPONENTS
-------------------------------------- */

class LensVideoFrameTrackingTimeline extends Component {

    // shouldComponentUpdate (nextProps) {
    //     const { props } = this

    //     if (shallowEqual(props, nextProps)) {
    //         return false
    //     }

    //     return true
    // }

    render () {
        const { props } = this

        let {
            video,

            seek,
            track,
        } = props

        let {
            currentTime,
            duration,
        } = video || {}

        currentTime = currentTime || 0
        duration = duration || 0

        seek = seek || 0
        track = track || [0, 30]

        if (seek < 0) {
            seek = 0
        }

        if (seek > duration) {
            seek = duration
        }

        const [trackPast, trackFuture] = track

        let trackFromTime = seek + ((typeof trackPast === 'number') ? trackPast : DEFAULT_TRACK_MIN)
        let trackToTime = seek + ((typeof trackFuture === 'number') ? trackFuture : DEFAULT_TRACK_MAX)

        if (trackFromTime < 0) {
            trackFromTime = 0
        }

        if (trackToTime > duration) {
            trackToTime = duration
        }

        const trackDuration = trackToTime - trackFromTime

        return (
            <div className={classnames('LensVideoFrameTrackingTimeline', {})}

                data-seek={seek}
                data-track={`[${track[0]}, ${track[1]}]`}

                data-track-from-time={trackFromTime}
                data-track-to-time={trackToTime}
            >
                <div className={classnames('LensVideoFrameTrackingTimeline-header')}></div>

                <div className={classnames('LensVideoFrameTrackingTimeline-content')}>
                    <div className={classnames('LensVideoFrameTrackingTimeline-bar')} style={{}}>
                        <div className={classnames('LensVideoFrameTrackingTimeline-bar-current')}
                            style={{
                                left: `${currentTime / duration * 100}%`,
                            }}
                            data-label={`${parseFloat(currentTime).toFixed(2)}`}
                        />

                        <div className={classnames('LensVideoFrameTrackingTimeline-bar-track')}
                            style={{
                                left: `${trackFromTime / duration * 100}%`,
                                width: `${trackDuration / duration * 100}%`,
                            }}
                        >
                            <div className={classnames('LensVideoFrameTrackingTimeline-bar-seek')}
                                style={{
                                    left: `${(seek - trackFromTime) / trackDuration * 100}%`,
                                }}
                                data-label={`moment = ${parseFloat(seek).toFixed(2)}`}
                            />
                        </div>
                    </div>
                </div>

                <div className={classnames('LensVideoFrameTicker-footer')}></div>
            </div>
        )
    }

}


/* =========================================
      EXPORT
-------------------------------------- */

export default LensVideoFrameTrackingTimeline
