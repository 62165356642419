
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { API_HEALTH } from '../../../constants'


/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/system/BackendStatus')


/* =========================================
      COMPONENTS
-------------------------------------- */

class BackendStatus extends PureComponent {

    state = {
        backendAvailable: true,
    }

    get status () {
        return !!this.state.backendAvailable
    }

    componentDidMount () {
        const { props } = this
        console.log("props", props)
        const { disabled, interval } = props

        this.interval = setInterval(() => {
            if (!disabled) {
                this.onCheckBackendStatus()
            }
        }, interval || 3000)
    }

    componentWillUnmount () {
        clearInterval(this.interval)
    }

    render () {
        const { props } = this

        const {
            uri,

            id,
            className,
            style,

            hidden,

            children,
        } = props

        const {
            backendAvailable,
        } = this.state

        let {
            label,
            value,
        } = props

        label = (label === true) ? 'Backend' : label
        value = (value === true) ? `${this.status}` : value

        return (
            <div id={id}
                className={classnames('BackendStatus', 'Status', className, {hidden})}
                style={style}

                data-uri={uri}
                data-status={backendAvailable}

                title={`Available: ${this.status ? 'yes' : 'no'} (URI: \`${uri}\`)`}
            >
                <div className={classnames('Status-content')}>
                    <div className={classnames('Status-label')}>
                        { children || label || `${this.status}` }
                    </div>

                    <div className={classnames('Status-value')}>
                        { value }
                    </div>
                </div>
            </div>
        )
    }

    onCheckBackendStatus = async () => {
        // log('onCheckBackendStatus')

        const { props } = this
        const { uri } = props

        let backendAvailable = true

        if (backendAvailable) {
            try {
                // if (uri) {
                //     try {
                //         let health = await fetch(API_HEALTH, {
                //             headers: new Headers({
                //                 'Authorization': props.accessToken && `Bearer ${props.accessToken}`
                //             }),
                //             mode: 'no-cors',
                //         })  
                //         console.log("health",health)                     
                //     } catch (error) {
                //         console.log("error")
                //     }

                // }
                let health = await fetch(API_HEALTH, {
                    mode: 'no-cors',
                })  

            } catch (err) {
                backendAvailable = false
            }

        } else {
            backendAvailable = false
        }

        // if (!backendAvailable) {
        //     console.warn('BACKEND NOT AVAILABLE')
        // }

        this.setState({
            backendAvailable,
        })

        if (typeof props.onStatusChange === 'function') {
            if (backendAvailable !== this.state.backendAvailable) {
                props.onStatusChange(backendAvailable)
            }
        }
    }

}

BackendStatus.propTypes = {
    uri: PropTypes.string,
    interval: PropTypes.number,

    id: PropTypes.string,
    className: PropTypes.string,
    styles: PropTypes.object,

    label: PropTypes.any,
    value: PropTypes.any,

    hidden: PropTypes.bool,

    onStatusChange: PropTypes.func,
}

BackendStatus.defaultProps = {
    uri: undefined,
    interval: undefined,

    id: undefined,
    className: undefined,
    styles: undefined,

    label: true,
    value: false,

    hidden: true,

    onStatusChange: undefined,
}


/* =========================================
      EXPORT
-------------------------------------- */

export default BackendStatus
