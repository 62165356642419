
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import shallowEqual from 'react-fast-compare'
import ScrollArea from 'react-scrollbar'

import {
    CATEGORY_SCORE_THRESHOLD, TRANSPARENT_PIXEL
} from '../../constants'


/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/DemoResults')


/* =========================================
      COMPONENTS
-------------------------------------- */

class DemoResultsByCategory extends Component {

    shouldComponentUpdate (nextProps) {
        const { props } = this

        if (shallowEqual(props, nextProps)) {
            return false
        }

        return true
    }

    render () {
        const { props } = this

        const id = props.id
        const className = props.className
        const style = props.style
        const disabled = props.disabled
        const colors = props.colors || []
        const results = props.results || []
        const categoryScoreThresholds = props.categoryScoreThresholds || {}
        const hidden = Boolean(props.hidden)

        const selectedTrack = props.currentTrack || {}
        const selectedTrackId = selectedTrack.id

        const index = selectedTrack.index
        const color = selectedTrack.color || colors[index]
        const category = selectedTrack.category || 'unknown'
        const categoryThreshold = categoryScoreThresholds[category] || CATEGORY_SCORE_THRESHOLD || 1

        const selectedResults = results.filter((result) => {
            // V3
            return result.matches && result.matches.length && Boolean(result.matches[0]._id === selectedTrackId)
        })

        const matches = selectedResults.map(result => result.matches).reduce((accumulator, value) => value, []) || []
        const hasMatches = Boolean(matches.length > 0)

        return (
            <div className={classnames('DemoResultsByCategory', className, {hidden, disabled})} id={id} style={style} >
                <div className={classnames('DemoResults-content')}>
                    <div className={classnames('DemoResults-empty', {hidden: hasMatches})}>
                        <span className={classnames('DemoResults-empty-text')}>
                            No detections in this frame for {category} track
                        </span>
                    </div>

                    <div className={classnames('DemoResults-results')}>
                        {
                            <div className={classnames('DemoResults-result', {hidden: !hasMatches})} style={{borderColor: color}} result={selectedResults}>
                                <ScrollArea
                                    contentClassName="DemoResults-result-matches-scroll"
                                    verticalScrollbarStyle={{
                                        borderRadius: 0,
                                        opacity: 0,
                                    }}
                                    verticalContainerStyle={{
                                        borderRadius: 0,
                                        opacity: 0,
                                    }}
                                    horizontalScrollbarStyle={{
                                        borderRadius: 0,
                                        opacity: 0,
                                    }}
                                    horizontalContainerStyle={{
                                        borderRadius: 0,
                                        opacity: 0,
                                    }}
                                    minScrollSize={0}
                                    smoothScrolling={true}
                                >
                                    {
                                        matches.map((match) => {
                                            const selected = (match.score >= categoryThreshold)

                                            return {...match, selected}
                                        })
                                        .map((match, j) => {
                                            match.images = [
                                                ...(match.images || []),
                                                match.image
                                            ].filter(Boolean)

                                            match.data = match.data || {}

                                            const image = match.images[0] || {}
                                            const key = `DemoResults-result-${selectedTrack._id}-match-${j}`

                                            return (
                                                <div key={key}
                                                    id={undefined}
                                                    className={classnames('DemoResults-result-match', {
                                                        rejected: !match.selected,
                                                    })}

                                                    match={match}

                                                    data-score={match.score}
                                                    data-score-threshold={categoryThreshold}
                                                >
                                                    <img className={classnames('DemoResults-result-match-image')}
                                                        src={image.uri}
                                                        alt={''}

                                                        onError={(err) => {
                                                            try {
                                                                this.src = TRANSPARENT_PIXEL
                                                            } catch (err) {}
                                                        }}

                                                        data-src={image.uri}
                                                    />
                                                </div>
                                            )
                                        })
                                    }

                                </ScrollArea>

                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

DemoResultsByCategory.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,

    colors: PropTypes.array,

    frame: PropTypes.object,
    tracks: PropTypes.array,
    results: PropTypes.array,

    categoryScoreThresholds: PropTypes.object,
    categoryScoreThresholdsView: PropTypes.object,

    resultSelection: PropTypes.object,
    // resultMatchSelection: PropTypes.object,

    showAccuracyScore: PropTypes.bool,

    onResultActive: PropTypes.func,
    onResultInactive: PropTypes.func,

    onResultMatchActive: PropTypes.func,
    onResultMatchInactive: PropTypes.func,
    onResultMatchClick: PropTypes.func,

    onResultMatchPreview: PropTypes.func,

    onSearchThresholdToggle: PropTypes.func,
    onSearchThresholdChange: PropTypes.func,
}

DemoResultsByCategory.defaultProps = {
    id: undefined,
    className: undefined,
    style: undefined,
    disabled: undefined,
    hidden: undefined,

    colors: [],

    frame: undefined,
    tracks: [],
    results: [],

    categoryScoreThresholds: {},
    categoryScoreThresholdsView: {},

    resultSelection: undefined,

    showAccuracyScore: false,
}


/* =========================================
      EXPORT
-------------------------------------- */

export default DemoResultsByCategory
