
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { Component } from 'react'
import classnames from 'classnames'
import queryString from 'query-string'

import Favicon from 'react-favicon'

import {
    isUri,
} from '../utils/uri'

import {
    findMatchingVideoFrames,
} from '../utils/video'

import LensVideo from './components/DemoPlayer/LensVideo'
import LensVideoControls from './components/DemoPlayer/LensVideoControls'
import LensVideoFrameTrackingTimeline from './components/DemoPlayer/LensVideoFrameTrackingTimeline'
import LensVideoFrameTicker from './components/DemoPlayer/LensVideoFrameTicker'
import LensVideoOverlay from './components/DemoPlayer/LensVideoOverlay'
import LensVideoOverlayFrameImage from './components/DemoPlayer/LensVideoOverlayFrameImage'

import DemoAlertOverlay from './components/DemoAlertOverlay'
import DemoInputField from './components/DemoInputField'
import DemoServerProcessManager from './components/DemoServerProcessManager'
import DemoResults from './components/DemoResults'
import DemoTopMatches from './components/DemoTopMatches'
import DemoFrameSnapshotsPreviewer from './components/DemoFrameSnapshotsPreviewer'
import DemoVideoStatus from './components/DemoVideoStatus'
import DemoResultsByCategory from './components/DemoResultsByCategory.jsx'
import DemoAttributesModal from './components/DemoAttributesModal.jsx'
// import DemoPlayers from './components/DemoPlayers'
// import DemoPlayer from './components/DemoPlayer'

import DemoStatus from './components/DemoStatus'

import ObjectInspector from './components/ObjectInspector'

import {
    videoCV,
    videoLens,
} from '../services'

import {
    API_ENDPOINT,
    // API_ENDPOINTS,

    API_VIDEO_PIPELINE_STEPS,
    API_VIDEO_PIPELINE_STEPS_BY_BACKEND,

    API_VIDEO_SERVICE_DEFAULT_TRACK_MAX,
    API_VIDEO_SERVICE_DEFAULT_TRACK_DEFAULT,

    API_VIDEO_MAX_TOP_TRACKS,

    CLOUDINARY_CLOUD_NAME,
    CLOUDINARY_UPLOAD_PRESET,

    COLORS,
} from '../constants'

import {
    DEMO_VIDEOS,
    TEST_VIDEOS,
    SAMPLE_VIDEO_SETS,
} from '../constants/samples'

import fixtures from '../state/fixtures'

import './App.css'

import logo from '../static/markable-octopus-black.svg'
import favicon from '../static/play-128.png'


/* =========================================
      DEBUG
-------------------------------------- */

window.videoCV = videoCV
window.videoLens = videoLens


/* =========================================
      EXTENSIONS
-------------------------------------- */

// REFACTOR: use functional approach
String.prototype.toHHMMSS = function () {
    const seconds = parseInt(this, 10)

    return (new Date(seconds * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0]
}


/* =========================================
      CONSTANTS
-------------------------------------- */

const IS_LOCALHOST = window.location.host.includes('localhost')
const IS_DEV = IS_LOCALHOST || window.location.host.includes('-dev')

const STATS_COLORS = [...COLORS]
const SHUFFLED_COLORS = [...COLORS].sort(() => .5 - Math.random())

const SAMPLE_VIDEOS = [
    ...(DEMO_VIDEOS),
    ...(IS_DEV ? TEST_VIDEOS : []),
]

const SAMPLE_DATA = IS_LOCALHOST ? {
    // [`${location.origin}/static/videos/vip_1.mp4`]: fixtures['vip_1'],
    // [`https://www.youtube.com/watch?v=KB61lPDqfNE`]: fixtures['vip_1'],
    [`https://s3.amazonaws.com/data-operations-sallie-gardner/tmp/VIP_1.mp4`]: fixtures['vip_1'],

    // [`${location.origin}/static/videos/vip_2.mp4`]: fixtures['vip_2'],
    // [`https://www.youtube.com/watch?v=Ed6L5GugkcQ`]: fixtures['vip_2'],
    [`https://d3vv6lp55qjaqc.cloudfront.net/items/171R2R0x071W1B431r44/vip_2.mp4`]: fixtures['vip_2'],
} : {}

/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/App')


/* =========================================
      COMPONENTS
-------------------------------------- */

class App extends Component {

    video2 = null

    state = {
        sampleSet: null,
        sampleVideos: SAMPLE_VIDEOS,

        videoURI: SAMPLE_VIDEOS[0],

        videoData: undefined,
        videoError: undefined,

        videoFrames: undefined,

        videoSearchCatalogMode: 'default',
        videoSearchCatalogsByRegion: {},
        videoSearchCatalogs: [],

        videoSearchFPS: 3,
        videoSearchLimit: 15,
        videoSearchRelevance: 0.8,

        videoSearchFamilySearch: true,
        videoSearchDeduplication: true,
        videoSearchSpecificMatch: true,

        videoSearchSeek: 0,
        videoSearchTrackBegin: API_VIDEO_SERVICE_DEFAULT_TRACK_DEFAULT,
        videoSearchTrackEnd: 0,
        videoSearchPrecision: 2,

        showVideo: true,
        enableVideo: true,

        showInput: true,
        enableInput: true,

        // videoSize: {
        //     width: 0,
        //     height: 0,

        //     naturalWidth: 0,
        //     naturalHeight: 0,

        //     aspectRatio: 1.0,
        //     scaleRatio: 1.0,
        // },

        // REFACTOR/FIXME: use player directly?
        videoPlayerSize: {
            width: 0,
            height: 0,

            naturalWidth: 0,
            naturalHeight: 0,

            aspectRatio: 1.0,
            scaleRatio: 1.0,
        },

        videoSnapshotSize: {
            width: 0,
            height: 0,

            naturalWidth: 0,
            naturalHeight: 0,

            aspectRatio: 1.0,
            scaleRatio: 1.0,
        },

        topTracksPreviewPagination: {

        },
    }

    // setState (params) {
    //     if (this._isMount) {
    //         super.setState(params)
    //     }
    // }

    componentDidMount () {
        // this._isMount = true

        log('componentDidMount')

        const { location, match } = this.props
        const params = queryString.parse(location.search)

        log('ROUTE', {match, location, params})

        window.app = this
        window.fixtures = fixtures

        let state = {}

        state.params = params

        if (params.set) {
            state.sampleSet = params.set

            const sampleSetObject = SAMPLE_VIDEO_SETS.find((set) => {
                return Boolean(set.name === state.sampleSet)
            }) || {}

            state.sampleVideos = sampleSetObject.videos || SAMPLE_VIDEOS || []

            state.sampleVideos = state.sampleVideos
                .filter(Boolean)
                .map((sampleVideo) => {
                    let sampleVideoURI

                    if (typeof sampleVideo === 'string') {
                        sampleVideoURI = sampleVideo

                    } else {
                        sampleVideoURI = sampleVideo.uri
                    }

                    return sampleVideoURI
                })

            state.videoURI = state.sampleVideos[0]
        }

        if (params.uri) {
            state.videoURI = params.uri || state.videoURI
        }
        console.log("params", params)
        if (params.endpoint) {
            state.videoEndpoint = params.endpoint

        } else {
            if (params.backend) {
                state.videoEndpoint = params.endpoint || API_ENDPOINT
                // state.videoEndpoint = params.endpoint || API_ENDPOINTS[params.backend] || API_ENDPOINT
            } else {
                state.videoEndpoint = API_ENDPOINT
            }
        }

        if (params.backend) {
            state.videoSearchStatsKeys = API_VIDEO_PIPELINE_STEPS_BY_BACKEND[params.backend]

        } else {
            state.videoSearchStatsKeys = API_VIDEO_PIPELINE_STEPS
        }

        if (params.custom) {
            state.custom = true
        }

        state.pageLimit = params.pageLimit || 200
        state.accessToken = params.accessToken || null

        state.videoSearchCatalogsByRegion = {}
        state.videoSearchCatalogs = []

        window.API_ENDPOINT = API_ENDPOINT

        window.videoEndpoint = state.videoEndpoint

        this.setState(state, () => {
            // HACK/FIXME: player state not passed to "controls" - last minute fix
            this.stateInterval = setInterval(() => {
                if (this.lensVideo) {
                    this.setState({
                        playing: this.lensVideo.playing,
                        paused: this.lensVideo.paused,
                        stopped: this.lensVideo.stopped,
                    })
                }
            }, 100)
        })

        this.cloudinaryUploadWidget = window.cloudinary.createUploadWidget({
            cloudName: CLOUDINARY_CLOUD_NAME,
            uploadPreset: CLOUDINARY_UPLOAD_PRESET,
        }, (error, result) => {
            if (error) {
                return console.error(error)
            }

            this.onCloudinaryFileUploaded(result)
        })

        setImmediate(() => {
            this.onLoadCatalogs()
        })

        // ISSUE: `window.onerror` gets hijacked by React Hot Reload
        // window.onerrorOld = window.onerror
        // window.onerror = this.onError
    }

    componentWillUnmount () {
        // this._isMount = false

        clearInterval(this.stateInterval)

        // window.onerror = window.onerrorOld
    }

    componentDidCatch (error, info) {
        this.setState({
            error,
            info,
        })

        console.error(error)

        this.onError(error)
    }

    onError = (error) => {
        const errors = [
            error,
            ...(this.state.errors || []),
        ]

        this.setState({
            errors,
        })
    }

    onLoadCatalogs = async () => {
        const accessToken = this.state.accessToken || null

        try {
            const result = await videoLens.getPublicCatalogs({
                accessToken,
            })

            if (result) {
                const { body } = result.response || {}
                const { data } = body || {}

                const videoSearchCatalogsByRegion = {}

                videoSearchCatalogsByRegion['ALL'] = data || []

                this.setState({
                    videoSearchCatalogsByRegion,
                })
            }

        } catch (error) {
            console.error('onLoadCatalogs', error)
        }
    }

    get isVideoReady() {
        return !!this.state.videoData
    }

    get isVideoError() {
        return !!this.state.videoError && !!this.state.videoURI && !!this.state.videoURI.length
    }

    get isVideoInputVisible () {
        return !!this.state.showInput
    }

    get isVideoInputHidden () {
        return !this.isVideoInputVisible
    }

    get isVideoInputEnabled () {
        return !!this.state.enableInput
    }

    get isVideoInputDisabled () {
        return !this.isVideoInputEnabled
    }

    get isVideoInputReadOnly () {
        // return !!this.state.sampleSet
        return !this.state.custom
    }

    get isVideoPlayerVisible () {
        return !!this.state.showVideo && this.isVideoURIValid
    }

    get isVideoPlayerHidden () {
        return !this.isVideoPlayerVisible
    }

    get videoPlayerEnabled () {
        return true // !!this.state.videoData
    }

    get isVideoPlayerDisabled () {
        return !this.videoPlayerEnabled
    }

    get videoWidth () {
        // return this.lensPlayer && this.lensPlayer.width || 0
        return (this.state.videoPlayerSize || {}).width || 0
    }

    get videoHeight () {
        // return this.lensPlayer && this.lensPlayer.height || 0
        return (this.state.videoPlayerSize || {}).height || 0
    }

    get videoSize () {
        return [this.videoWidth, this.videoHeight]
    }

    get videoAspectRatio () {
        return this.videoWidth / parseFloat(this.videoHeight || 1)
    }

    // get videoAspectRatio () {
    //     return (this.state.videoSize || {}).aspectRatio || 1.0
    // }

    get videoScaleRatio () {
        // return (this.state.videoSnapshotSize || {}).width / ((this.lensVideo || {}).width || 1)
        return (this.state.videoSnapshotSize || {}).width / (this.videoWidth || 1)
    }

    get videoSnapshotWidth () {
        return (this.state.videoSnapshotSize || {}).width
    }

    get videoSnapshotHeight () {
        return (this.state.videoSnapshotSize || {}).height
    }

    get videoSnapshotNaturalWidth () {
        return (this.state.videoSnapshotSize || {}).naturalWidth
    }

    get videoSnapshotNaturalHeight () {
        return (this.state.videoSnapshotSize || {}).naturalHeight
    }

    get videoSnapshotAspectRatio () {
        return (this.state.videoSnapshotSize || {}).aspectRatio  || 1.0
    }

    get videoSnapshotScaleRatio () {
        return (this.state.videoSnapshotSize || {}).scaleRatio  || 1.0
    }

    get videoURI () {
        return this.state.videoURI
    }

    get isVideoURIValid () {
        return isUri(this.videoURI)
    }

    get isVideoURIInvalid () {
        return !this.isVideoURIValid
    }

    get isVideoSearchPending () {
        return this.state.videoSearchPending
    }

    get isVideoIndexingComplete () {
        const stats = this.state.videoSearchStats || {}

        return Object.entries(stats).every(([key, value]) => {
            return !!value['endedAt'] && value['completed'] >= value['total']
        })
    }

    get videoSearchStats () {
        return this.state.videoSearchStats || {}
    }

    get videoSearchResponse () {
        return this.state.videoSearchResponse || {}
    }

    get videoSearchResponseMeta () {
        return this.videoSearchResponse.meta || {}
    }

    get videoSearchResponseTime () {
        return this.state.videoSearchResponseTime || undefined
    }

    get videoSearchFrames () {
        return this.state.videoSearchResponse && this.state.videoSearchResponse.data || []
    }

    get videoFrames () {
        return this.state.videoFrames || []
    }

    get videoFrame () {
         return this.state.videoFrameHover || this.state.videoFrame || {}
    }

    get videoSearchResults () {
        return this.videoFrame && this.videoFrame.results || []
    }

    get videoSearchResultsAll () {
         return this.state.videoSearchResultsAll || []
    }

    get topTracks () {
        return this.state.topTracks || []
    }

    get tracks () {
        return this.topTracks || []
    }

    get frames () {
        return this.state.frames || []
    }

    get frame () {
        return this.state.videoFrame
    }

    get sampleVideos () {
        const sampleVideos = IS_LOCALHOST ? this.state.sampleVideos : this.state.sampleVideos.filter((videoURI) => {
            return videoURI && !videoURI.includes('locahost:')
        })

        return sampleVideos
    }

    get sampleSet () {
        return this.state.sampleSet
    }

    get jobQueues () {
        return {
            active: (this.videoSearchResponseMeta.jobs || {}).active,
            pending: (this.videoSearchResponseMeta.jobs || {}).pending,
        } || {
            // REFACTOR: consider pass mapped queue/job data structure from `App`
            active: [
                {
                    id: 0,
                    uri: this.sampleVideos[0],
                },

            ],
            pending: [
                {
                    id: 1,
                    uri: this.sampleVideos[1],
                },
                {
                    id: 2,
                    uri: this.sampleVideos[2],
                },
                {
                    id: 3,
                    uri: this.sampleVideos[3],
                },
            ],
        }
    }

    get hasJobs () {
        return Object.values(this.jobQueues).every((queue) => (queue || []).length)
    }

    onCloudinaryFileUpload = (event) => {
        this.cloudinaryUploadWidget.open()
    }

    onCloudinaryFileUploaded = (result) => {
        if (result && result.event === 'success'){
            this.state.sampleVideos.unshift(result.info.secure_url)

            this.onVideoURIChange({
                target: {
                    value: result.info.secure_url,
                },
            })
        }
    }

    render () {
        return (
            <div className={classnames('App', {})}>
                <Favicon url={favicon} />

                <header>

                    <img className={classnames('Logo', {})}
                        src={logo}
                        alt={`Markable.ai`}
                    />

                    <DemoStatus
                        hidden={false}
                        endpoint={this.state.videoEndpoint}
                        accessToken={this.state.accessToken}
                    />

                    <div className={classnames('InputContainer', {})}>
                        <DemoInputField
                            label={`▶︎`}
                            value={this.videoURI}
                            error={this.isVideoError}
                            hidden={this.isVideoInputHidden}
                            disabled={this.isVideoInputDisabled}
                            readonly={this.isVideoInputReadOnly}

                            onChange={this.onVideoURIChange}
                            onDrop={this.onVideoURIDrop}
                            onBrowse={this.onVideoURIBrowse}
                            onClear={this.onVideoURIClear}

                            showImport={false}

                            sample={{uri: this.videoURI}}
                            samples={this.sampleVideos}

                            upload={this.onCloudinaryFileUpload}
                        >
                        </DemoInputField>
                    </div>

                    <div className={classnames('OptionsContainer')}>

                        <div className={classnames('SetContainer', {hidden: !this.sampleSet})}>
                            <div className={classnames('SetContainer-label')}>
                                <span className={classnames('SetContainer-label-prefix')}>
                                    {
                                        `samples: `
                                    }
                                </span>

                                {
                                    `${this.sampleSet}`
                                }
                            </div>
                        </div>

                        <div className={classnames('DemoServiceActions')}>
                            <div className={classnames('DemoServiceActions-label', {})}>
                                Actions:
                            </div>

                            <div className={classnames('DemoServiceActions-action', {hidden: !this.state.custom})}
                                onClick={() => {
                                    const result = window.confirm('REINDEX: Re-run all steps for this video? This will take a few moments.')

                                    if (result) {
                                        this.onSearchVideo({
                                            reindex: true,
                                        })
                                    }
                                }}
                            >
                                Reindex
                            </div>

                            <div className={classnames('DemoServiceActions-action')}
                                onClick={() => {
                                    this.onSearchVideo({
                                        refresh: true,
                                    })
                                }}
                            >
                                Refresh
                            </div>

                            {/* <div className={classnames('DemoServiceActions-action')}
                                onClick={() => {
                                    const result = window.confirm('FORCE: Re-run all steps for this video? This will take a few moments.')
                                    if (result) {
                                        this.onSearchVideo({
                                            force: true,
                                        })
                                    }
                                }}
                            >
                                Force
                            </div> */}
                        </div>

                        <div className={classnames('DemoServiceCatalogSelection')}>
                            <div className={classnames('DemoServiceCatalogSelection-label', {})}>
                                Catalogs:
                            </div>

                            <div
                                className={classnames('DemoServiceCatalogSelection-action', {
                                    selected: Boolean(this.state.videoSearchCatalogMode === 'default'),
                                })}

                                onClick={() => {
                                    this.setState({
                                        videoSearchCatalogMode: 'default',
                                    })
                                }}
                            >
                                Default
                            </div>

                            <div
                                className={classnames('DemoServiceCatalogSelection-action', {
                                    selected: Boolean(this.state.videoSearchCatalogMode === 'public'),
                                })}

                                onClick={() => {
                                    this.setState({
                                        videoSearchCatalogMode: 'public',
                                    })
                                }}
                            >
                                Public
                            </div>

                            <div
                                className={classnames('DemoServiceCatalogSelection-action', {
                                    selected: Boolean(this.state.videoSearchCatalogMode === 'custom'),
                                })}

                                onClick={() => {
                                    this.setState({
                                        videoSearchCatalogMode: 'custom',
                                    })
                                }}
                            >
                                Custom

                                <div className={classnames('DemoServiceCatalogSelection-action-menu')}>
                                    <div className={classnames('DemoServiceCatalogSelection-action-menu-body')}>
                                        {
                                            Object.entries(this.state.videoSearchCatalogsByRegion || {})
                                                .map(([region, catalogs]) => {
                                                    return {
                                                        label: region,
                                                        options: catalogs.map((catalog) => {
                                                            return {
                                                                label: catalog.name,
                                                                value: catalog,
                                                                selected: ((this.state.videoSearchCatalogs || {})[region] || []).includes(catalog)
                                                            }
                                                        })
                                                    }
                                                })
                                                .map((group) => {
                                                    return (
                                                        <div key={`DemoServiceCatalogSelection-select-group-${group.label}`}
                                                            className={classnames('DemoServiceCatalogSelection-select-group')}
                                                        >
                                                            <div className={classnames('DemoServiceCatalogSelection-select-group-label')}>
                                                                { `${group.label}` }
                                                            </div>

                                                            <div className={classnames('DemoServiceCatalogSelection-select-group-options')}>
                                                                {
                                                                    (group.options || []).map((option) => {
                                                                        // const videoSearchCatalogs = this.state.videoSearchCatalogsByRegion[group.label] || []
                                                                        let videoSearchCatalogs = [...(this.state.videoSearchCatalogs || [])]

                                                                        const catalog = option.value

                                                                        const isSelected = !!videoSearchCatalogs.find((videoSearchCatalog) => {
                                                                            return Boolean(videoSearchCatalog._id === catalog._id)
                                                                        })

                                                                        return (
                                                                            <div key={`DemoServiceCatalogSelection-select-group-option-${option.label}`}
                                                                                className={classnames('DemoServiceCatalogSelection-select-group-option', {selected: isSelected})}

                                                                                onClick={() => {
                                                                                    if (isSelected) {
                                                                                        videoSearchCatalogs = videoSearchCatalogs.filter((videoSearchCatalog) => {
                                                                                            return !Boolean(videoSearchCatalog._id === catalog._id)
                                                                                        })

                                                                                    } else {
                                                                                        videoSearchCatalogs = [
                                                                                            ...videoSearchCatalogs,
                                                                                            catalog,
                                                                                        ]
                                                                                    }

                                                                                    this.setState({
                                                                                        videoSearchCatalogs,
                                                                                    })
                                                                                }}
                                                                            >
                                                                                <div className={classnames('DemoServiceCatalogSelection-select-group-option-checkbox')} />

                                                                                <div className={classnames('DemoServiceCatalogSelection-select-group-option-label')}>
                                                                                    { `${option.label}` }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className={classnames('DemoServiceFPS')}>
                            <div className={classnames('DemoServiceFPS-label')}>
                                FPS:
                            </div>

                            <input
                                className={classnames('DemoServiceFPS-input')}
                                value={this.state.videoSearchFPS || ''}
                                type="number"
                                size={1}
                                min={1}
                                max={5}
                                step={1}
                                // pattern="[1-5]"
                                onChange={(event) => {
                                    let videoSearchFPS = parseInt(event.target.value || 3)

                                    if (isNaN(videoSearchFPS)) {
                                        videoSearchFPS = 5
                                    }

                                    if (videoSearchFPS < 1) {
                                        videoSearchFPS = 1
                                    }

                                    if (videoSearchFPS > 5) {
                                        videoSearchFPS = 5
                                    }

                                    this.setState({
                                        videoSearchFPS,
                                    })
                                }}
                            />
                        </div> */}

                        <div className={classnames('DemoServiceLimit')}>
                            <div className={classnames('DemoServiceLimit-label')}>
                                Max # of results:
                            </div>

                            <input
                                className={classnames('DemoServiceLimit-input')}
                                value={this.state.videoSearchLimit || ''}
                                size={2}
                                type="number"
                                min={1}
                                max={30}
                                step={1}
                                onChange={(event) => {
                                    let videoSearchLimit = parseInt(event.target.value || 15)

                                    if (isNaN(videoSearchLimit)) {
                                        videoSearchLimit = 30
                                    }

                                    if (videoSearchLimit < 1) {
                                        videoSearchLimit = 1
                                    }

                                    if (videoSearchLimit > 30) {
                                        videoSearchLimit = 30
                                    }

                                    this.setState({
                                        videoSearchLimit,
                                    })
                                }}
                            />
                        </div>

                        <div className={classnames('DemoServiceRelevance')}>
                            <div className={classnames('DemoServiceRelevance-label')}>
                                Relevance:
                            </div>

                            <input
                                className={classnames('DemoServiceRelevance-input')}
                                value={this.state.videoSearchRelevance || ''}
                                type="number"
                                size={2}
                                min={0}
                                max={1}
                                step={0.05}
                                onChange={(event) => {
                                    let videoSearchRelevance = parseFloat(event.target.value || 0.8)

                                    if (isNaN(videoSearchRelevance)) {
                                        videoSearchRelevance = 1
                                    }

                                    if (videoSearchRelevance < 0) {
                                        videoSearchRelevance = 0
                                    }

                                    if (videoSearchRelevance > 1) {
                                        videoSearchRelevance = 1
                                    }

                                    this.setState({
                                        videoSearchRelevance,
                                    })
                                }}
                            />
                        </div>

                        <div className={classnames('DemoServiceTrack')}>
                            <div className={classnames('DemoServiceTrack-label')}>
                                Track:
                            </div>

                            <div className={classnames('DemoServiceTrack-sep')}>
                                &minus;
                            </div>

                            <input
                                className={classnames('DemoServiceTrack-input', 'DemoServiceTrack-input-begin')}
                                value={this.state.videoSearchTrackBegin || '0'}
                                type="number"
                                min={1}
                                max={API_VIDEO_SERVICE_DEFAULT_TRACK_MAX}
                                step={1}
                                // pattern={`([01]?\\d|${API_VIDEO_SERVICE_DEFAULT_TRACK_MAX})`}
                                onChange={(event) => {
                                    let { videoSearchTrackEnd } = this.state

                                    let videoSearchTrackBegin = parseInt(event.target.value || 0)

                                    if (isNaN(videoSearchTrackBegin)) {
                                        videoSearchTrackBegin = API_VIDEO_SERVICE_DEFAULT_TRACK_MAX
                                    }

                                    if (videoSearchTrackBegin < 1) {
                                        videoSearchTrackBegin = 1
                                    }

                                    if (videoSearchTrackBegin > API_VIDEO_SERVICE_DEFAULT_TRACK_MAX) {
                                        videoSearchTrackBegin = API_VIDEO_SERVICE_DEFAULT_TRACK_MAX
                                    }

                                    // videoSearchTrackEnd = API_VIDEO_SERVICE_DEFAULT_TRACK_MAX - Math.abs(videoSearchTrackBegin)

                                    // if (Math.abs(Math.abs(videoSearchTrackEnd) - Math.abs(videoSearchTrackBegin)) < 1) {
                                    //     videoSearchTrackBegin = API_VIDEO_SERVICE_DEFAULT_TRACK_MAX
                                    //     videoSearchTrackEnd = 0
                                    // }

                                    this.setState({
                                        videoSearchTrackBegin,
                                        videoSearchTrackEnd,
                                    })
                                }}
                            />

                            <input
                                className={classnames('DemoServiceTrack-input', 'DemoServiceTrack-input-end')}
                                value={Math.abs(this.state.videoSearchTrackEnd) || '0'}
                                type="number"
                                min={0}
                                max={API_VIDEO_SERVICE_DEFAULT_TRACK_MAX}
                                step={1}
                                readOnly={true}
                                disabled={true}
                                onChange={(event) => {
                                    // TODO
                                }}
                            />
                        </div>

                        <div className={classnames('DemoServiceOptions')}>
                            <div className={classnames('DemoServiceOptions-label')}>
                                Options:
                            </div>

                            <div className={classnames('DemoServiceOptions-option')}>
                                <input
                                    id={`DemoServiceOptions-option-familysearch`}
                                    className={classnames('DemoServiceOptions-input')}
                                    value={this.state.videoSearchFamilySearch || ''}
                                    type="checkbox"
                                    checked={this.state.videoSearchFamilySearch || false}
                                    onChange={(event) => {
                                        let videoSearchFamilySearch = !!(event.target.checked || 0)

                                        this.setState({
                                            videoSearchFamilySearch,
                                        })
                                    }}
                                />

                                <label className={classnames('DemoServiceOptions-label')}
                                    htmlFor={`DemoServiceOptions-option-familysearch`}
                                >
                                    Family Search
                                </label>
                            </div>

                            <div className={classnames('DemoServiceOptions-option')}>
                                <input
                                    id={`DemoServiceOptions-option-deduplication`}
                                    className={classnames('DemoServiceOptions-input')}
                                    value={this.state.videoSearchDeduplication || ''}
                                    type="checkbox"
                                    checked={this.state.videoSearchDeduplication || false}
                                    onChange={(event) => {
                                        let videoSearchDeduplication = !!(event.target.checked || 0)

                                        this.setState({
                                            videoSearchDeduplication,
                                        })
                                    }}
                                />

                                <label className={classnames('DemoServiceOptions-label')}
                                    htmlFor={`DemoServiceOptions-option-deduplication`}
                                >
                                    De-duplication
                                </label>
                            </div>

                            <div className={classnames('DemoServiceOptions-option')}>
                                <input
                                    id={`DemoServiceOptions-option-specificmatch`}
                                    className={classnames('DemoServiceOptions-input')}
                                    value={this.state.videoSearchSpecificMatch || ''}
                                    type="checkbox"
                                    checked={this.state.videoSearchSpecificMatch || false}
                                    onChange={(event) => {
                                        let videoSearchSpecificMatch = !!(event.target.checked || 0)

                                        this.setState({
                                            videoSearchSpecificMatch,
                                        })
                                    }}
                                />

                                <label className={classnames('DemoServiceOptions-label')}
                                    htmlFor={`DemoServiceOptions-option-specificmatch`}
                                >
                                    Specific Match
                                </label>
                            </div>

                            <div className={classnames('DemoServiceOptions-option')}>
                                <input
                                    id={`DemoServiceOptions-option-attributes`}
                                    className={classnames('DemoServiceOptions-input')}
                                    value={this.state.videoSearchAttributes || ''}
                                    type="checkbox"
                                    checked={this.state.videoSearchAttributes || false}
                                    onChange={(event) => {
                                        let videoSearchAttributes = !!(event.target.checked || 0)

                                        this.setState({
                                            videoSearchAttributes,
                                        })
                                    }}
                                />

                                <label className={classnames('DemoServiceOptions-label')}
                                    htmlFor={`DemoServiceOptions-option-attributes`}
                                >
                                    Attributes
                                </label>
                            </div>

                        </div>
                    </div>
                </header>

                <main>
                    <div className={classnames('DebugContainer')}>
                        <div className={classnames('DebugContainer-content', {hidden: true})}>
                            <ObjectInspector name='state'
                                data={this.state}
                                initialExpandedPaths={[
                                    'root',
                                    'root.*',
                                ]}
                            />
                        </div>
                    </div>

                    <div className={classnames('LoadingContainer', {hidden: this.isVideoPlayerVisible})}>
                        <span className={classnames('LoadingContainer-text')}>
                            Loading...
                        </span>
                    </div>

                    {
                        this.isVideoIndexingComplete && (
                            <div
                                className={classnames('VideoContainer', {
                                    hidden: this.isVideoPlayerHidden,
                                })}
                            >

                                <LensVideo {...{
                                    ref: (el) => {
                                        this.lensVideo = el
                                    },
                                    className: classnames({
                                        disabled: this.isVideoPlayerDisabled,
                                        hidden: this.isVideoPlayerHidden,
                                        error: this.isVideoError,
                                    }),
                                    src: `${this.videoURI}`,

                                    width: this.videoWidth,
                                    height: this.videoHeight,

                                    controls: true,
                                    muted: true,
                                    autoplay: false,
                                    loop: false,

                                    ready: this.isVideoReady,
                                    hidden: this.isVideoPlayerHidden,
                                    disabled: this.isVideoPlayerDisabled,

                                    onTimeUpdate: this.onVideoTimeUpdate,
                                    onLoadedData: this.onVideoLoad,
                                    onLoadedMetaData: this.onVideoLoad,
                                    onLoad: this.onVideoLoad,
                                    onError: this.onVideoError,
                                    onEnded: this.onVideoEnded,
                                    // onSeeked: this.onVideoSeeked,
                                    // onPause: this.onVideoPaused,
                                }}>

                                    <LensVideoOverlay
                                        colors={SHUFFLED_COLORS}

                                        tracks={this.tracks}
                                        results={this.videoSearchResults}

                                        scaleRatio={this.videoScaleRatio}

                                        className={classnames({
                                            loading: !!(this.state.videoFrameHover && this.state.videoFrameSnapshotImageLoading),
                                        })}
                                    >
                                        <LensVideoOverlayFrameImage
                                            uri={this.videoFrame && this.videoFrame.image && this.videoFrame.image.uri}
                                            hidden={!this.state.videoFrameHover || this.state.videoFrameSnapshotImageLoading}


                                            onLoading={this.onVideoOverlayFrameImageLoading}
                                            onLoad={this.onVideoOverlayFrameImageLoad}
                                            onError={this.onVideoOverlayFrameImageError}
                                        />

                                        <LensVideoControls
                                            video={this.lensVideo}
                                            hidden={!this.state.videoLoaded}
                                        />
                                    </LensVideoOverlay>

                                </LensVideo>

                                <div className={classnames('LensVideoFrameTracking')}>
                                    <div className={classnames('LensVideoFrameTrackingActions')}>
                                        <button className={classnames('LensVideoFrameTrackingActions-action')}
                                            onClick={() => {
                                                this.lensVideo.pause()

                                                this.onVideoSeeked()
                                            }}
                                        >
                                            { `Moment` }
                                        </button>
                                    </div>

                                    <LensVideoFrameTrackingTimeline
                                        video={this.lensVideo}
                                        seek={this.state.videoSearchSeek}
                                        track={[-Math.abs(this.state.videoSearchTrackBegin), Math.abs(this.state.videoSearchTrackEnd)]}
                                        precision={this.state.videoSearchPrecision}
                                    />
                                </div>

                                <LensVideoFrameTicker
                                    video={this.lensVideo}

                                    colors={SHUFFLED_COLORS}

                                    frames={this.videoSearchFrames}
                                    tracks={this.tracks}

                                    currentFrame={this.state.videoFrame}

                                    onFrameMouseOver={this.onVideoFrameTickMouseOver}
                                    onFrameMouseOut={this.onVideoFrameTickMouseOut}
                                    onFrameClick={this.onVideoFrameTickClick}
                                />

                            </div>
                        )
                    }

                    {
                        (
                            <div
                                className={classnames('ResultsContainer', {
                                    hidden: this.isVideoPlayerHidden,
                                })}
                            >

                                <div className={classnames('QueueContainer', {})}>
                                    <DemoServerProcessManager
                                        queues={this.jobQueues}

                                        hidden={!this.hasJobs}
                                        minimized={true}
                                    />
                                </div>

                                <DemoVideoStatus
                                    colors={STATS_COLORS}

                                    searchPending={this.isVideoSearchPending}
                                    searchResults={this.videoSearchResults}
                                    searchResponseStats={this.videoSearchStats}
                                    searchResponseTime={this.videoSearchResponseTime}

                                    searchTotalTime={this.state.videoSearchTotalTime}
                                />

                                {
                                    this.isVideoIndexingComplete && (
                                        <div>
                                            <DemoAttributesModal
                                                show={this.state.show}
                                                attributes={this.state.attributes}
                                                category={this.state.category}
                                            />

                                            <DemoTopMatches
                                                colors={SHUFFLED_COLORS}
                                                tracks={this.topTracks}

                                                onTrackActive={this.onVideoTopTrackActive}
                                                onTrackInactive={this.onVideoTopTrackInactive}

                                                onModalActive={this.showModal}
                                                onModalInactive={this.hideModal}

                                                onTrackPreviewPaginationChange={(pagination) => {
                                                    this.setState({
                                                        topTracksPreviewPagination: {
                                                            ...(this.state.topTracksPreviewPagination || {}),
                                                            ...pagination,
                                                        },
                                                    })
                                                }}
                                            />
                                        </div>
                                    )
                                }

                                {
                                    this.isVideoIndexingComplete && (
                                        <DemoResultsByCategory
                                            colors={SHUFFLED_COLORS}
                                            tracks={this.tracks}
                                            results={this.videoSearchResults}
                                            currentTrack={this.state.selectedTrack}

                                            onTrackActive={(track) => {
                                                this.setState({
                                                    selectedTrack: track,
                                                })
                                            }}

                                            onTrackInactive={(track) => {
                                                this.setState({
                                                    selectedTrack: null,
                                                })
                                            }}

                                            hidden={!this.state.selectedTrack}
                                        />
                                    )
                                }

                                {
                                    this.isVideoIndexingComplete && (
                                        <DemoResults
                                            colors={SHUFFLED_COLORS}
                                            tracks={this.tracks}
                                            results={this.videoSearchResultsAll}
                                        />
                                    )
                                }

                            </div>
                        )
                    }

                    {
                        this.isVideoIndexingComplete && (
                            <DemoFrameSnapshotsPreviewer
                                frames={this.state.selectedTrackFrames}
                                tracks={this.tracks}

                                offset={this.state.topTracksPreviewPagination.offset || 0}
                                limit={this.state.topTracksPreviewPagination.limit || 200}

                                currentFrame={this.frame}
                                currentTrack={this.state.selectedTrack}

                                onTrackActive={(track) => {
                                    this.setState({
                                        selectedTrack: track,
                                    })
                                }}

                                onTrackInactive={(track) => {
                                    this.setState({
                                        selectedTrack: null,
                                    })
                                }}

                                hidden={!this.state.selectedTrack}
                            />
                        )
                    }

                    <DemoAlertOverlay
                        hidden={true}
                        type={['warning', 'error', 'info'][0]}
                        title={['Sorry!'][0]}
                        message={['We are currently messing around with the backend, please try later.']}
                    />

                    <DemoAlertOverlay
                        hidden={window.location.host.includes('localhost') || !Boolean(this.state.apiError && this.state.apiError.code === 401)}
                        type='error'
                        title='Missing Access Token'
                        message={[
                            `You are not authorized to view the **Markable Video Demo** without an access token.\n\nPlease pass in a token in the URL like so: **\`${window.location.origin}/#/?accessToken=<TOKEN>\`**`,
                        ][0]}
                    />

                </main>

                <div className="FrameImages">
                    {
                        this.frames.map((frame) => {
                            return (
                                <link key={`frame-image-${frame._id}`} rel="preload" as="image" type="image/png" href={frame.image.uri} />
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    // FIXME: https://medium.com/@Charles_Stover/cache-your-react-event-listeners-to-improve-performance-14f635a62e15
    // onPlayHandler(key) {
    //     this.eventHandlers = this.eventHandlers || {}

    //     if (!Object.prototype.hasOwnProperty.call(this.eventHandlers, key)) {
    //         this.eventHandlers[key] = () => th(key)
    //     }

    //     return this.eventHandlers[key]
    // }

    onVideoURIChange = (event) => {
        log('onVideoURIChange', event)

        const videoURI = event && event.target ? event.target.value : event

        if (videoURI === this.state.videoURI) {
            return false
        }

        this.onClearOutputVideo()

        this.setState({
            videoURI,
        }, () => {
            setTimeout(() => {
                this.setState({
                    showVideo: true,
                })

                this.onVideoTimeUpdate(0, {force: true})
            }, 1000)
        })

        if (!isUri(videoURI)) {
            // TODO: update error/warning state
            return log(`Not a valid video URL: \`${videoURI}\``)
        }
    }

    onVideoURIDrop = (event) => {
        log('onVideoURIDrop', event)
    }

    onVideoURIBrowse = (event) => {
        log('onVideoURIBrowse', event)

        // document.querySelector('#DemoInputField-url').focus()
    }

    onVideoURIClear = (event) => {
        log('onVideoURIClear', event)

        this.setState({
            videoURI: '',
        })

        this.onClearOutputVideo()
    }

    onClearOutputVideo = (event) => {
        log(`onClearOutputVideo`)

        return new Promise((resolve) => {
            clearInterval(this.searchInterval)

            this.setState({
                videoData: undefined,
                videoError: undefined,

                videoFrameSnapshotImageURI: undefined,

                videoFrame: undefined,
                videoFrames: undefined,

                videoSearchResponse: undefined,
                videoSearchResults: undefined,
                videoSearchResultsAll: undefined,
                videoSearchStats: undefined,
                videoSearchTotalTime: undefined,

                videoPlayerSize: {
                    width: 0,
                    height: 0,

                    naturalWidth: 0,
                    naturalHeight: 0,

                    aspectRatio: 1.0,
                    scaleRatio: 1.0,
                },

                videoSnapshotSize: {
                    width: 0,
                    height: 0,

                    naturalWidth: 0,
                    naturalHeight: 0,

                    aspectRatio: 1.0,
                    scaleRatio: 1.0,
                },

                showVideo: false,

                topTracks: [],

            }, resolve)
        })
    }

    onVideoLoad = (event) => {
        log('onVideoLoad')

        this.setState({
            videoLoaded: true,

            showVideo: true,

            videoSearchSeek: 0,
        })
    }

    onVideoEnded = (event) => {
        if (this.lensVideo) {
            this.lensVideo.stop()
        }
    }

    onVideoSeeked = (event) => {
        const { currentTime, duration } = this.lensVideo || {}

        const videoSearchSeek = currentTime || 0

        this.setState({
            videoSearchSeek,
        }, async () => {
            await this.onSearchVideo()

            this.onVideoTimeUpdate(videoSearchSeek)
        })
    }

    onVideoPaused = (event) => {
        this.onVideoSeeked(event)
    }

    onVideoError = (event) => {
        log('onVideoError')

        this.setState({
            videoLoaded: false,

            showVideo: true,
        })
    }

    onVideoTimeUpdate = (currentTime, options = {}) => {
        log('onVideoTimeUpdate', currentTime, options)

        if (!options.force) {
            if (this.state.videoFrameHover) {
                return false
            }

            if (this.state.videoCurrentTime === currentTime) {
                return false
            }
        }

        if (options.seek) {
            this.lensVideo.currentTime = currentTime
        }

        this.setState({
            videoCurrentTime: currentTime,
        }, async () => {
            // TODO/WIP: only make another request if current paged response don't have the current frame
            const videoCurrentTime = this.state.videoCurrentTime || 0

            await this.onSearchVideo()

            const videoSearchResponse = this.state.videoSearchResponse || {}

            // log('onSearchVideo', videoCurrentTime, videoSearchResponse)

            const meta = videoSearchResponse.meta || {}

            let frames = videoSearchResponse.data || []

            // HACK: map to correct endpoint
            frames = frames.map((frame) => {
                return {
                    ...frame,
                    image: {
                        ...frame.image,
                        uri: `${frame.image && frame.image.uri}`.replace('localhost:1331', this.state.videoEndpoint),
                    },
                    results: [...frame.results].sort((resultA, resultB) => {
                        return resultB.score - resultA.score
                    })
                }
            })

            videoSearchResponse.data = frames

            const matchVideoTimeFrom = (videoCurrentTime * 1000)
            const matchVideoTimeTo = (matchVideoTimeFrom)

            const matchingFrames = findMatchingVideoFrames(frames, matchVideoTimeFrom, 0, matchVideoTimeTo) || []
            const matchingFrame = matchingFrames[0] || {}

            // log('findMatchingVideoFrames', videoCurrentTime, matchVideoTimeFrom, matchVideoTimeTo, matchingFrames)

            matchingFrame.results = (matchingFrame.results || []).filter((result) => {
                const category = (typeof result.category === 'object') ? result.category._id : result.category

                // REFACTOR: hardcoded hack
                return ![
                    'humans',
                ].includes(category)
            })

            let videoSearchResults = matchingFrame.results || []

            let videoSearchResultsAll = []

            for (const frame of frames) {
                for (const result of frame.results) {
                    const exists = !!videoSearchResultsAll.find((videoSearchResult) => {
                        return Boolean(videoSearchResult.track._id === result.track._id)
                    })

                    if (!exists) {
                        videoSearchResultsAll = [...videoSearchResultsAll, result]
                    }
                }
            }

            const videoFrame = {
                ...matchingFrame,
                // searchResults: results,
            }

            const videoFrames = [videoFrame]

            log({videoFrame})

            const videoFrameSnapshotImageURI = videoFrame.image && videoFrame.image.uri

            this.setState({
                videoFrameSnapshotImageURI,

                videoFrame,
                videoFrames,

                videoSearchResults,
                videoSearchResultsAll,

                frames,

            }, () => {
                if (!this.lensVideo) {
                    return log('waiting for video')
                }

                // NOTE: this causes buggy player experience
                // this.lensVideo.playbackRate = 0.5
                // this.lensVideo.currentTime = ((videoFrame.timestamp || 0) / 1000.0)

                const videoSnapshotImage = (videoFrame && videoFrame.image) || {}
                const videoPlayerSizeImage = (frames[0] && frames[0].image) || {}

                // FIXME: server should generate original dimension snapshots (as well)
                const videoSnapshotSize = {
                    width: parseInt(videoPlayerSizeImage.width || 0),
                    height: parseInt(videoPlayerSizeImage.height || 0),

                    naturalWidth: parseInt(videoPlayerSizeImage.naturalWidth || 0),
                    naturalHeight: parseInt(videoPlayerSizeImage.naturalHeight || 0),

                    aspectRatio: parseFloat(videoPlayerSizeImage.width || 0.0) / parseFloat(videoPlayerSizeImage.height || 1),
                    scaleRatio: parseFloat(videoPlayerSizeImage.width || 0.0) / parseFloat(videoPlayerSizeImage.width || 1),
                }

                // FIXME: use `lensVideo.element` or something similar
                const player = this.lensVideo

                const videoPlayerSize = {
                    width: parseInt(player.width || 0),
                    height: parseInt((player.width || 0) / parseFloat(videoSnapshotSize.aspectRatio || 1)),

                    naturalWidth: parseInt(player.naturalWidth || 0),
                    naturalHeight: parseInt(player.naturalHeight || 0) / parseFloat(videoSnapshotSize.aspectRatio || 1),

                    aspectRatio: videoSnapshotSize.aspectRatio,
                    scaleRatio: videoSnapshotSize.scaleRatio,
                }

                this.setState({
                    videoPlayerSize,

                    videoSnapshotImage,
                    videoSnapshotSize,

                }, () => {

                    this.onGenerateTopMatches()
                })

            })
        })
    }

    onSearchVideo = (options = {force: false, reindex: false, refresh: false}) => {
        return new Promise((resolve, reject) => {
            const videoSearchURI = this.state.videoURI
            const videoSearchURILast = this.state.videoSearchURI

            log('onSearchVideo', videoSearchURI)

            if (!isUri(videoSearchURI)) {
                return console.warn('onSearchVideoServer', `Invalid URI: ${this.state.videoURI}`)
            }

            const videoIsAlreadySearched = Boolean(videoSearchURI === videoSearchURILast)

            log('videoIsAlreadySearched', videoIsAlreadySearched)

            let seek = this.state.videoSearchSeek

            let trackBegin = this.state.videoSearchTrackBegin
            let trackEnd = this.state.videoSearchTrackEnd
            let track = [-Math.abs(trackBegin), Math.abs(trackEnd)]

            let precision = this.state.videoSearchPrecision

            let framesPerSecond = this.state.videoSearchFPS

            let limit = this.state.videoSearchLimit
            let relevance = this.state.videoSearchRelevance
            let family_search = this.state.videoSearchFamilySearch
            let deduplication = this.state.videoSearchDeduplication
            let specific_match = this.state.videoSearchSpecificMatch
            let attributes = this.state.videoSearchAttributes

            const hasNewtrackingOptions = (
                (this.state.videoSearchSeekLast !== seek) ||
                (this.state.videoSearchTrackBeginLast !== trackBegin) ||
                (this.state.videoSearchTrackEndLast !== trackEnd) ||
                (this.state.videoSearchPrecisionLast !== precision) ||
                (this.state.videoSearchFPSLast !== framesPerSecond) ||
                (this.state.videoSearchLimitLast !== limit) ||
                (this.state.videoSearchRelevanceLast !== relevance) ||
                (this.state.videoSearchFamilySearchLast !== family_search) ||
                (this.state.videoSearchDeduplicationLast !== deduplication) ||
                (this.state.videoSearchSpecificMatchLast !== specific_match)
            )

            console.warn({hasNewtrackingOptions})

            let {
                force,
                refresh,
                reindex,
            } = options

            if (!force && !refresh && !reindex && !hasNewtrackingOptions) {
                if (videoIsAlreadySearched) {
                    return this.setState({
                        videoIsAlreadySearched,
                    }, resolve)
                }
            }

            this.setState({
                videoSearchURI,

                // REFACTOR: `onResetState` helper function
                videoSearchResponse: null,
                videoSearchResults: null,
                videoSearchResultsAll: null,
                videoSearchStats: null,
                videoSearchTotalTime: null,
                videoSearchURIComplete: null,

                videoSnapshotImage: null,
                videoSnapshotSize: null,

                videoPlayerSize: null,

                videoFrameSnapshotImageURI: null,

                videoFrame: null,
                videoFrames: null,

                frames: null,

                topTracks: null,

            }, async () => {
                clearInterval(this.searchInterval)

                let catalogs

                switch (this.state.videoSearchCatalogMode) {
                    case 'default':
                        catalogs = undefined
                        break

                    case 'public':
                        catalogs = Object.values(this.state.videoSearchCatalogsByRegion)
                            .reduce((catalogs, regionCatalogs) => {
                                return [...catalogs, ...regionCatalogs]
                            }, [])
                            .map((catalog) => {
                                return catalog._id || catalog.name
                            })
                        break

                    case 'custom':
                    default:
                        catalogs = (this.state.videoSearchCatalogs || [])
                            .filter(Boolean)
                            .map((catalog) => {
                                return catalog._id || catalog.name
                            })
                }

                let cache = true
                let pendingResponse = false

                let reindex = options.reindex
                let refresh = options.refresh
                let force = options.force

                this.searchInterval = setInterval(async () => {
                    if (pendingResponse) {
                        return
                    }

                    pendingResponse = true

                    let result

                    if (videoSearchURI.includes('localhost') && SAMPLE_DATA[videoSearchURI]) {
                        result = {
                            response: {
                                body: SAMPLE_DATA[videoSearchURI],
                            },
                        }

                    } else {
                        const endpoint = this.state.videoEndpoint
                        const accessToken = this.state.accessToken || null
                        const pageLimit = this.state.pageLimit || 200

                        try {
                            const videoSearchParams = {
                                endpoint,
                                accessToken,
                                force,
                                catalogs,

                                seek,
                                track,
                                precision,

                                framesPerSecond,

                                limit,
                                relevance,
                                family_search,
                                deduplication,
                                specific_match,
                                attributes,

                                reindex,
                                refresh,

                                pageLimit,

                                cache,
                            }

                            // =========================================================================================
                            // Search Call
                            // =========================================================================================
                            result = await videoLens.search(videoSearchURI, videoSearchParams)
                            force = false;

                            this.setState({
                                apiError: null
                            })

                        } catch (error) {
                            this.setState({
                                apiError: error
                            })
                        }

                        reindex = false
                        refresh = false
                    }

                    pendingResponse = false

                    // log('API', result)

                    try {
                        const videoSearchResponse = result && result.response && result.response.body || {}

                        const meta = videoSearchResponse.meta || {}
                        const data = videoSearchResponse.data || {}

                        // log('META', meta)
                        // log('STATUSES', statuses)

                        const videoSearchStatuses = meta.statuses || []

                        const videoSearchStats = videoSearchStatuses
                            .reduce((status, videoStep) => {
                                // HACK: need fix server side eventually
                                if (Object.keys(videoStep || {}).length <= 1) {
                                    return status
                                }

                                return {
                                    ...status,
                                    [videoStep.step]: videoStep
                                }
                            }, {})

                        const videoSearchTotalTime = videoSearchStatuses
                            .reduce((sum, status) => {
                                if (!!status.elapsedTime) {
                                    return sum + status.elapsedTime
                                }

                                return sum
                            }, 0)

                        for (const key of this.state.videoSearchStatsKeys) {
                            videoSearchStats[key] = videoSearchStats[key] || {
                                completed: 0,
                                total: data.length,
                            }
                        }

                        this.setState({
                            videoSearchResponse,

                            videoSearchStats,
                            videoSearchTotalTime,

                            // videoSearchSeek: seek,
                            // videoSearchTrack: track,
                            // videoSearchPrecision: precision,

                            videoSearchSeekLast: seek,
                            videoSearchTrackBeginLast: trackBegin,
                            videoSearchTrackEndLast: trackEnd,
                            videoSearchPrecisionLast: precision,
                            videoSearchFPSLast: framesPerSecond,
                            videoSearchLimitLast: limit,
                            videoSearchRelevanceLast: relevance,
                            videoSearchFamilySearchLast: family_search,
                            videoSearchDeduplicationLast: deduplication,
                            videoSearchSpecificMatchLast: specific_match,

                        }, () => {
                            const requiredCompletionStep = this.state.videoSearchStatsKeys[this.state.videoSearchStatsKeys.length - 1]
                            const requiredStepStats = videoSearchStats[requiredCompletionStep]
                            const videoSearchComplete = Boolean(requiredStepStats.completed >= requiredStepStats.total)

                            if (videoSearchComplete) {
                                clearInterval(this.searchInterval)

                                this.onVideoTimeUpdate(0, {force: true})

                                const videoSearchURIComplete = videoSearchURI

                                this.setState({
                                    videoSearchURIComplete,
                                }, resolve)

                            } else {
                                cache = false
                            }
                        })

                    } catch (error) {
                        // console.error(error)

                        resolve()
                    }

                }, 3000)
            })
        })
    }

    onGenerateTopMatches = async () => {
        const frames = this.videoSearchFrames || []

        let topTracks = []

        try {
            frames.forEach((frame) => {
                frame.results.forEach((result) => {
                    const categoryName = (result.category || {}).name || result.category
                    const frameAttributes = result.attributes || []
                    const firstMatch = result.matches[0]

                    if (firstMatch) {
                        const firstMatchID = firstMatch._id
                        const trackID = `${firstMatchID}`

                        const existingTrack = topTracks.find((existingTrack) => {
                            return trackID === existingTrack.id
                        })

                        if (existingTrack) {
                            existingTrack.length = existingTrack.length += 1

                            existingTrack.timestamps = [
                                ...new Set([
                                    ...existingTrack.timestamps,
                                    frame.timestamp,
                                ])
                            ]

                        } else {

                            const track = {
                                id: trackID,
                                index: undefined,
                                category: categoryName,
                                attributes: frameAttributes,
                                matches: [],
                                length: 0,
                                timestamps: [],
                            }

                            const match = {
                                ...firstMatch,
                                detection_score: result.score,
                            }

                            track.length = 1
                            track.matches = [
                                ...track.matches,
                                match,
                            ]
                            track.timestamps = [
                                frame.timestamp,
                            ]

                            topTracks = [
                                ...topTracks,
                                track,
                            ]
                        }

                        topTracks = [...topTracks].sort((trackA, trackB) => {
                            return trackB.length - trackA.length
                        })

                        topTracks = topTracks.map((track, index) => {
                            track.index = index
                            track.color = SHUFFLED_COLORS[index]
                            track.score = track.length / parseFloat(frames.length)

                            return track
                        })

                        // topTracks = topTracks.slice(0, API_VIDEO_MAX_TOP_TRACKS)
                    }
                })
            })

            this.setState({
                topTracks,
            })

        } catch (error) {
            console.error(error)
        }
    }

    onVideoTopTrackActive = (track) => {
        const selectedTrack = track

        const selectedTrackFrames = selectedTrack ? this.frames.filter((frame) => {
            return selectedTrack.timestamps.includes(frame.timestamp)
        }) :  []

        this.setState({
            selectedTrack,
            selectedTrackFrames,
        })
    }

    showModal = (attributes, category) => {
        this.setState({
            show: true,
            attributes: attributes,
            category: category
        })
    }

    hideModal = () => {
        this.setState({
            show: false,
            attributes: [],
            category: null,
        })
    }

    onVideoTopTrackInactive = (track) => {
        const selectedTrack = null
        const selectedTrackFrames = []

        this.setState({
            selectedTrack,
            selectedTrackFrames,
        })
    }

    onVideoFrameTickMouseOver = (frame) => {
        cancelAnimationFrame(this.onVideoFrameTickMouseHoverRAF)

        this.onVideoFrameTickMouseHoverRAF = requestAnimationFrame(() => {
            const videoFrameHover = frame

            const wasPlaying = this.lensVideo.playing

            if (wasPlaying) {
                this.lensVideo.pause()
            }

            this.setState({
                videoFrameHover,

                wasPlaying,

                videoFrameSnapshotImageLoading: true,
            })
        })
    }

    onVideoFrameTickMouseOut = (frame) => {
        cancelAnimationFrame(this.onVideoFrameTickMouseHoverRAF)

        this.onVideoFrameTickMouseHoverRAF = requestAnimationFrame(() => {
            const videoFrameHover = undefined

            const wasPlaying = !!this.state.wasPlaying

            if (wasPlaying) {
                this.lensVideo.play()
            }

            this.setState({
                videoFrameHover,

                wasPlaying,

                videoFrameSnapshotImageLoading: false,
            })
        })
    }

    onVideoFrameTickClick = (frame) => {
        const currentTime = frame.timestamp / 1000.0

        this.onVideoTimeUpdate(currentTime, {
            force: true,
            seek: true,
        })
    }

    onVideoOverlayFrameImageLoading = () => {
        // console.warn('frame snapshot loading')

        this.setState({
            videoFrameSnapshotImageLoading: true,
        })
    }

    onVideoOverlayFrameImageLoad = () => {
        // console.warn('frame snapshot loaded')

        this.setState({
            videoFrameSnapshotImageLoading: false,
        })
    }

    onVideoOverlayFrameImageError = () => {
        this.setState({
            videoFrameSnapshotImageLoading: false,
        })
    }

}

export default App
