
/* =========================================
      FUNCTIONS
-------------------------------------- */

export const isPresent = (uri) => {
    return !!`${uri}`.length
}

export const isUriImage = (uri) => {
    return /\.(?:jp?g|png|gif|webp|tiff)/i.test(uri)
}

export const isUriVideo = (uri) => {
    return /\.(?:mp4|avi|ogv|webm)/i.test(uri)
}

export const isUriHTTP = (uri) => {
    return /^https?:\/{2}/i.test(uri)
}

export const isUriData = (uri) => {
    return /^data:[\w\W\d]+,[\w\W\d]+/i.test(uri)
}

export const isUriBlob = (uri) => {
    return /^blob:/i.test(uri)
}

export const isUriFile = (uri) => {
    return /^file:\/{2}/i.test(uri)
}

export const isUriLocalhost = (uri) => {
    return `${uri}`.includes('localhost')
}

export const isUri = (uri) => {
    return isUriHTTP(uri) || isUriData(uri) || isUriBlob(uri) || isUriFile(uri)
}


/* =========================================
      EXPORT
-------------------------------------- */

export default {
    isPresent,
    isUriHTTP,
    isUriData,
    isUriBlob,
    isUriFile,
    isUriLocalhost,
    isUri,
}
