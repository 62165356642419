/* =========================================
      CONSTANTS
-------------------------------------- */

export const QA_VIDEOS_ENDPOINT = process.env.REACT_APP_ENV == 'production' ? `${window.location.protocol}//qa.markable.ai/videos` : `${window.location.protocol}//markable-qa.s3.cn-north-1.amazonaws.com.cn/video`
export const CN_VIDEOS_ENDPOINT = `${window.location.protocol}//markable-qa.s3.cn-north-1.amazonaws.com.cn`
export const CN_QA_VIDEOS_ENDPOINT = `${window.location.protocol}//markable-china-miniapp.s3.cn-north-1.amazonaws.com.cn/video`
export const CN_IQIYI_VIDEOS_ENDPOINT = `${window.location.protocol}//markable-china-miniapp.s3.cn-north-1.amazonaws.com.cn/iqiyi`
export const CN_IQIYI_VIDEOS_MINIAPP_ENDPOINT = `${window.location.protocol}//markable-china-miniapp.s3.cn-north-1.amazonaws.com.cn/zhupan/new_video`

const baiwei = [
    `${CN_VIDEOS_ENDPOINT}/video+demo/baiwei/4.mp4`
]

const couchFashion = [
`${CN_VIDEOS_ENDPOINT}/video+demo/tiktok/1.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/tiktok/2.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/tiktok/3.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/tiktok/4.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/tiktok/5.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/tiktok/6.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/tiktok/7.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/tiktok/8.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/tiktok/9.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/tiktok/10.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/tiktok/11.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/tiktok/12.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/tiktok/13.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/tiktok/14.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/tiktok/15.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/tiktok/16.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/tiktok/17.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/tiktok/18.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/tiktok/19.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/tiktok/20.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/tiktok/21.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/tiktok/22.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/tiktok/23.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/202110271035_1.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/Birthday Glam.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/Power Suit Trend.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/Pretty in Pink.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/Vintage Inspired Outfits.mp4`]

const yinduTitok = [
`${CN_VIDEOS_ENDPOINT}/video+demo/h264_high_540.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/h264_high_360.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/h264_high_540 (1).mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/h264_high_720.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/h264_high_640.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/h264_high_406.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/h264_high_576.mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/h264_high_540 (2).mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/h264_high_540 (3).mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/h264_high_576 (1).mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/h264_high_360 (1).mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/h264_high_540 (4).mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/h264_high_360 (2).mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/h264_high_360 (3).mp4`,
`${CN_VIDEOS_ENDPOINT}/video+demo/h264_high_480.mp4`]
// SEE: https://docs.google.com/spreadsheets/d/1lvZXAg93ag4nSa2NHiRkEYdiYnDrIcWK57OuvtiVonU/edit#gid=0
export const CN_VIDEOS = process.env.REACT_APP_ENV === 'cn' ? [
    ...baiwei,
    ...couchFashion,
    ...yinduTitok,
    `${CN_VIDEOS_ENDPOINT}/video+demo/23_Mysta_%E3%80%8E%E3%82%A2%E3%82%A4%E3%83%88%E3%82%99%E3%83%AB%E3%81%AE%E3%83%81%E3%82%A2%E3%82%BF%E3%82%99%E3%83%B3%E3%82%B9%E3%81%BE%E3%81%A8%E3%82%81%EF%BC%81%E3%80%8F%EF%BD%9Cmysta+YouTube_00%3A00~00%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/19_LineLive_LINELIVE+2019.09.03+%E2%91%A0_03%3A30~04%3A00.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/21_Mysta_YouTube%E3%83%9E%E3%82%A4%E3%82%B9%E3%82%BF+%E3%80%8E%E3%81%8A%E7%AC%91%E3%81%84%C3%97%E3%82%B1%E3%82%99%E3%83%BC%E3%83%A0%E3%80%8F_00%3A00~00%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/24_LiveCommerce_%E3%80%90YouTubeLIVE%E3%80%91%E3%83%A6%E3%83%8B%E3%82%AF%E3%83%ADU%E7%99%BA%E5%A3%B2%E5%89%8D%E6%97%A5%E3%83%9E%E3%82%B9%E3%83%88%E3%83%8F%E3%82%99%E3%82%A4%E3%83%AC%E3%83%9B%E3%82%9A%E3%83%BC%E3%83%88%EF%BC%81_15%3A00~15%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/13_LineLive_%E6%B0%B4%E7%80%AC%E3%81%84%E3%81%AE%E3%82%8Aline+live+(24%3A6%3A2021)_00%3A00~00%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/11_TV+commercial_%E3%83%9F%E3%82%AA%E3%82%AF%E3%83%AD+%236+%E8%A1%97%E7%9D%80%E3%81%AE%E3%83%AA%E3%83%A9%E3%82%B3_00%3A00~00%3A15.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/09_TV+commercial_Yahoo!+JAPAN%E3%82%A2%E3%83%95%E3%82%9A%E3%83%AA+%E3%80%8C%E3%81%A8%E3%82%99%E3%82%93%E3%81%A8%E3%82%99%E3%82%93%E4%BE%BF%E5%88%A9%E3%81%AB+%E9%9B%A8%E9%9B%B2%E3%83%AC%E3%83%BC%E3%82%BF%E3%82%99%E3%83%BC%E3%80%8D%E7%AF%87_00%3A00~00%3A15.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/22_Mysta_PINK+CRES.+%E5%A4%8F%E7%84%BC%E9%9B%85+mysta(%E3%83%9E%E3%82%A4%E3%82%B9%E3%82%BF)%E3%82%BB%E3%83%AC%E3%82%AF%E3%83%88%E3%82%B7%E3%83%A7%E3%83%83%E3%83%95%E3%82%9A%E4%BC%81%E7%94%BB%E3%82%B9%E3%82%BF%E3%83%BC%E3%83%88%E3%80%90%E3%81%9D%E3%81%AE1%E3%80%91_00%3A00~00%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/20_Mysta_INI+%E6%9C%A8%E6%9D%91%E6%9F%BE%E5%93%89+mysta_00%3A00~00%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/03_TV+drama_%E3%80%90%E7%89%B9%E5%88%A5%E4%BA%88%E5%91%8A%E3%80%91%E3%80%8C%E3%83%8D%E3%83%A1%E3%82%B7%E3%82%B9%E3%80%8D%E3%81%AE%E3%83%86%E3%83%BC%E3%83%9E%E6%9B%B2%E3%80%8ENemesis%E3%80%8F%E3%83%95%E3%83%AB%E3%83%8F%E3%82%99%E3%83%BC%E3%82%B7%E3%82%99%E3%83%A7%E3%83%B3%E8%A7%A3%E7%A6%81%EF%BC%81%EF%BC%8F%E5%BA%83%E7%80%AC%E3%81%99%E3%81%99%E3%82%99%E3%83%BB%E6%AB%BB%E4%BA%95%E7%BF%94W%E4%B8%BB%E6%BC%94%EF%BC%81%E6%AF%8E%E9%80%B1%E6%97%A5%E6%9B%9C%E3%82%88%E3%82%8B10%E6%99%8230%E5%88%86%E6%94%BE%E9%80%81%EF%BC%8F%E3%80%90%E6%97%A5%E3%83%86%E3%83%AC%E3%83%88%E3%82%99%E3%83%A9%E3%83%9E%E5%85%AC%E5%BC%8F%E3%80%91_00%3A30~01%3A00.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/07_TV+commercial_%E3%82%BD%E3%83%95%E3%83%88%E3%83%8F%E3%82%99%E3%83%B3%E3%82%AF+CM+5G%E3%81%A3%E3%81%A6%E3%83%88%E3%82%99%E3%83%A9%E3%81%88%E3%82%82%E3%82%93%EF%BC%9F%E3%80%8C%E3%83%88%E3%82%99%E3%83%A9%E3%83%9F%E7%99%BB%E5%A0%B4%E3%80%8D%E7%AF%87%EF%BC%8830%E7%A7%92%EF%BC%89_00%3A00~00%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/06_Sports_%E3%80%90D.LEAGUE%E3%80%91%7C+Where+is+the+love%3F+-+avex+ROYAL+BRATS+%7C+Choreography+by+RIEHATA+(%E5%92%8C%E8%A8%B3%E4%BB%98%E3%81%8D)_00%3A30~01%3A00.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/01_TV+drama_%E3%80%90%E7%9F%B3%E5%8E%9F%E3%81%95%E3%81%A8%E3%81%BF%E2%9D%A4%E7%B6%BE%E9%87%8E%E5%89%9B%E3%80%91%E5%8A%87%E7%9A%84%E3%81%AA%E5%86%8D%E4%BC%9A%E3%81%A6%E3%82%99%E7%89%A9%E8%AA%9E%E3%81%AF%E5%AE%8C%E7%B5%90%EF%BC%81%E5%86%8D%E4%BC%9A%E3%81%BE%E3%81%A6%E3%82%99%E3%81%AE%E7%A9%BA%E7%99%BD%E3%81%AE%E6%99%82%E9%96%93%E3%81%AB%E3%81%AF%E3%82%82%E3%81%A3%E3%81%A8Deep%E3%81%AA%E6%84%9B%E3%81%AE%E7%89%A9%E8%AA%9E%E3%81%8B%E3%82%99%EF%BC%81%E6%B0%B4%E6%9B%9C%E3%83%88%E3%82%99%E3%83%A9%E3%83%9E%E3%80%8E%E6%81%8B%E3%81%AF%E3%82%82%E3%81%A3%E3%81%A8Deep%E3%81%AB%E3%83%BC%E9%81%8B%E5%91%BD%E3%81%AE%E5%86%8D%E4%BC%9A%E3%82%B9%E3%83%98%E3%82%9A%E3%82%B7_00%3A00~00%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/02_TV+drama_%E6%97%A5%E6%9B%9C%E5%8A%87%E5%A0%B4%E3%80%80%E3%83%88%E3%82%99%E3%83%A9%E3%82%B3%E3%82%99%E3%83%B3%E6%A1%9C%E3%80%80%E4%B8%AD%E5%B0%BE%E6%98%8E%E6%85%B6%E3%80%80%E5%B0%8F%E6%B1%A0%E5%BE%B9%E5%B9%B3%E3%80%80%E3%82%B5%E3%82%A8%E3%82%B3%E3%80%80%E7%99%BB%E5%A0%B4%E3%82%B7%E3%83%BC%E3%83%B3%E2%91%A0_00%3A00~00%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/04_Sports_2021%E5%B9%B46%E6%9C%8830%E6%97%A5+%E7%A6%8F%E5%B2%A1%E3%82%BD%E3%83%95%E3%83%88%E3%83%8F%E3%82%99%E3%83%B3%E3%82%AF%E5%AF%BE%E5%9F%BC%E7%8E%89%E8%A5%BF%E6%AD%A6+%E8%A9%A6%E5%90%88%E3%83%8F%E3%82%A4%E3%83%A9%E3%82%A4%E3%83%88_01%3A00~01%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/05_Sports_%E3%80%90%E3%83%8F%E3%82%A4%E3%83%A9%E3%82%A4%E3%83%88%E3%80%912021%3A6%3A1+%E5%8D%83%E8%91%89%E3%82%B7%E3%82%99%E3%82%A7%E3%83%83%E3%83%84vs%E5%AE%87%E9%83%BD%E5%AE%AE%E3%83%95%E3%82%99%E3%83%AC%E3%83%83%E3%82%AF%E3%82%B9%E3%80%90%E5%84%AA%E5%8B%9D%E3%80%91.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/08_TV+commercial_%E3%80%90LINELIVE%E3%80%912019%E5%B9%B43%E6%9C%88+TVCM_00%3A00~00%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/10_TV+commercial_%E3%80%90ZOZOTOWN%E3%80%91TVCM%E3%80%8CZOZOSALE_%E3%82%AB%E3%83%BC%E3%83%88%E7%AF%87%E3%80%8D_00%3A00~00%3A15.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/12_LineLive_%E5%90%89%E6%B2%A2%E4%BA%AE+%E3%83%A9%E3%82%A4%E3%83%B3%E3%83%A9%E3%82%A4%E3%83%95%E3%82%99%EF%BC%88LINE+LIVE%EF%BC%89%E3%83%95%E3%82%A1%E3%83%B3%E3%81%AE%E4%BA%BA%E3%81%AB%E7%B5%90%E5%A9%9A%E3%81%97%E3%81%A6%E3%81%8F%E3%81%9F%E3%82%99%E3%81%95%E3%81%84%E3%81%A8%E8%A8%80%E3%82%8F%E3%82%8C%E3%81%A6%E3%81%AE%E5%8F%8D%E5%BF%9C%E3%81%8B%E3%82%99%E5%8F%AF%E6%84%9B%E3%81%84%E3%81%8B%E3%82%99%E3%82%81%E3%81%A3%E3%81%A1%E3%82%83%E3%82%A4%E3%82%B1%E3%83%A1%E3%83%B3Video+Ryo+Yoshizawa_00%3A00~00%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/15_LineLive_%E3%80%90%E6%94%BE%E9%80%81%E4%BA%8B%E6%95%85(%EF%BC%9F)%E3%80%91LINE+LIVE%E3%81%A6%E3%82%99%E3%82%A4%E3%83%98%E3%82%99%E3%83%B3%E3%83%88%E5%8F%82%E5%8A%A0%E8%80%85%E8%A6%8B%E3%81%A6%E3%81%84%E3%81%9F%E3%82%89%E2%80%A6_00%3A30~01%3A00.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/16_LineLive_%E3%80%90LINE+LIVE%E3%80%91+2019%E5%B9%B47%E6%9C%88+%E3%83%A9%E3%82%A4%E3%83%8F%E3%82%99%E3%83%BC%E7%B4%B9%E4%BB%8B_00%3A00~00%3A20.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/17_LineLive_%5BFULL%5D+190214+JAY+%26+JUNE+LINE+LIVE+ON+VALENTINE_S+DAY_02%3A00~02%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/18_LineLive_20210619+linelive+yatsuifes2021+day1+batten+girls_04%3A30~05%3A00.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/25_LiveCommerce_%E3%80%90%E5%B0%8F%E6%9F%84%E3%81%95%E3%82%93%E3%83%95%E3%82%A1%E3%83%83%E3%82%B7%E3%83%A7%E3%83%B3%E3%80%91152cm%E3%81%AEyuki%E3%81%95%E3%82%93%E3%81%8B%E3%82%99JOURNAL+STANDARD%E3%81%AE%E6%96%B0%E4%BD%9C%E3%81%A6%E3%82%99%E3%82%B3%E3%83%BC%E3%83%86%E3%82%99%E3%82%A3%E3%83%8D%E3%83%BC%E3%83%88%EF%BC%81_01%3A30~02%3A00.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/26_LiveCommerce_%E3%80%90Uniqlo+U%E3%80%91%E7%99%BA%E5%A3%B2%E5%BD%93%E6%97%A5%EF%BC%81%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%AA%E3%82%B9%E3%83%88%E3%81%8B%E3%82%99%E9%81%B8%E3%82%93%E3%81%9F%E3%82%99%E6%9C%AC%E6%B0%97%E3%81%AA%E3%83%AA%E3%82%A2%E3%83%AB%E8%B2%B7%E3%81%84%E3%82%A2%E3%82%A4%E3%83%86%E3%83%A0%E5%BE%B9%E5%BA%95%E3%83%AC%E3%83%9B%E3%82%9A%E3%83%BC%E3%83%88%EF%BC%81_14%3A00~14%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/27_LiveCommerce_%E3%80%90%E3%82%BF%E3%82%99%E3%82%B5%E3%81%84%E6%9C%8D%E3%83%98%E3%82%99%E3%82%B9%E3%83%885%E3%80%91%E3%82%84%E3%81%A3%E3%81%A6%E3%82%8B%E4%BA%BA%E3%81%AF%E8%A6%81%E6%B3%A8%E6%84%8F%EF%BC%81%E7%B5%B6%E5%AF%BE%E3%81%AB%E5%A4%B1%E6%95%97%E3%81%99%E3%82%8B%E3%82%A4%E3%82%B1%E3%81%A6%E3%81%AA%E3%81%84%E7%9D%80%E3%81%93%E3%81%AA%E3%81%97_02%3A30~03%3A00.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/28_runway_TGC+SPECIAL+COLLECTION%EF%BD%9C%E7%AC%AC32%E5%9B%9E+%E3%83%9E%E3%82%A4%E3%83%8A%E3%83%92%E3%82%99+%E6%9D%B1%E4%BA%AC%E3%82%AB%E3%82%99%E3%83%BC%E3%83%AB%E3%82%B9%E3%82%99%E3%82%B3%E3%83%AC%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3+2021+SPRING%3ASUMMER_01%3A30~02%3A00.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/29_runway_AEON+CARD+SPECIAL+STAGE%EF%BD%9C%E7%AC%AC32%E5%9B%9E+%E3%83%9E%E3%82%A4%E3%83%8A%E3%83%92%E3%82%99+%E6%9D%B1%E4%BA%AC%E3%82%AB%E3%82%99%E3%83%BC%E3%83%AB%E3%82%B9%E3%82%99%E3%82%B3%E3%83%AC%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3+2021+SPRING%3ASUMMER_01%3A00~01%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/30_runway_TGC+SPECIAL+COLLECTION+2%EF%BD%9C%E3%83%9E%E3%82%A4%E3%83%8A%E3%83%92%E3%82%99+presents+%E7%AC%AC25%E5%9B%9E+%E6%9D%B1%E4%BA%AC%E3%82%AB%E3%82%99%E3%83%BC%E3%83%AB%E3%82%B9%E3%82%99%E3%82%B3%E3%83%AC%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3+2017+AUTUMN_05%3A00~05%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/%E9%9F%93%E5%8A%87.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/130385be712ebeef0c5f9d513dcb062b.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/BV_3000k.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/JUSTA_tid_0000999001_m.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/%E4%BD%A0%E7%9A%84%E5%90%8D%E5%AD%97.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/%E5%8F%A4%E5%A2%93%E5%A5%87%E5%85%B5.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/%E5%87%BA%E7%A5%9E%E5%85%A5%E5%8C%962.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/1617849766315.mp4`,
    `https://client-mediatek.s3.cn-north-1.amazonaws.com.cn/lady+dress+up+in+spring.mp4`,
    `https://client-mediatek.s3.cn-north-1.amazonaws.com.cn/outdoor+climbing.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/1617849766315+4.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/1617849766315+3.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/1617849766315+2.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/1617849399417.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/1617849399417+2.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/1617849083412.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/1617848754671.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/1617848754671+3.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/1617848754671+2.mp4`,
] : [
    ...baiwei,
    ...couchFashion,
    ...yinduTitok,
    `${CN_VIDEOS_ENDPOINT}/video+demo/23_Mysta_%E3%80%8E%E3%82%A2%E3%82%A4%E3%83%88%E3%82%99%E3%83%AB%E3%81%AE%E3%83%81%E3%82%A2%E3%82%BF%E3%82%99%E3%83%B3%E3%82%B9%E3%81%BE%E3%81%A8%E3%82%81%EF%BC%81%E3%80%8F%EF%BD%9Cmysta+YouTube_00%3A00~00%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/19_LineLive_LINELIVE+2019.09.03+%E2%91%A0_03%3A30~04%3A00.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/21_Mysta_YouTube%E3%83%9E%E3%82%A4%E3%82%B9%E3%82%BF+%E3%80%8E%E3%81%8A%E7%AC%91%E3%81%84%C3%97%E3%82%B1%E3%82%99%E3%83%BC%E3%83%A0%E3%80%8F_00%3A00~00%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/24_LiveCommerce_%E3%80%90YouTubeLIVE%E3%80%91%E3%83%A6%E3%83%8B%E3%82%AF%E3%83%ADU%E7%99%BA%E5%A3%B2%E5%89%8D%E6%97%A5%E3%83%9E%E3%82%B9%E3%83%88%E3%83%8F%E3%82%99%E3%82%A4%E3%83%AC%E3%83%9B%E3%82%9A%E3%83%BC%E3%83%88%EF%BC%81_15%3A00~15%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/13_LineLive_%E6%B0%B4%E7%80%AC%E3%81%84%E3%81%AE%E3%82%8Aline+live+(24%3A6%3A2021)_00%3A00~00%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/11_TV+commercial_%E3%83%9F%E3%82%AA%E3%82%AF%E3%83%AD+%236+%E8%A1%97%E7%9D%80%E3%81%AE%E3%83%AA%E3%83%A9%E3%82%B3_00%3A00~00%3A15.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/09_TV+commercial_Yahoo!+JAPAN%E3%82%A2%E3%83%95%E3%82%9A%E3%83%AA+%E3%80%8C%E3%81%A8%E3%82%99%E3%82%93%E3%81%A8%E3%82%99%E3%82%93%E4%BE%BF%E5%88%A9%E3%81%AB+%E9%9B%A8%E9%9B%B2%E3%83%AC%E3%83%BC%E3%82%BF%E3%82%99%E3%83%BC%E3%80%8D%E7%AF%87_00%3A00~00%3A15.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/22_Mysta_PINK+CRES.+%E5%A4%8F%E7%84%BC%E9%9B%85+mysta(%E3%83%9E%E3%82%A4%E3%82%B9%E3%82%BF)%E3%82%BB%E3%83%AC%E3%82%AF%E3%83%88%E3%82%B7%E3%83%A7%E3%83%83%E3%83%95%E3%82%9A%E4%BC%81%E7%94%BB%E3%82%B9%E3%82%BF%E3%83%BC%E3%83%88%E3%80%90%E3%81%9D%E3%81%AE1%E3%80%91_00%3A00~00%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/20_Mysta_INI+%E6%9C%A8%E6%9D%91%E6%9F%BE%E5%93%89+mysta_00%3A00~00%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/03_TV+drama_%E3%80%90%E7%89%B9%E5%88%A5%E4%BA%88%E5%91%8A%E3%80%91%E3%80%8C%E3%83%8D%E3%83%A1%E3%82%B7%E3%82%B9%E3%80%8D%E3%81%AE%E3%83%86%E3%83%BC%E3%83%9E%E6%9B%B2%E3%80%8ENemesis%E3%80%8F%E3%83%95%E3%83%AB%E3%83%8F%E3%82%99%E3%83%BC%E3%82%B7%E3%82%99%E3%83%A7%E3%83%B3%E8%A7%A3%E7%A6%81%EF%BC%81%EF%BC%8F%E5%BA%83%E7%80%AC%E3%81%99%E3%81%99%E3%82%99%E3%83%BB%E6%AB%BB%E4%BA%95%E7%BF%94W%E4%B8%BB%E6%BC%94%EF%BC%81%E6%AF%8E%E9%80%B1%E6%97%A5%E6%9B%9C%E3%82%88%E3%82%8B10%E6%99%8230%E5%88%86%E6%94%BE%E9%80%81%EF%BC%8F%E3%80%90%E6%97%A5%E3%83%86%E3%83%AC%E3%83%88%E3%82%99%E3%83%A9%E3%83%9E%E5%85%AC%E5%BC%8F%E3%80%91_00%3A30~01%3A00.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/07_TV+commercial_%E3%82%BD%E3%83%95%E3%83%88%E3%83%8F%E3%82%99%E3%83%B3%E3%82%AF+CM+5G%E3%81%A3%E3%81%A6%E3%83%88%E3%82%99%E3%83%A9%E3%81%88%E3%82%82%E3%82%93%EF%BC%9F%E3%80%8C%E3%83%88%E3%82%99%E3%83%A9%E3%83%9F%E7%99%BB%E5%A0%B4%E3%80%8D%E7%AF%87%EF%BC%8830%E7%A7%92%EF%BC%89_00%3A00~00%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/06_Sports_%E3%80%90D.LEAGUE%E3%80%91%7C+Where+is+the+love%3F+-+avex+ROYAL+BRATS+%7C+Choreography+by+RIEHATA+(%E5%92%8C%E8%A8%B3%E4%BB%98%E3%81%8D)_00%3A30~01%3A00.mp4`,    
    `${CN_VIDEOS_ENDPOINT}/video+demo/01_TV+drama_%E3%80%90%E7%9F%B3%E5%8E%9F%E3%81%95%E3%81%A8%E3%81%BF%E2%9D%A4%E7%B6%BE%E9%87%8E%E5%89%9B%E3%80%91%E5%8A%87%E7%9A%84%E3%81%AA%E5%86%8D%E4%BC%9A%E3%81%A6%E3%82%99%E7%89%A9%E8%AA%9E%E3%81%AF%E5%AE%8C%E7%B5%90%EF%BC%81%E5%86%8D%E4%BC%9A%E3%81%BE%E3%81%A6%E3%82%99%E3%81%AE%E7%A9%BA%E7%99%BD%E3%81%AE%E6%99%82%E9%96%93%E3%81%AB%E3%81%AF%E3%82%82%E3%81%A3%E3%81%A8Deep%E3%81%AA%E6%84%9B%E3%81%AE%E7%89%A9%E8%AA%9E%E3%81%8B%E3%82%99%EF%BC%81%E6%B0%B4%E6%9B%9C%E3%83%88%E3%82%99%E3%83%A9%E3%83%9E%E3%80%8E%E6%81%8B%E3%81%AF%E3%82%82%E3%81%A3%E3%81%A8Deep%E3%81%AB%E3%83%BC%E9%81%8B%E5%91%BD%E3%81%AE%E5%86%8D%E4%BC%9A%E3%82%B9%E3%83%98%E3%82%9A%E3%82%B7_00%3A00~00%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/02_TV+drama_%E6%97%A5%E6%9B%9C%E5%8A%87%E5%A0%B4%E3%80%80%E3%83%88%E3%82%99%E3%83%A9%E3%82%B3%E3%82%99%E3%83%B3%E6%A1%9C%E3%80%80%E4%B8%AD%E5%B0%BE%E6%98%8E%E6%85%B6%E3%80%80%E5%B0%8F%E6%B1%A0%E5%BE%B9%E5%B9%B3%E3%80%80%E3%82%B5%E3%82%A8%E3%82%B3%E3%80%80%E7%99%BB%E5%A0%B4%E3%82%B7%E3%83%BC%E3%83%B3%E2%91%A0_00%3A00~00%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/04_Sports_2021%E5%B9%B46%E6%9C%8830%E6%97%A5+%E7%A6%8F%E5%B2%A1%E3%82%BD%E3%83%95%E3%83%88%E3%83%8F%E3%82%99%E3%83%B3%E3%82%AF%E5%AF%BE%E5%9F%BC%E7%8E%89%E8%A5%BF%E6%AD%A6+%E8%A9%A6%E5%90%88%E3%83%8F%E3%82%A4%E3%83%A9%E3%82%A4%E3%83%88_01%3A00~01%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/05_Sports_%E3%80%90%E3%83%8F%E3%82%A4%E3%83%A9%E3%82%A4%E3%83%88%E3%80%912021%3A6%3A1+%E5%8D%83%E8%91%89%E3%82%B7%E3%82%99%E3%82%A7%E3%83%83%E3%83%84vs%E5%AE%87%E9%83%BD%E5%AE%AE%E3%83%95%E3%82%99%E3%83%AC%E3%83%83%E3%82%AF%E3%82%B9%E3%80%90%E5%84%AA%E5%8B%9D%E3%80%91.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/08_TV+commercial_%E3%80%90LINELIVE%E3%80%912019%E5%B9%B43%E6%9C%88+TVCM_00%3A00~00%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/10_TV+commercial_%E3%80%90ZOZOTOWN%E3%80%91TVCM%E3%80%8CZOZOSALE_%E3%82%AB%E3%83%BC%E3%83%88%E7%AF%87%E3%80%8D_00%3A00~00%3A15.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/12_LineLive_%E5%90%89%E6%B2%A2%E4%BA%AE+%E3%83%A9%E3%82%A4%E3%83%B3%E3%83%A9%E3%82%A4%E3%83%95%E3%82%99%EF%BC%88LINE+LIVE%EF%BC%89%E3%83%95%E3%82%A1%E3%83%B3%E3%81%AE%E4%BA%BA%E3%81%AB%E7%B5%90%E5%A9%9A%E3%81%97%E3%81%A6%E3%81%8F%E3%81%9F%E3%82%99%E3%81%95%E3%81%84%E3%81%A8%E8%A8%80%E3%82%8F%E3%82%8C%E3%81%A6%E3%81%AE%E5%8F%8D%E5%BF%9C%E3%81%8B%E3%82%99%E5%8F%AF%E6%84%9B%E3%81%84%E3%81%8B%E3%82%99%E3%82%81%E3%81%A3%E3%81%A1%E3%82%83%E3%82%A4%E3%82%B1%E3%83%A1%E3%83%B3Video+Ryo+Yoshizawa_00%3A00~00%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/15_LineLive_%E3%80%90%E6%94%BE%E9%80%81%E4%BA%8B%E6%95%85(%EF%BC%9F)%E3%80%91LINE+LIVE%E3%81%A6%E3%82%99%E3%82%A4%E3%83%98%E3%82%99%E3%83%B3%E3%83%88%E5%8F%82%E5%8A%A0%E8%80%85%E8%A6%8B%E3%81%A6%E3%81%84%E3%81%9F%E3%82%89%E2%80%A6_00%3A30~01%3A00.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/16_LineLive_%E3%80%90LINE+LIVE%E3%80%91+2019%E5%B9%B47%E6%9C%88+%E3%83%A9%E3%82%A4%E3%83%8F%E3%82%99%E3%83%BC%E7%B4%B9%E4%BB%8B_00%3A00~00%3A20.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/17_LineLive_%5BFULL%5D+190214+JAY+%26+JUNE+LINE+LIVE+ON+VALENTINE_S+DAY_02%3A00~02%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/18_LineLive_20210619+linelive+yatsuifes2021+day1+batten+girls_04%3A30~05%3A00.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/25_LiveCommerce_%E3%80%90%E5%B0%8F%E6%9F%84%E3%81%95%E3%82%93%E3%83%95%E3%82%A1%E3%83%83%E3%82%B7%E3%83%A7%E3%83%B3%E3%80%91152cm%E3%81%AEyuki%E3%81%95%E3%82%93%E3%81%8B%E3%82%99JOURNAL+STANDARD%E3%81%AE%E6%96%B0%E4%BD%9C%E3%81%A6%E3%82%99%E3%82%B3%E3%83%BC%E3%83%86%E3%82%99%E3%82%A3%E3%83%8D%E3%83%BC%E3%83%88%EF%BC%81_01%3A30~02%3A00.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/26_LiveCommerce_%E3%80%90Uniqlo+U%E3%80%91%E7%99%BA%E5%A3%B2%E5%BD%93%E6%97%A5%EF%BC%81%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%AA%E3%82%B9%E3%83%88%E3%81%8B%E3%82%99%E9%81%B8%E3%82%93%E3%81%9F%E3%82%99%E6%9C%AC%E6%B0%97%E3%81%AA%E3%83%AA%E3%82%A2%E3%83%AB%E8%B2%B7%E3%81%84%E3%82%A2%E3%82%A4%E3%83%86%E3%83%A0%E5%BE%B9%E5%BA%95%E3%83%AC%E3%83%9B%E3%82%9A%E3%83%BC%E3%83%88%EF%BC%81_14%3A00~14%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/27_LiveCommerce_%E3%80%90%E3%82%BF%E3%82%99%E3%82%B5%E3%81%84%E6%9C%8D%E3%83%98%E3%82%99%E3%82%B9%E3%83%885%E3%80%91%E3%82%84%E3%81%A3%E3%81%A6%E3%82%8B%E4%BA%BA%E3%81%AF%E8%A6%81%E6%B3%A8%E6%84%8F%EF%BC%81%E7%B5%B6%E5%AF%BE%E3%81%AB%E5%A4%B1%E6%95%97%E3%81%99%E3%82%8B%E3%82%A4%E3%82%B1%E3%81%A6%E3%81%AA%E3%81%84%E7%9D%80%E3%81%93%E3%81%AA%E3%81%97_02%3A30~03%3A00.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/28_runway_TGC+SPECIAL+COLLECTION%EF%BD%9C%E7%AC%AC32%E5%9B%9E+%E3%83%9E%E3%82%A4%E3%83%8A%E3%83%92%E3%82%99+%E6%9D%B1%E4%BA%AC%E3%82%AB%E3%82%99%E3%83%BC%E3%83%AB%E3%82%B9%E3%82%99%E3%82%B3%E3%83%AC%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3+2021+SPRING%3ASUMMER_01%3A30~02%3A00.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/29_runway_AEON+CARD+SPECIAL+STAGE%EF%BD%9C%E7%AC%AC32%E5%9B%9E+%E3%83%9E%E3%82%A4%E3%83%8A%E3%83%92%E3%82%99+%E6%9D%B1%E4%BA%AC%E3%82%AB%E3%82%99%E3%83%BC%E3%83%AB%E3%82%B9%E3%82%99%E3%82%B3%E3%83%AC%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3+2021+SPRING%3ASUMMER_01%3A00~01%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/30_runway_TGC+SPECIAL+COLLECTION+2%EF%BD%9C%E3%83%9E%E3%82%A4%E3%83%8A%E3%83%92%E3%82%99+presents+%E7%AC%AC25%E5%9B%9E+%E6%9D%B1%E4%BA%AC%E3%82%AB%E3%82%99%E3%83%BC%E3%83%AB%E3%82%B9%E3%82%99%E3%82%B3%E3%83%AC%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3+2017+AUTUMN_05%3A00~05%3A30.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/%E9%9F%93%E5%8A%87.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/130385be712ebeef0c5f9d513dcb062b.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/BV_3000k.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/JUSTA_tid_0000999001_m.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/%E4%BD%A0%E7%9A%84%E5%90%8D%E5%AD%97.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/%E5%8F%A4%E5%A2%93%E5%A5%87%E5%85%B5.mp4`,
    `${CN_VIDEOS_ENDPOINT}/video+demo/%E5%87%BA%E7%A5%9E%E5%85%A5%E5%8C%962.mp4`,
]

export const TEST_VIDEOS = [
    ...CN_VIDEOS,
    `${QA_VIDEOS_ENDPOINT}/vip-howtowearit-3-eland.mp4`,
    `${QA_VIDEOS_ENDPOINT}/vip-howtowearit-1-prich.mp4`,
    `${QA_VIDEOS_ENDPOINT}/vip-7.18-caroline-female-special-7.mp4`,
    // `${QA_VIDEOS_ENDPOINT}/vip-7.18-eland-female-classic-.mp4`,
    `${QA_VIDEOS_ENDPOINT}/vip-7.18-eland-female-classic-5.mp4`,

    // `${QA_VIDEOS_ENDPOINT}/24-hours-with-rina-sawayama-in-shanghai.mp4`,
    // `${QA_VIDEOS_ENDPOINT}/73-questions-with-gisele-bundchen-ft-tom-brady-vogue-trim.mp4`,
    `${QA_VIDEOS_ENDPOINT}/amanda-steele-streetwear-style-guide.mp4`,
    `${QA_VIDEOS_ENDPOINT}/at-home-with-lara-pia-arrobio-the-creative-director-behind-lpa.mp4`,
    `${QA_VIDEOS_ENDPOINT}/bella-hadid-goes-sneaker-shopping-with-complex-trim.mp4`,
    // `${QA_VIDEOS_ENDPOINT}/cl-interview-pt2.mp4`,
    // `${QA_VIDEOS_ENDPOINT}/cl-interview.mp4`,
    `${QA_VIDEOS_ENDPOINT}/cosy-winter-lookbook.mp4`,
    `${QA_VIDEOS_ENDPOINT}/cp-diary-paris-fashion-week-2018-teaser-ft.-christina-paik.mp4`,
    `${QA_VIDEOS_ENDPOINT}/cp-short-interview.mp4`,
    `${QA_VIDEOS_ENDPOINT}/daily-look.mp4`,
    `${QA_VIDEOS_ENDPOINT}/ellen-and-first-lady-michelle-obama-go-to-cvs-trim.mp4`,
    `${QA_VIDEOS_ENDPOINT}/ellen-oprah-take-over-a-grocery-store-part-1-trim.mp4`,
    `${QA_VIDEOS_ENDPOINT}/emily-oberg-gives-us-a-bts-look-at-the-kith-sport-nyfw-show.mp4`,
    `${QA_VIDEOS_ENDPOINT}/fall-outlook.mp4`,
    `${QA_VIDEOS_ENDPOINT}/high-jumper-cecilia-yeung-is-a-goddess-in-our-nike-lookbook.mp4`,
    `${QA_VIDEOS_ENDPOINT}/hongdae-street-style.mp4`,
    `${QA_VIDEOS_ENDPOINT}/how-luxury-streetwear-shaped-calabasas-style-out-there-refinery29-trim.mp4`,
    // `${QA_VIDEOS_ENDPOINT}/how-luxury-streetwear-shaped-calabasas-style-out-there-refinery29-trim2.mp4`,
    `${QA_VIDEOS_ENDPOINT}/hypebae-1.mp4`,
    // `${QA_VIDEOS_ENDPOINT}/hypebae-2.mp4`,
    // `${QA_VIDEOS_ENDPOINT}/hypebae-summer-2016-fashion-lookbook.mp4`,
    `${QA_VIDEOS_ENDPOINT}/hypebaes-fallwinter-2017-xxl-editorial.mp4`,
    // `${QA_VIDEOS_ENDPOINT}/i-d-meets-charli-xcx.mp4`,
    // `${QA_VIDEOS_ENDPOINT}/i-d-meets-elle-fanning-x-nicolas-winding-refn.mp4`,
    `${QA_VIDEOS_ENDPOINT}/i-d-meets-lorde.mp4`,
    // `${QA_VIDEOS_ENDPOINT}/i-d-meets-new-skater-girls.mp4`,
    // `${QA_VIDEOS_ENDPOINT}/iamkareno-gets-ready-for-the-music-festival-season.mp4`,
    `${QA_VIDEOS_ENDPOINT}/jusuf-winter-look-2017.mp4`,
    `${QA_VIDEOS_ENDPOINT}/kozue-akimoto-is-the-japanese-it-girl-you-need-to-know.mp4`,
    `${QA_VIDEOS_ENDPOINT}/lauren-tsai-gives-us-a-tokyo-city-guide.mp4`,
    `${QA_VIDEOS_ENDPOINT}/liya-on-liya.mp4`,
    `${QA_VIDEOS_ENDPOINT}/lookbook.mp4`,
    // `${QA_VIDEOS_ENDPOINT}/loreal-paris-presents-soo-joo-barbara-in-paris.mp4`,
    // `${QA_VIDEOS_ENDPOINT}/maye-musk-is-introduced-to-supreme-at-age-70.mp4`,
    `${QA_VIDEOS_ENDPOINT}/men-winter-outfit.mp4`,
    `${QA_VIDEOS_ENDPOINT}/men-zara-lookbook.mp4`,
    // `${QA_VIDEOS_ENDPOINT}/missalex-winter-outfit.mp4`,
    `${QA_VIDEOS_ENDPOINT}/ny-winter-outfit-2017.mp4`,
    `${QA_VIDEOS_ENDPOINT}/office-lookbook-professional-outfit-ideas!-trim.mp4`,
    `${QA_VIDEOS_ENDPOINT}/parisian-style-outfits-french-chic-lookbook-trim.mp4`,
    `${QA_VIDEOS_ENDPOINT}/real-lies--one-club-town-(official-video).mp4`,
    `${QA_VIDEOS_ENDPOINT}/sofia-vergara-thug-moments-trim.mp4`,
    // `${QA_VIDEOS_ENDPOINT}/sofia-vergara-thug-moments-trim2.mp4`,
    `${QA_VIDEOS_ENDPOINT}/spring-lookbook.mp4`,
    `${QA_VIDEOS_ENDPOINT}/spring-outfit.mp4`,
    `${QA_VIDEOS_ENDPOINT}/springwinter-thrifted-lookbook.mp4`,
    `${QA_VIDEOS_ENDPOINT}/summer-work-wear.mp4`,
    `${QA_VIDEOS_ENDPOINT}/thelineup-shows-us-how-to-style-tracksuits.mp4`,
    `${QA_VIDEOS_ENDPOINT}/tokyo-girls-2018-springsummer.mp4`,
    // `${QA_VIDEOS_ENDPOINT}/zolita-the-rising-queer-icon-you-need-to-know.mp4`,

    `https://res.cloudinary.com/dksvnv29q/video/upload/v1581978290/ijp36pbxguudoihk9bgi.mov`,
    `https://res.cloudinary.com/dksvnv29q/video/upload/v1581978290/ijp36pbxguudoihk9bgi.mov`,
    `https://markable-qa.s3.amazonaws.com/videos/rui-test-data/williams.mp4`,
    `https://markable-qa.s3.amazonaws.com/videos/video_qa_LiveMe/3a4ec35af7b17298ebbe1a27a32496d0.mp4`,
    `https://markable-qa.s3.amazonaws.com/videos/video_qa_LiveMe/0374d84965adf67c144d7de5fd6a1071.mp4`,
    `https://markable-qa.s3.amazonaws.com/videos/cl-interview-pt2.mp4`,
    `https://markable-qa.s3.amazonaws.com/videos/ellen-and-first-lady-michelle-obama-go-to-cvs-trim.mp4`,
    `https://markable-qa.s3.amazonaws.com/videos/hypebae-2.mp4`,
]

export const DEMO_VIDEOS = [

    // vip 2
    // `${location.origin}/static/videos/vip_2.mp4`,
    // 'https://www.youtube.com/watch?v=Ed6L5GugkcQ',
    // 'https://d3vv6lp55qjaqc.cloudfront.net/items/171R2R0x071W1B431r44/vip_2.mp4',

    // vip 1
    // `${location.origin}/static/videos/vip_1.mp4`,
    // 'https://www.youtube.com/watch?v=KB61lPDqfNE',
    // 'https://s3.amazonaws.com/data-operations-sallie-gardner/tmp/VIP_1.mp4',

    // 'https://s3.amazonaws.com/markable-training-data/feb-2017-demo-videos/ootw-short2.mp4',

    // good demo (general):
    // 'http://qa.markable.ai/videos/ny-winter-outfit-2017.mp4',

    // good demo (general):
    // 'http://qa.markable.ai/videos/i-d-meets-lorde.mp4',

    // good demo for `glasses`:
    // 'http://qa.markable.ai/videos/real-lies--one-club-town-(official-video).mp4',

    // good demo for `jackets`:
    // 'http://qa.markable.ai/videos/parisian-style-outfits-french-chic-lookbook-trim.mp4',

    // 'https://www.youtube.com/watch?v=ABz2m0olmPg',


    ...TEST_VIDEOS,
]

export const SAMPLE_VIDEO_SETS = [{
        name: 'yahoo',
        videos: [{
                name: 'test-1',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-1/output-1m-000.mp4`,
                index: 0,
            },
            {
                name: 'test-1',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-1/output-1m-001.mp4`,
                index: 1,
            },
            {
                name: 'test-1',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-1/output-1m-002.mp4`,
                index: 2,
            },
            {
                name: 'test-1',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-1/output-1m-003.mp4`,
                index: 3,
            },
            {
                name: 'test-1',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-1/output-1m-004.mp4`,
                index: 4,
            },
            {
                name: 'test-1',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-1/output-1m-005.mp4`,
                index: 5,
            },
            {
                name: 'test-1',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-1/output-1m-006.mp4`,
                index: 6,
            },
            {
                name: 'test-1',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-1/output-1m-007.mp4`,
                index: 7,
            },
            {
                name: 'test-1',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-1/output-1m-008.mp4`,
                index: 8,
            },
            {
                name: 'test-1',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-1/output-1m-009.mp4`,
                index: 9,
            },
            {
                name: 'test-1',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-1/output-1m-010.mp4`,
                index: 10,
            },
            {
                name: 'test-1',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-1/output-1m-011.mp4`,
                index: 11,
            },
            {
                name: 'test-1',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-1/output-1m-012.mp4`,
                index: 12,
            },
            {
                name: 'test-1',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-1/output-1m-013.mp4`,
                index: 13,
            },
            {
                name: 'test-1',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-1/output-1m-014.mp4`,
                index: 14,
            },
            {
                name: 'test-1',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-1/output-1m-015.mp4`,
                index: 15,
            },

            // test-2

            {
                name: 'test-2',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-2/output-1m-000.mp4`,
                index: 0,
            },
            {
                name: 'test-2',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-2/output-1m-001.mp4`,
                index: 1,
            },
            {
                name: 'test-2',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-2/output-1m-002.mp4`,
                index: 2,
            },
            {
                name: 'test-2',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-2/output-1m-003.mp4`,
                index: 3,
            },
            {
                name: 'test-2',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-2/output-1m-004.mp4`,
                index: 4,
            },
            {
                name: 'test-2',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-2/output-1m-005.mp4`,
                index: 5,
            },
            {
                name: 'test-2',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-2/output-1m-006.mp4`,
                index: 6,
            },
            {
                name: 'test-2',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-2/output-1m-007.mp4`,
                index: 7,
            },
            {
                name: 'test-2',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-2/output-1m-008.mp4`,
                index: 8,
            },
            {
                name: 'test-2',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-2/output-1m-009.mp4`,
                index: 9,
            },
            {
                name: 'test-2',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-2/output-1m-010.mp4`,
                index: 10,
            },
            {
                name: 'test-2',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-2/output-1m-011.mp4`,
                index: 11,
            },
            {
                name: 'test-2',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-2/output-1m-012.mp4`,
                index: 12,
            },
            {
                name: 'test-2',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-2/output-1m-013.mp4`,
                index: 13,
            },
            {
                name: 'test-2',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-2/output-1m-014.mp4`,
                index: 14,
            },
            {
                name: 'test-2',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-2/output-1m-015.mp4`,
                index: 15,
            },
            {
                name: 'test-2',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-2/output-1m-016.mp4`,
                index: 16,
            },
            {
                name: 'test-2',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-2/output-1m-017.mp4`,
                index: 17,
            },
            {
                name: 'test-2',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-2/output-1m-018.mp4`,
                index: 18,
            },
            {
                name: 'test-2',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-2/output-1m-019.mp4`,
                index: 19,
            },

            // test-3

            {
                name: 'test-3',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-3/output-1m-000.mp4`,
                index: 0,
            },
            {
                name: 'test-3',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-3/output-1m-001.mp4`,
                index: 1,
            },
            {
                name: 'test-3',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-3/output-1m-002.mp4`,
                index: 2,
            },
            {
                name: 'test-3',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-3/output-1m-003.mp4`,
                index: 3,
            },
            {
                name: 'test-3',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-3/output-1m-004.mp4`,
                index: 4,
            },
            {
                name: 'test-3',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-3/output-1m-005.mp4`,
                index: 5,
            },
            {
                name: 'test-3',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-3/output-1m-006.mp4`,
                index: 6,
            },
            {
                name: 'test-3',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-3/output-1m-007.mp4`,
                index: 7,
            },
            {
                name: 'test-3',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-3/output-1m-008.mp4`,
                index: 8,
            },
            {
                name: 'test-3',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-3/output-1m-009.mp4`,
                index: 9,
            },
            {
                name: 'test-3',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-3/output-1m-010.mp4`,
                index: 10,
            },
            {
                name: 'test-3',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-3/output-1m-011.mp4`,
                index: 11,
            },
            {
                name: 'test-3',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-3/output-1m-012.mp4`,
                index: 12,
            },
            {
                name: 'test-3',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-3/output-1m-013.mp4`,
                index: 13,
            },
            {
                name: 'test-3',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-3/output-1m-014.mp4`,
                index: 14,
            },
        ],
    },

    {
        name: 'yahoo-5m',
        videos: [

            // test-1
            {
                name: 'test-1',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-1/output-5m-000.mp4`,
                index: 0,
            },
            {
                name: 'test-1',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-1/output-5m-001.mp4`,
                index: 1,
            },
            {
                name: 'test-1',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-1/output-5m-002.mp4`,
                index: 2,
            },
            {
                name: 'test-1',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-1/output-5m-003.mp4`,
                index: 3,
            },

            // test-2
            {
                name: 'test-2',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-2/output-5m-000.mp4`,
                index: 0,
            },
            {
                name: 'test-2',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-2/output-5m-001.mp4`,
                index: 1,
            },
            {
                name: 'test-2',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-2/output-5m-002.mp4`,
                index: 2,
            },
            {
                name: 'test-2',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-2/output-5m-003.mp4`,
                index: 3,
            },

            // test-3
            {
                name: 'test-3',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-3/output-5m-000.mp4`,
                index: 0,
            },
            {
                name: 'test-3',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-3/output-5m-001.mp4`,
                index: 1,
            },
            {
                name: 'test-3',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-3/output-5m-002.mp4`,
                index: 2,
            },

        ],
    },

    {
        name: 'yahoo-full',
        videos: [

            // test-1
            {
                name: 'test-1',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-1.mp4`,
                index: 0,
            },

            // test-2
            {
                name: 'test-2',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-2.mp4`,
                index: 0,
            },

            // test-3
            {
                name: 'test-3',
                uri: `${QA_VIDEOS_ENDPOINT}/yahoo/test-3.mp4`,
                index: 0,
            },

        ],
    },

    {
        name: 'iqiyi-test-2020-04-25',
        videos: [

            {

                name: `test-0-may-27`,
                uri: `${CN_IQIYI_VIDEOS_ENDPOINT}/AQY-f4v/1.mp4`,
                index: 0
            },
            {
                name: `test-1-may-27`,
                uri: `${CN_IQIYI_VIDEOS_ENDPOINT}/AQY-f4v/2.mp4`,
                index: 1
            },
            {
                name: `test-2-may-27`,
                uri: `${CN_IQIYI_VIDEOS_ENDPOINT}/AQY-f4v/3.mp4`,
                index: 2
            },
            {
                name: `test-3-may-27`,
                uri: `${CN_IQIYI_VIDEOS_ENDPOINT}/AQY-f4v/4.mp4`,
                index: 4
            },
            {
                name: `test-4-may-27`,
                uri: `${CN_IQIYI_VIDEOS_ENDPOINT}/AQY-f4v/5.mp4`,
                index: 5
            },
            {
                name: `test-5-may-27`,
                uri: `${CN_IQIYI_VIDEOS_ENDPOINT}/AQY-f4v/7.mp4`,
                index: 6
            },
            {
                name: `1-00:00-01:30`,
                uri: `${CN_IQIYI_VIDEOS_MINIAPP_ENDPOINT}/1-00-00-00-90.mp4`,
                index: 7
            },
            {
                name: `1-02:16-02:30`,
                uri: `${CN_IQIYI_VIDEOS_MINIAPP_ENDPOINT}/1-00-02-16-14.mp4`,
                index: 8
            },
            {
                name: `1-03:23-05:43`,
                uri: `${CN_IQIYI_VIDEOS_MINIAPP_ENDPOINT}/1-00-03-23-140.mp4`,
                index: 9
            },
            {
                name: `3-00:35-00:45`,
                uri: `${CN_IQIYI_VIDEOS_MINIAPP_ENDPOINT}/3-00-00-35-10.mp4`,
                index: 10
            },
            {
                name: `3-02:02-02:14`,
                uri: `${CN_IQIYI_VIDEOS_MINIAPP_ENDPOINT}/3-00-02-02-12.mp4`,
                index: 11
            },
            {
                name: `3-02:34-02:40`,
                uri: `${CN_IQIYI_VIDEOS_MINIAPP_ENDPOINT}/3-00-02-34-6.mp4`,
                index: 12
            },
            {
                name: `3-03:09-03:21`,
                uri: `${CN_IQIYI_VIDEOS_MINIAPP_ENDPOINT}/3-00-03-09-12.mp4`,
                index: 13
            },
            {
                name: `3-03:54-04:59`,
                uri: `${CN_IQIYI_VIDEOS_MINIAPP_ENDPOINT}/3-00-03-54-65.mp4`,
                index: 14
            },
            {
                name: `4-00:00-00:25`,
                uri: `${CN_IQIYI_VIDEOS_MINIAPP_ENDPOINT}/4-00-00-00-25.mp4`,
                index: 15
            },
            {
                name: `4-00:36-00:49`,
                uri: `${CN_IQIYI_VIDEOS_MINIAPP_ENDPOINT}/4-00-00-36-13.mp4`,
                index: 16
            },
            {
                name: `4-01:37-02:35`,
                uri: `${CN_IQIYI_VIDEOS_MINIAPP_ENDPOINT}/4-00-01-37-58.mp4`,
                index: 17
            },
            {
                name: `4-03:38-03:51`,
                uri: `${CN_IQIYI_VIDEOS_MINIAPP_ENDPOINT}/4-00-03-38-13.mp4`,
                index: 18
            },
            {
                name: `5-00:00-00:14`,
                uri: `${CN_IQIYI_VIDEOS_MINIAPP_ENDPOINT}/5-00-00-00-14.mp4`,
                index: 19
            },
            {
                name: `5-04:44-05:33`,
                uri: `${CN_IQIYI_VIDEOS_MINIAPP_ENDPOINT}/5-00-04-44-49.mp4`,
                index: 20
            },
            {
                name: `7-00:17-00:31`,
                uri: `${CN_IQIYI_VIDEOS_MINIAPP_ENDPOINT}/7-00-00-17-14.mp4`,
                index: 21
            },
            {
                name: `7-01:22-02:01`,
                uri: `${CN_IQIYI_VIDEOS_MINIAPP_ENDPOINT}/7-00-01-22-39.mp4`,
                index: 22
            },
            {
                name: `7-02:10-02:16`,
                uri: `${CN_IQIYI_VIDEOS_MINIAPP_ENDPOINT}/7-00-02-10-6.mp4`,
                index: 23
            },
            {
                name: `7-05:05-05:33`,
                uri: `${CN_IQIYI_VIDEOS_MINIAPP_ENDPOINT}/7-00-05-05-28.mp4`,
                index: 24
            },
            {
                name: `7-06:00-06:31`,
                uri: `${CN_IQIYI_VIDEOS_MINIAPP_ENDPOINT}/7-00-06-00-31.mp4`,
                index: 25
            }
        ]
    }
]