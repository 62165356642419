
/* =========================================
      IMPORTS
-------------------------------------- */

import classnames from 'classnames'
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class DemoAttributesModal extends Component {
    state = {
        show: false,
    }

    render() {
        const { show, attributes, category } = this.props

        if(!show || attributes.length === 0) {
            return null;
        }

        return (

            <div className={classnames('AttributesDiv')}>
                <div style={{paddingBottom: 10}}>Track: {category.toUpperCase()}</div>
                {
                    attributes.map((attribute) => {
                        return (
                            <div key={attributes[attribute.name]} style={{paddingBottom: 10}}>
                                {
                                    attribute.name.toUpperCase()
                                }
                                {
                                    attribute.values.map((value) => {
                                        return (
                                            <div key={attribute[value.score]}>
                                                <div>score: {value.score.toPrecision(3)}</div>
                                                <div>value: {value.value}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}


DemoAttributesModal.propTypes = {
    name: PropTypes.string,
    values: PropTypes.array,
}

DemoAttributesModal.defaultProps = {
    name: undefined,
    values: undefined,
}

export default DemoAttributesModal
