
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'


/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/DemoServerProcessJob')



/* =========================================
      COMPONENTS
-------------------------------------- */

const DemoServerProcessJob = (props) => {
    // const queue = props.queue
    const queueID = props.queue.id || props.queue.key || props.queue.name

    const job = props.job
    // const jobID = job.id || encodeURIComponent(job.uri || `${job}`)

    job.status = queueID

    // REFACTOR: move to parent container
    // const id = `DemoServerProcessJob-${jobID}`

    const id = props.id
    const className = props.className

    const hidden = !!props.hidden

    return (
        <div className={classnames(`DemoServerProcessJob`, className, {hidden})}
            id={id}

            data-id={job.id}
            data-status={job.status}
        >

            <div className={classnames('DemoServerProcessJob-content')}>
                { JSON.stringify(job) }
            </div>
        </div>
    )
}

DemoServerProcessJob.propTypes = {
    queue: PropTypes.object,
    job: PropTypes.object,

    hidden: PropTypes.bool,
}


DemoServerProcessJob.defaultProps = {
    // job: undefined,
    hidden: false,
}


/* =========================================
      EXPORT
-------------------------------------- */

export default DemoServerProcessJob
