
/* =========================================
      IMPORTS
-------------------------------------- */

import _ from 'lodash'


/* =========================================
      FUNCTIONS
-------------------------------------- */

export const findMatchingVideoFrames = (frames, currentTime = 0, backtrackTime, forwardTrack = 0) => {
    frames = frames || []
    frames = Array.isArray(frames) ? frames : []

    return frames
        .sort((f1, f2) => {
            if (f1.detect) {
                return (f1.detect.frame.frameTimestamp - f2.detect.frame.frameTimestamp)

            } else {
                return (f1.timestamp - f2.timestamp)
            }
        })
        .filter((result, i) => {
            if (result.detect) {
                // console.log('filter',
                //     `${result.detect.frame.frameTimestamp} >= ${(currentTime - backtrackTime)}`,
                //     `${result.detect.frame.frameTimestamp} <= ${(currentTime)}`,
                //     result.detect.frame)

                return (
                    result.detect.frame.frameTimestamp >= (currentTime - backtrackTime)
                    &&
                    result.detect.frame.frameTimestamp <= (currentTime + forwardTrack)
                )

            } else {
                // console.log('filter',
                //     `${result.timestamp} >= ${(currentTime - backtrackTime)}`,
                //     `${result.timestamp} <= ${(currentTime + forwardTrack)}`,
                //     result)

                return (
                    (result.timestamp) >= (currentTime - backtrackTime)
                    &&
                    (result.timestamp) <= (currentTime + forwardTrack)
                )
            }
        })
}

export const getVideoFramesWithUniqueMatches = (frames) => {
    frames = frames || []
    frames = Array.isArray(frames) ? frames : []

    let globalMatchIds = []

    return frames
        .reverse()
        .map((frame) => {
            if (frame.detect) {
                return {
                    ...frame,
                    data: (frame.data || [])
                        .map((result) => {
                            return {
                                ...result,
                                matches: _.uniqBy(result.matches || [], (match) => match.id)
                                    .slice(0, 1) // first match only
                                    .map((match) => {
                                        return {
                                            ...match,

                                            // tracks how many times this specific match is occuring in all frames
                                            _index: (() => {
                                                // const i = globalMatchIds.includes(match.image.uri)
                                                const i = globalMatchIds.filter(id => `${id}` === `${match.id}`).length
                                                // console.log(i, match.id)
                                                globalMatchIds.push(match.id)
                                                return i
                                            })()
                                        }
                                    })
                                    .map((match) => {
                                        // console.log(after', match)
                                        return match
                                    })
                            }
                        })
                        .map((result) => {
                            // console.log(after', result)
                            return result
                        })
                }

            } else {
                return {
                    ...frame,
                    results: (frame.results || [])
                        .map((result) => {
                            return {
                                ...result,
                                matches: _.uniqBy(result.matches || [], (match) => match._id)
                                    .slice(0, 1) // first match only
                                    .map((match) => {
                                        return {
                                            ...match,

                                            // tracks how many times this specific match is occuring in all frames
                                            _index: (() => {
                                                // const i = globalMatchIds.includes(match.image.uri)
                                                const i = globalMatchIds.filter(id => `${id}` === `${match._id}`).length
                                                // console.log(i, match._id)
                                                globalMatchIds.push(match._id)
                                                return i
                                            })()
                                        }
                                    })
                                    .map((match) => {
                                        // console.log(after', match)
                                        return match
                                    })
                            }
                        })
                        .map((result) => {
                            // console.log(after', result)
                            return result
                        })
                }
            }
        })
        .reverse()
        .map((frames) => {
            // console.log('after', frames)
            return frames
        })
}


/* =========================================
      EXPORT
-------------------------------------- */

export default {
    findMatchingVideoFrames,
    getVideoFramesWithUniqueMatches,
}
