
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import shallowEqual from 'react-fast-compare'


/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/DemoPlayer/LensVideoOverlayCategories')


/* =========================================
      COMPONENTS
-------------------------------------- */

class LensVideoOverlayCategories extends Component {

    shouldComponentUpdate (nextProps) {
        const { props } = this

        if (shallowEqual(props, nextProps)) {
            return false
        }

        return true
    }

    render () {
        const { props } = this

        const colors = props.colors || []

        const tracks = props.tracks || []
        const results = props.results || []

        let colorIndex = 0

        const categoryStats = results
            .map((result, i) => {
                const category = result.category || {}
                const name = category.name || category._id || category
                // const index = i

                // REFACTOR: not needed later when (top-)track IDs are generated server-side
                const resultTrack = tracks.find((track) => {
                    return track.id === (result.matches[0] || {})._id
                }) || {}

                const color = resultTrack.color

                return {
                    result,
                    // index,
                    name,
                    color,
                }
            })
            .reduce((stat, category) => {
                let index = colorIndex++
                let count = 1

                if (typeof stat[category.name] === 'object') {
                    count = stat[category.name].count + 1
                    index = stat[category.name].index
                }

                stat[category.name] = {
                    ...category,
                    index,
                    count,
                }

                return stat
            }, {})

        return (
            <div className={classnames('LensVideoOverlayCategories')}
                onMouseOver={(event) => {
                    if (typeof props.onMouseOver !== 'function') {
                        return
                    }

                    props.onMouseOver(event)
                }}
                onMouseOut={(event) => {
                    if (typeof props.onMouseOut !== 'function') {
                        return
                    }

                    props.onMouseOut(event)
                }}
            >
                {
                    Object.entries(categoryStats).map(([categoryName, categoryObject], i) => {
                        const key = `LensVideoOverlayCategories-category-${categoryName}`

                        return (
                            <div key={key} id={key}
                                className={classnames(`LensVideoOverlayCategories-category`)}

                                onMouseOver={(event) => {
                                    if (typeof props.onResultActive !== 'function') {
                                        return
                                    }

                                    const args = results
                                        .map((result, index) => {
                                            const id = index

                                            return {
                                                ...result,
                                                id,
                                            }
                                        })
                                        .filter((result) => {
                                            const category = result.category

                                            return (category.name || category._id) === categoryName
                                        })

                                    props.onResultActive(...args)
                                }}

                                onMouseOut={(event) => {
                                    if (typeof props.onResultInactive !== 'function') {
                                        return
                                    }

                                    const result = categoryObject.result
                                    const id = categoryObject.index

                                    props.onResultInactive({
                                        ...result,
                                        id,
                                    })
                                }}
                            >
                                <span className={classnames(`LensVideoOverlayCategories-category-name`)}>
                                    { categoryName.replace(/[\W_]/gi, ' / ') }
                                </span>

                                {
                                    [...Array(categoryObject.count).keys()].map((j) => {
                                        const key = `LensVideoOverlayCategories-category-${categoryName}-bullet-${j}`
                                        const color = categoryObject.color || colors[categoryObject.index + j]

                                        return (
                                            <span key={key} id={key}
                                                className={classnames(`LensVideoOverlayCategories-category-bullet`)}
                                                style={{
                                                    background: color,
                                                }}
                                            >
                                            </span>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

LensVideoOverlayCategories.propTypes = {
    colors: PropTypes.array,

    tracks: PropTypes.array,
    results: PropTypes.array,

    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,

    onResultActive: PropTypes.func,
    onResultInactive: PropTypes.func,
}

LensVideoOverlayCategories.defaultProps = {
    colors: [],

    tracks: [],
    results: [],

    // onMouseOver: (event) => log(`onMouseOver`),
    // onMouseOut: (event) => log(`onMouseOut`),

    // onResultActive: (event) => log(`onResultActive`),
    // onResultInactive: (event) => log(`onResultInactive`),
}


/* =========================================
      EXPORT
-------------------------------------- */

export default LensVideoOverlayCategories
