
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'

import {
    API_ENDPOINT,
} from '../constants'


/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/services/videoCV')


/* =========================================
      FUNCTIONS
-------------------------------------- */

export async function track (frames, options = {}) {
    options = options || {}

    if (typeof options.force !== 'boolean') {
        options.force = false
    }

    if (typeof options.transform !== 'boolean') {
        options.transform = true
    }

    if (typeof options.tracks_limit !== 'number') {
        options.tracks_limit = null
    }

    // REFACTOR: create internal `Request` object, for debugging reasons
    const videoCV = {
        request: {
            url: `//${API_ENDPOINT}.ai:1332`, // REFACTOR: move to config
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authentication': undefined,
            },
            body: {
                method: 'track',
                options,
            },
        },
        response: undefined,
    }

    // log('REQUEST', {videoCV})

    try {
        const fetchResponse = await fetch(videoCV.request.url, {
            method: videoCV.request.method,
            headers: videoCV.request.headers,
            body: JSON.stringify(videoCV.request.body),
            mode: 'cors',
            cache: 'default',
            redirect: 'follow',
            referrer: 'no-referrer',
            // credentials: 'omit',
        })

        const request = {...videoCV.request}
        const response = {}

        response.status = fetchResponse.status
        response.headers = (fetchResponse.headers || {})._headers || fetchResponse.headers
        response.body = await fetchResponse.json()

        // log(response)

        const result = {
            request,
            response,
        }

        // log('REQUEST + RESPONSE', result)

        return result

    } catch (error) {
        throw error
    }
}


/* =========================================
      EXPORT
-------------------------------------- */

export default {
    track,
}
