
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React from 'react'
import { render } from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import {
  // BrowserRouter as Router,
  HashRouter as Router,
  Route,
} from 'react-router-dom'

import App from './ui/App'
import Player from './ui/Player'


/* =========================================
      LOGGER
-------------------------------------- */

debug.enable('src*')

const log = debug('src/index')


/* =========================================
      MAIN
-------------------------------------- */

log('initializing...')

const root = document.getElementById('root')

const load = () => {
    return render(
        <AppContainer>
            <Router>
                <div>
                    <Route exact path="/player" component={Player} />
                    <Route exact path="/" component={App} />
                </div>
            </Router>
        </AppContainer>
    , root)
}

if (module.hot) {
  module.hot.accept('./ui/App', load)
}

load()

log('done')
