
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import {
    detectBandwidth,
} from '../../../utils/internet'

import {
    UPTIME_PIXEL_URL,
} from '../../../constants'


window.detectBandwidth = detectBandwidth

/* =========================================
      CONSTANTS
-------------------------------------- */

const DEFAULT_BANDWIDTH_METRICS = {
    start: undefined,
    firstByte: undefined,
    url: undefined,
    dataSizeKB: undefined,
    end: undefined,
    latency: undefined,
    throughput: undefined,
    throughputClass: {
        name: undefined,
        latency: undefined,
        throughput: undefined,
    },
    latencyClass: {
        name: undefined,
        latency: undefined,
        throughput: undefined,
    },
}

// const CHECK_BANDWIDTH_URL = 'http://ashanbh.github.io/images/coffee-apple-iphone-laptop.jpg'
const CHECK_BANDWIDTH_URL = '/static/videos/vip_1.json'


/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/system/InternetStatus')


/* =========================================
      COMPONENTS
-------------------------------------- */

class InternetStatus extends PureComponent {

    state = {
        internetAvailable: true,
        internetQualityAvailable: true,
    }

    get status () {
        if (typeof navigator.onLine === 'boolean') {
            return !!navigator.onLine

        } else {
            return !!this.state.internetAvailable
        }
    }

    get quality () {
        return !!this.state.internetQualityAvailable
    }

    get metrics () {
        return this.state.internetMetrics || {}
    }

    componentDidMount () {
        const { props } = this
        const { disabled, interval, bandwidthDelay, bandwidthInterval } = props

        const supportsOnlineAPI = (typeof navigator.onLine === 'boolean')

        setTimeout(this.onCheckInternetStatus)

        if (!supportsOnlineAPI) {
            this.intervalCheckInternetStatus = setInterval(() => {
                if (!disabled) {
                    this.onCheckInternetStatus()
                }
            }, interval || 3000)
        }

        setTimeout(this.onCheckInternetBandwidth, bandwidthDelay || 1000)

        this.intervalCheckInternetBandwidth = setInterval(() => {
            if (!disabled && this.status) {
                this.onCheckInternetBandwidth()
            }
        }, bandwidthInterval || (10 * 1000))

        window.InternetStatus = this
    }

    componentWillUnmount () {
        clearInterval(this.intervalCheckInternetStatus)
        clearInterval(this.intervalCheckInternetBandwidth)
    }

    render () {
        const { props } = this

        const {
            id,
            className,
            style,

            hidden,

            children,
        } = props

        let {
            label,
            value,
        } = props

        label = (label === true) ? 'Internet' : label
        value = (value === true) ? `${this.status}` : value

        const statusValueText = this.status ? 'yes' : 'no'
        const statusValueMetaObject = this.metrics.latencyClass || {}
        const statusValueMetaName = statusValueMetaObject.name
        const statusValueMetaThroughput = statusValueMetaObject.throughput ? Math.round(statusValueMetaObject.throughput / 1000) : undefined
        const statusValueMetaText = `${statusValueMetaName} ~ ${statusValueMetaThroughput || '?'} MBPS`

        return (
            <div id={id}
                className={classnames('InternetStatus', 'Status', className, {hidden})}
                style={style}

                data-status={this.status}
                data-quality={this.quality}

                title={`Available: ${statusValueText} (${statusValueMetaText})`}
            >
                <div className={classnames('Status-content')}>
                    <div className={classnames('Status-label')}>
                        { children || label || `${this.status}` }
                    </div>

                    <div className={classnames('Status-value')}>
                        { value }
                    </div>
                </div>
            </div>
        )
    }

    onCheckInternetStatus = async () => {
        // log('onCheckInternetStatus')

        const { props } = this

        let internetAvailable = true

        const supportsOnlineAPI = (typeof navigator.onLine === 'boolean')

        if (supportsOnlineAPI) {
            internetAvailable = !!navigator.onLine

        } else {
            try {
                await fetch(UPTIME_PIXEL_URL, {
                    mode: 'cors',
                })

            } catch (error) {
                internetAvailable = false
            }
        }

        // if (!internetAvailable) {
        //     console.warn('INTERNET NOT AVAILABLE')
        // }

        if (typeof props.onStatusChange === 'function') {
            if (internetAvailable !== this.state.internetAvailable) {
                props.onStatusChange(internetAvailable)
            }
        }

        this.setState({
            internetAvailable,
        })
    }

    onCheckInternetBandwidth = async () => {
        // log('onCheckInternetBandwidth')

        const { props } = this

        let internetMetrics = DEFAULT_BANDWIDTH_METRICS

        try {
            internetMetrics = await detectBandwidth(CHECK_BANDWIDTH_URL)

        } catch (error) {}

        // console.warn('INTERNET METRICS', internetMetrics)

        const currentLatencyClass = (internetMetrics || {}).latencyClass || {}

        if (typeof props.onStatusChange === 'function') {
            const previousLatencyClass = (this.state.internetMetrics || {}).latencyClass || {}

            if (currentLatencyClass !== previousLatencyClass) {
                props.onStatusInfoChange(currentLatencyClass)
            }
        }

        const internetQualityAvailable = (currentLatencyClass.throughput >= 750)

        this.setState({
            internetMetrics,
            internetQualityAvailable,
        })
    }

}

InternetStatus.propTypes = {
    uri: PropTypes.string,

    interval: PropTypes.number,

    bandwidthDelay: PropTypes.number,
    bandwidthInterval: PropTypes.number,

    id: PropTypes.string,
    className: PropTypes.string,
    styles: PropTypes.object,

    label: PropTypes.any,
    value: PropTypes.any,

    hidden: PropTypes.bool,

    onStatusChange: PropTypes.func,
    onStatusInfoChange: PropTypes.func,
}

InternetStatus.defaultProps = {
    uri: undefined,

    interval: undefined,

    bandwidthDelay: undefined,
    bandwidthInterval: undefined,

    id: undefined,
    className: undefined,
    styles: undefined,

    label: true,
    value: false,

    hidden: true,

    onStatusChange: undefined,
    onStatusInfoChange: undefined,
}


/* =========================================
      EXPORT
-------------------------------------- */

export default InternetStatus
