
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { PureComponent } from 'react'
import classnames from 'classnames'


/* =========================================
      ASSETS
-------------------------------------- */

import makableIcon from '../../../../static/markable-octopus-black.svg'


/* =========================================
      STYLES
-------------------------------------- */

import './LensXRay.css'


/* =========================================
      CONSTANTS
-------------------------------------- */

import {
    COLORS,
} from '../../../../constants'

const SHUFFLED_COLORS = [...COLORS].sort(() => .5 - Math.random())


/* =========================================
      LOGGER
-------------------------------------- */

let log = debug('src/ui/components/LensPlayer/layers/LensXRay')


/* =========================================
      COMPONENTS
-------------------------------------- */

class LensXRay extends PureComponent {

    state = {
        show: false,
        topTracks: [],
    }

    componentDidMount () {

    }

    componentWillReceiveProps (nextProps) {
        const { frames } = nextProps

        if (frames && frames.length) {
            this.onGenerateTopMatches(frames)
        }
    }

    // shouldComponentUpdate (nextProps) {
    //     return JSON.stringify(this.props) === JSON.stringify(nextProps)
    //     return (((nextProps || {}).frames || []).length === ((this.props || {}).frames || []).length)
    // }

    render () {
        const {
            // props,
            state,

            onToggleXRayAction,
        } = this

        // let {
        //     onToggle,
        // } = props

        const {
            show,
            topTracks,
        } = state

        return (
            <div className={classnames('LensXRay')}>
                <img className={classnames('LensXRayAction', {'LensXRay-pending': !topTracks.length})}
                    src={makableIcon}
                    alt={`Markable X-Ray`}
                    title={`Markable X-Ray`}

                    onClick={onToggleXRayAction}
                />

                <div className={classnames('LensXRayOverlay', {hidden: !show})}>

                    <div className={`LensXRayOverlayAction-close`}
                        onClick={onToggleXRayAction}
                    >
                        <span>&times;</span>
                    </div>

                    <div className={classnames('LensXRayOverlay-header')}>
                        <div className={classnames('LensXRayOverlay-title')}>
                            <span>Lens</span> <span>X-Ray</span>
                        </div>

                        <div className={classnames('LensXRayOverlay-description')}>
                            This video contains shoppable fashion, see below for the most relevant matches we found.
                        </div>
                    </div>

                    <img className={classnames('LensXRayTopResult-preview')}
                        style={{
                            opacity: this.state.currentTopTrack ? 1 : 0,
                        }}
                        src={this.state.currentTopTrack && `${this.state.currentTopTrack.image.uri}`}
                        alt=""
                    />

                    <div className={classnames('LensXRayTopResults')}>
                        {
                            topTracks.map((topTrack) => {
                                const match = topTrack.matches[0]
                                const matchImage = match.images[0]

                                return (
                                    <a className={classnames('LensXRayTopResult')}
                                        key={`LensXRayTopResult-${topTrack.index}`}
                                        style={{
                                            backgroundImage: `url("${matchImage.uri}")`,
                                        }}

                                        href={match.data.url}
                                        target="_blank"

                                        onMouseEnter={() => {
                                            this.setState({
                                                currentTopTrack: topTrack,
                                            })
                                        }}
                                        onMouseLeave={() => {
                                            this.setState({
                                                currentTopTrack: null,
                                            })
                                        }}
                                    >
                                        <div className={`LensXRayTopResult-details`}>
                                            <div className={`LensXRayTopResult-category`}
                                                style={{
                                                    backgroundColor: `${match.color}`,
                                                }}
                                            >
                                                { `${match.category}` }
                                            </div>

                                            <div className={`LensXRayTopResult-price`}
                                                style={{
                                                    transform: `rotate(${topTrack.rotation}deg)`,
                                                }}
                                            >
                                                { `${parseFloat(match.data.price).toFixed(0)} ${match.data.currency}` }
                                            </div>
                                        </div>
                                    </a>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }

    onToggleXRayAction = (event) => {
        const { props, state } = this
        const { onToggle } = props

        let { show } = state

        show = !show

        if (typeof onToggle === 'function') {
            onToggle(show)
        }

        this.setState({
            show,
        })
    }

    onGenerateTopMatches = async (frames) => {
        log('onGenerateTopMatches')

        frames = frames || []

        let topTracks = []

        let categoryColorMap = {}

        for (const frame of frames) {
            const results = frame.results || []

            for (const result of results) {
                const categoryName = (result.category || {}).name || result.category
                const firstMatch = result.matches[0]

                if (firstMatch) {
                    const firstMatchID = firstMatch._id
                    const trackID = `${firstMatchID}`

                    const existingTrack = topTracks.find((existingTrack) => {
                        return trackID === existingTrack.id
                    })

                    if (existingTrack) {
                        existingTrack.length = existingTrack.length += 1

                        existingTrack.timestamps = [
                            ...new Set([
                                ...existingTrack.timestamps,
                                frame.timestamp,
                            ])
                        ]

                    } else {
                        const track = {
                            id: trackID,
                            index: undefined,
                            category: categoryName,
                            matches: [],
                            length: 0,
                            timestamps: [],
                        }
                        const match = {
                            ...firstMatch,
                            detection_score: result.score,
                        }

                        categoryColorMap[match.category] = categoryColorMap[match.category] || COLORS[Object.keys(categoryColorMap).length]

                        match.color = categoryColorMap[match.category]

                        track.length = 1
                        track.matches = [
                            ...track.matches,
                            match,
                        ]
                        track.timestamps = [
                            frame.timestamp,
                        ]

                        topTracks = [
                            ...topTracks,
                            track,
                        ]
                    }

                    topTracks = [...topTracks].sort((trackA, trackB) => {
                        return trackB.length - trackA.length
                    })

                    topTracks = topTracks.map((track, index) => {
                        track.index = index
                        track.color = SHUFFLED_COLORS[index]
                        track.score = track.length / parseFloat(frames.length)
                        track.image = frame.image
                        track.rotation = (Math.round(Math.random() * 5) + 2) * (Math.random() > 0.5 ? 1 : -1)

                        return track
                    })
                }
            }
        }

        log('topTracks', topTracks)

        this.setState({
            topTracks,
        })
    }
}


/* =========================================
      EXPORTS
-------------------------------------- */

export default LensXRay
