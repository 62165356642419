
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import shallowEqual from 'react-fast-compare'

import LensVideoOverlayCategories from './LensVideoOverlayCategories'
import LensVideoOverlayBBoxes from './LensVideoOverlayBBoxes'


/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/DemoPlayer/LensVideoOverlay')


/* =========================================
      COMPONENTS
-------------------------------------- */

class LensVideoOverlay extends Component {

    shouldComponentUpdate (nextProps) {
        const { props } = this

        if (shallowEqual(props, nextProps)) {
            return false
        }

        return true
    }

    render () {
        const { props } = this

        const colors = props.colors || []

        const tracks = props.tracks || []
        const results = props.results || []

        const id = props.id
        const className = props.className
        const style = props.style
        const disabled = props.disabled
        const hidden = props.hidden

        return (
            <div className={classnames('LensVideoOverlay', className, {disabled, hidden})}
                id={id}
                style={style}

                onMouseOver={(event) => {
                    if (typeof props.onMouseOver !== 'function') {
                        return
                    }

                    props.onMouseOver(event)
                }}
                onMouseOut={(event) => {
                    if (typeof props.onMouseOut !== 'function') {
                        return
                    }

                    props.onMouseOut(event)
                }}

                data-scale-ratio={props.scaleRatio || 1.0}
            >
                { props.children }

                <LensVideoOverlayCategories
                    colors={colors}

                    tracks={tracks}
                    results={results}

                    onMouseOver={props.onMouseOver}
                    onMouseOut={props.onMouseOut}

                    onResultActive={props.onResultActive}
                    onResultInactive={props.onResultInactive}
                />

                <LensVideoOverlayBBoxes
                    colors={colors}

                    tracks={tracks}
                    results={results}

                    resultSelection={props.resultSelection}

                    scaleRatio={props.scaleRatio}
                    scrollFixed={props.scrollFixed}

                    onMouseOver={props.onMouseOver}
                    onMouseOut={props.onMouseOut}

                    onResultActive={props.onResultActive}
                    onResultInactive={props.onResultInactive}
                />
            </div>
        )
    }
}

LensVideoOverlay.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,

    colors: PropTypes.array,

    tracks: PropTypes.array,
    results: PropTypes.array,

    resultSelection: PropTypes.object,

    onResultActive: PropTypes.func,
    onResultInactive: PropTypes.func,

    onResultSelect: PropTypes.func,
    onResultUnselect: PropTypes.func,

    scaleRatio: PropTypes.number,
    scrollFixed: PropTypes.bool,
}

LensVideoOverlay.defaultProps = {
    id: undefined,
    className: undefined,
    style: undefined,
    disabled: undefined,
    hidden: undefined,

    colors: [],

    tracks: [],
    results: [],

    resultSelection: undefined,

    // onMouseOver: (event) => log(`onMouseOver`),
    // onMouseOut: (event) => log(`onMouseOut`),

    // onResultActive: (resultSelection) => log(`onResultActive`, resultSelection),
    // onResultInactive: (resultSelection) => log(`onResultInactive`, resultSelection),

    scaleRatio: undefined,
    scrollFixed: undefined,
}


/* =========================================
      EXPORT
-------------------------------------- */

export default LensVideoOverlay
