
export default {
    "meta": {
        "service": {
            "name": "markable-api-video",
            "environment": "development"
        },
        "request": {
            "protocol": "http",
            "method": "GET",
            "url": "/video/search?uri=https://s3.amazonaws.com/data-operations-sallie-gardner/tmp/VIP_1.mp4",
            "route": "/video/search",
            "params": {},
            "query": {
                "uri": "https://s3.amazonaws.com/data-operations-sallie-gardner/tmp/VIP_1.mp4"
            }
        },
        "response": {},
        "status": "complete",
        "video": {
            "uri": "https://s3.amazonaws.com/data-operations-sallie-gardner/tmp/VIP_1.mp4"
        },
        "pagination": {
            "page": 0,
            "pageBy": "frameindex",
            "pageLimit": 100,
            "pageFrom": 0,
            "pageTo": 100,
            "pageSize": 31,
            "pageTotalSize": 31
        },
        "statuses": [
            {
                "step": "lookup",
                "completed": 1,
                "total": 1,
                "unit": "lookups",
                "startedAt": 1536179225406,
                "status": 1,
                "elapsedTime": 796,
                "eachTime": 796,
                "elapsedTimeString": "00:00:00",
                "eachTimeString": "00:00:00",
                "endedAt": 1536179226202,
                "totalTime": 796,
                "totalTimeString": "00:00:00"
            },
            {
                "step": "fetch",
                "startedAt": 1536179226203,
                "elapsedTime": 508,
                "elapsedTimeString": "00:00:00",
                "completed": 0.01560211181640625,
                "total": 20.82680606842041,
                "unit": "MB",
                "status": 0.0007491360780500885,
                "eachTime": 32559.69486552567,
                "eachTimeString": "00:00:32",
                "endedAt": 1536179226711,
                "totalTime": 508,
                "totalTimeString": "00:00:00"
            },
            {
                "step": "snapshot",
                "startedAt": 1536179226879,
                "elapsedTime": 2818,
                "elapsedTimeString": "00:00:02",
                "completed": 31,
                "total": 30,
                "status": 1.0333333333333334,
                "eachTime": 90.90322580645162,
                "eachTimeString": "00:00:00",
                "endedAt": 1536179229697,
                "totalTime": 2818,
                "totalTimeString": "00:00:02"
            },
            {
                "step": "detect"
            },
            {
                "step": "track",
                "completed": 62,
                "total": 62,
                "startedAt": 1536179229699,
                "status": 1,
                "elapsedTime": 24049,
                "eachTime": 387.88709677419354,
                "elapsedTimeString": "00:00:24",
                "eachTimeString": "00:00:00",
                "endedAt": 1536179253748,
                "totalTime": 24049,
                "totalTimeString": "00:00:24"
            },
            {
                "step": "search",
                "completed": 31,
                "total": 31,
                "startedAt": 1536179253750,
                "status": 1,
                "elapsedTime": 2072,
                "eachTime": 66.83870967741936,
                "elapsedTimeString": "00:00:02",
                "eachTimeString": "00:00:00",
                "endedAt": 1536179255822,
                "totalTime": 2072,
                "totalTimeString": "00:00:02"
            },
            {
                "step": "map",
                "total": 31,
                "startedAt": 1536179255823,
                "elapsedTime": 34,
                "elapsedTimeString": "00:00:00",
                "completed": 31,
                "status": 1,
                "eachTime": 1.096774193548387,
                "eachTimeString": "00:00:00",
                "endedAt": 1536179255856,
                "totalTime": 33,
                "totalTimeString": "00:00:00"
            }
        ],
        "job": {
            "id": "b2b5fd1ff12b1b51c07fe95ca7b6fe17",
            "uri": "https://s3.amazonaws.com/data-operations-sallie-gardner/tmp/VIP_1.mp4",
            "options": {
                "search": {
                    "catalogs": [
                        "5ae7af541393dd3d2907816d"
                    ]
                }
            },
            "config": {
                "step": "config",
                "key": "00.config",
                "startedAt": 1536179258061,
                "endedAt": 1536179258061,
                "options": {
                    "verbose": true,
                    "log": true,
                    "cache": false,
                    "bail": false,
                    "configure": null,
                    "read": null,
                    "clean": null,
                    "lookup": null,
                    "fetch": null,
                    "snapshot": {
                        "size": 512,
                        "fps": 2
                    },
                    "detect": {
                        "cache": true,
                        "concurrency": 8,
                        "url": "http://samsonite-cv-ohio-1585320898.us-east-2.elb.amazonaws.com/v1/index/attributes",
                        "debugDumpObjectData": false,
                        "debugDumpImageSnapshots": false,
                        "debugDumpVideoSnapshot": false
                    },
                    "track": {
                        "maxVectorDistance": 20,
                        "minOccurrantFrameRatio": 0.5,
                        "minSequenceFrameRatio": 0.3,
                        "occurancyFrequencyFilterEnabled": true,
                        "debugDumpImageSnapshots": false,
                        "debugDumpVideoSnapshot": false
                    },
                    "search": {
                        "catalogs": [
                            "5ae7af541393dd3d2907816d"
                        ]
                    },
                    "map": null,
                    "write": null
                },
                "verbose": true,
                "log": true,
                "cache": false,
                "bail": false,
                "configure": {
                    "prefix": "00"
                },
                "read": {
                    "prefix": "01"
                },
                "clean": {
                    "prefix": "02"
                },
                "lookup": {
                    "prefix": "03"
                },
                "fetch": {
                    "prefix": "04"
                },
                "snapshot": {
                    "prefix": "05",
                    "size": 512,
                    "fps": 2
                },
                "detect": {
                    "prefix": "06",
                    "cache": true,
                    "concurrency": 8,
                    "url": "http://samsonite-cv-ohio-1585320898.us-east-2.elb.amazonaws.com/v1/index/attributes",
                    "debugDumpObjectData": false,
                    "debugDumpImageSnapshots": false,
                    "debugDumpVideoSnapshot": false
                },
                "track": {
                    "prefix": "07",
                    "maxVectorDistance": 20,
                    "minOccurrantFrameRatio": 0.5,
                    "minSequenceFrameRatio": 0.3,
                    "occurancyFrequencyFilterEnabled": true,
                    "debugDumpImageSnapshots": false,
                    "debugDumpVideoSnapshot": false
                },
                "search": {
                    "prefix": "08",
                    "concurrency": 8,
                    "genders": [
                        "women"
                    ],
                    "catalogs": [
                        "5ae7af541393dd3d2907816d"
                    ],
                    "limit": 10,
                    "apiKey": "5991c086c17de50e1e2e1d66",
                    "debugDumpImageSnapshots": false,
                    "debugDumpVideoSnapshot": false,
                    "debugDumpObjectData": false,
                    "debugDumpObjectResultMatchImages": false,
                    "ignoreCategories": [
                        "humans"
                    ]
                },
                "map": {
                    "prefix": "09"
                },
                "write": {
                    "prefix": "10"
                },
                "domain": "s3.amazonaws.com",
                "id": "b2b5fd1ff12b1b51c07fe95ca7b6fe17",
                "cachePath": "/tmp/markable-video-processing/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20",
                "cacheProcessPath": "/tmp/markable-video-processing/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process",
                "detectFramesPath": "/tmp/markable-video-processing/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/detect",
                "trackFramesPath": "/tmp/markable-video-processing/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/track",
                "searchFramesPath": "/tmp/markable-video-processing/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/search",
                "writeFilePath": "/tmp/markable-video-processing/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/index.json",
                "time": 0
            }
        },
        "jobs": {
            "pending": [],
            "active": [],
            "successful": [
                {
                    "id": "b2b5fd1ff12b1b51c07fe95ca7b6fe17",
                    "uri": "https://s3.amazonaws.com/data-operations-sallie-gardner/tmp/VIP_1.mp4",
                    "options": {
                        "search": {
                            "catalogs": [
                                "5ae7af541393dd3d2907816d"
                            ]
                        }
                    },
                    "config": {
                        "verbose": true,
                        "log": true,
                        "cache": false,
                        "bail": false,
                        "configure": {
                            "prefix": "00"
                        },
                        "read": {
                            "prefix": "01"
                        },
                        "clean": {
                            "prefix": "02"
                        },
                        "lookup": {
                            "prefix": "03"
                        },
                        "fetch": {
                            "prefix": "04"
                        },
                        "snapshot": {
                            "prefix": "05",
                            "size": 512,
                            "fps": 2
                        },
                        "detect": {
                            "prefix": "06",
                            "cache": true,
                            "concurrency": 8,
                            "url": "http://samsonite-cv-ohio-1585320898.us-east-2.elb.amazonaws.com/v1/index/attributes",
                            "debugDumpObjectData": false,
                            "debugDumpImageSnapshots": false,
                            "debugDumpVideoSnapshot": false
                        },
                        "track": {
                            "prefix": "07",
                            "maxVectorDistance": 20,
                            "minOccurrantFrameRatio": 0.5,
                            "minSequenceFrameRatio": 0.3,
                            "occurancyFrequencyFilterEnabled": true,
                            "debugDumpImageSnapshots": false,
                            "debugDumpVideoSnapshot": false
                        },
                        "search": {
                            "prefix": "08",
                            "concurrency": 8,
                            "genders": [
                                "women"
                            ],
                            "catalogs": [
                                "5ae7af541393dd3d2907816d"
                            ],
                            "limit": 10,
                            "apiKey": "5991c086c17de50e1e2e1d66",
                            "debugDumpImageSnapshots": false,
                            "debugDumpVideoSnapshot": false,
                            "debugDumpObjectData": false,
                            "debugDumpObjectResultMatchImages": false,
                            "ignoreCategories": [
                                "humans"
                            ]
                        },
                        "map": {
                            "prefix": "09"
                        },
                        "write": {
                            "prefix": "10"
                        },
                        "domain": "s3.amazonaws.com",
                        "id": "b2b5fd1ff12b1b51c07fe95ca7b6fe17",
                        "cachePath": "/tmp/markable-video-processing/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20",
                        "cacheProcessPath": "/tmp/markable-video-processing/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process",
                        "detectFramesPath": "/tmp/markable-video-processing/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/detect",
                        "trackFramesPath": "/tmp/markable-video-processing/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/track",
                        "searchFramesPath": "/tmp/markable-video-processing/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/search",
                        "writeFilePath": "/tmp/markable-video-processing/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/index.json"
                    },
                    "__id": "b2b5fd1ff12b1b51c07fe95ca7b6fe17",
                    "error": null
                }
            ],
            "failed": []
        }
    },
    "data": [
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-0",
            "index": 0,
            "timestamp": 0,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000001.png",
                "width": 288,
                "height": 512
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-1",
            "index": 1,
            "timestamp": 500,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000002.png",
                "width": 288,
                "height": 512
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-2",
            "index": 2,
            "timestamp": 1000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000003.png",
                "width": 288,
                "height": 512
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-3",
            "index": 3,
            "timestamp": 1500,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000004.png",
                "width": 288,
                "height": 512
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "b649ed28-b7ec-4e84-a58b-f841e122e615",
                    "score": 0.9811553508043289,
                    "category": {
                        "_type": "Category",
                        "_id": "jeans",
                        "name": "jeans"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 24.422775268554688,
                        "y": 294.13568115234375,
                        "width": 90.99398803710938,
                        "height": 212.7138671875
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b468a27738101553baf3",
                            "schema": "product",
                            "score": 0.8975136947631837,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/47/22/47227779e77f7fa67f02a22e558adbc7_best.jpg",
                                    "width": 369,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "704446986_LIGHT BLUE|Celeste:704446986:LIGHT BLUE|Celeste:https://www.shopstyle.com/p/dafni-frayed-jeans/704446986",
                                "sku": "704446986",
                                "name": "Dafni Frayed Jeans",
                                "url": "https://www.shopstyle.com/p/dafni-frayed-jeans/704446986",
                                "brand": "Vivetta",
                                "color": "LIGHT BLUE|Celeste",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Vivetta jeans with heart-shaped cut-outs and frayed hem. Zip and logo button closure, two front pockets and two back pockets with faded effect. Contrast seams. Model height is 177 cm and she is wearing a size IT 38. Composition: 100% cotone",
                                "category": "Women > Clothing > Denim |  | ",
                                "size": "42",
                                "age_group": "adult",
                                "stock": true,
                                "price": 423,
                                "currency": "USD"
                            },
                            "fingerprint": "ee033593ea9b765d417d9c927a491ce1",
                            "created_at": "2018-05-01T18:39:36.688Z",
                            "updated_at": "2018-06-25T17:16:09.024Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fe80a277381015580680",
                            "schema": "product",
                            "score": 0.8972167110443116,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/57/ab/57ab00abcba24d2e97f1a879081cbb70_best.jpg",
                                    "width": 226,
                                    "height": 521
                                }
                            ],
                            "data": {
                                "id": "705514513_Blue:705514513:Blue:https://www.shopstyle.com/g/men/dsquared2/slim-fit-patchwork-jeans",
                                "sku": "705514513",
                                "name": "DSQUARED2 Slim Fit Patchwork Jeans",
                                "url": "https://www.shopstyle.com/g/men/dsquared2/slim-fit-patchwork-jeans",
                                "brand": "DSQUARED2",
                                "color": "Blue",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Amply bleach-washed and distressed denim jeans repurpose pockets for patches, reconstructing a slender-fit style for a heavily worn vintage appearance. Style Name:Dsquared2 Slim Fit Patchwork Jeans. Style Number: 5490958. Available in stores.",
                                "category": "Men > Clothing > Jeans > Distressed |  | ",
                                "size": "52 EU",
                                "age_group": "adult",
                                "stock": true,
                                "price": 785,
                                "currency": "USD"
                            },
                            "fingerprint": "32d47f5566179ebbf0e501f20e088201",
                            "created_at": "2018-05-01T23:55:44.455Z",
                            "updated_at": "2018-06-23T01:14:13.585Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7be1d1393dd3d2908566d",
                            "schema": "product",
                            "score": 0.8946675682067871,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a8/c1/a8c188fd2fb5641537f97b61ee6a4b18_best.jpg",
                                    "width": 347,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "720844654_Blue:720844654:Blue:https://www.shopstyle.com/p/vivetta-lip-embroidered-jeans/720844654",
                                "sku": "720844654",
                                "name": "Vivetta lip embroidered jeans",
                                "url": "https://www.shopstyle.com/p/vivetta-lip-embroidered-jeans/720844654",
                                "brand": "Vivetta",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Blue cotton lip embroidered jeans from Vivetta featuring a waistband with belt loops, a button and zip fly, a five pocket design and a high rise.",
                                "category": "Women > Clothing > Denim |  | ",
                                "size": "42",
                                "age_group": "adult",
                                "stock": true,
                                "price": 446,
                                "currency": "USD"
                            },
                            "fingerprint": "cb7d6dc582afd4ad75d7d6a834772861",
                            "created_at": "2018-05-01T01:08:45.730Z",
                            "updated_at": "2018-06-23T01:07:17.987Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8eb846efb8d3d2f837e6e",
                            "schema": "product",
                            "score": 0.8935240268707275,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/f7/8b/f78b279cd66bebf1e797b3ca5a3adee0_best.jpg",
                                    "width": 496,
                                    "height": 792
                                }
                            ],
                            "data": {
                                "id": "707240306_Via Colombo:707240306:Via Colombo:https://www.shopstyle.com/p/mcguire-high-rise-ibiza-jean/707240306",
                                "sku": "707240306",
                                "name": "McGuire High Rise Ibiza Jean",
                                "url": "https://www.shopstyle.com/p/mcguire-high-rise-ibiza-jean/707240306",
                                "brand": "McGuire",
                                "color": "Via Colombo",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "81% Cotton, 13% Modal, 5% T400, 1% Spandex Made in the USA",
                                "category": "Women > Clothing > Denim |  | ",
                                "size": "23",
                                "age_group": "adult",
                                "stock": true,
                                "price": 268,
                                "currency": "USD"
                            },
                            "fingerprint": "4d184f4a2b940c750e2957633a4e025b",
                            "created_at": "2018-05-01T22:34:44.634Z",
                            "updated_at": "2018-06-25T19:35:12.528Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fd6fa27738101557662b",
                            "schema": "product",
                            "score": 0.8934673404693604,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5a/ac/5aac68a2b6f07114fa41a4f889850d15_best.jpg",
                                    "width": 298,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "718489466_Blue:718489466:Blue:https://www.shopstyle.com/p/vivetta-embroidered-lip-patch-mom-jeans/718489466",
                                "sku": "718489466",
                                "name": "Vivetta embroidered lip patch mom jeans",
                                "url": "https://www.shopstyle.com/p/vivetta-embroidered-lip-patch-mom-jeans/718489466",
                                "brand": "Vivetta",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Light blue cotton embroidered lip patch mom jeans from Vivetta featuring a high waist, a button and zip fly, a waistband with belt loops, a five pocket design, a relaxed shape, a regular length, a faded effect and embroidered lip patch details.",
                                "category": "Women > Clothing > Denim > Relaxed |  | ",
                                "size": "44",
                                "age_group": "adult",
                                "stock": true,
                                "price": 439,
                                "currency": "USD"
                            },
                            "fingerprint": "62a4c0254c03d000081a70d9636c31a1",
                            "created_at": "2018-05-01T23:51:11.224Z",
                            "updated_at": "2018-06-23T01:14:12.798Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c2e06efb8d3d2f810433",
                            "schema": "product",
                            "score": 0.8932852554321289,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/35/84/358440d65b65025b9d2da42d443dafd1_best.jpg",
                                    "width": 325,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "689311382_Multicolour:689311382:Multicolour:https://www.shopstyle.com/g/women/moschino/tapered-trompe-loeil-trousers",
                                "sku": "689311382",
                                "name": "Moschino tapered trompe l'oeil trousers",
                                "url": "https://www.shopstyle.com/g/women/moschino/tapered-trompe-loeil-trousers",
                                "brand": "Moschino",
                                "color": "Multicolour",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Jeremy Scott creates punchy designs under the house of Moschino. Expect to see colour blocked designs, pop culture motifs and bold brand logos. Multicoloured virgin wool blend tapered trompe l'oeil trousers featuring a high rise, a five pocket design and a button and zip fly.",
                                "category": "Women > Clothing > Pants |  | ",
                                "size": "42",
                                "age_group": "adult",
                                "stock": true,
                                "price": 1150,
                                "currency": "USD"
                            },
                            "fingerprint": "c87917bd0533f308386d003776b19c89",
                            "created_at": "2018-05-01T19:41:20.658Z",
                            "updated_at": "2018-06-18T23:40:07.204Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e06db77b36100f15cfb8",
                            "schema": "product",
                            "score": 0.8923169326782227,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b2/a6/b2a674ec5186f36055457ddc874e4dcc_best.jpg",
                                    "width": 361,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "655399097_Blue:655399097:Blue:https://www.shopstyle.com/p/dont-cry-jeans/655399097",
                                "sku": "655399097",
                                "name": "DON'T CRY Jeans",
                                "url": "https://www.shopstyle.com/p/dont-cry-jeans/655399097",
                                "brand": "DON'T CRY",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "DON&apos;T CRY Jeans. denim, faded effect, logo, patchwork design, dark wash, mid rise, front closure, button, zip, multipockets, contains non-textile parts of animal origin, straight leg. 100% Cotton",
                                "category": "Women > Clothing > Denim > Straight |  | ",
                                "size": "27",
                                "age_group": "adult",
                                "stock": true,
                                "price": 199,
                                "currency": "USD"
                            },
                            "fingerprint": "60c0c13962d24a5af017bca2860ed8de",
                            "created_at": "2018-05-01T21:47:25.936Z",
                            "updated_at": "2018-07-06T00:03:36.548Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee2a6efb8d3d2f850c07",
                            "schema": "product",
                            "score": 0.8917850589752198,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/eb/37/eb372fa0353f104705aabbdeab2764b5_best.jpg",
                                    "width": 360,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "650196997_Blue:650196997:Blue:https://www.shopstyle.com/g/women/stella-mccartney/embellished-wide-leg-jeans",
                                "sku": "650196997",
                                "name": "Stella McCartney embellished wide-leg jeans",
                                "url": "https://www.shopstyle.com/g/women/stella-mccartney/embellished-wide-leg-jeans",
                                "brand": "Stella McCartney",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Stella McCartney clash and complement a variety of styles with their SS17 collection. As always, the English fashion house create their chic garments and accessories without eschewing the ethical ambitions the brand was built on. These classic blue stretch cotton wide-leg jeans from Stella McCartney feature a front button fastening, embellishments at the leg, belt loops, a cropped length and a five pocket design.",
                                "category": "Women > Clothing > Denim > Cropped |  | ",
                                "size": "27",
                                "age_group": "adult",
                                "stock": true,
                                "price": 645,
                                "currency": "USD"
                            },
                            "fingerprint": "8eabf8724acded3ee4ace5f5ce3a5f1f",
                            "created_at": "2018-05-01T22:46:02.806Z",
                            "updated_at": "2018-06-23T01:02:32.466Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8f0536efb8d3d2f864d4e",
                            "schema": "product",
                            "score": 0.891236982345581,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/be/00/be0054c41d00285796a50ae11d7053ad_best.jpg",
                                    "width": 410,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "717038738_Stripe Pale Blue/White:717038738:Stripe Pale Blue/White:https://www.shopstyle.com/g/women/ralph-lauren/striped-jersey-pajama-pant",
                                "sku": "717038738",
                                "name": "Ralph Lauren Striped Jersey Pajama Pant",
                                "url": "https://www.shopstyle.com/g/women/ralph-lauren/striped-jersey-pajama-pant",
                                "brand": "Ralph Lauren",
                                "color": "Stripe Pale Blue/White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Details Signature \"Lauren Ralph Lauren\" elasticized waist. Two-button fly. Size medium has a 30\" inseam. 100% cotton. Machine washable. Imported. Model is 5'10\"/178 cm and wears a size small. Overview This loungewear essential combines smooth jersey with an easy-fitting design inspired by men's boxer briefs. Style Number: 365710",
                                "category": "Women > Clothing > Intimates > Pajamas |  | ",
                                "size": "L",
                                "age_group": "adult",
                                "stock": true,
                                "price": 40,
                                "currency": "USD"
                            },
                            "fingerprint": "9a42fbf97f31b051b42f271b088e2375",
                            "created_at": "2018-05-01T22:55:15.781Z",
                            "updated_at": "2018-06-25T18:17:07.707Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b1b5a2773810155292e4",
                            "schema": "product",
                            "score": 0.8905060386657715,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/bf/99/bf997c534ad549811cc99ff90683e15f_best.jpg",
                                    "width": 379,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "699695600_Blue:699695600:Blue:https://www.shopstyle.com/g/women/r-13/cut-out-cropped-jeans",
                                "sku": "699695600",
                                "name": "R 13 cut out cropped jeans",
                                "url": "https://www.shopstyle.com/g/women/r-13/cut-out-cropped-jeans",
                                "brand": "R 13",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Light blue cotton cut out cropped jeans from R13 featuring a mid rise, a waistband with belt loops, a button and zip fly, a cut out detail, distressed effects, a turned up hem and a cropped length.",
                                "category": "Women > Clothing > Denim > Cropped |  | ",
                                "size": "26",
                                "age_group": "adult",
                                "stock": true,
                                "price": 637,
                                "currency": "USD"
                            },
                            "fingerprint": "060d2fe537f753f392bb1441f5e2a0e5",
                            "created_at": "2018-05-01T18:28:05.181Z",
                            "updated_at": "2018-06-23T01:05:21.307Z"
                        }
                    ]
                },
                {
                    "_type": "SearchResult",
                    "_id": "a526b422-d9a3-478a-acc5-a45f592255b3",
                    "score": 0.995984360575676,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 12.196586608886719,
                        "y": 196.95924377441406,
                        "width": 105.81744384765625,
                        "height": 120.84629821777344
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae903c9a2773810155b2b19",
                            "schema": "product",
                            "score": 0.9151873302459717,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0c/f1/0cf17111064a265169f03924ceaffc0a_best.jpg",
                                    "width": 464,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "682877234_Eco Grey Riviera Stripe:682877234:Eco Grey Riviera Stripe:https://www.shopstyle.com/p/wallflower-womens-juniors-knit-plaid-belted-dress/682877234",
                                "sku": "682877234",
                                "name": "WallFlower Women's Juniors Knit Plaid Belted Dress",
                                "url": "https://www.shopstyle.com/p/wallflower-womens-juniors-knit-plaid-belted-dress/682877234",
                                "brand": "WallFlower",
                                "color": "Eco Grey Riviera Stripe",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "WallFlower juniors knit plaid button down belted dress with crochet at the back.",
                                "category": "Women > Clothing > Teens > Dresses |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 11.69,
                                "currency": "USD"
                            },
                            "fingerprint": "0ceeaae31d00d4dd4efabcd441b5843f",
                            "created_at": "2018-05-02T00:18:17.061Z",
                            "updated_at": "2018-06-23T01:44:21.669Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c7df1393dd3d290cc54c",
                            "schema": "product",
                            "score": 0.9127095127105713,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/ca/58/ca583472a36699a41d52f7ffe134e10f_best.jpg",
                                    "width": 489,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "540674191_White:540674191:White:https://www.shopstyle.com/g/men/tavik/striker-short-sleeve-knit",
                                "sku": "540674191",
                                "name": "Tavik Striker Short Sleeve Knit",
                                "url": "https://www.shopstyle.com/g/men/tavik/striker-short-sleeve-knit",
                                "brand": "Tavik",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Take your swing with the fresh Tavik Striker Tee. ; Cotton jersey knit with fine horizontal stripes. ; Crew neck. ; Two-button henley placket. ; Short sleeves. ; Straight hemline. ; Woven brand patch at the left hip. ; 100% cotton. ; Machine wash cold- tumble dry low. ; Imported. Measurements: ; Length: 29 in ; Product measurements were taken using size MD. Please note that measurements may vary by size.",
                                "category": "Men > Clothing > Shirts > Shortsleeve |  | ",
                                "size": "SM",
                                "age_group": "adult",
                                "stock": true,
                                "price": 34,
                                "currency": "USD"
                            },
                            "fingerprint": "3a47d0e420ce094a14670b5fc010419a",
                            "created_at": "2018-05-01T20:02:39.942Z",
                            "updated_at": "2018-06-18T19:32:06.873Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c0176efb8d3d2f7f692b",
                            "schema": "product",
                            "score": 0.903253231048584,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/07/a6/07a6f12eeeac248b791cd47daeb5cbf8_best.jpg",
                                    "width": 430,
                                    "height": 496
                                }
                            ],
                            "data": {
                                "id": "660232319_Ivory/Multi:660232319:Ivory/Multi:https://www.shopstyle.com/g/women/joie/womens-nieva-dress",
                                "sku": "660232319",
                                "name": "Joie Women's Nieva Dress",
                                "url": "https://www.shopstyle.com/g/women/joie/womens-nieva-dress",
                                "brand": "Joie",
                                "color": "Ivory/Multi",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "This long sleeve dress features unique layered tassels along with contrasting embroidery at the sleeves and front of the dress.",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49.5,
                                "currency": "USD"
                            },
                            "fingerprint": "c49da966065fe2a41d8ccdf0bb692e65",
                            "created_at": "2018-05-01T19:29:27.406Z",
                            "updated_at": "2018-06-28T19:39:22.092Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c0176efb8d3d2f7f692b",
                            "schema": "product",
                            "score": 0.903253231048584,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/07/a6/07a6f12eeeac248b791cd47daeb5cbf8_best.jpg",
                                    "width": 430,
                                    "height": 496
                                }
                            ],
                            "data": {
                                "id": "660232319_Ivory/Multi:660232319:Ivory/Multi:https://www.shopstyle.com/g/women/joie/womens-nieva-dress",
                                "sku": "660232319",
                                "name": "Joie Women's Nieva Dress",
                                "url": "https://www.shopstyle.com/g/women/joie/womens-nieva-dress",
                                "brand": "Joie",
                                "color": "Ivory/Multi",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "This long sleeve dress features unique layered tassels along with contrasting embroidery at the sleeves and front of the dress.",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49.5,
                                "currency": "USD"
                            },
                            "fingerprint": "c49da966065fe2a41d8ccdf0bb692e65",
                            "created_at": "2018-05-01T19:29:27.406Z",
                            "updated_at": "2018-06-28T19:39:22.092Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b74bb77b36100f1425ff",
                            "schema": "product",
                            "score": 0.9014315700531006,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b7/5d/b75d6e638396a4154d19ef691f738e9a_best.jpg",
                                    "width": 306,
                                    "height": 500
                                }
                            ],
                            "data": {
                                "id": "694876984_White - Ecru600:694876984:White - Ecru600:https://www.shopstyle.com/g/men/calvin-klein/mens-bar-stripe-henley-shirt-ecru600-xl",
                                "sku": "694876984",
                                "name": "Calvin Klein Mens Bar Stripe Henley Shirt ecru600 XL",
                                "url": "https://www.shopstyle.com/g/men/calvin-klein/mens-bar-stripe-henley-shirt-ecru600-xl",
                                "brand": "Calvin Klein",
                                "color": "White - Ecru600",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Heathered striped cotton casual tee shirt 2 button placket henley collar Left side chest pocket with metal logo on top Short sleeves",
                                "category": "Men > Clothing |  | ",
                                "size": "X-Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39.49,
                                "currency": "USD"
                            },
                            "fingerprint": "f5acecf22a5d7264600e177fd4dc4da3",
                            "created_at": "2018-05-01T18:51:55.693Z",
                            "updated_at": "2018-06-25T18:00:06.794Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d3c21393dd3d29137d0a",
                            "schema": "product",
                            "score": 0.8999680233001709,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5e/67/5e67eeababacd5e3def86b10ad2e5698_best.jpg",
                                    "width": 689,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712443839_Skin color:712443839:Skin color:https://www.shopstyle.com/g/men/armor-lux/armor-lux-t-shirts",
                                "sku": "712443839",
                                "name": "Armor Lux ARMOR-LUX T-shirts",
                                "url": "https://www.shopstyle.com/g/men/armor-lux/armor-lux-t-shirts",
                                "brand": "Armor Lux",
                                "color": "Skin color",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "ARMOR-LUX T-shirts. jersey, logo, stripes, round collar, short sleeves, no pockets. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 47,
                                "currency": "USD"
                            },
                            "fingerprint": "5bbdb816dd46b7fb861471c2b47505db",
                            "created_at": "2018-05-01T20:53:22.624Z",
                            "updated_at": "2018-06-23T00:15:08.084Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c1336efb8d3d2f801243",
                            "schema": "product",
                            "score": 0.8987185382843017,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/dc/88/dc881ea12fc0c8ce858586b727924eba_best.jpg",
                                    "width": 406,
                                    "height": 496
                                }
                            ],
                            "data": {
                                "id": "659592228_Ecru:659592228:Ecru:https://www.shopstyle.com/g/men/ben-sherman/mens-short-sleeve-geo-target-print-shirt",
                                "sku": "659592228",
                                "name": "Ben Sherman Men's Short Sleeve Geo Target Print Shirt",
                                "url": "https://www.shopstyle.com/g/men/ben-sherman/mens-short-sleeve-geo-target-print-shirt",
                                "brand": "Ben Sherman",
                                "color": "Ecru",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Ben Sherman premium modern fit short sleeve woven with geo target pattern.",
                                "category": "Men > Clothing |  | ",
                                "size": "X-Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39.5,
                                "currency": "USD"
                            },
                            "fingerprint": "695e746d8ead29084fdc9baf94d5f4c3",
                            "created_at": "2018-05-01T19:34:11.173Z",
                            "updated_at": "2018-06-22T20:40:58.035Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b218a27738101552cf23",
                            "schema": "product",
                            "score": 0.8973863887786865,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/64/a5/64a567f515e7ef863066b96f8ca444ec_best.jpg",
                                    "width": 686,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "719991220_White/Navy Stripe:719991220:White/Navy Stripe:https://www.shopstyle.com/g/women/a-pea-in-the-pod/pull-down-drop-shoulder-nursing-top",
                                "sku": "719991220",
                                "name": "A Pea in the Pod Pull Down Drop Shoulder Nursing Top",
                                "url": "https://www.shopstyle.com/g/women/a-pea-in-the-pod/pull-down-drop-shoulder-nursing-top",
                                "brand": "A Pea in the Pod",
                                "color": "White/Navy Stripe",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "nursing Top short Sleeve crew Neck pull Down Nursing Function drop Shoulder mock Layer soft Top striped modal / Spandex french Terry machine Wash Or Dry Clean imported",
                                "category": "Women > Clothing > Maternity > Tops |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 58,
                                "currency": "USD"
                            },
                            "fingerprint": "c4cd34aea4b93d5490e88baa392929d3",
                            "created_at": "2018-05-01T18:29:44.394Z",
                            "updated_at": "2018-06-28T19:13:19.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ba92a277381015565a0e",
                            "schema": "product",
                            "score": 0.8970769596099853,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/89/fc/89fc511d161cad606c827d93815b017c_best.jpg",
                                    "width": 273,
                                    "height": 400
                                }
                            ],
                            "data": {
                                "id": "620422496_Off White Blue:620422496:Off White Blue:https://www.shopstyle.com/g/women/sacai/dixie-border-cotton-tee",
                                "sku": "620422496",
                                "name": "Sacai Dixie Border Cotton Tee",
                                "url": "https://www.shopstyle.com/g/women/sacai/dixie-border-cotton-tee",
                                "brand": "Sacai",
                                "color": "Off White Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Brilliant stripes in a relaxed silhouette. Boatneck. Short sleeves. About 25\" from shoulder to hem. Cotton. Hand wash. Imported. Model shown is 5'10\" (177cm) wearing size Small.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "2 (SMALL)",
                                "age_group": "adult",
                                "stock": true,
                                "price": 259,
                                "currency": "USD"
                            },
                            "fingerprint": "e8c8d18992f97f2ded2765560b087929",
                            "created_at": "2018-05-01T19:05:54.163Z",
                            "updated_at": "2018-06-22T23:43:07.931Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ffa56efb8d3d2f8eccba",
                            "schema": "product",
                            "score": 0.8967910480499268,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/fb/aa/fbaa0efb5be5de3750da49e1f5afadf7_best.jpg",
                                    "width": 455,
                                    "height": 434
                                }
                            ],
                            "data": {
                                "id": "695689050_gray:695689050:gray:https://www.shopstyle.com/g/men/saint-laurent/striped-slub-cotton-blend-jersey-t-shirt",
                                "sku": "695689050",
                                "name": "Saint Laurent Striped Slub Cotton-Blend Jersey T-Shirt",
                                "url": "https://www.shopstyle.com/g/men/saint-laurent/striped-slub-cotton-blend-jersey-t-shirt",
                                "brand": "Saint Laurent",
                                "color": "gray",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Saint Laurent 's striped T-shirt has the soft, well-worn appeal of something you've owned for years. Made in France, it's cut for a regular fit from slub cotton-blend jersey that's lightly distressed. Shown here with Saint Laurent Skinny jeans, Saint Laurent leather jacket, Saint Laurent sneakers, Saint Laurent bracelet. - - Grey and off-white slub cotton-blend jersey - Crew neck, distressed - 86% cotton, 14% polyamide - Machine wash - Made in France",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XXL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 490,
                                "currency": "USD"
                            },
                            "fingerprint": "a3a16aeee069238e23576a1169de5012",
                            "created_at": "2018-05-02T00:00:37.932Z",
                            "updated_at": "2018-06-18T12:19:08.974Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-4",
            "index": 4,
            "timestamp": 2000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000005.png",
                "width": 288,
                "height": 512
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "3f695d3d-cb3c-40dd-8c23-976f67bfdd10",
                    "score": 0.995984360575676,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 3.0070266723632812,
                        "y": 187.53692626953125,
                        "width": 134.6501693725586,
                        "height": 141.86822509765625
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae903c9a2773810155b2b19",
                            "schema": "product",
                            "score": 0.9151873302459717,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0c/f1/0cf17111064a265169f03924ceaffc0a_best.jpg",
                                    "width": 464,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "682877234_Eco Grey Riviera Stripe:682877234:Eco Grey Riviera Stripe:https://www.shopstyle.com/p/wallflower-womens-juniors-knit-plaid-belted-dress/682877234",
                                "sku": "682877234",
                                "name": "WallFlower Women's Juniors Knit Plaid Belted Dress",
                                "url": "https://www.shopstyle.com/p/wallflower-womens-juniors-knit-plaid-belted-dress/682877234",
                                "brand": "WallFlower",
                                "color": "Eco Grey Riviera Stripe",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "WallFlower juniors knit plaid button down belted dress with crochet at the back.",
                                "category": "Women > Clothing > Teens > Dresses |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 11.69,
                                "currency": "USD"
                            },
                            "fingerprint": "0ceeaae31d00d4dd4efabcd441b5843f",
                            "created_at": "2018-05-02T00:18:17.061Z",
                            "updated_at": "2018-06-23T01:44:21.669Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c7df1393dd3d290cc54c",
                            "schema": "product",
                            "score": 0.9127095127105713,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/ca/58/ca583472a36699a41d52f7ffe134e10f_best.jpg",
                                    "width": 489,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "540674191_White:540674191:White:https://www.shopstyle.com/g/men/tavik/striker-short-sleeve-knit",
                                "sku": "540674191",
                                "name": "Tavik Striker Short Sleeve Knit",
                                "url": "https://www.shopstyle.com/g/men/tavik/striker-short-sleeve-knit",
                                "brand": "Tavik",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Take your swing with the fresh Tavik Striker Tee. ; Cotton jersey knit with fine horizontal stripes. ; Crew neck. ; Two-button henley placket. ; Short sleeves. ; Straight hemline. ; Woven brand patch at the left hip. ; 100% cotton. ; Machine wash cold- tumble dry low. ; Imported. Measurements: ; Length: 29 in ; Product measurements were taken using size MD. Please note that measurements may vary by size.",
                                "category": "Men > Clothing > Shirts > Shortsleeve |  | ",
                                "size": "SM",
                                "age_group": "adult",
                                "stock": true,
                                "price": 34,
                                "currency": "USD"
                            },
                            "fingerprint": "3a47d0e420ce094a14670b5fc010419a",
                            "created_at": "2018-05-01T20:02:39.942Z",
                            "updated_at": "2018-06-18T19:32:06.873Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c0176efb8d3d2f7f692b",
                            "schema": "product",
                            "score": 0.903253231048584,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/07/a6/07a6f12eeeac248b791cd47daeb5cbf8_best.jpg",
                                    "width": 430,
                                    "height": 496
                                }
                            ],
                            "data": {
                                "id": "660232319_Ivory/Multi:660232319:Ivory/Multi:https://www.shopstyle.com/g/women/joie/womens-nieva-dress",
                                "sku": "660232319",
                                "name": "Joie Women's Nieva Dress",
                                "url": "https://www.shopstyle.com/g/women/joie/womens-nieva-dress",
                                "brand": "Joie",
                                "color": "Ivory/Multi",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "This long sleeve dress features unique layered tassels along with contrasting embroidery at the sleeves and front of the dress.",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49.5,
                                "currency": "USD"
                            },
                            "fingerprint": "c49da966065fe2a41d8ccdf0bb692e65",
                            "created_at": "2018-05-01T19:29:27.406Z",
                            "updated_at": "2018-06-28T19:39:22.092Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c0176efb8d3d2f7f692b",
                            "schema": "product",
                            "score": 0.903253231048584,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/07/a6/07a6f12eeeac248b791cd47daeb5cbf8_best.jpg",
                                    "width": 430,
                                    "height": 496
                                }
                            ],
                            "data": {
                                "id": "660232319_Ivory/Multi:660232319:Ivory/Multi:https://www.shopstyle.com/g/women/joie/womens-nieva-dress",
                                "sku": "660232319",
                                "name": "Joie Women's Nieva Dress",
                                "url": "https://www.shopstyle.com/g/women/joie/womens-nieva-dress",
                                "brand": "Joie",
                                "color": "Ivory/Multi",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "This long sleeve dress features unique layered tassels along with contrasting embroidery at the sleeves and front of the dress.",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49.5,
                                "currency": "USD"
                            },
                            "fingerprint": "c49da966065fe2a41d8ccdf0bb692e65",
                            "created_at": "2018-05-01T19:29:27.406Z",
                            "updated_at": "2018-06-28T19:39:22.092Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b74bb77b36100f1425ff",
                            "schema": "product",
                            "score": 0.9014315700531006,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b7/5d/b75d6e638396a4154d19ef691f738e9a_best.jpg",
                                    "width": 306,
                                    "height": 500
                                }
                            ],
                            "data": {
                                "id": "694876984_White - Ecru600:694876984:White - Ecru600:https://www.shopstyle.com/g/men/calvin-klein/mens-bar-stripe-henley-shirt-ecru600-xl",
                                "sku": "694876984",
                                "name": "Calvin Klein Mens Bar Stripe Henley Shirt ecru600 XL",
                                "url": "https://www.shopstyle.com/g/men/calvin-klein/mens-bar-stripe-henley-shirt-ecru600-xl",
                                "brand": "Calvin Klein",
                                "color": "White - Ecru600",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Heathered striped cotton casual tee shirt 2 button placket henley collar Left side chest pocket with metal logo on top Short sleeves",
                                "category": "Men > Clothing |  | ",
                                "size": "X-Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39.49,
                                "currency": "USD"
                            },
                            "fingerprint": "f5acecf22a5d7264600e177fd4dc4da3",
                            "created_at": "2018-05-01T18:51:55.693Z",
                            "updated_at": "2018-06-25T18:00:06.794Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d3c21393dd3d29137d0a",
                            "schema": "product",
                            "score": 0.8999680233001709,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5e/67/5e67eeababacd5e3def86b10ad2e5698_best.jpg",
                                    "width": 689,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712443839_Skin color:712443839:Skin color:https://www.shopstyle.com/g/men/armor-lux/armor-lux-t-shirts",
                                "sku": "712443839",
                                "name": "Armor Lux ARMOR-LUX T-shirts",
                                "url": "https://www.shopstyle.com/g/men/armor-lux/armor-lux-t-shirts",
                                "brand": "Armor Lux",
                                "color": "Skin color",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "ARMOR-LUX T-shirts. jersey, logo, stripes, round collar, short sleeves, no pockets. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 47,
                                "currency": "USD"
                            },
                            "fingerprint": "5bbdb816dd46b7fb861471c2b47505db",
                            "created_at": "2018-05-01T20:53:22.624Z",
                            "updated_at": "2018-06-23T00:15:08.084Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c1336efb8d3d2f801243",
                            "schema": "product",
                            "score": 0.8987185382843017,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/dc/88/dc881ea12fc0c8ce858586b727924eba_best.jpg",
                                    "width": 406,
                                    "height": 496
                                }
                            ],
                            "data": {
                                "id": "659592228_Ecru:659592228:Ecru:https://www.shopstyle.com/g/men/ben-sherman/mens-short-sleeve-geo-target-print-shirt",
                                "sku": "659592228",
                                "name": "Ben Sherman Men's Short Sleeve Geo Target Print Shirt",
                                "url": "https://www.shopstyle.com/g/men/ben-sherman/mens-short-sleeve-geo-target-print-shirt",
                                "brand": "Ben Sherman",
                                "color": "Ecru",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Ben Sherman premium modern fit short sleeve woven with geo target pattern.",
                                "category": "Men > Clothing |  | ",
                                "size": "X-Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39.5,
                                "currency": "USD"
                            },
                            "fingerprint": "695e746d8ead29084fdc9baf94d5f4c3",
                            "created_at": "2018-05-01T19:34:11.173Z",
                            "updated_at": "2018-06-22T20:40:58.035Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b218a27738101552cf23",
                            "schema": "product",
                            "score": 0.8973863887786865,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/64/a5/64a567f515e7ef863066b96f8ca444ec_best.jpg",
                                    "width": 686,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "719991220_White/Navy Stripe:719991220:White/Navy Stripe:https://www.shopstyle.com/g/women/a-pea-in-the-pod/pull-down-drop-shoulder-nursing-top",
                                "sku": "719991220",
                                "name": "A Pea in the Pod Pull Down Drop Shoulder Nursing Top",
                                "url": "https://www.shopstyle.com/g/women/a-pea-in-the-pod/pull-down-drop-shoulder-nursing-top",
                                "brand": "A Pea in the Pod",
                                "color": "White/Navy Stripe",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "nursing Top short Sleeve crew Neck pull Down Nursing Function drop Shoulder mock Layer soft Top striped modal / Spandex french Terry machine Wash Or Dry Clean imported",
                                "category": "Women > Clothing > Maternity > Tops |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 58,
                                "currency": "USD"
                            },
                            "fingerprint": "c4cd34aea4b93d5490e88baa392929d3",
                            "created_at": "2018-05-01T18:29:44.394Z",
                            "updated_at": "2018-06-28T19:13:19.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ba92a277381015565a0e",
                            "schema": "product",
                            "score": 0.8970769596099853,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/89/fc/89fc511d161cad606c827d93815b017c_best.jpg",
                                    "width": 273,
                                    "height": 400
                                }
                            ],
                            "data": {
                                "id": "620422496_Off White Blue:620422496:Off White Blue:https://www.shopstyle.com/g/women/sacai/dixie-border-cotton-tee",
                                "sku": "620422496",
                                "name": "Sacai Dixie Border Cotton Tee",
                                "url": "https://www.shopstyle.com/g/women/sacai/dixie-border-cotton-tee",
                                "brand": "Sacai",
                                "color": "Off White Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Brilliant stripes in a relaxed silhouette. Boatneck. Short sleeves. About 25\" from shoulder to hem. Cotton. Hand wash. Imported. Model shown is 5'10\" (177cm) wearing size Small.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "2 (SMALL)",
                                "age_group": "adult",
                                "stock": true,
                                "price": 259,
                                "currency": "USD"
                            },
                            "fingerprint": "e8c8d18992f97f2ded2765560b087929",
                            "created_at": "2018-05-01T19:05:54.163Z",
                            "updated_at": "2018-06-22T23:43:07.931Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ffa56efb8d3d2f8eccba",
                            "schema": "product",
                            "score": 0.8967910480499268,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/fb/aa/fbaa0efb5be5de3750da49e1f5afadf7_best.jpg",
                                    "width": 455,
                                    "height": 434
                                }
                            ],
                            "data": {
                                "id": "695689050_gray:695689050:gray:https://www.shopstyle.com/g/men/saint-laurent/striped-slub-cotton-blend-jersey-t-shirt",
                                "sku": "695689050",
                                "name": "Saint Laurent Striped Slub Cotton-Blend Jersey T-Shirt",
                                "url": "https://www.shopstyle.com/g/men/saint-laurent/striped-slub-cotton-blend-jersey-t-shirt",
                                "brand": "Saint Laurent",
                                "color": "gray",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Saint Laurent 's striped T-shirt has the soft, well-worn appeal of something you've owned for years. Made in France, it's cut for a regular fit from slub cotton-blend jersey that's lightly distressed. Shown here with Saint Laurent Skinny jeans, Saint Laurent leather jacket, Saint Laurent sneakers, Saint Laurent bracelet. - - Grey and off-white slub cotton-blend jersey - Crew neck, distressed - 86% cotton, 14% polyamide - Machine wash - Made in France",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XXL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 490,
                                "currency": "USD"
                            },
                            "fingerprint": "a3a16aeee069238e23576a1169de5012",
                            "created_at": "2018-05-02T00:00:37.932Z",
                            "updated_at": "2018-06-18T12:19:08.974Z"
                        }
                    ]
                },
                {
                    "_type": "SearchResult",
                    "_id": "b1e52028-93e6-4b72-a14a-5ca1e27e4d15",
                    "score": 0.9811553508043289,
                    "category": {
                        "_type": "Category",
                        "_id": "jeans",
                        "name": "jeans"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 23.446876525878906,
                        "y": 308.3470458984375,
                        "width": 90.22013854980469,
                        "height": 199.206787109375
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b468a27738101553baf3",
                            "schema": "product",
                            "score": 0.8975136947631837,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/47/22/47227779e77f7fa67f02a22e558adbc7_best.jpg",
                                    "width": 369,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "704446986_LIGHT BLUE|Celeste:704446986:LIGHT BLUE|Celeste:https://www.shopstyle.com/p/dafni-frayed-jeans/704446986",
                                "sku": "704446986",
                                "name": "Dafni Frayed Jeans",
                                "url": "https://www.shopstyle.com/p/dafni-frayed-jeans/704446986",
                                "brand": "Vivetta",
                                "color": "LIGHT BLUE|Celeste",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Vivetta jeans with heart-shaped cut-outs and frayed hem. Zip and logo button closure, two front pockets and two back pockets with faded effect. Contrast seams. Model height is 177 cm and she is wearing a size IT 38. Composition: 100% cotone",
                                "category": "Women > Clothing > Denim |  | ",
                                "size": "42",
                                "age_group": "adult",
                                "stock": true,
                                "price": 423,
                                "currency": "USD"
                            },
                            "fingerprint": "ee033593ea9b765d417d9c927a491ce1",
                            "created_at": "2018-05-01T18:39:36.688Z",
                            "updated_at": "2018-06-25T17:16:09.024Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fe80a277381015580680",
                            "schema": "product",
                            "score": 0.8972167110443116,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/57/ab/57ab00abcba24d2e97f1a879081cbb70_best.jpg",
                                    "width": 226,
                                    "height": 521
                                }
                            ],
                            "data": {
                                "id": "705514513_Blue:705514513:Blue:https://www.shopstyle.com/g/men/dsquared2/slim-fit-patchwork-jeans",
                                "sku": "705514513",
                                "name": "DSQUARED2 Slim Fit Patchwork Jeans",
                                "url": "https://www.shopstyle.com/g/men/dsquared2/slim-fit-patchwork-jeans",
                                "brand": "DSQUARED2",
                                "color": "Blue",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Amply bleach-washed and distressed denim jeans repurpose pockets for patches, reconstructing a slender-fit style for a heavily worn vintage appearance. Style Name:Dsquared2 Slim Fit Patchwork Jeans. Style Number: 5490958. Available in stores.",
                                "category": "Men > Clothing > Jeans > Distressed |  | ",
                                "size": "52 EU",
                                "age_group": "adult",
                                "stock": true,
                                "price": 785,
                                "currency": "USD"
                            },
                            "fingerprint": "32d47f5566179ebbf0e501f20e088201",
                            "created_at": "2018-05-01T23:55:44.455Z",
                            "updated_at": "2018-06-23T01:14:13.585Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7be1d1393dd3d2908566d",
                            "schema": "product",
                            "score": 0.8946675682067871,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a8/c1/a8c188fd2fb5641537f97b61ee6a4b18_best.jpg",
                                    "width": 347,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "720844654_Blue:720844654:Blue:https://www.shopstyle.com/p/vivetta-lip-embroidered-jeans/720844654",
                                "sku": "720844654",
                                "name": "Vivetta lip embroidered jeans",
                                "url": "https://www.shopstyle.com/p/vivetta-lip-embroidered-jeans/720844654",
                                "brand": "Vivetta",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Blue cotton lip embroidered jeans from Vivetta featuring a waistband with belt loops, a button and zip fly, a five pocket design and a high rise.",
                                "category": "Women > Clothing > Denim |  | ",
                                "size": "42",
                                "age_group": "adult",
                                "stock": true,
                                "price": 446,
                                "currency": "USD"
                            },
                            "fingerprint": "cb7d6dc582afd4ad75d7d6a834772861",
                            "created_at": "2018-05-01T01:08:45.730Z",
                            "updated_at": "2018-06-23T01:07:17.987Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8eb846efb8d3d2f837e6e",
                            "schema": "product",
                            "score": 0.8935240268707275,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/f7/8b/f78b279cd66bebf1e797b3ca5a3adee0_best.jpg",
                                    "width": 496,
                                    "height": 792
                                }
                            ],
                            "data": {
                                "id": "707240306_Via Colombo:707240306:Via Colombo:https://www.shopstyle.com/p/mcguire-high-rise-ibiza-jean/707240306",
                                "sku": "707240306",
                                "name": "McGuire High Rise Ibiza Jean",
                                "url": "https://www.shopstyle.com/p/mcguire-high-rise-ibiza-jean/707240306",
                                "brand": "McGuire",
                                "color": "Via Colombo",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "81% Cotton, 13% Modal, 5% T400, 1% Spandex Made in the USA",
                                "category": "Women > Clothing > Denim |  | ",
                                "size": "23",
                                "age_group": "adult",
                                "stock": true,
                                "price": 268,
                                "currency": "USD"
                            },
                            "fingerprint": "4d184f4a2b940c750e2957633a4e025b",
                            "created_at": "2018-05-01T22:34:44.634Z",
                            "updated_at": "2018-06-25T19:35:12.528Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fd6fa27738101557662b",
                            "schema": "product",
                            "score": 0.8934673404693604,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5a/ac/5aac68a2b6f07114fa41a4f889850d15_best.jpg",
                                    "width": 298,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "718489466_Blue:718489466:Blue:https://www.shopstyle.com/p/vivetta-embroidered-lip-patch-mom-jeans/718489466",
                                "sku": "718489466",
                                "name": "Vivetta embroidered lip patch mom jeans",
                                "url": "https://www.shopstyle.com/p/vivetta-embroidered-lip-patch-mom-jeans/718489466",
                                "brand": "Vivetta",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Light blue cotton embroidered lip patch mom jeans from Vivetta featuring a high waist, a button and zip fly, a waistband with belt loops, a five pocket design, a relaxed shape, a regular length, a faded effect and embroidered lip patch details.",
                                "category": "Women > Clothing > Denim > Relaxed |  | ",
                                "size": "44",
                                "age_group": "adult",
                                "stock": true,
                                "price": 439,
                                "currency": "USD"
                            },
                            "fingerprint": "62a4c0254c03d000081a70d9636c31a1",
                            "created_at": "2018-05-01T23:51:11.224Z",
                            "updated_at": "2018-06-23T01:14:12.798Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c2e06efb8d3d2f810433",
                            "schema": "product",
                            "score": 0.8932852554321289,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/35/84/358440d65b65025b9d2da42d443dafd1_best.jpg",
                                    "width": 325,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "689311382_Multicolour:689311382:Multicolour:https://www.shopstyle.com/g/women/moschino/tapered-trompe-loeil-trousers",
                                "sku": "689311382",
                                "name": "Moschino tapered trompe l'oeil trousers",
                                "url": "https://www.shopstyle.com/g/women/moschino/tapered-trompe-loeil-trousers",
                                "brand": "Moschino",
                                "color": "Multicolour",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Jeremy Scott creates punchy designs under the house of Moschino. Expect to see colour blocked designs, pop culture motifs and bold brand logos. Multicoloured virgin wool blend tapered trompe l'oeil trousers featuring a high rise, a five pocket design and a button and zip fly.",
                                "category": "Women > Clothing > Pants |  | ",
                                "size": "42",
                                "age_group": "adult",
                                "stock": true,
                                "price": 1150,
                                "currency": "USD"
                            },
                            "fingerprint": "c87917bd0533f308386d003776b19c89",
                            "created_at": "2018-05-01T19:41:20.658Z",
                            "updated_at": "2018-06-18T23:40:07.204Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e06db77b36100f15cfb8",
                            "schema": "product",
                            "score": 0.8923169326782227,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b2/a6/b2a674ec5186f36055457ddc874e4dcc_best.jpg",
                                    "width": 361,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "655399097_Blue:655399097:Blue:https://www.shopstyle.com/p/dont-cry-jeans/655399097",
                                "sku": "655399097",
                                "name": "DON'T CRY Jeans",
                                "url": "https://www.shopstyle.com/p/dont-cry-jeans/655399097",
                                "brand": "DON'T CRY",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "DON&apos;T CRY Jeans. denim, faded effect, logo, patchwork design, dark wash, mid rise, front closure, button, zip, multipockets, contains non-textile parts of animal origin, straight leg. 100% Cotton",
                                "category": "Women > Clothing > Denim > Straight |  | ",
                                "size": "27",
                                "age_group": "adult",
                                "stock": true,
                                "price": 199,
                                "currency": "USD"
                            },
                            "fingerprint": "60c0c13962d24a5af017bca2860ed8de",
                            "created_at": "2018-05-01T21:47:25.936Z",
                            "updated_at": "2018-07-06T00:03:36.548Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee2a6efb8d3d2f850c07",
                            "schema": "product",
                            "score": 0.8917850589752198,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/eb/37/eb372fa0353f104705aabbdeab2764b5_best.jpg",
                                    "width": 360,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "650196997_Blue:650196997:Blue:https://www.shopstyle.com/g/women/stella-mccartney/embellished-wide-leg-jeans",
                                "sku": "650196997",
                                "name": "Stella McCartney embellished wide-leg jeans",
                                "url": "https://www.shopstyle.com/g/women/stella-mccartney/embellished-wide-leg-jeans",
                                "brand": "Stella McCartney",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Stella McCartney clash and complement a variety of styles with their SS17 collection. As always, the English fashion house create their chic garments and accessories without eschewing the ethical ambitions the brand was built on. These classic blue stretch cotton wide-leg jeans from Stella McCartney feature a front button fastening, embellishments at the leg, belt loops, a cropped length and a five pocket design.",
                                "category": "Women > Clothing > Denim > Cropped |  | ",
                                "size": "27",
                                "age_group": "adult",
                                "stock": true,
                                "price": 645,
                                "currency": "USD"
                            },
                            "fingerprint": "8eabf8724acded3ee4ace5f5ce3a5f1f",
                            "created_at": "2018-05-01T22:46:02.806Z",
                            "updated_at": "2018-06-23T01:02:32.466Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8f0536efb8d3d2f864d4e",
                            "schema": "product",
                            "score": 0.891236982345581,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/be/00/be0054c41d00285796a50ae11d7053ad_best.jpg",
                                    "width": 410,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "717038738_Stripe Pale Blue/White:717038738:Stripe Pale Blue/White:https://www.shopstyle.com/g/women/ralph-lauren/striped-jersey-pajama-pant",
                                "sku": "717038738",
                                "name": "Ralph Lauren Striped Jersey Pajama Pant",
                                "url": "https://www.shopstyle.com/g/women/ralph-lauren/striped-jersey-pajama-pant",
                                "brand": "Ralph Lauren",
                                "color": "Stripe Pale Blue/White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Details Signature \"Lauren Ralph Lauren\" elasticized waist. Two-button fly. Size medium has a 30\" inseam. 100% cotton. Machine washable. Imported. Model is 5'10\"/178 cm and wears a size small. Overview This loungewear essential combines smooth jersey with an easy-fitting design inspired by men's boxer briefs. Style Number: 365710",
                                "category": "Women > Clothing > Intimates > Pajamas |  | ",
                                "size": "L",
                                "age_group": "adult",
                                "stock": true,
                                "price": 40,
                                "currency": "USD"
                            },
                            "fingerprint": "9a42fbf97f31b051b42f271b088e2375",
                            "created_at": "2018-05-01T22:55:15.781Z",
                            "updated_at": "2018-06-25T18:17:07.707Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b1b5a2773810155292e4",
                            "schema": "product",
                            "score": 0.8905060386657715,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/bf/99/bf997c534ad549811cc99ff90683e15f_best.jpg",
                                    "width": 379,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "699695600_Blue:699695600:Blue:https://www.shopstyle.com/g/women/r-13/cut-out-cropped-jeans",
                                "sku": "699695600",
                                "name": "R 13 cut out cropped jeans",
                                "url": "https://www.shopstyle.com/g/women/r-13/cut-out-cropped-jeans",
                                "brand": "R 13",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Light blue cotton cut out cropped jeans from R13 featuring a mid rise, a waistband with belt loops, a button and zip fly, a cut out detail, distressed effects, a turned up hem and a cropped length.",
                                "category": "Women > Clothing > Denim > Cropped |  | ",
                                "size": "26",
                                "age_group": "adult",
                                "stock": true,
                                "price": 637,
                                "currency": "USD"
                            },
                            "fingerprint": "060d2fe537f753f392bb1441f5e2a0e5",
                            "created_at": "2018-05-01T18:28:05.181Z",
                            "updated_at": "2018-06-23T01:05:21.307Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-5",
            "index": 5,
            "timestamp": 2500,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000006.png",
                "width": 288,
                "height": 512
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "b6875384-0d9d-469a-8dfc-08756c1a4b7b",
                    "score": 0.995984360575676,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 1.1847648620605469,
                        "y": 186.1139373779297,
                        "width": 106.30307388305664,
                        "height": 156.9032745361328
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae903c9a2773810155b2b19",
                            "schema": "product",
                            "score": 0.9151873302459717,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0c/f1/0cf17111064a265169f03924ceaffc0a_best.jpg",
                                    "width": 464,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "682877234_Eco Grey Riviera Stripe:682877234:Eco Grey Riviera Stripe:https://www.shopstyle.com/p/wallflower-womens-juniors-knit-plaid-belted-dress/682877234",
                                "sku": "682877234",
                                "name": "WallFlower Women's Juniors Knit Plaid Belted Dress",
                                "url": "https://www.shopstyle.com/p/wallflower-womens-juniors-knit-plaid-belted-dress/682877234",
                                "brand": "WallFlower",
                                "color": "Eco Grey Riviera Stripe",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "WallFlower juniors knit plaid button down belted dress with crochet at the back.",
                                "category": "Women > Clothing > Teens > Dresses |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 11.69,
                                "currency": "USD"
                            },
                            "fingerprint": "0ceeaae31d00d4dd4efabcd441b5843f",
                            "created_at": "2018-05-02T00:18:17.061Z",
                            "updated_at": "2018-06-23T01:44:21.669Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c7df1393dd3d290cc54c",
                            "schema": "product",
                            "score": 0.9127095127105713,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/ca/58/ca583472a36699a41d52f7ffe134e10f_best.jpg",
                                    "width": 489,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "540674191_White:540674191:White:https://www.shopstyle.com/g/men/tavik/striker-short-sleeve-knit",
                                "sku": "540674191",
                                "name": "Tavik Striker Short Sleeve Knit",
                                "url": "https://www.shopstyle.com/g/men/tavik/striker-short-sleeve-knit",
                                "brand": "Tavik",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Take your swing with the fresh Tavik Striker Tee. ; Cotton jersey knit with fine horizontal stripes. ; Crew neck. ; Two-button henley placket. ; Short sleeves. ; Straight hemline. ; Woven brand patch at the left hip. ; 100% cotton. ; Machine wash cold- tumble dry low. ; Imported. Measurements: ; Length: 29 in ; Product measurements were taken using size MD. Please note that measurements may vary by size.",
                                "category": "Men > Clothing > Shirts > Shortsleeve |  | ",
                                "size": "SM",
                                "age_group": "adult",
                                "stock": true,
                                "price": 34,
                                "currency": "USD"
                            },
                            "fingerprint": "3a47d0e420ce094a14670b5fc010419a",
                            "created_at": "2018-05-01T20:02:39.942Z",
                            "updated_at": "2018-06-18T19:32:06.873Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c0176efb8d3d2f7f692b",
                            "schema": "product",
                            "score": 0.903253231048584,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/07/a6/07a6f12eeeac248b791cd47daeb5cbf8_best.jpg",
                                    "width": 430,
                                    "height": 496
                                }
                            ],
                            "data": {
                                "id": "660232319_Ivory/Multi:660232319:Ivory/Multi:https://www.shopstyle.com/g/women/joie/womens-nieva-dress",
                                "sku": "660232319",
                                "name": "Joie Women's Nieva Dress",
                                "url": "https://www.shopstyle.com/g/women/joie/womens-nieva-dress",
                                "brand": "Joie",
                                "color": "Ivory/Multi",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "This long sleeve dress features unique layered tassels along with contrasting embroidery at the sleeves and front of the dress.",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49.5,
                                "currency": "USD"
                            },
                            "fingerprint": "c49da966065fe2a41d8ccdf0bb692e65",
                            "created_at": "2018-05-01T19:29:27.406Z",
                            "updated_at": "2018-06-28T19:39:22.092Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c0176efb8d3d2f7f692b",
                            "schema": "product",
                            "score": 0.903253231048584,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/07/a6/07a6f12eeeac248b791cd47daeb5cbf8_best.jpg",
                                    "width": 430,
                                    "height": 496
                                }
                            ],
                            "data": {
                                "id": "660232319_Ivory/Multi:660232319:Ivory/Multi:https://www.shopstyle.com/g/women/joie/womens-nieva-dress",
                                "sku": "660232319",
                                "name": "Joie Women's Nieva Dress",
                                "url": "https://www.shopstyle.com/g/women/joie/womens-nieva-dress",
                                "brand": "Joie",
                                "color": "Ivory/Multi",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "This long sleeve dress features unique layered tassels along with contrasting embroidery at the sleeves and front of the dress.",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49.5,
                                "currency": "USD"
                            },
                            "fingerprint": "c49da966065fe2a41d8ccdf0bb692e65",
                            "created_at": "2018-05-01T19:29:27.406Z",
                            "updated_at": "2018-06-28T19:39:22.092Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b74bb77b36100f1425ff",
                            "schema": "product",
                            "score": 0.9014315700531006,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b7/5d/b75d6e638396a4154d19ef691f738e9a_best.jpg",
                                    "width": 306,
                                    "height": 500
                                }
                            ],
                            "data": {
                                "id": "694876984_White - Ecru600:694876984:White - Ecru600:https://www.shopstyle.com/g/men/calvin-klein/mens-bar-stripe-henley-shirt-ecru600-xl",
                                "sku": "694876984",
                                "name": "Calvin Klein Mens Bar Stripe Henley Shirt ecru600 XL",
                                "url": "https://www.shopstyle.com/g/men/calvin-klein/mens-bar-stripe-henley-shirt-ecru600-xl",
                                "brand": "Calvin Klein",
                                "color": "White - Ecru600",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Heathered striped cotton casual tee shirt 2 button placket henley collar Left side chest pocket with metal logo on top Short sleeves",
                                "category": "Men > Clothing |  | ",
                                "size": "X-Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39.49,
                                "currency": "USD"
                            },
                            "fingerprint": "f5acecf22a5d7264600e177fd4dc4da3",
                            "created_at": "2018-05-01T18:51:55.693Z",
                            "updated_at": "2018-06-25T18:00:06.794Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d3c21393dd3d29137d0a",
                            "schema": "product",
                            "score": 0.8999680233001709,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5e/67/5e67eeababacd5e3def86b10ad2e5698_best.jpg",
                                    "width": 689,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712443839_Skin color:712443839:Skin color:https://www.shopstyle.com/g/men/armor-lux/armor-lux-t-shirts",
                                "sku": "712443839",
                                "name": "Armor Lux ARMOR-LUX T-shirts",
                                "url": "https://www.shopstyle.com/g/men/armor-lux/armor-lux-t-shirts",
                                "brand": "Armor Lux",
                                "color": "Skin color",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "ARMOR-LUX T-shirts. jersey, logo, stripes, round collar, short sleeves, no pockets. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 47,
                                "currency": "USD"
                            },
                            "fingerprint": "5bbdb816dd46b7fb861471c2b47505db",
                            "created_at": "2018-05-01T20:53:22.624Z",
                            "updated_at": "2018-06-23T00:15:08.084Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c1336efb8d3d2f801243",
                            "schema": "product",
                            "score": 0.8987185382843017,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/dc/88/dc881ea12fc0c8ce858586b727924eba_best.jpg",
                                    "width": 406,
                                    "height": 496
                                }
                            ],
                            "data": {
                                "id": "659592228_Ecru:659592228:Ecru:https://www.shopstyle.com/g/men/ben-sherman/mens-short-sleeve-geo-target-print-shirt",
                                "sku": "659592228",
                                "name": "Ben Sherman Men's Short Sleeve Geo Target Print Shirt",
                                "url": "https://www.shopstyle.com/g/men/ben-sherman/mens-short-sleeve-geo-target-print-shirt",
                                "brand": "Ben Sherman",
                                "color": "Ecru",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Ben Sherman premium modern fit short sleeve woven with geo target pattern.",
                                "category": "Men > Clothing |  | ",
                                "size": "X-Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39.5,
                                "currency": "USD"
                            },
                            "fingerprint": "695e746d8ead29084fdc9baf94d5f4c3",
                            "created_at": "2018-05-01T19:34:11.173Z",
                            "updated_at": "2018-06-22T20:40:58.035Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b218a27738101552cf23",
                            "schema": "product",
                            "score": 0.8973863887786865,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/64/a5/64a567f515e7ef863066b96f8ca444ec_best.jpg",
                                    "width": 686,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "719991220_White/Navy Stripe:719991220:White/Navy Stripe:https://www.shopstyle.com/g/women/a-pea-in-the-pod/pull-down-drop-shoulder-nursing-top",
                                "sku": "719991220",
                                "name": "A Pea in the Pod Pull Down Drop Shoulder Nursing Top",
                                "url": "https://www.shopstyle.com/g/women/a-pea-in-the-pod/pull-down-drop-shoulder-nursing-top",
                                "brand": "A Pea in the Pod",
                                "color": "White/Navy Stripe",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "nursing Top short Sleeve crew Neck pull Down Nursing Function drop Shoulder mock Layer soft Top striped modal / Spandex french Terry machine Wash Or Dry Clean imported",
                                "category": "Women > Clothing > Maternity > Tops |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 58,
                                "currency": "USD"
                            },
                            "fingerprint": "c4cd34aea4b93d5490e88baa392929d3",
                            "created_at": "2018-05-01T18:29:44.394Z",
                            "updated_at": "2018-06-28T19:13:19.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ba92a277381015565a0e",
                            "schema": "product",
                            "score": 0.8970769596099853,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/89/fc/89fc511d161cad606c827d93815b017c_best.jpg",
                                    "width": 273,
                                    "height": 400
                                }
                            ],
                            "data": {
                                "id": "620422496_Off White Blue:620422496:Off White Blue:https://www.shopstyle.com/g/women/sacai/dixie-border-cotton-tee",
                                "sku": "620422496",
                                "name": "Sacai Dixie Border Cotton Tee",
                                "url": "https://www.shopstyle.com/g/women/sacai/dixie-border-cotton-tee",
                                "brand": "Sacai",
                                "color": "Off White Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Brilliant stripes in a relaxed silhouette. Boatneck. Short sleeves. About 25\" from shoulder to hem. Cotton. Hand wash. Imported. Model shown is 5'10\" (177cm) wearing size Small.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "2 (SMALL)",
                                "age_group": "adult",
                                "stock": true,
                                "price": 259,
                                "currency": "USD"
                            },
                            "fingerprint": "e8c8d18992f97f2ded2765560b087929",
                            "created_at": "2018-05-01T19:05:54.163Z",
                            "updated_at": "2018-06-22T23:43:07.931Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ffa56efb8d3d2f8eccba",
                            "schema": "product",
                            "score": 0.8967910480499268,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/fb/aa/fbaa0efb5be5de3750da49e1f5afadf7_best.jpg",
                                    "width": 455,
                                    "height": 434
                                }
                            ],
                            "data": {
                                "id": "695689050_gray:695689050:gray:https://www.shopstyle.com/g/men/saint-laurent/striped-slub-cotton-blend-jersey-t-shirt",
                                "sku": "695689050",
                                "name": "Saint Laurent Striped Slub Cotton-Blend Jersey T-Shirt",
                                "url": "https://www.shopstyle.com/g/men/saint-laurent/striped-slub-cotton-blend-jersey-t-shirt",
                                "brand": "Saint Laurent",
                                "color": "gray",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Saint Laurent 's striped T-shirt has the soft, well-worn appeal of something you've owned for years. Made in France, it's cut for a regular fit from slub cotton-blend jersey that's lightly distressed. Shown here with Saint Laurent Skinny jeans, Saint Laurent leather jacket, Saint Laurent sneakers, Saint Laurent bracelet. - - Grey and off-white slub cotton-blend jersey - Crew neck, distressed - 86% cotton, 14% polyamide - Machine wash - Made in France",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XXL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 490,
                                "currency": "USD"
                            },
                            "fingerprint": "a3a16aeee069238e23576a1169de5012",
                            "created_at": "2018-05-02T00:00:37.932Z",
                            "updated_at": "2018-06-18T12:19:08.974Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-6",
            "index": 6,
            "timestamp": 3000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000007.png",
                "width": 288,
                "height": 512
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "45cefa83-9e32-4061-8465-97b592707f29",
                    "score": 0.995984360575676,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 46.881431579589844,
                        "y": 193.27163696289062,
                        "width": 142.89002227783203,
                        "height": 189.335205078125
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae903c9a2773810155b2b19",
                            "schema": "product",
                            "score": 0.9151873302459717,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0c/f1/0cf17111064a265169f03924ceaffc0a_best.jpg",
                                    "width": 464,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "682877234_Eco Grey Riviera Stripe:682877234:Eco Grey Riviera Stripe:https://www.shopstyle.com/p/wallflower-womens-juniors-knit-plaid-belted-dress/682877234",
                                "sku": "682877234",
                                "name": "WallFlower Women's Juniors Knit Plaid Belted Dress",
                                "url": "https://www.shopstyle.com/p/wallflower-womens-juniors-knit-plaid-belted-dress/682877234",
                                "brand": "WallFlower",
                                "color": "Eco Grey Riviera Stripe",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "WallFlower juniors knit plaid button down belted dress with crochet at the back.",
                                "category": "Women > Clothing > Teens > Dresses |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 11.69,
                                "currency": "USD"
                            },
                            "fingerprint": "0ceeaae31d00d4dd4efabcd441b5843f",
                            "created_at": "2018-05-02T00:18:17.061Z",
                            "updated_at": "2018-06-23T01:44:21.669Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c7df1393dd3d290cc54c",
                            "schema": "product",
                            "score": 0.9127095127105713,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/ca/58/ca583472a36699a41d52f7ffe134e10f_best.jpg",
                                    "width": 489,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "540674191_White:540674191:White:https://www.shopstyle.com/g/men/tavik/striker-short-sleeve-knit",
                                "sku": "540674191",
                                "name": "Tavik Striker Short Sleeve Knit",
                                "url": "https://www.shopstyle.com/g/men/tavik/striker-short-sleeve-knit",
                                "brand": "Tavik",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Take your swing with the fresh Tavik Striker Tee. ; Cotton jersey knit with fine horizontal stripes. ; Crew neck. ; Two-button henley placket. ; Short sleeves. ; Straight hemline. ; Woven brand patch at the left hip. ; 100% cotton. ; Machine wash cold- tumble dry low. ; Imported. Measurements: ; Length: 29 in ; Product measurements were taken using size MD. Please note that measurements may vary by size.",
                                "category": "Men > Clothing > Shirts > Shortsleeve |  | ",
                                "size": "SM",
                                "age_group": "adult",
                                "stock": true,
                                "price": 34,
                                "currency": "USD"
                            },
                            "fingerprint": "3a47d0e420ce094a14670b5fc010419a",
                            "created_at": "2018-05-01T20:02:39.942Z",
                            "updated_at": "2018-06-18T19:32:06.873Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c0176efb8d3d2f7f692b",
                            "schema": "product",
                            "score": 0.903253231048584,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/07/a6/07a6f12eeeac248b791cd47daeb5cbf8_best.jpg",
                                    "width": 430,
                                    "height": 496
                                }
                            ],
                            "data": {
                                "id": "660232319_Ivory/Multi:660232319:Ivory/Multi:https://www.shopstyle.com/g/women/joie/womens-nieva-dress",
                                "sku": "660232319",
                                "name": "Joie Women's Nieva Dress",
                                "url": "https://www.shopstyle.com/g/women/joie/womens-nieva-dress",
                                "brand": "Joie",
                                "color": "Ivory/Multi",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "This long sleeve dress features unique layered tassels along with contrasting embroidery at the sleeves and front of the dress.",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49.5,
                                "currency": "USD"
                            },
                            "fingerprint": "c49da966065fe2a41d8ccdf0bb692e65",
                            "created_at": "2018-05-01T19:29:27.406Z",
                            "updated_at": "2018-06-28T19:39:22.092Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c0176efb8d3d2f7f692b",
                            "schema": "product",
                            "score": 0.903253231048584,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/07/a6/07a6f12eeeac248b791cd47daeb5cbf8_best.jpg",
                                    "width": 430,
                                    "height": 496
                                }
                            ],
                            "data": {
                                "id": "660232319_Ivory/Multi:660232319:Ivory/Multi:https://www.shopstyle.com/g/women/joie/womens-nieva-dress",
                                "sku": "660232319",
                                "name": "Joie Women's Nieva Dress",
                                "url": "https://www.shopstyle.com/g/women/joie/womens-nieva-dress",
                                "brand": "Joie",
                                "color": "Ivory/Multi",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "This long sleeve dress features unique layered tassels along with contrasting embroidery at the sleeves and front of the dress.",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49.5,
                                "currency": "USD"
                            },
                            "fingerprint": "c49da966065fe2a41d8ccdf0bb692e65",
                            "created_at": "2018-05-01T19:29:27.406Z",
                            "updated_at": "2018-06-28T19:39:22.092Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b74bb77b36100f1425ff",
                            "schema": "product",
                            "score": 0.9014315700531006,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b7/5d/b75d6e638396a4154d19ef691f738e9a_best.jpg",
                                    "width": 306,
                                    "height": 500
                                }
                            ],
                            "data": {
                                "id": "694876984_White - Ecru600:694876984:White - Ecru600:https://www.shopstyle.com/g/men/calvin-klein/mens-bar-stripe-henley-shirt-ecru600-xl",
                                "sku": "694876984",
                                "name": "Calvin Klein Mens Bar Stripe Henley Shirt ecru600 XL",
                                "url": "https://www.shopstyle.com/g/men/calvin-klein/mens-bar-stripe-henley-shirt-ecru600-xl",
                                "brand": "Calvin Klein",
                                "color": "White - Ecru600",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Heathered striped cotton casual tee shirt 2 button placket henley collar Left side chest pocket with metal logo on top Short sleeves",
                                "category": "Men > Clothing |  | ",
                                "size": "X-Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39.49,
                                "currency": "USD"
                            },
                            "fingerprint": "f5acecf22a5d7264600e177fd4dc4da3",
                            "created_at": "2018-05-01T18:51:55.693Z",
                            "updated_at": "2018-06-25T18:00:06.794Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d3c21393dd3d29137d0a",
                            "schema": "product",
                            "score": 0.8999680233001709,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5e/67/5e67eeababacd5e3def86b10ad2e5698_best.jpg",
                                    "width": 689,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712443839_Skin color:712443839:Skin color:https://www.shopstyle.com/g/men/armor-lux/armor-lux-t-shirts",
                                "sku": "712443839",
                                "name": "Armor Lux ARMOR-LUX T-shirts",
                                "url": "https://www.shopstyle.com/g/men/armor-lux/armor-lux-t-shirts",
                                "brand": "Armor Lux",
                                "color": "Skin color",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "ARMOR-LUX T-shirts. jersey, logo, stripes, round collar, short sleeves, no pockets. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 47,
                                "currency": "USD"
                            },
                            "fingerprint": "5bbdb816dd46b7fb861471c2b47505db",
                            "created_at": "2018-05-01T20:53:22.624Z",
                            "updated_at": "2018-06-23T00:15:08.084Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c1336efb8d3d2f801243",
                            "schema": "product",
                            "score": 0.8987185382843017,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/dc/88/dc881ea12fc0c8ce858586b727924eba_best.jpg",
                                    "width": 406,
                                    "height": 496
                                }
                            ],
                            "data": {
                                "id": "659592228_Ecru:659592228:Ecru:https://www.shopstyle.com/g/men/ben-sherman/mens-short-sleeve-geo-target-print-shirt",
                                "sku": "659592228",
                                "name": "Ben Sherman Men's Short Sleeve Geo Target Print Shirt",
                                "url": "https://www.shopstyle.com/g/men/ben-sherman/mens-short-sleeve-geo-target-print-shirt",
                                "brand": "Ben Sherman",
                                "color": "Ecru",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Ben Sherman premium modern fit short sleeve woven with geo target pattern.",
                                "category": "Men > Clothing |  | ",
                                "size": "X-Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39.5,
                                "currency": "USD"
                            },
                            "fingerprint": "695e746d8ead29084fdc9baf94d5f4c3",
                            "created_at": "2018-05-01T19:34:11.173Z",
                            "updated_at": "2018-06-22T20:40:58.035Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b218a27738101552cf23",
                            "schema": "product",
                            "score": 0.8973863887786865,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/64/a5/64a567f515e7ef863066b96f8ca444ec_best.jpg",
                                    "width": 686,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "719991220_White/Navy Stripe:719991220:White/Navy Stripe:https://www.shopstyle.com/g/women/a-pea-in-the-pod/pull-down-drop-shoulder-nursing-top",
                                "sku": "719991220",
                                "name": "A Pea in the Pod Pull Down Drop Shoulder Nursing Top",
                                "url": "https://www.shopstyle.com/g/women/a-pea-in-the-pod/pull-down-drop-shoulder-nursing-top",
                                "brand": "A Pea in the Pod",
                                "color": "White/Navy Stripe",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "nursing Top short Sleeve crew Neck pull Down Nursing Function drop Shoulder mock Layer soft Top striped modal / Spandex french Terry machine Wash Or Dry Clean imported",
                                "category": "Women > Clothing > Maternity > Tops |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 58,
                                "currency": "USD"
                            },
                            "fingerprint": "c4cd34aea4b93d5490e88baa392929d3",
                            "created_at": "2018-05-01T18:29:44.394Z",
                            "updated_at": "2018-06-28T19:13:19.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ba92a277381015565a0e",
                            "schema": "product",
                            "score": 0.8970769596099853,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/89/fc/89fc511d161cad606c827d93815b017c_best.jpg",
                                    "width": 273,
                                    "height": 400
                                }
                            ],
                            "data": {
                                "id": "620422496_Off White Blue:620422496:Off White Blue:https://www.shopstyle.com/g/women/sacai/dixie-border-cotton-tee",
                                "sku": "620422496",
                                "name": "Sacai Dixie Border Cotton Tee",
                                "url": "https://www.shopstyle.com/g/women/sacai/dixie-border-cotton-tee",
                                "brand": "Sacai",
                                "color": "Off White Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Brilliant stripes in a relaxed silhouette. Boatneck. Short sleeves. About 25\" from shoulder to hem. Cotton. Hand wash. Imported. Model shown is 5'10\" (177cm) wearing size Small.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "2 (SMALL)",
                                "age_group": "adult",
                                "stock": true,
                                "price": 259,
                                "currency": "USD"
                            },
                            "fingerprint": "e8c8d18992f97f2ded2765560b087929",
                            "created_at": "2018-05-01T19:05:54.163Z",
                            "updated_at": "2018-06-22T23:43:07.931Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ffa56efb8d3d2f8eccba",
                            "schema": "product",
                            "score": 0.8967910480499268,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/fb/aa/fbaa0efb5be5de3750da49e1f5afadf7_best.jpg",
                                    "width": 455,
                                    "height": 434
                                }
                            ],
                            "data": {
                                "id": "695689050_gray:695689050:gray:https://www.shopstyle.com/g/men/saint-laurent/striped-slub-cotton-blend-jersey-t-shirt",
                                "sku": "695689050",
                                "name": "Saint Laurent Striped Slub Cotton-Blend Jersey T-Shirt",
                                "url": "https://www.shopstyle.com/g/men/saint-laurent/striped-slub-cotton-blend-jersey-t-shirt",
                                "brand": "Saint Laurent",
                                "color": "gray",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Saint Laurent 's striped T-shirt has the soft, well-worn appeal of something you've owned for years. Made in France, it's cut for a regular fit from slub cotton-blend jersey that's lightly distressed. Shown here with Saint Laurent Skinny jeans, Saint Laurent leather jacket, Saint Laurent sneakers, Saint Laurent bracelet. - - Grey and off-white slub cotton-blend jersey - Crew neck, distressed - 86% cotton, 14% polyamide - Machine wash - Made in France",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XXL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 490,
                                "currency": "USD"
                            },
                            "fingerprint": "a3a16aeee069238e23576a1169de5012",
                            "created_at": "2018-05-02T00:00:37.932Z",
                            "updated_at": "2018-06-18T12:19:08.974Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-7",
            "index": 7,
            "timestamp": 3500,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000008.png",
                "width": 288,
                "height": 512
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-8",
            "index": 8,
            "timestamp": 4000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000009.png",
                "width": 288,
                "height": 512
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "d06b8d87-2ade-44e2-9615-9da4da95aea6",
                    "score": 0.9811553508043289,
                    "category": {
                        "_type": "Category",
                        "_id": "jeans",
                        "name": "jeans"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 8.557598114013672,
                        "y": 290.5306396484375,
                        "width": 29.394081115722656,
                        "height": 79.0809326171875
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b468a27738101553baf3",
                            "schema": "product",
                            "score": 0.8975136947631837,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/47/22/47227779e77f7fa67f02a22e558adbc7_best.jpg",
                                    "width": 369,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "704446986_LIGHT BLUE|Celeste:704446986:LIGHT BLUE|Celeste:https://www.shopstyle.com/p/dafni-frayed-jeans/704446986",
                                "sku": "704446986",
                                "name": "Dafni Frayed Jeans",
                                "url": "https://www.shopstyle.com/p/dafni-frayed-jeans/704446986",
                                "brand": "Vivetta",
                                "color": "LIGHT BLUE|Celeste",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Vivetta jeans with heart-shaped cut-outs and frayed hem. Zip and logo button closure, two front pockets and two back pockets with faded effect. Contrast seams. Model height is 177 cm and she is wearing a size IT 38. Composition: 100% cotone",
                                "category": "Women > Clothing > Denim |  | ",
                                "size": "42",
                                "age_group": "adult",
                                "stock": true,
                                "price": 423,
                                "currency": "USD"
                            },
                            "fingerprint": "ee033593ea9b765d417d9c927a491ce1",
                            "created_at": "2018-05-01T18:39:36.688Z",
                            "updated_at": "2018-06-25T17:16:09.024Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fe80a277381015580680",
                            "schema": "product",
                            "score": 0.8972167110443116,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/57/ab/57ab00abcba24d2e97f1a879081cbb70_best.jpg",
                                    "width": 226,
                                    "height": 521
                                }
                            ],
                            "data": {
                                "id": "705514513_Blue:705514513:Blue:https://www.shopstyle.com/g/men/dsquared2/slim-fit-patchwork-jeans",
                                "sku": "705514513",
                                "name": "DSQUARED2 Slim Fit Patchwork Jeans",
                                "url": "https://www.shopstyle.com/g/men/dsquared2/slim-fit-patchwork-jeans",
                                "brand": "DSQUARED2",
                                "color": "Blue",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Amply bleach-washed and distressed denim jeans repurpose pockets for patches, reconstructing a slender-fit style for a heavily worn vintage appearance. Style Name:Dsquared2 Slim Fit Patchwork Jeans. Style Number: 5490958. Available in stores.",
                                "category": "Men > Clothing > Jeans > Distressed |  | ",
                                "size": "52 EU",
                                "age_group": "adult",
                                "stock": true,
                                "price": 785,
                                "currency": "USD"
                            },
                            "fingerprint": "32d47f5566179ebbf0e501f20e088201",
                            "created_at": "2018-05-01T23:55:44.455Z",
                            "updated_at": "2018-06-23T01:14:13.585Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7be1d1393dd3d2908566d",
                            "schema": "product",
                            "score": 0.8946675682067871,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a8/c1/a8c188fd2fb5641537f97b61ee6a4b18_best.jpg",
                                    "width": 347,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "720844654_Blue:720844654:Blue:https://www.shopstyle.com/p/vivetta-lip-embroidered-jeans/720844654",
                                "sku": "720844654",
                                "name": "Vivetta lip embroidered jeans",
                                "url": "https://www.shopstyle.com/p/vivetta-lip-embroidered-jeans/720844654",
                                "brand": "Vivetta",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Blue cotton lip embroidered jeans from Vivetta featuring a waistband with belt loops, a button and zip fly, a five pocket design and a high rise.",
                                "category": "Women > Clothing > Denim |  | ",
                                "size": "42",
                                "age_group": "adult",
                                "stock": true,
                                "price": 446,
                                "currency": "USD"
                            },
                            "fingerprint": "cb7d6dc582afd4ad75d7d6a834772861",
                            "created_at": "2018-05-01T01:08:45.730Z",
                            "updated_at": "2018-06-23T01:07:17.987Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8eb846efb8d3d2f837e6e",
                            "schema": "product",
                            "score": 0.8935240268707275,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/f7/8b/f78b279cd66bebf1e797b3ca5a3adee0_best.jpg",
                                    "width": 496,
                                    "height": 792
                                }
                            ],
                            "data": {
                                "id": "707240306_Via Colombo:707240306:Via Colombo:https://www.shopstyle.com/p/mcguire-high-rise-ibiza-jean/707240306",
                                "sku": "707240306",
                                "name": "McGuire High Rise Ibiza Jean",
                                "url": "https://www.shopstyle.com/p/mcguire-high-rise-ibiza-jean/707240306",
                                "brand": "McGuire",
                                "color": "Via Colombo",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "81% Cotton, 13% Modal, 5% T400, 1% Spandex Made in the USA",
                                "category": "Women > Clothing > Denim |  | ",
                                "size": "23",
                                "age_group": "adult",
                                "stock": true,
                                "price": 268,
                                "currency": "USD"
                            },
                            "fingerprint": "4d184f4a2b940c750e2957633a4e025b",
                            "created_at": "2018-05-01T22:34:44.634Z",
                            "updated_at": "2018-06-25T19:35:12.528Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fd6fa27738101557662b",
                            "schema": "product",
                            "score": 0.8934673404693604,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5a/ac/5aac68a2b6f07114fa41a4f889850d15_best.jpg",
                                    "width": 298,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "718489466_Blue:718489466:Blue:https://www.shopstyle.com/p/vivetta-embroidered-lip-patch-mom-jeans/718489466",
                                "sku": "718489466",
                                "name": "Vivetta embroidered lip patch mom jeans",
                                "url": "https://www.shopstyle.com/p/vivetta-embroidered-lip-patch-mom-jeans/718489466",
                                "brand": "Vivetta",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Light blue cotton embroidered lip patch mom jeans from Vivetta featuring a high waist, a button and zip fly, a waistband with belt loops, a five pocket design, a relaxed shape, a regular length, a faded effect and embroidered lip patch details.",
                                "category": "Women > Clothing > Denim > Relaxed |  | ",
                                "size": "44",
                                "age_group": "adult",
                                "stock": true,
                                "price": 439,
                                "currency": "USD"
                            },
                            "fingerprint": "62a4c0254c03d000081a70d9636c31a1",
                            "created_at": "2018-05-01T23:51:11.224Z",
                            "updated_at": "2018-06-23T01:14:12.798Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c2e06efb8d3d2f810433",
                            "schema": "product",
                            "score": 0.8932852554321289,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/35/84/358440d65b65025b9d2da42d443dafd1_best.jpg",
                                    "width": 325,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "689311382_Multicolour:689311382:Multicolour:https://www.shopstyle.com/g/women/moschino/tapered-trompe-loeil-trousers",
                                "sku": "689311382",
                                "name": "Moschino tapered trompe l'oeil trousers",
                                "url": "https://www.shopstyle.com/g/women/moschino/tapered-trompe-loeil-trousers",
                                "brand": "Moschino",
                                "color": "Multicolour",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Jeremy Scott creates punchy designs under the house of Moschino. Expect to see colour blocked designs, pop culture motifs and bold brand logos. Multicoloured virgin wool blend tapered trompe l'oeil trousers featuring a high rise, a five pocket design and a button and zip fly.",
                                "category": "Women > Clothing > Pants |  | ",
                                "size": "42",
                                "age_group": "adult",
                                "stock": true,
                                "price": 1150,
                                "currency": "USD"
                            },
                            "fingerprint": "c87917bd0533f308386d003776b19c89",
                            "created_at": "2018-05-01T19:41:20.658Z",
                            "updated_at": "2018-06-18T23:40:07.204Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e06db77b36100f15cfb8",
                            "schema": "product",
                            "score": 0.8923169326782227,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b2/a6/b2a674ec5186f36055457ddc874e4dcc_best.jpg",
                                    "width": 361,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "655399097_Blue:655399097:Blue:https://www.shopstyle.com/p/dont-cry-jeans/655399097",
                                "sku": "655399097",
                                "name": "DON'T CRY Jeans",
                                "url": "https://www.shopstyle.com/p/dont-cry-jeans/655399097",
                                "brand": "DON'T CRY",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "DON&apos;T CRY Jeans. denim, faded effect, logo, patchwork design, dark wash, mid rise, front closure, button, zip, multipockets, contains non-textile parts of animal origin, straight leg. 100% Cotton",
                                "category": "Women > Clothing > Denim > Straight |  | ",
                                "size": "27",
                                "age_group": "adult",
                                "stock": true,
                                "price": 199,
                                "currency": "USD"
                            },
                            "fingerprint": "60c0c13962d24a5af017bca2860ed8de",
                            "created_at": "2018-05-01T21:47:25.936Z",
                            "updated_at": "2018-07-06T00:03:36.548Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee2a6efb8d3d2f850c07",
                            "schema": "product",
                            "score": 0.8917850589752198,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/eb/37/eb372fa0353f104705aabbdeab2764b5_best.jpg",
                                    "width": 360,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "650196997_Blue:650196997:Blue:https://www.shopstyle.com/g/women/stella-mccartney/embellished-wide-leg-jeans",
                                "sku": "650196997",
                                "name": "Stella McCartney embellished wide-leg jeans",
                                "url": "https://www.shopstyle.com/g/women/stella-mccartney/embellished-wide-leg-jeans",
                                "brand": "Stella McCartney",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Stella McCartney clash and complement a variety of styles with their SS17 collection. As always, the English fashion house create their chic garments and accessories without eschewing the ethical ambitions the brand was built on. These classic blue stretch cotton wide-leg jeans from Stella McCartney feature a front button fastening, embellishments at the leg, belt loops, a cropped length and a five pocket design.",
                                "category": "Women > Clothing > Denim > Cropped |  | ",
                                "size": "27",
                                "age_group": "adult",
                                "stock": true,
                                "price": 645,
                                "currency": "USD"
                            },
                            "fingerprint": "8eabf8724acded3ee4ace5f5ce3a5f1f",
                            "created_at": "2018-05-01T22:46:02.806Z",
                            "updated_at": "2018-06-23T01:02:32.466Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8f0536efb8d3d2f864d4e",
                            "schema": "product",
                            "score": 0.891236982345581,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/be/00/be0054c41d00285796a50ae11d7053ad_best.jpg",
                                    "width": 410,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "717038738_Stripe Pale Blue/White:717038738:Stripe Pale Blue/White:https://www.shopstyle.com/g/women/ralph-lauren/striped-jersey-pajama-pant",
                                "sku": "717038738",
                                "name": "Ralph Lauren Striped Jersey Pajama Pant",
                                "url": "https://www.shopstyle.com/g/women/ralph-lauren/striped-jersey-pajama-pant",
                                "brand": "Ralph Lauren",
                                "color": "Stripe Pale Blue/White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Details Signature \"Lauren Ralph Lauren\" elasticized waist. Two-button fly. Size medium has a 30\" inseam. 100% cotton. Machine washable. Imported. Model is 5'10\"/178 cm and wears a size small. Overview This loungewear essential combines smooth jersey with an easy-fitting design inspired by men's boxer briefs. Style Number: 365710",
                                "category": "Women > Clothing > Intimates > Pajamas |  | ",
                                "size": "L",
                                "age_group": "adult",
                                "stock": true,
                                "price": 40,
                                "currency": "USD"
                            },
                            "fingerprint": "9a42fbf97f31b051b42f271b088e2375",
                            "created_at": "2018-05-01T22:55:15.781Z",
                            "updated_at": "2018-06-25T18:17:07.707Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b1b5a2773810155292e4",
                            "schema": "product",
                            "score": 0.8905060386657715,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/bf/99/bf997c534ad549811cc99ff90683e15f_best.jpg",
                                    "width": 379,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "699695600_Blue:699695600:Blue:https://www.shopstyle.com/g/women/r-13/cut-out-cropped-jeans",
                                "sku": "699695600",
                                "name": "R 13 cut out cropped jeans",
                                "url": "https://www.shopstyle.com/g/women/r-13/cut-out-cropped-jeans",
                                "brand": "R 13",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Light blue cotton cut out cropped jeans from R13 featuring a mid rise, a waistband with belt loops, a button and zip fly, a cut out detail, distressed effects, a turned up hem and a cropped length.",
                                "category": "Women > Clothing > Denim > Cropped |  | ",
                                "size": "26",
                                "age_group": "adult",
                                "stock": true,
                                "price": 637,
                                "currency": "USD"
                            },
                            "fingerprint": "060d2fe537f753f392bb1441f5e2a0e5",
                            "created_at": "2018-05-01T18:28:05.181Z",
                            "updated_at": "2018-06-23T01:05:21.307Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-9",
            "index": 9,
            "timestamp": 4500,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000010.png",
                "width": 288,
                "height": 512
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-10",
            "index": 10,
            "timestamp": 5000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000011.png",
                "width": 288,
                "height": 512
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "429330f6-20e3-45c3-bd15-9c62ac2e68f5",
                    "score": 0.8677928745746613,
                    "category": {
                        "_type": "Category",
                        "_id": "dresses",
                        "name": "dresses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 159.29212951660156,
                        "y": 221.1651611328125,
                        "width": 75.26742553710938,
                        "height": 134.16497802734375
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c5d01393dd3d290b95d2",
                            "schema": "product",
                            "score": 0.8424236392974853,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/85/d3/85d30817ca33f785d58f4e5eecd14abb_best.jpg",
                                    "width": 407,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "703702616_Black:703702616:Black:https://www.shopstyle.com/g/women/herve-leger/short-dresses",
                                "sku": "703702616",
                                "name": "Herve Leger Short dresses",
                                "url": "https://www.shopstyle.com/g/women/herve-leger/short-dresses",
                                "brand": "Herve Leger",
                                "color": "Black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "HERVÉ LÉGER Short dresses. jersey, no appliqués, basic solid color, deep neckline, sleeveless, no pockets, unlined, rear closure, zip, pencil style, small sized. 90% Rayon, 9% Nylon, 1% Elastane",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "L",
                                "age_group": "adult",
                                "stock": true,
                                "price": 1800,
                                "currency": "USD"
                            },
                            "fingerprint": "b1a9084424cbf0238216aa1320d2643e",
                            "created_at": "2018-05-01T19:53:52.520Z",
                            "updated_at": "2018-06-22T21:16:08.529Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c2016efb8d3d2f808c90",
                            "schema": "product",
                            "score": 0.8388369369506836,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9f/e0/9fe09074d29e342a9f45ff91d2912f7a_best.jpg",
                                    "width": 130,
                                    "height": 500
                                }
                            ],
                            "data": {
                                "id": "721094678_Black:721094678:Black:https://www.shopstyle.com/g/women/tahari-by-arthur-s-levine/womens-relaxed-fit-chemical-lace-dress-with-tie-at-waist",
                                "sku": "721094678",
                                "name": "Tahari by Arthur S. Levine Women's Relaxed Fit Chemical Lace Dress with Tie AT Waist",
                                "url": "https://www.shopstyle.com/g/women/tahari-by-arthur-s-levine/womens-relaxed-fit-chemical-lace-dress-with-tie-at-waist",
                                "brand": "Tahari by Arthur S. Levine",
                                "color": "Black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Chemical lace dress tie at waist relaxed fit dresses seasonal demand",
                                "category": "Women > Clothing > Pants |  | ",
                                "size": "6",
                                "age_group": "adult",
                                "stock": true,
                                "price": 108,
                                "currency": "USD"
                            },
                            "fingerprint": "10cb0d9147ef1714c9bfae8a1c263e42",
                            "created_at": "2018-05-01T19:37:37.516Z",
                            "updated_at": "2018-06-28T15:09:16.820Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d5e21393dd3d2914a152",
                            "schema": "product",
                            "score": 0.8387387275695801,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/26/98/2698b710003475ba7c25c63efcaf5957_best.jpg",
                                    "width": 253,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "697672547_Black:697672547:Black:https://www.shopstyle.com/g/women/susana-monaco/ella-sheath-dress",
                                "sku": "697672547",
                                "name": "Susana Monaco Ella Sheath Dress",
                                "url": "https://www.shopstyle.com/g/women/susana-monaco/ella-sheath-dress",
                                "brand": "Susana Monaco",
                                "color": "Black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Color/pattern: black Approximately 34in from shoulder to hem Measurement was taken from a size small and may vary slightly by size Model is 5ft 8in Design details: plunging neckline with strap detail, strappy back Slip-on styling 86% supplex? nylon, 14% lycra Machine wash Made in the USA",
                                "category": "Women > Clothing > Dresses > Day |  | ",
                                "size": "L",
                                "age_group": "adult",
                                "stock": true,
                                "price": 174,
                                "currency": "USD"
                            },
                            "fingerprint": "79f18d157b956e0ee001529aa131c780",
                            "created_at": "2018-05-01T21:02:26.476Z",
                            "updated_at": "2018-07-06T00:15:57.419Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90c976efb8d3d2f9129e9",
                            "schema": "product",
                            "score": 0.8361745071411133,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/d7/f0/d7f0a023e56ee81bd89cdc789198ca8d_best.jpg",
                                    "width": 184,
                                    "height": 478
                                }
                            ],
                            "data": {
                                "id": "642172273_Black:642172273:Black:https://www.shopstyle.com/g/women/vena-cava/wool-mini-dress",
                                "sku": "642172273",
                                "name": "Vena Cava Wool Mini Dress",
                                "url": "https://www.shopstyle.com/g/women/vena-cava/wool-mini-dress",
                                "brand": "Vena Cava",
                                "color": "Black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Black Vena Cava wool sleeveless mini dress with V-neck, beaded trim at bust, rope accent at waist and concealed zip closure at side.",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "XS | US 0",
                                "age_group": "adult",
                                "stock": true,
                                "price": 75,
                                "currency": "USD"
                            },
                            "fingerprint": "e94dbd691d99f82f81f086abb8af881e",
                            "created_at": "2018-05-02T00:55:51.298Z",
                            "updated_at": "2018-06-26T20:03:06.248Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8da3f1393dd3d2916f8f3",
                            "schema": "product",
                            "score": 0.8349379348754883,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/df/57/df57f76e43aa07add7a12fe86518c4a7_best.jpg",
                                    "width": 105,
                                    "height": 392
                                }
                            ],
                            "data": {
                                "id": "712953634_Black:712953634:Black:https://www.shopstyle.com/g/women/black-halo/behati-x-back-mini-bodycon-dress",
                                "sku": "712953634",
                                "name": "Black Halo Behati X-Back Mini Bodycon Dress",
                                "url": "https://www.shopstyle.com/g/women/black-halo/behati-x-back-mini-bodycon-dress",
                                "brand": "Black Halo",
                                "color": "Black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Solid dress meticulously designed with X-back Double straps at shoulder Sweetheart neckline Back zipper closure Lined About 33\" from shoulder to hem Polyamide/viscose/elastane Dry clean Made in USA Model shown is 5'10\" (177cm) wearing US size 4",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "12",
                                "age_group": "adult",
                                "stock": true,
                                "price": 325,
                                "currency": "USD"
                            },
                            "fingerprint": "3efa4915ec13c12c675f5eb414e6e8ce",
                            "created_at": "2018-05-01T21:21:03.381Z",
                            "updated_at": "2018-07-06T00:10:35.880Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c0fe6efb8d3d2f7ff297",
                            "schema": "product",
                            "score": 0.8346970558166504,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/3b/ad/3bad379244e1d73cce674ea3dab7196c_best.jpg",
                                    "width": 600,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "703444176_black:703444176:black:https://www.shopstyle.com/g/women/boohoo/tall-gabriella-strappy-back-lace-bodysuit",
                                "sku": "703444176",
                                "name": "boohoo Tall Gabriella Strappy Back Lace Bodysuit",
                                "url": "https://www.shopstyle.com/g/women/boohoo/tall-gabriella-strappy-back-lace-bodysuit",
                                "brand": "boohoo",
                                "color": "black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Meet your new go-to tops. Featuring failsafe basics, achingly-cool tees, wow-worthy bodysuits, and many more; the boohoo Tall collection includes all of the core pieces you wardrobe is missing. Stock up.",
                                "category": "Women > Clothing > Tops |  | ",
                                "size": "6",
                                "age_group": "adult",
                                "stock": true,
                                "price": 19,
                                "currency": "USD"
                            },
                            "fingerprint": "e99c36a356ab4f47912632a68a154c21",
                            "created_at": "2018-05-01T19:33:18.044Z",
                            "updated_at": "2018-06-28T14:52:12.044Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8f8026efb8d3d2f8aa8ae",
                            "schema": "product",
                            "score": 0.8319544792175293,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/42/aa/42aa7e8caa600f0fb48ebed7573ab65f_best.jpg",
                                    "width": 241,
                                    "height": 537
                                }
                            ],
                            "data": {
                                "id": "652520337_Black:652520337:Black:https://www.shopstyle.com/p/misa-los-angeles-domino-dress/652520337",
                                "sku": "652520337",
                                "name": "MISA LOS ANGELES Domino Dress",
                                "url": "https://www.shopstyle.com/p/misa-los-angeles-domino-dress/652520337",
                                "brand": "MISA LOS ANGELES",
                                "color": "Black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Get ready for date night in no time with this slinky dress tailored with a flattering knotted front and slender racerback straps. Style Name:Misa Los Angeles Domino Dress. Style Number: 5405407. Available in stores.",
                                "category": "Women > Clothing > Dresses > Day |  | ",
                                "size": "X-Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 180,
                                "currency": "USD"
                            },
                            "fingerprint": "9bf163918e45eb11ac9e1d5eae7188ae",
                            "created_at": "2018-05-01T23:28:02.342Z",
                            "updated_at": "2018-07-05T23:54:07.104Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b1a3b77b36100f11ec12",
                            "schema": "product",
                            "score": 0.830588207244873,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b1/49/b149ae47383cb98dadf2cabf2555dc9b_best.jpg",
                                    "width": 210,
                                    "height": 400
                                }
                            ],
                            "data": {
                                "id": "692156741_BLACK:692156741:BLACK:https://www.shopstyle.com/g/women/guess/double-embroidered-sheath-dress",
                                "sku": "692156741",
                                "name": "GUESS Double Embroidered Sheath Dress",
                                "url": "https://www.shopstyle.com/g/women/guess/double-embroidered-sheath-dress",
                                "brand": "GUESS",
                                "color": "BLACK",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Double Embroidered Sheath Dress;Skin-baring dress with crisscross straps on open back. Deep V-neck. Sleeveless. Concealed back zip. Lined. About 33\" from shoulder to hem. Polyester/spandex. Hand wash. Imported. Model shown is 5'10\" (177cm) wearing US size 4.",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "8",
                                "age_group": "adult",
                                "stock": true,
                                "price": 128,
                                "currency": "USD"
                            },
                            "fingerprint": "829fb97110017344464562a29a014f0d",
                            "created_at": "2018-05-01T00:15:31.478Z",
                            "updated_at": "2018-06-18T23:28:08.550Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8bad7b77b36100f14d2d6",
                            "schema": "product",
                            "score": 0.8304223442077636,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/da/3b/da3bc915bde4ece7388ee3b2160daabf_best.jpg",
                                    "width": 452,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "706491293_NERO:706491293:NERO:https://www.shopstyle.com/g/women/moschino/olivia-printed-dress",
                                "sku": "706491293",
                                "name": "Moschino Olivia Printed Dress",
                                "url": "https://www.shopstyle.com/g/women/moschino/olivia-printed-dress",
                                "brand": "Moschino",
                                "color": "NERO",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "- ROUND COLLAR- SHORT PUFF SLEEVE- FRONT SATIN LACE UP CLOSURE WITH METAL EYELETS- OLIVIA PRINT- UNLINED- 95% VISCOSE, 5% OTHER FABRIC - MADE IN ITALY- MODEL IS WEARING SIZE 42",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "44",
                                "age_group": "adult",
                                "stock": true,
                                "price": 513,
                                "currency": "USD"
                            },
                            "fingerprint": "878970eaad573283695d6b1e1c784b00",
                            "created_at": "2018-05-01T19:07:03.300Z",
                            "updated_at": "2018-06-28T14:42:19.958Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b3f860cf5e48d63c66f5",
                            "schema": "product",
                            "score": 0.8298108291625976,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/af/8a/af8a34f75d9fff94442314bfab20b7f9_best.jpg",
                                    "width": 184,
                                    "height": 478
                                }
                            ],
                            "data": {
                                "id": "708090382_Blue:708090382:Blue:https://www.shopstyle.com/g/women/ramy-brook/sleeveless-mini-dress-w-tags",
                                "sku": "708090382",
                                "name": "Ramy Brook Sleeveless Mini Dress w/ Tags",
                                "url": "https://www.shopstyle.com/g/women/ramy-brook/sleeveless-mini-dress-w-tags",
                                "brand": "Ramy Brook",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Blue Ramy Brook sleeveless mini dress with V-neck, embroidered eyelet accents and zip at back.",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "M | US 6",
                                "age_group": "adult",
                                "stock": true,
                                "price": 75,
                                "currency": "USD"
                            },
                            "fingerprint": "bb3802efc7d1b1355aca534108e4d628",
                            "created_at": "2018-05-01T00:25:28.756Z",
                            "updated_at": "2018-06-25T16:31:10.569Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-11",
            "index": 11,
            "timestamp": 5500,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000012.png",
                "width": 288,
                "height": 512
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "d76e7c43-82f9-4ac0-980b-62f3ba744dcd",
                    "score": 0.9811553508043289,
                    "category": {
                        "_type": "Category",
                        "_id": "jeans",
                        "name": "jeans"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 36.77861404418945,
                        "y": 313.5406799316406,
                        "width": 45.47504806518555,
                        "height": 92.0133056640625
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b468a27738101553baf3",
                            "schema": "product",
                            "score": 0.8975136947631837,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/47/22/47227779e77f7fa67f02a22e558adbc7_best.jpg",
                                    "width": 369,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "704446986_LIGHT BLUE|Celeste:704446986:LIGHT BLUE|Celeste:https://www.shopstyle.com/p/dafni-frayed-jeans/704446986",
                                "sku": "704446986",
                                "name": "Dafni Frayed Jeans",
                                "url": "https://www.shopstyle.com/p/dafni-frayed-jeans/704446986",
                                "brand": "Vivetta",
                                "color": "LIGHT BLUE|Celeste",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Vivetta jeans with heart-shaped cut-outs and frayed hem. Zip and logo button closure, two front pockets and two back pockets with faded effect. Contrast seams. Model height is 177 cm and she is wearing a size IT 38. Composition: 100% cotone",
                                "category": "Women > Clothing > Denim |  | ",
                                "size": "42",
                                "age_group": "adult",
                                "stock": true,
                                "price": 423,
                                "currency": "USD"
                            },
                            "fingerprint": "ee033593ea9b765d417d9c927a491ce1",
                            "created_at": "2018-05-01T18:39:36.688Z",
                            "updated_at": "2018-06-25T17:16:09.024Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fe80a277381015580680",
                            "schema": "product",
                            "score": 0.8972167110443116,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/57/ab/57ab00abcba24d2e97f1a879081cbb70_best.jpg",
                                    "width": 226,
                                    "height": 521
                                }
                            ],
                            "data": {
                                "id": "705514513_Blue:705514513:Blue:https://www.shopstyle.com/g/men/dsquared2/slim-fit-patchwork-jeans",
                                "sku": "705514513",
                                "name": "DSQUARED2 Slim Fit Patchwork Jeans",
                                "url": "https://www.shopstyle.com/g/men/dsquared2/slim-fit-patchwork-jeans",
                                "brand": "DSQUARED2",
                                "color": "Blue",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Amply bleach-washed and distressed denim jeans repurpose pockets for patches, reconstructing a slender-fit style for a heavily worn vintage appearance. Style Name:Dsquared2 Slim Fit Patchwork Jeans. Style Number: 5490958. Available in stores.",
                                "category": "Men > Clothing > Jeans > Distressed |  | ",
                                "size": "52 EU",
                                "age_group": "adult",
                                "stock": true,
                                "price": 785,
                                "currency": "USD"
                            },
                            "fingerprint": "32d47f5566179ebbf0e501f20e088201",
                            "created_at": "2018-05-01T23:55:44.455Z",
                            "updated_at": "2018-06-23T01:14:13.585Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7be1d1393dd3d2908566d",
                            "schema": "product",
                            "score": 0.8946675682067871,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a8/c1/a8c188fd2fb5641537f97b61ee6a4b18_best.jpg",
                                    "width": 347,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "720844654_Blue:720844654:Blue:https://www.shopstyle.com/p/vivetta-lip-embroidered-jeans/720844654",
                                "sku": "720844654",
                                "name": "Vivetta lip embroidered jeans",
                                "url": "https://www.shopstyle.com/p/vivetta-lip-embroidered-jeans/720844654",
                                "brand": "Vivetta",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Blue cotton lip embroidered jeans from Vivetta featuring a waistband with belt loops, a button and zip fly, a five pocket design and a high rise.",
                                "category": "Women > Clothing > Denim |  | ",
                                "size": "42",
                                "age_group": "adult",
                                "stock": true,
                                "price": 446,
                                "currency": "USD"
                            },
                            "fingerprint": "cb7d6dc582afd4ad75d7d6a834772861",
                            "created_at": "2018-05-01T01:08:45.730Z",
                            "updated_at": "2018-06-23T01:07:17.987Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8eb846efb8d3d2f837e6e",
                            "schema": "product",
                            "score": 0.8935240268707275,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/f7/8b/f78b279cd66bebf1e797b3ca5a3adee0_best.jpg",
                                    "width": 496,
                                    "height": 792
                                }
                            ],
                            "data": {
                                "id": "707240306_Via Colombo:707240306:Via Colombo:https://www.shopstyle.com/p/mcguire-high-rise-ibiza-jean/707240306",
                                "sku": "707240306",
                                "name": "McGuire High Rise Ibiza Jean",
                                "url": "https://www.shopstyle.com/p/mcguire-high-rise-ibiza-jean/707240306",
                                "brand": "McGuire",
                                "color": "Via Colombo",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "81% Cotton, 13% Modal, 5% T400, 1% Spandex Made in the USA",
                                "category": "Women > Clothing > Denim |  | ",
                                "size": "23",
                                "age_group": "adult",
                                "stock": true,
                                "price": 268,
                                "currency": "USD"
                            },
                            "fingerprint": "4d184f4a2b940c750e2957633a4e025b",
                            "created_at": "2018-05-01T22:34:44.634Z",
                            "updated_at": "2018-06-25T19:35:12.528Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fd6fa27738101557662b",
                            "schema": "product",
                            "score": 0.8934673404693604,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5a/ac/5aac68a2b6f07114fa41a4f889850d15_best.jpg",
                                    "width": 298,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "718489466_Blue:718489466:Blue:https://www.shopstyle.com/p/vivetta-embroidered-lip-patch-mom-jeans/718489466",
                                "sku": "718489466",
                                "name": "Vivetta embroidered lip patch mom jeans",
                                "url": "https://www.shopstyle.com/p/vivetta-embroidered-lip-patch-mom-jeans/718489466",
                                "brand": "Vivetta",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Light blue cotton embroidered lip patch mom jeans from Vivetta featuring a high waist, a button and zip fly, a waistband with belt loops, a five pocket design, a relaxed shape, a regular length, a faded effect and embroidered lip patch details.",
                                "category": "Women > Clothing > Denim > Relaxed |  | ",
                                "size": "44",
                                "age_group": "adult",
                                "stock": true,
                                "price": 439,
                                "currency": "USD"
                            },
                            "fingerprint": "62a4c0254c03d000081a70d9636c31a1",
                            "created_at": "2018-05-01T23:51:11.224Z",
                            "updated_at": "2018-06-23T01:14:12.798Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c2e06efb8d3d2f810433",
                            "schema": "product",
                            "score": 0.8932852554321289,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/35/84/358440d65b65025b9d2da42d443dafd1_best.jpg",
                                    "width": 325,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "689311382_Multicolour:689311382:Multicolour:https://www.shopstyle.com/g/women/moschino/tapered-trompe-loeil-trousers",
                                "sku": "689311382",
                                "name": "Moschino tapered trompe l'oeil trousers",
                                "url": "https://www.shopstyle.com/g/women/moschino/tapered-trompe-loeil-trousers",
                                "brand": "Moschino",
                                "color": "Multicolour",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Jeremy Scott creates punchy designs under the house of Moschino. Expect to see colour blocked designs, pop culture motifs and bold brand logos. Multicoloured virgin wool blend tapered trompe l'oeil trousers featuring a high rise, a five pocket design and a button and zip fly.",
                                "category": "Women > Clothing > Pants |  | ",
                                "size": "42",
                                "age_group": "adult",
                                "stock": true,
                                "price": 1150,
                                "currency": "USD"
                            },
                            "fingerprint": "c87917bd0533f308386d003776b19c89",
                            "created_at": "2018-05-01T19:41:20.658Z",
                            "updated_at": "2018-06-18T23:40:07.204Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e06db77b36100f15cfb8",
                            "schema": "product",
                            "score": 0.8923169326782227,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b2/a6/b2a674ec5186f36055457ddc874e4dcc_best.jpg",
                                    "width": 361,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "655399097_Blue:655399097:Blue:https://www.shopstyle.com/p/dont-cry-jeans/655399097",
                                "sku": "655399097",
                                "name": "DON'T CRY Jeans",
                                "url": "https://www.shopstyle.com/p/dont-cry-jeans/655399097",
                                "brand": "DON'T CRY",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "DON&apos;T CRY Jeans. denim, faded effect, logo, patchwork design, dark wash, mid rise, front closure, button, zip, multipockets, contains non-textile parts of animal origin, straight leg. 100% Cotton",
                                "category": "Women > Clothing > Denim > Straight |  | ",
                                "size": "27",
                                "age_group": "adult",
                                "stock": true,
                                "price": 199,
                                "currency": "USD"
                            },
                            "fingerprint": "60c0c13962d24a5af017bca2860ed8de",
                            "created_at": "2018-05-01T21:47:25.936Z",
                            "updated_at": "2018-07-06T00:03:36.548Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee2a6efb8d3d2f850c07",
                            "schema": "product",
                            "score": 0.8917850589752198,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/eb/37/eb372fa0353f104705aabbdeab2764b5_best.jpg",
                                    "width": 360,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "650196997_Blue:650196997:Blue:https://www.shopstyle.com/g/women/stella-mccartney/embellished-wide-leg-jeans",
                                "sku": "650196997",
                                "name": "Stella McCartney embellished wide-leg jeans",
                                "url": "https://www.shopstyle.com/g/women/stella-mccartney/embellished-wide-leg-jeans",
                                "brand": "Stella McCartney",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Stella McCartney clash and complement a variety of styles with their SS17 collection. As always, the English fashion house create their chic garments and accessories without eschewing the ethical ambitions the brand was built on. These classic blue stretch cotton wide-leg jeans from Stella McCartney feature a front button fastening, embellishments at the leg, belt loops, a cropped length and a five pocket design.",
                                "category": "Women > Clothing > Denim > Cropped |  | ",
                                "size": "27",
                                "age_group": "adult",
                                "stock": true,
                                "price": 645,
                                "currency": "USD"
                            },
                            "fingerprint": "8eabf8724acded3ee4ace5f5ce3a5f1f",
                            "created_at": "2018-05-01T22:46:02.806Z",
                            "updated_at": "2018-06-23T01:02:32.466Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8f0536efb8d3d2f864d4e",
                            "schema": "product",
                            "score": 0.891236982345581,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/be/00/be0054c41d00285796a50ae11d7053ad_best.jpg",
                                    "width": 410,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "717038738_Stripe Pale Blue/White:717038738:Stripe Pale Blue/White:https://www.shopstyle.com/g/women/ralph-lauren/striped-jersey-pajama-pant",
                                "sku": "717038738",
                                "name": "Ralph Lauren Striped Jersey Pajama Pant",
                                "url": "https://www.shopstyle.com/g/women/ralph-lauren/striped-jersey-pajama-pant",
                                "brand": "Ralph Lauren",
                                "color": "Stripe Pale Blue/White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Details Signature \"Lauren Ralph Lauren\" elasticized waist. Two-button fly. Size medium has a 30\" inseam. 100% cotton. Machine washable. Imported. Model is 5'10\"/178 cm and wears a size small. Overview This loungewear essential combines smooth jersey with an easy-fitting design inspired by men's boxer briefs. Style Number: 365710",
                                "category": "Women > Clothing > Intimates > Pajamas |  | ",
                                "size": "L",
                                "age_group": "adult",
                                "stock": true,
                                "price": 40,
                                "currency": "USD"
                            },
                            "fingerprint": "9a42fbf97f31b051b42f271b088e2375",
                            "created_at": "2018-05-01T22:55:15.781Z",
                            "updated_at": "2018-06-25T18:17:07.707Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b1b5a2773810155292e4",
                            "schema": "product",
                            "score": 0.8905060386657715,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/bf/99/bf997c534ad549811cc99ff90683e15f_best.jpg",
                                    "width": 379,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "699695600_Blue:699695600:Blue:https://www.shopstyle.com/g/women/r-13/cut-out-cropped-jeans",
                                "sku": "699695600",
                                "name": "R 13 cut out cropped jeans",
                                "url": "https://www.shopstyle.com/g/women/r-13/cut-out-cropped-jeans",
                                "brand": "R 13",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Light blue cotton cut out cropped jeans from R13 featuring a mid rise, a waistband with belt loops, a button and zip fly, a cut out detail, distressed effects, a turned up hem and a cropped length.",
                                "category": "Women > Clothing > Denim > Cropped |  | ",
                                "size": "26",
                                "age_group": "adult",
                                "stock": true,
                                "price": 637,
                                "currency": "USD"
                            },
                            "fingerprint": "060d2fe537f753f392bb1441f5e2a0e5",
                            "created_at": "2018-05-01T18:28:05.181Z",
                            "updated_at": "2018-06-23T01:05:21.307Z"
                        }
                    ]
                },
                {
                    "_type": "SearchResult",
                    "_id": "97b2bdaa-f054-49d1-9f97-2a64babf56c4",
                    "score": 0.8677928745746613,
                    "category": {
                        "_type": "Category",
                        "_id": "dresses",
                        "name": "dresses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 148.22361755371094,
                        "y": 206.79754638671875,
                        "width": 82.49728393554688,
                        "height": 144.0916748046875
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c5d01393dd3d290b95d2",
                            "schema": "product",
                            "score": 0.8424236392974853,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/85/d3/85d30817ca33f785d58f4e5eecd14abb_best.jpg",
                                    "width": 407,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "703702616_Black:703702616:Black:https://www.shopstyle.com/g/women/herve-leger/short-dresses",
                                "sku": "703702616",
                                "name": "Herve Leger Short dresses",
                                "url": "https://www.shopstyle.com/g/women/herve-leger/short-dresses",
                                "brand": "Herve Leger",
                                "color": "Black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "HERVÉ LÉGER Short dresses. jersey, no appliqués, basic solid color, deep neckline, sleeveless, no pockets, unlined, rear closure, zip, pencil style, small sized. 90% Rayon, 9% Nylon, 1% Elastane",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "L",
                                "age_group": "adult",
                                "stock": true,
                                "price": 1800,
                                "currency": "USD"
                            },
                            "fingerprint": "b1a9084424cbf0238216aa1320d2643e",
                            "created_at": "2018-05-01T19:53:52.520Z",
                            "updated_at": "2018-06-22T21:16:08.529Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c2016efb8d3d2f808c90",
                            "schema": "product",
                            "score": 0.8388369369506836,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9f/e0/9fe09074d29e342a9f45ff91d2912f7a_best.jpg",
                                    "width": 130,
                                    "height": 500
                                }
                            ],
                            "data": {
                                "id": "721094678_Black:721094678:Black:https://www.shopstyle.com/g/women/tahari-by-arthur-s-levine/womens-relaxed-fit-chemical-lace-dress-with-tie-at-waist",
                                "sku": "721094678",
                                "name": "Tahari by Arthur S. Levine Women's Relaxed Fit Chemical Lace Dress with Tie AT Waist",
                                "url": "https://www.shopstyle.com/g/women/tahari-by-arthur-s-levine/womens-relaxed-fit-chemical-lace-dress-with-tie-at-waist",
                                "brand": "Tahari by Arthur S. Levine",
                                "color": "Black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Chemical lace dress tie at waist relaxed fit dresses seasonal demand",
                                "category": "Women > Clothing > Pants |  | ",
                                "size": "6",
                                "age_group": "adult",
                                "stock": true,
                                "price": 108,
                                "currency": "USD"
                            },
                            "fingerprint": "10cb0d9147ef1714c9bfae8a1c263e42",
                            "created_at": "2018-05-01T19:37:37.516Z",
                            "updated_at": "2018-06-28T15:09:16.820Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d5e21393dd3d2914a152",
                            "schema": "product",
                            "score": 0.8387387275695801,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/26/98/2698b710003475ba7c25c63efcaf5957_best.jpg",
                                    "width": 253,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "697672547_Black:697672547:Black:https://www.shopstyle.com/g/women/susana-monaco/ella-sheath-dress",
                                "sku": "697672547",
                                "name": "Susana Monaco Ella Sheath Dress",
                                "url": "https://www.shopstyle.com/g/women/susana-monaco/ella-sheath-dress",
                                "brand": "Susana Monaco",
                                "color": "Black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Color/pattern: black Approximately 34in from shoulder to hem Measurement was taken from a size small and may vary slightly by size Model is 5ft 8in Design details: plunging neckline with strap detail, strappy back Slip-on styling 86% supplex? nylon, 14% lycra Machine wash Made in the USA",
                                "category": "Women > Clothing > Dresses > Day |  | ",
                                "size": "L",
                                "age_group": "adult",
                                "stock": true,
                                "price": 174,
                                "currency": "USD"
                            },
                            "fingerprint": "79f18d157b956e0ee001529aa131c780",
                            "created_at": "2018-05-01T21:02:26.476Z",
                            "updated_at": "2018-07-06T00:15:57.419Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90c976efb8d3d2f9129e9",
                            "schema": "product",
                            "score": 0.8361745071411133,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/d7/f0/d7f0a023e56ee81bd89cdc789198ca8d_best.jpg",
                                    "width": 184,
                                    "height": 478
                                }
                            ],
                            "data": {
                                "id": "642172273_Black:642172273:Black:https://www.shopstyle.com/g/women/vena-cava/wool-mini-dress",
                                "sku": "642172273",
                                "name": "Vena Cava Wool Mini Dress",
                                "url": "https://www.shopstyle.com/g/women/vena-cava/wool-mini-dress",
                                "brand": "Vena Cava",
                                "color": "Black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Black Vena Cava wool sleeveless mini dress with V-neck, beaded trim at bust, rope accent at waist and concealed zip closure at side.",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "XS | US 0",
                                "age_group": "adult",
                                "stock": true,
                                "price": 75,
                                "currency": "USD"
                            },
                            "fingerprint": "e94dbd691d99f82f81f086abb8af881e",
                            "created_at": "2018-05-02T00:55:51.298Z",
                            "updated_at": "2018-06-26T20:03:06.248Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8da3f1393dd3d2916f8f3",
                            "schema": "product",
                            "score": 0.8349379348754883,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/df/57/df57f76e43aa07add7a12fe86518c4a7_best.jpg",
                                    "width": 105,
                                    "height": 392
                                }
                            ],
                            "data": {
                                "id": "712953634_Black:712953634:Black:https://www.shopstyle.com/g/women/black-halo/behati-x-back-mini-bodycon-dress",
                                "sku": "712953634",
                                "name": "Black Halo Behati X-Back Mini Bodycon Dress",
                                "url": "https://www.shopstyle.com/g/women/black-halo/behati-x-back-mini-bodycon-dress",
                                "brand": "Black Halo",
                                "color": "Black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Solid dress meticulously designed with X-back Double straps at shoulder Sweetheart neckline Back zipper closure Lined About 33\" from shoulder to hem Polyamide/viscose/elastane Dry clean Made in USA Model shown is 5'10\" (177cm) wearing US size 4",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "12",
                                "age_group": "adult",
                                "stock": true,
                                "price": 325,
                                "currency": "USD"
                            },
                            "fingerprint": "3efa4915ec13c12c675f5eb414e6e8ce",
                            "created_at": "2018-05-01T21:21:03.381Z",
                            "updated_at": "2018-07-06T00:10:35.880Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c0fe6efb8d3d2f7ff297",
                            "schema": "product",
                            "score": 0.8346970558166504,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/3b/ad/3bad379244e1d73cce674ea3dab7196c_best.jpg",
                                    "width": 600,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "703444176_black:703444176:black:https://www.shopstyle.com/g/women/boohoo/tall-gabriella-strappy-back-lace-bodysuit",
                                "sku": "703444176",
                                "name": "boohoo Tall Gabriella Strappy Back Lace Bodysuit",
                                "url": "https://www.shopstyle.com/g/women/boohoo/tall-gabriella-strappy-back-lace-bodysuit",
                                "brand": "boohoo",
                                "color": "black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Meet your new go-to tops. Featuring failsafe basics, achingly-cool tees, wow-worthy bodysuits, and many more; the boohoo Tall collection includes all of the core pieces you wardrobe is missing. Stock up.",
                                "category": "Women > Clothing > Tops |  | ",
                                "size": "6",
                                "age_group": "adult",
                                "stock": true,
                                "price": 19,
                                "currency": "USD"
                            },
                            "fingerprint": "e99c36a356ab4f47912632a68a154c21",
                            "created_at": "2018-05-01T19:33:18.044Z",
                            "updated_at": "2018-06-28T14:52:12.044Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8f8026efb8d3d2f8aa8ae",
                            "schema": "product",
                            "score": 0.8319544792175293,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/42/aa/42aa7e8caa600f0fb48ebed7573ab65f_best.jpg",
                                    "width": 241,
                                    "height": 537
                                }
                            ],
                            "data": {
                                "id": "652520337_Black:652520337:Black:https://www.shopstyle.com/p/misa-los-angeles-domino-dress/652520337",
                                "sku": "652520337",
                                "name": "MISA LOS ANGELES Domino Dress",
                                "url": "https://www.shopstyle.com/p/misa-los-angeles-domino-dress/652520337",
                                "brand": "MISA LOS ANGELES",
                                "color": "Black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Get ready for date night in no time with this slinky dress tailored with a flattering knotted front and slender racerback straps. Style Name:Misa Los Angeles Domino Dress. Style Number: 5405407. Available in stores.",
                                "category": "Women > Clothing > Dresses > Day |  | ",
                                "size": "X-Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 180,
                                "currency": "USD"
                            },
                            "fingerprint": "9bf163918e45eb11ac9e1d5eae7188ae",
                            "created_at": "2018-05-01T23:28:02.342Z",
                            "updated_at": "2018-07-05T23:54:07.104Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b1a3b77b36100f11ec12",
                            "schema": "product",
                            "score": 0.830588207244873,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b1/49/b149ae47383cb98dadf2cabf2555dc9b_best.jpg",
                                    "width": 210,
                                    "height": 400
                                }
                            ],
                            "data": {
                                "id": "692156741_BLACK:692156741:BLACK:https://www.shopstyle.com/g/women/guess/double-embroidered-sheath-dress",
                                "sku": "692156741",
                                "name": "GUESS Double Embroidered Sheath Dress",
                                "url": "https://www.shopstyle.com/g/women/guess/double-embroidered-sheath-dress",
                                "brand": "GUESS",
                                "color": "BLACK",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Double Embroidered Sheath Dress;Skin-baring dress with crisscross straps on open back. Deep V-neck. Sleeveless. Concealed back zip. Lined. About 33\" from shoulder to hem. Polyester/spandex. Hand wash. Imported. Model shown is 5'10\" (177cm) wearing US size 4.",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "8",
                                "age_group": "adult",
                                "stock": true,
                                "price": 128,
                                "currency": "USD"
                            },
                            "fingerprint": "829fb97110017344464562a29a014f0d",
                            "created_at": "2018-05-01T00:15:31.478Z",
                            "updated_at": "2018-06-18T23:28:08.550Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8bad7b77b36100f14d2d6",
                            "schema": "product",
                            "score": 0.8304223442077636,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/da/3b/da3bc915bde4ece7388ee3b2160daabf_best.jpg",
                                    "width": 452,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "706491293_NERO:706491293:NERO:https://www.shopstyle.com/g/women/moschino/olivia-printed-dress",
                                "sku": "706491293",
                                "name": "Moschino Olivia Printed Dress",
                                "url": "https://www.shopstyle.com/g/women/moschino/olivia-printed-dress",
                                "brand": "Moschino",
                                "color": "NERO",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "- ROUND COLLAR- SHORT PUFF SLEEVE- FRONT SATIN LACE UP CLOSURE WITH METAL EYELETS- OLIVIA PRINT- UNLINED- 95% VISCOSE, 5% OTHER FABRIC - MADE IN ITALY- MODEL IS WEARING SIZE 42",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "44",
                                "age_group": "adult",
                                "stock": true,
                                "price": 513,
                                "currency": "USD"
                            },
                            "fingerprint": "878970eaad573283695d6b1e1c784b00",
                            "created_at": "2018-05-01T19:07:03.300Z",
                            "updated_at": "2018-06-28T14:42:19.958Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b3f860cf5e48d63c66f5",
                            "schema": "product",
                            "score": 0.8298108291625976,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/af/8a/af8a34f75d9fff94442314bfab20b7f9_best.jpg",
                                    "width": 184,
                                    "height": 478
                                }
                            ],
                            "data": {
                                "id": "708090382_Blue:708090382:Blue:https://www.shopstyle.com/g/women/ramy-brook/sleeveless-mini-dress-w-tags",
                                "sku": "708090382",
                                "name": "Ramy Brook Sleeveless Mini Dress w/ Tags",
                                "url": "https://www.shopstyle.com/g/women/ramy-brook/sleeveless-mini-dress-w-tags",
                                "brand": "Ramy Brook",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Blue Ramy Brook sleeveless mini dress with V-neck, embroidered eyelet accents and zip at back.",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "M | US 6",
                                "age_group": "adult",
                                "stock": true,
                                "price": 75,
                                "currency": "USD"
                            },
                            "fingerprint": "bb3802efc7d1b1355aca534108e4d628",
                            "created_at": "2018-05-01T00:25:28.756Z",
                            "updated_at": "2018-06-25T16:31:10.569Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-12",
            "index": 12,
            "timestamp": 6000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000013.png",
                "width": 288,
                "height": 512
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "94789af6-34e5-45ca-9123-ae551638cdab",
                    "score": 0.8677928745746613,
                    "category": {
                        "_type": "Category",
                        "_id": "dresses",
                        "name": "dresses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 149.8420867919922,
                        "y": 219.0521240234375,
                        "width": 74.56954956054688,
                        "height": 153.91583251953125
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c5d01393dd3d290b95d2",
                            "schema": "product",
                            "score": 0.8424236392974853,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/85/d3/85d30817ca33f785d58f4e5eecd14abb_best.jpg",
                                    "width": 407,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "703702616_Black:703702616:Black:https://www.shopstyle.com/g/women/herve-leger/short-dresses",
                                "sku": "703702616",
                                "name": "Herve Leger Short dresses",
                                "url": "https://www.shopstyle.com/g/women/herve-leger/short-dresses",
                                "brand": "Herve Leger",
                                "color": "Black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "HERVÉ LÉGER Short dresses. jersey, no appliqués, basic solid color, deep neckline, sleeveless, no pockets, unlined, rear closure, zip, pencil style, small sized. 90% Rayon, 9% Nylon, 1% Elastane",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "L",
                                "age_group": "adult",
                                "stock": true,
                                "price": 1800,
                                "currency": "USD"
                            },
                            "fingerprint": "b1a9084424cbf0238216aa1320d2643e",
                            "created_at": "2018-05-01T19:53:52.520Z",
                            "updated_at": "2018-06-22T21:16:08.529Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c2016efb8d3d2f808c90",
                            "schema": "product",
                            "score": 0.8388369369506836,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9f/e0/9fe09074d29e342a9f45ff91d2912f7a_best.jpg",
                                    "width": 130,
                                    "height": 500
                                }
                            ],
                            "data": {
                                "id": "721094678_Black:721094678:Black:https://www.shopstyle.com/g/women/tahari-by-arthur-s-levine/womens-relaxed-fit-chemical-lace-dress-with-tie-at-waist",
                                "sku": "721094678",
                                "name": "Tahari by Arthur S. Levine Women's Relaxed Fit Chemical Lace Dress with Tie AT Waist",
                                "url": "https://www.shopstyle.com/g/women/tahari-by-arthur-s-levine/womens-relaxed-fit-chemical-lace-dress-with-tie-at-waist",
                                "brand": "Tahari by Arthur S. Levine",
                                "color": "Black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Chemical lace dress tie at waist relaxed fit dresses seasonal demand",
                                "category": "Women > Clothing > Pants |  | ",
                                "size": "6",
                                "age_group": "adult",
                                "stock": true,
                                "price": 108,
                                "currency": "USD"
                            },
                            "fingerprint": "10cb0d9147ef1714c9bfae8a1c263e42",
                            "created_at": "2018-05-01T19:37:37.516Z",
                            "updated_at": "2018-06-28T15:09:16.820Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d5e21393dd3d2914a152",
                            "schema": "product",
                            "score": 0.8387387275695801,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/26/98/2698b710003475ba7c25c63efcaf5957_best.jpg",
                                    "width": 253,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "697672547_Black:697672547:Black:https://www.shopstyle.com/g/women/susana-monaco/ella-sheath-dress",
                                "sku": "697672547",
                                "name": "Susana Monaco Ella Sheath Dress",
                                "url": "https://www.shopstyle.com/g/women/susana-monaco/ella-sheath-dress",
                                "brand": "Susana Monaco",
                                "color": "Black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Color/pattern: black Approximately 34in from shoulder to hem Measurement was taken from a size small and may vary slightly by size Model is 5ft 8in Design details: plunging neckline with strap detail, strappy back Slip-on styling 86% supplex? nylon, 14% lycra Machine wash Made in the USA",
                                "category": "Women > Clothing > Dresses > Day |  | ",
                                "size": "L",
                                "age_group": "adult",
                                "stock": true,
                                "price": 174,
                                "currency": "USD"
                            },
                            "fingerprint": "79f18d157b956e0ee001529aa131c780",
                            "created_at": "2018-05-01T21:02:26.476Z",
                            "updated_at": "2018-07-06T00:15:57.419Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90c976efb8d3d2f9129e9",
                            "schema": "product",
                            "score": 0.8361745071411133,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/d7/f0/d7f0a023e56ee81bd89cdc789198ca8d_best.jpg",
                                    "width": 184,
                                    "height": 478
                                }
                            ],
                            "data": {
                                "id": "642172273_Black:642172273:Black:https://www.shopstyle.com/g/women/vena-cava/wool-mini-dress",
                                "sku": "642172273",
                                "name": "Vena Cava Wool Mini Dress",
                                "url": "https://www.shopstyle.com/g/women/vena-cava/wool-mini-dress",
                                "brand": "Vena Cava",
                                "color": "Black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Black Vena Cava wool sleeveless mini dress with V-neck, beaded trim at bust, rope accent at waist and concealed zip closure at side.",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "XS | US 0",
                                "age_group": "adult",
                                "stock": true,
                                "price": 75,
                                "currency": "USD"
                            },
                            "fingerprint": "e94dbd691d99f82f81f086abb8af881e",
                            "created_at": "2018-05-02T00:55:51.298Z",
                            "updated_at": "2018-06-26T20:03:06.248Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8da3f1393dd3d2916f8f3",
                            "schema": "product",
                            "score": 0.8349379348754883,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/df/57/df57f76e43aa07add7a12fe86518c4a7_best.jpg",
                                    "width": 105,
                                    "height": 392
                                }
                            ],
                            "data": {
                                "id": "712953634_Black:712953634:Black:https://www.shopstyle.com/g/women/black-halo/behati-x-back-mini-bodycon-dress",
                                "sku": "712953634",
                                "name": "Black Halo Behati X-Back Mini Bodycon Dress",
                                "url": "https://www.shopstyle.com/g/women/black-halo/behati-x-back-mini-bodycon-dress",
                                "brand": "Black Halo",
                                "color": "Black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Solid dress meticulously designed with X-back Double straps at shoulder Sweetheart neckline Back zipper closure Lined About 33\" from shoulder to hem Polyamide/viscose/elastane Dry clean Made in USA Model shown is 5'10\" (177cm) wearing US size 4",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "12",
                                "age_group": "adult",
                                "stock": true,
                                "price": 325,
                                "currency": "USD"
                            },
                            "fingerprint": "3efa4915ec13c12c675f5eb414e6e8ce",
                            "created_at": "2018-05-01T21:21:03.381Z",
                            "updated_at": "2018-07-06T00:10:35.880Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c0fe6efb8d3d2f7ff297",
                            "schema": "product",
                            "score": 0.8346970558166504,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/3b/ad/3bad379244e1d73cce674ea3dab7196c_best.jpg",
                                    "width": 600,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "703444176_black:703444176:black:https://www.shopstyle.com/g/women/boohoo/tall-gabriella-strappy-back-lace-bodysuit",
                                "sku": "703444176",
                                "name": "boohoo Tall Gabriella Strappy Back Lace Bodysuit",
                                "url": "https://www.shopstyle.com/g/women/boohoo/tall-gabriella-strappy-back-lace-bodysuit",
                                "brand": "boohoo",
                                "color": "black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Meet your new go-to tops. Featuring failsafe basics, achingly-cool tees, wow-worthy bodysuits, and many more; the boohoo Tall collection includes all of the core pieces you wardrobe is missing. Stock up.",
                                "category": "Women > Clothing > Tops |  | ",
                                "size": "6",
                                "age_group": "adult",
                                "stock": true,
                                "price": 19,
                                "currency": "USD"
                            },
                            "fingerprint": "e99c36a356ab4f47912632a68a154c21",
                            "created_at": "2018-05-01T19:33:18.044Z",
                            "updated_at": "2018-06-28T14:52:12.044Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8f8026efb8d3d2f8aa8ae",
                            "schema": "product",
                            "score": 0.8319544792175293,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/42/aa/42aa7e8caa600f0fb48ebed7573ab65f_best.jpg",
                                    "width": 241,
                                    "height": 537
                                }
                            ],
                            "data": {
                                "id": "652520337_Black:652520337:Black:https://www.shopstyle.com/p/misa-los-angeles-domino-dress/652520337",
                                "sku": "652520337",
                                "name": "MISA LOS ANGELES Domino Dress",
                                "url": "https://www.shopstyle.com/p/misa-los-angeles-domino-dress/652520337",
                                "brand": "MISA LOS ANGELES",
                                "color": "Black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Get ready for date night in no time with this slinky dress tailored with a flattering knotted front and slender racerback straps. Style Name:Misa Los Angeles Domino Dress. Style Number: 5405407. Available in stores.",
                                "category": "Women > Clothing > Dresses > Day |  | ",
                                "size": "X-Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 180,
                                "currency": "USD"
                            },
                            "fingerprint": "9bf163918e45eb11ac9e1d5eae7188ae",
                            "created_at": "2018-05-01T23:28:02.342Z",
                            "updated_at": "2018-07-05T23:54:07.104Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b1a3b77b36100f11ec12",
                            "schema": "product",
                            "score": 0.830588207244873,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b1/49/b149ae47383cb98dadf2cabf2555dc9b_best.jpg",
                                    "width": 210,
                                    "height": 400
                                }
                            ],
                            "data": {
                                "id": "692156741_BLACK:692156741:BLACK:https://www.shopstyle.com/g/women/guess/double-embroidered-sheath-dress",
                                "sku": "692156741",
                                "name": "GUESS Double Embroidered Sheath Dress",
                                "url": "https://www.shopstyle.com/g/women/guess/double-embroidered-sheath-dress",
                                "brand": "GUESS",
                                "color": "BLACK",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Double Embroidered Sheath Dress;Skin-baring dress with crisscross straps on open back. Deep V-neck. Sleeveless. Concealed back zip. Lined. About 33\" from shoulder to hem. Polyester/spandex. Hand wash. Imported. Model shown is 5'10\" (177cm) wearing US size 4.",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "8",
                                "age_group": "adult",
                                "stock": true,
                                "price": 128,
                                "currency": "USD"
                            },
                            "fingerprint": "829fb97110017344464562a29a014f0d",
                            "created_at": "2018-05-01T00:15:31.478Z",
                            "updated_at": "2018-06-18T23:28:08.550Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8bad7b77b36100f14d2d6",
                            "schema": "product",
                            "score": 0.8304223442077636,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/da/3b/da3bc915bde4ece7388ee3b2160daabf_best.jpg",
                                    "width": 452,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "706491293_NERO:706491293:NERO:https://www.shopstyle.com/g/women/moschino/olivia-printed-dress",
                                "sku": "706491293",
                                "name": "Moschino Olivia Printed Dress",
                                "url": "https://www.shopstyle.com/g/women/moschino/olivia-printed-dress",
                                "brand": "Moschino",
                                "color": "NERO",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "- ROUND COLLAR- SHORT PUFF SLEEVE- FRONT SATIN LACE UP CLOSURE WITH METAL EYELETS- OLIVIA PRINT- UNLINED- 95% VISCOSE, 5% OTHER FABRIC - MADE IN ITALY- MODEL IS WEARING SIZE 42",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "44",
                                "age_group": "adult",
                                "stock": true,
                                "price": 513,
                                "currency": "USD"
                            },
                            "fingerprint": "878970eaad573283695d6b1e1c784b00",
                            "created_at": "2018-05-01T19:07:03.300Z",
                            "updated_at": "2018-06-28T14:42:19.958Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b3f860cf5e48d63c66f5",
                            "schema": "product",
                            "score": 0.8298108291625976,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/af/8a/af8a34f75d9fff94442314bfab20b7f9_best.jpg",
                                    "width": 184,
                                    "height": 478
                                }
                            ],
                            "data": {
                                "id": "708090382_Blue:708090382:Blue:https://www.shopstyle.com/g/women/ramy-brook/sleeveless-mini-dress-w-tags",
                                "sku": "708090382",
                                "name": "Ramy Brook Sleeveless Mini Dress w/ Tags",
                                "url": "https://www.shopstyle.com/g/women/ramy-brook/sleeveless-mini-dress-w-tags",
                                "brand": "Ramy Brook",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Blue Ramy Brook sleeveless mini dress with V-neck, embroidered eyelet accents and zip at back.",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "M | US 6",
                                "age_group": "adult",
                                "stock": true,
                                "price": 75,
                                "currency": "USD"
                            },
                            "fingerprint": "bb3802efc7d1b1355aca534108e4d628",
                            "created_at": "2018-05-01T00:25:28.756Z",
                            "updated_at": "2018-06-25T16:31:10.569Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-13",
            "index": 13,
            "timestamp": 6500,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000014.png",
                "width": 288,
                "height": 512
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "24e10d54-559f-4bca-92cb-e9ad162d3a88",
                    "score": 0.9144316017627716,
                    "category": {
                        "_type": "Category",
                        "_id": "handbags",
                        "name": "handbags"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 137.92587280273438,
                        "y": 240.63479614257812,
                        "width": 59.9710693359375,
                        "height": 154.4677734375
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec656efb8d3d2f84025d",
                            "schema": "product",
                            "score": 0.8675570392608642,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/cd/8e/cd8e88273293d81dc56818085864f4a5_best.jpg",
                                    "width": 720,
                                    "height": 531
                                }
                            ],
                            "data": {
                                "id": "700736872_Silver:700736872:Silver:https://www.shopstyle.com/g/women/dolce-gabbana/handbags",
                                "sku": "700736872",
                                "name": "Dolce & Gabbana Handbags",
                                "url": "https://www.shopstyle.com/g/women/dolce-gabbana/handbags",
                                "brand": "Dolce & Gabbana",
                                "color": "Silver",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "DOLCE & GABBANA Handbags. mini, flower application, solid color, combination lock, removable shoulder strap, leather lining, bottom with studs, laminated effect, clutch. Plexiglas&reg;",
                                "category": "Women > Bags > Clutches |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 1950,
                                "currency": "USD"
                            },
                            "fingerprint": "e7b5588b51043e365d0f89481c3acbe7",
                            "created_at": "2018-05-01T22:38:29.937Z",
                            "updated_at": "2018-06-23T01:22:07.880Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d74a1393dd3d2915618c",
                            "schema": "product",
                            "score": 0.8674124145507812,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/bb/99/bb994525fa2c428764af2e492d9447d8_best.jpg",
                                    "width": 720,
                                    "height": 837
                                }
                            ],
                            "data": {
                                "id": "688919963_Red:688919963:Red:https://www.shopstyle.com/g/women/rich/handbags",
                                "sku": "688919963",
                                "name": "Rich Handbags",
                                "url": "https://www.shopstyle.com/g/women/rich/handbags",
                                "brand": "Rich",
                                "color": "Red",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "RICH Handbags. medium, gabardine, coated effect, logo, solid color, magnetic closure, internal pockets, double handle, lined interior, baguette bag. Textile fibers",
                                "category": "Women > Bags |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 179,
                                "currency": "USD"
                            },
                            "fingerprint": "d56c5426cd9cb13be41d60c48b4920bb",
                            "created_at": "2018-05-01T21:08:26.280Z",
                            "updated_at": "2018-06-18T19:51:06.403Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8edca6efb8d3d2f84d3fa",
                            "schema": "product",
                            "score": 0.8651898860931396,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/76/1a/761ac0720d25c186180eafe2b985f23b_best.jpg",
                                    "width": 542,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679567645_Red:679567645:Red:https://www.shopstyle.com/g/women/mia-bag/handbags",
                                "sku": "679567645",
                                "name": "Mia Bag Handbags",
                                "url": "https://www.shopstyle.com/g/women/mia-bag/handbags",
                                "brand": "Mia Bag",
                                "color": "Red",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MIA BAG Handbags. medium, canvas, detachable application, logo, paisley print, framed closure, internal pockets, double handle, lined interior, satchel. 100% Cotton",
                                "category": "Women > Bags > Satchels |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 149,
                                "currency": "USD"
                            },
                            "fingerprint": "11f9e50f1d7d58540dfeb038fddd51b5",
                            "created_at": "2018-05-01T22:44:26.907Z",
                            "updated_at": "2018-06-25T18:10:16.916Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cace1393dd3d290e780f",
                            "schema": "product",
                            "score": 0.8651326656341553,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4d/ac/4dacb7044cf8fcf2715a3bc7db513a90_best.jpg",
                                    "width": 720,
                                    "height": 832
                                }
                            ],
                            "data": {
                                "id": "704947305_Multicolour:704947305:Multicolour:https://www.shopstyle.com/g/women/marc-jacobs/canvas-handbag",
                                "sku": "704947305",
                                "name": "Marc Jacobs Canvas Handbag",
                                "url": "https://www.shopstyle.com/g/women/marc-jacobs/canvas-handbag",
                                "brand": "Marc Jacobs",
                                "color": "Multicolour",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Small canvas bag with a USA flag motif, a leather handle to carry it by hand or wear it on the shoulder, a flap with a gold-plated clasp and a zipped pocket inside. Original dust bag included. Some light marks on the leather of the flap.",
                                "category": "Women > Bags |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 1713.32,
                                "currency": "USD"
                            },
                            "fingerprint": "fe0d9b8fad8308a461b23df3960cc51c",
                            "created_at": "2018-05-01T20:15:10.715Z",
                            "updated_at": "2018-06-25T17:30:16.816Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d91393dd3d291411de",
                            "schema": "product",
                            "score": 0.8593526458740235,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/99/a3/99a3a712b3cf17ec738f6c4690e9b351_best.jpg",
                                    "width": 579,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "640575587_Red:640575587:Red:https://www.shopstyle.com/g/women/mia-bag/handbags",
                                "sku": "640575587",
                                "name": "Mia Bag Handbags",
                                "url": "https://www.shopstyle.com/g/women/mia-bag/handbags",
                                "brand": "Mia Bag",
                                "color": "Red",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MIA BAG Handbags. medium, denim, paisley print, logo, framed closure, internal pockets, metallic straps, lined interior, messenger. 100% Cotton",
                                "category": "Women > Bags |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 129,
                                "currency": "USD"
                            },
                            "fingerprint": "9d0a2faa16010023ec6041afaa20ecaa",
                            "created_at": "2018-05-01T20:58:01.219Z",
                            "updated_at": "2018-06-22T22:04:06.030Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90e946efb8d3d2f9249c6",
                            "schema": "product",
                            "score": 0.8576600265502929,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0b/15/0b15cbd229b299b5e0bb43f3bcefff15_best.jpg",
                                    "width": 720,
                                    "height": 720
                                }
                            ],
                            "data": {
                                "id": "705510221_Beige:705510221:Beige:https://www.shopstyle.com/g/women/furla/cloth-handbag",
                                "sku": "705510221",
                                "name": "Furla Cloth handbag",
                                "url": "https://www.shopstyle.com/g/women/furla/cloth-handbag",
                                "brand": "Furla",
                                "color": "Beige",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Beige canvas bag with motif in silver and copper colour lurex and burgundy velvet, brown leather trim, silver metal hardware, detachable black leather handle and sequined star patches and detachable shoulder strap adjustable up to 52 cm, the bag can be carried by hand, on the shoulder and cross-body, it closes with a clasp, textile interior, three small inside pockets including one zipped. No box or dust bag.",
                                "category": "Women > Bags |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 305.95,
                                "currency": "USD"
                            },
                            "fingerprint": "b13d84b59813948d03d8934df30ee74d",
                            "created_at": "2018-05-02T01:04:20.624Z",
                            "updated_at": "2018-06-26T20:52:12.668Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90d6d6efb8d3d2f91a379",
                            "schema": "product",
                            "score": 0.8554326438903809,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/83/ee/83eeacecdde988855c049e4cbaf98951_best.jpg",
                                    "width": 720,
                                    "height": 456
                                }
                            ],
                            "data": {
                                "id": "530755244_Red:530755244:Red:https://www.shopstyle.com/g/women/dooney-bourke/st-louis-cardinals-nylon-crossbody-pouchette",
                                "sku": "530755244",
                                "name": "Dooney & Bourke St. Louis Cardinals Nylon Crossbody Pouchette",
                                "url": "https://www.shopstyle.com/g/women/dooney-bourke/st-louis-cardinals-nylon-crossbody-pouchette",
                                "brand": "Dooney & Bourke",
                                "color": "Red",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "With a trim style that&apos;s versatile for your every day needs, this Dooney & Bourke St. Louis Cardinals Nylon Crossbody pouchette is a great compliment to your busy lifestyle",
                                "category": "Women > Bags > Shoulder |  | ",
                                "size": "OS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 98,
                                "currency": "USD"
                            },
                            "fingerprint": "0d7fd9dc9d8f7f9bcec0080bcdb447c2",
                            "created_at": "2018-05-02T00:59:25.311Z",
                            "updated_at": "2018-06-23T01:22:14.067Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0f1b77b36100f161ecb",
                            "schema": "product",
                            "score": 0.8548881149291992,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/3d/c8/3dc8da3698fc9efbb39867270f3adaf3_best.jpg",
                                    "width": 272,
                                    "height": 451
                                }
                            ],
                            "data": {
                                "id": "715566437_magenta:715566437:magenta:https://www.shopstyle.com/p/shrimps-venezia-beaded-handbag/715566437",
                                "sku": "715566437",
                                "name": "Shrimps Venezia Beaded Handbag",
                                "url": "https://www.shopstyle.com/p/shrimps-venezia-beaded-handbag/715566437",
                                "brand": "Shrimps",
                                "color": "magenta",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Compact and cute, this beaded handbag from Shrimps is inspired by the architecture and artworks of Venice, made with Venetian pink resin pearls and elevated with intricate floral detail in red. Let it pop against bright white or jet black. 100% Plastic Beads",
                                "category": "Women > Bags |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 450,
                                "currency": "USD"
                            },
                            "fingerprint": "74517ff0e4986cf60d9be085138d4f57",
                            "created_at": "2018-05-01T21:49:37.163Z",
                            "updated_at": "2018-06-23T01:23:07.150Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c5881393dd3d290b6e56",
                            "schema": "product",
                            "score": 0.8533598136901855,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0e/72/0e727fb1b6e793cfc9358779feb1f56b_best.jpg",
                                    "width": 555,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "704951322_Red:704951322:Red:https://www.shopstyle.com/g/women/charlotte-olympia/leather-handbag",
                                "sku": "704951322",
                                "name": "Charlotte Olympia Leather handbag",
                                "url": "https://www.shopstyle.com/g/women/charlotte-olympia/leather-handbag",
                                "brand": "Charlotte Olympia",
                                "color": "Red",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Movie night bag by Charlotte Olympia. Red and white leather popcorn clutch with white golden tone shoulder chain. Measurement: shoulder strap height: 44 cm. Material: nappa leather. Like new. Dustbag and box provided.",
                                "category": "Women > Bags > Clutches |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 558.56,
                                "currency": "USD"
                            },
                            "fingerprint": "8e5ccc4d0497ba275ef79d2c47461ab9",
                            "created_at": "2018-05-01T19:52:40.545Z",
                            "updated_at": "2018-06-22T22:01:07.752Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b486a27738101553c38d",
                            "schema": "product",
                            "score": 0.8495689010620118,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/09/27/092731ac2a447c4d0182a0fe5647a1fb_best.jpg",
                                    "width": 720,
                                    "height": 514
                                }
                            ],
                            "data": {
                                "id": "708254493_White:708254493:White:https://www.shopstyle.com/g/women/moschino/handbags",
                                "sku": "708254493",
                                "name": "Moschino Handbags",
                                "url": "https://www.shopstyle.com/g/women/moschino/handbags",
                                "brand": "Moschino",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOSCHINO Handbags. leather, print, magnetic closure, internal pockets, lined interior, stiff bottom, mini, solid color, contains non-textile parts of animal origin, satchel bags. Soft Leather",
                                "category": "Women > Bags > Satchels |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 713,
                                "currency": "USD"
                            },
                            "fingerprint": "ef0cabc187aee8f51bcf724a70abda54",
                            "created_at": "2018-05-01T18:40:06.660Z",
                            "updated_at": "2018-06-25T17:05:13.556Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-14",
            "index": 14,
            "timestamp": 7000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000015.png",
                "width": 288,
                "height": 512
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "3e87026f-885a-41f2-9829-c0ce1a3699a0",
                    "score": 0.9144316017627716,
                    "category": {
                        "_type": "Category",
                        "_id": "handbags",
                        "name": "handbags"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 163.58529663085938,
                        "y": 268.0721435546875,
                        "width": 67.5137939453125,
                        "height": 168.3836669921875
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec656efb8d3d2f84025d",
                            "schema": "product",
                            "score": 0.8675570392608642,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/cd/8e/cd8e88273293d81dc56818085864f4a5_best.jpg",
                                    "width": 720,
                                    "height": 531
                                }
                            ],
                            "data": {
                                "id": "700736872_Silver:700736872:Silver:https://www.shopstyle.com/g/women/dolce-gabbana/handbags",
                                "sku": "700736872",
                                "name": "Dolce & Gabbana Handbags",
                                "url": "https://www.shopstyle.com/g/women/dolce-gabbana/handbags",
                                "brand": "Dolce & Gabbana",
                                "color": "Silver",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "DOLCE & GABBANA Handbags. mini, flower application, solid color, combination lock, removable shoulder strap, leather lining, bottom with studs, laminated effect, clutch. Plexiglas&reg;",
                                "category": "Women > Bags > Clutches |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 1950,
                                "currency": "USD"
                            },
                            "fingerprint": "e7b5588b51043e365d0f89481c3acbe7",
                            "created_at": "2018-05-01T22:38:29.937Z",
                            "updated_at": "2018-06-23T01:22:07.880Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d74a1393dd3d2915618c",
                            "schema": "product",
                            "score": 0.8674124145507812,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/bb/99/bb994525fa2c428764af2e492d9447d8_best.jpg",
                                    "width": 720,
                                    "height": 837
                                }
                            ],
                            "data": {
                                "id": "688919963_Red:688919963:Red:https://www.shopstyle.com/g/women/rich/handbags",
                                "sku": "688919963",
                                "name": "Rich Handbags",
                                "url": "https://www.shopstyle.com/g/women/rich/handbags",
                                "brand": "Rich",
                                "color": "Red",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "RICH Handbags. medium, gabardine, coated effect, logo, solid color, magnetic closure, internal pockets, double handle, lined interior, baguette bag. Textile fibers",
                                "category": "Women > Bags |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 179,
                                "currency": "USD"
                            },
                            "fingerprint": "d56c5426cd9cb13be41d60c48b4920bb",
                            "created_at": "2018-05-01T21:08:26.280Z",
                            "updated_at": "2018-06-18T19:51:06.403Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8edca6efb8d3d2f84d3fa",
                            "schema": "product",
                            "score": 0.8651898860931396,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/76/1a/761ac0720d25c186180eafe2b985f23b_best.jpg",
                                    "width": 542,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679567645_Red:679567645:Red:https://www.shopstyle.com/g/women/mia-bag/handbags",
                                "sku": "679567645",
                                "name": "Mia Bag Handbags",
                                "url": "https://www.shopstyle.com/g/women/mia-bag/handbags",
                                "brand": "Mia Bag",
                                "color": "Red",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MIA BAG Handbags. medium, canvas, detachable application, logo, paisley print, framed closure, internal pockets, double handle, lined interior, satchel. 100% Cotton",
                                "category": "Women > Bags > Satchels |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 149,
                                "currency": "USD"
                            },
                            "fingerprint": "11f9e50f1d7d58540dfeb038fddd51b5",
                            "created_at": "2018-05-01T22:44:26.907Z",
                            "updated_at": "2018-06-25T18:10:16.916Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cace1393dd3d290e780f",
                            "schema": "product",
                            "score": 0.8651326656341553,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4d/ac/4dacb7044cf8fcf2715a3bc7db513a90_best.jpg",
                                    "width": 720,
                                    "height": 832
                                }
                            ],
                            "data": {
                                "id": "704947305_Multicolour:704947305:Multicolour:https://www.shopstyle.com/g/women/marc-jacobs/canvas-handbag",
                                "sku": "704947305",
                                "name": "Marc Jacobs Canvas Handbag",
                                "url": "https://www.shopstyle.com/g/women/marc-jacobs/canvas-handbag",
                                "brand": "Marc Jacobs",
                                "color": "Multicolour",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Small canvas bag with a USA flag motif, a leather handle to carry it by hand or wear it on the shoulder, a flap with a gold-plated clasp and a zipped pocket inside. Original dust bag included. Some light marks on the leather of the flap.",
                                "category": "Women > Bags |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 1713.32,
                                "currency": "USD"
                            },
                            "fingerprint": "fe0d9b8fad8308a461b23df3960cc51c",
                            "created_at": "2018-05-01T20:15:10.715Z",
                            "updated_at": "2018-06-25T17:30:16.816Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d91393dd3d291411de",
                            "schema": "product",
                            "score": 0.8593526458740235,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/99/a3/99a3a712b3cf17ec738f6c4690e9b351_best.jpg",
                                    "width": 579,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "640575587_Red:640575587:Red:https://www.shopstyle.com/g/women/mia-bag/handbags",
                                "sku": "640575587",
                                "name": "Mia Bag Handbags",
                                "url": "https://www.shopstyle.com/g/women/mia-bag/handbags",
                                "brand": "Mia Bag",
                                "color": "Red",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MIA BAG Handbags. medium, denim, paisley print, logo, framed closure, internal pockets, metallic straps, lined interior, messenger. 100% Cotton",
                                "category": "Women > Bags |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 129,
                                "currency": "USD"
                            },
                            "fingerprint": "9d0a2faa16010023ec6041afaa20ecaa",
                            "created_at": "2018-05-01T20:58:01.219Z",
                            "updated_at": "2018-06-22T22:04:06.030Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90e946efb8d3d2f9249c6",
                            "schema": "product",
                            "score": 0.8576600265502929,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0b/15/0b15cbd229b299b5e0bb43f3bcefff15_best.jpg",
                                    "width": 720,
                                    "height": 720
                                }
                            ],
                            "data": {
                                "id": "705510221_Beige:705510221:Beige:https://www.shopstyle.com/g/women/furla/cloth-handbag",
                                "sku": "705510221",
                                "name": "Furla Cloth handbag",
                                "url": "https://www.shopstyle.com/g/women/furla/cloth-handbag",
                                "brand": "Furla",
                                "color": "Beige",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Beige canvas bag with motif in silver and copper colour lurex and burgundy velvet, brown leather trim, silver metal hardware, detachable black leather handle and sequined star patches and detachable shoulder strap adjustable up to 52 cm, the bag can be carried by hand, on the shoulder and cross-body, it closes with a clasp, textile interior, three small inside pockets including one zipped. No box or dust bag.",
                                "category": "Women > Bags |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 305.95,
                                "currency": "USD"
                            },
                            "fingerprint": "b13d84b59813948d03d8934df30ee74d",
                            "created_at": "2018-05-02T01:04:20.624Z",
                            "updated_at": "2018-06-26T20:52:12.668Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90d6d6efb8d3d2f91a379",
                            "schema": "product",
                            "score": 0.8554326438903809,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/83/ee/83eeacecdde988855c049e4cbaf98951_best.jpg",
                                    "width": 720,
                                    "height": 456
                                }
                            ],
                            "data": {
                                "id": "530755244_Red:530755244:Red:https://www.shopstyle.com/g/women/dooney-bourke/st-louis-cardinals-nylon-crossbody-pouchette",
                                "sku": "530755244",
                                "name": "Dooney & Bourke St. Louis Cardinals Nylon Crossbody Pouchette",
                                "url": "https://www.shopstyle.com/g/women/dooney-bourke/st-louis-cardinals-nylon-crossbody-pouchette",
                                "brand": "Dooney & Bourke",
                                "color": "Red",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "With a trim style that&apos;s versatile for your every day needs, this Dooney & Bourke St. Louis Cardinals Nylon Crossbody pouchette is a great compliment to your busy lifestyle",
                                "category": "Women > Bags > Shoulder |  | ",
                                "size": "OS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 98,
                                "currency": "USD"
                            },
                            "fingerprint": "0d7fd9dc9d8f7f9bcec0080bcdb447c2",
                            "created_at": "2018-05-02T00:59:25.311Z",
                            "updated_at": "2018-06-23T01:22:14.067Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0f1b77b36100f161ecb",
                            "schema": "product",
                            "score": 0.8548881149291992,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/3d/c8/3dc8da3698fc9efbb39867270f3adaf3_best.jpg",
                                    "width": 272,
                                    "height": 451
                                }
                            ],
                            "data": {
                                "id": "715566437_magenta:715566437:magenta:https://www.shopstyle.com/p/shrimps-venezia-beaded-handbag/715566437",
                                "sku": "715566437",
                                "name": "Shrimps Venezia Beaded Handbag",
                                "url": "https://www.shopstyle.com/p/shrimps-venezia-beaded-handbag/715566437",
                                "brand": "Shrimps",
                                "color": "magenta",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Compact and cute, this beaded handbag from Shrimps is inspired by the architecture and artworks of Venice, made with Venetian pink resin pearls and elevated with intricate floral detail in red. Let it pop against bright white or jet black. 100% Plastic Beads",
                                "category": "Women > Bags |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 450,
                                "currency": "USD"
                            },
                            "fingerprint": "74517ff0e4986cf60d9be085138d4f57",
                            "created_at": "2018-05-01T21:49:37.163Z",
                            "updated_at": "2018-06-23T01:23:07.150Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c5881393dd3d290b6e56",
                            "schema": "product",
                            "score": 0.8533598136901855,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0e/72/0e727fb1b6e793cfc9358779feb1f56b_best.jpg",
                                    "width": 555,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "704951322_Red:704951322:Red:https://www.shopstyle.com/g/women/charlotte-olympia/leather-handbag",
                                "sku": "704951322",
                                "name": "Charlotte Olympia Leather handbag",
                                "url": "https://www.shopstyle.com/g/women/charlotte-olympia/leather-handbag",
                                "brand": "Charlotte Olympia",
                                "color": "Red",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Movie night bag by Charlotte Olympia. Red and white leather popcorn clutch with white golden tone shoulder chain. Measurement: shoulder strap height: 44 cm. Material: nappa leather. Like new. Dustbag and box provided.",
                                "category": "Women > Bags > Clutches |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 558.56,
                                "currency": "USD"
                            },
                            "fingerprint": "8e5ccc4d0497ba275ef79d2c47461ab9",
                            "created_at": "2018-05-01T19:52:40.545Z",
                            "updated_at": "2018-06-22T22:01:07.752Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b486a27738101553c38d",
                            "schema": "product",
                            "score": 0.8495689010620118,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/09/27/092731ac2a447c4d0182a0fe5647a1fb_best.jpg",
                                    "width": 720,
                                    "height": 514
                                }
                            ],
                            "data": {
                                "id": "708254493_White:708254493:White:https://www.shopstyle.com/g/women/moschino/handbags",
                                "sku": "708254493",
                                "name": "Moschino Handbags",
                                "url": "https://www.shopstyle.com/g/women/moschino/handbags",
                                "brand": "Moschino",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOSCHINO Handbags. leather, print, magnetic closure, internal pockets, lined interior, stiff bottom, mini, solid color, contains non-textile parts of animal origin, satchel bags. Soft Leather",
                                "category": "Women > Bags > Satchels |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 713,
                                "currency": "USD"
                            },
                            "fingerprint": "ef0cabc187aee8f51bcf724a70abda54",
                            "created_at": "2018-05-01T18:40:06.660Z",
                            "updated_at": "2018-06-25T17:05:13.556Z"
                        }
                    ]
                },
                {
                    "_type": "SearchResult",
                    "_id": "dcebb5c7-fe26-4f43-b398-a280495c2285",
                    "score": 0.8677928745746613,
                    "category": {
                        "_type": "Category",
                        "_id": "dresses",
                        "name": "dresses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 163.79034423828125,
                        "y": 266.7722473144531,
                        "width": 99.9368896484375,
                        "height": 203.778076171875
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c5d01393dd3d290b95d2",
                            "schema": "product",
                            "score": 0.8424236392974853,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/85/d3/85d30817ca33f785d58f4e5eecd14abb_best.jpg",
                                    "width": 407,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "703702616_Black:703702616:Black:https://www.shopstyle.com/g/women/herve-leger/short-dresses",
                                "sku": "703702616",
                                "name": "Herve Leger Short dresses",
                                "url": "https://www.shopstyle.com/g/women/herve-leger/short-dresses",
                                "brand": "Herve Leger",
                                "color": "Black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "HERVÉ LÉGER Short dresses. jersey, no appliqués, basic solid color, deep neckline, sleeveless, no pockets, unlined, rear closure, zip, pencil style, small sized. 90% Rayon, 9% Nylon, 1% Elastane",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "L",
                                "age_group": "adult",
                                "stock": true,
                                "price": 1800,
                                "currency": "USD"
                            },
                            "fingerprint": "b1a9084424cbf0238216aa1320d2643e",
                            "created_at": "2018-05-01T19:53:52.520Z",
                            "updated_at": "2018-06-22T21:16:08.529Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c2016efb8d3d2f808c90",
                            "schema": "product",
                            "score": 0.8388369369506836,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9f/e0/9fe09074d29e342a9f45ff91d2912f7a_best.jpg",
                                    "width": 130,
                                    "height": 500
                                }
                            ],
                            "data": {
                                "id": "721094678_Black:721094678:Black:https://www.shopstyle.com/g/women/tahari-by-arthur-s-levine/womens-relaxed-fit-chemical-lace-dress-with-tie-at-waist",
                                "sku": "721094678",
                                "name": "Tahari by Arthur S. Levine Women's Relaxed Fit Chemical Lace Dress with Tie AT Waist",
                                "url": "https://www.shopstyle.com/g/women/tahari-by-arthur-s-levine/womens-relaxed-fit-chemical-lace-dress-with-tie-at-waist",
                                "brand": "Tahari by Arthur S. Levine",
                                "color": "Black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Chemical lace dress tie at waist relaxed fit dresses seasonal demand",
                                "category": "Women > Clothing > Pants |  | ",
                                "size": "6",
                                "age_group": "adult",
                                "stock": true,
                                "price": 108,
                                "currency": "USD"
                            },
                            "fingerprint": "10cb0d9147ef1714c9bfae8a1c263e42",
                            "created_at": "2018-05-01T19:37:37.516Z",
                            "updated_at": "2018-06-28T15:09:16.820Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d5e21393dd3d2914a152",
                            "schema": "product",
                            "score": 0.8387387275695801,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/26/98/2698b710003475ba7c25c63efcaf5957_best.jpg",
                                    "width": 253,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "697672547_Black:697672547:Black:https://www.shopstyle.com/g/women/susana-monaco/ella-sheath-dress",
                                "sku": "697672547",
                                "name": "Susana Monaco Ella Sheath Dress",
                                "url": "https://www.shopstyle.com/g/women/susana-monaco/ella-sheath-dress",
                                "brand": "Susana Monaco",
                                "color": "Black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Color/pattern: black Approximately 34in from shoulder to hem Measurement was taken from a size small and may vary slightly by size Model is 5ft 8in Design details: plunging neckline with strap detail, strappy back Slip-on styling 86% supplex? nylon, 14% lycra Machine wash Made in the USA",
                                "category": "Women > Clothing > Dresses > Day |  | ",
                                "size": "L",
                                "age_group": "adult",
                                "stock": true,
                                "price": 174,
                                "currency": "USD"
                            },
                            "fingerprint": "79f18d157b956e0ee001529aa131c780",
                            "created_at": "2018-05-01T21:02:26.476Z",
                            "updated_at": "2018-07-06T00:15:57.419Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90c976efb8d3d2f9129e9",
                            "schema": "product",
                            "score": 0.8361745071411133,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/d7/f0/d7f0a023e56ee81bd89cdc789198ca8d_best.jpg",
                                    "width": 184,
                                    "height": 478
                                }
                            ],
                            "data": {
                                "id": "642172273_Black:642172273:Black:https://www.shopstyle.com/g/women/vena-cava/wool-mini-dress",
                                "sku": "642172273",
                                "name": "Vena Cava Wool Mini Dress",
                                "url": "https://www.shopstyle.com/g/women/vena-cava/wool-mini-dress",
                                "brand": "Vena Cava",
                                "color": "Black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Black Vena Cava wool sleeveless mini dress with V-neck, beaded trim at bust, rope accent at waist and concealed zip closure at side.",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "XS | US 0",
                                "age_group": "adult",
                                "stock": true,
                                "price": 75,
                                "currency": "USD"
                            },
                            "fingerprint": "e94dbd691d99f82f81f086abb8af881e",
                            "created_at": "2018-05-02T00:55:51.298Z",
                            "updated_at": "2018-06-26T20:03:06.248Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8da3f1393dd3d2916f8f3",
                            "schema": "product",
                            "score": 0.8349379348754883,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/df/57/df57f76e43aa07add7a12fe86518c4a7_best.jpg",
                                    "width": 105,
                                    "height": 392
                                }
                            ],
                            "data": {
                                "id": "712953634_Black:712953634:Black:https://www.shopstyle.com/g/women/black-halo/behati-x-back-mini-bodycon-dress",
                                "sku": "712953634",
                                "name": "Black Halo Behati X-Back Mini Bodycon Dress",
                                "url": "https://www.shopstyle.com/g/women/black-halo/behati-x-back-mini-bodycon-dress",
                                "brand": "Black Halo",
                                "color": "Black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Solid dress meticulously designed with X-back Double straps at shoulder Sweetheart neckline Back zipper closure Lined About 33\" from shoulder to hem Polyamide/viscose/elastane Dry clean Made in USA Model shown is 5'10\" (177cm) wearing US size 4",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "12",
                                "age_group": "adult",
                                "stock": true,
                                "price": 325,
                                "currency": "USD"
                            },
                            "fingerprint": "3efa4915ec13c12c675f5eb414e6e8ce",
                            "created_at": "2018-05-01T21:21:03.381Z",
                            "updated_at": "2018-07-06T00:10:35.880Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c0fe6efb8d3d2f7ff297",
                            "schema": "product",
                            "score": 0.8346970558166504,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/3b/ad/3bad379244e1d73cce674ea3dab7196c_best.jpg",
                                    "width": 600,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "703444176_black:703444176:black:https://www.shopstyle.com/g/women/boohoo/tall-gabriella-strappy-back-lace-bodysuit",
                                "sku": "703444176",
                                "name": "boohoo Tall Gabriella Strappy Back Lace Bodysuit",
                                "url": "https://www.shopstyle.com/g/women/boohoo/tall-gabriella-strappy-back-lace-bodysuit",
                                "brand": "boohoo",
                                "color": "black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Meet your new go-to tops. Featuring failsafe basics, achingly-cool tees, wow-worthy bodysuits, and many more; the boohoo Tall collection includes all of the core pieces you wardrobe is missing. Stock up.",
                                "category": "Women > Clothing > Tops |  | ",
                                "size": "6",
                                "age_group": "adult",
                                "stock": true,
                                "price": 19,
                                "currency": "USD"
                            },
                            "fingerprint": "e99c36a356ab4f47912632a68a154c21",
                            "created_at": "2018-05-01T19:33:18.044Z",
                            "updated_at": "2018-06-28T14:52:12.044Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8f8026efb8d3d2f8aa8ae",
                            "schema": "product",
                            "score": 0.8319544792175293,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/42/aa/42aa7e8caa600f0fb48ebed7573ab65f_best.jpg",
                                    "width": 241,
                                    "height": 537
                                }
                            ],
                            "data": {
                                "id": "652520337_Black:652520337:Black:https://www.shopstyle.com/p/misa-los-angeles-domino-dress/652520337",
                                "sku": "652520337",
                                "name": "MISA LOS ANGELES Domino Dress",
                                "url": "https://www.shopstyle.com/p/misa-los-angeles-domino-dress/652520337",
                                "brand": "MISA LOS ANGELES",
                                "color": "Black",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Get ready for date night in no time with this slinky dress tailored with a flattering knotted front and slender racerback straps. Style Name:Misa Los Angeles Domino Dress. Style Number: 5405407. Available in stores.",
                                "category": "Women > Clothing > Dresses > Day |  | ",
                                "size": "X-Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 180,
                                "currency": "USD"
                            },
                            "fingerprint": "9bf163918e45eb11ac9e1d5eae7188ae",
                            "created_at": "2018-05-01T23:28:02.342Z",
                            "updated_at": "2018-07-05T23:54:07.104Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b1a3b77b36100f11ec12",
                            "schema": "product",
                            "score": 0.830588207244873,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b1/49/b149ae47383cb98dadf2cabf2555dc9b_best.jpg",
                                    "width": 210,
                                    "height": 400
                                }
                            ],
                            "data": {
                                "id": "692156741_BLACK:692156741:BLACK:https://www.shopstyle.com/g/women/guess/double-embroidered-sheath-dress",
                                "sku": "692156741",
                                "name": "GUESS Double Embroidered Sheath Dress",
                                "url": "https://www.shopstyle.com/g/women/guess/double-embroidered-sheath-dress",
                                "brand": "GUESS",
                                "color": "BLACK",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Double Embroidered Sheath Dress;Skin-baring dress with crisscross straps on open back. Deep V-neck. Sleeveless. Concealed back zip. Lined. About 33\" from shoulder to hem. Polyester/spandex. Hand wash. Imported. Model shown is 5'10\" (177cm) wearing US size 4.",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "8",
                                "age_group": "adult",
                                "stock": true,
                                "price": 128,
                                "currency": "USD"
                            },
                            "fingerprint": "829fb97110017344464562a29a014f0d",
                            "created_at": "2018-05-01T00:15:31.478Z",
                            "updated_at": "2018-06-18T23:28:08.550Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8bad7b77b36100f14d2d6",
                            "schema": "product",
                            "score": 0.8304223442077636,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/da/3b/da3bc915bde4ece7388ee3b2160daabf_best.jpg",
                                    "width": 452,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "706491293_NERO:706491293:NERO:https://www.shopstyle.com/g/women/moschino/olivia-printed-dress",
                                "sku": "706491293",
                                "name": "Moschino Olivia Printed Dress",
                                "url": "https://www.shopstyle.com/g/women/moschino/olivia-printed-dress",
                                "brand": "Moschino",
                                "color": "NERO",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "- ROUND COLLAR- SHORT PUFF SLEEVE- FRONT SATIN LACE UP CLOSURE WITH METAL EYELETS- OLIVIA PRINT- UNLINED- 95% VISCOSE, 5% OTHER FABRIC - MADE IN ITALY- MODEL IS WEARING SIZE 42",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "44",
                                "age_group": "adult",
                                "stock": true,
                                "price": 513,
                                "currency": "USD"
                            },
                            "fingerprint": "878970eaad573283695d6b1e1c784b00",
                            "created_at": "2018-05-01T19:07:03.300Z",
                            "updated_at": "2018-06-28T14:42:19.958Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b3f860cf5e48d63c66f5",
                            "schema": "product",
                            "score": 0.8298108291625976,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/af/8a/af8a34f75d9fff94442314bfab20b7f9_best.jpg",
                                    "width": 184,
                                    "height": 478
                                }
                            ],
                            "data": {
                                "id": "708090382_Blue:708090382:Blue:https://www.shopstyle.com/g/women/ramy-brook/sleeveless-mini-dress-w-tags",
                                "sku": "708090382",
                                "name": "Ramy Brook Sleeveless Mini Dress w/ Tags",
                                "url": "https://www.shopstyle.com/g/women/ramy-brook/sleeveless-mini-dress-w-tags",
                                "brand": "Ramy Brook",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Blue Ramy Brook sleeveless mini dress with V-neck, embroidered eyelet accents and zip at back.",
                                "category": "Women > Clothing > Dresses |  | ",
                                "size": "M | US 6",
                                "age_group": "adult",
                                "stock": true,
                                "price": 75,
                                "currency": "USD"
                            },
                            "fingerprint": "bb3802efc7d1b1355aca534108e4d628",
                            "created_at": "2018-05-01T00:25:28.756Z",
                            "updated_at": "2018-06-25T16:31:10.569Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-15",
            "index": 15,
            "timestamp": 7500,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000016.png",
                "width": 288,
                "height": 512
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-16",
            "index": 16,
            "timestamp": 8000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000017.png",
                "width": 288,
                "height": 512
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-17",
            "index": 17,
            "timestamp": 8500,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000018.png",
                "width": 288,
                "height": 512
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-18",
            "index": 18,
            "timestamp": 9000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000019.png",
                "width": 288,
                "height": 512
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-19",
            "index": 19,
            "timestamp": 9500,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000020.png",
                "width": 288,
                "height": 512
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-20",
            "index": 20,
            "timestamp": 10000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000021.png",
                "width": 288,
                "height": 512
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-21",
            "index": 21,
            "timestamp": 10500,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000022.png",
                "width": 288,
                "height": 512
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-22",
            "index": 22,
            "timestamp": 11000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000023.png",
                "width": 288,
                "height": 512
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-23",
            "index": 23,
            "timestamp": 11500,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000024.png",
                "width": 288,
                "height": 512
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-24",
            "index": 24,
            "timestamp": 12000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000025.png",
                "width": 288,
                "height": 512
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-25",
            "index": 25,
            "timestamp": 12500,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000026.png",
                "width": 288,
                "height": 512
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-26",
            "index": 26,
            "timestamp": 13000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000027.png",
                "width": 288,
                "height": 512
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "bb5b7ac1-8a71-4b87-a250-187a4244aaa9",
                    "score": 0.9144316017627716,
                    "category": {
                        "_type": "Category",
                        "_id": "handbags",
                        "name": "handbags"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 120.61197662353516,
                        "y": 217.97079467773438,
                        "width": 77.54534149169922,
                        "height": 190.3642578125
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec656efb8d3d2f84025d",
                            "schema": "product",
                            "score": 0.8675570392608642,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/cd/8e/cd8e88273293d81dc56818085864f4a5_best.jpg",
                                    "width": 720,
                                    "height": 531
                                }
                            ],
                            "data": {
                                "id": "700736872_Silver:700736872:Silver:https://www.shopstyle.com/g/women/dolce-gabbana/handbags",
                                "sku": "700736872",
                                "name": "Dolce & Gabbana Handbags",
                                "url": "https://www.shopstyle.com/g/women/dolce-gabbana/handbags",
                                "brand": "Dolce & Gabbana",
                                "color": "Silver",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "DOLCE & GABBANA Handbags. mini, flower application, solid color, combination lock, removable shoulder strap, leather lining, bottom with studs, laminated effect, clutch. Plexiglas&reg;",
                                "category": "Women > Bags > Clutches |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 1950,
                                "currency": "USD"
                            },
                            "fingerprint": "e7b5588b51043e365d0f89481c3acbe7",
                            "created_at": "2018-05-01T22:38:29.937Z",
                            "updated_at": "2018-06-23T01:22:07.880Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d74a1393dd3d2915618c",
                            "schema": "product",
                            "score": 0.8674124145507812,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/bb/99/bb994525fa2c428764af2e492d9447d8_best.jpg",
                                    "width": 720,
                                    "height": 837
                                }
                            ],
                            "data": {
                                "id": "688919963_Red:688919963:Red:https://www.shopstyle.com/g/women/rich/handbags",
                                "sku": "688919963",
                                "name": "Rich Handbags",
                                "url": "https://www.shopstyle.com/g/women/rich/handbags",
                                "brand": "Rich",
                                "color": "Red",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "RICH Handbags. medium, gabardine, coated effect, logo, solid color, magnetic closure, internal pockets, double handle, lined interior, baguette bag. Textile fibers",
                                "category": "Women > Bags |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 179,
                                "currency": "USD"
                            },
                            "fingerprint": "d56c5426cd9cb13be41d60c48b4920bb",
                            "created_at": "2018-05-01T21:08:26.280Z",
                            "updated_at": "2018-06-18T19:51:06.403Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8edca6efb8d3d2f84d3fa",
                            "schema": "product",
                            "score": 0.8651898860931396,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/76/1a/761ac0720d25c186180eafe2b985f23b_best.jpg",
                                    "width": 542,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679567645_Red:679567645:Red:https://www.shopstyle.com/g/women/mia-bag/handbags",
                                "sku": "679567645",
                                "name": "Mia Bag Handbags",
                                "url": "https://www.shopstyle.com/g/women/mia-bag/handbags",
                                "brand": "Mia Bag",
                                "color": "Red",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MIA BAG Handbags. medium, canvas, detachable application, logo, paisley print, framed closure, internal pockets, double handle, lined interior, satchel. 100% Cotton",
                                "category": "Women > Bags > Satchels |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 149,
                                "currency": "USD"
                            },
                            "fingerprint": "11f9e50f1d7d58540dfeb038fddd51b5",
                            "created_at": "2018-05-01T22:44:26.907Z",
                            "updated_at": "2018-06-25T18:10:16.916Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cace1393dd3d290e780f",
                            "schema": "product",
                            "score": 0.8651326656341553,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4d/ac/4dacb7044cf8fcf2715a3bc7db513a90_best.jpg",
                                    "width": 720,
                                    "height": 832
                                }
                            ],
                            "data": {
                                "id": "704947305_Multicolour:704947305:Multicolour:https://www.shopstyle.com/g/women/marc-jacobs/canvas-handbag",
                                "sku": "704947305",
                                "name": "Marc Jacobs Canvas Handbag",
                                "url": "https://www.shopstyle.com/g/women/marc-jacobs/canvas-handbag",
                                "brand": "Marc Jacobs",
                                "color": "Multicolour",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Small canvas bag with a USA flag motif, a leather handle to carry it by hand or wear it on the shoulder, a flap with a gold-plated clasp and a zipped pocket inside. Original dust bag included. Some light marks on the leather of the flap.",
                                "category": "Women > Bags |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 1713.32,
                                "currency": "USD"
                            },
                            "fingerprint": "fe0d9b8fad8308a461b23df3960cc51c",
                            "created_at": "2018-05-01T20:15:10.715Z",
                            "updated_at": "2018-06-25T17:30:16.816Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d91393dd3d291411de",
                            "schema": "product",
                            "score": 0.8593526458740235,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/99/a3/99a3a712b3cf17ec738f6c4690e9b351_best.jpg",
                                    "width": 579,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "640575587_Red:640575587:Red:https://www.shopstyle.com/g/women/mia-bag/handbags",
                                "sku": "640575587",
                                "name": "Mia Bag Handbags",
                                "url": "https://www.shopstyle.com/g/women/mia-bag/handbags",
                                "brand": "Mia Bag",
                                "color": "Red",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MIA BAG Handbags. medium, denim, paisley print, logo, framed closure, internal pockets, metallic straps, lined interior, messenger. 100% Cotton",
                                "category": "Women > Bags |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 129,
                                "currency": "USD"
                            },
                            "fingerprint": "9d0a2faa16010023ec6041afaa20ecaa",
                            "created_at": "2018-05-01T20:58:01.219Z",
                            "updated_at": "2018-06-22T22:04:06.030Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90e946efb8d3d2f9249c6",
                            "schema": "product",
                            "score": 0.8576600265502929,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0b/15/0b15cbd229b299b5e0bb43f3bcefff15_best.jpg",
                                    "width": 720,
                                    "height": 720
                                }
                            ],
                            "data": {
                                "id": "705510221_Beige:705510221:Beige:https://www.shopstyle.com/g/women/furla/cloth-handbag",
                                "sku": "705510221",
                                "name": "Furla Cloth handbag",
                                "url": "https://www.shopstyle.com/g/women/furla/cloth-handbag",
                                "brand": "Furla",
                                "color": "Beige",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Beige canvas bag with motif in silver and copper colour lurex and burgundy velvet, brown leather trim, silver metal hardware, detachable black leather handle and sequined star patches and detachable shoulder strap adjustable up to 52 cm, the bag can be carried by hand, on the shoulder and cross-body, it closes with a clasp, textile interior, three small inside pockets including one zipped. No box or dust bag.",
                                "category": "Women > Bags |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 305.95,
                                "currency": "USD"
                            },
                            "fingerprint": "b13d84b59813948d03d8934df30ee74d",
                            "created_at": "2018-05-02T01:04:20.624Z",
                            "updated_at": "2018-06-26T20:52:12.668Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90d6d6efb8d3d2f91a379",
                            "schema": "product",
                            "score": 0.8554326438903809,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/83/ee/83eeacecdde988855c049e4cbaf98951_best.jpg",
                                    "width": 720,
                                    "height": 456
                                }
                            ],
                            "data": {
                                "id": "530755244_Red:530755244:Red:https://www.shopstyle.com/g/women/dooney-bourke/st-louis-cardinals-nylon-crossbody-pouchette",
                                "sku": "530755244",
                                "name": "Dooney & Bourke St. Louis Cardinals Nylon Crossbody Pouchette",
                                "url": "https://www.shopstyle.com/g/women/dooney-bourke/st-louis-cardinals-nylon-crossbody-pouchette",
                                "brand": "Dooney & Bourke",
                                "color": "Red",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "With a trim style that&apos;s versatile for your every day needs, this Dooney & Bourke St. Louis Cardinals Nylon Crossbody pouchette is a great compliment to your busy lifestyle",
                                "category": "Women > Bags > Shoulder |  | ",
                                "size": "OS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 98,
                                "currency": "USD"
                            },
                            "fingerprint": "0d7fd9dc9d8f7f9bcec0080bcdb447c2",
                            "created_at": "2018-05-02T00:59:25.311Z",
                            "updated_at": "2018-06-23T01:22:14.067Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0f1b77b36100f161ecb",
                            "schema": "product",
                            "score": 0.8548881149291992,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/3d/c8/3dc8da3698fc9efbb39867270f3adaf3_best.jpg",
                                    "width": 272,
                                    "height": 451
                                }
                            ],
                            "data": {
                                "id": "715566437_magenta:715566437:magenta:https://www.shopstyle.com/p/shrimps-venezia-beaded-handbag/715566437",
                                "sku": "715566437",
                                "name": "Shrimps Venezia Beaded Handbag",
                                "url": "https://www.shopstyle.com/p/shrimps-venezia-beaded-handbag/715566437",
                                "brand": "Shrimps",
                                "color": "magenta",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Compact and cute, this beaded handbag from Shrimps is inspired by the architecture and artworks of Venice, made with Venetian pink resin pearls and elevated with intricate floral detail in red. Let it pop against bright white or jet black. 100% Plastic Beads",
                                "category": "Women > Bags |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 450,
                                "currency": "USD"
                            },
                            "fingerprint": "74517ff0e4986cf60d9be085138d4f57",
                            "created_at": "2018-05-01T21:49:37.163Z",
                            "updated_at": "2018-06-23T01:23:07.150Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c5881393dd3d290b6e56",
                            "schema": "product",
                            "score": 0.8533598136901855,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0e/72/0e727fb1b6e793cfc9358779feb1f56b_best.jpg",
                                    "width": 555,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "704951322_Red:704951322:Red:https://www.shopstyle.com/g/women/charlotte-olympia/leather-handbag",
                                "sku": "704951322",
                                "name": "Charlotte Olympia Leather handbag",
                                "url": "https://www.shopstyle.com/g/women/charlotte-olympia/leather-handbag",
                                "brand": "Charlotte Olympia",
                                "color": "Red",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Movie night bag by Charlotte Olympia. Red and white leather popcorn clutch with white golden tone shoulder chain. Measurement: shoulder strap height: 44 cm. Material: nappa leather. Like new. Dustbag and box provided.",
                                "category": "Women > Bags > Clutches |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 558.56,
                                "currency": "USD"
                            },
                            "fingerprint": "8e5ccc4d0497ba275ef79d2c47461ab9",
                            "created_at": "2018-05-01T19:52:40.545Z",
                            "updated_at": "2018-06-22T22:01:07.752Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b486a27738101553c38d",
                            "schema": "product",
                            "score": 0.8495689010620118,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/09/27/092731ac2a447c4d0182a0fe5647a1fb_best.jpg",
                                    "width": 720,
                                    "height": 514
                                }
                            ],
                            "data": {
                                "id": "708254493_White:708254493:White:https://www.shopstyle.com/g/women/moschino/handbags",
                                "sku": "708254493",
                                "name": "Moschino Handbags",
                                "url": "https://www.shopstyle.com/g/women/moschino/handbags",
                                "brand": "Moschino",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOSCHINO Handbags. leather, print, magnetic closure, internal pockets, lined interior, stiff bottom, mini, solid color, contains non-textile parts of animal origin, satchel bags. Soft Leather",
                                "category": "Women > Bags > Satchels |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 713,
                                "currency": "USD"
                            },
                            "fingerprint": "ef0cabc187aee8f51bcf724a70abda54",
                            "created_at": "2018-05-01T18:40:06.660Z",
                            "updated_at": "2018-06-25T17:05:13.556Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-27",
            "index": 27,
            "timestamp": 13500,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000028.png",
                "width": 288,
                "height": 512
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-28",
            "index": 28,
            "timestamp": 14000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000029.png",
                "width": 288,
                "height": 512
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "f532950d-45b2-482b-a79f-1d36b06a8aed",
                    "score": 0.9144316017627716,
                    "category": {
                        "_type": "Category",
                        "_id": "handbags",
                        "name": "handbags"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 68.69999694824219,
                        "y": 216.20294189453125,
                        "width": 101.36343383789062,
                        "height": 222.2216796875
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec656efb8d3d2f84025d",
                            "schema": "product",
                            "score": 0.8675570392608642,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/cd/8e/cd8e88273293d81dc56818085864f4a5_best.jpg",
                                    "width": 720,
                                    "height": 531
                                }
                            ],
                            "data": {
                                "id": "700736872_Silver:700736872:Silver:https://www.shopstyle.com/g/women/dolce-gabbana/handbags",
                                "sku": "700736872",
                                "name": "Dolce & Gabbana Handbags",
                                "url": "https://www.shopstyle.com/g/women/dolce-gabbana/handbags",
                                "brand": "Dolce & Gabbana",
                                "color": "Silver",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "DOLCE & GABBANA Handbags. mini, flower application, solid color, combination lock, removable shoulder strap, leather lining, bottom with studs, laminated effect, clutch. Plexiglas&reg;",
                                "category": "Women > Bags > Clutches |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 1950,
                                "currency": "USD"
                            },
                            "fingerprint": "e7b5588b51043e365d0f89481c3acbe7",
                            "created_at": "2018-05-01T22:38:29.937Z",
                            "updated_at": "2018-06-23T01:22:07.880Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d74a1393dd3d2915618c",
                            "schema": "product",
                            "score": 0.8674124145507812,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/bb/99/bb994525fa2c428764af2e492d9447d8_best.jpg",
                                    "width": 720,
                                    "height": 837
                                }
                            ],
                            "data": {
                                "id": "688919963_Red:688919963:Red:https://www.shopstyle.com/g/women/rich/handbags",
                                "sku": "688919963",
                                "name": "Rich Handbags",
                                "url": "https://www.shopstyle.com/g/women/rich/handbags",
                                "brand": "Rich",
                                "color": "Red",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "RICH Handbags. medium, gabardine, coated effect, logo, solid color, magnetic closure, internal pockets, double handle, lined interior, baguette bag. Textile fibers",
                                "category": "Women > Bags |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 179,
                                "currency": "USD"
                            },
                            "fingerprint": "d56c5426cd9cb13be41d60c48b4920bb",
                            "created_at": "2018-05-01T21:08:26.280Z",
                            "updated_at": "2018-06-18T19:51:06.403Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8edca6efb8d3d2f84d3fa",
                            "schema": "product",
                            "score": 0.8651898860931396,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/76/1a/761ac0720d25c186180eafe2b985f23b_best.jpg",
                                    "width": 542,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679567645_Red:679567645:Red:https://www.shopstyle.com/g/women/mia-bag/handbags",
                                "sku": "679567645",
                                "name": "Mia Bag Handbags",
                                "url": "https://www.shopstyle.com/g/women/mia-bag/handbags",
                                "brand": "Mia Bag",
                                "color": "Red",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MIA BAG Handbags. medium, canvas, detachable application, logo, paisley print, framed closure, internal pockets, double handle, lined interior, satchel. 100% Cotton",
                                "category": "Women > Bags > Satchels |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 149,
                                "currency": "USD"
                            },
                            "fingerprint": "11f9e50f1d7d58540dfeb038fddd51b5",
                            "created_at": "2018-05-01T22:44:26.907Z",
                            "updated_at": "2018-06-25T18:10:16.916Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cace1393dd3d290e780f",
                            "schema": "product",
                            "score": 0.8651326656341553,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4d/ac/4dacb7044cf8fcf2715a3bc7db513a90_best.jpg",
                                    "width": 720,
                                    "height": 832
                                }
                            ],
                            "data": {
                                "id": "704947305_Multicolour:704947305:Multicolour:https://www.shopstyle.com/g/women/marc-jacobs/canvas-handbag",
                                "sku": "704947305",
                                "name": "Marc Jacobs Canvas Handbag",
                                "url": "https://www.shopstyle.com/g/women/marc-jacobs/canvas-handbag",
                                "brand": "Marc Jacobs",
                                "color": "Multicolour",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Small canvas bag with a USA flag motif, a leather handle to carry it by hand or wear it on the shoulder, a flap with a gold-plated clasp and a zipped pocket inside. Original dust bag included. Some light marks on the leather of the flap.",
                                "category": "Women > Bags |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 1713.32,
                                "currency": "USD"
                            },
                            "fingerprint": "fe0d9b8fad8308a461b23df3960cc51c",
                            "created_at": "2018-05-01T20:15:10.715Z",
                            "updated_at": "2018-06-25T17:30:16.816Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d91393dd3d291411de",
                            "schema": "product",
                            "score": 0.8593526458740235,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/99/a3/99a3a712b3cf17ec738f6c4690e9b351_best.jpg",
                                    "width": 579,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "640575587_Red:640575587:Red:https://www.shopstyle.com/g/women/mia-bag/handbags",
                                "sku": "640575587",
                                "name": "Mia Bag Handbags",
                                "url": "https://www.shopstyle.com/g/women/mia-bag/handbags",
                                "brand": "Mia Bag",
                                "color": "Red",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MIA BAG Handbags. medium, denim, paisley print, logo, framed closure, internal pockets, metallic straps, lined interior, messenger. 100% Cotton",
                                "category": "Women > Bags |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 129,
                                "currency": "USD"
                            },
                            "fingerprint": "9d0a2faa16010023ec6041afaa20ecaa",
                            "created_at": "2018-05-01T20:58:01.219Z",
                            "updated_at": "2018-06-22T22:04:06.030Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90e946efb8d3d2f9249c6",
                            "schema": "product",
                            "score": 0.8576600265502929,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0b/15/0b15cbd229b299b5e0bb43f3bcefff15_best.jpg",
                                    "width": 720,
                                    "height": 720
                                }
                            ],
                            "data": {
                                "id": "705510221_Beige:705510221:Beige:https://www.shopstyle.com/g/women/furla/cloth-handbag",
                                "sku": "705510221",
                                "name": "Furla Cloth handbag",
                                "url": "https://www.shopstyle.com/g/women/furla/cloth-handbag",
                                "brand": "Furla",
                                "color": "Beige",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Beige canvas bag with motif in silver and copper colour lurex and burgundy velvet, brown leather trim, silver metal hardware, detachable black leather handle and sequined star patches and detachable shoulder strap adjustable up to 52 cm, the bag can be carried by hand, on the shoulder and cross-body, it closes with a clasp, textile interior, three small inside pockets including one zipped. No box or dust bag.",
                                "category": "Women > Bags |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 305.95,
                                "currency": "USD"
                            },
                            "fingerprint": "b13d84b59813948d03d8934df30ee74d",
                            "created_at": "2018-05-02T01:04:20.624Z",
                            "updated_at": "2018-06-26T20:52:12.668Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90d6d6efb8d3d2f91a379",
                            "schema": "product",
                            "score": 0.8554326438903809,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/83/ee/83eeacecdde988855c049e4cbaf98951_best.jpg",
                                    "width": 720,
                                    "height": 456
                                }
                            ],
                            "data": {
                                "id": "530755244_Red:530755244:Red:https://www.shopstyle.com/g/women/dooney-bourke/st-louis-cardinals-nylon-crossbody-pouchette",
                                "sku": "530755244",
                                "name": "Dooney & Bourke St. Louis Cardinals Nylon Crossbody Pouchette",
                                "url": "https://www.shopstyle.com/g/women/dooney-bourke/st-louis-cardinals-nylon-crossbody-pouchette",
                                "brand": "Dooney & Bourke",
                                "color": "Red",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "With a trim style that&apos;s versatile for your every day needs, this Dooney & Bourke St. Louis Cardinals Nylon Crossbody pouchette is a great compliment to your busy lifestyle",
                                "category": "Women > Bags > Shoulder |  | ",
                                "size": "OS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 98,
                                "currency": "USD"
                            },
                            "fingerprint": "0d7fd9dc9d8f7f9bcec0080bcdb447c2",
                            "created_at": "2018-05-02T00:59:25.311Z",
                            "updated_at": "2018-06-23T01:22:14.067Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0f1b77b36100f161ecb",
                            "schema": "product",
                            "score": 0.8548881149291992,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/3d/c8/3dc8da3698fc9efbb39867270f3adaf3_best.jpg",
                                    "width": 272,
                                    "height": 451
                                }
                            ],
                            "data": {
                                "id": "715566437_magenta:715566437:magenta:https://www.shopstyle.com/p/shrimps-venezia-beaded-handbag/715566437",
                                "sku": "715566437",
                                "name": "Shrimps Venezia Beaded Handbag",
                                "url": "https://www.shopstyle.com/p/shrimps-venezia-beaded-handbag/715566437",
                                "brand": "Shrimps",
                                "color": "magenta",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Compact and cute, this beaded handbag from Shrimps is inspired by the architecture and artworks of Venice, made with Venetian pink resin pearls and elevated with intricate floral detail in red. Let it pop against bright white or jet black. 100% Plastic Beads",
                                "category": "Women > Bags |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 450,
                                "currency": "USD"
                            },
                            "fingerprint": "74517ff0e4986cf60d9be085138d4f57",
                            "created_at": "2018-05-01T21:49:37.163Z",
                            "updated_at": "2018-06-23T01:23:07.150Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c5881393dd3d290b6e56",
                            "schema": "product",
                            "score": 0.8533598136901855,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0e/72/0e727fb1b6e793cfc9358779feb1f56b_best.jpg",
                                    "width": 555,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "704951322_Red:704951322:Red:https://www.shopstyle.com/g/women/charlotte-olympia/leather-handbag",
                                "sku": "704951322",
                                "name": "Charlotte Olympia Leather handbag",
                                "url": "https://www.shopstyle.com/g/women/charlotte-olympia/leather-handbag",
                                "brand": "Charlotte Olympia",
                                "color": "Red",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Movie night bag by Charlotte Olympia. Red and white leather popcorn clutch with white golden tone shoulder chain. Measurement: shoulder strap height: 44 cm. Material: nappa leather. Like new. Dustbag and box provided.",
                                "category": "Women > Bags > Clutches |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 558.56,
                                "currency": "USD"
                            },
                            "fingerprint": "8e5ccc4d0497ba275ef79d2c47461ab9",
                            "created_at": "2018-05-01T19:52:40.545Z",
                            "updated_at": "2018-06-22T22:01:07.752Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b486a27738101553c38d",
                            "schema": "product",
                            "score": 0.8495689010620118,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/09/27/092731ac2a447c4d0182a0fe5647a1fb_best.jpg",
                                    "width": 720,
                                    "height": 514
                                }
                            ],
                            "data": {
                                "id": "708254493_White:708254493:White:https://www.shopstyle.com/g/women/moschino/handbags",
                                "sku": "708254493",
                                "name": "Moschino Handbags",
                                "url": "https://www.shopstyle.com/g/women/moschino/handbags",
                                "brand": "Moschino",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOSCHINO Handbags. leather, print, magnetic closure, internal pockets, lined interior, stiff bottom, mini, solid color, contains non-textile parts of animal origin, satchel bags. Soft Leather",
                                "category": "Women > Bags > Satchels |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 713,
                                "currency": "USD"
                            },
                            "fingerprint": "ef0cabc187aee8f51bcf724a70abda54",
                            "created_at": "2018-05-01T18:40:06.660Z",
                            "updated_at": "2018-06-25T17:05:13.556Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-29",
            "index": 29,
            "timestamp": 14500,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000030.png",
                "width": 288,
                "height": 512
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-b2b5fd1ff12b1b51c07fe95ca7b6fe17-30",
            "index": 30,
            "timestamp": 15000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-s3.amazonaws.com-f58de1818a61508b860cfd9dbcb7b5a0/c-baa8b737b9fd5a82da8bb585eea279b7-fps-2-tdt-20/process/snapshot/fps2-000031.png",
                "width": 288,
                "height": 512
            },
            "results": []
        }
    ]
}
