
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { PureComponent } from 'react'
// import classnames from 'classnames'

import Video from './DefaultVideo'


/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/DemoPlayer/Video/JWPVideo')


/* =========================================
      COMPONENTS
-------------------------------------- */

// API: https://developer.jwplayer.com/jw-player
// Demos: https://developer.jwplayer.com/jw-player/demos

class JWPVideo extends PureComponent {

    state = {}

    install = () => {
        return Video.installScript('jwplayer', 'https://content.jwplatform.com/libraries/khZsVQcY.js')
    }

    uninstall = () => {
        return new Promise((resolve, reject) => {
            try {
                if (this.api) {
                    this.api.destroy()
                }

                resolve()

            } catch (err) {
                reject(err)
            }
        })
    }

    // REVIEW: https://github.com/micnews/react-jw-player/blob/master/src/helpers/initialize.js
    setup = (options = {}) => {
        return new Promise((resolve, reject) => {
            if (!this.player) return reject(new Error(`Player element is undefined`))
            // if (!this.video) return reject(new Error(`Video element is undefined`))

            const jwplayer = window.jwplayer

            const _jwplayer = jwplayer(this.player.id)

            // setup - https://developer.jwplayer.com/jw-player/docs/developer-guide/customization/configuration-reference
            _jwplayer.setup({
                file: this.props.src,

                width: this.props.width || this.state.width || 'auto',
                height: this.props.height || this.state.height || 'auto',

                autostart: this.props.autoplay,
                mute: this.props.muted,
                controls: this.props.controls,
                repeat: this.props.loop,
            })
            // _jwplayer.remove()
            // _jwplayer.getProvider()
            // _jwplayer.getContainer()
            // _jwplayer.getPlugin()
            _jwplayer.on('ready', (data) => {
                this.event('onLoad')()
            })
            _jwplayer.on('setupError', (err) => {
                this.event('onError')(err)
            })
            _jwplayer.on('remove', () => {})

            // viewability
            // _jwplayer.getViewable()

            // playlist
            // _jwplayer.next()
            // _jwplayer.getPlaylist()
            // _jwplayer.getPlaylistItem()
            // _jwplayer.getPlaylistIndex(index)
            // _jwplayer.load(playlist)
            // _jwplayer.playlistitem(index)
            // _jwplayer.on('playlist', (data) => {})
            // _jwplayer.on('playlistItem', (data) => {})
            // _jwplayer.on('playlistComplete', () => {})

            // buffer
            // _jwplayer.getBuffer()
            // _jwplayer.on('bufferChange', (data) => {})

            // playback
            // _jwplayer.play(state)
            // _jwplayer.pause(state)
            // _jwplayer.stop()
            // _jwplayer.getState()
            _jwplayer.on('play', (data) => {
                this.event('onPlay')()
            })
            _jwplayer.on('pause', (data) => {
                this.event('onPause')()
            })
            _jwplayer.on('buffer', (data) => {
                this.event('onProgress')()
            })
            _jwplayer.on('idle', (data) => {})
            _jwplayer.on('complete', (data) => {
                this.event('onEnded')()
            })
            _jwplayer.on('firstFrame', () => {
                this.event('onLoadedData')()
            })
            _jwplayer.on('error', (err) => {
                this.event('onError')(err)
            })

            // seek
            // _jwplayer.getPosition()
            // _jwplayer.getDuration()
            // _jwplayer.seek(position)
            _jwplayer.on('seek', (data) => {})
            _jwplayer.on('seeked', (data) => {})
            _jwplayer.on('time', (data) => {
                this.currentTime = parseFloat(data.position)

                this.event('onTimeUpdate')(this.currentTime)
            })

            // volume
            // _jwplayer.getMute()
            // _jwplayer.getVolume()
            // _jwplayer.setMute(state)
            // _jwplayer.setVolume(volume)
            _jwplayer.on('mute', (data) => {})
            _jwplayer.on('volume', (data) => {})

            // resize
            // _jwplayer.getFullscreen()
            // _jwplayer.getHeight()
            // _jwplayer.getWidth()
            // _jwplayer.resize(width, height)
            _jwplayer.on('fullscreen', (data) => {
                const isFullscreen = !!data.fullscreen

                this.event('onFullscreen')(isFullscreen)
            })
            _jwplayer.on('resize', (data) => {
                const { width, height } = data

                this.event('onResize')(width, height)
            })

            // quality
            // _jwplayer.getQualityLevels()
            // _jwplayer.getCurrentQuality()
            // _jwplayer.getVisualQuality()
            // _jwplayer.setCurrentQuality(index)
            _jwplayer.on('levels', (data) => {})
            _jwplayer.on('levelsChanged', (data) => {})
            _jwplayer.on('visualQuality', (data) => {})

            // audio tracks
            // _jwplayer.getAudioTracks()
            // _jwplayer.getCurrentAudioTrack()
            // _jwplayer.setCurrentAudioTrack(index)
            _jwplayer.on('audioTracks', (data) => {})
            _jwplayer.on('audioTrackChanged', (data) => {})

            // captions
            // _jwplayer.setCaptions(styles)
            // _jwplayer.getCaptionsList()
            // _jwplayer.getCurrentCaptions()
            // _jwplayer.setCurrentCaptions(index)
            _jwplayer.on('captionsList', (data) => {})
            _jwplayer.on('captionsChanged', (data) => {})

            // controls
            // _jwplayer.getControls()
            // _jwplayer.getSafeRegion()
            // _jwplayer.addButton(icon, label, handler, id)
            // _jwplayer.removeButton(id)
            // _jwplayer.setControls(state)
            _jwplayer.on('controls', (data) => {})
            _jwplayer.on('displayClick', () => {})

            // advertising
            // _jwplayer.playAd(tag)
            _jwplayer.on('adBlock', () => {})
            _jwplayer.on('beforePlay', () => {})
            _jwplayer.on('beforeComplete', () => {})
            _jwplayer.on('adClick', (data) => {})
            _jwplayer.on('adCompanions', (data) => {})
            _jwplayer.on('adComplete', (data) => {})
            _jwplayer.on('adSkipped', (data) => {})
            _jwplayer.on('adError', (data) => {})
            _jwplayer.on('adRequest', (data) => {})
            _jwplayer.on('adStarted', (data) => {})
            _jwplayer.on('adImpression', (data) => {})
            _jwplayer.on('adPlay', (data) => {})
            _jwplayer.on('adPause', (data) => {})
            _jwplayer.on('adTime', (data) => {})

            // metadata
            _jwplayer.on('meta', (data) => {
                // TODO: this.event('onLoadedMetaData')()
            })

            // sharing
            // _jwplayerSharingPlugin.open()
            // _jwplayerSharingPlugin.close()
            // _jwplayerSharingPlugin.on('open', () => {})
            // _jwplayerSharingPlugin.on('close', () => {})
            // _jwplayerSharingPlugin.on('click', (data) => {})

            // related
            // _jwplayerRelatedPlugin.open()
            // _jwplayerRelatedPlugin.close()
            // _jwplayerRelatedPlugin.on('open', () => {})
            // _jwplayerRelatedPlugin.on('close', () => {})
            // _jwplayerRelatedPlugin.on('play', (data) => {})

            this.api = _jwplayer
            this.jwPlayer = _jwplayer

            resolve(this.api)
        })
    }

    get playing () {
        return this.state.play || this.props.autoplay
    }

    play () {
        if (this.jwPlayer) {
            this.setState({
                play: true,
                pause: false,
                stop: false,
            }, () => {
                try {
                    this.jwPlayer.play()
                } catch (err) {}
            })
        }
    }

    pause () {
        if (this.jwPlayer) {
            this.setState({
                play: false,
                pause: true,
                stop: false,
            }, () => {
                try {
                    this.jwPlayer.pause()
                } catch (err) {}
            })
        }
    }

    stop () {
        if (this.jwPlayer) {
            this.setState({
                play: false,
                pause: false,
                stop: true,
            }, () => {
                try {
                    this.jwPlayer.stop()
                } catch (err) {}
            })
        }
    }

    mute () {
        if (this.jwPlayer) {
            this.setState({
                mute: true,
            }, () => {
                try {
                    this.jwPlayer.mute()
                } catch (err) {}
            })
        }
    }

    unmute () {
        if (this.jwPlayer) {
            this.setState({
                mute: false,
            }, () => {
                try {
                    this.jwPlayer.unmute()
                } catch (err) {}
            })
        }
    }

    seek (seconds = 0) {
        if (this.youtubePlayer) {
            this.setState({
                seek: seconds,
            }, () => {
                this.jwPlayer.seek(seconds)
            })
        }
    }

    event = (name) => {
        const props = this.props

        return (...args) => {
            log('event', name, ...args)

            if (props[name]) {
                props[name](...args)
            }
        }
    }

    componentDidMount() {
        JWPVideo.index = JWPVideo.index || 0
        JWPVideo.index++

        this.setState({
            index: JWPVideo.index,
            id: this.props.id || `jwplayer-video-${JWPVideo.index}`
        })

        this.install()
            .then(() => {
                this.setup()
            })
            .catch((error) => {
                log('install', error)
            })
    }

    componentWillUnmount() {
        this.uninstall()
            .then(() => {
                // noop
            })
            .catch((error) => {
                log('uninstall', error)
            })
    }

    render() {
        return (
            <div {...{
                ref: (ref) => this.player = ref,
                id: this.state.id,
                className: this.props.className,
                style: this.props.style,
            }} />
        )
    }
}

JWPVideo.propTypes = {
    ...Video.propTypes
}

JWPVideo.defaultProps = {
    ...Video.defaultProps
}


/* =========================================
      EXPORTS
-------------------------------------- */

export default JWPVideo
