
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import '../../../../vendor/detect-element-resize'


/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/DemoPlayer/Video/DefaultVideo')


/* =========================================
      COMPONENTS
-------------------------------------- */

// API: https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Media_events

class DefaultVideo extends PureComponent {

    state = {}

    // REFACTOR: async/await
    static installScript = (global, src) => {
        return new Promise((resolve, reject) => {
            const root = window[global]

            if (root) {
                return resolve(root)
            }

            const id = `${global}-script`

            let script = document.querySelector(`script[id=${global}]`)

            const onload = () => {
                const checkIfReady = () => {
                    if (typeof root === 'function') {
                        resolve(root)

                    } else {
                        setTimeout(checkIfReady, 100)
                    }
                }
                checkIfReady()
            }

            const onerror = (error) => {
                reject(error)
            }

            if (!script) {
                script = document.createElement('script')

                script.id = id
                script.src = src

                script.onload = onload
                script.onerror = onerror

                document.head.appendChild(script)
            }

            if (root) {
                return resolve(root)

            } else {
                script.onload = onload
                script.onerror = onerror
            }
        })
    }

    // REFACTOR: async/await
    static waitForProperty = (component, property) => {
        return new Promise((resolve, reject) => {
            const checkIfReady = () => {
                if (component[property]) {
                    resolve(component[property])

                } else {
                    setTimeout(checkIfReady, 100)
                }
            }

            checkIfReady()
        })
    }

    // REFACTOR: async/await
    install = async () => {}

    // REFACTOR: async/await
    uninstall = async () => {
        try {
            window.removeResizeListener(this.player, this.onResize)
        } catch (error) {}
    }

    // REFACTOR: async/await
    setup = async () => {
        try {
            await Promise.all([
                DefaultVideo.waitForProperty(this, 'player'),
                DefaultVideo.waitForProperty(this, 'video')
            ])

            // if (!this.player) return Promise.reject(new Error(`Player element is undefined`))
            // if (!this.video) return Promise.reject(new Error(`Video element is undefined`))

            // TODO: detect video `onFullscreen` event and status (true/false)
            //      - http://stackoverflow.com/questions/9094913/how-to-figure-out-when-a-html5-video-player-enters-the-full-screen-mode-on-ios

            // TODO: detect video `onResize` event and status (width + height)
            //      - http://stackoverflow.com/questions/6492683/how-to-detect-divs-dimension-changed

            window.addResizeListener(this.player, this.onResize)

            this.api = this.video
            this.videoPlayer = this.video

            return this.api

        } catch (error) {
            throw error
        }
    }

    // REFACTOR: async/await
    play () {
        log('play()')

        if (this.videoPlayer) {
            this.setState({}, () => {
                try {
                    this.videoPlayer.play()
                } catch (error) {}
            })
        }
    }

    // REFACTOR: async/await
    pause () {
        log('pause()')

        if (this.videoPlayer) {
            this.setState({}, () => {
                try {
                    this.videoPlayer.pause()
                } catch (error) {}
            })
        }
    }

    // REFACTOR: async/await
    stop () {
        log('stop()')

        if (this.videoPlayer) {
            this.setState({}, () => {
                try {
                    this.pause()
                    this.seek(0)

                } catch (error) {}
            })
        }
    }

    // REFACTOR: async/await
    mute (value = true) {
        log('mute()')

        if (this.videoPlayer) {
            this.setState({}, () => {
                this.videoPlayer.muted = value
            })
        }
    }

    // REFACTOR: async/await
    loop (value = true) {
        log('loop()')

        if (this.videoPlayer) {
            this.setState({}, () => {
                this.videoPlayer.loop = value
            })
        }
    }

    // REFACTOR: async/await
    seek (seconds = 0) {
        log(`seek(${seconds})`)

        if (this.videoPlayer) {
            // if (this.videoPlayer.currentTime !== seconds) {
            //     return
            // }

            seconds = parseFloat(seconds || 0)

            this.setState({}, () => {
                this.videoPlayer.currentTime = parseFloat(seconds)
            })
        }
    }

    get element () {
        return this.video
    }

    get src () {
        if (this.video) {
            return this.video.src

        } else {
            return 0
        }
    }

    get duration () {
        if (this.video) {
            return this.video.duration

        } else {
            return 0
        }
    }

    // set muted (value) {}

    get muted () {
        if (this.video) {
            return !!this.video.muted

        } else {
            return false
        }
    }

    // set playing (value) {}

    get playing () {
        if (this.video) {
            return !this.video.paused

        } else {
            return false
        }
    }

    // set paused (value) {}

    get paused () {
        if (this.video) {
            return !!this.video.paused

        } else {
            return false
        }
    }

    // set stopped (value) {}

    get stopped () {
        if (this.video) {
            return !!this.video.paused && this.video.currentTime === 0

        } else {
            return false
        }
    }

    get seeking () {
        if (this.video) {
            return this.video.seeking

        } else {
            return 0
        }
    }

    set currentTime (value) {
        if (this.video) {
            return this.video.currentTime = value

        } else {
            return 0
        }
    }

    get currentTime () {
        if (this.video) {
            return this.video.currentTime

        } else {
            return 0
        }
    }

    get currentTimeString () {
        return `${this._currentTime && Math.round(this._currentTime * 100) / 100 || 0}`.toHHMMSS()
    }

    get width () {
        if (this.video) {
            // OPTIMIZATION: use memoized value
            const { width } = this.video && getComputedStyle(this.video)

            return parseInt(width || 0)

        } else {
            return 0
        }
    }

    get height () {
        if (this.video) {
            // OPTIMIZATION: use memoized value
            const { height } = this.video && getComputedStyle(this.video)

            return parseInt(height || 0)

        } else {
            return 0
        }
    }

    get size () {
        return [this.width, this.height]
    }

    get naturalWidth () {
        if (this.video) {
            const width = this.video.videoWidth

            return parseInt(width || 0)

        } else {
            return 0
        }
    }

    get naturalHeight () {
        if (this.video) {
            const height = this.video.videoHeight

            return parseInt(height || 0)

        } else {
            return 0
        }
    }

    get naturalSize () {
        return [this.naturalWidth, this.naturalHeight]
    }

    get aspectRatio () {
        return this.width / parseFloat(this.height || 1)
    }

    get naturalAspectRatio () {
        return this.naturalWidth / parseFloat(this.naturalHeight || 1)
    }

    get scaleRatio () {
        return this.width / parseFloat(this.naturalWidth || 1)
    }

    get scaleRatioX () {
        return this.width / parseFloat(this.naturalWidth || 1)
    }

    get scaleRatioY () {
        return this.height / parseFloat(this.naturalHeight || 1)
    }

    event = (name) => {
        const props = this.props

        return (...args) => {
            log('event', name, ...args)

            if (props[name]) {
                props[name](...args)
            }
        }
    }

    componentDidMount () {
        window.DefaultVideo = this

        DefaultVideo.index = DefaultVideo.index || 0
        DefaultVideo.index++

        this.setState({
            index: DefaultVideo.index,
            id: this.props.id || `html5-video-${DefaultVideo.index}`
        })

        this.install()
            .then(() => {
                this.setup()
            })
            .catch((error) => {
                log('install', error)
            })
    }

    componentWillUnmount () {
        this.uninstall()
            .then(() => {
                // noop
            })
            .catch((error) => {
                log('uninstall', error)
            })
    }

    render () {
        let width = this.props.width || 'auto'
        let height = this.props.height || 'auto'

        const aspectRatio = parseFloat(this.props.width || 0) / parseFloat(this.props.height || 1)

        width = parseInt(width)
        height = parseInt(height)

        if (Number.isNaN(width)) {
            width = undefined
        } else {
            width = `${width}px`
        }

        if (Number.isNaN(height)) {
            height = undefined
        } else {
            height = `${height}px`
        }

        const playerProps = {
            id: this.props.id,
            className: classnames('DefaultVideo', this.props.className),
            style: this.props.style,

            src: this.props.src,

            width: this.props.width || this.state.width || 'auto',
            height: this.props.height || this.state.height || 'auto',

            autoPlay: this.props.autoplay,
            muted: this.props.muted,
            controls: this.props.controls,
            loop: this.props.loop,

            ref: this.onRef,

            onPlay: this.onPlay,
            onPause: this.onPause,
            onTimeUpdate: this.onTimeUpdate,
            onProgress: this.onProgress,
            onSeeking: this.onSeeking, // TODO: add support in other players
            onSeeked: this.onSeeked, // TODO: add support in other players
            onLoadedData: this.onLoadedData,
            onLoad: this.onLoad,
            onError: this.onError,
            onEnded: this.onEnded,
        }

        return (
            <video className={classnames('DefaultVideo')}
                {...playerProps}

                style={{
                    width,
                    height,
                }}

                data-width={width}
                data-height={height}
                data-aspect-ratio={aspectRatio}
            />
        )
    }

    onRef = (ref) => {
        this.player = ref
        this.video = ref
    }

    onPlay = () => {
        this.event('onPlay')()
    }

    onPause = () => {
        this.event('onPause')()
    }

    onTimeUpdate = () => {
        if (this.video.currentTime === this._currentTime) {
            return
        }

        this._currentTime = this.video.currentTime

        this.event('onTimeUpdate')(this._currentTime)
    }

    onProgress = () => {
        this.event('onProgress')()
    }

    onSeeking = () => {
        this.event('onSeeking')()
    }

    onSeeked = () => {
        this.event('onSeeked')()
    }

    onLoadedData = () => {
        this.event('onLoadedData')()
    }

    onLoad = () => {
        this.event('onLoad')()
    }

    onError = () => {
        this.event('onError')()
    }

    onEnded = () => {
        this.event('onEnded')()
    }

    onResize = () => {
        const width = this.video.width
        const height = this.video.height

        this.event('onResize')(this.video.width, this.video.height)
    }

    // TODO:
    //
    // play = () => {}
    // pause = () => {}
}

DefaultVideo.propTypes = {
    src: PropTypes.string,
    width: PropTypes.any,
    height: PropTypes.any,
    autoplay: PropTypes.bool,
    controls: PropTypes.bool,
    muted: PropTypes.bool,

    hidden: PropTypes.bool,

    onError: PropTypes.func,
    onLoad: PropTypes.func,
    onLoadedData: PropTypes.func,
    onLoadedMetaData: PropTypes.func,

    onPlay: PropTypes.func,
    onPause: PropTypes.func,
    onProgress: PropTypes.func,
    onTimeUpdate: PropTypes.func,
    onEnded: PropTypes.func,
    onFullscreen: PropTypes.func,
    onResize: PropTypes.func,
}

DefaultVideo.defaultProps = {
    src: undefined,
    width: undefined,
    height: undefined,
    autoplay: false,
    controls: true,
    muted: true,

    hidden: undefined,

    onError: () => undefined, // log('onError'),
    onLoad: () => undefined, // log('onLoadedData'),
    onLoadedData: () => undefined, // log('onLoadedData'),
    onLoadedMetaData: () => undefined, // log('onLoadedMetaData'),

    onPlay: (event) => undefined, // log('onPlay'),
    onPause: (event) => undefined, // log('onPause'),
    onProgress: (event) => undefined, // log('onProgress'),
    onTimeUpdate: (event) => undefined, // log('onTimeUpdate'),
    onEnded: () => undefined, // log('onEnded'),
    onFullscreen: () => undefined, // log('onFullscreen'),
    onResize: () => undefined, // log('onResize'),
}


/* =========================================
      EXPORTS
-------------------------------------- */

export default DefaultVideo
