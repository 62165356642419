
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import shallowEqual from 'react-fast-compare'

import Dropzone from 'react-dropzone'

import DemoInputFieldSampleVideos from './DemoInputFieldSampleVideos'


/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/DemoInputField')


/* =========================================
      COMPONENTS
-------------------------------------- */

class DemoInputField extends Component {

    state = {
        focus: false,
    }

    shouldComponentUpdate (nextProps, nextState) {
        const { props, state } = this

        if (shallowEqual(props, nextProps) && shallowEqual(state, nextState)) {
            return false
        }

        return true
    }

    render () {
        const { props } = this

        const samples = props.samples || []
        const hasSamples = !!samples.length
        const hasFocus = !!this.state.focus

        const id = props.id
        const className = props.className
        const style = props.style
        const disabled = !!props.disabled
        const readonly = !!props.readonly
        const hidden = !!props.hidden

        const upload = props.upload
        return (
            <div className={classnames('DemoInputField', className, {hidden, disabled})}
                id={id}
                style={style}

                data-disabled={disabled}
                data-readonly={readonly}
                data-hidden={hidden}
            >
                <label htmlFor="DemoInputField-url" className={classnames('DemoInputField-label', {hidden: !props.label})}>
                    <span className={classnames('DemoInputField-label-text')}>
                        { props.label }
                    </span>
                </label>

                <div className="DemoInputField-url-input">

                    <input id="DemoInputField-url"
                        className={classnames('DemoInputField-url', {error: !!props.error})}
                        type="text"
                        value={props.value || ''}
                        placeholder={props.placeholder || `URL or CSV`}
                        readOnly={readonly}

                        ref={(ref) => {
                            this.input = ref
                        }}

                        onChange={this.onInputChange}
                        onFocus={this.onInputFocus}
                        onBlur={this.onInputBlur}
                    />

                    <DemoInputFieldSampleVideos
                        samples={samples}
                        hidden={!hasSamples || !hasFocus}

                        onChange={this.onInputChange}
                    />

                </div>

                <div className="DemoInputField-url-actions">
                    {
                        props.showImport && (
                            <Dropzone className="DemoInputField-url-pick-drop" onDrop={props.onDrop} activeClassName="drop">
                                <button className="DemoInputField-url-action DemoInputField-url-action-pick">
                                    Import
                                </button>
                            </Dropzone>
                        )
                    }

                    <button className={classnames('DemoInputField-url-action DemoInputField-action-browse', {disabled: hasFocus, hidden: hasFocus})}
                        onClick={() => setTimeout(() => { this.input.focus() }, 100)}
                    >
                        { hasFocus ? 'Close' : 'Samples' }
                    </button>

                    <button className={classnames('DemoInputField-url-action DemoInputField-action-clear', {hidden: true})}
                        onClick={props.onClear}
                    >
                        Clear
                    </button>

                    <button className={classnames('DemoInputField-url-action DemoInputField-action-upload', {hidden: readonly})}
                        onClick={upload}
                    >
                        Upload Video
                    </button>

                    { props.children }
                </div>
            </div>
        )
    }

    onInputChange = (event) => {
        const  { props } = this

        if (typeof props.onChange !== 'function') {
            return
        }

        props.onChange(event)
    }

    onInputFocus = (event) => {
        this.setState({
            focus: true,
        })
    }

    onInputBlur = (event) => {
        setTimeout(() => {
            this.setState({
                focus: false,
            })
        }, 300)
    }

}

DemoInputField.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    hidden: PropTypes.bool,

    error: PropTypes.any,
    value: PropTypes.string,
    placeholder: PropTypes.string,

    showImport: PropTypes.bool,

    onChange: PropTypes.func,
    onDrop: PropTypes.func,
    onBrowse: PropTypes.func,
    onClear: PropTypes.func,
}

DemoInputField.defaultProps = {
    id: undefined,
    className: undefined,
    style: undefined,
    disabled: undefined,
    readonly: undefined,
    hidden: undefined,

    error: undefined,
    value: undefined,
    placeholder: undefined,

    showImport: true,

    onChange: () => log('onChange'),
    onDrop: () => log('onDrop'),
    onBrowse: () => log('onBrowse'),
    onClear: () => log('onClear'),
}


/* =========================================
      EXPORT
-------------------------------------- */

export default DemoInputField
