
/* =========================================
      CONSTANTS
-------------------------------------- */
const API_BACKEND_NAME = process.env.REACT_APP_ENV

export const API_BACKEND = API_BACKEND_NAME

// export const API_ENDPOINT = API_ENDPOINTS[API_BACKEND]
export const API_ENDPOINT = process.env.REACT_APP_ENDPOINT

export const API_HEALTH = process.env.REACT_APP_HEALTH

export const API_VIDEO_PIPELINE_STEPS_V2 = [
    'meta',
    'download',
    'snapshot',
    'cv',
    'nn',
    'db',
    'map',
]
export const API_VIDEO_PIPELINE_STEPS_V3 = [
    'meta',
    'download',
    'snapshot',
    'cv',
    // 'track',
    // 'nn',
    // 'db',
]

export const API_VIDEO_PIPELINE_STEPS_BY_BACKEND = {
    v2: API_VIDEO_PIPELINE_STEPS_V3,
    v3: API_VIDEO_PIPELINE_STEPS_V3,
    dev: API_VIDEO_PIPELINE_STEPS_V3,
    staging: API_VIDEO_PIPELINE_STEPS_V3,
    production: API_VIDEO_PIPELINE_STEPS_V3,
    cn: API_VIDEO_PIPELINE_STEPS_V3,
}

export const API_VIDEO_PIPELINE_STEPS = API_VIDEO_PIPELINE_STEPS_BY_BACKEND[API_BACKEND]

export const API_VIDEO_SERVICE_DEFAULT_SEEK = 0
export const API_VIDEO_SERVICE_DEFAULT_TRACK_MIN = 0
export const API_VIDEO_SERVICE_DEFAULT_TRACK_MAX = 30
// export const API_VIDEO_SERVICE_DEFAULT_TRACK_DEFAULT = 30
export const API_VIDEO_SERVICE_DEFAULT_TRACK_DEFAULT = 7
export const API_VIDEO_SERVICE_DEFAULT_TRACK = [
    API_VIDEO_SERVICE_DEFAULT_TRACK_MIN,
    API_VIDEO_SERVICE_DEFAULT_TRACK_MAX
]
export const API_VIDEO_SERVICE_DEFAULT_PRECISION = undefined
export const API_VIDEO_SERVICE_DEFAULT_FPS = undefined

export const API_VIDEO_SERVICE_DEFAULT_LIMIT = 15
export const API_VIDEO_SERVICE_DEFAULT_RELEVANCE = 0.8
export const API_VIDEO_SERVICE_DEFAULT_FAMILY_SEARCH = true
export const API_VIDEO_SERVICE_DEFAULT_DEDUPLICATION = true
export const API_VIDEO_SERVICE_DEFAULT_SPECIFIC_MATCH = true
export const API_VIDEO_SERVICE_DEFAULT_ATTRIBUTES = false
export const API_VIDEO_SERVICE_DEFAULT_PUBLISH = true

export const API_VIDEO_MAX_TOP_TRACKS = 7

// export const API_IMAGE_SERVICE_REGIONS = ['US', 'CH']

// export const API_IMAGE_SERVICE_ENDPOINT_BY_REGION = {
//     'US': 'catalog.markable.ai',
//     // 'CH': 'catalog.markable.ai',
// }

export const API_IMAGE_SERVICE_ENDPOINT = process.env.REACT_APP_API_IMAGE_SERVICE_ENDPOINT_BY_REGION

export const CLOUDINARY_CLOUD_NAME = 'dksvnv29q'
export const CLOUDINARY_UPLOAD_PRESET = 'ooji8lv8'

export const UPTIME_PIXEL_URL = `//s3.amazonaws.com/markable-drishti-ui/pixel.png`

export const COLORS = [
    // 'aqua',
    // 'azure',
    // 'beige',
    // 'black',
    'blue',
    // 'brown',
    // 'cyan',
    'darkblue',
    'darkcyan',
    // 'darkgrey',
    // 'darkgreen',
    // 'darkkhaki',
    // 'darkmagenta',
    'darkolivegreen',
    'darkorange',
    // 'darkorchid',
    'darkred',
    // 'darksalmon',
    'darkviolet',
    'fuchsia',
    'gold',
    'green',
    'indigo',
    // 'khaki',
    // 'lightblue',
    // 'lightcyan',
    // 'lightgreen',
    // 'lightgrey',
    // 'lightpink',
    // 'lightyellow',
    // 'lime',
    // 'magenta',
    // 'maroon',
    'navy',
    'olive',
    'orange',
    'pink',
    'purple',
    // 'violet',
    'red',
    // 'silver',
    // 'white',
    // 'yellow'
]

export const TRANSPARENT_PIXEL = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
export const ZINDEX_MAX = 2147483647
export const CATEGORY_SCORE_THRESHOLD = 0.6
