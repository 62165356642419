
import videoCV from './videoCV'
import videoLens from './videoLens'

export { default as videoCV } from './videoCV'
export { default as videoLens } from './videoLens'

export default {
    videoCV,
    videoLens,
}
