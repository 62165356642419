
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import shallowEqual from 'react-fast-compare'


/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/DemoPlayer/LensVideoOverlayFrameImage')


/* =========================================
      COMPONENTS
-------------------------------------- */

class LensVideoOverlayFrameImage extends Component {

    state = {
        loading: true,
        loaded: false,
        error: false,
    }

    // componentWillReceiveProps () {
    //     this.onLoading()
    // }

    shouldComponentUpdate (nextProps, nextState) {
        const { props, state } = this

        if (props.uri !== nextProps.uri) {
            this.onLoading()
        }

        if (state.loaded !== nextState.loaded) {
            const isLoaded = !!nextState.loaded

            if (isLoaded) {
                this.onLoad()
            }
        }

        if (shallowEqual(props, nextProps) && shallowEqual(state, nextState)) {
            return false
        }


        return true
    }

    render () {
        const { props } = this

        const uri = (props.uri || props.src || '#').replace(window.API_ENDPOINT, window.videoEndpoint) // HACK >:(
        const hidden = props.hidden
        const className = props.className

        return (
            <img
                className={classnames('LensVideoOverlayFrameImage', className, {
                    hidden: !uri || hidden,
                })}

                ref={(img) => {
                    this.img = img

                    // if (img.completed) {
                    //     return this.onLoad()
                    // }
                }}

                src={uri}

                alt={''}

                onLoad={this.onLoad}
                onError={this.onError}
            />
        )
    }

    // BUG: `onLoading` is not properly triggered, partly because CORS access not allowed for checking `completed` images
    onLoading = () => {
        const { props } = this

        this.setState({
            loading: true,
            loaded: false,
            error: false,
        })

        if (typeof props.onLoading !== 'function') {
            return
        }

        props.onLoading()
    }

    onLoad = () => {
        const { props } = this

        this.setState({
            loading: false,
            loaded: true,
            error: false,
        })

        if (typeof props.onLoad !== 'function') {
            return
        }

        props.onLoad()
    }

    onError = (error) => {
        const { props } = this

        this.setState({
            loading: false,
            loaded: false,
            error: true,
        })

        if (typeof props.onError !== 'function') {
            return
        }

        props.onError(error)
    }

}

LensVideoOverlayFrameImage.propTypes = {
    uri: PropTypes.string,

    onLoading: PropTypes.func,
    onLoad: PropTypes.func,
    onError: PropTypes.func,
}

LensVideoOverlayFrameImage.defaultProps = {
    uri: undefined,

    onLoading: undefined,
    onLoad: undefined,
    onError: undefined,
}


/* =========================================
      EXPORTS
-------------------------------------- */

export default LensVideoOverlayFrameImage
