
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { Component, PureComponent } from 'react'
import classnames from 'classnames'

import ReactPlayer from 'react-player'

import {
    isUri,
} from '../../../utils/uri'

import {
    findMatchingVideoFrames,
} from '../../../utils/video'


import {
    videoLens,
} from '../../../services'

import LensXRay from './layers/LensXRay'


/* =========================================
      STYLES
-------------------------------------- */

import './LensPlayer.css'


/* =========================================
      CONSTANTS
-------------------------------------- */

import {
    API_VIDEO_PIPELINE_STEPS,
} from '../../../constants'

import {
    DEMO_VIDEOS,
    TEST_VIDEOS,
} from '../../../constants/samples'

import fixtures from '../../../state/fixtures'

const IS_LOCALHOST = window.location.host.includes('localhost')
const IS_DEV = IS_LOCALHOST || window.location.host.includes('-dev')

const SAMPLE_VIDEOS = [
    ...(DEMO_VIDEOS),
    ...(IS_DEV ? TEST_VIDEOS : []),
]

const SAMPLE_DATA = IS_LOCALHOST ? {
    // [`${location.origin}/static/videos/vip_1.mp4`]: fixtures['vip_1'],
    // [`https://www.youtube.com/watch?v=KB61lPDqfNE`]: fixtures['vip_1'],
    [`https://s3.amazonaws.com/data-operations-sallie-gardner/tmp/VIP_1.mp4`]: fixtures['vip_1'],

    // [`${location.origin}/static/videos/vip_2.mp4`]: fixtures['vip_2'],
    // [`https://www.youtube.com/watch?v=Ed6L5GugkcQ`]: fixtures['vip_2'],
    [`https://d3vv6lp55qjaqc.cloudfront.net/items/171R2R0x071W1B431r44/vip_2.mp4`]: fixtures['vip_2'],
} : {}


/* =========================================
      LOGGER
-------------------------------------- */

let log = debug('src/ui/components/LensPlayer')


/* =========================================
      COMPONENTS
-------------------------------------- */

class LensPlayer extends Component {

    state = {
        videoURI: SAMPLE_VIDEOS[0],
    }

    componentDidMount () {
        const { props } = this

        const { url, accessToken } = props

        const videoURI = url
        const videoSearchStatsKeys = API_VIDEO_PIPELINE_STEPS

        this.setState({
            videoURI,
            accessToken,

            // HACK: should not be required for a component
            videoSearchStatsKeys,
        }, () => {
            this.onVideoSeeked()
        })

        window.player = this
    }

    render () {
        const { props, state } = this

        const {
            url,

            id,
            className,
            style,
        } = props || {}

        const {
            onPlayerReady,
            onPlayerStart,
            onPlayerPlay,
            onPlayerProgress,
            onPlayerDuration,
            onPlayerPause,
            onPlayerBuffer,
            onPlayerBufferEnd,
            onPlayerSeek,
            onPlayerEnded,
            onPlayerError,
            onPlayerEnablePIP,
            onPlayerDisablePIP,
        } = this

        const {} = state

        return (
            <div
                id={id}
                className={classnames('LensPlayer', className)}
                style={style}
            >
                <ReactPlayer
                    ref={(reactPlayer) => {
                        this.reactPlayer = reactPlayer
                        this.video = reactPlayer && reactPlayer.wrapper && reactPlayer.wrapper.querySelector('video')
                    }}
                    className={classnames('LensReactPlayer')}
                    style={{}}

                    url={url}

                    width={`100%`}
                    height={`100%`}

                    playing={false}
                    loop={false}
                    controls={true}
                    muted={true}
                    volume={0}

                    wrapper={`div`}
                    config={{}}
                    light={false}
                    pip={false}
                    playsinline={true}

                    onReady={onPlayerReady}
                    onStart={onPlayerStart}
                    onPlay={onPlayerPlay}
                    onProgress={onPlayerProgress}
                    onDuration={onPlayerDuration}
                    onPause={onPlayerPause}
                    onBuffer={onPlayerBuffer}
                    onBufferEnd={onPlayerBufferEnd}
                    onSeek={onPlayerSeek}
                    onEnded={onPlayerEnded}
                    onError={onPlayerError}
                    onEnablePIP={onPlayerEnablePIP}
                    onDisablePIP={onPlayerDisablePIP}
                />

                <LensXRay
                    frames={this.videoSearchFrames}

                    onToggle={(show) => {
                        if (show) {
                            this.video.pause()

                        } else {
                            this.video.play()
                        }
                    }}
                />
            </div>
        )
    }

    onPlayerReady = (...args) => {
        log('onPlayerReady', ...args)
    }

    onPlayerStart = (...args) => {
        log('onPlayerStart', ...args)
    }

    onPlayerPlay = (...args) => {
        log('onPlayerPlay', ...args)
    }

    onPlayerProgress = (progress) => {
        log('onPlayerProgress', progress)

        const currentTime = progress.playedSeconds || 0

        this.onVideoTimeUpdate(currentTime)
    }

    onPlayerDuration = (...args) => {
        log('onPlayerDuration', ...args)
    }

    onPlayerPause = (...args) => {
        log('onPlayerPause', ...args)
    }

    onPlayerBuffer = (...args) => {
        log('onPlayerBuffer', ...args)
    }

    onPlayerBufferEnd = (...args) => {
        log('onPlayerBufferEnd', ...args)
    }

    onPlayerSeek = (...args) => {
        log('onPlayerSeek', ...args)
    }

    onPlayerEnded = (...args) => {
        log('onPlayerEnded', ...args)
    }

    onPlayerError = (...args) => {
        log('onPlayerError', ...args)
    }

    onPlayerEnablePIP = (...args) => {
        log('onPlayerEnablePIP', ...args)
    }

    onPlayerDisablePIP = (...args) => {
        log('onPlayerDisablePIP', ...args)
    }

    onVideoTimeUpdate = (currentTime, options = {}) => {
        log('onVideoTimeUpdate', currentTime, options)

        if (!options.force) {
            if (this.state.videoFrameHover) {
                return false
            }

            if (this.state.videoCurrentTime === currentTime) {
                return false
            }
        }

        if (options.seek) {
            this.video.currentTime = currentTime
        }

        this.setState({
            videoCurrentTime: currentTime,
        }, async () => {
            // TODO/WIP: only make another request if current paged response don't have the current frame
            const videoCurrentTime = this.state.videoCurrentTime || 0

            await this.onSearchVideo()

            const videoSearchResponse = this.state.videoSearchResponse || {}

            // log('onSearchVideo', videoCurrentTime, videoSearchResponse)

            const meta = videoSearchResponse.meta || {}

            let frames = videoSearchResponse.data || []

            // HACK: map to correct endpoint
            frames = frames.map((frame) => {
                return {
                    ...frame,
                    image: {
                        ...frame.image,
                        uri: `${frame.image && frame.image.uri}`.replace('localhost:1331', this.state.videoEndpoint),
                    },
                    results: [...frame.results].sort((resultA, resultB) => {
                        return resultB.score - resultA.score
                    })
                }
            })

            videoSearchResponse.data = frames

            const matchVideoTimeFrom = (videoCurrentTime * 1000)
            const matchVideoTimeTo = (matchVideoTimeFrom)

            const matchingFrames = findMatchingVideoFrames(frames, matchVideoTimeFrom, 0, matchVideoTimeTo) || []
            const matchingFrame = matchingFrames[0] || {}

            // log('findMatchingVideoFrames', videoCurrentTime, matchVideoTimeFrom, matchVideoTimeTo, matchingFrames)

            matchingFrame.results = (matchingFrame.results || []).filter((result) => {
                const category = (typeof result.category === 'object') ? result.category._id : result.category

                // REFACTOR: hardcoded hack
                return ![
                    'humans',
                ].includes(category)
            })

            let videoSearchResults = matchingFrame.results || []

            let videoSearchResultsAll = []

            for (const frame of frames) {
                for (const result of frame.results) {
                    const exists = !!videoSearchResultsAll.find((videoSearchResult) => {
                        return Boolean(videoSearchResult.track._id === result.track._id)
                    })

                    if (!exists) {
                        videoSearchResultsAll = [...videoSearchResultsAll, result]
                    }
                }
            }

            const videoFrame = {
                ...matchingFrame,
                // searchResults: results,
            }

            const videoFrames = [videoFrame]

            // log({videoFrame})

            const videoFrameSnapshotImageURI = videoFrame.image && videoFrame.image.uri

            this.setState({
                videoFrameSnapshotImageURI,

                videoFrame,
                videoFrames,

                videoSearchResults,
                videoSearchResultsAll,

                frames,

            }, () => {
                if (!this.video) {
                    return log('waiting for video')
                }

                // NOTE: this causes buggy player experience
                // this.video.playbackRate = 0.5
                // this.video.currentTime = ((videoFrame.timestamp || 0) / 1000.0)

                const videoSnapshotImage = (videoFrame && videoFrame.image) || {}
                const videoPlayerSizeImage = (frames[0] && frames[0].image) || {}

                // FIXME: server should generate original dimension snapshots (as well)
                const videoSnapshotSize = {
                    width: parseInt(videoPlayerSizeImage.width || 0),
                    height: parseInt(videoPlayerSizeImage.height || 0),

                    naturalWidth: parseInt(videoPlayerSizeImage.naturalWidth || 0),
                    naturalHeight: parseInt(videoPlayerSizeImage.naturalHeight || 0),

                    aspectRatio: parseFloat(videoPlayerSizeImage.width || 0.0) / parseFloat(videoPlayerSizeImage.height || 1),
                    scaleRatio: parseFloat(videoPlayerSizeImage.width || 0.0) / parseFloat(videoPlayerSizeImage.width || 1),
                }

                // FIXME: use `lensVideo.element` or something similar
                const player = this.video

                const videoPlayerSize = {
                    width: parseInt(player.width || 0),
                    height: parseInt((player.width || 0) / parseFloat(videoSnapshotSize.aspectRatio || 1)),

                    naturalWidth: parseInt(player.naturalWidth || 0),
                    naturalHeight: parseInt(player.naturalHeight || 0) / parseFloat(videoSnapshotSize.aspectRatio || 1),

                    aspectRatio: videoSnapshotSize.aspectRatio,
                    scaleRatio: videoSnapshotSize.scaleRatio,
                }

                this.setState({
                    videoPlayerSize,

                    videoSnapshotImage,
                    videoSnapshotSize,
                })

            })
        })
    }

    onSearchVideo = (options = {force: false, reindex: false, refresh: false}) => {
        return new Promise((resolve, reject) => {
            let videoSearchURI = this.state.videoURI
            const videoSearchURILast = this.state.videoSearchURI

            log('onSearchVideo', videoSearchURI)

            if (videoSearchURI && videoSearchURI.startsWith('//')) {
                videoSearchURI = `${window.location.protocol}${videoSearchURI}`
            }

            if (!isUri(videoSearchURI)) {
                return console.warn('onSearchVideo', `Invalid URI: ${this.state.videoURI}`)
            }

            const videoIsAlreadySearched = Boolean(videoSearchURI === videoSearchURILast)

            log('videoIsAlreadySearched', videoIsAlreadySearched)

            let seek = this.state.videoSearchSeek

            let trackBegin = this.state.videoSearchTrackBegin
            let trackEnd = this.state.videoSearchTrackEnd
            let track = [-Math.abs(trackBegin), Math.abs(trackEnd)]

            let precision = this.state.videoSearchPrecision

            let framesPerSecond = this.state.videoSearchFPS

            let limit = this.state.videoSearchLimit
            let relevance = this.state.videoSearchRelevance
            let family_search = this.state.videoSearchFamilySearch
            let deduplication = this.state.videoSearchDeduplication
            let specific_match = this.state.videoSearchSpecificMatch

            const hasNewtrackingOptions = (
                (this.state.videoSearchSeekLast !== seek) ||
                (this.state.videoSearchTrackBeginLast !== trackBegin) ||
                (this.state.videoSearchTrackEndLast !== trackEnd) ||
                (this.state.videoSearchPrecisionLast !== precision) ||
                (this.state.videoSearchFPSLast !== framesPerSecond) ||
                (this.state.videoSearchLimitLast !== limit) ||
                (this.state.videoSearchRelevanceLast !== relevance) ||
                (this.state.videoSearchFamilySearchLast !== family_search) ||
                (this.state.videoSearchDeduplicationLast !== deduplication) ||
                (this.state.videoSearchSpecificMatchLast !== specific_match)
            )

            let {
                force,
                refresh,
                reindex,
            } = options

            if (!force && !refresh && !reindex && !hasNewtrackingOptions) {
                if (videoIsAlreadySearched) {
                    return this.setState({
                        videoIsAlreadySearched,
                    }, resolve)
                }
            }

            this.setState({
                videoSearchURI,

                // REFACTOR: `onResetState` helper function
                videoSearchResponse: null,
                videoSearchResults: null,
                videoSearchResultsAll: null,
                videoSearchStats: null,
                videoSearchTotalTime: null,
                videoSearchURIComplete: null,

                videoSnapshotImage: null,
                videoSnapshotSize: null,

                videoPlayerSize: null,

                videoFrameSnapshotImageURI: null,

                videoFrame: null,
                videoFrames: null,

                frames: null,

                topTracks: null,

            }, async () => {
                clearInterval(this.searchInterval)

                let catalogs

                switch (this.state.videoSearchCatalogMode) {
                    case 'default':
                        catalogs = undefined
                        break

                    case 'public':
                        catalogs = Object.values(this.state.videoSearchCatalogsByRegion)
                            .reduce((catalogs, regionCatalogs) => {
                                return [...catalogs, ...regionCatalogs]
                            }, [])
                            .map((catalog) => {
                                return catalog._id || catalog.name
                            })
                        break

                    case 'custom':
                    default:
                        catalogs = (this.state.videoSearchCatalogs || [])
                            .filter(Boolean)
                            .map((catalog) => {
                                return catalog._id || catalog.name
                            })
                }

                let cache = true
                let pendingResponse = false

                let reindex = options.reindex
                let refresh = options.refresh

                this.searchInterval = setInterval(async () => {
                    if (pendingResponse) {
                        return
                    }

                    pendingResponse = true

                    let result

                    if (videoSearchURI.includes('localhost') && SAMPLE_DATA[videoSearchURI]) {
                        result = {
                            response: {
                                body: SAMPLE_DATA[videoSearchURI],
                            },
                        }

                    } else {
                        const endpoint = this.state.videoEndpoint
                        const accessToken = this.state.accessToken || this.props.accessToken || null
                        const pageLimit = this.state.pageLimit || 200

                        try {
                            const videoSearchParams = {
                                endpoint,
                                accessToken,

                                catalogs,

                                seek,
                                track,
                                precision,

                                framesPerSecond,

                                limit,
                                relevance,
                                family_search,
                                deduplication,
                                specific_match,

                                reindex,
                                refresh,

                                pageLimit,

                                cache,
                            }

                            result = await videoLens.search(videoSearchURI, videoSearchParams)

                            this.setState({
                                apiError: null
                            })

                        } catch (error) {
                            this.setState({
                                apiError: error
                            })
                        }

                        reindex = false
                        refresh = false
                    }

                    pendingResponse = false

                    // log('API', result)

                    try {
                        const videoSearchResponse = result && result.response && result.response.body || {}

                        const meta = videoSearchResponse.meta || {}
                        const data = videoSearchResponse.data || {}

                        // log('META', meta)
                        // log('STATUSES', statuses)

                        const videoSearchStatuses = meta.statuses || []

                        const videoSearchStats = videoSearchStatuses
                            .reduce((status, videoStep) => {
                                // HACK: need fix server side eventually
                                if (Object.keys(videoStep || {}).length <= 1) {
                                    return status
                                }

                                return {
                                    ...status,
                                    [videoStep.step]: videoStep
                                }
                            }, {})

                        const videoSearchTotalTime = videoSearchStatuses
                            .reduce((sum, status) => {
                                if (!!status.elapsedTime) {
                                    return sum + status.elapsedTime
                                }

                                return sum
                            }, 0)

                        for (const key of (this.state.videoSearchStatsKeys || [])) {
                            videoSearchStats[key] = videoSearchStats[key] || {
                                completed: 0,
                                total: data.length,
                            }
                        }

                        this.setState({
                            videoSearchResponse,

                            videoSearchStats,
                            videoSearchTotalTime,

                            // videoSearchSeek: seek,
                            // videoSearchTrack: track,
                            // videoSearchPrecision: precision,

                            videoSearchSeekLast: seek,
                            videoSearchTrackBeginLast: trackBegin,
                            videoSearchTrackEndLast: trackEnd,
                            videoSearchPrecisionLast: precision,
                            videoSearchFPSLast: framesPerSecond,
                            videoSearchLimitLast: limit,
                            videoSearchRelevanceLast: relevance,
                            videoSearchFamilySearchLast: family_search,
                            videoSearchDeduplicationLast: deduplication,
                            videoSearchSpecificMatchLast: specific_match,

                        }, () => {
                            const requiredCompletionStep = this.state.videoSearchStatsKeys[(this.state.videoSearchStatsKeys || []).length - 1]
                            const requiredStepStats = videoSearchStats[requiredCompletionStep]
                            const videoSearchComplete = Boolean(requiredStepStats.completed >= requiredStepStats.total)


                            if (videoSearchComplete) {
                                clearInterval(this.searchInterval)

                                this.onVideoTimeUpdate(0, {force: true})

                                const videoSearchURIComplete = videoSearchURI

                                this.setState({
                                    videoSearchURIComplete,
                                }, resolve)

                            } else {
                                cache = false
                            }
                        })

                    } catch (error) {
                        console.error(error)

                        resolve()
                    }

                }, 3000)
            })
        })
    }

    onVideoSeeked = (event) => {
        log('onVideoSeeked')

        const { currentTime } = this.video || {}

        const videoSearchSeek = currentTime || 0

        this.setState({
            videoSearchSeek,
        }, async () => {
            await this.onSearchVideo()

            this.onVideoTimeUpdate(videoSearchSeek)
        })
    }

    get isVideoReady() {
        return !!this.state.videoData
    }

    get isVideoError() {
        return !!this.state.videoError && !!this.state.videoURI && !!this.state.videoURI.length
    }

    get isVideoInputVisible () {
        return !!this.state.showInput
    }

    get isVideoInputHidden () {
        return !this.isVideoInputVisible
    }

    get isVideoInputEnabled () {
        return !!this.state.enableInput
    }

    get isVideoInputDisabled () {
        return !this.isVideoInputEnabled
    }

    get isVideoInputReadOnly () {
        // return !!this.state.sampleSet
        return !this.state.custom
    }

    get isVideoPlayerVisible () {
        return !!this.state.showVideo && this.isVideoURIValid
    }

    get isVideoPlayerHidden () {
        return !this.isVideoPlayerVisible
    }

    get videoPlayerEnabled () {
        return true // !!this.state.videoData
    }

    get isVideoPlayerDisabled () {
        return !this.videoPlayerEnabled
    }

    get videoWidth () {
        // return this.lensPlayer && this.lensPlayer.width || 0
        return (this.state.videoPlayerSize || {}).width || 0
    }

    get videoHeight () {
        // return this.lensPlayer && this.lensPlayer.height || 0
        return (this.state.videoPlayerSize || {}).height || 0
    }

    get videoSize () {
        return [this.videoWidth, this.videoHeight]
    }

    get videoAspectRatio () {
        return this.videoWidth / parseFloat(this.videoHeight || 1)
    }

    // get videoAspectRatio () {
    //     return (this.state.videoSize || {}).aspectRatio || 1.0
    // }

    get videoScaleRatio () {
        // return (this.state.videoSnapshotSize || {}).width / ((this.video || {}).width || 1)
        return (this.state.videoSnapshotSize || {}).width / (this.videoWidth || 1)
    }

    get videoSnapshotWidth () {
        return (this.state.videoSnapshotSize || {}).width
    }

    get videoSnapshotHeight () {
        return (this.state.videoSnapshotSize || {}).height
    }

    get videoSnapshotNaturalWidth () {
        return (this.state.videoSnapshotSize || {}).naturalWidth
    }

    get videoSnapshotNaturalHeight () {
        return (this.state.videoSnapshotSize || {}).naturalHeight
    }

    get videoSnapshotAspectRatio () {
        return (this.state.videoSnapshotSize || {}).aspectRatio  || 1.0
    }

    get videoSnapshotScaleRatio () {
        return (this.state.videoSnapshotSize || {}).scaleRatio  || 1.0
    }

    get videoURI () {
        return this.state.videoURI
    }

    get isVideoURIValid () {
        return isUri(this.videoURI)
    }

    get isVideoURIInvalid () {
        return !this.isVideoURIValid
    }

    get isVideoSearchPending () {
        return this.state.videoSearchPending
    }

    get isVideoIndexingComplete () {
        const stats = this.state.videoSearchStats || {}

        return Object.entries(stats).every(([key, value]) => {
            return !!value['endedAt'] && value['completed'] >= value['total']
        })
    }

    get videoSearchStats () {
        return this.state.videoSearchStats || {}
    }

    get videoSearchResponse () {
        return this.state.videoSearchResponse || {}
    }

    get videoSearchResponseMeta () {
        return this.videoSearchResponse.meta || {}
    }

    get videoSearchResponseTime () {
        return this.state.videoSearchResponseTime || undefined
    }

    get videoSearchFrames () {
        return this.state.videoSearchResponse && this.state.videoSearchResponse.data || []
    }

    get videoFrames () {
        return this.state.videoFrames || []
    }

    get videoFrame () {
         return this.state.videoFrameHover || this.state.videoFrame || {}
    }

    get videoSearchResults () {
        return this.videoFrame && this.videoFrame.results || []
    }

    get videoSearchResultsAll () {
         return this.state.videoSearchResultsAll || []
    }

    get topTracks () {
        return this.state.topTracks || []
    }

    get tracks () {
        return this.topTracks || []
    }

    get frames () {
        return this.state.frames || []
    }

    get frame () {
        return this.state.videoFrame
    }

}


/* =========================================
      EXPORTS
-------------------------------------- */

export default LensPlayer
