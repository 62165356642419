
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { PureComponent } from 'react'
// import classnames from 'classnames'

import Video from './DefaultVideo'

import videojs from 'video.js'
import 'videojs-youtube'

import 'video.js/dist/video-js.css'

// REVIEW: not in use??
import '../../../../vendor/detect-element-resize'


/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/DemoPlayer/Video/VJSVideo')


/* =========================================
      COMPONENTS
-------------------------------------- */

// API: http://docs.videojs.com

class VJSVideo extends PureComponent {

    state = {}

    install = () => {
        return new Promise((resolve, reject) => {
            // NOTE: using Webpack/NPM import
            window.videojs = videojs

            resolve()
        })
    }

    uninstall = () => {
        return new Promise((resolve, reject) => {
            try {
                if (this.api) {
                    this.api.dispose()
                }

                try {
                    window.removeResizeListener(this.player, this.onResize)
                } catch (err) {}

                resolve()

            } catch (err) {
                reject(err)
            }
        })
    }

    setup = () => {
        return new Promise((resolve, reject) => {
            if (!this.player) return reject(new Error(`Player element is undefined`))
            if (!this.video) return reject(new Error(`Video element is undefined`))

            const _videojs = videojs(this.video, {
                sources: [
                    {
                        src: this.props.src
                    }
                ],

                width: this.props.width || this.state.width || 'auto',
                height: this.props.height || this.state.height || 'auto',

                autoplay: this.props.autoplay,
                muted: this.props.muted,
                controls: this.props.controls,
                loop: this.props.loop,

            }, () => {
                this.event('onLoad')()

                _videojs.on('error', (err) => {
                    this.event('onError')(err)
                })

                // playback
                _videojs.on('play', () => {
                    this.event('onPlay')()
                })
                _videojs.on('pause', () => {
                    this.event('onPause')()
                })
                _videojs.on('timeupdate', () => {
                    this.currentTime = parseFloat(_videojs.currentTime())

                    this.event('onTimeUpdate')(this.currentTime)
                })
                _videojs.on('loadeddata', () => {
                    this.event('onLoadedData')()
                })
                _videojs.on('ended', () => {
                    this.event('onEnded')()
                })

                // TODO: onProgress support

                // TODO: detect video `onFullscreen` event and status (true/false)
                //      - http://stackoverflow.com/questions/9094913/how-to-figure-out-when-a-html5-video-player-enters-the-full-screen-mode-on-ios

                // TODO: detect video `onResize` event and status (width + height)
                //      - http://stackoverflow.com/questions/6492683/how-to-detect-divs-dimension-changed

                // ------

                // TODO: add lens button
                // this.lensButton = this.player.controlBar.addChild('button', {
                //     id: "lens",
                //     text: "Lens",
                // })

                window.addResizeListener(this.player, this.onResize)

                this.api = _videojs

                resolve(this.api)
            })
        })
    }

    get playing () {
        return this.state.play || this.props.autoplay
    }

    play () {
        if (this.vjsPlayer) {
            this.setState({
                play: true,
                pause: false,
                stop: false,
            }, () => {
                try {
                    this.vjsPlayer.play()
                } catch (err) {}
            })
        }
    }

    pause () {
        if (this.vjsPlayer) {
            this.setState({
                play: false,
                pause: true,
                stop: false,
            }, () => {
                try {
                    this.vjsPlayer.pause()
                } catch (err) {}
            })
        }
    }

    stop () {
        if (this.vjsPlayer) {
            this.setState({
                play: false,
                pause: false,
                stop: true,
            }, () => {
                try {
                    this.pause()
                    this.seek(0)
                } catch (err) {}
            })
        }
    }

    mute () {
        if (this.vjsPlayer) {
            this.setState({
                mute: true,
            }, () => {
                try {
                    // BUG: works in never version
                    // this.vjsPlayer.muted(muted)
                } catch (err) {}
            })
        }
    }

    unmute () {
        if (this.vjsPlayer) {
            this.setState({
                mute: false,
            }, () => {
                try {
                    // BUG: works in never version
                    // this.vjsPlayer.muted(false)
                } catch (err) {}
            })
        }
    }

    seek (seconds = 0) {
        if (this.youtubePlayer) {
            this.setState({
                seek: seconds,
            }, () => {
                this.vjsPlayer.currentTime(parseInt(seconds))
            })
        }
    }

    event = (name) => {
        const props = this.props

        return (...args) => {
            log(`VJSVideo.event`, name, ...args)

            if (props[name]) {
                props[name](...args)
            }
        }
    }

    componentDidMount() {
        VJSVideo.index = VJSVideo.index || 0
        VJSVideo.index++

        this.setState({
            index: VJSVideo.index,
            id: this.props.id || `videojs-video-${VJSVideo.index}`
        })

        this.install()
            .then(() => {
                this.setup()
            })
            .catch((error) => {
                log('install', error)
            })
    }

    componentWillUnmount() {
        this.uninstall()
            .then(() => {
                // noop
            })
            .catch((error) => {
                log('uninstall', error)
            })
    }

    render() {
        return (
            <div data-vjs-player {...{
                ref: (ref) => this.player = ref,
                id: this.state.id,
                className: this.props.className,
                style: this.props.style,
            }}>
                <video ref={(ref) => this.video = ref}
                    className="video-js"
                />
            </div>
        )
    }

    onResize = () => {
        const width = this.video.width
        const height = this.video.height

        this.event('onResize')(this.video.width, this.video.height)
    }
}

VJSVideo.propTypes = {
    ...Video.propTypes
}

VJSVideo.defaultProps = {
    ...Video.defaultProps
}


/* =========================================
      EXPORTS
-------------------------------------- */

export default VJSVideo
