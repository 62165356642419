/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import httpStatus from 'http-status'

import {
    API_ENDPOINT,

    API_VIDEO_SERVICE_DEFAULT_SEEK,

    API_VIDEO_SERVICE_DEFAULT_TRACK_MIN,
    API_VIDEO_SERVICE_DEFAULT_TRACK_MAX,

    API_VIDEO_SERVICE_DEFAULT_FPS,

    API_VIDEO_SERVICE_DEFAULT_LIMIT,
    API_VIDEO_SERVICE_DEFAULT_RELEVANCE,

    API_VIDEO_SERVICE_DEFAULT_FAMILY_SEARCH,
    API_VIDEO_SERVICE_DEFAULT_DEDUPLICATION,
    API_VIDEO_SERVICE_DEFAULT_SPECIFIC_MATCH,
    API_VIDEO_SERVICE_DEFAULT_ATTRIBUTES,
    API_VIDEO_SERVICE_DEFAULT_PUBLISH,

    API_IMAGE_SERVICE_ENDPOINT,

} from '../constants'

/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/services/videoLens')


/* =========================================
      FUNCTIONS
-------------------------------------- */

export async function search(uri, options = {}) {
    options = options || {}

    const hasCatalogs = Boolean(Array.isArray(options.catalogs) || (typeof options.catalogs === 'string'))

    if (!hasCatalogs) {
        options.catalogs = undefined
    }

    if (typeof options.catalogs === 'string') {
        options.catalogs = [options.catalogs]
    }

    if (typeof options.page !== 'number') {
        options.page = 0
    }

    if (typeof options.pageLimit !== 'number') {
        options.pageLimit = 100
    }

    options.endpoint = options.endpoint || API_ENDPOINT

    // if (!Array.isArray(options.gender) || typeof options.gender !== 'string') {
    //     options.gender = ['women']
    // }

    // if (typeof options.gender === 'string') {
    //     options.gender = [options.gender]
    // }


    const videoLensEndpoint = `https://${options.endpoint}/video/search/?page=${options.page}&pageLimit=${options.pageLimit}`

    if (typeof options.seek !== 'number') {
        options.seek = API_VIDEO_SERVICE_DEFAULT_SEEK
    }

    if (!Array.isArray(options.track)) {
        options.track = [options.track, options.track]
    }

    options.track = [
        (typeof options.track[0] === 'number') ? options.track[0] : API_VIDEO_SERVICE_DEFAULT_TRACK_MIN,
        (typeof options.track[1] === 'number') ? options.track[1] : API_VIDEO_SERVICE_DEFAULT_TRACK_MAX,
    ]

    if (typeof options.precision !== 'number') {
        options.precision = undefined
    }

    if (typeof options.framesPerSecond !== 'number') {
        options.framesPerSecond = API_VIDEO_SERVICE_DEFAULT_FPS
    }

    if (typeof options.limit !== 'number') {
        options.limit = API_VIDEO_SERVICE_DEFAULT_LIMIT
    }

    if (typeof options.relevance !== 'number') {
        options.relevance = API_VIDEO_SERVICE_DEFAULT_RELEVANCE
    }

    if (typeof options.family_search !== 'boolean') {
        options.family_search = API_VIDEO_SERVICE_DEFAULT_FAMILY_SEARCH
    }

    if (typeof options.deduplication !== 'boolean') {
        options.deduplication = API_VIDEO_SERVICE_DEFAULT_DEDUPLICATION
    }

    if (typeof options.specific_match !== 'boolean') {
        options.specific_match = API_VIDEO_SERVICE_DEFAULT_SPECIFIC_MATCH
    }

    if (typeof options.attributes !== 'boolean') {
        options.attributes = API_VIDEO_SERVICE_DEFAULT_ATTRIBUTES
    }

    if (!options.publish) {
        options.publish = {}
        options.publish.enabled = API_VIDEO_SERVICE_DEFAULT_PUBLISH
    }

    // REFACTOR: create internal `Request` object, for debugging reasons
    const videoLens = {
        request: {
            url: videoLensEndpoint, // REFACTOR: move to config
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': (options.accessToken ? `Bearer ${options.accessToken}` : undefined),
            },
            body: {
                data: {
                    uri,
                    // force: options.force, 
                    options: {
                        seek: options.seek,
                        track: options.track,
                        precision: options.precision,
                        framesPerSecond: options.framesPerSecond,

                        limit: options.limit,
                        relevance: options.relevance,
                        family_search: options.family_search,
                        deduplication: options.deduplication,
                        specific_match: options.specific_match,

                        catalogs: options.catalogs,
                        // gender: options.gender,
                        // ageGroup: options.ageGroup,

                        force: options.reindex || options.force,
                        // refresh: options.refresh,
                        attributes: options.attributes,

                        publish: options.publish
                    },
                },
            },
        },
        response: undefined,
    }

    log('REQUEST', { videoLens })

    try {
        const fetchResponse = await fetch(videoLens.request.url, {
            method: videoLens.request.method,
            headers: videoLens.request.headers,
            body: JSON.stringify(videoLens.request.body),
            mode: 'cors',
            cache: !!options.cache ? 'no-cache' : 'reload',
            redirect: 'follow',
            referrer: 'no-referrer',
            credentials: 'same-origin',
        })

        // log(fetchResponse)

        const request = {
            ...videoLens.request,
        }
        const response = {}

        const responseStatus = fetchResponse.status
        const responseHeaders = (fetchResponse.headers || {})._headers || fetchResponse.headers

        let responseBody

        try {
            responseBody = await fetchResponse.json()

            if (responseStatus >= 400) {
                const errorCode = responseStatus
                const errorMessage = `${errorCode} ${httpStatus[errorCode]}`

                const apiError = new Error(errorMessage)

                apiError.code = errorCode

                throw apiError

            }
        } catch (error) {
            throw error
        }

        response.status = responseStatus
        response.headers = responseHeaders
        response.body = responseBody

        const result = {
            request,
            response
        }

        log('REQUEST + RESPONSE', result)

        return result

    } catch (error) {
        throw error
    }
}

export async function getCatalogs(options = {}) {
    let endpoint = options.endpoint || API_IMAGE_SERVICE_ENDPOINT
    let scope = options.scope || ''
    let limit = options.pageLimit || 50
    let offset = options.pageOffset || 0

    const url = `//${endpoint}/catalogs/${scope}?limit=${limit}&skip=${offset}`

    const call = {
        request: {
            url, // REFACTOR: move to config
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': (options.accessToken ? `Bearer ${options.accessToken}` : undefined),
            },
        },
        response: undefined,
    }

    try {
        const fetchResponse = await fetch(call.request.url, {
            method: call.request.method,
            headers: call.request.headers,
            body: JSON.stringify(call.request.body),
            mode: 'cors',
            cache: !!call.cache ? 'no-cache' : 'reload',
            redirect: 'follow',
            referrer: 'no-referrer',
            credentials: 'same-origin',
        })

        // log(fetchResponse)

        const request = {
            ...call.request,
        }

        const response = {}
        const responseStatus = fetchResponse.status
        const responseHeaders = (fetchResponse.headers || {})._headers || fetchResponse.headers

        let responseBody

        try {

            responseBody = await fetchResponse.json()

            if (responseStatus >= 400) {
                const errorCode = responseStatus
                const errorMessage = `${errorCode} ${httpStatus[errorCode]}`

                const apiError = new Error(errorMessage)

                apiError.code = errorCode

                throw apiError
            }

        } catch (error) {
            throw error
        }

        response.status = responseStatus
        response.headers = responseHeaders
        response.body = responseBody

        const result = {
            request,
            response
        }

        // log('REQUEST + RESPONSE', result)
        return result

    } catch (error) {
        throw error
    }

}

export async function getDefaultCatalogs(options = {}) {
    return getCatalogs({...options, scope: null })
}

export async function getPublicCatalogs(options = {}) {
    return getCatalogs({...options, scope: 'public' })
}


/* =========================================
      EXPORT
-------------------------------------- */

export default {
    search,

    getCatalogs,
    getDefaultCatalogs,
    getPublicCatalogs,
}