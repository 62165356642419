
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'
import classnames from 'classnames'


/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/DemoPlayer/DemoAlertOverlay')


/* =========================================
      COMPONENTS
-------------------------------------- */

const DemoAlertOverlay = (props) => {
    const type = props.type
    const title = props.title || props.type
    const message = props.message

    const id = props.id
    const className = props.className
    const hidden = props.hidden

    return (
        <div id={id}
            className={classnames('DemoAlertOverlay', type, className, {hidden})}

            data-type={type}
        >
            <div className={classnames('DemoAlertOverlay-header')}>
                <div className={classnames('DemoAlertOverlay-logo')}>
                    Markable.ai
                </div>

                <div className={classnames('DemoAlertOverlay-title')}>
                    <ReactMarkdown source={title} />
                </div>
            </div>

            <div className={classnames('DemoAlertOverlay-content')}>
                <div className={classnames('DemoAlertOverlay-message')}>
                    {
                        (Array.isArray(message) ? message : [message]).map((source) => {
                            return (
                                <ReactMarkdown key={source} source={source} />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}


DemoAlertOverlay.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,

    id: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
}

DemoAlertOverlay.defaultProps = {
    type: 'info',
    title: undefined,
    message: 'Lorem ipsum...',

    id: undefined,
    className: undefined,
    disabled: false,
}


/* =========================================
      EXPORT
-------------------------------------- */

export default DemoAlertOverlay
