
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import shallowEqual from 'react-fast-compare'


/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/DemoFrameSnapshotsPreviewer')



/* =========================================
      COMPONENTS
-------------------------------------- */

class DemoFrameSnapshotsPreviewer extends Component {

    state = {
        scaleRatio: 0,
    }

    shouldComponentUpdate (nextProps, nextState) {
        const { props, state } = this

        if (shallowEqual(props, nextProps) && shallowEqual(state, nextState)) {
            return false
        }

        return true
    }

    render () {
        const { props } = this

        const offset = props.offset || 0
        const limit = props.limit || 10
        const frames = (props.frames || []).slice(offset, limit)

        const currentFrame = props.currentFrame || undefined
        const currentTrack = props.currentTrack || undefined

        const hidden = !!props.hidden
        const style = props.style
        const className = props.className

        const scaleRatio = (window.innerHeight * 0.4) / (((frames[0] || {}).image || {}).height || 1)

        return (
            <div className={classnames(`DemoFrameSnapshotsPreviewer`, className, {hidden})}
                style={style}
                data-offset={offset}
            >

                <div className={classnames('DemoFrameSnapshotsPreviewer-header')}>
                    <div className={classnames('DemoFrameSnapshotsPreviewer-header-title')}>

                    </div>
                </div>

                <div className={classnames('DemoFrameSnapshotsPreviewer-content')}>
                    <div className={classnames('DemoFrameSnapshotsPreviewer-snapshots')}
                        data-scale-ratio={this.state.scaleRatio}
                    >
                        {
                            frames.map((frame) => {
                                const key = `DemoFrameSnapshotsPreviewer-snapshot-${frame._id}`
                                const color = currentTrack && currentTrack.color

                                const frameLabel = `Frame ${frame.index} (${frame.timestamp} ms)`
                                const frameImageURI = ((frame.image || {}).uri || '#').replace(window.API_ENDPOINT, window.videoEndpoint) // HACK >:(

                                const isCurrent = ((currentFrame || {})._id === frame._id)

                                return (
                                    <div key={key} id={key}
                                        className={classnames('DemoFrameSnapshotsPreviewer-snapshot', {
                                            current: isCurrent,
                                        })}

                                        data-scale-ratio={scaleRatio}

                                        data-frame-width={frame.image.width}
                                        data-frame-height={frame.image.height}

                                        data-frame-preview-width={frame.image.width * scaleRatio}
                                        data-frame-preview-height={frame.image.height * scaleRatio}

                                        ref={DemoFrameSnapshotsPreviewer.onRef}
                                    >
                                        <code className={classnames('DemoFrameSnapshotsPreviewer-snapshot-label', 'DemoFrameSnapshotsPreviewer-snapshot-id')}
                                            style={{
                                                backgroundColor: color,
                                            }}
                                            title={`Frame #`}
                                        >
                                            { `#${frame.index}` }
                                        </code>

                                        <code className={classnames('DemoFrameSnapshotsPreviewer-snapshot-label', 'DemoFrameSnapshotsPreviewer-snapshot-timestamp')}
                                            title={`Frame #`}
                                        >
                                            { `${frame.timestamp / 1000}`.toHHMMSS() }
                                        </code>

                                        <img className={classnames('DemoFrameSnapshotsPreviewer-snapshot-image')}
                                            src={frameImageURI}
                                            alt={frameLabel}

                                            width={`${frame.image.width * scaleRatio}px`}
                                            height={`${frame.image.height * scaleRatio}px`}
                                        />

                                        <div className={classnames('DemoFrameSnapshotsPreviewer-snapshot-bboxes')}>
                                            {
                                                frame.results
                                                    .filter((result) => {
                                                        const isCurrentTrackDetection = ((currentTrack || {}).id === ((result.matches || [])[0] || {})._id)

                                                        return isCurrentTrackDetection
                                                    })
                                                    .map((result) => {
                                                        const key = `DemoFrameSnapshotsPreviewer-snapshot-bbox-${result._id}`

                                                        return (
                                                            <div key={key} id={key}
                                                                className={classnames('DemoFrameSnapshotsPreviewer-snapshot-bbox')}
                                                                style={{
                                                                    left: `${result.bounding_box.x * scaleRatio}px`,
                                                                    top: `${result.bounding_box.y * scaleRatio}px`,
                                                                    width: `${result.bounding_box.width * scaleRatio}px`,
                                                                    height: `${result.bounding_box.height * scaleRatio}px`,
                                                                    borderColor: color,
                                                                }}
                                                            >
                                                                &nbsp;
                                                            </div>
                                                        )
                                                    })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>

            </div>
        )
    }
}

DemoFrameSnapshotsPreviewer.onRef = (ref) => {
    // if (!this.snapshotElement) {
    //     this.snapshotElement = ref

    //     setTimeout(() => {
    //         const style = getComputedStyle(this.snapshotElement)

    //         this.setState({
    //             scaleRatio: (
    //                 parseInt(style.height || 0) / parseFloat(frame.image.height || 1)
    //             )
    //         })
    //     })
    // }
}

DemoFrameSnapshotsPreviewer.propTypes = {
    frames: PropTypes.array,
    tracks: PropTypes.array,

    limit: PropTypes.number,

    currentTrack: PropTypes.object,
    currentFrame: PropTypes.object,

    hidden: PropTypes.bool,
}


DemoFrameSnapshotsPreviewer.defaultProps = {
    frames: [],
    tracks: [],

    limit: 10,

    currentTrack: undefined,
    currentFrame: undefined,

    hidden: false,
}


/* =========================================
      EXPORT
-------------------------------------- */

export default DemoFrameSnapshotsPreviewer
