

/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import shallowEqual from 'react-fast-compare'

import {
    API_ENDPOINT,
} from '../../constants'

import BackendStatus from './DemoStatus/BackendStatus'
import InternetStatus from './DemoStatus/InternetStatus'
import SSLStatus from './DemoStatus/SSLStatus'


/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/DemoStatus')


/* =========================================
      COMPONENTS
-------------------------------------- */

class DemoStatus extends Component {

    shouldComponentUpdate (nextProps, nextState) {
        const { props, state } = this

        if (shallowEqual(props, nextProps) && shallowEqual(state, nextState)) {
            return false
        }

        return true
    }


    render () {
        const { props } = this

        const id = props.id
        const className = props.className
        const style = props.style
        const disabled = props.disabled
        const hidden = props.hidden
        const endpoint = props.endpoint
        const accessToken = props.accessToken

        return (
            <div className={classnames('DemoStatus', className, {hidden, disabled})}
                id={id}
                style={style}
            >
                <InternetStatus
                    interval={3000}

                    hidden={false}
                    disabled={false}

                    onStatusChange={(status) => {
                        log('InternetStatus', 'onStatusChange', status)

                        // TODO: show warning alert `if (!status)``
                    }}
                    onStatusInfoChange={(info) => {
                        log('InternetStatus', 'onStatusInfoChange', info)

                        // TODO: show warning alert `if (info.throughput < 750)``
                    }}
                />

                <BackendStatus
                    uri={`//${endpoint || API_ENDPOINT}`}
                    accessToken={accessToken}

                    interval={3000}

                    hidden={false}
                    disabled={false}

                    onStatusChange={(status) => {
                        log('BackendStatus', 'onStatusChange', status)

                        // TODO: show warning alert `if (!status)``
                    }}
                />

                <SSLStatus
                    hidden={false}
                    disabled={false}

                    onStatusChange={(status) => {
                        log('SSLStatus', 'onStatusChange', status)
                    }}
                />
            </div>
        )
    }

}

DemoStatus.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
}

DemoStatus.defaultProps = {
    id: undefined,
    className: undefined,
    style: undefined,
    disabled: undefined,
    hidden: undefined,
}


/* =========================================
      EXPORT
-------------------------------------- */

export default DemoStatus
