
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import shallowEqual from 'react-fast-compare'

import {
    ZINDEX_MAX,
} from '../../../constants'


/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/DemoPlayer/LensVideoOverlayBBoxes')


/* =========================================
      COMPONENTS
-------------------------------------- */

class LensVideoOverlayBBoxes extends Component {

    shouldComponentUpdate (nextProps) {
        const { props } = this

        if (shallowEqual(props, nextProps)) {
            return false
        }

        return true
    }

    render () {
        const { props } = this

        const colors = props.colors || []

        const tracks = props.tracks || []
        const results = props.results || []

        const resultSelection = props.resultSelection || {}

        return (
            <div className={classnames('LensVideoOverlayBBoxes')}
                onMouseOver={(event) => {
                    if (typeof props.onMouseOver !== 'function') {
                        return
                    }

                    props.onMouseOver(event)
                }}
                onMouseOut={(event) => {
                    if (typeof props.onMouseOut !== 'function') {
                        return
                    }

                    props.onMouseOut(event)
                }}
            >
                {
                    results.map((result, index) => {
                        const category = (typeof result.category === 'object') ? result.category._id : result.category
                        const bbox = result.bounding_box || {}
                        const scaleRatio = props.scaleRatio || 1
                        const attributes = result.attributes || []
                        const zIndex = (ZINDEX_MAX - 1000 - (bbox.width * bbox.height))

                        const scaleTranslationRatio = 1 / scaleRatio

                        const position = {
                            x: bbox.x * scaleTranslationRatio,
                            y: bbox.y * scaleTranslationRatio,
                            width: bbox.width * scaleTranslationRatio,
                            height: bbox.height * scaleTranslationRatio,
                        }

                        // REFACTOR: not needed later when (top-)track IDs are generated server-side
                        const resultTrack = tracks.find((track) => {
                            return track.id === (result.matches[0] || {})._id
                        }) || {}

                        const key = `LensVideoOverlayBBox-${result._id}-scrollfixed-${!!props.scrollFixed}-${index}`
                        const color = resultTrack.color || colors[index]

                        return (
                            <div key={key} id={key}
                                className={
                                    classnames('LensVideoOverlayBBox', {
                                        active: (typeof resultSelection.ids === 'undefined' || resultSelection.ids === null || resultSelection.ids.includes(index)),
                                        inactive: Array.isArray(resultSelection.ids) && !resultSelection.ids.includes(index),
                                        boundingbox: resultSelection.source === 'boundingbox',
                                        result: resultSelection.source === 'result',
                                    })
                                }
                                style={{
                                    left: `${position.x}px`,
                                    top: `${position.y}px`,
                                    width: `${position.width}px`,
                                    height: `${position.height}px`,
                                    borderColor: color,
                                    zIndex: zIndex,
                                }}

                                data-scale-ratio={scaleRatio}

                                data-bbox={[
                                    bbox.x,
                                    bbox.y,
                                    bbox.width,
                                    bbox.height
                                ]}
                                data-bbox-scaled={[
                                    position.x,
                                    position.y,
                                    position.width,
                                    position.height,
                                ]}

                                onMouseOver={(event) => {
                                    if (typeof props.onResultActive !== 'function') {
                                        return
                                    }

                                    const id = index

                                    props.onResultActive({
                                        ...result,
                                        id,
                                    })
                                }}
                                onMouseOut={(event) => {
                                    if (typeof props.onResultInactive !== 'function') {
                                        return
                                    }

                                    const id = index

                                    props.onResultInactive({
                                        ...result,
                                        id,
                                    })
                                }}
                                onClick={(event) => {
                                    if (typeof props.onResultSelect !== 'function') {
                                        return
                                    }

                                    const id = index

                                    props.onResultSelect({
                                        ...result,
                                        id,
                                    })
                                }}
                            >
                                <code className="LensVideoOverlayBBox-label LensVideoOverlayBBox-id"
                                    style={{
                                        backgroundColor: color,
                                    }}
                                >
                                    { index + 1 }
                                </code>

                                <code className="LensVideoOverlayBBox-label LensVideoOverlayBBox-score"
                                    style={{
                                        backgroundColor: color,
                                    }}
                                >
                                    { parseFloat(result.score).toFixed(2) }
                                </code>

                                <code className="LensVideoOverlayBBox-label LensVideoOverlayBBox-class"
                                    style={{
                                        backgroundColor: color,
                                    }}
                                >
                                    { category.replace(/[\W_]/gi, ' / ') }
                                </code>

                            </div>
                        )
                    })
                }
            </div>
        )
    }

}

LensVideoOverlayBBoxes.propTypes = {
    colors: PropTypes.array,

    tracks: PropTypes.array,
    results: PropTypes.array,

    resultSelection: PropTypes.array,

    scaleRatio: PropTypes.number,
    scrollFixed: PropTypes.bool,

    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,

    onResultActive: PropTypes.func,
    onResultInactive: PropTypes.func,
}

LensVideoOverlayBBoxes.defaultProps = {
    colors: [],

    tracks: [],
    results: [],

    scaleRatio: undefined,
    scrollFixed: undefined,

    // onMouseOver: (event) => log(`onMouseOver`),
    // onMouseOut: (event) => log(`onMouseOut`),

    // onResultActive: (event) => log(`onResultActive`),
    // onResultInactive: (event) => log(`onResultInactive`),
}


/* =========================================
      EXPORT
-------------------------------------- */

export default LensVideoOverlayBBoxes
