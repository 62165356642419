
/* =========================================
      IMPORTS
-------------------------------------- */

// ...


/* =========================================
      CONSTANTS
-------------------------------------- */

const DEFAULT_BANDWIDTH_TEST_ASSET_URL = '//ashanbh.github.io/images/coffee-apple-iphone-laptop.jpg'

export const BANDWIDTH_CLASSES = [
    {
        name: 'OFFLINE',
        latency: Number.POSITIVE_INFINITY,
        throughput: 0,
    },
    {
        name: 'DIAL_UP',
        latency: 2000,
        throughput: 2.4,
    },
    {
        name: 'GPRS',
        latency: 500,
        throughput: 50,
    },
    {
        name: '2G',
        latency: 300,
        throughput: 250,
    },
    {
        name: '2G_EDGE',
        latency: 300,
        throughput: 450,
    },
    {
        name: '3G',
        latency: 200,
        throughput: 750,
    },
    {
        name: '3G_HSPA',
        latency: 200,
        throughput: 1000,
    },
    {
        name: '4G',
        latency: 100,
        throughput: 4000,
    },
    {
        name: 'WIFI',
        latency: 100,
        throughput: 10000,
    },
]

/* =========================================

-------------------------------------- */



/* =========================================
      FUNCTIONS
-------------------------------------- */

export const detectBandwidth = async (uri) => {
    uri = uri || DEFAULT_BANDWIDTH_TEST_ASSET_URL

    uri = uri + (/\?/.test(uri) ? '&' : '?') + `_=${Date.now()}`

    const bandwidthDetection = {}

    bandwidthDetection.start = Date.now()

    const response = await fetch(uri, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
    })

    const bodyReader = response.body.getReader()

    let size = 0

    const read = async () => {
        if (size === 0) {
            bandwidthDetection.firstByte = bandwidthDetection.firstByte || Date.now()
        }

        const result = await bodyReader.read()

        size += result.length

        if (!result.done) {
            read()
        }
    }

    read()

    bandwidthDetection.end = Date.now()

    bandwidthDetection.metrics = {}
    bandwidthDetection.metrics.url = uri
    bandwidthDetection.metrics.metricsSizeKB = size / 1024
    bandwidthDetection.metrics.latency = (bandwidthDetection.firstByte - bandwidthDetection.start)
    bandwidthDetection.metrics.throughput = Math.round(size / ((bandwidthDetection.end - bandwidthDetection.firstByte) || 1) * 100) / 100 // in KBPS

    BANDWIDTH_CLASSES.forEach((bandwidthClass) => {
        if (bandwidthDetection.metrics.throughput > bandwidthClass.throughput){
            bandwidthDetection.metrics.throughputClass = bandwidthClass
        }

        if (bandwidthDetection.metrics.latency < bandwidthClass.latency){
            bandwidthDetection.metrics.latencyClass = bandwidthClass
        }
    })

    return bandwidthDetection.metrics
}


/* =========================================
      EXPORT
-------------------------------------- */

export default {
    detectBandwidth,
}
