
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import md5 from 'md5'

import {
    isUri
} from '../../../utils/uri'

import DefaultVideo from './Video/DefaultVideo'
import VJSVideo from './Video/VJSVideo'
import JWPVideo from './Video/JWPVideo'
import YouTubeVideo from './Video/YouTubeVideo'
import VimeoVideo from './Video/VimeoVideo'


/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/DemoPlayer/LensVideo')


/* =========================================
      COMPONENTS
-------------------------------------- */

class LensVideo extends PureComponent {

    state = {
        type: 'raw',

        playerHover: false,

        timeUpdateInterval: 100,
    }

    video = undefined

    static getVideoInfo = (props = {}) => {
        let {
            player,
            src,

            width,
            height,

            autoplay,
            muted,
            controls,
            loop,
        } = props

        autoplay = !!autoplay
        muted = !!muted
        controls = !!controls

        const uri = src

        if (!isUri(uri)) {
            return undefined
        }

        let playersSupported = []

        let videoInfo

        switch (true) {
            case /youtube\.com|youtu\.be/i.test(uri):
                playersSupported = [
                    'youtube',
                    'jwplayer'
                ] // TODO: videojs
                player = player || playersSupported[0]

                videoInfo = {
                    uri,
                    player, // TODO: only accept supported players
                    playersSupported,
                    element: 'iframe',
                    source: 'youtube',
                    id: uri.match(/\/watch\?v=([_\d\w\-]+)/mi)[1],
                    label: {
                        youtube: 'IFrame + Youtube',
                        jwplayer: 'JWPlayer + Youtube',
                    }[player],

                    src,

                    width,
                    height,

                    autoplay,
                    muted,
                    controls,
                    loop,
                }

                break

            case /vimeo\.com/i.test(uri):
                playersSupported = [
                    'vimeo',
                    'jwplayer'
                ] // TODO: videojs
                player = player || playersSupported[0]

                videoInfo = {
                    uri,
                    player, // TODO: only accept supported players
                    playersSupported, // TODO: videojs
                    element: 'iframe',
                    source: 'vimeo',
                    id: uri.match(/\/([_\d\w\-]+)/mi)[1],
                    label: {
                        vimeo: 'IFrame + Vimeo',
                        jwplayer: 'JWPlayer + Vimeo',
                    }[player],

                    src,

                    width,
                    height,

                    autoplay,
                    muted,
                    controls,
                }

                break

            default: // html5
                playersSupported = [
                    'html5',
                    'videojs',
                    'jwplayer'
                ]
                player = player || playersSupported[0]

                videoInfo = {
                    uri,
                    player, // TODO: only accept supported players
                    playersSupported,
                    element: 'video',
                    source: undefined,
                    id: `url-${md5(uri)}`,
                    label: {
                        html5: 'HTML5',
                        videojs: 'HTML5 + VideoJS',
                        jwplayer: 'HTML5 + JWPlayer',
                    }[player],

                    src,

                    width,
                    height,

                    autoplay,
                    muted,
                    controls,
                }

                break
        }

        return videoInfo
    }

    getVideoComponent = (videoInfo = {}) => {
        const ref = (ref) => {
            if (ref) {
                this.player = ref
                this.video = ref && ref.video
            }
        }

        const src = videoInfo.src

        const autoplay = videoInfo.autoplay
        const muted = videoInfo.muted
        const controls = videoInfo.controls
        const loop = videoInfo.loop

        // FIXME: `videoStyle` undefined
        const width = this.props.width || videoInfo.width || (this.videoStyle || {}).width
        const height = this.props.height || videoInfo.height || (this.videoStyle || {}).height

        const onError = this.onError
        const onLoad = this.onLoad
        const onLoadedData = this.onLoadedData
        const onLoadedMetaData = this.onLoadedMetaData
        const onPlay = this.onPlay
        const onPause = this.onPause
        const onProgress = this.onProgress
        const onTimeUpdate = this.onTimeUpdate
        const onEnded = this.onEnded
        const onFullscreen = this.onFullscreen
        const onResize = this.onResize

        // TODO: add support in all players
        const onSeeking = this.onSeeking
        const onSeeked = this.onSeeked

        const videoAttributes = {
            ref,

            src,

            autoplay,
            muted,
            controls,
            loop,

            width,
            height,

            onError,
            onLoad,
            onLoadedData,
            onLoadedMetaData,
            onPlay,
            onPause,
            onProgress,
            onTimeUpdate,
            onEnded,
            onFullscreen,
            onResize,

            // TODO: add support in all players
            onSeeking,
            onSeeked,
        }

        // REVIEW
        // videoAttributes.width = '100%'
        // videoAttributes.height = '100%'

        // REVIEW: is height `100%` cover all cases?
        videoAttributes.style = {
            // width: '100%',
            height: '100%',
        }

        switch (videoInfo.source) {
            case 'youtube':
                switch (videoInfo.player) {
                    // TODO: case 'jwplayer': break
                    // here
                    default:
                        return (
                            <div>
                                <YouTubeVideo {...videoAttributes} />
                            </div>
                        )
                }

            case 'vimeo':
                switch (videoInfo.player) {
                    // TODO: case 'jwplayer': break
                    default:
                        return (
                            <VimeoVideo {...videoAttributes} />
                        )
                }

            default:
                switch (videoInfo.player) {

                    case 'videojs':
                        return (
                            <VJSVideo {...videoAttributes} />
                        )

                    case 'jwplayer':
                        return (
                            <JWPVideo {...videoAttributes} />
                        )

                    default:
                        return (
                            <div>
                                <DefaultVideo {...videoAttributes} />
                            </div>
                        )
                }
        }
    }

    play () {
        if (this.player) {
            this.player.play()
        }
    }

    pause () {
        if (this.player) {
            this.player.pause()
        }
    }

    stop () {
        if (this.player) {
            this.player.stop()
        }
    }

    mute (value = true) {
        if (this.player) {
            this.player.mute(value)
        }
    }

    loop (value = true) {
        if (this.player) {
            this.player.loop(value)
        }
    }

    seek (value = 0) {
        if (this.player) {
            this.player.seek(value)
        }
    }

    get element () {
        return this.player && this.player.element
    }

    get uri () {
        return this.src
    }

    get src () {
        return this.props.src
    }

    get duration () {
        return this.player && this.player.duration || 0
    }

    get seeking () {
        return !!(this.player && this.player.seeking)
    }

    set muted (value) {
        return this.player && this.player.mute(value)
    }

    get muted () {
        return !!(this.player && this.player.muted)
    }

    set playing (value) {
        return this.player && this.player.play(value)
    }

    get playing () {
        return !!(this.player && this.player.playing)
    }

    set paused (value) {
        return this.player && this.player.pause(value)
    }

    get paused () {
        return !!(this.player && this.player.paused)
    }

    set stopped (value) {
        return this.player && this.player.stop(value)
    }

    get stopped () {
        return !!(this.player && this.player.stopped)
    }

    set currentTime (value) {
        return this.player && this.player.seek(value)
    }

    get currentTime () {
        return (this.player && this.player.currentTime) || 0
    }

    get currentTimeString () {
        return `${this.currentTime && Math.round(this.currentTime * 100) / 100 || 0}`.toHHMMSS()
    }

    get width () {
        return (this.player && this.player.width) || undefined
    }

    get height () {
        return (this.player && this.player.height) || undefined
    }

    get size () {
        return [this.width, this.height]
    }

    get naturalWidth () {
        return (this.player && this.player.naturalWidth) || undefined
    }

    get naturalHeight () {
        return (this.player && this.player.naturalHeight) || undefined
    }

    get naturalSize () {
        return [this.naturalWidth, this.naturalHeight]
    }

    get aspectRatio () {
        return (this.player && this.player.aspectRatio) || 1.0
    }

    get naturalAspectRatio () {
        return (this.player && this.player.naturalAspectRatio) || 1.0
    }

    get scaleRatio () {
        return (this.player && this.player.scaleRatio) || 1.0
    }

    get scaleRatioX () {
        return (this.player && this.player.scaleRatioX) || 1.0
    }

    get scaleRatioY () {
        return (this.player && this.player.scaleRatioY) || 1.0
    }

    componentDidMount () {
        const videoStyle = this.video && getComputedStyle(this.video)
        const videoInfo = LensVideo.getVideoInfo(this.props)

        this.setState({
            videoStyle,
            videoInfo,
        })

        this.intervalTimeUpdate = setInterval(() => {
            try {
                const videoInfo = LensVideo.getVideoInfo(this.props)

                this.setState({
                    videoInfo,
                })

            } catch (err) {}

        }, this.state.timeUpdateInterval)

        setTimeout(() => {
            this.onTimeUpdate(0)
        })
    }

    componentWillUnmount() {
        clearInterval(this.intervalTimeUpdate)
    }

    render() {
        // const videoStyle = this.state.videoStyle || {}
        const videoInfo = this.state.videoInfo || {}
        const VideoComponent = this.getVideoComponent(videoInfo)

        if (this.props.hidden) {
            return (
                <div id={this.state.id} className={classnames(this.props.className, {hidden: true})} />
            )
        }

        const playerWidth = parseInt(this.props.width) || parseInt(this.state.width) || 'auto'
        const playerHeight = parseInt(this.props.height) || parseInt(this.state.height) || 'auto'

        const videoWidth = parseInt(videoInfo.width)
        const videoHeight = parseInt(videoInfo.height)

        // const scaleX = playerWidth / videoWidth
        // const scaleY = playerHeight / videoHeight

        const label = videoInfo.label || 'Untitled'
        // const timeString = `${this.state.currentTime && Math.round(this.state.currentTime * 100) / 100 || 0}`.toHHMMSS()

        // log({playerWidth, playerHeight, videoWidth, videoHeight})
        // log({'1': parseInt(videoStyle.width), '2': playerWidth})
        // log({videoInfo})

        return (
            <div className={classnames('LensVideo', this.props.className, {disabled: !!this.props.disabled})}
                ref={(el) => {
                    // this.video = el
                }}

                data-current-time={this.state.currentTime}

                data-width={playerWidth}
                data-height={playerHeight}

                onMouseOver={() => {
                    this.setState({
                        playerHover: true,
                    })
                }}
                onMouseOut={() => {
                    this.setState({
                        playerHover: false,
                    })
                }}
            >
                <div className="LensVideo-meta">
                    <div className="LensVideo-meta-label">
                        <strong>{ label }</strong> Video
                    </div>

                    <div className="LensVideo-meta-time">
                        { this.currentTimeString }
                    </div>
                </div>

                <div className="LensVideo-content">
                    {
                        isUri(this.props.src) && VideoComponent || ''
                    }

                    {
                        this.props.children
                    }
                </div>

                <div className={classnames('LensVideo-footer', {hidden: false})}>
                    <div className={classnames('LensVideo-footer-metrics', {hidden: false})}>
                        <strong>size</strong> { this.naturalWidth } &times; { this.naturalHeight }

                        &nbsp;

                        |

                        &nbsp;

                        <strong>scale</strong> { parseFloat(this.scaleRatio).toFixed(2) }x
                    </div>
                </div>
            </div>
        )
    }

    onError = (error) => {
        log('onError', error)

        // error.target = this

        // error = new Error(error)
        // error.target = this

        // TODO

        error = error || new Error('Something went wrong')

        if (this.props.onError) {
            this.props.onError(error)
        }
    }

    onLoad = () => {
        log('onLoad')

        // TODO

        if (this.props.onLoad) {
            this.props.onLoad()
        }
    }

    onLoadedData = () => {
        log('onLoadedData')

        // TODO

        if (this.props.onLoadedData) {
            this.props.onLoadedData()
        }
    }

    // onLoadedMeta = () => {
    //     log('onLoadedMeta')

    //     // TODO

    //     if (this.props.onLoadedMeta) {
    //         this.props.onLoadedMeta()
    //     }
    // }

    onPlay = () => {
        log('onPlay')

        if (this.props.onPlay) {
            this.props.onPlay()
        }
    }

    onPause = () => {
        log('onPause')

        if (this.props.onPause) {
            this.props.onPause()
        }
    }

    onProgress = () => {
        log('onProgress')

        if (this.props.onProgress) {
            this.props.onProgress()
        }
    }

    onTimeUpdate = (currentTime) => {
        log('onTimeUpdate', currentTime)

        this.setState({
            currentTime,
        })

        if (this.props.onTimeUpdate) {
            this.props.onTimeUpdate(currentTime)
        }
    }

    onEnded = () => {
        log('onEnded')

        if (this.props.onEnded) {
            this.props.onEnded()
        }
    }

    onFullscreen = () => {
        log('onFullscreen')

        if (this.props.onFullscreen) {
            this.props.onFullscreen()
        }
    }

    onResize = () => {
        log('onResize')

        if (this.props.onResize) {
            this.props.onResize()
        }
    }

    onSeeking = () => {
        log('onSeeking')

        if (this.props.onSeeking) {
            this.props.onSeeking()
        }
    }

    onSeeked = () => {
        log('onSeeked')

        if (this.props.onSeeked) {
            this.props.onSeeked()
        }
    }

}

LensVideo.propTypes = {
    src: PropTypes.string,

    width: PropTypes.any,
    height: PropTypes.any,
    autoplay: PropTypes.bool,
    controls: PropTypes.bool,
    muted: PropTypes.bool,
    loop: PropTypes.bool,

    player: PropTypes.string,

    hidden: PropTypes.bool,
    disabled: PropTypes.bool,

    onError: PropTypes.func,
    onLoad: PropTypes.func,
    onLoadedData: PropTypes.func,
    onLoadedMetaData: PropTypes.func,

    onPlay: PropTypes.func,
    onPause: PropTypes.func,
    onProgress: PropTypes.func,
    onTimeUpdate: PropTypes.func,
    onEnded: PropTypes.func,
    onFullscreen: PropTypes.func,
    onResize: PropTypes.func,
}

LensVideo.defaultProps = {
    src: undefined,

    width: undefined,
    height: undefined,
    autoplay: false,
    controls: true,
    muted: true,
    loop: false,

    player: undefined,

    hidden: undefined,
    disabled: undefined,

    onError: () => undefined, // log('onError'),
    onLoad: () => undefined, // log('onLoadedData'),
    onLoadedData: () => undefined, // log('onLoadedData'),
    onLoadedMetaData: () => undefined, // log('onLoadedMetaData'),

    onPlay: (event) => undefined, // log('onPlay'),
    onPause: (event) => undefined, // log('onPause'),
    onProgress: (event) => undefined, // log('onProgress'),
    onTimeUpdate: (event) => undefined, // log('onTimeUpdate'),
    onEnded: () => undefined, // log('onEnded'),
    onFullscreen: () => undefined, // log('onFullscreen'),
    onResize: () => undefined, // log('onResize'),
}


/* =========================================
      EXPORTS
-------------------------------------- */

export default LensVideo
