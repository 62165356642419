
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'


/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/system/SSLStatus')


/* =========================================
      COMPONENTS
-------------------------------------- */

class SSLStatus extends PureComponent {

    state = {
        sslAvailable: true,
    }

    get status () {
        return !!window.location.protocol.includes('https')
    }

    render () {
        const { props } = this

        const {
            id,
            className,
            style,

            hidden,

            children,
        } = props

        let {
            label,
            value,
        } = props

        label = (label === true) ? 'SSL' : label
        value = (value === true) ? `${this.status}` : value

        return (
            <div id={id}
                className={classnames('SSLStatus', 'Status', className, {hidden})}
                style={style}

                data-status={this.status}

                title={`Available: ${this.status ? 'yes' : 'no'}`}
            >
                <div className={classnames('Status-content')}>
                    <div className={classnames('Status-label')}>
                        { children || label || `${this.status}` }
                    </div>

                    <div className={classnames('Status-value')}>
                        { value }
                    </div>
                </div>
            </div>
        )
    }

}

SSLStatus.propTypes = {
    interval: PropTypes.number,

    id: PropTypes.string,
    className: PropTypes.string,
    styles: PropTypes.object,

    label: PropTypes.any,
    value: PropTypes.any,

    hidden: PropTypes.bool,

    onStatusChange: PropTypes.func,
}

SSLStatus.defaultProps = {
    interval: undefined,

    id: undefined,
    className: undefined,
    styles: undefined,

    label: true,
    value: false,

    hidden: true,
    disabled: false,

    onStatusChange: undefined,
}


/* =========================================
      EXPORT
-------------------------------------- */


export default SSLStatus
