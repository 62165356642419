
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import DemoServerProcessQueue from './DemoServerProcessQueue'


/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/DemoServerProcessManager')


/* =========================================
      COMPONENTS
-------------------------------------- */

const DemoServerProcessManager = (props) => {
    const queues = props.queues || {}

    const pendingQueue = {
        name: 'pending',
        jobs: queues.pending || [],
    }

    const activeQueue = {
        name: 'active',
        jobs: queues.active || [],
    }

    const failedQueue = {
        name: 'failed',
        jobs: queues.failed || [],
    }

    const successfulQueue = {
        name: 'successful',
        jobs: queues.successful || [],
    }

    const id = props.id
    const className = props.className

    const hidden = !!props.hidden
    const minimized = !!props.minimized

    return (
        <div className={classnames('DemoServerProcessManager', className, {hidden, minimized})}
            id={id}
        >

            <div className={classnames('DemoServerProcessManager-header')}>
                <div className={classnames('DemoServerProcessManager-header-title', {hidden: true})}>
                    { `Queues` }
                </div>
            </div>

            <div className={classnames('DemoServerProcessManager-content')}>

                <DemoServerProcessQueue queue={activeQueue} />

                <DemoServerProcessQueue queue={pendingQueue} />

                <DemoServerProcessQueue queue={failedQueue} />

                <DemoServerProcessQueue queue={successfulQueue} />

            </div>

        </div>
    )
}

DemoServerProcessManager.propTypes = {
    queues: PropTypes.object,
    hidden: PropTypes.bool,
}


DemoServerProcessManager.defaultProps = {
    // queues: {},
    hidden: false,
}


/* =========================================
      EXPORT
-------------------------------------- */

export default DemoServerProcessManager
