/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import shallowEqual from 'react-fast-compare'

import { TRANSPARENT_PIXEL } from '../../constants'

/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/DemoInputField')

/* =========================================
      COMPONENTS
-------------------------------------- */

class DemoInputFieldSampleVideos extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { props } = this

    if (shallowEqual(props, nextProps)) {
      return false
    }

    return true
  }

  render() {
    const { props } = this

    let samples = props.samples || []

    samples = samples.filter(sample => {
      return !!`${sample || ''}`.trim().length
    })

    const currentSampleURI = (props.sample || {}).uri

    const id = props.id
    const className = props.className
    const style = props.style
    const disabled = props.disabled
    const hidden = props.hidden

    return (
      <div
        className={classnames('DemoInputFieldSampleVideos', className, {
          hidden,
          disabled
        })}
        id={id}
        style={style}
      >
        <div className={classnames('DemoInputFieldSampleVideos-header')}>
          <div
            className={classnames('DemoInputFieldSampleVideos-action-close')}
            onClick={() => {
              if (this.input) {
                this.input.blur()
              }
            }}
          >
            <span>&times;</span>
          </div>
        </div>

        <div className={classnames('DemoInputFieldSampleVideos-content')}>
          {samples.map((sample, index) => {
            const sampleURI = (sample || {}).uri || sample
            const isCurrentSample = sampleURI === currentSampleURI

            const key = `DemoInputField-url-sample-${encodeURIComponent(
              sampleURI
            )}-${index}`

            const isYouTube =
              sampleURI.includes('youtube') || sampleURI.includes('youtu.be')
            const isRaw = !isYouTube

            let sampleVendorID

            if (isYouTube) {
              if (sampleURI.includes('youtu.be')) {
                sampleVendorID = sampleURI.match(/youtu\.be\/([_\d\w-]+)/im)[1]
              } else {
                sampleVendorID = sampleURI.match(/\/watch\?v=([_\d\w-]+)/im)[1]
              }
            }

            return (
              <div
                key={key}
                id={key}
                className={classnames('DemoInputField-url-sample', {
                  raw: isRaw,
                  youtube: isYouTube
                })}
                onClick={this.onSelectSample(sampleURI)}
              >
                <div className={classnames('DemoInputField-url-sample-meta')}>
                  <span
                    className={classnames(
                      'DemoInputField-url-sample-meta-kind'
                    )}
                  >
                    {isRaw && 'Raw'}
                    {isYouTube && 'YouTube'}
                  </span>
                </div>

                {isRaw && (
                  <video
                    className={classnames('DemoInputField-url-sample-video', {
                      selected: isCurrentSample
                    })}
                    style={{
                      background: isYouTube ? 'black' : 'transparent'
                    }}
                    src={sampleURI}
                    controls={false}
                    muted={true}
                    autoPlay={false}
                    loop={true}
                    onLoad={event => {
                      this.onLoadSampleVideo(event, sample.uri)
                    }}
                    onError={error => {
                      this.onErrorSampleVideo(error, sample.uri)
                    }}
                    onMouseOver={event => {
                      this.onPlaySampleVideo(event.target, sample.uri)
                    }}
                    onMouseOut={event => {
                      this.onPauseSampleVideo(event.target, sample.uri)
                    }}
                    alt={sampleURI}
                    title={sampleURI}
                  />
                )}

                {isYouTube && (
                  <img
                    className={classnames('DemoInputField-url-sample-video', {
                      selected: isCurrentSample
                    })}
                    style={{
                      background: isYouTube ? 'red' : 'transparent'
                    }}
                    src={`http://img.youtube.com/vi/${sampleVendorID}/maxresdefault.jpg`}
                    alt={sampleURI}
                    title={sampleURI}
                  />
                )}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  onSelectSample = uri => {
    return event => {
      const { props } = this

      if (typeof props.onChange !== 'function') {
        return
      }

      const onChangeEvent = {
        target: {
          value: uri
        }
      }

      props.onChange(onChangeEvent)
    }
  }

  onLoadSampleVideo = event => {
    // log('onLoadSampleVideo', event.target)
  }

  onErrorSampleVideo = event => {
    // log('onErrorSampleVideo', event)

    try {
      event.target.classList.add('error')
    } catch (err) {}

    try {
      event.target.src = TRANSPARENT_PIXEL
    } catch (err) {}
  }

  onLoadSampleImage = event => {
    // log('onLoadSampleImage', event.target)
  }

  onErrorSampleImage = event => {
    // log('onErrorSampleImage', event.target)

    try {
      event.target.classList.add('error')
    } catch (err) {}

    try {
      event.target.src = TRANSPARENT_PIXEL
    } catch (err) {}
  }

  onPlaySampleVideo = video => {
    // log('onPlaySampleVideo')

    video = video || this.video

    if (!video) return

    try {
      if (
        !/youtu|vimeo/i.test(video.src) &&
        video.src &&
        video.src.startsWith('http')
      ) {
        video.play()
      }
    } catch (err) {}
  }

  onPauseSampleVideo = video => {
    // log('onPauseSampleVideo')

    video = video || this.video

    if (!video) return

    try {
      if (
        !/youtu|vimeo/i.test(video.src) &&
        video.src &&
        video.src.startsWith('http')
      ) {
        video.pause()
      }
    } catch (err) {}
  }
}

DemoInputFieldSampleVideos.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool
}

DemoInputFieldSampleVideos.defaultProps = {
  id: undefined,
  className: undefined,
  style: undefined,
  disabled: undefined,
  hidden: undefined
}

/* =========================================
      EXPORT
-------------------------------------- */

export default DemoInputFieldSampleVideos
