/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { PureComponent } from 'react'
import classnames from 'classnames'

import Video from './DefaultVideo'

import YouTubePlayer from 'youtube-player'

/* =========================================
      GLOBALS
-------------------------------------- */

global.YouTube = YouTubePlayer

/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/DemoPlayer/Video/YouTubeVideo')

/* =========================================
      COMPONENTS
-------------------------------------- */

// API: https://developers.google.com/youtube/iframe_api_reference

// DEBUG: app.productPlayers.productPlayerYoutube.player

class YoutubeVideo extends PureComponent {
  state = {
    currentTime: 0
  }

  install = async () => {
    // NOTE: using Webpack/NPM import
  }

  uninstall = async () => {
    try {
      clearInterval(this._playerStateInterval)
      clearInterval(this._playerTimeUpdateInterval)

      if (this.api) {
        this.api.destroy()
      }
    } catch (error) {
      throw error
    }
  }

  setup = () => {
    return new Promise(async (resolve, reject) => {
      await Promise.all([Video.waitForProperty(this, 'root')])

      if (!this.root) {
        return reject(new Error(`Player element is undefined`))
      }

      try {
        const Youtube = window.YT

        const events = {
          ready: event => {
            setTimeout(() => {
              try {
                this.mute(!!this.props.muted)
              } catch (error) {}

              try {
                this.loop(!!this.props.loop)
              } catch (error) {}
            }, 1000)

            this.event('onLoad')()
          },
          stateChange: async state => {
            const stateCode = state.data

            const Youtube = window.YT

            switch (stateCode) {
              case Youtube.PlayerState.ENDED:
                return this.event('onEnded')()

              case Youtube.PlayerState.PLAYING:
                return this.event('onPlay')()

              case Youtube.PlayerState.PAUSED:
                return this.event('onPause')()

              case Youtube.PlayerState.BUFFERING:
                return this.event('onProgress')()

              case Youtube.PlayerState.CUED:
                return this.event('onDataLoaded')()

              default:
                // ~UNSTARTED
                return
            }
          },
          playbackQualityChange: suggestedQuality => {},
          apiChange: () => {}
        }

        const _youtube = YouTubePlayer(this.state.id, {
          videoId: this.state.videoID,

          width: this.props.width || this.props.width || 'auto',
          height: this.props.height || this.props.height || 'auto',

          playerVars: {
            autohide: true,
            autoplay: this.props.autoplay ? 1 : 0,
            controls: this.props.controls ? 1 : 0,
            loop: this.props.loop ? 1 : 0,
            fs: true,
            iv_load_policy: 3,
            modestbranding: true,
            playsinline: true,
            showinfo: false,
            theme: 'dark'
          }

          // events not allowed here (for the NPM module)
        })

        // attach events here instead
        Object.keys(events).forEach(name => {
          _youtube.on(name, events[name])
        })

        this.api = _youtube
        this.youtubePlayer = _youtube

        if (this.props.autoplay) {
          this.play()
        } else {
          this.stop()
        }

        // REVIEW
        // if (this.props.width && this.props.height) {
        //     this.api.setSize(
        //         parseInt(this.props.width),
        //         parseInt(this.props.height),
        //     )
        // }

        const youtubeVideoURI = `https://www.youtube.com/watch?v=${
          this.state.videoID
        }`
        const youtubeVideoMetaURI = `https://noembed.com/embed?format=json&url=${encodeURIComponent(
          youtubeVideoURI
        )}`

        const youtubeVideoMetaResponse = await fetch(youtubeVideoMetaURI, {
          mode: 'cors'
        })
        const youtubeVideoMeta = await youtubeVideoMetaResponse.json()

        this.setState({
          youtubeVideoURI,
          youtubeVideoMetaURI,
          youtubeVideoMeta
        })

        this._playerStateInterval = setInterval(async () => {
          if (Youtube) {
            let stateCode

            try {
              stateCode = await this.api.getPlayerState()
            } catch (error) {
              stateCode = -1
            }

            // const stateCodeChanged = stateCode !== this.state.stateCode

            let src

            try {
              src = youtubeVideoURI // await this.api.getVideoUrl()
            } catch (error) {
              src = undefined
            }

            let duration

            try {
              duration = await this.api.getDuration()
            } catch (error) {
              duration = 0
            }

            let currentTime

            try {
              currentTime = await this.api.getCurrentTime()

              if (
                Math.round(duration) !== 0 &&
                Math.round(currentTime) >= Math.round(duration) - 1
              ) {
                this.seek(0.1)

                // setTimeout(() => {
                //     if (!this.loop) {
                //         this.stop()
                //     }
                // }, 500)
              }
            } catch (error) {
              currentTime = 0
            }

            let muted

            try {
              muted = await this.api.isMuted()
            } catch (error) {
              muted = 0
            }

            const seeking = stateCode === Youtube.PlayerState.BUFFERING
            const seeked =
              stateCode !== Youtube.PlayerState.BUFFERING &&
              this.state.stateCode === Youtube.PlayerState.BUFFERING
            const paused = stateCode === Youtube.PlayerState.PAUSED
            const stopped =
              stateCode === Youtube.PlayerState.UNSTARTED ||
              stateCode === Youtube.PlayerState.ENDED ||
              stateCode === Youtube.PlayerState.CUED
            // || (currentTime === 0)
            const playing =
              !paused &&
              !stopped &&
              (seeking || stateCode === Youtube.PlayerState.PLAYING)

            if (playing) {
              this.event('onTimeUpdate')(currentTime)
            }

            // REVIEW
            if (seeking) {
              this.event('onSeeking')(currentTime)
            }

            // REVIEW
            if (seeked) {
              this.event('onSeeked')(currentTime)
            }

            const newPlayerState = {
              src,
              currentTime,
              duration,
              muted,
              seeking,
              playing,
              paused,
              stopped,

              stateCode
            }

            // console.warn('newPlayerState', JSON.stringify(newPlayerState))

            this.setState(newPlayerState)
          }
        }, 100)

        resolve(this.api)
      } catch (error) {
        reject(error)
      }
    })
  }

  event = name => {
    const props = this.props

    return (...args) => {
      log('event', name, ...args)

      if (props[name]) {
        props[name](...args)
      }
    }
  }

  play() {
    log('play()')

    if (this.youtubePlayer) {
      this.setState(
        {
          // play: true,
          // pause: false,
          // stop: false,
        },
        () => {
          try {
            this.youtubePlayer.playVideo()
          } catch (error) {}
        }
      )
    }
  }

  pause() {
    log('pause()')

    if (this.youtubePlayer) {
      this.setState(
        {
          // play: false,
          // pause: true,
          // stop: false,
        },
        () => {
          try {
            this.youtubePlayer.pauseVideo()
          } catch (error) {}
        }
      )
    }
  }

  stop() {
    log('stop()')

    if (this.youtubePlayer) {
      this.setState(
        {
          // play: false,
          // pause: false,
          // stop: true,
        },
        () => {
          try {
            this.youtubePlayer.stopVideo()
          } catch (error) {}
        }
      )
    }
  }

  mute(value = true) {
    log('mute()')

    if (this.youtubePlayer) {
      this.setState(
        {
          // mute: value,value
        },
        () => {
          try {
            this.youtubePlayer.mute(value)
          } catch (error) {}
        }
      )
    }
  }

  loop(value = true) {
    log('loop()')

    if (this.youtubePlayer) {
      this.setState(
        {
          // loop: value,
        },
        () => {
          try {
            this.youtubePlayer.setLoop(value)
          } catch (error) {}
        }
      )
    }
  }

  seek(seconds = 0) {
    log('seek()')

    if (this.youtubePlayer) {
      seconds = parseFloat(seconds || 0)

      this.setState(
        {
          // seek: seconds,
        },
        () => {
          this.youtubePlayer.seekTo(seconds, true)

          this.event('onTimeUpdate', seconds)
        }
      )
    }
  }

  get element() {
    return document.querySelector(`iframe#${this.state.id}`)
  }

  get src() {
    return this.state.src
  }

  get duration() {
    return this.state.duration
  }

  // set muted (value) {}

  get muted() {
    return !!this.state.muted
  }

  // set playing (value) {}

  get playing() {
    return !!this.state.playing
  }

  // set paused (value) {}

  get paused() {
    return !!this.state.paused
  }

  // set stopped (value) {}

  get stopped() {
    return !!this.state.stopped
  }

  get seeking() {
    return !!this.state.seeking
  }

  // set currentTime (value) {
  //     if (this.youtubePlayer) {
  //         this.seek(value)

  //     } else {
  //         return 0
  //     }
  // }

  get currentTime() {
    return this.state.currentTime || 0
  }

  get currentTimeString() {
    return `${(this.currentTime && Math.round(this.currentTime * 100) / 100) ||
      0}`.toHHMMSS()
  }

  get width() {
    if (this.youtubePlayer) {
      try {
        // OPTIMIZATION: use memoized value
        const { width } = this.element && getComputedStyle(this.element)

        return parseInt(width || 0)
      } catch (error) {
        return 0
      }
    } else {
      return 0
    }
  }

  get height() {
    if (this.youtubePlayer) {
      try {
        // OPTIMIZATION: use memoized value
        const { height } = this.element && getComputedStyle(this.element)

        return parseInt(height || 0)
      } catch (error) {
        return 0
      }
    } else {
      return 0
    }
  }

  get size() {
    return [this.width, this.height]
  }

  get naturalWidth() {
    if (this.youtubePlayer) {
      try {
        // OPTIMIZATION: use memoized value
        const { width } = this.state.youtubeVideoMeta || {}

        return parseInt(width || 0)
      } catch (error) {
        return 0
      }
    } else {
      return 0
    }
  }

  get naturalHeight() {
    if (this.youtubePlayer) {
      try {
        // OPTIMIZATION: use memoized value
        const { height } = this.state.youtubeVideoMeta || {}

        return parseInt(height || 0)
      } catch (error) {
        return 0
      }
    } else {
      return 0
    }
  }

  get naturalSize() {
    return [this.naturalWidth, this.naturalHeight]
  }

  get aspectRatio() {
    return this.width / parseFloat(this.height || 1)
  }

  get naturalAspectRatio() {
    return this.naturalWidth / parseFloat(this.naturalHeight || 1)
  }

  get scaleRatio() {
    return this.width / parseFloat(this.naturalWidth || 1)
  }

  get scaleRatioX() {
    return this.width / parseFloat(this.naturalWidth || 1)
  }

  get scaleRatioY() {
    return this.height / parseFloat(this.naturalHeight || 1)
  }

  componentDidMount() {
    YoutubeVideo.index = YoutubeVideo.index || 0
    YoutubeVideo.index++

    const index = YoutubeVideo.index
    const id = `youtube-video-${YoutubeVideo.index}`
    const videoID = `${this.props.src}`.match(/\/watch\?v=([_\d\w-]+)/im)[1]

    const width = parseInt(this.props.width) || undefined
    const height = parseInt(this.props.height) || undefined

    this.setState({
      index,
      id,
      videoID,

      width,
      height
    })

    this.install()
      .then(() => {
        this.setup()
      })
      .catch(error => {
        log('install', error)
      })
  }

  componentWillUnmount() {
    this.uninstall()
      .then(() => {
        // noop
      })
      .catch(error => {
        log('uninstall', error)
      })
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps) === JSON.stringify(this.props)) {
      return false
    }

    log('refresh')

    const videoID = `${nextProps.src}`.match(/\/watch\?v=([_\d\w-]+)/im)[1]

    this.setState(
      {
        videoID
      },
      () => {
        this.uninstall()
          .then(this.install)
          .then(this.setup)
      }
    )
  }

  render() {
    let width = this.props.width || 'auto'
    let height = this.props.height || 'auto'

    const aspectRatio =
      parseFloat(this.props.width || 0) / parseFloat(this.props.height || 1)

    width = parseInt(width)
    height = parseInt(height)

    if (Number.isNaN(width)) {
      width = 'auto'
    } else {
      width = `${width}px`
    }

    if (Number.isNaN(height)) {
      height = 'auto'
    } else {
      height = `${height}px`
    }

    return (
      <div
        className={classnames('YouTubeVideo')}
        style={{
          width,
          height
        }}
        data-width={width}
        data-height={height}
        data-aspect-ratio={aspectRatio}
      >
        <div
          {...{
            ref: ref => (this.root = ref),
            id: this.state.id,
            className: this.props.className,
            style: this.props.style
          }}
        />
      </div>
    )
  }
}

YoutubeVideo.propTypes = {
  ...Video.propTypes
}

YoutubeVideo.defaultProps = {
  ...Video.defaultProps
}

/* =========================================
      EXPORTS
-------------------------------------- */

export default YoutubeVideo
