

/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import shallowEqual from 'react-fast-compare'

// TODO: replace - don't seem to work
// import ScrollArea from 'react-scrollbar'

/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/DemoTopMatches')

/* =========================================
      COMPONENTS
-------------------------------------- */

class DemoTopMatches extends Component {

    state = {
        offset: 0,
    }

    shouldComponentUpdate (nextProps, nextState) {
        const { props } = this

        if (shallowEqual(props, nextProps)) {
            return false
        }

        return true
    }

    render () {
        const { props } = this

        const colors = props.colors || []
        const tracks = props.tracks || []

        const hasTracks = !!tracks.length

        const id = props.id
        const className = props.className
        const style = props.style
        const disabled = props.disabled
        const hidden = props.hidden

        const onTrackPreviewPaginationChange = props.onTrackPreviewPaginationChange || undefined

        return (
            <div className={classnames('DemoTopMatches', className, {hidden, disabled})}
                id={id}
                style={style}
            >
                <div className={classnames('DemoTopMatches-header')}>
                    <div className={classnames('DemoTopMatches-header-title')}>
                        { `Top matches` }
                    </div>
                </div>

                <div className={classnames('DemoTopMatches-content')}>
                    <div className={classnames('DemoResults-empty', {hidden: hasTracks})}>
                        <span className={classnames('DemoResults-empty-text')}>
                            No top matches generated
                        </span>
                    </div>

                    <div className={classnames('DemoTopMatches-matches')}>
                        {
                            tracks.map((track, trackIndex) => {
                                const trackMatches = track.matches || []
                                const trackCategoryName = track.category
                                const trackLength = track.length
                                const trackScore = track.score
                                const trackLengthMax = Math.round(trackLength / (trackScore || 1))
                                const trackAttributes = track.attributes || []

                                // const key = `DemoTopMatches-track-${trackCategoryName}`
                                const color = track.color || colors[trackIndex]

                                return trackMatches.map((match, matchIndex) => {
                                    const trackImage = match.images[0] || {uri: '#'}
                                    const trackCategoryLabel = trackCategoryName.replace(/[\W_]/gi, ' / ')
                                    const trackTooltip = [
                                        `Track occurance: ${trackLength} / ${trackLength / trackScore}`,
                                        `Track score: ${trackScore.toFixed(2)} (${parseInt(trackScore * 100)}%)`,
                                        `Match detection score: ${match.detection_score.toFixed(2)} (${parseInt(match.detection_score * 100)}%)`,
                                        `Match similarity score: ${match.score.toFixed(2)} (${parseInt(match.score * 100)}%)`,
                                    ].join('  •  ')

                                    const key = `DemoTopMatches-track-${trackCategoryName}-match-${match._id}`

                                    return (
                                        <div key={key} id={key}
                                            className={classnames('DemoTopMatches-match')}
                                            style={{
                                                borderColor: color,
                                            }}

                                            data-track-length={trackLength}
                                            data-track-score={trackScore}
                                            data-track-length-max={trackLengthMax}

                                            data-match-detection-score={match.detection_score}
                                            data-match-similariy-score={match.score}

                                            title={trackTooltip}

                                            onMouseOver={() => {
                                                if (typeof props.onTrackActive !== 'function') {
                                                    return
                                                }

                                                props.onModalActive(trackAttributes, trackCategoryName)
                                                props.onTrackActive(track)
                                            }}

                                            onMouseOut={() => {
                                                if (typeof props.onTrackInactive !== 'function') {
                                                    return
                                                }

                                                props.onModalInactive()
                                                props.onTrackInactive(track)
                                            }}
                                        >
                                            <div className={classnames('DemoTopMatches-match-content')}>
                                                <img className={classnames('DemoTopMatches-match-image')}
                                                    src={trackImage.uri}

                                                    alt={''}
                                                />

                                                <div className={classnames('DemoTopMatches-match-preview-actions')}>
                                                    <span className={classnames('DemoTopMatches-match-preview-action previous')}
                                                        style={{
                                                            backgroundColor: color,
                                                        }}

                                                        onClick={() => {
                                                            let offset = this.state.offset || 0

                                                            offset--

                                                            if (offset < 0) {
                                                                offset = (tracks.length - 1)
                                                            }

                                                            this.setState({
                                                                offset,
                                                            }, () => {
                                                                if (typeof onTrackPreviewPaginationChange === 'function') {
                                                                    onTrackPreviewPaginationChange({offset})
                                                                }
                                                            })
                                                        }}
                                                    >
                                                        ←
                                                    </span>

                                                    <span className={classnames('DemoTopMatches-match-preview-action next')}
                                                        style={{
                                                            backgroundColor: color,
                                                        }}

                                                        onClick={() => {
                                                            let offset = this.state.offset || 0

                                                            offset++

                                                            if (offset >= tracks.length) {
                                                                offset = 0
                                                            }

                                                            this.setState({
                                                                offset,
                                                            }, () => {
                                                                if (typeof onTrackPreviewPaginationChange === 'function') {
                                                                    onTrackPreviewPaginationChange({offset})
                                                                }
                                                            })
                                                        }}
                                                    >
                                                        →
                                                    </span>
                                                </div>

                                                <span />
                                            </div>

                                            <code className={classnames('DemoTopMatches-match-label', 'DemoTopMatches-match-class')}
                                                style={{
                                                    backgroundColor: color,
                                                }}

                                                title={`Track category`}
                                            >
                                                { trackCategoryLabel }
                                            </code>

                                            <code className={classnames('DemoTopMatches-match-label', 'DemoTopMatches-match-occurances')}
                                                style={{
                                                    backgroundColor: color,
                                                }}

                                                title={`Track length`}
                                            >
                                                <span>
                                                    { trackLength }
                                                </span>
                                            </code>

                                            <a className="DemoTopMatches-match-label DemoTopMatches-match-link"
                                                style={{
                                                    backgroundColor: color,
                                                }}
                                                href={match.data.url || '#'}
                                                target={`_blank`}
                                                title={`Go to product page`}

                                                onClick={(event) => {
                                                    event.stopPropagation()
                                                }}
                                            >
                                                <svg width="100px" height="100px" viewBox="0 0 100 100">
                                                    <g xmlns="http://www.w3.org/2000/svg" id="markabel-enhanced-demo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <g id="selector-ux-hover-to-buy" transform="translate(-810.000000, -496.000000)" fill="#FFFFFE" stroke="#FFFFFF">
                                                            <path d="M824.6,511.733333 C823.9744,511.733333 823.466667,512.241067 823.466667,512.866667 C823.466667,513.492267 823.9744,514 824.6,514 C825.2256,514 825.733333,513.492267 825.733333,512.866667 C825.733333,512.241067 825.2256,511.733333 824.6,511.733333 L824.6,511.733333 Z M818.933333,511.733333 C818.307733,511.733333 817.8,512.241067 817.8,512.866667 C817.8,513.492267 818.307733,514 818.933333,514 C819.558933,514 820.066667,513.492267 820.066667,512.866667 C820.066667,512.241067 819.558933,511.733333 818.933333,511.733333 L818.933333,511.733333 Z M826.521765,508.333333 C826.521765,508.958933 825.991965,509.466667 825.339177,509.466667 L818.834942,509.466667 C818.182153,509.466667 817.652354,508.958933 817.652354,508.333333 L816.666667,502.666667 L828,502.666667 L826.521765,508.333333 L826.521765,508.333333 Z M816.649667,501.533333 L815.235833,497 L811.566667,497 C811.2533,497 811,497.253867 811,497.566667 C811,497.880033 811.2533,498.133333 811.566667,498.133333 L814.4,498.133333 L815.539567,501.533333 L815.533333,501.533333 L816.666667,508.333333 C816.666667,509.5851 817.681567,510.6 818.933333,510.6 L825.166667,510.6 C826.418433,510.6 827.433333,509.5851 827.433333,508.333333 L829.133333,501.533333 L816.649667,501.533333 L816.649667,501.533333 Z" id="Fill-95" transform="translate(865, 550) scale(-4.5, 4.5) translate(-820.066667, -505.500000) "/>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>
                                        </div>
                                    )
                                })
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

DemoTopMatches.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,

    tracks: PropTypes.array,
    colors: PropTypes.array,
}

DemoTopMatches.defaultProps = {
    id: undefined,
    className: undefined,
    style: undefined,
    disabled: undefined,
    hidden: undefined,

    tracks: [],
    colors: [],
}


/* =========================================
      EXPORT
-------------------------------------- */

export default DemoTopMatches
