
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import shallowEqual from 'react-fast-compare'

// TODO: replace - don't seem to work
import ScrollArea from 'react-scrollbar'

import {
    CATEGORY_SCORE_THRESHOLD, TRANSPARENT_PIXEL
} from '../../constants'


/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/DemoResults')


/* =========================================
      COMPONENTS
-------------------------------------- */

class DemoResults extends Component {

    shouldComponentUpdate (nextProps) {
        const { props } = this

        if (shallowEqual(props, nextProps)) {
            return false
        }

        return true
    }

    render () {
        const { props } = this

        const colors = props.colors || []

        const frame = props.frame || undefined
        const tracks = props.tracks || []

        const results = props.results || []
        const resultSelection = props.resultSelection || {}

        const categoryScoreThresholds = props.categoryScoreThresholds || {}

        const hasResults = (results.length > 0)

        const id = props.id
        const className = props.className
        const style = props.style
        const disabled = props.disabled
        const hidden = props.hidden

        return (
            <div className={classnames('DemoResults', className, {hidden, disabled})}
                id={id}
                style={style}
            >
                <div className={classnames('DemoResults-header')}>
                    <div className={classnames('DemoResults-header-title')}>
                        { `All matches` }
                    </div>
                </div>

                <div className={classnames('DemoResults-content')}>
                    <div className={classnames('DemoResults-empty', {hidden: hasResults})}>
                        <span className={classnames('DemoResults-empty-text')}>
                            No detections in this frame
                        </span>
                    </div>

                    <div className={classnames('DemoResults-results')}>
                        {
                            results
                                .map((result, index) => {
                                    const category = (typeof result.category === 'object') ? result.category._id : result.category
                                    const categoryThreshold = categoryScoreThresholds[category] || CATEGORY_SCORE_THRESHOLD || 1

                                    // REFACTOR: not needed later when (top-)track IDs are generated server-side
                                    const resultTrack = tracks.find((track) => {
                                        return track.id === (result.matches[0] || {})._id
                                    }) || {}

                                    // const key = `DemoResults-result-${result._id}-${index}`
                                    const key = `DemoResults-result-${result._id}-${result.category._id}`
                                    const color = resultTrack.color || colors[index]

                                    return (
                                        <div key={key} id={undefined}
                                            className={classnames('DemoResults-results')}
                                        >
                                            <div className={
                                                    classnames('DemoResults-result', {
                                                        active: (typeof resultSelection.ids === 'undefined' || resultSelection.ids === null || resultSelection.ids.includes(index)),
                                                        inactive: Array.isArray(resultSelection.ids) && !resultSelection.ids.includes(index),
                                                        boundingbox: resultSelection.source === 'boundingbox',
                                                        result: resultSelection.source === 'result',
                                                    })
                                                }
                                                style={{
                                                    borderColor: color,
                                                }}

                                                result={result}

                                                onMouseOver={(event) => {
                                                    if (typeof props.onResultActive !== 'function') {
                                                        return
                                                    }

                                                    const id = index

                                                    props.onResultActive({
                                                        ...result,
                                                        id,
                                                    })
                                                }}
                                                onMouseOut={(event) => {
                                                    if (typeof props.onResultInactive !== 'function') {
                                                        return
                                                    }

                                                    const id = index

                                                    props.onResultInactive({
                                                        ...result,
                                                        id,
                                                    })
                                                }}
                                            >
                                                <ScrollArea
                                                    contentClassName="DemoResults-result-matches-scroll"
                                                    verticalScrollbarStyle={{
                                                        borderRadius: 0,
                                                        opacity: 0,
                                                    }}
                                                    verticalContainerStyle={{
                                                        borderRadius: 0,
                                                        opacity: 0,
                                                    }}
                                                    horizontalScrollbarStyle={{
                                                        borderRadius: 0,
                                                        opacity: 0,
                                                    }}
                                                    horizontalContainerStyle={{
                                                        borderRadius: 0,
                                                        opacity: 0,
                                                    }}
                                                    minScrollSize={0}
                                                    smoothScrolling={true}
                                                >
                                                    {
                                                        result.matches
                                                            .map((match) => {
                                                                const selected = (match.score >= categoryThreshold)

                                                                return {
                                                                    ...match,
                                                                    selected,
                                                                }
                                                            })
                                                            .map((match, j) => {
                                                                match.images = [
                                                                    ...(match.images || []),
                                                                    match.image
                                                                ].filter(Boolean)

                                                                match.data = match.data || {}

                                                                const image = match.images[0] || {}

                                                                const key = `DemoResults-result-${result._id}-match-${j}`

                                                                return (
                                                                    <div key={key}
                                                                        id={undefined}
                                                                        className={classnames('DemoResults-result-match', {
                                                                            rejected: !match.selected,
                                                                        })}

                                                                        match={match}

                                                                        data-score={match.score}
                                                                        data-score-threshold={categoryThreshold}

                                                                        onMouseOver={(event) => {
                                                                            if (typeof props.onResultMatchActive !== 'function') {
                                                                                return
                                                                            }

                                                                            props.onResultMatchActive(
                                                                                {
                                                                                    ...match,
                                                                                    id: index,
                                                                                },
                                                                                {
                                                                                    ...result,
                                                                                    id: index,
                                                                                },
                                                                            )
                                                                        }}
                                                                        onMouseOut={(event) => {
                                                                            if (typeof props.onResultMatchInactive !== 'function') {
                                                                                return
                                                                            }

                                                                            props.onResultMatchInactive(
                                                                                {
                                                                                    ...match,
                                                                                    id: index,
                                                                                },
                                                                                {
                                                                                    ...result,
                                                                                    id: index,
                                                                                },
                                                                            )
                                                                        }}
                                                                        onClick={(event) => {
                                                                            if (typeof props.onResultMatchClick !== 'function') {
                                                                                return
                                                                            }

                                                                            props.onResultMatchClick(
                                                                                {
                                                                                    ...match,
                                                                                    id: index,
                                                                                },
                                                                                {
                                                                                    ...result,
                                                                                    id: index,
                                                                                },
                                                                            )
                                                                        }}
                                                                    >
                                                                        <img className={classnames('DemoResults-result-match-image')}
                                                                            style={{
                                                                                // borderColor: color,
                                                                            }}
                                                                            src={image.uri}
                                                                            alt={''}

                                                                            onError={(err) => {
                                                                                try {
                                                                                    this.src = TRANSPARENT_PIXEL
                                                                                } catch (err) {}
                                                                            }}

                                                                            data-src={image.uri}
                                                                        />

                                                                        <code className="DemoResults-result-match-label DemoResults-result-match-score"
                                                                            style={{
                                                                                backgroundColor: color,
                                                                            }}
                                                                            title={`Similarity score`}
                                                                        >
                                                                            <span>
                                                                                { parseFloat(match.score).toFixed(2) }
                                                                            </span>
                                                                        </code>

                                                                        <a className="DemoResults-result-match-label DemoResults-result-match-link"
                                                                            style={{
                                                                                backgroundColor: color,
                                                                            }}
                                                                            href={match.data.url || '#'}
                                                                            target={`_blank`}
                                                                            title={`Go to product page`}

                                                                            onClick={(event) => {
                                                                                event.stopPropagation()
                                                                            }}
                                                                        >
                                                                            <svg width="100px" height="100px" viewBox="0 0 100 100">
                                                                                <g xmlns="http://www.w3.org/2000/svg" id="markabel-enhanced-demo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                    <g id="selector-ux-hover-to-buy" transform="translate(-810.000000, -496.000000)" fill="#FFFFFE" stroke="#FFFFFF">
                                                                                        <path d="M824.6,511.733333 C823.9744,511.733333 823.466667,512.241067 823.466667,512.866667 C823.466667,513.492267 823.9744,514 824.6,514 C825.2256,514 825.733333,513.492267 825.733333,512.866667 C825.733333,512.241067 825.2256,511.733333 824.6,511.733333 L824.6,511.733333 Z M818.933333,511.733333 C818.307733,511.733333 817.8,512.241067 817.8,512.866667 C817.8,513.492267 818.307733,514 818.933333,514 C819.558933,514 820.066667,513.492267 820.066667,512.866667 C820.066667,512.241067 819.558933,511.733333 818.933333,511.733333 L818.933333,511.733333 Z M826.521765,508.333333 C826.521765,508.958933 825.991965,509.466667 825.339177,509.466667 L818.834942,509.466667 C818.182153,509.466667 817.652354,508.958933 817.652354,508.333333 L816.666667,502.666667 L828,502.666667 L826.521765,508.333333 L826.521765,508.333333 Z M816.649667,501.533333 L815.235833,497 L811.566667,497 C811.2533,497 811,497.253867 811,497.566667 C811,497.880033 811.2533,498.133333 811.566667,498.133333 L814.4,498.133333 L815.539567,501.533333 L815.533333,501.533333 L816.666667,508.333333 C816.666667,509.5851 817.681567,510.6 818.933333,510.6 L825.166667,510.6 C826.418433,510.6 827.433333,509.5851 827.433333,508.333333 L829.133333,501.533333 L816.649667,501.533333 L816.649667,501.533333 Z" id="Fill-95" transform="translate(865, 550) scale(-4.5, 4.5) translate(-820.066667, -505.500000) "/>
                                                                                    </g>
                                                                                </g>
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                )
                                                            })
                                                    }
                                                </ScrollArea>

                                                <code className="DemoResults-result-label DemoResults-result-id"
                                                    style={{
                                                        backgroundColor: color,
                                                    }}
                                                    title={`Detection #`}
                                                >
                                                    { index + 1 }
                                                </code>

                                                <code className="DemoResults-result-label DemoResults-result-score"
                                                    style={{
                                                        backgroundColor: color,
                                                    }}
                                                    title={`Detection score`}
                                                >
                                                    { parseFloat(result.score).toFixed(2) }
                                                </code>

                                                <code className="DemoResults-result-label DemoResults-result-class"
                                                    style={{
                                                        backgroundColor: color,
                                                    }}
                                                    title={`Detection category`}

                                                    onClick={() => {
                                                        if (typeof props.onSearchThresholdToggle !== 'function') {
                                                            return
                                                        }

                                                        props.onSearchThresholdToggle(result)
                                                    }}
                                                >
                                                    { category.replace(/[\W_]/gi, ' / ') }
                                                </code>

                                            </div>
                                        </div>
                                    )
                                })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

DemoResults.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,

    colors: PropTypes.array,

    frame: PropTypes.object,
    tracks: PropTypes.array,
    results: PropTypes.array,

    categoryScoreThresholds: PropTypes.object,
    categoryScoreThresholdsView: PropTypes.object,

    resultSelection: PropTypes.object,
    // resultMatchSelection: PropTypes.object,

    showAccuracyScore: PropTypes.bool,

    onResultActive: PropTypes.func,
    onResultInactive: PropTypes.func,

    onResultMatchActive: PropTypes.func,
    onResultMatchInactive: PropTypes.func,
    onResultMatchClick: PropTypes.func,

    onResultMatchPreview: PropTypes.func,

    onSearchThresholdToggle: PropTypes.func,
    onSearchThresholdChange: PropTypes.func,
}

DemoResults.defaultProps = {
    id: undefined,
    className: undefined,
    style: undefined,
    disabled: undefined,
    hidden: undefined,

    colors: [],

    frame: undefined,
    tracks: [],
    results: [],

    categoryScoreThresholds: {},
    categoryScoreThresholdsView: {},

    resultSelection: undefined,
    // resultMatchSelection: undefined,

    showAccuracyScore: false,

    // onResultActive: (resultSelection) => log(`onResultActive`, resultSelection),
    // onResultInactive: (resultSelection) => log(`onResultInactive`, resultSelection),

    // onResultMatchActive: (matchSelection, resultSelection) => log(`onResultMatchActive`),
    // onResultMatchInactive: (matchSelection, resultSelection) => log(`onResultMatchInactive`),
    // onResultMatchClick: (matchSelection, resultSelection) => log(`onResultMatchClick`),
}


/* =========================================
      EXPORT
-------------------------------------- */

export default DemoResults
