
/* =========================================
      IMPORTS
-------------------------------------- */

import React, { Component } from 'react'
import classnames from 'classnames'
import shallowEqual from 'react-fast-compare'

import ObjectInspector from 'react-object-inspector'


/* =========================================
      COMPONENTS
-------------------------------------- */

class _ObjectInspector extends Component {

    shouldComponentUpdate (nextProps) {
        const { props } = this

        if (shallowEqual(props, nextProps)) {
            return false
        }

        return true
    }

    render () {
        const { props } = this

        return (
            <div className={classnames('ObjectInspector', props.className)}>
                <ObjectInspector {...props} />
            </div>
        )
    }
}


/* =========================================
      EXPORT
-------------------------------------- */

export default _ObjectInspector
