
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { Component } from 'react'
import classnames from 'classnames'
import queryString from 'query-string'

import LensPlayer from './components/LensPlayer/LensPlayer.jsx'


/* =========================================
      STYLES
-------------------------------------- */

import './Player.css'


/* =========================================
      LOGGER
-------------------------------------- */

let log = debug('src/ui/Player')


/* =========================================
      COMPONENTS
-------------------------------------- */

class Player extends Component {

    state = {
        url: `//qa.markable.ai/videos/vip-howtowearit-3-eland.mp4`,
        accessToken: null,
    }

    componentDidMount () {
        const { props, state } = this
        const { location, match } = props

        const params = queryString.parse(location.search)

        const { accessToken } = params

        log('componentDidMount', {params})

        this.setState({
            accessToken,
        })
    }

    componentWillUnmount () {
        clearInterval(this.stateInterval)
    }

    render () {
        const { state } = this
        const { url, accessToken } = state

        return (
            <main id='Player' className={classnames('Player', {})}>
                <LensPlayer
                    url={url}
                    accessToken={accessToken}
                />
            </main>
        )
    }
}


/* =========================================
      EXPORTS
-------------------------------------- */

export default Player
