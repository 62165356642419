
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import shallowEqual from 'react-fast-compare'


/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/DemoVideoStatus')


/* =========================================
      COMPONENTS
-------------------------------------- */

class DemoVideoStatus extends Component {

    state = {
        showStats: true,
        showStatsProcent: false,
        peekStats: false,
    }

    shouldComponentUpdate (nextProps, nextState) {
        const { props, state } = this

        if (shallowEqual(props, nextProps) && shallowEqual(state, nextState)) {
            return false
        }

        return true
    }

    render () {
        const { props } = this

        const searchResultsCount = (props.searchResults || []).length

        const searchResponseStats = props.searchResponseStats || {}
        const searchResponseTime = props.searchResponseTime || 0

        const isCachedResponse = (props.searchResponseTime === 0)

        const statsHidden = (
            (!this.state.showStats && !this.state.peekStats) ||
            !Object.keys(props.searchResponseStats || {}).length
        )

        return (
            <div className={classnames('DemoVideoStatus', {hidden: props.searchPending})}>
                <div className={classnames('DemoVideoStatus-label', {hidden: true})}
                    onClick={() => {
                        this.setState({
                            showStats: !this.state.showStats,
                        })
                    }}
                >
                    <span>
                        <strong>{ searchResultsCount }</strong> items

                        in&nbsp;

                        <span className={classnames('DemoVideoStatus-label-time')}
                            onMouseOver={() => {
                                this.setState({
                                    peekStats: true,
                                })
                            }}
                            onMouseOut={() => {
                                this.setState({
                                    peekStats: false,
                                })
                            }}
                        >
                            <span className={classnames('DemoVideoStatus-label-time-text')}>
                                <strong className={classnames('DemoVideoStatus-label-time-value')}>
                                    { searchResponseTime }
                                </strong> milli-seconds { isCachedResponse && '(cached)'}
                            </span>
                        </span>
                    </span>
                </div>


                <div className={classnames('DemoVideoStatus-stats', {
                        hidden: statsHidden,
                    })}
                    onMouseOut={() => {
                        this.setState({
                            peekStats: false,
                        })
                    }}
                >

                    <div className={classnames('DemoVideoStatus-stats-times')}>
                        {
                            Object.values(searchResponseStats)
                                .map((stat, i) => {
                                    let widthTime = 'auto'
                                    let widthStep = 0
                                    let backgroundColor = 'transparent'

                                    const stepEachTimeInSeconds = Math.round(stat.eachTime || 0) / 1000.0
                                    const stepElapsedTimeInSeconds = Math.round(stat.elapsedTime || 0) / 1000.0
                                    const stepElapsedTimeInProcent = parseFloat(stat.elapsedTime / props.searchTotalTime * 80)

                                    const stepStepsInProcent = parseFloat(stat.completed / stat.total * 100)

                                    const stepTooltip = `${stat.completed} / ${stat.total} (${stepStepsInProcent.toFixed(0)}%) • ${stepElapsedTimeInSeconds.toFixed(3)} seconds (~${stepElapsedTimeInProcent.toFixed(1)}%) • ${stepEachTimeInSeconds} seconds each`

                                    if (stat.elapsedTime && stat.completed) {
                                        widthTime = `${stepElapsedTimeInProcent}%`
                                        widthStep = `${stepStepsInProcent}%`
                                    }

                                    if (stat.total) {
                                        backgroundColor = props.colors[i]
                                    }

                                    const key = `DemoVideoStatus-stats-times-${i}`

                                    return (
                                        <div key={key} id={key}
                                            className={classnames('DemoVideoStatus-stats-time', {blinkSlow: !stat.completed})}
                                            style={{
                                                backgroundColor: (this.state.showStatsProcent ? backgroundColor : 'lightgray'), // FIXME
                                                width: (this.state.showStatsProcent ? widthTime : 'auto'), // FIXME
                                                padding: (this.state.showStatsProcent ? '6px 10px' : '0'), // FIXME
                                            }}

                                            onClick={() => {
                                                this.setState({
                                                    showStatsProcent: !this.state.showStatsProcent
                                                })
                                            }}

                                            title={stepTooltip}
                                        >
                                            <span
                                                className={classnames('DemoVideoStatus-stats-time-text time', {
                                                    hidden: !this.state.showStatsProcent,
                                                })}
                                            >
                                                { stepElapsedTimeInSeconds.toFixed(1) } s ({stepElapsedTimeInProcent.toFixed(1)}%)
                                            </span>

                                            <div
                                                className={classnames('DemoVideoStatus-stats-time-text procent', {
                                                    hidden: this.state.showStatsProcent
                                                })}
                                                style={{
                                                    width: widthStep,
                                                    padding: (this.state.showStatsProcent ? '0' : '6px 10px'),
                                                    backgroundColor,
                                                }}
                                            >
                                                { stat.completed || '0' } / { stat.total || '?' } { stat.unit }
                                            </div>
                                        </div>
                                    )
                                })
                        }
                    </div>

                    <div className="DemoVideoStatus-stats-labels">
                        {
                            Object.keys(searchResponseStats)
                                .map((statKey, index) => {
                                    const label = `${statKey}`.replace(/[\W\-_]/i, ' ')

                                    const key = `DemoVideoStatus-stats-label-${index}`
                                    const color = props.colors[index]

                                    return (
                                        <div key={key}
                                            className={classnames('DemoVideoStatus-stats-label')}
                                        >
                                            <span className={classnames('DemoVideoStatus-stats-label-box')}
                                                style={{
                                                    backgroundColor: color,
                                                }}
                                            />

                                            &nbsp;

                                            <span className={classnames('DemoVideoStatus-stats-label-text')}>
                                                { label }
                                            </span>
                                        </div>
                                    )
                                })
                        }
                    </div>

                </div>
            </div>
        )
    }
}

DemoVideoStatus.propTypes = {
    colors: PropTypes.array,

    searchPending: PropTypes.bool,
    searchResults: PropTypes.array,
    searchResponseStats: PropTypes.object,
    searchResponseTime: PropTypes.number,
    searchTotalTime: PropTypes.number,
}

DemoVideoStatus.defaultProps = {
    colors: [],

    searchPending: undefined,
    searchResults: undefined,
    searchResponseStats: undefined,
    searchResponseTime: undefined,
    searchTotalTime: undefined,
}


/* =========================================
      EXPORT
-------------------------------------- */

export default DemoVideoStatus
