
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import shallowEqual from 'react-fast-compare'


/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/DemoPlayer/LensVideoFrameTicker')


/* =========================================
      COMPONENTS
-------------------------------------- */

class LensVideoFrameTicker extends Component {

    // shouldComponentUpdate (nextProps) {
    //     const { props } = this

    //     if (shallowEqual(props, nextProps)) {
    //         return false
    //     }

    //     return true
    // }

    render () {
        const { props } = this

        const video = props.video || {}

        const colors = props.colors || []

        const frames = props.frames || []
        const frameCount = frames.length

        const tracks = props.tracks || []
        const trackCount = tracks.length

        const currentFrame = props.currentFrame || {}

        const emptyFrame = {timestamp: 0, image: {width: 0, height: 0}}
        const firstFrame = frames[0] || emptyFrame
        const lastFrame = frames.length && frames[frames.length - 1] || emptyFrame

        const framesTimeMilliSeconds = lastFrame.timestamp - firstFrame.timestamp
        const framesTimeSeconds = framesTimeMilliSeconds / 1000
        const framesSamplingRate = parseInt(framesTimeSeconds && (frameCount / parseFloat(framesTimeSeconds)))

        return (
            <div className={classnames('LensVideoFrameTicker', {})}>

                <div className={classnames('LensVideoFrameTicker-header')}>
                    <div className={classnames('LensVideoFrameTicker-header-title')}>

                    </div>
                </div>

                <div className={classnames('LensVideoFrameTicker-content')}>
                    <div className={classnames('LensVideoFrameTicker-frames')}>
                        {
                            frames.map((frame, index) => {
                                const currentTime = ((frame.timestamp || 0) / 1000.0)
                                const isCurrent = (frame.timestamp === currentFrame.timestamp)

                                const label = `Frame ${index} of ${frameCount} (${currentTime} s)`
                                const detectionCount = (frame.results || []).length

                                const frameTracks = tracks.filter((track) => {
                                    return track.timestamps.includes(frame.timestamp)
                                })

                                const key = `LensVideoFrameTicker-${frame._id}`

                                return (
                                    <div key={key} id={key}
                                        className={classnames('LensVideoFrameTicker-frame', {
                                            current: isCurrent,
                                        })}

                                        data-detections={detectionCount}

                                        data-frame-index={index}
                                        data-frame-count={frameCount}

                                        onClick={() => {
                                            try {
                                                video.currentTime = currentTime

                                                log('onFrameSelect', currentTime, video.currentTime)

                                            } catch (error) {
                                                log('onFrameSelect', 'ERROR', error)
                                            }

                                            if (typeof props.onFrameClick !== 'function') {
                                                return
                                            }

                                            props.onFrameClick(frame)
                                        }}
                                        onMouseOver={() => {
                                            if (typeof props.onFrameMouseOver !== 'function') {
                                                return
                                            }

                                            props.onFrameMouseOver(frame)
                                        }}
                                        onMouseOut={() => {
                                            if (typeof props.onFrameMouseOut !== 'function') {
                                                return
                                            }

                                            props.onFrameMouseOut(frame)
                                        }}
                                    >
                                        <div className={classnames('LensVideoFrameTicker-frame-tick')}
                                            title={label}
                                        >
                                            &nbsp;
                                        </div>

                                        <div className={classnames('LensVideoFrameTicker-frame-tracks')}>
                                            {
                                                frameTracks.map((track) => {
                                                    const key = `LensVideoFrameTicker-frame-track-${track.id}`
                                                    const color = track.color || colors[track.index]

                                                    return (
                                                        <div key={key} id={key}
                                                            className={classnames('LensVideoFrameTicker-frame-track')}
                                                        >
                                                            <div className={classnames('LensVideoFrameTicker-frame-track-tick')}
                                                                style={{
                                                                    backgroundColor: color,
                                                                }}
                                                            >
                                                                &nbsp;
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>

                <div className={classnames('LensVideoFrameTicker-footer')}>
                    <div className={classnames('LensVideoFrameTicker-footer-summary')}>
                        <span className={classnames('LensVideoFrameTicker-footer-summary-value', 'LensVideoFrameTicker-footer-summary-track-length')}>
                            { trackCount }
                        </span>

                        <span className={classnames('LensVideoFrameTicker-footer-summary-unit', 'LensVideoFrameTicker-footer-summary-track-length')}>
                            { ` tracks` }
                        </span>

                        &nbsp;/&nbsp;

                        <span className={classnames('LensVideoFrameTicker-footer-summary-value', 'LensVideoFrameTicker-footer-summary-frame-length')}>
                            { frameCount }
                        </span>

                        <span className={classnames('LensVideoFrameTicker-footer-summary-unit', 'LensVideoFrameTicker-footer-summary-frame-length')}>
                            { ` frames` }
                        </span>

                        { !!framesSamplingRate && (' @ ')}

                        <span className={classnames('LensVideoFrameTicker-footer-summary-value', 'LensVideoFrameTicker-footer-summary-frame-rate')}>
                            { !!framesSamplingRate && (framesSamplingRate) }
                        </span>

                        <span className={classnames('LensVideoFrameTicker-footer-summary-unit', 'LensVideoFrameTicker-footer-summary-frame-rate')}>
                            { !!framesSamplingRate && (' fps') }
                        </span>

                        &nbsp;~&nbsp;

                        <span className={classnames('LensVideoFrameTicker-footer-summary-value', 'LensVideoFrameTicker-footer-summary-frame-rate')}>
                            { firstFrame.image.width }
                        </span>

                        <span className={classnames('LensVideoFrameTicker-footer-summary-divider', 'LensVideoFrameTicker-footer-summary-frame-rate')}>
                            &times;
                        </span>

                        <span className={classnames('LensVideoFrameTicker-footer-summary-value', 'LensVideoFrameTicker-footer-summary-frame-rate')}>
                            { firstFrame.image.height }
                        </span>

                        <span className={classnames('LensVideoFrameTicker-footer-summary-unit', 'LensVideoFrameTicker-footer-summary-frame-rate')}>
                            { ` pixels` }
                        </span>
                    </div>
                </div>

            </div>
        )
    }

}

LensVideoFrameTicker.propTypes = {
    video: PropTypes.object,

    colors: PropTypes.array,

    frames: PropTypes.arrayOf(PropTypes.object),
    tracks: PropTypes.arrayOf(PropTypes.object),

    currentFrame: PropTypes.object,
}

LensVideoFrameTicker.defaultProps = {
    video: undefined,

    colors: [],

    frames: [],
    tracks: [],

    currentFrame: undefined,
}


/* =========================================
      EXPORT
-------------------------------------- */

export default LensVideoFrameTicker
