
export default {
    "meta": {
        "service": {
            "name": "markable-api-video",
            "environment": "development"
        },
        "request": {
            "protocol": "http",
            "method": "GET",
            "url": "/video/search/?uri=https://youtu.be/Ed6L5GugkcQ",
            "route": "/video/search/",
            "params": {},
            "query": {
                "uri": "https://youtu.be/Ed6L5GugkcQ"
            }
        },
        "response": {},
        "status": "complete",
        "video": {
            "uri": "https://youtu.be/Ed6L5GugkcQ"
        },
        "pagination": {
            "page": 0,
            "pageBy": "frameindex",
            "pageLimit": 100,
            "pageFrom": 0,
            "pageTo": 100,
            "pageSize": 100,
            "pageTotalSize": 100
        },
        "statuses": [
            {
                "step": "lookup",
                "completed": 1,
                "total": 1,
                "unit": "lookups",
                "startedAt": 1537160529846,
                "status": 1,
                "elapsedTime": 1046,
                "eachTime": 1046,
                "elapsedTimeString": "00:00:01",
                "eachTimeString": "00:00:01",
                "endedAt": 1537160530892,
                "totalTime": 1046,
                "totalTimeString": "00:00:01"
            },
            {
                "step": "fetch",
                "startedAt": 1537160530893,
                "elapsedTime": 276,
                "elapsedTimeString": "00:00:00",
                "completed": 0.03125,
                "total": 12.601143836975098,
                "unit": "MB",
                "status": 0.0024799336000200403,
                "eachTime": 8832,
                "eachTimeString": "00:00:08",
                "endedAt": 1537160531169,
                "totalTime": 276,
                "totalTimeString": "00:00:00"
            },
            {
                "step": "snapshot",
                "startedAt": 1537160531240,
                "elapsedTime": 5936,
                "elapsedTimeString": "00:00:05",
                "completed": 129,
                "total": 130,
                "status": 0.9923076923076923,
                "eachTime": 46.01550387596899,
                "eachTimeString": "00:00:00",
                "endedAt": 1537160537176,
                "totalTime": 5936,
                "totalTimeString": "00:00:05"
            },
            {
                "step": "detect"
            },
            {
                "step": "track",
                "completed": 258,
                "total": 258,
                "startedAt": 1537160537177,
                "status": 1,
                "elapsedTime": 70177,
                "eachTime": 272.00387596899225,
                "elapsedTimeString": "00:01:10",
                "eachTimeString": "00:00:00",
                "endedAt": 1537160607354,
                "totalTime": 70177,
                "totalTimeString": "00:01:10"
            },
            {
                "step": "search",
                "completed": 129,
                "total": 129,
                "startedAt": 1537160607356,
                "status": 1,
                "elapsedTime": 6450,
                "eachTime": 50,
                "elapsedTimeString": "00:00:06",
                "eachTimeString": "00:00:00",
                "endedAt": 1537160613806,
                "totalTime": 6450,
                "totalTimeString": "00:00:06"
            },
            {
                "step": "map",
                "total": 129,
                "startedAt": 1537160613808,
                "elapsedTime": 76,
                "elapsedTimeString": "00:00:00",
                "completed": 129,
                "status": 1,
                "eachTime": 0.5891472868217055,
                "eachTimeString": "00:00:00",
                "endedAt": 1537160613884,
                "totalTime": 76,
                "totalTimeString": "00:00:00"
            }
        ],
        "job": {
            "id": "42b3de649f8204ddb8036b179eb9e512",
            "uri": "https://youtu.be/Ed6L5GugkcQ",
            "options": {
                "search": {
                    "catalogs": [
                        "5ae7af541393dd3d2907816d"
                    ]
                }
            },
            "config": {
                "step": "config",
                "key": "00.config",
                "startedAt": 1537160663264,
                "endedAt": 1537160663264,
                "options": {
                    "verbose": true,
                    "log": true,
                    "cache": false,
                    "bail": false,
                    "configure": null,
                    "read": null,
                    "clean": null,
                    "lookup": null,
                    "fetch": null,
                    "snapshot": {
                        "size": 800,
                        "fps": 5
                    },
                    "detect": {
                        "cache": true,
                        "concurrency": 8,
                        "url": "http://samsonite-cv-ohio-1585320898.us-east-2.elb.amazonaws.com/v1/index/attributes",
                        "debugDumpObjectData": false,
                        "debugDumpImageSnapshots": false,
                        "debugDumpVideoSnapshot": false
                    },
                    "track": {
                        "maxVectorDistance": 20,
                        "minOccurrantFrameRatio": 0.5,
                        "minSequenceFrameRatio": 0.3,
                        "occurancyFrequencyFilterEnabled": true,
                        "debugDumpImageSnapshots": false,
                        "debugDumpVideoSnapshot": false
                    },
                    "search": {
                        "catalogs": [
                            "5ae7af541393dd3d2907816d"
                        ]
                    },
                    "map": null,
                    "write": null
                },
                "verbose": true,
                "log": true,
                "cache": false,
                "bail": false,
                "configure": {
                    "prefix": "00"
                },
                "read": {
                    "prefix": "01"
                },
                "clean": {
                    "prefix": "02"
                },
                "lookup": {
                    "prefix": "03"
                },
                "fetch": {
                    "prefix": "04"
                },
                "snapshot": {
                    "prefix": "05",
                    "size": 800,
                    "fps": 5
                },
                "detect": {
                    "prefix": "06",
                    "cache": true,
                    "concurrency": 8,
                    "url": "http://samsonite-cv-ohio-1585320898.us-east-2.elb.amazonaws.com/v1/index/attributes",
                    "debugDumpObjectData": false,
                    "debugDumpImageSnapshots": false,
                    "debugDumpVideoSnapshot": false
                },
                "track": {
                    "prefix": "07",
                    "maxVectorDistance": 20,
                    "minOccurrantFrameRatio": 0.5,
                    "minSequenceFrameRatio": 0.3,
                    "occurancyFrequencyFilterEnabled": true,
                    "debugDumpImageSnapshots": false,
                    "debugDumpVideoSnapshot": false
                },
                "search": {
                    "prefix": "08",
                    "concurrency": 8,
                    "genders": [
                        "women"
                    ],
                    "catalogs": [
                        "5ae7af541393dd3d2907816d"
                    ],
                    "limit": 10,
                    "apiKey": "5991c086c17de50e1e2e1d66",
                    "debugDumpImageSnapshots": false,
                    "debugDumpVideoSnapshot": false,
                    "debugDumpObjectData": false,
                    "debugDumpObjectResultMatchImages": false,
                    "ignoreCategories": [
                        "humans"
                    ]
                },
                "map": {
                    "prefix": "09"
                },
                "write": {
                    "prefix": "10"
                },
                "domain": "youtu.be",
                "id": "42b3de649f8204ddb8036b179eb9e512",
                "cachePath": "/tmp/markable-video-processing/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20",
                "cacheProcessPath": "/tmp/markable-video-processing/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process",
                "detectFramesPath": "/tmp/markable-video-processing/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/detect",
                "trackFramesPath": "/tmp/markable-video-processing/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/track",
                "searchFramesPath": "/tmp/markable-video-processing/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/search",
                "writeFilePath": "/tmp/markable-video-processing/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/index.json",
                "time": 0
            }
        },
        "jobs": {
            "pending": [],
            "active": [],
            "successful": [
                {
                    "id": "42b3de649f8204ddb8036b179eb9e512",
                    "uri": "https://youtu.be/Ed6L5GugkcQ",
                    "options": {
                        "search": {
                            "catalogs": [
                                "5ae7af541393dd3d2907816d"
                            ]
                        }
                    },
                    "config": {
                        "verbose": true,
                        "log": true,
                        "cache": false,
                        "bail": false,
                        "configure": {
                            "prefix": "00"
                        },
                        "read": {
                            "prefix": "01"
                        },
                        "clean": {
                            "prefix": "02"
                        },
                        "lookup": {
                            "prefix": "03"
                        },
                        "fetch": {
                            "prefix": "04"
                        },
                        "snapshot": {
                            "prefix": "05",
                            "size": 800,
                            "fps": 5
                        },
                        "detect": {
                            "prefix": "06",
                            "cache": true,
                            "concurrency": 8,
                            "url": "http://samsonite-cv-ohio-1585320898.us-east-2.elb.amazonaws.com/v1/index/attributes",
                            "debugDumpObjectData": false,
                            "debugDumpImageSnapshots": false,
                            "debugDumpVideoSnapshot": false
                        },
                        "track": {
                            "prefix": "07",
                            "maxVectorDistance": 20,
                            "minOccurrantFrameRatio": 0.5,
                            "minSequenceFrameRatio": 0.3,
                            "occurancyFrequencyFilterEnabled": true,
                            "debugDumpImageSnapshots": false,
                            "debugDumpVideoSnapshot": false
                        },
                        "search": {
                            "prefix": "08",
                            "concurrency": 8,
                            "genders": [
                                "women"
                            ],
                            "catalogs": [
                                "5ae7af541393dd3d2907816d"
                            ],
                            "limit": 10,
                            "apiKey": "5991c086c17de50e1e2e1d66",
                            "debugDumpImageSnapshots": false,
                            "debugDumpVideoSnapshot": false,
                            "debugDumpObjectData": false,
                            "debugDumpObjectResultMatchImages": false,
                            "ignoreCategories": [
                                "humans"
                            ]
                        },
                        "map": {
                            "prefix": "09"
                        },
                        "write": {
                            "prefix": "10"
                        },
                        "domain": "youtu.be",
                        "id": "42b3de649f8204ddb8036b179eb9e512",
                        "cachePath": "/tmp/markable-video-processing/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20",
                        "cacheProcessPath": "/tmp/markable-video-processing/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process",
                        "detectFramesPath": "/tmp/markable-video-processing/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/detect",
                        "trackFramesPath": "/tmp/markable-video-processing/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/track",
                        "searchFramesPath": "/tmp/markable-video-processing/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/search",
                        "writeFilePath": "/tmp/markable-video-processing/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/index.json"
                    },
                    "__id": "42b3de649f8204ddb8036b179eb9e512",
                    "error": null
                }
            ],
            "failed": []
        }
    },
    "data": [
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-0",
            "index": 0,
            "timestamp": 0,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000001.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-1",
            "index": 1,
            "timestamp": 200,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000002.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-2",
            "index": 2,
            "timestamp": 400,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000003.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-3",
            "index": 3,
            "timestamp": 600,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000004.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "941d09ca-c37a-4234-a244-957edeb2c781",
                    "score": 0.9494697478684512,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 260.6316833496094,
                        "y": 341.0899963378906,
                        "width": 57.4830322265625,
                        "height": 21.28509521484375
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c26d6efb8d3d2f80ccba",
                            "schema": "product",
                            "score": 0.8650787830352783,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/56/7a/567a6cddec9b5b7b582d42e820acbe66_best.jpg",
                                    "width": 720,
                                    "height": 858
                                }
                            ],
                            "data": {
                                "id": "717496724_White:717496724:White:https://www.shopstyle.com/g/women/sonic-editions/john-cale-lou-reed-and-warhol-by-waring-abbott-framed",
                                "sku": "717496724",
                                "name": "Sonic Editions John Cale, Lou Reed and Warhol by Waring Abbott (Framed)",
                                "url": "https://www.shopstyle.com/g/women/sonic-editions/john-cale-lou-reed-and-warhol-by-waring-abbott-framed",
                                "brand": "Sonic Editions",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "John Cale, Lou Reed and Warhol Framed Print: *Framed art print *Photograph of John Cale and Lou Reed, at a photo shoot in New York City. Photo of Andy Warhol in background. *Photograph by Waring Abbott *Quality digital C-Type prints *High-quality handmade wooden frame *Acid-free and cut to order mount *Shatter resistant Perspex",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "12x16",
                                "age_group": "adult",
                                "stock": true,
                                "price": 195,
                                "currency": "USD"
                            },
                            "fingerprint": "ce7e97654f9748b989ec4140ecbb1aeb",
                            "created_at": "2018-05-01T19:39:25.326Z",
                            "updated_at": "2018-06-18T22:18:09.053Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae900666efb8d3d2f8f36fe",
                            "schema": "product",
                            "score": 0.8461506271362305,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/35/1c/351c70b19c5522e1c5e04450ebc7876f_best.jpg",
                                    "width": 272,
                                    "height": 273
                                }
                            ],
                            "data": {
                                "id": "648557865_Black:648557865:Black:https://www.shopstyle.com/g/men/christian-dior/walk-51mm-square-sunglasses",
                                "sku": "648557865",
                                "name": "Christian Dior Walk 51MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/christian-dior/walk-51mm-square-sunglasses",
                                "brand": "Christian Dior",
                                "color": "Black",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Transparent square frame with contrast arms.51mm lens width; 19mm bridge width; 150mm temple length.100% UV protection. Acetate. Made in Italy.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "ONE SIZE",
                                "age_group": "adult",
                                "stock": true,
                                "price": 320,
                                "currency": "USD"
                            },
                            "fingerprint": "ba88d3f527c7eb81659e20c9b172248a",
                            "created_at": "2018-05-02T00:03:50.172Z",
                            "updated_at": "2018-06-23T01:25:09.460Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d12a1393dd3d291213a2",
                            "schema": "product",
                            "score": 0.8415222072601318,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/dc/c3/dcc3d05785f618419e95a64ddb41cf81_best.jpg",
                                    "width": 720,
                                    "height": 344
                                }
                            ],
                            "data": {
                                "id": "689036426_BLACK:689036426:BLACK:https://www.shopstyle.com/g/women/tom-ford/womens-ft-9280-50f-59mm-sunglasses",
                                "sku": "689036426",
                                "name": "Tom Ford Women's Ft_9280_50F 59Mm Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/tom-ford/womens-ft-9280-50f-59mm-sunglasses",
                                "brand": "Tom Ford",
                                "color": "BLACK",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Frame shape: cat eye Frame color: striped brown Lens color: gradient brown These frames flatter those with an oval, round, diamond, triangle or oblong shaped face Plastic lenses with 100% UV protection Lens: 59mm wide Bridge: 16mm wide Arms: 135mm long Case included; case size and color may vary Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "NoSize",
                                "age_group": "adult",
                                "stock": true,
                                "price": 450,
                                "currency": "USD"
                            },
                            "fingerprint": "918350b9a0677055a8d8b10f8bbb6f72",
                            "created_at": "2018-05-01T20:42:18.071Z",
                            "updated_at": "2018-06-19T01:43:07.001Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b4c060cf5e48d63ce2c4",
                            "schema": "product",
                            "score": 0.8414638328552246,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/52/45/524587dc6baca3f3cadeaa65967b90db_best.jpg",
                                    "width": 490,
                                    "height": 328
                                }
                            ],
                            "data": {
                                "id": "664398819:664398819::https://www.shopstyle.com/p/rikki-knight-hipster-giraffes-with-colorful-glasses-design-compact-mirror/664398819",
                                "sku": "664398819",
                                "name": "Rikki Knight Hipster Giraffes with Colorful Glasses Design Compact Mirror",
                                "url": "https://www.shopstyle.com/p/rikki-knight-hipster-giraffes-with-colorful-glasses-design-compact-mirror/664398819",
                                "brand": "Rikki Knight",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "This Rikki Knight Hipster Giraffes with colorful glasses Compact Mirror is the perfect sized mirror to slip into your pocket, purse, bag, pencil case, or any bag and features quality vibrant Images that will not fade with time. The compact has two mirrors on the inside and features an easy hinged open close latch. If you are planning to buy this for someone else once you see it, it will be hard to part from it. When you purchase a Rikki Knight Brand you can count on satisfaction; This Rikki knight Hipster Giraffes with colorful glasses could be an ideal gift for all occasions; Disclaimer: All images are printed directly into the material - The Texture of the image will always be a flat surface. Any images that appears to be 3d, textured, or glittery are flat printed images and are proudly printed in the USA.",
                                "category": "Women > Beauty > Tools > Mirrors |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 17.95,
                                "currency": "USD"
                            },
                            "fingerprint": "6fbd2148d02f137fdb4df9e3a7eac338",
                            "created_at": "2018-05-01T00:28:48.506Z",
                            "updated_at": "2018-06-18T20:18:08.446Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e765b77b36100f19e9e0",
                            "schema": "product",
                            "score": 0.8406785488128662,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/7d/26/7d26d5011cb95aec276e4e17d606a687_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "716846003_Silver/Solid Grey:716846003:Silver/Solid Grey:https://www.shopstyle.com/g/women/saint-laurent/sl-182-metal-betty-sunglasses",
                                "sku": "716846003",
                                "name": "Saint Laurent SL 182 Metal Betty Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/sl-182-metal-betty-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "Silver/Solid Grey",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Mirrored frames Soft case Cleaning cloth included Shield frame Non-polarized lenses Made in Italy Style # YVESL20164 Measurements Width: 6in / 15cm Height: 2.5in / 6.5cm Lens Width: 68mm",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 530,
                                "currency": "USD"
                            },
                            "fingerprint": "ea9286cb4b85b77892c450bb6abf01f8",
                            "created_at": "2018-05-01T22:17:09.854Z",
                            "updated_at": "2018-06-26T23:42:19.889Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fadc6efb8d3d2f8c3c46",
                            "schema": "product",
                            "score": 0.8389666557312012,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/38/da/38daa07c656265015e558b0f9d9c150d_best.jpg",
                                    "width": 336,
                                    "height": 328
                                }
                            ],
                            "data": {
                                "id": "651083859_White:651083859:White:https://www.shopstyle.com/g/men/nike/legend-65mm-multi-sport-sunglasses",
                                "sku": "651083859",
                                "name": "Nike Legend 65mm Multi-Sport Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/nike/legend-65mm-multi-sport-sunglasses",
                                "brand": "Nike",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Lightweight, durable and versatile enough to keep the sun out of your eyes in any outdoor activity, these action-ready sunglasses are constructed with Nike Max Optics to provide enhanced clarity from every angle in an 8-base sport-wrap frame for maximum coverage. High-tension temple hinges ensure a stay-put fit that makes these shades an ideal choice for runs, workouts and more. Style Name:Nike Legend 65Mm Multi-Sport Sunglasses. Style Number: 5417633. Available in stores.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 79,
                                "currency": "USD"
                            },
                            "fingerprint": "70ea91c7ba2507d37a5933b3991212bc",
                            "created_at": "2018-05-01T23:40:12.209Z",
                            "updated_at": "2018-06-23T01:25:09.223Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0f1b77b36100f161f43",
                            "schema": "product",
                            "score": 0.8382883453369141,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5e/3f/5e3faee81a2349c095deb113b51fa4b8_best.jpg",
                                    "width": 496,
                                    "height": 224
                                }
                            ],
                            "data": {
                                "id": "437780163_White:437780163:White:https://www.shopstyle.com/p/kreed-mens-makin-it-big-square-sunglasses/437780163",
                                "sku": "437780163",
                                "name": "Kreed Men's Makin It Big Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/kreed-mens-makin-it-big-square-sunglasses/437780163",
                                "brand": "Kreed",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "You can never go wrong with a classic. You canâ€TMt help but feel great wearing this 80s-inspired wayfarer frame!",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "57 mm",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35,
                                "currency": "USD"
                            },
                            "fingerprint": "aa8f881b8362e3383c2e5c310039394e",
                            "created_at": "2018-05-01T21:49:37.971Z",
                            "updated_at": "2018-06-23T01:24:27.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b9e1b77b36100f148c61",
                            "schema": "product",
                            "score": 0.8378244590759277,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/8b/37/8b378c307289256ca668069717f71b7d_best.jpg",
                                    "width": 350,
                                    "height": 322
                                }
                            ],
                            "data": {
                                "id": "651083831_Green:651083831:Green:https://www.shopstyle.com/g/men/nike/legend-65mm-mirrored-multi-sport-sunglasses",
                                "sku": "651083831",
                                "name": "Nike Legend 65mm Mirrored Multi-Sport Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/nike/legend-65mm-mirrored-multi-sport-sunglasses",
                                "brand": "Nike",
                                "color": "Green",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Lightweight, durable and versatile enough to keep the sun out of your eyes in any outdoor activity, these cool reflective sunglasses are constructed with Nike Max Optics to provide enhanced clarity from every angle in an 8-base sport-wrap frame for maximum coverage. High-tension temple hinges ensure a stay-put fit that makes these shades an ideal choice for runs, workouts and more. Style Name:Nike Legend 65Mm Mirrored Multi-Sport Sunglasses. Style Number: 5417620. Available in stores.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 99,
                                "currency": "USD"
                            },
                            "fingerprint": "a3c548eaa4c0b639b6b8fd11ccd808a4",
                            "created_at": "2018-05-01T19:02:57.938Z",
                            "updated_at": "2018-06-23T01:24:23.167Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ba3eb77b36100f14a5a4",
                            "schema": "product",
                            "score": 0.8373895645141601,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/90/06/900657cf03bbf2df777cef167248808d_best.jpg",
                                    "width": 285,
                                    "height": 276
                                }
                            ],
                            "data": {
                                "id": "719127092_Grey:719127092:Grey:https://www.shopstyle.com/g/men/gucci/57mm-square-sunglasses",
                                "sku": "719127092",
                                "name": "Gucci 57MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/gucci/57mm-square-sunglasses",
                                "brand": "Gucci",
                                "color": "Grey",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Must-have sunglasses elevated with abstract design on temples 57mm lens width; 17mm bridge width; 145mm temple length Case and cleaning cloth included Gradient lenses Plastic Made in Italy",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 290,
                                "currency": "USD"
                            },
                            "fingerprint": "78da1add68eb7ed04ac2648a098b9634",
                            "created_at": "2018-05-01T19:04:30.788Z",
                            "updated_at": "2018-06-18T15:24:04.833Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8f13a6efb8d3d2f86d331",
                            "schema": "product",
                            "score": 0.8364857292175293,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/f4/24/f424f170028130dff8dd8e1fe794bf9a_best.jpg",
                                    "width": 720,
                                    "height": 245
                                }
                            ],
                            "data": {
                                "id": "642655114_CLEAR/BLACK PRIZM POLARIZED:642655114:CLEAR/BLACK PRIZM POLARIZED:https://www.shopstyle.com/g/men/oakley/polarized-turbine-rotor-prizm-sunglasses-oo9307-32",
                                "sku": "642655114",
                                "name": "Oakley Polarized Turbine Rotor Prizm Sunglasses, OO9307 32",
                                "url": "https://www.shopstyle.com/g/men/oakley/polarized-turbine-rotor-prizm-sunglasses-oo9307-32",
                                "brand": "Oakley",
                                "color": "CLEAR/BLACK PRIZM POLARIZED",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Devoted to making the best sunglasses on the planet by letting invention lead the way. With a passion to reinvent from scratch, Oakley sunglasses defy convention and set the standard for design, performance, and protection by wrapping innovation in art.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 203,
                                "currency": "USD"
                            },
                            "fingerprint": "45a0a8086aae0c556320c0e597f96e46",
                            "created_at": "2018-05-01T22:59:06.438Z",
                            "updated_at": "2018-06-25T18:20:08.836Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-4",
            "index": 4,
            "timestamp": 800,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000005.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "8274311e-2cc4-4238-a298-480eae83996f",
                    "score": 0.9494697478684512,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 271.2268981933594,
                        "y": 329.8352355957031,
                        "width": 60.25457763671875,
                        "height": 16.54736328125
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c26d6efb8d3d2f80ccba",
                            "schema": "product",
                            "score": 0.8650787830352783,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/56/7a/567a6cddec9b5b7b582d42e820acbe66_best.jpg",
                                    "width": 720,
                                    "height": 858
                                }
                            ],
                            "data": {
                                "id": "717496724_White:717496724:White:https://www.shopstyle.com/g/women/sonic-editions/john-cale-lou-reed-and-warhol-by-waring-abbott-framed",
                                "sku": "717496724",
                                "name": "Sonic Editions John Cale, Lou Reed and Warhol by Waring Abbott (Framed)",
                                "url": "https://www.shopstyle.com/g/women/sonic-editions/john-cale-lou-reed-and-warhol-by-waring-abbott-framed",
                                "brand": "Sonic Editions",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "John Cale, Lou Reed and Warhol Framed Print: *Framed art print *Photograph of John Cale and Lou Reed, at a photo shoot in New York City. Photo of Andy Warhol in background. *Photograph by Waring Abbott *Quality digital C-Type prints *High-quality handmade wooden frame *Acid-free and cut to order mount *Shatter resistant Perspex",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "12x16",
                                "age_group": "adult",
                                "stock": true,
                                "price": 195,
                                "currency": "USD"
                            },
                            "fingerprint": "ce7e97654f9748b989ec4140ecbb1aeb",
                            "created_at": "2018-05-01T19:39:25.326Z",
                            "updated_at": "2018-06-18T22:18:09.053Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae900666efb8d3d2f8f36fe",
                            "schema": "product",
                            "score": 0.8461506271362305,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/35/1c/351c70b19c5522e1c5e04450ebc7876f_best.jpg",
                                    "width": 272,
                                    "height": 273
                                }
                            ],
                            "data": {
                                "id": "648557865_Black:648557865:Black:https://www.shopstyle.com/g/men/christian-dior/walk-51mm-square-sunglasses",
                                "sku": "648557865",
                                "name": "Christian Dior Walk 51MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/christian-dior/walk-51mm-square-sunglasses",
                                "brand": "Christian Dior",
                                "color": "Black",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Transparent square frame with contrast arms.51mm lens width; 19mm bridge width; 150mm temple length.100% UV protection. Acetate. Made in Italy.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "ONE SIZE",
                                "age_group": "adult",
                                "stock": true,
                                "price": 320,
                                "currency": "USD"
                            },
                            "fingerprint": "ba88d3f527c7eb81659e20c9b172248a",
                            "created_at": "2018-05-02T00:03:50.172Z",
                            "updated_at": "2018-06-23T01:25:09.460Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d12a1393dd3d291213a2",
                            "schema": "product",
                            "score": 0.8415222072601318,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/dc/c3/dcc3d05785f618419e95a64ddb41cf81_best.jpg",
                                    "width": 720,
                                    "height": 344
                                }
                            ],
                            "data": {
                                "id": "689036426_BLACK:689036426:BLACK:https://www.shopstyle.com/g/women/tom-ford/womens-ft-9280-50f-59mm-sunglasses",
                                "sku": "689036426",
                                "name": "Tom Ford Women's Ft_9280_50F 59Mm Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/tom-ford/womens-ft-9280-50f-59mm-sunglasses",
                                "brand": "Tom Ford",
                                "color": "BLACK",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Frame shape: cat eye Frame color: striped brown Lens color: gradient brown These frames flatter those with an oval, round, diamond, triangle or oblong shaped face Plastic lenses with 100% UV protection Lens: 59mm wide Bridge: 16mm wide Arms: 135mm long Case included; case size and color may vary Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "NoSize",
                                "age_group": "adult",
                                "stock": true,
                                "price": 450,
                                "currency": "USD"
                            },
                            "fingerprint": "918350b9a0677055a8d8b10f8bbb6f72",
                            "created_at": "2018-05-01T20:42:18.071Z",
                            "updated_at": "2018-06-19T01:43:07.001Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b4c060cf5e48d63ce2c4",
                            "schema": "product",
                            "score": 0.8414638328552246,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/52/45/524587dc6baca3f3cadeaa65967b90db_best.jpg",
                                    "width": 490,
                                    "height": 328
                                }
                            ],
                            "data": {
                                "id": "664398819:664398819::https://www.shopstyle.com/p/rikki-knight-hipster-giraffes-with-colorful-glasses-design-compact-mirror/664398819",
                                "sku": "664398819",
                                "name": "Rikki Knight Hipster Giraffes with Colorful Glasses Design Compact Mirror",
                                "url": "https://www.shopstyle.com/p/rikki-knight-hipster-giraffes-with-colorful-glasses-design-compact-mirror/664398819",
                                "brand": "Rikki Knight",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "This Rikki Knight Hipster Giraffes with colorful glasses Compact Mirror is the perfect sized mirror to slip into your pocket, purse, bag, pencil case, or any bag and features quality vibrant Images that will not fade with time. The compact has two mirrors on the inside and features an easy hinged open close latch. If you are planning to buy this for someone else once you see it, it will be hard to part from it. When you purchase a Rikki Knight Brand you can count on satisfaction; This Rikki knight Hipster Giraffes with colorful glasses could be an ideal gift for all occasions; Disclaimer: All images are printed directly into the material - The Texture of the image will always be a flat surface. Any images that appears to be 3d, textured, or glittery are flat printed images and are proudly printed in the USA.",
                                "category": "Women > Beauty > Tools > Mirrors |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 17.95,
                                "currency": "USD"
                            },
                            "fingerprint": "6fbd2148d02f137fdb4df9e3a7eac338",
                            "created_at": "2018-05-01T00:28:48.506Z",
                            "updated_at": "2018-06-18T20:18:08.446Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e765b77b36100f19e9e0",
                            "schema": "product",
                            "score": 0.8406785488128662,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/7d/26/7d26d5011cb95aec276e4e17d606a687_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "716846003_Silver/Solid Grey:716846003:Silver/Solid Grey:https://www.shopstyle.com/g/women/saint-laurent/sl-182-metal-betty-sunglasses",
                                "sku": "716846003",
                                "name": "Saint Laurent SL 182 Metal Betty Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/sl-182-metal-betty-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "Silver/Solid Grey",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Mirrored frames Soft case Cleaning cloth included Shield frame Non-polarized lenses Made in Italy Style # YVESL20164 Measurements Width: 6in / 15cm Height: 2.5in / 6.5cm Lens Width: 68mm",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 530,
                                "currency": "USD"
                            },
                            "fingerprint": "ea9286cb4b85b77892c450bb6abf01f8",
                            "created_at": "2018-05-01T22:17:09.854Z",
                            "updated_at": "2018-06-26T23:42:19.889Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fadc6efb8d3d2f8c3c46",
                            "schema": "product",
                            "score": 0.8389666557312012,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/38/da/38daa07c656265015e558b0f9d9c150d_best.jpg",
                                    "width": 336,
                                    "height": 328
                                }
                            ],
                            "data": {
                                "id": "651083859_White:651083859:White:https://www.shopstyle.com/g/men/nike/legend-65mm-multi-sport-sunglasses",
                                "sku": "651083859",
                                "name": "Nike Legend 65mm Multi-Sport Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/nike/legend-65mm-multi-sport-sunglasses",
                                "brand": "Nike",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Lightweight, durable and versatile enough to keep the sun out of your eyes in any outdoor activity, these action-ready sunglasses are constructed with Nike Max Optics to provide enhanced clarity from every angle in an 8-base sport-wrap frame for maximum coverage. High-tension temple hinges ensure a stay-put fit that makes these shades an ideal choice for runs, workouts and more. Style Name:Nike Legend 65Mm Multi-Sport Sunglasses. Style Number: 5417633. Available in stores.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 79,
                                "currency": "USD"
                            },
                            "fingerprint": "70ea91c7ba2507d37a5933b3991212bc",
                            "created_at": "2018-05-01T23:40:12.209Z",
                            "updated_at": "2018-06-23T01:25:09.223Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0f1b77b36100f161f43",
                            "schema": "product",
                            "score": 0.8382883453369141,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5e/3f/5e3faee81a2349c095deb113b51fa4b8_best.jpg",
                                    "width": 496,
                                    "height": 224
                                }
                            ],
                            "data": {
                                "id": "437780163_White:437780163:White:https://www.shopstyle.com/p/kreed-mens-makin-it-big-square-sunglasses/437780163",
                                "sku": "437780163",
                                "name": "Kreed Men's Makin It Big Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/kreed-mens-makin-it-big-square-sunglasses/437780163",
                                "brand": "Kreed",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "You can never go wrong with a classic. You canâ€TMt help but feel great wearing this 80s-inspired wayfarer frame!",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "57 mm",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35,
                                "currency": "USD"
                            },
                            "fingerprint": "aa8f881b8362e3383c2e5c310039394e",
                            "created_at": "2018-05-01T21:49:37.971Z",
                            "updated_at": "2018-06-23T01:24:27.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b9e1b77b36100f148c61",
                            "schema": "product",
                            "score": 0.8378244590759277,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/8b/37/8b378c307289256ca668069717f71b7d_best.jpg",
                                    "width": 350,
                                    "height": 322
                                }
                            ],
                            "data": {
                                "id": "651083831_Green:651083831:Green:https://www.shopstyle.com/g/men/nike/legend-65mm-mirrored-multi-sport-sunglasses",
                                "sku": "651083831",
                                "name": "Nike Legend 65mm Mirrored Multi-Sport Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/nike/legend-65mm-mirrored-multi-sport-sunglasses",
                                "brand": "Nike",
                                "color": "Green",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Lightweight, durable and versatile enough to keep the sun out of your eyes in any outdoor activity, these cool reflective sunglasses are constructed with Nike Max Optics to provide enhanced clarity from every angle in an 8-base sport-wrap frame for maximum coverage. High-tension temple hinges ensure a stay-put fit that makes these shades an ideal choice for runs, workouts and more. Style Name:Nike Legend 65Mm Mirrored Multi-Sport Sunglasses. Style Number: 5417620. Available in stores.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 99,
                                "currency": "USD"
                            },
                            "fingerprint": "a3c548eaa4c0b639b6b8fd11ccd808a4",
                            "created_at": "2018-05-01T19:02:57.938Z",
                            "updated_at": "2018-06-23T01:24:23.167Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ba3eb77b36100f14a5a4",
                            "schema": "product",
                            "score": 0.8373895645141601,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/90/06/900657cf03bbf2df777cef167248808d_best.jpg",
                                    "width": 285,
                                    "height": 276
                                }
                            ],
                            "data": {
                                "id": "719127092_Grey:719127092:Grey:https://www.shopstyle.com/g/men/gucci/57mm-square-sunglasses",
                                "sku": "719127092",
                                "name": "Gucci 57MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/gucci/57mm-square-sunglasses",
                                "brand": "Gucci",
                                "color": "Grey",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Must-have sunglasses elevated with abstract design on temples 57mm lens width; 17mm bridge width; 145mm temple length Case and cleaning cloth included Gradient lenses Plastic Made in Italy",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 290,
                                "currency": "USD"
                            },
                            "fingerprint": "78da1add68eb7ed04ac2648a098b9634",
                            "created_at": "2018-05-01T19:04:30.788Z",
                            "updated_at": "2018-06-18T15:24:04.833Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8f13a6efb8d3d2f86d331",
                            "schema": "product",
                            "score": 0.8364857292175293,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/f4/24/f424f170028130dff8dd8e1fe794bf9a_best.jpg",
                                    "width": 720,
                                    "height": 245
                                }
                            ],
                            "data": {
                                "id": "642655114_CLEAR/BLACK PRIZM POLARIZED:642655114:CLEAR/BLACK PRIZM POLARIZED:https://www.shopstyle.com/g/men/oakley/polarized-turbine-rotor-prizm-sunglasses-oo9307-32",
                                "sku": "642655114",
                                "name": "Oakley Polarized Turbine Rotor Prizm Sunglasses, OO9307 32",
                                "url": "https://www.shopstyle.com/g/men/oakley/polarized-turbine-rotor-prizm-sunglasses-oo9307-32",
                                "brand": "Oakley",
                                "color": "CLEAR/BLACK PRIZM POLARIZED",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Devoted to making the best sunglasses on the planet by letting invention lead the way. With a passion to reinvent from scratch, Oakley sunglasses defy convention and set the standard for design, performance, and protection by wrapping innovation in art.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 203,
                                "currency": "USD"
                            },
                            "fingerprint": "45a0a8086aae0c556320c0e597f96e46",
                            "created_at": "2018-05-01T22:59:06.438Z",
                            "updated_at": "2018-06-25T18:20:08.836Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-5",
            "index": 5,
            "timestamp": 1000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000006.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "4525fd81-b079-444b-aed9-404727d72874",
                    "score": 0.9494697478684512,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 274.8287048339844,
                        "y": 292.3988342285156,
                        "width": 66.109619140625,
                        "height": 17.874755859375
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c26d6efb8d3d2f80ccba",
                            "schema": "product",
                            "score": 0.8650787830352783,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/56/7a/567a6cddec9b5b7b582d42e820acbe66_best.jpg",
                                    "width": 720,
                                    "height": 858
                                }
                            ],
                            "data": {
                                "id": "717496724_White:717496724:White:https://www.shopstyle.com/g/women/sonic-editions/john-cale-lou-reed-and-warhol-by-waring-abbott-framed",
                                "sku": "717496724",
                                "name": "Sonic Editions John Cale, Lou Reed and Warhol by Waring Abbott (Framed)",
                                "url": "https://www.shopstyle.com/g/women/sonic-editions/john-cale-lou-reed-and-warhol-by-waring-abbott-framed",
                                "brand": "Sonic Editions",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "John Cale, Lou Reed and Warhol Framed Print: *Framed art print *Photograph of John Cale and Lou Reed, at a photo shoot in New York City. Photo of Andy Warhol in background. *Photograph by Waring Abbott *Quality digital C-Type prints *High-quality handmade wooden frame *Acid-free and cut to order mount *Shatter resistant Perspex",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "12x16",
                                "age_group": "adult",
                                "stock": true,
                                "price": 195,
                                "currency": "USD"
                            },
                            "fingerprint": "ce7e97654f9748b989ec4140ecbb1aeb",
                            "created_at": "2018-05-01T19:39:25.326Z",
                            "updated_at": "2018-06-18T22:18:09.053Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae900666efb8d3d2f8f36fe",
                            "schema": "product",
                            "score": 0.8461506271362305,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/35/1c/351c70b19c5522e1c5e04450ebc7876f_best.jpg",
                                    "width": 272,
                                    "height": 273
                                }
                            ],
                            "data": {
                                "id": "648557865_Black:648557865:Black:https://www.shopstyle.com/g/men/christian-dior/walk-51mm-square-sunglasses",
                                "sku": "648557865",
                                "name": "Christian Dior Walk 51MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/christian-dior/walk-51mm-square-sunglasses",
                                "brand": "Christian Dior",
                                "color": "Black",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Transparent square frame with contrast arms.51mm lens width; 19mm bridge width; 150mm temple length.100% UV protection. Acetate. Made in Italy.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "ONE SIZE",
                                "age_group": "adult",
                                "stock": true,
                                "price": 320,
                                "currency": "USD"
                            },
                            "fingerprint": "ba88d3f527c7eb81659e20c9b172248a",
                            "created_at": "2018-05-02T00:03:50.172Z",
                            "updated_at": "2018-06-23T01:25:09.460Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d12a1393dd3d291213a2",
                            "schema": "product",
                            "score": 0.8415222072601318,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/dc/c3/dcc3d05785f618419e95a64ddb41cf81_best.jpg",
                                    "width": 720,
                                    "height": 344
                                }
                            ],
                            "data": {
                                "id": "689036426_BLACK:689036426:BLACK:https://www.shopstyle.com/g/women/tom-ford/womens-ft-9280-50f-59mm-sunglasses",
                                "sku": "689036426",
                                "name": "Tom Ford Women's Ft_9280_50F 59Mm Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/tom-ford/womens-ft-9280-50f-59mm-sunglasses",
                                "brand": "Tom Ford",
                                "color": "BLACK",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Frame shape: cat eye Frame color: striped brown Lens color: gradient brown These frames flatter those with an oval, round, diamond, triangle or oblong shaped face Plastic lenses with 100% UV protection Lens: 59mm wide Bridge: 16mm wide Arms: 135mm long Case included; case size and color may vary Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "NoSize",
                                "age_group": "adult",
                                "stock": true,
                                "price": 450,
                                "currency": "USD"
                            },
                            "fingerprint": "918350b9a0677055a8d8b10f8bbb6f72",
                            "created_at": "2018-05-01T20:42:18.071Z",
                            "updated_at": "2018-06-19T01:43:07.001Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b4c060cf5e48d63ce2c4",
                            "schema": "product",
                            "score": 0.8414638328552246,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/52/45/524587dc6baca3f3cadeaa65967b90db_best.jpg",
                                    "width": 490,
                                    "height": 328
                                }
                            ],
                            "data": {
                                "id": "664398819:664398819::https://www.shopstyle.com/p/rikki-knight-hipster-giraffes-with-colorful-glasses-design-compact-mirror/664398819",
                                "sku": "664398819",
                                "name": "Rikki Knight Hipster Giraffes with Colorful Glasses Design Compact Mirror",
                                "url": "https://www.shopstyle.com/p/rikki-knight-hipster-giraffes-with-colorful-glasses-design-compact-mirror/664398819",
                                "brand": "Rikki Knight",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "This Rikki Knight Hipster Giraffes with colorful glasses Compact Mirror is the perfect sized mirror to slip into your pocket, purse, bag, pencil case, or any bag and features quality vibrant Images that will not fade with time. The compact has two mirrors on the inside and features an easy hinged open close latch. If you are planning to buy this for someone else once you see it, it will be hard to part from it. When you purchase a Rikki Knight Brand you can count on satisfaction; This Rikki knight Hipster Giraffes with colorful glasses could be an ideal gift for all occasions; Disclaimer: All images are printed directly into the material - The Texture of the image will always be a flat surface. Any images that appears to be 3d, textured, or glittery are flat printed images and are proudly printed in the USA.",
                                "category": "Women > Beauty > Tools > Mirrors |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 17.95,
                                "currency": "USD"
                            },
                            "fingerprint": "6fbd2148d02f137fdb4df9e3a7eac338",
                            "created_at": "2018-05-01T00:28:48.506Z",
                            "updated_at": "2018-06-18T20:18:08.446Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e765b77b36100f19e9e0",
                            "schema": "product",
                            "score": 0.8406785488128662,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/7d/26/7d26d5011cb95aec276e4e17d606a687_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "716846003_Silver/Solid Grey:716846003:Silver/Solid Grey:https://www.shopstyle.com/g/women/saint-laurent/sl-182-metal-betty-sunglasses",
                                "sku": "716846003",
                                "name": "Saint Laurent SL 182 Metal Betty Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/sl-182-metal-betty-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "Silver/Solid Grey",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Mirrored frames Soft case Cleaning cloth included Shield frame Non-polarized lenses Made in Italy Style # YVESL20164 Measurements Width: 6in / 15cm Height: 2.5in / 6.5cm Lens Width: 68mm",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 530,
                                "currency": "USD"
                            },
                            "fingerprint": "ea9286cb4b85b77892c450bb6abf01f8",
                            "created_at": "2018-05-01T22:17:09.854Z",
                            "updated_at": "2018-06-26T23:42:19.889Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fadc6efb8d3d2f8c3c46",
                            "schema": "product",
                            "score": 0.8389666557312012,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/38/da/38daa07c656265015e558b0f9d9c150d_best.jpg",
                                    "width": 336,
                                    "height": 328
                                }
                            ],
                            "data": {
                                "id": "651083859_White:651083859:White:https://www.shopstyle.com/g/men/nike/legend-65mm-multi-sport-sunglasses",
                                "sku": "651083859",
                                "name": "Nike Legend 65mm Multi-Sport Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/nike/legend-65mm-multi-sport-sunglasses",
                                "brand": "Nike",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Lightweight, durable and versatile enough to keep the sun out of your eyes in any outdoor activity, these action-ready sunglasses are constructed with Nike Max Optics to provide enhanced clarity from every angle in an 8-base sport-wrap frame for maximum coverage. High-tension temple hinges ensure a stay-put fit that makes these shades an ideal choice for runs, workouts and more. Style Name:Nike Legend 65Mm Multi-Sport Sunglasses. Style Number: 5417633. Available in stores.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 79,
                                "currency": "USD"
                            },
                            "fingerprint": "70ea91c7ba2507d37a5933b3991212bc",
                            "created_at": "2018-05-01T23:40:12.209Z",
                            "updated_at": "2018-06-23T01:25:09.223Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0f1b77b36100f161f43",
                            "schema": "product",
                            "score": 0.8382883453369141,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5e/3f/5e3faee81a2349c095deb113b51fa4b8_best.jpg",
                                    "width": 496,
                                    "height": 224
                                }
                            ],
                            "data": {
                                "id": "437780163_White:437780163:White:https://www.shopstyle.com/p/kreed-mens-makin-it-big-square-sunglasses/437780163",
                                "sku": "437780163",
                                "name": "Kreed Men's Makin It Big Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/kreed-mens-makin-it-big-square-sunglasses/437780163",
                                "brand": "Kreed",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "You can never go wrong with a classic. You canâ€TMt help but feel great wearing this 80s-inspired wayfarer frame!",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "57 mm",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35,
                                "currency": "USD"
                            },
                            "fingerprint": "aa8f881b8362e3383c2e5c310039394e",
                            "created_at": "2018-05-01T21:49:37.971Z",
                            "updated_at": "2018-06-23T01:24:27.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b9e1b77b36100f148c61",
                            "schema": "product",
                            "score": 0.8378244590759277,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/8b/37/8b378c307289256ca668069717f71b7d_best.jpg",
                                    "width": 350,
                                    "height": 322
                                }
                            ],
                            "data": {
                                "id": "651083831_Green:651083831:Green:https://www.shopstyle.com/g/men/nike/legend-65mm-mirrored-multi-sport-sunglasses",
                                "sku": "651083831",
                                "name": "Nike Legend 65mm Mirrored Multi-Sport Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/nike/legend-65mm-mirrored-multi-sport-sunglasses",
                                "brand": "Nike",
                                "color": "Green",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Lightweight, durable and versatile enough to keep the sun out of your eyes in any outdoor activity, these cool reflective sunglasses are constructed with Nike Max Optics to provide enhanced clarity from every angle in an 8-base sport-wrap frame for maximum coverage. High-tension temple hinges ensure a stay-put fit that makes these shades an ideal choice for runs, workouts and more. Style Name:Nike Legend 65Mm Mirrored Multi-Sport Sunglasses. Style Number: 5417620. Available in stores.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 99,
                                "currency": "USD"
                            },
                            "fingerprint": "a3c548eaa4c0b639b6b8fd11ccd808a4",
                            "created_at": "2018-05-01T19:02:57.938Z",
                            "updated_at": "2018-06-23T01:24:23.167Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ba3eb77b36100f14a5a4",
                            "schema": "product",
                            "score": 0.8373895645141601,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/90/06/900657cf03bbf2df777cef167248808d_best.jpg",
                                    "width": 285,
                                    "height": 276
                                }
                            ],
                            "data": {
                                "id": "719127092_Grey:719127092:Grey:https://www.shopstyle.com/g/men/gucci/57mm-square-sunglasses",
                                "sku": "719127092",
                                "name": "Gucci 57MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/gucci/57mm-square-sunglasses",
                                "brand": "Gucci",
                                "color": "Grey",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Must-have sunglasses elevated with abstract design on temples 57mm lens width; 17mm bridge width; 145mm temple length Case and cleaning cloth included Gradient lenses Plastic Made in Italy",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 290,
                                "currency": "USD"
                            },
                            "fingerprint": "78da1add68eb7ed04ac2648a098b9634",
                            "created_at": "2018-05-01T19:04:30.788Z",
                            "updated_at": "2018-06-18T15:24:04.833Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8f13a6efb8d3d2f86d331",
                            "schema": "product",
                            "score": 0.8364857292175293,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/f4/24/f424f170028130dff8dd8e1fe794bf9a_best.jpg",
                                    "width": 720,
                                    "height": 245
                                }
                            ],
                            "data": {
                                "id": "642655114_CLEAR/BLACK PRIZM POLARIZED:642655114:CLEAR/BLACK PRIZM POLARIZED:https://www.shopstyle.com/g/men/oakley/polarized-turbine-rotor-prizm-sunglasses-oo9307-32",
                                "sku": "642655114",
                                "name": "Oakley Polarized Turbine Rotor Prizm Sunglasses, OO9307 32",
                                "url": "https://www.shopstyle.com/g/men/oakley/polarized-turbine-rotor-prizm-sunglasses-oo9307-32",
                                "brand": "Oakley",
                                "color": "CLEAR/BLACK PRIZM POLARIZED",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Devoted to making the best sunglasses on the planet by letting invention lead the way. With a passion to reinvent from scratch, Oakley sunglasses defy convention and set the standard for design, performance, and protection by wrapping innovation in art.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 203,
                                "currency": "USD"
                            },
                            "fingerprint": "45a0a8086aae0c556320c0e597f96e46",
                            "created_at": "2018-05-01T22:59:06.438Z",
                            "updated_at": "2018-06-25T18:20:08.836Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-6",
            "index": 6,
            "timestamp": 1200,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000007.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-7",
            "index": 7,
            "timestamp": 1400,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000008.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-8",
            "index": 8,
            "timestamp": 1600,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000009.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-9",
            "index": 9,
            "timestamp": 1800,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000010.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-10",
            "index": 10,
            "timestamp": 2000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000011.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-11",
            "index": 11,
            "timestamp": 2200,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000012.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "66b5fe78-3e8e-4353-ae53-51da9542b412",
                    "score": 0.9494697478684512,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 136.15625,
                        "y": 167.3686065673828,
                        "width": 57.164398193359375,
                        "height": 15.64117431640625
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c26d6efb8d3d2f80ccba",
                            "schema": "product",
                            "score": 0.8650787830352783,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/56/7a/567a6cddec9b5b7b582d42e820acbe66_best.jpg",
                                    "width": 720,
                                    "height": 858
                                }
                            ],
                            "data": {
                                "id": "717496724_White:717496724:White:https://www.shopstyle.com/g/women/sonic-editions/john-cale-lou-reed-and-warhol-by-waring-abbott-framed",
                                "sku": "717496724",
                                "name": "Sonic Editions John Cale, Lou Reed and Warhol by Waring Abbott (Framed)",
                                "url": "https://www.shopstyle.com/g/women/sonic-editions/john-cale-lou-reed-and-warhol-by-waring-abbott-framed",
                                "brand": "Sonic Editions",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "John Cale, Lou Reed and Warhol Framed Print: *Framed art print *Photograph of John Cale and Lou Reed, at a photo shoot in New York City. Photo of Andy Warhol in background. *Photograph by Waring Abbott *Quality digital C-Type prints *High-quality handmade wooden frame *Acid-free and cut to order mount *Shatter resistant Perspex",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "12x16",
                                "age_group": "adult",
                                "stock": true,
                                "price": 195,
                                "currency": "USD"
                            },
                            "fingerprint": "ce7e97654f9748b989ec4140ecbb1aeb",
                            "created_at": "2018-05-01T19:39:25.326Z",
                            "updated_at": "2018-06-18T22:18:09.053Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae900666efb8d3d2f8f36fe",
                            "schema": "product",
                            "score": 0.8461506271362305,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/35/1c/351c70b19c5522e1c5e04450ebc7876f_best.jpg",
                                    "width": 272,
                                    "height": 273
                                }
                            ],
                            "data": {
                                "id": "648557865_Black:648557865:Black:https://www.shopstyle.com/g/men/christian-dior/walk-51mm-square-sunglasses",
                                "sku": "648557865",
                                "name": "Christian Dior Walk 51MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/christian-dior/walk-51mm-square-sunglasses",
                                "brand": "Christian Dior",
                                "color": "Black",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Transparent square frame with contrast arms.51mm lens width; 19mm bridge width; 150mm temple length.100% UV protection. Acetate. Made in Italy.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "ONE SIZE",
                                "age_group": "adult",
                                "stock": true,
                                "price": 320,
                                "currency": "USD"
                            },
                            "fingerprint": "ba88d3f527c7eb81659e20c9b172248a",
                            "created_at": "2018-05-02T00:03:50.172Z",
                            "updated_at": "2018-06-23T01:25:09.460Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d12a1393dd3d291213a2",
                            "schema": "product",
                            "score": 0.8415222072601318,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/dc/c3/dcc3d05785f618419e95a64ddb41cf81_best.jpg",
                                    "width": 720,
                                    "height": 344
                                }
                            ],
                            "data": {
                                "id": "689036426_BLACK:689036426:BLACK:https://www.shopstyle.com/g/women/tom-ford/womens-ft-9280-50f-59mm-sunglasses",
                                "sku": "689036426",
                                "name": "Tom Ford Women's Ft_9280_50F 59Mm Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/tom-ford/womens-ft-9280-50f-59mm-sunglasses",
                                "brand": "Tom Ford",
                                "color": "BLACK",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Frame shape: cat eye Frame color: striped brown Lens color: gradient brown These frames flatter those with an oval, round, diamond, triangle or oblong shaped face Plastic lenses with 100% UV protection Lens: 59mm wide Bridge: 16mm wide Arms: 135mm long Case included; case size and color may vary Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "NoSize",
                                "age_group": "adult",
                                "stock": true,
                                "price": 450,
                                "currency": "USD"
                            },
                            "fingerprint": "918350b9a0677055a8d8b10f8bbb6f72",
                            "created_at": "2018-05-01T20:42:18.071Z",
                            "updated_at": "2018-06-19T01:43:07.001Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b4c060cf5e48d63ce2c4",
                            "schema": "product",
                            "score": 0.8414638328552246,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/52/45/524587dc6baca3f3cadeaa65967b90db_best.jpg",
                                    "width": 490,
                                    "height": 328
                                }
                            ],
                            "data": {
                                "id": "664398819:664398819::https://www.shopstyle.com/p/rikki-knight-hipster-giraffes-with-colorful-glasses-design-compact-mirror/664398819",
                                "sku": "664398819",
                                "name": "Rikki Knight Hipster Giraffes with Colorful Glasses Design Compact Mirror",
                                "url": "https://www.shopstyle.com/p/rikki-knight-hipster-giraffes-with-colorful-glasses-design-compact-mirror/664398819",
                                "brand": "Rikki Knight",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "This Rikki Knight Hipster Giraffes with colorful glasses Compact Mirror is the perfect sized mirror to slip into your pocket, purse, bag, pencil case, or any bag and features quality vibrant Images that will not fade with time. The compact has two mirrors on the inside and features an easy hinged open close latch. If you are planning to buy this for someone else once you see it, it will be hard to part from it. When you purchase a Rikki Knight Brand you can count on satisfaction; This Rikki knight Hipster Giraffes with colorful glasses could be an ideal gift for all occasions; Disclaimer: All images are printed directly into the material - The Texture of the image will always be a flat surface. Any images that appears to be 3d, textured, or glittery are flat printed images and are proudly printed in the USA.",
                                "category": "Women > Beauty > Tools > Mirrors |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 17.95,
                                "currency": "USD"
                            },
                            "fingerprint": "6fbd2148d02f137fdb4df9e3a7eac338",
                            "created_at": "2018-05-01T00:28:48.506Z",
                            "updated_at": "2018-06-18T20:18:08.446Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e765b77b36100f19e9e0",
                            "schema": "product",
                            "score": 0.8406785488128662,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/7d/26/7d26d5011cb95aec276e4e17d606a687_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "716846003_Silver/Solid Grey:716846003:Silver/Solid Grey:https://www.shopstyle.com/g/women/saint-laurent/sl-182-metal-betty-sunglasses",
                                "sku": "716846003",
                                "name": "Saint Laurent SL 182 Metal Betty Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/sl-182-metal-betty-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "Silver/Solid Grey",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Mirrored frames Soft case Cleaning cloth included Shield frame Non-polarized lenses Made in Italy Style # YVESL20164 Measurements Width: 6in / 15cm Height: 2.5in / 6.5cm Lens Width: 68mm",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 530,
                                "currency": "USD"
                            },
                            "fingerprint": "ea9286cb4b85b77892c450bb6abf01f8",
                            "created_at": "2018-05-01T22:17:09.854Z",
                            "updated_at": "2018-06-26T23:42:19.889Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fadc6efb8d3d2f8c3c46",
                            "schema": "product",
                            "score": 0.8389666557312012,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/38/da/38daa07c656265015e558b0f9d9c150d_best.jpg",
                                    "width": 336,
                                    "height": 328
                                }
                            ],
                            "data": {
                                "id": "651083859_White:651083859:White:https://www.shopstyle.com/g/men/nike/legend-65mm-multi-sport-sunglasses",
                                "sku": "651083859",
                                "name": "Nike Legend 65mm Multi-Sport Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/nike/legend-65mm-multi-sport-sunglasses",
                                "brand": "Nike",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Lightweight, durable and versatile enough to keep the sun out of your eyes in any outdoor activity, these action-ready sunglasses are constructed with Nike Max Optics to provide enhanced clarity from every angle in an 8-base sport-wrap frame for maximum coverage. High-tension temple hinges ensure a stay-put fit that makes these shades an ideal choice for runs, workouts and more. Style Name:Nike Legend 65Mm Multi-Sport Sunglasses. Style Number: 5417633. Available in stores.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 79,
                                "currency": "USD"
                            },
                            "fingerprint": "70ea91c7ba2507d37a5933b3991212bc",
                            "created_at": "2018-05-01T23:40:12.209Z",
                            "updated_at": "2018-06-23T01:25:09.223Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0f1b77b36100f161f43",
                            "schema": "product",
                            "score": 0.8382883453369141,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5e/3f/5e3faee81a2349c095deb113b51fa4b8_best.jpg",
                                    "width": 496,
                                    "height": 224
                                }
                            ],
                            "data": {
                                "id": "437780163_White:437780163:White:https://www.shopstyle.com/p/kreed-mens-makin-it-big-square-sunglasses/437780163",
                                "sku": "437780163",
                                "name": "Kreed Men's Makin It Big Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/kreed-mens-makin-it-big-square-sunglasses/437780163",
                                "brand": "Kreed",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "You can never go wrong with a classic. You canâ€TMt help but feel great wearing this 80s-inspired wayfarer frame!",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "57 mm",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35,
                                "currency": "USD"
                            },
                            "fingerprint": "aa8f881b8362e3383c2e5c310039394e",
                            "created_at": "2018-05-01T21:49:37.971Z",
                            "updated_at": "2018-06-23T01:24:27.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b9e1b77b36100f148c61",
                            "schema": "product",
                            "score": 0.8378244590759277,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/8b/37/8b378c307289256ca668069717f71b7d_best.jpg",
                                    "width": 350,
                                    "height": 322
                                }
                            ],
                            "data": {
                                "id": "651083831_Green:651083831:Green:https://www.shopstyle.com/g/men/nike/legend-65mm-mirrored-multi-sport-sunglasses",
                                "sku": "651083831",
                                "name": "Nike Legend 65mm Mirrored Multi-Sport Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/nike/legend-65mm-mirrored-multi-sport-sunglasses",
                                "brand": "Nike",
                                "color": "Green",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Lightweight, durable and versatile enough to keep the sun out of your eyes in any outdoor activity, these cool reflective sunglasses are constructed with Nike Max Optics to provide enhanced clarity from every angle in an 8-base sport-wrap frame for maximum coverage. High-tension temple hinges ensure a stay-put fit that makes these shades an ideal choice for runs, workouts and more. Style Name:Nike Legend 65Mm Mirrored Multi-Sport Sunglasses. Style Number: 5417620. Available in stores.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 99,
                                "currency": "USD"
                            },
                            "fingerprint": "a3c548eaa4c0b639b6b8fd11ccd808a4",
                            "created_at": "2018-05-01T19:02:57.938Z",
                            "updated_at": "2018-06-23T01:24:23.167Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ba3eb77b36100f14a5a4",
                            "schema": "product",
                            "score": 0.8373895645141601,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/90/06/900657cf03bbf2df777cef167248808d_best.jpg",
                                    "width": 285,
                                    "height": 276
                                }
                            ],
                            "data": {
                                "id": "719127092_Grey:719127092:Grey:https://www.shopstyle.com/g/men/gucci/57mm-square-sunglasses",
                                "sku": "719127092",
                                "name": "Gucci 57MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/gucci/57mm-square-sunglasses",
                                "brand": "Gucci",
                                "color": "Grey",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Must-have sunglasses elevated with abstract design on temples 57mm lens width; 17mm bridge width; 145mm temple length Case and cleaning cloth included Gradient lenses Plastic Made in Italy",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 290,
                                "currency": "USD"
                            },
                            "fingerprint": "78da1add68eb7ed04ac2648a098b9634",
                            "created_at": "2018-05-01T19:04:30.788Z",
                            "updated_at": "2018-06-18T15:24:04.833Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8f13a6efb8d3d2f86d331",
                            "schema": "product",
                            "score": 0.8364857292175293,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/f4/24/f424f170028130dff8dd8e1fe794bf9a_best.jpg",
                                    "width": 720,
                                    "height": 245
                                }
                            ],
                            "data": {
                                "id": "642655114_CLEAR/BLACK PRIZM POLARIZED:642655114:CLEAR/BLACK PRIZM POLARIZED:https://www.shopstyle.com/g/men/oakley/polarized-turbine-rotor-prizm-sunglasses-oo9307-32",
                                "sku": "642655114",
                                "name": "Oakley Polarized Turbine Rotor Prizm Sunglasses, OO9307 32",
                                "url": "https://www.shopstyle.com/g/men/oakley/polarized-turbine-rotor-prizm-sunglasses-oo9307-32",
                                "brand": "Oakley",
                                "color": "CLEAR/BLACK PRIZM POLARIZED",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Devoted to making the best sunglasses on the planet by letting invention lead the way. With a passion to reinvent from scratch, Oakley sunglasses defy convention and set the standard for design, performance, and protection by wrapping innovation in art.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 203,
                                "currency": "USD"
                            },
                            "fingerprint": "45a0a8086aae0c556320c0e597f96e46",
                            "created_at": "2018-05-01T22:59:06.438Z",
                            "updated_at": "2018-06-25T18:20:08.836Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-12",
            "index": 12,
            "timestamp": 2400,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000013.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "6735b786-415f-40f7-b547-727c345421c3",
                    "score": 0.9494697478684512,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 144.52943420410156,
                        "y": 165.02883911132812,
                        "width": 58.795135498046875,
                        "height": 15.552093505859375
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c26d6efb8d3d2f80ccba",
                            "schema": "product",
                            "score": 0.8650787830352783,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/56/7a/567a6cddec9b5b7b582d42e820acbe66_best.jpg",
                                    "width": 720,
                                    "height": 858
                                }
                            ],
                            "data": {
                                "id": "717496724_White:717496724:White:https://www.shopstyle.com/g/women/sonic-editions/john-cale-lou-reed-and-warhol-by-waring-abbott-framed",
                                "sku": "717496724",
                                "name": "Sonic Editions John Cale, Lou Reed and Warhol by Waring Abbott (Framed)",
                                "url": "https://www.shopstyle.com/g/women/sonic-editions/john-cale-lou-reed-and-warhol-by-waring-abbott-framed",
                                "brand": "Sonic Editions",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "John Cale, Lou Reed and Warhol Framed Print: *Framed art print *Photograph of John Cale and Lou Reed, at a photo shoot in New York City. Photo of Andy Warhol in background. *Photograph by Waring Abbott *Quality digital C-Type prints *High-quality handmade wooden frame *Acid-free and cut to order mount *Shatter resistant Perspex",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "12x16",
                                "age_group": "adult",
                                "stock": true,
                                "price": 195,
                                "currency": "USD"
                            },
                            "fingerprint": "ce7e97654f9748b989ec4140ecbb1aeb",
                            "created_at": "2018-05-01T19:39:25.326Z",
                            "updated_at": "2018-06-18T22:18:09.053Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae900666efb8d3d2f8f36fe",
                            "schema": "product",
                            "score": 0.8461506271362305,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/35/1c/351c70b19c5522e1c5e04450ebc7876f_best.jpg",
                                    "width": 272,
                                    "height": 273
                                }
                            ],
                            "data": {
                                "id": "648557865_Black:648557865:Black:https://www.shopstyle.com/g/men/christian-dior/walk-51mm-square-sunglasses",
                                "sku": "648557865",
                                "name": "Christian Dior Walk 51MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/christian-dior/walk-51mm-square-sunglasses",
                                "brand": "Christian Dior",
                                "color": "Black",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Transparent square frame with contrast arms.51mm lens width; 19mm bridge width; 150mm temple length.100% UV protection. Acetate. Made in Italy.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "ONE SIZE",
                                "age_group": "adult",
                                "stock": true,
                                "price": 320,
                                "currency": "USD"
                            },
                            "fingerprint": "ba88d3f527c7eb81659e20c9b172248a",
                            "created_at": "2018-05-02T00:03:50.172Z",
                            "updated_at": "2018-06-23T01:25:09.460Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d12a1393dd3d291213a2",
                            "schema": "product",
                            "score": 0.8415222072601318,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/dc/c3/dcc3d05785f618419e95a64ddb41cf81_best.jpg",
                                    "width": 720,
                                    "height": 344
                                }
                            ],
                            "data": {
                                "id": "689036426_BLACK:689036426:BLACK:https://www.shopstyle.com/g/women/tom-ford/womens-ft-9280-50f-59mm-sunglasses",
                                "sku": "689036426",
                                "name": "Tom Ford Women's Ft_9280_50F 59Mm Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/tom-ford/womens-ft-9280-50f-59mm-sunglasses",
                                "brand": "Tom Ford",
                                "color": "BLACK",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Frame shape: cat eye Frame color: striped brown Lens color: gradient brown These frames flatter those with an oval, round, diamond, triangle or oblong shaped face Plastic lenses with 100% UV protection Lens: 59mm wide Bridge: 16mm wide Arms: 135mm long Case included; case size and color may vary Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "NoSize",
                                "age_group": "adult",
                                "stock": true,
                                "price": 450,
                                "currency": "USD"
                            },
                            "fingerprint": "918350b9a0677055a8d8b10f8bbb6f72",
                            "created_at": "2018-05-01T20:42:18.071Z",
                            "updated_at": "2018-06-19T01:43:07.001Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b4c060cf5e48d63ce2c4",
                            "schema": "product",
                            "score": 0.8414638328552246,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/52/45/524587dc6baca3f3cadeaa65967b90db_best.jpg",
                                    "width": 490,
                                    "height": 328
                                }
                            ],
                            "data": {
                                "id": "664398819:664398819::https://www.shopstyle.com/p/rikki-knight-hipster-giraffes-with-colorful-glasses-design-compact-mirror/664398819",
                                "sku": "664398819",
                                "name": "Rikki Knight Hipster Giraffes with Colorful Glasses Design Compact Mirror",
                                "url": "https://www.shopstyle.com/p/rikki-knight-hipster-giraffes-with-colorful-glasses-design-compact-mirror/664398819",
                                "brand": "Rikki Knight",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "This Rikki Knight Hipster Giraffes with colorful glasses Compact Mirror is the perfect sized mirror to slip into your pocket, purse, bag, pencil case, or any bag and features quality vibrant Images that will not fade with time. The compact has two mirrors on the inside and features an easy hinged open close latch. If you are planning to buy this for someone else once you see it, it will be hard to part from it. When you purchase a Rikki Knight Brand you can count on satisfaction; This Rikki knight Hipster Giraffes with colorful glasses could be an ideal gift for all occasions; Disclaimer: All images are printed directly into the material - The Texture of the image will always be a flat surface. Any images that appears to be 3d, textured, or glittery are flat printed images and are proudly printed in the USA.",
                                "category": "Women > Beauty > Tools > Mirrors |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 17.95,
                                "currency": "USD"
                            },
                            "fingerprint": "6fbd2148d02f137fdb4df9e3a7eac338",
                            "created_at": "2018-05-01T00:28:48.506Z",
                            "updated_at": "2018-06-18T20:18:08.446Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e765b77b36100f19e9e0",
                            "schema": "product",
                            "score": 0.8406785488128662,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/7d/26/7d26d5011cb95aec276e4e17d606a687_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "716846003_Silver/Solid Grey:716846003:Silver/Solid Grey:https://www.shopstyle.com/g/women/saint-laurent/sl-182-metal-betty-sunglasses",
                                "sku": "716846003",
                                "name": "Saint Laurent SL 182 Metal Betty Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/sl-182-metal-betty-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "Silver/Solid Grey",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Mirrored frames Soft case Cleaning cloth included Shield frame Non-polarized lenses Made in Italy Style # YVESL20164 Measurements Width: 6in / 15cm Height: 2.5in / 6.5cm Lens Width: 68mm",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 530,
                                "currency": "USD"
                            },
                            "fingerprint": "ea9286cb4b85b77892c450bb6abf01f8",
                            "created_at": "2018-05-01T22:17:09.854Z",
                            "updated_at": "2018-06-26T23:42:19.889Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fadc6efb8d3d2f8c3c46",
                            "schema": "product",
                            "score": 0.8389666557312012,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/38/da/38daa07c656265015e558b0f9d9c150d_best.jpg",
                                    "width": 336,
                                    "height": 328
                                }
                            ],
                            "data": {
                                "id": "651083859_White:651083859:White:https://www.shopstyle.com/g/men/nike/legend-65mm-multi-sport-sunglasses",
                                "sku": "651083859",
                                "name": "Nike Legend 65mm Multi-Sport Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/nike/legend-65mm-multi-sport-sunglasses",
                                "brand": "Nike",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Lightweight, durable and versatile enough to keep the sun out of your eyes in any outdoor activity, these action-ready sunglasses are constructed with Nike Max Optics to provide enhanced clarity from every angle in an 8-base sport-wrap frame for maximum coverage. High-tension temple hinges ensure a stay-put fit that makes these shades an ideal choice for runs, workouts and more. Style Name:Nike Legend 65Mm Multi-Sport Sunglasses. Style Number: 5417633. Available in stores.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 79,
                                "currency": "USD"
                            },
                            "fingerprint": "70ea91c7ba2507d37a5933b3991212bc",
                            "created_at": "2018-05-01T23:40:12.209Z",
                            "updated_at": "2018-06-23T01:25:09.223Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0f1b77b36100f161f43",
                            "schema": "product",
                            "score": 0.8382883453369141,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5e/3f/5e3faee81a2349c095deb113b51fa4b8_best.jpg",
                                    "width": 496,
                                    "height": 224
                                }
                            ],
                            "data": {
                                "id": "437780163_White:437780163:White:https://www.shopstyle.com/p/kreed-mens-makin-it-big-square-sunglasses/437780163",
                                "sku": "437780163",
                                "name": "Kreed Men's Makin It Big Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/kreed-mens-makin-it-big-square-sunglasses/437780163",
                                "brand": "Kreed",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "You can never go wrong with a classic. You canâ€TMt help but feel great wearing this 80s-inspired wayfarer frame!",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "57 mm",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35,
                                "currency": "USD"
                            },
                            "fingerprint": "aa8f881b8362e3383c2e5c310039394e",
                            "created_at": "2018-05-01T21:49:37.971Z",
                            "updated_at": "2018-06-23T01:24:27.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b9e1b77b36100f148c61",
                            "schema": "product",
                            "score": 0.8378244590759277,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/8b/37/8b378c307289256ca668069717f71b7d_best.jpg",
                                    "width": 350,
                                    "height": 322
                                }
                            ],
                            "data": {
                                "id": "651083831_Green:651083831:Green:https://www.shopstyle.com/g/men/nike/legend-65mm-mirrored-multi-sport-sunglasses",
                                "sku": "651083831",
                                "name": "Nike Legend 65mm Mirrored Multi-Sport Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/nike/legend-65mm-mirrored-multi-sport-sunglasses",
                                "brand": "Nike",
                                "color": "Green",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Lightweight, durable and versatile enough to keep the sun out of your eyes in any outdoor activity, these cool reflective sunglasses are constructed with Nike Max Optics to provide enhanced clarity from every angle in an 8-base sport-wrap frame for maximum coverage. High-tension temple hinges ensure a stay-put fit that makes these shades an ideal choice for runs, workouts and more. Style Name:Nike Legend 65Mm Mirrored Multi-Sport Sunglasses. Style Number: 5417620. Available in stores.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 99,
                                "currency": "USD"
                            },
                            "fingerprint": "a3c548eaa4c0b639b6b8fd11ccd808a4",
                            "created_at": "2018-05-01T19:02:57.938Z",
                            "updated_at": "2018-06-23T01:24:23.167Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ba3eb77b36100f14a5a4",
                            "schema": "product",
                            "score": 0.8373895645141601,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/90/06/900657cf03bbf2df777cef167248808d_best.jpg",
                                    "width": 285,
                                    "height": 276
                                }
                            ],
                            "data": {
                                "id": "719127092_Grey:719127092:Grey:https://www.shopstyle.com/g/men/gucci/57mm-square-sunglasses",
                                "sku": "719127092",
                                "name": "Gucci 57MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/gucci/57mm-square-sunglasses",
                                "brand": "Gucci",
                                "color": "Grey",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Must-have sunglasses elevated with abstract design on temples 57mm lens width; 17mm bridge width; 145mm temple length Case and cleaning cloth included Gradient lenses Plastic Made in Italy",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 290,
                                "currency": "USD"
                            },
                            "fingerprint": "78da1add68eb7ed04ac2648a098b9634",
                            "created_at": "2018-05-01T19:04:30.788Z",
                            "updated_at": "2018-06-18T15:24:04.833Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8f13a6efb8d3d2f86d331",
                            "schema": "product",
                            "score": 0.8364857292175293,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/f4/24/f424f170028130dff8dd8e1fe794bf9a_best.jpg",
                                    "width": 720,
                                    "height": 245
                                }
                            ],
                            "data": {
                                "id": "642655114_CLEAR/BLACK PRIZM POLARIZED:642655114:CLEAR/BLACK PRIZM POLARIZED:https://www.shopstyle.com/g/men/oakley/polarized-turbine-rotor-prizm-sunglasses-oo9307-32",
                                "sku": "642655114",
                                "name": "Oakley Polarized Turbine Rotor Prizm Sunglasses, OO9307 32",
                                "url": "https://www.shopstyle.com/g/men/oakley/polarized-turbine-rotor-prizm-sunglasses-oo9307-32",
                                "brand": "Oakley",
                                "color": "CLEAR/BLACK PRIZM POLARIZED",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Devoted to making the best sunglasses on the planet by letting invention lead the way. With a passion to reinvent from scratch, Oakley sunglasses defy convention and set the standard for design, performance, and protection by wrapping innovation in art.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 203,
                                "currency": "USD"
                            },
                            "fingerprint": "45a0a8086aae0c556320c0e597f96e46",
                            "created_at": "2018-05-01T22:59:06.438Z",
                            "updated_at": "2018-06-25T18:20:08.836Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-13",
            "index": 13,
            "timestamp": 2600,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000014.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "19720458-c608-4de8-a9f7-cb186dcec882",
                    "score": 0.9494697478684512,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 162.09991455078125,
                        "y": 163.30545043945312,
                        "width": 57.3685302734375,
                        "height": 15.71337890625
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c26d6efb8d3d2f80ccba",
                            "schema": "product",
                            "score": 0.8650787830352783,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/56/7a/567a6cddec9b5b7b582d42e820acbe66_best.jpg",
                                    "width": 720,
                                    "height": 858
                                }
                            ],
                            "data": {
                                "id": "717496724_White:717496724:White:https://www.shopstyle.com/g/women/sonic-editions/john-cale-lou-reed-and-warhol-by-waring-abbott-framed",
                                "sku": "717496724",
                                "name": "Sonic Editions John Cale, Lou Reed and Warhol by Waring Abbott (Framed)",
                                "url": "https://www.shopstyle.com/g/women/sonic-editions/john-cale-lou-reed-and-warhol-by-waring-abbott-framed",
                                "brand": "Sonic Editions",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "John Cale, Lou Reed and Warhol Framed Print: *Framed art print *Photograph of John Cale and Lou Reed, at a photo shoot in New York City. Photo of Andy Warhol in background. *Photograph by Waring Abbott *Quality digital C-Type prints *High-quality handmade wooden frame *Acid-free and cut to order mount *Shatter resistant Perspex",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "12x16",
                                "age_group": "adult",
                                "stock": true,
                                "price": 195,
                                "currency": "USD"
                            },
                            "fingerprint": "ce7e97654f9748b989ec4140ecbb1aeb",
                            "created_at": "2018-05-01T19:39:25.326Z",
                            "updated_at": "2018-06-18T22:18:09.053Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae900666efb8d3d2f8f36fe",
                            "schema": "product",
                            "score": 0.8461506271362305,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/35/1c/351c70b19c5522e1c5e04450ebc7876f_best.jpg",
                                    "width": 272,
                                    "height": 273
                                }
                            ],
                            "data": {
                                "id": "648557865_Black:648557865:Black:https://www.shopstyle.com/g/men/christian-dior/walk-51mm-square-sunglasses",
                                "sku": "648557865",
                                "name": "Christian Dior Walk 51MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/christian-dior/walk-51mm-square-sunglasses",
                                "brand": "Christian Dior",
                                "color": "Black",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Transparent square frame with contrast arms.51mm lens width; 19mm bridge width; 150mm temple length.100% UV protection. Acetate. Made in Italy.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "ONE SIZE",
                                "age_group": "adult",
                                "stock": true,
                                "price": 320,
                                "currency": "USD"
                            },
                            "fingerprint": "ba88d3f527c7eb81659e20c9b172248a",
                            "created_at": "2018-05-02T00:03:50.172Z",
                            "updated_at": "2018-06-23T01:25:09.460Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d12a1393dd3d291213a2",
                            "schema": "product",
                            "score": 0.8415222072601318,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/dc/c3/dcc3d05785f618419e95a64ddb41cf81_best.jpg",
                                    "width": 720,
                                    "height": 344
                                }
                            ],
                            "data": {
                                "id": "689036426_BLACK:689036426:BLACK:https://www.shopstyle.com/g/women/tom-ford/womens-ft-9280-50f-59mm-sunglasses",
                                "sku": "689036426",
                                "name": "Tom Ford Women's Ft_9280_50F 59Mm Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/tom-ford/womens-ft-9280-50f-59mm-sunglasses",
                                "brand": "Tom Ford",
                                "color": "BLACK",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Frame shape: cat eye Frame color: striped brown Lens color: gradient brown These frames flatter those with an oval, round, diamond, triangle or oblong shaped face Plastic lenses with 100% UV protection Lens: 59mm wide Bridge: 16mm wide Arms: 135mm long Case included; case size and color may vary Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "NoSize",
                                "age_group": "adult",
                                "stock": true,
                                "price": 450,
                                "currency": "USD"
                            },
                            "fingerprint": "918350b9a0677055a8d8b10f8bbb6f72",
                            "created_at": "2018-05-01T20:42:18.071Z",
                            "updated_at": "2018-06-19T01:43:07.001Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b4c060cf5e48d63ce2c4",
                            "schema": "product",
                            "score": 0.8414638328552246,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/52/45/524587dc6baca3f3cadeaa65967b90db_best.jpg",
                                    "width": 490,
                                    "height": 328
                                }
                            ],
                            "data": {
                                "id": "664398819:664398819::https://www.shopstyle.com/p/rikki-knight-hipster-giraffes-with-colorful-glasses-design-compact-mirror/664398819",
                                "sku": "664398819",
                                "name": "Rikki Knight Hipster Giraffes with Colorful Glasses Design Compact Mirror",
                                "url": "https://www.shopstyle.com/p/rikki-knight-hipster-giraffes-with-colorful-glasses-design-compact-mirror/664398819",
                                "brand": "Rikki Knight",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "This Rikki Knight Hipster Giraffes with colorful glasses Compact Mirror is the perfect sized mirror to slip into your pocket, purse, bag, pencil case, or any bag and features quality vibrant Images that will not fade with time. The compact has two mirrors on the inside and features an easy hinged open close latch. If you are planning to buy this for someone else once you see it, it will be hard to part from it. When you purchase a Rikki Knight Brand you can count on satisfaction; This Rikki knight Hipster Giraffes with colorful glasses could be an ideal gift for all occasions; Disclaimer: All images are printed directly into the material - The Texture of the image will always be a flat surface. Any images that appears to be 3d, textured, or glittery are flat printed images and are proudly printed in the USA.",
                                "category": "Women > Beauty > Tools > Mirrors |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 17.95,
                                "currency": "USD"
                            },
                            "fingerprint": "6fbd2148d02f137fdb4df9e3a7eac338",
                            "created_at": "2018-05-01T00:28:48.506Z",
                            "updated_at": "2018-06-18T20:18:08.446Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e765b77b36100f19e9e0",
                            "schema": "product",
                            "score": 0.8406785488128662,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/7d/26/7d26d5011cb95aec276e4e17d606a687_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "716846003_Silver/Solid Grey:716846003:Silver/Solid Grey:https://www.shopstyle.com/g/women/saint-laurent/sl-182-metal-betty-sunglasses",
                                "sku": "716846003",
                                "name": "Saint Laurent SL 182 Metal Betty Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/sl-182-metal-betty-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "Silver/Solid Grey",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Mirrored frames Soft case Cleaning cloth included Shield frame Non-polarized lenses Made in Italy Style # YVESL20164 Measurements Width: 6in / 15cm Height: 2.5in / 6.5cm Lens Width: 68mm",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 530,
                                "currency": "USD"
                            },
                            "fingerprint": "ea9286cb4b85b77892c450bb6abf01f8",
                            "created_at": "2018-05-01T22:17:09.854Z",
                            "updated_at": "2018-06-26T23:42:19.889Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fadc6efb8d3d2f8c3c46",
                            "schema": "product",
                            "score": 0.8389666557312012,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/38/da/38daa07c656265015e558b0f9d9c150d_best.jpg",
                                    "width": 336,
                                    "height": 328
                                }
                            ],
                            "data": {
                                "id": "651083859_White:651083859:White:https://www.shopstyle.com/g/men/nike/legend-65mm-multi-sport-sunglasses",
                                "sku": "651083859",
                                "name": "Nike Legend 65mm Multi-Sport Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/nike/legend-65mm-multi-sport-sunglasses",
                                "brand": "Nike",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Lightweight, durable and versatile enough to keep the sun out of your eyes in any outdoor activity, these action-ready sunglasses are constructed with Nike Max Optics to provide enhanced clarity from every angle in an 8-base sport-wrap frame for maximum coverage. High-tension temple hinges ensure a stay-put fit that makes these shades an ideal choice for runs, workouts and more. Style Name:Nike Legend 65Mm Multi-Sport Sunglasses. Style Number: 5417633. Available in stores.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 79,
                                "currency": "USD"
                            },
                            "fingerprint": "70ea91c7ba2507d37a5933b3991212bc",
                            "created_at": "2018-05-01T23:40:12.209Z",
                            "updated_at": "2018-06-23T01:25:09.223Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0f1b77b36100f161f43",
                            "schema": "product",
                            "score": 0.8382883453369141,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5e/3f/5e3faee81a2349c095deb113b51fa4b8_best.jpg",
                                    "width": 496,
                                    "height": 224
                                }
                            ],
                            "data": {
                                "id": "437780163_White:437780163:White:https://www.shopstyle.com/p/kreed-mens-makin-it-big-square-sunglasses/437780163",
                                "sku": "437780163",
                                "name": "Kreed Men's Makin It Big Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/kreed-mens-makin-it-big-square-sunglasses/437780163",
                                "brand": "Kreed",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "You can never go wrong with a classic. You canâ€TMt help but feel great wearing this 80s-inspired wayfarer frame!",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "57 mm",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35,
                                "currency": "USD"
                            },
                            "fingerprint": "aa8f881b8362e3383c2e5c310039394e",
                            "created_at": "2018-05-01T21:49:37.971Z",
                            "updated_at": "2018-06-23T01:24:27.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b9e1b77b36100f148c61",
                            "schema": "product",
                            "score": 0.8378244590759277,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/8b/37/8b378c307289256ca668069717f71b7d_best.jpg",
                                    "width": 350,
                                    "height": 322
                                }
                            ],
                            "data": {
                                "id": "651083831_Green:651083831:Green:https://www.shopstyle.com/g/men/nike/legend-65mm-mirrored-multi-sport-sunglasses",
                                "sku": "651083831",
                                "name": "Nike Legend 65mm Mirrored Multi-Sport Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/nike/legend-65mm-mirrored-multi-sport-sunglasses",
                                "brand": "Nike",
                                "color": "Green",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Lightweight, durable and versatile enough to keep the sun out of your eyes in any outdoor activity, these cool reflective sunglasses are constructed with Nike Max Optics to provide enhanced clarity from every angle in an 8-base sport-wrap frame for maximum coverage. High-tension temple hinges ensure a stay-put fit that makes these shades an ideal choice for runs, workouts and more. Style Name:Nike Legend 65Mm Mirrored Multi-Sport Sunglasses. Style Number: 5417620. Available in stores.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 99,
                                "currency": "USD"
                            },
                            "fingerprint": "a3c548eaa4c0b639b6b8fd11ccd808a4",
                            "created_at": "2018-05-01T19:02:57.938Z",
                            "updated_at": "2018-06-23T01:24:23.167Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ba3eb77b36100f14a5a4",
                            "schema": "product",
                            "score": 0.8373895645141601,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/90/06/900657cf03bbf2df777cef167248808d_best.jpg",
                                    "width": 285,
                                    "height": 276
                                }
                            ],
                            "data": {
                                "id": "719127092_Grey:719127092:Grey:https://www.shopstyle.com/g/men/gucci/57mm-square-sunglasses",
                                "sku": "719127092",
                                "name": "Gucci 57MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/gucci/57mm-square-sunglasses",
                                "brand": "Gucci",
                                "color": "Grey",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Must-have sunglasses elevated with abstract design on temples 57mm lens width; 17mm bridge width; 145mm temple length Case and cleaning cloth included Gradient lenses Plastic Made in Italy",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 290,
                                "currency": "USD"
                            },
                            "fingerprint": "78da1add68eb7ed04ac2648a098b9634",
                            "created_at": "2018-05-01T19:04:30.788Z",
                            "updated_at": "2018-06-18T15:24:04.833Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8f13a6efb8d3d2f86d331",
                            "schema": "product",
                            "score": 0.8364857292175293,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/f4/24/f424f170028130dff8dd8e1fe794bf9a_best.jpg",
                                    "width": 720,
                                    "height": 245
                                }
                            ],
                            "data": {
                                "id": "642655114_CLEAR/BLACK PRIZM POLARIZED:642655114:CLEAR/BLACK PRIZM POLARIZED:https://www.shopstyle.com/g/men/oakley/polarized-turbine-rotor-prizm-sunglasses-oo9307-32",
                                "sku": "642655114",
                                "name": "Oakley Polarized Turbine Rotor Prizm Sunglasses, OO9307 32",
                                "url": "https://www.shopstyle.com/g/men/oakley/polarized-turbine-rotor-prizm-sunglasses-oo9307-32",
                                "brand": "Oakley",
                                "color": "CLEAR/BLACK PRIZM POLARIZED",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Devoted to making the best sunglasses on the planet by letting invention lead the way. With a passion to reinvent from scratch, Oakley sunglasses defy convention and set the standard for design, performance, and protection by wrapping innovation in art.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 203,
                                "currency": "USD"
                            },
                            "fingerprint": "45a0a8086aae0c556320c0e597f96e46",
                            "created_at": "2018-05-01T22:59:06.438Z",
                            "updated_at": "2018-06-25T18:20:08.836Z"
                        }
                    ]
                },
                {
                    "_type": "SearchResult",
                    "_id": "e6c07516-4533-44c0-8b93-812734e432d9",
                    "score": 0.9857125679651896,
                    "category": {
                        "_type": "Category",
                        "_id": "pants",
                        "name": "pants"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 101.72368621826172,
                        "y": 396.024658203125,
                        "width": 172.77066802978516,
                        "height": 292.04052734375
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9030ea2773810155abae8",
                            "schema": "product",
                            "score": 0.9190283489227296,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/f0/0c/f00c02fbc09deb7244d0fa0e7a055f60_best.jpg",
                                    "width": 383,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "623405262_Bright blue:623405262:Bright blue:https://www.shopstyle.com/p/gallego-desportes-casual-pants/623405262",
                                "sku": "623405262",
                                "name": "GALLEGO DESPORTES Casual pants",
                                "url": "https://www.shopstyle.com/p/gallego-desportes-casual-pants/623405262",
                                "brand": "GALLEGO DESPORTES",
                                "color": "Bright blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "GALLEGO DESPORTES Casual pants. plain weave, solid color, high waisted, comfort fit, wide leg, no appliqués, 3 buttons, multipockets, pants. 100% Cotton",
                                "category": "Women > Clothing > Pants > Casual |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 220,
                                "currency": "USD"
                            },
                            "fingerprint": "762ba0ec532ef1721ae6a9be5b45a66a",
                            "created_at": "2018-05-02T00:15:10.522Z",
                            "updated_at": "2018-07-05T23:49:56.486Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e4b9b77b36100f185ad5",
                            "schema": "product",
                            "score": 0.9182834911346436,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/d7/f0/d7f062195d11e643d09e6866ab5092d3_best.jpg",
                                    "width": 406,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "709920032_Bright blue:709920032:Bright blue:https://www.shopstyle.com/g/women/etro/casual-pants",
                                "sku": "709920032",
                                "name": "Etro Casual pants",
                                "url": "https://www.shopstyle.com/g/women/etro/casual-pants",
                                "brand": "Etro",
                                "color": "Bright blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "ETRO Casual pants. plain weave, no appliqués, solid color, mid rise, regular fit, straight leg, button, zip, multipockets, stretch, tailored. 97% Cotton, 3% Elastane",
                                "category": "Women > Clothing > Pants > Casual |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 319,
                                "currency": "USD"
                            },
                            "fingerprint": "3155f444845a9ba939acca9da3502011",
                            "created_at": "2018-05-01T22:05:45.126Z",
                            "updated_at": "2018-07-06T00:04:51.865Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b7f7a27738101554fa0d",
                            "schema": "product",
                            "score": 0.9176015663146972,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/75/e2/75e244d0acac11628d6ec4c56e7ecaae_best.jpg",
                                    "width": 624,
                                    "height": 800
                                }
                            ],
                            "data": {
                                "id": "697798873_Blue:697798873:Blue:https://www.shopstyle.com/g/women/claudie-pierlot/petula-crepe-trousers",
                                "sku": "697798873",
                                "name": "Claudie Pierlot Petula crepe trousers",
                                "url": "https://www.shopstyle.com/g/women/claudie-pierlot/petula-crepe-trousers",
                                "brand": "Claudie Pierlot",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Whether it’s workwear or casualwear, the Petula trousers from Parisian brand Claudie Pierlot are guaranteed to step your outfits up a couple of notches. Crafted from crepe, this tapered pair holds its shape well and falls just above the ankle for a flattering silhouette. The belt at the front is adorned with a bold bow so deciding how to wear this style is a no brainer: a shirt tucked in, of course. Claudie Pierlot crepe trousersConcealed hook and eye and zip fastening at frontRegular fit, tapered, high rise, belt loops, belt with bow detail at waist, two slip pockets at front, pressed centre creases at front and back, two welt pockets at back, partially lined70% triacetate, 30% polyester; 100% acetate Dry cleanTrue to sizeSize 8: Rise 13”, inside leg 24\", leg opening 12\"Model is 5ft 10\" and wears a size 8",
                                "category": "Women > Clothing > Pants |  | ",
                                "size": "10",
                                "age_group": "adult",
                                "stock": true,
                                "price": 280,
                                "currency": "USD"
                            },
                            "fingerprint": "b236462ea19330febe85335b4c329803",
                            "created_at": "2018-05-01T18:54:47.609Z",
                            "updated_at": "2018-06-28T14:51:20.050Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ad04a2773810154fc342",
                            "schema": "product",
                            "score": 0.9161422061920166,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/00/2a/002a27e60cd08d1c0fe3279f5e694298_best.jpg",
                                    "width": 258,
                                    "height": 500
                                }
                            ],
                            "data": {
                                "id": "720921589_Captain America:720921589:Captain America:https://www.shopstyle.com/p/opposuits-official-marvel-comics-hero-suits-of-avengers-costume-comes-with-pants/720921589",
                                "sku": "720921589",
                                "name": "Opposuits Official Marvel Comics Hero Suits Of - Avengers Costume Comes With Pants",
                                "url": "https://www.shopstyle.com/p/opposuits-official-marvel-comics-hero-suits-of-avengers-costume-comes-with-pants/720921589",
                                "brand": "Opposuits",
                                "color": "Captain America",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Are you a real Superhero fan? In that case you've come to the right place! We offer suits with several Marvel figures on it: The Hulk, Iron Man, Spider Man and Captain-America, they're all there. This means you'll have the ability to wear some legends on your sleeve. And which Marvel lover hasn't dreamt about that? No matter which action figure suit you'll pick, with one of these avengers outfits you'll definitely steal the show on many occasions; from theme parties to Comic Con and movie premieres to Halloween. Get yours now!",
                                "category": "Men > Clothing > Pants |  | ",
                                "size": "US36",
                                "age_group": "adult",
                                "stock": true,
                                "price": 129.99,
                                "currency": "USD"
                            },
                            "fingerprint": "46e62819e12aa72126d8ab36b2747710",
                            "created_at": "2018-05-01T18:08:04.811Z",
                            "updated_at": "2018-06-26T23:32:23.400Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8faef6efb8d3d2f8c46fd",
                            "schema": "product",
                            "score": 0.9151241683959961,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/72/f2/72f2c7301e1227d2252df68671736287_best.jpg",
                                    "width": 335,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "700494461_Bright blue:700494461:Bright blue:https://www.shopstyle.com/g/women/alexander-mcqueen/mcq-casual-pants",
                                "sku": "700494461",
                                "name": "Alexander McQueen McQ Casual pants",
                                "url": "https://www.shopstyle.com/g/women/alexander-mcqueen/mcq-casual-pants",
                                "brand": "Alexander McQueen",
                                "color": "Bright blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "McQ Alexander McQueen Casual pants. crepe, solid color, mid rise, comfort fit, straight leg, no appliqués, drawstring closure, multipockets, pants, large sized. 73% Triacetate, 27% Polyester",
                                "category": "Women > Clothing > Pants > Casual |  | ",
                                "size": "0",
                                "age_group": "adult",
                                "stock": true,
                                "price": 289,
                                "currency": "USD"
                            },
                            "fingerprint": "8854dd5461b31bf1d1ac412702bcddeb",
                            "created_at": "2018-05-01T23:40:31.426Z",
                            "updated_at": "2018-06-19T00:58:08.419Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b53860cf5e48d63d2989",
                            "schema": "product",
                            "score": 0.9133417320251465,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/75/c6/75c616b1fa98736601aca80fb3442c2e_best.jpg",
                                    "width": 414,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679590214_Blue:679590214:Blue:https://www.shopstyle.com/g/women/berwich/casual-pants",
                                "sku": "679590214",
                                "name": "Berwich Casual pants",
                                "url": "https://www.shopstyle.com/g/women/berwich/casual-pants",
                                "brand": "Berwich",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "BERWICH Casual pants. plain weave, detachable application, solid color, high waisted, regular fit, tapered leg, button, zip, multipockets, pants. 100% Cotton",
                                "category": "Women > Clothing > Pants > Casual |  | ",
                                "size": "8",
                                "age_group": "adult",
                                "stock": true,
                                "price": 174,
                                "currency": "USD"
                            },
                            "fingerprint": "294469689b4f4ca4f81583931938cac1",
                            "created_at": "2018-05-01T00:30:48.142Z",
                            "updated_at": "2018-06-28T13:31:26.951Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8f4796efb8d3d2f88ae0f",
                            "schema": "product",
                            "score": 0.9126931571960449,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/ae/f1/aef18b74104a11a8d1c5d8f2dc1380b5_best.jpg",
                                    "width": 414,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "686045818_Bright blue:686045818:Bright blue:https://www.shopstyle.com/p/mirella-matteini-casual-pants/686045818",
                                "sku": "686045818",
                                "name": "MIRELLA MATTEINI Casual pants",
                                "url": "https://www.shopstyle.com/p/mirella-matteini-casual-pants/686045818",
                                "brand": "MIRELLA MATTEINI",
                                "color": "Bright blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MIRELLA MATTEINI Casual pants. crepe, sash, solid color, mid rise, regular fit, wide leg, zip, no pockets, stretch, tailored. 95% Polyester, 5% Elastane",
                                "category": "Women > Clothing > Pants > Casual |  | ",
                                "size": "6",
                                "age_group": "adult",
                                "stock": true,
                                "price": 129,
                                "currency": "USD"
                            },
                            "fingerprint": "7d1fc075d527d83556005828c888da23",
                            "created_at": "2018-05-01T23:12:57.734Z",
                            "updated_at": "2018-07-05T23:54:49.425Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e469b77b36100f182c56",
                            "schema": "product",
                            "score": 0.9125935173034668,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/31/9b/319bf9a7bdb757c53e2d3186a1d528e0_best.jpg",
                                    "width": 194,
                                    "height": 496
                                }
                            ],
                            "data": {
                                "id": "718572584_BLUE:718572584:BLUE:https://www.shopstyle.com/p/victoria-victoria-beckham-flat-front-slim-straight-leg-ankle-length-wool-pants/718572584",
                                "sku": "718572584",
                                "name": "Victoria Victoria Beckham Flat-Front Slim Straight-Leg Ankle-Length Wool Pants",
                                "url": "https://www.shopstyle.com/p/victoria-victoria-beckham-flat-front-slim-straight-leg-ankle-length-wool-pants/718572584",
                                "brand": "Victoria Victoria Beckham",
                                "color": "BLUE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Victoria Victoria Beckham wool pants. Mid-rise; belt loops. Flat hook-zip fly. Side slip pockets; back besom pockets. Slim fit through straight legs. Ankle-length. Made in UK.",
                                "category": "Women > Clothing > Pants |  | ",
                                "size": "14 UK (10 US)",
                                "age_group": "adult",
                                "stock": true,
                                "price": 540,
                                "currency": "USD"
                            },
                            "fingerprint": "cb1376fae08877abf9b0d3dc0941f3a3",
                            "created_at": "2018-05-01T22:04:25.508Z",
                            "updated_at": "2018-07-06T00:20:07.089Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d99a1393dd3d29169e13",
                            "schema": "product",
                            "score": 0.912486400604248,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/3b/c6/3bc6315fed804da2e6f155dd103a1a6e_best.jpg",
                                    "width": 364,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "680250334_Bright blue:680250334:Bright blue:https://www.shopstyle.com/g/women/moschino/casual-pants",
                                "sku": "680250334",
                                "name": "Moschino Casual pants",
                                "url": "https://www.shopstyle.com/g/women/moschino/casual-pants",
                                "brand": "Moschino",
                                "color": "Bright blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "BOUTIQUE MOSCHINO Casual pants. plain weave, no appliqués, basic solid color, mid rise, comfort fit, wide leg, button, zip, multipockets, cuffed hems, tailored. 96% Cotton, 4% Other Fibres",
                                "category": "Women > Clothing > Pants > Casual |  | ",
                                "size": "4",
                                "age_group": "adult",
                                "stock": true,
                                "price": 369,
                                "currency": "USD"
                            },
                            "fingerprint": "9b12172ac1e451af4b070539eed07fee",
                            "created_at": "2018-05-01T21:18:18.097Z",
                            "updated_at": "2018-07-06T00:23:08.878Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b66c60cf5e48d63dd64e",
                            "schema": "product",
                            "score": 0.9112092971801757,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b5/65/b565d67169208bbad2afeae29211c1be_best.jpg",
                                    "width": 202,
                                    "height": 616
                                }
                            ],
                            "data": {
                                "id": "693388822_blue:693388822:blue:https://www.shopstyle.com/g/women/joseph/crepe-carrot-trousers",
                                "sku": "693388822",
                                "name": "Joseph Crepe Carrot Trousers",
                                "url": "https://www.shopstyle.com/g/women/joseph/crepe-carrot-trousers",
                                "brand": "Joseph",
                                "color": "blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Royal blue pants in a soft and comfortable carrot shape. Make sure to cinch your waist with the accompanying black belt. 64% Viscose, 33% Acetate, 3% Elastane",
                                "category": "Women > Clothing > Pants |  | ",
                                "size": "FR36 - US4",
                                "age_group": "adult",
                                "stock": true,
                                "price": 550,
                                "currency": "USD"
                            },
                            "fingerprint": "2cb3250353d67742528eb81721916b7d",
                            "created_at": "2018-05-01T00:35:56.554Z",
                            "updated_at": "2018-06-28T13:58:19.265Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-14",
            "index": 14,
            "timestamp": 2800,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000015.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "5f193038-1759-4a24-899c-b3b47be259ec",
                    "score": 0.9857125679651896,
                    "category": {
                        "_type": "Category",
                        "_id": "pants",
                        "name": "pants"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 100.01764678955078,
                        "y": 395.76080322265625,
                        "width": 183.54753875732422,
                        "height": 295.3682861328125
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9030ea2773810155abae8",
                            "schema": "product",
                            "score": 0.9190283489227296,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/f0/0c/f00c02fbc09deb7244d0fa0e7a055f60_best.jpg",
                                    "width": 383,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "623405262_Bright blue:623405262:Bright blue:https://www.shopstyle.com/p/gallego-desportes-casual-pants/623405262",
                                "sku": "623405262",
                                "name": "GALLEGO DESPORTES Casual pants",
                                "url": "https://www.shopstyle.com/p/gallego-desportes-casual-pants/623405262",
                                "brand": "GALLEGO DESPORTES",
                                "color": "Bright blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "GALLEGO DESPORTES Casual pants. plain weave, solid color, high waisted, comfort fit, wide leg, no appliqués, 3 buttons, multipockets, pants. 100% Cotton",
                                "category": "Women > Clothing > Pants > Casual |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 220,
                                "currency": "USD"
                            },
                            "fingerprint": "762ba0ec532ef1721ae6a9be5b45a66a",
                            "created_at": "2018-05-02T00:15:10.522Z",
                            "updated_at": "2018-07-05T23:49:56.486Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e4b9b77b36100f185ad5",
                            "schema": "product",
                            "score": 0.9182834911346436,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/d7/f0/d7f062195d11e643d09e6866ab5092d3_best.jpg",
                                    "width": 406,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "709920032_Bright blue:709920032:Bright blue:https://www.shopstyle.com/g/women/etro/casual-pants",
                                "sku": "709920032",
                                "name": "Etro Casual pants",
                                "url": "https://www.shopstyle.com/g/women/etro/casual-pants",
                                "brand": "Etro",
                                "color": "Bright blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "ETRO Casual pants. plain weave, no appliqués, solid color, mid rise, regular fit, straight leg, button, zip, multipockets, stretch, tailored. 97% Cotton, 3% Elastane",
                                "category": "Women > Clothing > Pants > Casual |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 319,
                                "currency": "USD"
                            },
                            "fingerprint": "3155f444845a9ba939acca9da3502011",
                            "created_at": "2018-05-01T22:05:45.126Z",
                            "updated_at": "2018-07-06T00:04:51.865Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b7f7a27738101554fa0d",
                            "schema": "product",
                            "score": 0.9176015663146972,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/75/e2/75e244d0acac11628d6ec4c56e7ecaae_best.jpg",
                                    "width": 624,
                                    "height": 800
                                }
                            ],
                            "data": {
                                "id": "697798873_Blue:697798873:Blue:https://www.shopstyle.com/g/women/claudie-pierlot/petula-crepe-trousers",
                                "sku": "697798873",
                                "name": "Claudie Pierlot Petula crepe trousers",
                                "url": "https://www.shopstyle.com/g/women/claudie-pierlot/petula-crepe-trousers",
                                "brand": "Claudie Pierlot",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Whether it’s workwear or casualwear, the Petula trousers from Parisian brand Claudie Pierlot are guaranteed to step your outfits up a couple of notches. Crafted from crepe, this tapered pair holds its shape well and falls just above the ankle for a flattering silhouette. The belt at the front is adorned with a bold bow so deciding how to wear this style is a no brainer: a shirt tucked in, of course. Claudie Pierlot crepe trousersConcealed hook and eye and zip fastening at frontRegular fit, tapered, high rise, belt loops, belt with bow detail at waist, two slip pockets at front, pressed centre creases at front and back, two welt pockets at back, partially lined70% triacetate, 30% polyester; 100% acetate Dry cleanTrue to sizeSize 8: Rise 13”, inside leg 24\", leg opening 12\"Model is 5ft 10\" and wears a size 8",
                                "category": "Women > Clothing > Pants |  | ",
                                "size": "10",
                                "age_group": "adult",
                                "stock": true,
                                "price": 280,
                                "currency": "USD"
                            },
                            "fingerprint": "b236462ea19330febe85335b4c329803",
                            "created_at": "2018-05-01T18:54:47.609Z",
                            "updated_at": "2018-06-28T14:51:20.050Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ad04a2773810154fc342",
                            "schema": "product",
                            "score": 0.9161422061920166,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/00/2a/002a27e60cd08d1c0fe3279f5e694298_best.jpg",
                                    "width": 258,
                                    "height": 500
                                }
                            ],
                            "data": {
                                "id": "720921589_Captain America:720921589:Captain America:https://www.shopstyle.com/p/opposuits-official-marvel-comics-hero-suits-of-avengers-costume-comes-with-pants/720921589",
                                "sku": "720921589",
                                "name": "Opposuits Official Marvel Comics Hero Suits Of - Avengers Costume Comes With Pants",
                                "url": "https://www.shopstyle.com/p/opposuits-official-marvel-comics-hero-suits-of-avengers-costume-comes-with-pants/720921589",
                                "brand": "Opposuits",
                                "color": "Captain America",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Are you a real Superhero fan? In that case you've come to the right place! We offer suits with several Marvel figures on it: The Hulk, Iron Man, Spider Man and Captain-America, they're all there. This means you'll have the ability to wear some legends on your sleeve. And which Marvel lover hasn't dreamt about that? No matter which action figure suit you'll pick, with one of these avengers outfits you'll definitely steal the show on many occasions; from theme parties to Comic Con and movie premieres to Halloween. Get yours now!",
                                "category": "Men > Clothing > Pants |  | ",
                                "size": "US36",
                                "age_group": "adult",
                                "stock": true,
                                "price": 129.99,
                                "currency": "USD"
                            },
                            "fingerprint": "46e62819e12aa72126d8ab36b2747710",
                            "created_at": "2018-05-01T18:08:04.811Z",
                            "updated_at": "2018-06-26T23:32:23.400Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8faef6efb8d3d2f8c46fd",
                            "schema": "product",
                            "score": 0.9151241683959961,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/72/f2/72f2c7301e1227d2252df68671736287_best.jpg",
                                    "width": 335,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "700494461_Bright blue:700494461:Bright blue:https://www.shopstyle.com/g/women/alexander-mcqueen/mcq-casual-pants",
                                "sku": "700494461",
                                "name": "Alexander McQueen McQ Casual pants",
                                "url": "https://www.shopstyle.com/g/women/alexander-mcqueen/mcq-casual-pants",
                                "brand": "Alexander McQueen",
                                "color": "Bright blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "McQ Alexander McQueen Casual pants. crepe, solid color, mid rise, comfort fit, straight leg, no appliqués, drawstring closure, multipockets, pants, large sized. 73% Triacetate, 27% Polyester",
                                "category": "Women > Clothing > Pants > Casual |  | ",
                                "size": "0",
                                "age_group": "adult",
                                "stock": true,
                                "price": 289,
                                "currency": "USD"
                            },
                            "fingerprint": "8854dd5461b31bf1d1ac412702bcddeb",
                            "created_at": "2018-05-01T23:40:31.426Z",
                            "updated_at": "2018-06-19T00:58:08.419Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b53860cf5e48d63d2989",
                            "schema": "product",
                            "score": 0.9133417320251465,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/75/c6/75c616b1fa98736601aca80fb3442c2e_best.jpg",
                                    "width": 414,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679590214_Blue:679590214:Blue:https://www.shopstyle.com/g/women/berwich/casual-pants",
                                "sku": "679590214",
                                "name": "Berwich Casual pants",
                                "url": "https://www.shopstyle.com/g/women/berwich/casual-pants",
                                "brand": "Berwich",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "BERWICH Casual pants. plain weave, detachable application, solid color, high waisted, regular fit, tapered leg, button, zip, multipockets, pants. 100% Cotton",
                                "category": "Women > Clothing > Pants > Casual |  | ",
                                "size": "8",
                                "age_group": "adult",
                                "stock": true,
                                "price": 174,
                                "currency": "USD"
                            },
                            "fingerprint": "294469689b4f4ca4f81583931938cac1",
                            "created_at": "2018-05-01T00:30:48.142Z",
                            "updated_at": "2018-06-28T13:31:26.951Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8f4796efb8d3d2f88ae0f",
                            "schema": "product",
                            "score": 0.9126931571960449,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/ae/f1/aef18b74104a11a8d1c5d8f2dc1380b5_best.jpg",
                                    "width": 414,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "686045818_Bright blue:686045818:Bright blue:https://www.shopstyle.com/p/mirella-matteini-casual-pants/686045818",
                                "sku": "686045818",
                                "name": "MIRELLA MATTEINI Casual pants",
                                "url": "https://www.shopstyle.com/p/mirella-matteini-casual-pants/686045818",
                                "brand": "MIRELLA MATTEINI",
                                "color": "Bright blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MIRELLA MATTEINI Casual pants. crepe, sash, solid color, mid rise, regular fit, wide leg, zip, no pockets, stretch, tailored. 95% Polyester, 5% Elastane",
                                "category": "Women > Clothing > Pants > Casual |  | ",
                                "size": "6",
                                "age_group": "adult",
                                "stock": true,
                                "price": 129,
                                "currency": "USD"
                            },
                            "fingerprint": "7d1fc075d527d83556005828c888da23",
                            "created_at": "2018-05-01T23:12:57.734Z",
                            "updated_at": "2018-07-05T23:54:49.425Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e469b77b36100f182c56",
                            "schema": "product",
                            "score": 0.9125935173034668,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/31/9b/319bf9a7bdb757c53e2d3186a1d528e0_best.jpg",
                                    "width": 194,
                                    "height": 496
                                }
                            ],
                            "data": {
                                "id": "718572584_BLUE:718572584:BLUE:https://www.shopstyle.com/p/victoria-victoria-beckham-flat-front-slim-straight-leg-ankle-length-wool-pants/718572584",
                                "sku": "718572584",
                                "name": "Victoria Victoria Beckham Flat-Front Slim Straight-Leg Ankle-Length Wool Pants",
                                "url": "https://www.shopstyle.com/p/victoria-victoria-beckham-flat-front-slim-straight-leg-ankle-length-wool-pants/718572584",
                                "brand": "Victoria Victoria Beckham",
                                "color": "BLUE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Victoria Victoria Beckham wool pants. Mid-rise; belt loops. Flat hook-zip fly. Side slip pockets; back besom pockets. Slim fit through straight legs. Ankle-length. Made in UK.",
                                "category": "Women > Clothing > Pants |  | ",
                                "size": "14 UK (10 US)",
                                "age_group": "adult",
                                "stock": true,
                                "price": 540,
                                "currency": "USD"
                            },
                            "fingerprint": "cb1376fae08877abf9b0d3dc0941f3a3",
                            "created_at": "2018-05-01T22:04:25.508Z",
                            "updated_at": "2018-07-06T00:20:07.089Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d99a1393dd3d29169e13",
                            "schema": "product",
                            "score": 0.912486400604248,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/3b/c6/3bc6315fed804da2e6f155dd103a1a6e_best.jpg",
                                    "width": 364,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "680250334_Bright blue:680250334:Bright blue:https://www.shopstyle.com/g/women/moschino/casual-pants",
                                "sku": "680250334",
                                "name": "Moschino Casual pants",
                                "url": "https://www.shopstyle.com/g/women/moschino/casual-pants",
                                "brand": "Moschino",
                                "color": "Bright blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "BOUTIQUE MOSCHINO Casual pants. plain weave, no appliqués, basic solid color, mid rise, comfort fit, wide leg, button, zip, multipockets, cuffed hems, tailored. 96% Cotton, 4% Other Fibres",
                                "category": "Women > Clothing > Pants > Casual |  | ",
                                "size": "4",
                                "age_group": "adult",
                                "stock": true,
                                "price": 369,
                                "currency": "USD"
                            },
                            "fingerprint": "9b12172ac1e451af4b070539eed07fee",
                            "created_at": "2018-05-01T21:18:18.097Z",
                            "updated_at": "2018-07-06T00:23:08.878Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b66c60cf5e48d63dd64e",
                            "schema": "product",
                            "score": 0.9112092971801757,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b5/65/b565d67169208bbad2afeae29211c1be_best.jpg",
                                    "width": 202,
                                    "height": 616
                                }
                            ],
                            "data": {
                                "id": "693388822_blue:693388822:blue:https://www.shopstyle.com/g/women/joseph/crepe-carrot-trousers",
                                "sku": "693388822",
                                "name": "Joseph Crepe Carrot Trousers",
                                "url": "https://www.shopstyle.com/g/women/joseph/crepe-carrot-trousers",
                                "brand": "Joseph",
                                "color": "blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Royal blue pants in a soft and comfortable carrot shape. Make sure to cinch your waist with the accompanying black belt. 64% Viscose, 33% Acetate, 3% Elastane",
                                "category": "Women > Clothing > Pants |  | ",
                                "size": "FR36 - US4",
                                "age_group": "adult",
                                "stock": true,
                                "price": 550,
                                "currency": "USD"
                            },
                            "fingerprint": "2cb3250353d67742528eb81721916b7d",
                            "created_at": "2018-05-01T00:35:56.554Z",
                            "updated_at": "2018-06-28T13:58:19.265Z"
                        }
                    ]
                },
                {
                    "_type": "SearchResult",
                    "_id": "859c22e5-b889-4fe3-a08d-e81acd93db33",
                    "score": 0.9494697478684512,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 180.5787353515625,
                        "y": 167.32528686523438,
                        "width": 57.295989990234375,
                        "height": 14.9234619140625
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c26d6efb8d3d2f80ccba",
                            "schema": "product",
                            "score": 0.8650787830352783,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/56/7a/567a6cddec9b5b7b582d42e820acbe66_best.jpg",
                                    "width": 720,
                                    "height": 858
                                }
                            ],
                            "data": {
                                "id": "717496724_White:717496724:White:https://www.shopstyle.com/g/women/sonic-editions/john-cale-lou-reed-and-warhol-by-waring-abbott-framed",
                                "sku": "717496724",
                                "name": "Sonic Editions John Cale, Lou Reed and Warhol by Waring Abbott (Framed)",
                                "url": "https://www.shopstyle.com/g/women/sonic-editions/john-cale-lou-reed-and-warhol-by-waring-abbott-framed",
                                "brand": "Sonic Editions",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "John Cale, Lou Reed and Warhol Framed Print: *Framed art print *Photograph of John Cale and Lou Reed, at a photo shoot in New York City. Photo of Andy Warhol in background. *Photograph by Waring Abbott *Quality digital C-Type prints *High-quality handmade wooden frame *Acid-free and cut to order mount *Shatter resistant Perspex",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "12x16",
                                "age_group": "adult",
                                "stock": true,
                                "price": 195,
                                "currency": "USD"
                            },
                            "fingerprint": "ce7e97654f9748b989ec4140ecbb1aeb",
                            "created_at": "2018-05-01T19:39:25.326Z",
                            "updated_at": "2018-06-18T22:18:09.053Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae900666efb8d3d2f8f36fe",
                            "schema": "product",
                            "score": 0.8461506271362305,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/35/1c/351c70b19c5522e1c5e04450ebc7876f_best.jpg",
                                    "width": 272,
                                    "height": 273
                                }
                            ],
                            "data": {
                                "id": "648557865_Black:648557865:Black:https://www.shopstyle.com/g/men/christian-dior/walk-51mm-square-sunglasses",
                                "sku": "648557865",
                                "name": "Christian Dior Walk 51MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/christian-dior/walk-51mm-square-sunglasses",
                                "brand": "Christian Dior",
                                "color": "Black",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Transparent square frame with contrast arms.51mm lens width; 19mm bridge width; 150mm temple length.100% UV protection. Acetate. Made in Italy.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "ONE SIZE",
                                "age_group": "adult",
                                "stock": true,
                                "price": 320,
                                "currency": "USD"
                            },
                            "fingerprint": "ba88d3f527c7eb81659e20c9b172248a",
                            "created_at": "2018-05-02T00:03:50.172Z",
                            "updated_at": "2018-06-23T01:25:09.460Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d12a1393dd3d291213a2",
                            "schema": "product",
                            "score": 0.8415222072601318,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/dc/c3/dcc3d05785f618419e95a64ddb41cf81_best.jpg",
                                    "width": 720,
                                    "height": 344
                                }
                            ],
                            "data": {
                                "id": "689036426_BLACK:689036426:BLACK:https://www.shopstyle.com/g/women/tom-ford/womens-ft-9280-50f-59mm-sunglasses",
                                "sku": "689036426",
                                "name": "Tom Ford Women's Ft_9280_50F 59Mm Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/tom-ford/womens-ft-9280-50f-59mm-sunglasses",
                                "brand": "Tom Ford",
                                "color": "BLACK",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Frame shape: cat eye Frame color: striped brown Lens color: gradient brown These frames flatter those with an oval, round, diamond, triangle or oblong shaped face Plastic lenses with 100% UV protection Lens: 59mm wide Bridge: 16mm wide Arms: 135mm long Case included; case size and color may vary Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "NoSize",
                                "age_group": "adult",
                                "stock": true,
                                "price": 450,
                                "currency": "USD"
                            },
                            "fingerprint": "918350b9a0677055a8d8b10f8bbb6f72",
                            "created_at": "2018-05-01T20:42:18.071Z",
                            "updated_at": "2018-06-19T01:43:07.001Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b4c060cf5e48d63ce2c4",
                            "schema": "product",
                            "score": 0.8414638328552246,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/52/45/524587dc6baca3f3cadeaa65967b90db_best.jpg",
                                    "width": 490,
                                    "height": 328
                                }
                            ],
                            "data": {
                                "id": "664398819:664398819::https://www.shopstyle.com/p/rikki-knight-hipster-giraffes-with-colorful-glasses-design-compact-mirror/664398819",
                                "sku": "664398819",
                                "name": "Rikki Knight Hipster Giraffes with Colorful Glasses Design Compact Mirror",
                                "url": "https://www.shopstyle.com/p/rikki-knight-hipster-giraffes-with-colorful-glasses-design-compact-mirror/664398819",
                                "brand": "Rikki Knight",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "This Rikki Knight Hipster Giraffes with colorful glasses Compact Mirror is the perfect sized mirror to slip into your pocket, purse, bag, pencil case, or any bag and features quality vibrant Images that will not fade with time. The compact has two mirrors on the inside and features an easy hinged open close latch. If you are planning to buy this for someone else once you see it, it will be hard to part from it. When you purchase a Rikki Knight Brand you can count on satisfaction; This Rikki knight Hipster Giraffes with colorful glasses could be an ideal gift for all occasions; Disclaimer: All images are printed directly into the material - The Texture of the image will always be a flat surface. Any images that appears to be 3d, textured, or glittery are flat printed images and are proudly printed in the USA.",
                                "category": "Women > Beauty > Tools > Mirrors |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 17.95,
                                "currency": "USD"
                            },
                            "fingerprint": "6fbd2148d02f137fdb4df9e3a7eac338",
                            "created_at": "2018-05-01T00:28:48.506Z",
                            "updated_at": "2018-06-18T20:18:08.446Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e765b77b36100f19e9e0",
                            "schema": "product",
                            "score": 0.8406785488128662,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/7d/26/7d26d5011cb95aec276e4e17d606a687_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "716846003_Silver/Solid Grey:716846003:Silver/Solid Grey:https://www.shopstyle.com/g/women/saint-laurent/sl-182-metal-betty-sunglasses",
                                "sku": "716846003",
                                "name": "Saint Laurent SL 182 Metal Betty Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/sl-182-metal-betty-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "Silver/Solid Grey",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Mirrored frames Soft case Cleaning cloth included Shield frame Non-polarized lenses Made in Italy Style # YVESL20164 Measurements Width: 6in / 15cm Height: 2.5in / 6.5cm Lens Width: 68mm",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 530,
                                "currency": "USD"
                            },
                            "fingerprint": "ea9286cb4b85b77892c450bb6abf01f8",
                            "created_at": "2018-05-01T22:17:09.854Z",
                            "updated_at": "2018-06-26T23:42:19.889Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fadc6efb8d3d2f8c3c46",
                            "schema": "product",
                            "score": 0.8389666557312012,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/38/da/38daa07c656265015e558b0f9d9c150d_best.jpg",
                                    "width": 336,
                                    "height": 328
                                }
                            ],
                            "data": {
                                "id": "651083859_White:651083859:White:https://www.shopstyle.com/g/men/nike/legend-65mm-multi-sport-sunglasses",
                                "sku": "651083859",
                                "name": "Nike Legend 65mm Multi-Sport Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/nike/legend-65mm-multi-sport-sunglasses",
                                "brand": "Nike",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Lightweight, durable and versatile enough to keep the sun out of your eyes in any outdoor activity, these action-ready sunglasses are constructed with Nike Max Optics to provide enhanced clarity from every angle in an 8-base sport-wrap frame for maximum coverage. High-tension temple hinges ensure a stay-put fit that makes these shades an ideal choice for runs, workouts and more. Style Name:Nike Legend 65Mm Multi-Sport Sunglasses. Style Number: 5417633. Available in stores.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 79,
                                "currency": "USD"
                            },
                            "fingerprint": "70ea91c7ba2507d37a5933b3991212bc",
                            "created_at": "2018-05-01T23:40:12.209Z",
                            "updated_at": "2018-06-23T01:25:09.223Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0f1b77b36100f161f43",
                            "schema": "product",
                            "score": 0.8382883453369141,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5e/3f/5e3faee81a2349c095deb113b51fa4b8_best.jpg",
                                    "width": 496,
                                    "height": 224
                                }
                            ],
                            "data": {
                                "id": "437780163_White:437780163:White:https://www.shopstyle.com/p/kreed-mens-makin-it-big-square-sunglasses/437780163",
                                "sku": "437780163",
                                "name": "Kreed Men's Makin It Big Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/kreed-mens-makin-it-big-square-sunglasses/437780163",
                                "brand": "Kreed",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "You can never go wrong with a classic. You canâ€TMt help but feel great wearing this 80s-inspired wayfarer frame!",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "57 mm",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35,
                                "currency": "USD"
                            },
                            "fingerprint": "aa8f881b8362e3383c2e5c310039394e",
                            "created_at": "2018-05-01T21:49:37.971Z",
                            "updated_at": "2018-06-23T01:24:27.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b9e1b77b36100f148c61",
                            "schema": "product",
                            "score": 0.8378244590759277,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/8b/37/8b378c307289256ca668069717f71b7d_best.jpg",
                                    "width": 350,
                                    "height": 322
                                }
                            ],
                            "data": {
                                "id": "651083831_Green:651083831:Green:https://www.shopstyle.com/g/men/nike/legend-65mm-mirrored-multi-sport-sunglasses",
                                "sku": "651083831",
                                "name": "Nike Legend 65mm Mirrored Multi-Sport Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/nike/legend-65mm-mirrored-multi-sport-sunglasses",
                                "brand": "Nike",
                                "color": "Green",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Lightweight, durable and versatile enough to keep the sun out of your eyes in any outdoor activity, these cool reflective sunglasses are constructed with Nike Max Optics to provide enhanced clarity from every angle in an 8-base sport-wrap frame for maximum coverage. High-tension temple hinges ensure a stay-put fit that makes these shades an ideal choice for runs, workouts and more. Style Name:Nike Legend 65Mm Mirrored Multi-Sport Sunglasses. Style Number: 5417620. Available in stores.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 99,
                                "currency": "USD"
                            },
                            "fingerprint": "a3c548eaa4c0b639b6b8fd11ccd808a4",
                            "created_at": "2018-05-01T19:02:57.938Z",
                            "updated_at": "2018-06-23T01:24:23.167Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ba3eb77b36100f14a5a4",
                            "schema": "product",
                            "score": 0.8373895645141601,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/90/06/900657cf03bbf2df777cef167248808d_best.jpg",
                                    "width": 285,
                                    "height": 276
                                }
                            ],
                            "data": {
                                "id": "719127092_Grey:719127092:Grey:https://www.shopstyle.com/g/men/gucci/57mm-square-sunglasses",
                                "sku": "719127092",
                                "name": "Gucci 57MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/gucci/57mm-square-sunglasses",
                                "brand": "Gucci",
                                "color": "Grey",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Must-have sunglasses elevated with abstract design on temples 57mm lens width; 17mm bridge width; 145mm temple length Case and cleaning cloth included Gradient lenses Plastic Made in Italy",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 290,
                                "currency": "USD"
                            },
                            "fingerprint": "78da1add68eb7ed04ac2648a098b9634",
                            "created_at": "2018-05-01T19:04:30.788Z",
                            "updated_at": "2018-06-18T15:24:04.833Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8f13a6efb8d3d2f86d331",
                            "schema": "product",
                            "score": 0.8364857292175293,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/f4/24/f424f170028130dff8dd8e1fe794bf9a_best.jpg",
                                    "width": 720,
                                    "height": 245
                                }
                            ],
                            "data": {
                                "id": "642655114_CLEAR/BLACK PRIZM POLARIZED:642655114:CLEAR/BLACK PRIZM POLARIZED:https://www.shopstyle.com/g/men/oakley/polarized-turbine-rotor-prizm-sunglasses-oo9307-32",
                                "sku": "642655114",
                                "name": "Oakley Polarized Turbine Rotor Prizm Sunglasses, OO9307 32",
                                "url": "https://www.shopstyle.com/g/men/oakley/polarized-turbine-rotor-prizm-sunglasses-oo9307-32",
                                "brand": "Oakley",
                                "color": "CLEAR/BLACK PRIZM POLARIZED",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Devoted to making the best sunglasses on the planet by letting invention lead the way. With a passion to reinvent from scratch, Oakley sunglasses defy convention and set the standard for design, performance, and protection by wrapping innovation in art.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 203,
                                "currency": "USD"
                            },
                            "fingerprint": "45a0a8086aae0c556320c0e597f96e46",
                            "created_at": "2018-05-01T22:59:06.438Z",
                            "updated_at": "2018-06-25T18:20:08.836Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-15",
            "index": 15,
            "timestamp": 3000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000016.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "8cfdc06c-8073-4723-9d74-d6ab61a256c5",
                    "score": 0.9857125679651896,
                    "category": {
                        "_type": "Category",
                        "_id": "pants",
                        "name": "pants"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 109.75184631347656,
                        "y": 401.887451171875,
                        "width": 188.1954803466797,
                        "height": 293.97900390625
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9030ea2773810155abae8",
                            "schema": "product",
                            "score": 0.9190283489227296,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/f0/0c/f00c02fbc09deb7244d0fa0e7a055f60_best.jpg",
                                    "width": 383,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "623405262_Bright blue:623405262:Bright blue:https://www.shopstyle.com/p/gallego-desportes-casual-pants/623405262",
                                "sku": "623405262",
                                "name": "GALLEGO DESPORTES Casual pants",
                                "url": "https://www.shopstyle.com/p/gallego-desportes-casual-pants/623405262",
                                "brand": "GALLEGO DESPORTES",
                                "color": "Bright blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "GALLEGO DESPORTES Casual pants. plain weave, solid color, high waisted, comfort fit, wide leg, no appliqués, 3 buttons, multipockets, pants. 100% Cotton",
                                "category": "Women > Clothing > Pants > Casual |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 220,
                                "currency": "USD"
                            },
                            "fingerprint": "762ba0ec532ef1721ae6a9be5b45a66a",
                            "created_at": "2018-05-02T00:15:10.522Z",
                            "updated_at": "2018-07-05T23:49:56.486Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e4b9b77b36100f185ad5",
                            "schema": "product",
                            "score": 0.9182834911346436,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/d7/f0/d7f062195d11e643d09e6866ab5092d3_best.jpg",
                                    "width": 406,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "709920032_Bright blue:709920032:Bright blue:https://www.shopstyle.com/g/women/etro/casual-pants",
                                "sku": "709920032",
                                "name": "Etro Casual pants",
                                "url": "https://www.shopstyle.com/g/women/etro/casual-pants",
                                "brand": "Etro",
                                "color": "Bright blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "ETRO Casual pants. plain weave, no appliqués, solid color, mid rise, regular fit, straight leg, button, zip, multipockets, stretch, tailored. 97% Cotton, 3% Elastane",
                                "category": "Women > Clothing > Pants > Casual |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 319,
                                "currency": "USD"
                            },
                            "fingerprint": "3155f444845a9ba939acca9da3502011",
                            "created_at": "2018-05-01T22:05:45.126Z",
                            "updated_at": "2018-07-06T00:04:51.865Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b7f7a27738101554fa0d",
                            "schema": "product",
                            "score": 0.9176015663146972,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/75/e2/75e244d0acac11628d6ec4c56e7ecaae_best.jpg",
                                    "width": 624,
                                    "height": 800
                                }
                            ],
                            "data": {
                                "id": "697798873_Blue:697798873:Blue:https://www.shopstyle.com/g/women/claudie-pierlot/petula-crepe-trousers",
                                "sku": "697798873",
                                "name": "Claudie Pierlot Petula crepe trousers",
                                "url": "https://www.shopstyle.com/g/women/claudie-pierlot/petula-crepe-trousers",
                                "brand": "Claudie Pierlot",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Whether it’s workwear or casualwear, the Petula trousers from Parisian brand Claudie Pierlot are guaranteed to step your outfits up a couple of notches. Crafted from crepe, this tapered pair holds its shape well and falls just above the ankle for a flattering silhouette. The belt at the front is adorned with a bold bow so deciding how to wear this style is a no brainer: a shirt tucked in, of course. Claudie Pierlot crepe trousersConcealed hook and eye and zip fastening at frontRegular fit, tapered, high rise, belt loops, belt with bow detail at waist, two slip pockets at front, pressed centre creases at front and back, two welt pockets at back, partially lined70% triacetate, 30% polyester; 100% acetate Dry cleanTrue to sizeSize 8: Rise 13”, inside leg 24\", leg opening 12\"Model is 5ft 10\" and wears a size 8",
                                "category": "Women > Clothing > Pants |  | ",
                                "size": "10",
                                "age_group": "adult",
                                "stock": true,
                                "price": 280,
                                "currency": "USD"
                            },
                            "fingerprint": "b236462ea19330febe85335b4c329803",
                            "created_at": "2018-05-01T18:54:47.609Z",
                            "updated_at": "2018-06-28T14:51:20.050Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ad04a2773810154fc342",
                            "schema": "product",
                            "score": 0.9161422061920166,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/00/2a/002a27e60cd08d1c0fe3279f5e694298_best.jpg",
                                    "width": 258,
                                    "height": 500
                                }
                            ],
                            "data": {
                                "id": "720921589_Captain America:720921589:Captain America:https://www.shopstyle.com/p/opposuits-official-marvel-comics-hero-suits-of-avengers-costume-comes-with-pants/720921589",
                                "sku": "720921589",
                                "name": "Opposuits Official Marvel Comics Hero Suits Of - Avengers Costume Comes With Pants",
                                "url": "https://www.shopstyle.com/p/opposuits-official-marvel-comics-hero-suits-of-avengers-costume-comes-with-pants/720921589",
                                "brand": "Opposuits",
                                "color": "Captain America",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Are you a real Superhero fan? In that case you've come to the right place! We offer suits with several Marvel figures on it: The Hulk, Iron Man, Spider Man and Captain-America, they're all there. This means you'll have the ability to wear some legends on your sleeve. And which Marvel lover hasn't dreamt about that? No matter which action figure suit you'll pick, with one of these avengers outfits you'll definitely steal the show on many occasions; from theme parties to Comic Con and movie premieres to Halloween. Get yours now!",
                                "category": "Men > Clothing > Pants |  | ",
                                "size": "US36",
                                "age_group": "adult",
                                "stock": true,
                                "price": 129.99,
                                "currency": "USD"
                            },
                            "fingerprint": "46e62819e12aa72126d8ab36b2747710",
                            "created_at": "2018-05-01T18:08:04.811Z",
                            "updated_at": "2018-06-26T23:32:23.400Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8faef6efb8d3d2f8c46fd",
                            "schema": "product",
                            "score": 0.9151241683959961,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/72/f2/72f2c7301e1227d2252df68671736287_best.jpg",
                                    "width": 335,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "700494461_Bright blue:700494461:Bright blue:https://www.shopstyle.com/g/women/alexander-mcqueen/mcq-casual-pants",
                                "sku": "700494461",
                                "name": "Alexander McQueen McQ Casual pants",
                                "url": "https://www.shopstyle.com/g/women/alexander-mcqueen/mcq-casual-pants",
                                "brand": "Alexander McQueen",
                                "color": "Bright blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "McQ Alexander McQueen Casual pants. crepe, solid color, mid rise, comfort fit, straight leg, no appliqués, drawstring closure, multipockets, pants, large sized. 73% Triacetate, 27% Polyester",
                                "category": "Women > Clothing > Pants > Casual |  | ",
                                "size": "0",
                                "age_group": "adult",
                                "stock": true,
                                "price": 289,
                                "currency": "USD"
                            },
                            "fingerprint": "8854dd5461b31bf1d1ac412702bcddeb",
                            "created_at": "2018-05-01T23:40:31.426Z",
                            "updated_at": "2018-06-19T00:58:08.419Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b53860cf5e48d63d2989",
                            "schema": "product",
                            "score": 0.9133417320251465,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/75/c6/75c616b1fa98736601aca80fb3442c2e_best.jpg",
                                    "width": 414,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679590214_Blue:679590214:Blue:https://www.shopstyle.com/g/women/berwich/casual-pants",
                                "sku": "679590214",
                                "name": "Berwich Casual pants",
                                "url": "https://www.shopstyle.com/g/women/berwich/casual-pants",
                                "brand": "Berwich",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "BERWICH Casual pants. plain weave, detachable application, solid color, high waisted, regular fit, tapered leg, button, zip, multipockets, pants. 100% Cotton",
                                "category": "Women > Clothing > Pants > Casual |  | ",
                                "size": "8",
                                "age_group": "adult",
                                "stock": true,
                                "price": 174,
                                "currency": "USD"
                            },
                            "fingerprint": "294469689b4f4ca4f81583931938cac1",
                            "created_at": "2018-05-01T00:30:48.142Z",
                            "updated_at": "2018-06-28T13:31:26.951Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8f4796efb8d3d2f88ae0f",
                            "schema": "product",
                            "score": 0.9126931571960449,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/ae/f1/aef18b74104a11a8d1c5d8f2dc1380b5_best.jpg",
                                    "width": 414,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "686045818_Bright blue:686045818:Bright blue:https://www.shopstyle.com/p/mirella-matteini-casual-pants/686045818",
                                "sku": "686045818",
                                "name": "MIRELLA MATTEINI Casual pants",
                                "url": "https://www.shopstyle.com/p/mirella-matteini-casual-pants/686045818",
                                "brand": "MIRELLA MATTEINI",
                                "color": "Bright blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MIRELLA MATTEINI Casual pants. crepe, sash, solid color, mid rise, regular fit, wide leg, zip, no pockets, stretch, tailored. 95% Polyester, 5% Elastane",
                                "category": "Women > Clothing > Pants > Casual |  | ",
                                "size": "6",
                                "age_group": "adult",
                                "stock": true,
                                "price": 129,
                                "currency": "USD"
                            },
                            "fingerprint": "7d1fc075d527d83556005828c888da23",
                            "created_at": "2018-05-01T23:12:57.734Z",
                            "updated_at": "2018-07-05T23:54:49.425Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e469b77b36100f182c56",
                            "schema": "product",
                            "score": 0.9125935173034668,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/31/9b/319bf9a7bdb757c53e2d3186a1d528e0_best.jpg",
                                    "width": 194,
                                    "height": 496
                                }
                            ],
                            "data": {
                                "id": "718572584_BLUE:718572584:BLUE:https://www.shopstyle.com/p/victoria-victoria-beckham-flat-front-slim-straight-leg-ankle-length-wool-pants/718572584",
                                "sku": "718572584",
                                "name": "Victoria Victoria Beckham Flat-Front Slim Straight-Leg Ankle-Length Wool Pants",
                                "url": "https://www.shopstyle.com/p/victoria-victoria-beckham-flat-front-slim-straight-leg-ankle-length-wool-pants/718572584",
                                "brand": "Victoria Victoria Beckham",
                                "color": "BLUE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Victoria Victoria Beckham wool pants. Mid-rise; belt loops. Flat hook-zip fly. Side slip pockets; back besom pockets. Slim fit through straight legs. Ankle-length. Made in UK.",
                                "category": "Women > Clothing > Pants |  | ",
                                "size": "14 UK (10 US)",
                                "age_group": "adult",
                                "stock": true,
                                "price": 540,
                                "currency": "USD"
                            },
                            "fingerprint": "cb1376fae08877abf9b0d3dc0941f3a3",
                            "created_at": "2018-05-01T22:04:25.508Z",
                            "updated_at": "2018-07-06T00:20:07.089Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d99a1393dd3d29169e13",
                            "schema": "product",
                            "score": 0.912486400604248,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/3b/c6/3bc6315fed804da2e6f155dd103a1a6e_best.jpg",
                                    "width": 364,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "680250334_Bright blue:680250334:Bright blue:https://www.shopstyle.com/g/women/moschino/casual-pants",
                                "sku": "680250334",
                                "name": "Moschino Casual pants",
                                "url": "https://www.shopstyle.com/g/women/moschino/casual-pants",
                                "brand": "Moschino",
                                "color": "Bright blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "BOUTIQUE MOSCHINO Casual pants. plain weave, no appliqués, basic solid color, mid rise, comfort fit, wide leg, button, zip, multipockets, cuffed hems, tailored. 96% Cotton, 4% Other Fibres",
                                "category": "Women > Clothing > Pants > Casual |  | ",
                                "size": "4",
                                "age_group": "adult",
                                "stock": true,
                                "price": 369,
                                "currency": "USD"
                            },
                            "fingerprint": "9b12172ac1e451af4b070539eed07fee",
                            "created_at": "2018-05-01T21:18:18.097Z",
                            "updated_at": "2018-07-06T00:23:08.878Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b66c60cf5e48d63dd64e",
                            "schema": "product",
                            "score": 0.9112092971801757,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b5/65/b565d67169208bbad2afeae29211c1be_best.jpg",
                                    "width": 202,
                                    "height": 616
                                }
                            ],
                            "data": {
                                "id": "693388822_blue:693388822:blue:https://www.shopstyle.com/g/women/joseph/crepe-carrot-trousers",
                                "sku": "693388822",
                                "name": "Joseph Crepe Carrot Trousers",
                                "url": "https://www.shopstyle.com/g/women/joseph/crepe-carrot-trousers",
                                "brand": "Joseph",
                                "color": "blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Royal blue pants in a soft and comfortable carrot shape. Make sure to cinch your waist with the accompanying black belt. 64% Viscose, 33% Acetate, 3% Elastane",
                                "category": "Women > Clothing > Pants |  | ",
                                "size": "FR36 - US4",
                                "age_group": "adult",
                                "stock": true,
                                "price": 550,
                                "currency": "USD"
                            },
                            "fingerprint": "2cb3250353d67742528eb81721916b7d",
                            "created_at": "2018-05-01T00:35:56.554Z",
                            "updated_at": "2018-06-28T13:58:19.265Z"
                        }
                    ]
                },
                {
                    "_type": "SearchResult",
                    "_id": "c3df08b9-0514-4705-9c88-e94a7aff1a15",
                    "score": 0.9494697478684512,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 193.79296875,
                        "y": 172.7937774658203,
                        "width": 58.48150634765625,
                        "height": 14.087432861328125
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c26d6efb8d3d2f80ccba",
                            "schema": "product",
                            "score": 0.8650787830352783,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/56/7a/567a6cddec9b5b7b582d42e820acbe66_best.jpg",
                                    "width": 720,
                                    "height": 858
                                }
                            ],
                            "data": {
                                "id": "717496724_White:717496724:White:https://www.shopstyle.com/g/women/sonic-editions/john-cale-lou-reed-and-warhol-by-waring-abbott-framed",
                                "sku": "717496724",
                                "name": "Sonic Editions John Cale, Lou Reed and Warhol by Waring Abbott (Framed)",
                                "url": "https://www.shopstyle.com/g/women/sonic-editions/john-cale-lou-reed-and-warhol-by-waring-abbott-framed",
                                "brand": "Sonic Editions",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "John Cale, Lou Reed and Warhol Framed Print: *Framed art print *Photograph of John Cale and Lou Reed, at a photo shoot in New York City. Photo of Andy Warhol in background. *Photograph by Waring Abbott *Quality digital C-Type prints *High-quality handmade wooden frame *Acid-free and cut to order mount *Shatter resistant Perspex",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "12x16",
                                "age_group": "adult",
                                "stock": true,
                                "price": 195,
                                "currency": "USD"
                            },
                            "fingerprint": "ce7e97654f9748b989ec4140ecbb1aeb",
                            "created_at": "2018-05-01T19:39:25.326Z",
                            "updated_at": "2018-06-18T22:18:09.053Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae900666efb8d3d2f8f36fe",
                            "schema": "product",
                            "score": 0.8461506271362305,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/35/1c/351c70b19c5522e1c5e04450ebc7876f_best.jpg",
                                    "width": 272,
                                    "height": 273
                                }
                            ],
                            "data": {
                                "id": "648557865_Black:648557865:Black:https://www.shopstyle.com/g/men/christian-dior/walk-51mm-square-sunglasses",
                                "sku": "648557865",
                                "name": "Christian Dior Walk 51MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/christian-dior/walk-51mm-square-sunglasses",
                                "brand": "Christian Dior",
                                "color": "Black",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Transparent square frame with contrast arms.51mm lens width; 19mm bridge width; 150mm temple length.100% UV protection. Acetate. Made in Italy.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "ONE SIZE",
                                "age_group": "adult",
                                "stock": true,
                                "price": 320,
                                "currency": "USD"
                            },
                            "fingerprint": "ba88d3f527c7eb81659e20c9b172248a",
                            "created_at": "2018-05-02T00:03:50.172Z",
                            "updated_at": "2018-06-23T01:25:09.460Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d12a1393dd3d291213a2",
                            "schema": "product",
                            "score": 0.8415222072601318,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/dc/c3/dcc3d05785f618419e95a64ddb41cf81_best.jpg",
                                    "width": 720,
                                    "height": 344
                                }
                            ],
                            "data": {
                                "id": "689036426_BLACK:689036426:BLACK:https://www.shopstyle.com/g/women/tom-ford/womens-ft-9280-50f-59mm-sunglasses",
                                "sku": "689036426",
                                "name": "Tom Ford Women's Ft_9280_50F 59Mm Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/tom-ford/womens-ft-9280-50f-59mm-sunglasses",
                                "brand": "Tom Ford",
                                "color": "BLACK",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Frame shape: cat eye Frame color: striped brown Lens color: gradient brown These frames flatter those with an oval, round, diamond, triangle or oblong shaped face Plastic lenses with 100% UV protection Lens: 59mm wide Bridge: 16mm wide Arms: 135mm long Case included; case size and color may vary Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "NoSize",
                                "age_group": "adult",
                                "stock": true,
                                "price": 450,
                                "currency": "USD"
                            },
                            "fingerprint": "918350b9a0677055a8d8b10f8bbb6f72",
                            "created_at": "2018-05-01T20:42:18.071Z",
                            "updated_at": "2018-06-19T01:43:07.001Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b4c060cf5e48d63ce2c4",
                            "schema": "product",
                            "score": 0.8414638328552246,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/52/45/524587dc6baca3f3cadeaa65967b90db_best.jpg",
                                    "width": 490,
                                    "height": 328
                                }
                            ],
                            "data": {
                                "id": "664398819:664398819::https://www.shopstyle.com/p/rikki-knight-hipster-giraffes-with-colorful-glasses-design-compact-mirror/664398819",
                                "sku": "664398819",
                                "name": "Rikki Knight Hipster Giraffes with Colorful Glasses Design Compact Mirror",
                                "url": "https://www.shopstyle.com/p/rikki-knight-hipster-giraffes-with-colorful-glasses-design-compact-mirror/664398819",
                                "brand": "Rikki Knight",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "This Rikki Knight Hipster Giraffes with colorful glasses Compact Mirror is the perfect sized mirror to slip into your pocket, purse, bag, pencil case, or any bag and features quality vibrant Images that will not fade with time. The compact has two mirrors on the inside and features an easy hinged open close latch. If you are planning to buy this for someone else once you see it, it will be hard to part from it. When you purchase a Rikki Knight Brand you can count on satisfaction; This Rikki knight Hipster Giraffes with colorful glasses could be an ideal gift for all occasions; Disclaimer: All images are printed directly into the material - The Texture of the image will always be a flat surface. Any images that appears to be 3d, textured, or glittery are flat printed images and are proudly printed in the USA.",
                                "category": "Women > Beauty > Tools > Mirrors |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 17.95,
                                "currency": "USD"
                            },
                            "fingerprint": "6fbd2148d02f137fdb4df9e3a7eac338",
                            "created_at": "2018-05-01T00:28:48.506Z",
                            "updated_at": "2018-06-18T20:18:08.446Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e765b77b36100f19e9e0",
                            "schema": "product",
                            "score": 0.8406785488128662,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/7d/26/7d26d5011cb95aec276e4e17d606a687_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "716846003_Silver/Solid Grey:716846003:Silver/Solid Grey:https://www.shopstyle.com/g/women/saint-laurent/sl-182-metal-betty-sunglasses",
                                "sku": "716846003",
                                "name": "Saint Laurent SL 182 Metal Betty Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/sl-182-metal-betty-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "Silver/Solid Grey",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Mirrored frames Soft case Cleaning cloth included Shield frame Non-polarized lenses Made in Italy Style # YVESL20164 Measurements Width: 6in / 15cm Height: 2.5in / 6.5cm Lens Width: 68mm",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 530,
                                "currency": "USD"
                            },
                            "fingerprint": "ea9286cb4b85b77892c450bb6abf01f8",
                            "created_at": "2018-05-01T22:17:09.854Z",
                            "updated_at": "2018-06-26T23:42:19.889Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fadc6efb8d3d2f8c3c46",
                            "schema": "product",
                            "score": 0.8389666557312012,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/38/da/38daa07c656265015e558b0f9d9c150d_best.jpg",
                                    "width": 336,
                                    "height": 328
                                }
                            ],
                            "data": {
                                "id": "651083859_White:651083859:White:https://www.shopstyle.com/g/men/nike/legend-65mm-multi-sport-sunglasses",
                                "sku": "651083859",
                                "name": "Nike Legend 65mm Multi-Sport Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/nike/legend-65mm-multi-sport-sunglasses",
                                "brand": "Nike",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Lightweight, durable and versatile enough to keep the sun out of your eyes in any outdoor activity, these action-ready sunglasses are constructed with Nike Max Optics to provide enhanced clarity from every angle in an 8-base sport-wrap frame for maximum coverage. High-tension temple hinges ensure a stay-put fit that makes these shades an ideal choice for runs, workouts and more. Style Name:Nike Legend 65Mm Multi-Sport Sunglasses. Style Number: 5417633. Available in stores.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 79,
                                "currency": "USD"
                            },
                            "fingerprint": "70ea91c7ba2507d37a5933b3991212bc",
                            "created_at": "2018-05-01T23:40:12.209Z",
                            "updated_at": "2018-06-23T01:25:09.223Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0f1b77b36100f161f43",
                            "schema": "product",
                            "score": 0.8382883453369141,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5e/3f/5e3faee81a2349c095deb113b51fa4b8_best.jpg",
                                    "width": 496,
                                    "height": 224
                                }
                            ],
                            "data": {
                                "id": "437780163_White:437780163:White:https://www.shopstyle.com/p/kreed-mens-makin-it-big-square-sunglasses/437780163",
                                "sku": "437780163",
                                "name": "Kreed Men's Makin It Big Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/kreed-mens-makin-it-big-square-sunglasses/437780163",
                                "brand": "Kreed",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "You can never go wrong with a classic. You canâ€TMt help but feel great wearing this 80s-inspired wayfarer frame!",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "57 mm",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35,
                                "currency": "USD"
                            },
                            "fingerprint": "aa8f881b8362e3383c2e5c310039394e",
                            "created_at": "2018-05-01T21:49:37.971Z",
                            "updated_at": "2018-06-23T01:24:27.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b9e1b77b36100f148c61",
                            "schema": "product",
                            "score": 0.8378244590759277,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/8b/37/8b378c307289256ca668069717f71b7d_best.jpg",
                                    "width": 350,
                                    "height": 322
                                }
                            ],
                            "data": {
                                "id": "651083831_Green:651083831:Green:https://www.shopstyle.com/g/men/nike/legend-65mm-mirrored-multi-sport-sunglasses",
                                "sku": "651083831",
                                "name": "Nike Legend 65mm Mirrored Multi-Sport Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/nike/legend-65mm-mirrored-multi-sport-sunglasses",
                                "brand": "Nike",
                                "color": "Green",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Lightweight, durable and versatile enough to keep the sun out of your eyes in any outdoor activity, these cool reflective sunglasses are constructed with Nike Max Optics to provide enhanced clarity from every angle in an 8-base sport-wrap frame for maximum coverage. High-tension temple hinges ensure a stay-put fit that makes these shades an ideal choice for runs, workouts and more. Style Name:Nike Legend 65Mm Mirrored Multi-Sport Sunglasses. Style Number: 5417620. Available in stores.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 99,
                                "currency": "USD"
                            },
                            "fingerprint": "a3c548eaa4c0b639b6b8fd11ccd808a4",
                            "created_at": "2018-05-01T19:02:57.938Z",
                            "updated_at": "2018-06-23T01:24:23.167Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ba3eb77b36100f14a5a4",
                            "schema": "product",
                            "score": 0.8373895645141601,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/90/06/900657cf03bbf2df777cef167248808d_best.jpg",
                                    "width": 285,
                                    "height": 276
                                }
                            ],
                            "data": {
                                "id": "719127092_Grey:719127092:Grey:https://www.shopstyle.com/g/men/gucci/57mm-square-sunglasses",
                                "sku": "719127092",
                                "name": "Gucci 57MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/gucci/57mm-square-sunglasses",
                                "brand": "Gucci",
                                "color": "Grey",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Must-have sunglasses elevated with abstract design on temples 57mm lens width; 17mm bridge width; 145mm temple length Case and cleaning cloth included Gradient lenses Plastic Made in Italy",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 290,
                                "currency": "USD"
                            },
                            "fingerprint": "78da1add68eb7ed04ac2648a098b9634",
                            "created_at": "2018-05-01T19:04:30.788Z",
                            "updated_at": "2018-06-18T15:24:04.833Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8f13a6efb8d3d2f86d331",
                            "schema": "product",
                            "score": 0.8364857292175293,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/f4/24/f424f170028130dff8dd8e1fe794bf9a_best.jpg",
                                    "width": 720,
                                    "height": 245
                                }
                            ],
                            "data": {
                                "id": "642655114_CLEAR/BLACK PRIZM POLARIZED:642655114:CLEAR/BLACK PRIZM POLARIZED:https://www.shopstyle.com/g/men/oakley/polarized-turbine-rotor-prizm-sunglasses-oo9307-32",
                                "sku": "642655114",
                                "name": "Oakley Polarized Turbine Rotor Prizm Sunglasses, OO9307 32",
                                "url": "https://www.shopstyle.com/g/men/oakley/polarized-turbine-rotor-prizm-sunglasses-oo9307-32",
                                "brand": "Oakley",
                                "color": "CLEAR/BLACK PRIZM POLARIZED",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Devoted to making the best sunglasses on the planet by letting invention lead the way. With a passion to reinvent from scratch, Oakley sunglasses defy convention and set the standard for design, performance, and protection by wrapping innovation in art.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 203,
                                "currency": "USD"
                            },
                            "fingerprint": "45a0a8086aae0c556320c0e597f96e46",
                            "created_at": "2018-05-01T22:59:06.438Z",
                            "updated_at": "2018-06-25T18:20:08.836Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-16",
            "index": 16,
            "timestamp": 3200,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000017.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "d8ede7c4-8271-4a73-98a4-398e8d440b9b",
                    "score": 0.9857125679651896,
                    "category": {
                        "_type": "Category",
                        "_id": "pants",
                        "name": "pants"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 117.7945785522461,
                        "y": 398.02880859375,
                        "width": 174.88092803955078,
                        "height": 296.8316650390625
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9030ea2773810155abae8",
                            "schema": "product",
                            "score": 0.9190283489227296,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/f0/0c/f00c02fbc09deb7244d0fa0e7a055f60_best.jpg",
                                    "width": 383,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "623405262_Bright blue:623405262:Bright blue:https://www.shopstyle.com/p/gallego-desportes-casual-pants/623405262",
                                "sku": "623405262",
                                "name": "GALLEGO DESPORTES Casual pants",
                                "url": "https://www.shopstyle.com/p/gallego-desportes-casual-pants/623405262",
                                "brand": "GALLEGO DESPORTES",
                                "color": "Bright blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "GALLEGO DESPORTES Casual pants. plain weave, solid color, high waisted, comfort fit, wide leg, no appliqués, 3 buttons, multipockets, pants. 100% Cotton",
                                "category": "Women > Clothing > Pants > Casual |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 220,
                                "currency": "USD"
                            },
                            "fingerprint": "762ba0ec532ef1721ae6a9be5b45a66a",
                            "created_at": "2018-05-02T00:15:10.522Z",
                            "updated_at": "2018-07-05T23:49:56.486Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e4b9b77b36100f185ad5",
                            "schema": "product",
                            "score": 0.9182834911346436,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/d7/f0/d7f062195d11e643d09e6866ab5092d3_best.jpg",
                                    "width": 406,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "709920032_Bright blue:709920032:Bright blue:https://www.shopstyle.com/g/women/etro/casual-pants",
                                "sku": "709920032",
                                "name": "Etro Casual pants",
                                "url": "https://www.shopstyle.com/g/women/etro/casual-pants",
                                "brand": "Etro",
                                "color": "Bright blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "ETRO Casual pants. plain weave, no appliqués, solid color, mid rise, regular fit, straight leg, button, zip, multipockets, stretch, tailored. 97% Cotton, 3% Elastane",
                                "category": "Women > Clothing > Pants > Casual |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 319,
                                "currency": "USD"
                            },
                            "fingerprint": "3155f444845a9ba939acca9da3502011",
                            "created_at": "2018-05-01T22:05:45.126Z",
                            "updated_at": "2018-07-06T00:04:51.865Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b7f7a27738101554fa0d",
                            "schema": "product",
                            "score": 0.9176015663146972,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/75/e2/75e244d0acac11628d6ec4c56e7ecaae_best.jpg",
                                    "width": 624,
                                    "height": 800
                                }
                            ],
                            "data": {
                                "id": "697798873_Blue:697798873:Blue:https://www.shopstyle.com/g/women/claudie-pierlot/petula-crepe-trousers",
                                "sku": "697798873",
                                "name": "Claudie Pierlot Petula crepe trousers",
                                "url": "https://www.shopstyle.com/g/women/claudie-pierlot/petula-crepe-trousers",
                                "brand": "Claudie Pierlot",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Whether it’s workwear or casualwear, the Petula trousers from Parisian brand Claudie Pierlot are guaranteed to step your outfits up a couple of notches. Crafted from crepe, this tapered pair holds its shape well and falls just above the ankle for a flattering silhouette. The belt at the front is adorned with a bold bow so deciding how to wear this style is a no brainer: a shirt tucked in, of course. Claudie Pierlot crepe trousersConcealed hook and eye and zip fastening at frontRegular fit, tapered, high rise, belt loops, belt with bow detail at waist, two slip pockets at front, pressed centre creases at front and back, two welt pockets at back, partially lined70% triacetate, 30% polyester; 100% acetate Dry cleanTrue to sizeSize 8: Rise 13”, inside leg 24\", leg opening 12\"Model is 5ft 10\" and wears a size 8",
                                "category": "Women > Clothing > Pants |  | ",
                                "size": "10",
                                "age_group": "adult",
                                "stock": true,
                                "price": 280,
                                "currency": "USD"
                            },
                            "fingerprint": "b236462ea19330febe85335b4c329803",
                            "created_at": "2018-05-01T18:54:47.609Z",
                            "updated_at": "2018-06-28T14:51:20.050Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ad04a2773810154fc342",
                            "schema": "product",
                            "score": 0.9161422061920166,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/00/2a/002a27e60cd08d1c0fe3279f5e694298_best.jpg",
                                    "width": 258,
                                    "height": 500
                                }
                            ],
                            "data": {
                                "id": "720921589_Captain America:720921589:Captain America:https://www.shopstyle.com/p/opposuits-official-marvel-comics-hero-suits-of-avengers-costume-comes-with-pants/720921589",
                                "sku": "720921589",
                                "name": "Opposuits Official Marvel Comics Hero Suits Of - Avengers Costume Comes With Pants",
                                "url": "https://www.shopstyle.com/p/opposuits-official-marvel-comics-hero-suits-of-avengers-costume-comes-with-pants/720921589",
                                "brand": "Opposuits",
                                "color": "Captain America",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Are you a real Superhero fan? In that case you've come to the right place! We offer suits with several Marvel figures on it: The Hulk, Iron Man, Spider Man and Captain-America, they're all there. This means you'll have the ability to wear some legends on your sleeve. And which Marvel lover hasn't dreamt about that? No matter which action figure suit you'll pick, with one of these avengers outfits you'll definitely steal the show on many occasions; from theme parties to Comic Con and movie premieres to Halloween. Get yours now!",
                                "category": "Men > Clothing > Pants |  | ",
                                "size": "US36",
                                "age_group": "adult",
                                "stock": true,
                                "price": 129.99,
                                "currency": "USD"
                            },
                            "fingerprint": "46e62819e12aa72126d8ab36b2747710",
                            "created_at": "2018-05-01T18:08:04.811Z",
                            "updated_at": "2018-06-26T23:32:23.400Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8faef6efb8d3d2f8c46fd",
                            "schema": "product",
                            "score": 0.9151241683959961,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/72/f2/72f2c7301e1227d2252df68671736287_best.jpg",
                                    "width": 335,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "700494461_Bright blue:700494461:Bright blue:https://www.shopstyle.com/g/women/alexander-mcqueen/mcq-casual-pants",
                                "sku": "700494461",
                                "name": "Alexander McQueen McQ Casual pants",
                                "url": "https://www.shopstyle.com/g/women/alexander-mcqueen/mcq-casual-pants",
                                "brand": "Alexander McQueen",
                                "color": "Bright blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "McQ Alexander McQueen Casual pants. crepe, solid color, mid rise, comfort fit, straight leg, no appliqués, drawstring closure, multipockets, pants, large sized. 73% Triacetate, 27% Polyester",
                                "category": "Women > Clothing > Pants > Casual |  | ",
                                "size": "0",
                                "age_group": "adult",
                                "stock": true,
                                "price": 289,
                                "currency": "USD"
                            },
                            "fingerprint": "8854dd5461b31bf1d1ac412702bcddeb",
                            "created_at": "2018-05-01T23:40:31.426Z",
                            "updated_at": "2018-06-19T00:58:08.419Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b53860cf5e48d63d2989",
                            "schema": "product",
                            "score": 0.9133417320251465,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/75/c6/75c616b1fa98736601aca80fb3442c2e_best.jpg",
                                    "width": 414,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679590214_Blue:679590214:Blue:https://www.shopstyle.com/g/women/berwich/casual-pants",
                                "sku": "679590214",
                                "name": "Berwich Casual pants",
                                "url": "https://www.shopstyle.com/g/women/berwich/casual-pants",
                                "brand": "Berwich",
                                "color": "Blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "BERWICH Casual pants. plain weave, detachable application, solid color, high waisted, regular fit, tapered leg, button, zip, multipockets, pants. 100% Cotton",
                                "category": "Women > Clothing > Pants > Casual |  | ",
                                "size": "8",
                                "age_group": "adult",
                                "stock": true,
                                "price": 174,
                                "currency": "USD"
                            },
                            "fingerprint": "294469689b4f4ca4f81583931938cac1",
                            "created_at": "2018-05-01T00:30:48.142Z",
                            "updated_at": "2018-06-28T13:31:26.951Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8f4796efb8d3d2f88ae0f",
                            "schema": "product",
                            "score": 0.9126931571960449,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/ae/f1/aef18b74104a11a8d1c5d8f2dc1380b5_best.jpg",
                                    "width": 414,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "686045818_Bright blue:686045818:Bright blue:https://www.shopstyle.com/p/mirella-matteini-casual-pants/686045818",
                                "sku": "686045818",
                                "name": "MIRELLA MATTEINI Casual pants",
                                "url": "https://www.shopstyle.com/p/mirella-matteini-casual-pants/686045818",
                                "brand": "MIRELLA MATTEINI",
                                "color": "Bright blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MIRELLA MATTEINI Casual pants. crepe, sash, solid color, mid rise, regular fit, wide leg, zip, no pockets, stretch, tailored. 95% Polyester, 5% Elastane",
                                "category": "Women > Clothing > Pants > Casual |  | ",
                                "size": "6",
                                "age_group": "adult",
                                "stock": true,
                                "price": 129,
                                "currency": "USD"
                            },
                            "fingerprint": "7d1fc075d527d83556005828c888da23",
                            "created_at": "2018-05-01T23:12:57.734Z",
                            "updated_at": "2018-07-05T23:54:49.425Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e469b77b36100f182c56",
                            "schema": "product",
                            "score": 0.9125935173034668,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/31/9b/319bf9a7bdb757c53e2d3186a1d528e0_best.jpg",
                                    "width": 194,
                                    "height": 496
                                }
                            ],
                            "data": {
                                "id": "718572584_BLUE:718572584:BLUE:https://www.shopstyle.com/p/victoria-victoria-beckham-flat-front-slim-straight-leg-ankle-length-wool-pants/718572584",
                                "sku": "718572584",
                                "name": "Victoria Victoria Beckham Flat-Front Slim Straight-Leg Ankle-Length Wool Pants",
                                "url": "https://www.shopstyle.com/p/victoria-victoria-beckham-flat-front-slim-straight-leg-ankle-length-wool-pants/718572584",
                                "brand": "Victoria Victoria Beckham",
                                "color": "BLUE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Victoria Victoria Beckham wool pants. Mid-rise; belt loops. Flat hook-zip fly. Side slip pockets; back besom pockets. Slim fit through straight legs. Ankle-length. Made in UK.",
                                "category": "Women > Clothing > Pants |  | ",
                                "size": "14 UK (10 US)",
                                "age_group": "adult",
                                "stock": true,
                                "price": 540,
                                "currency": "USD"
                            },
                            "fingerprint": "cb1376fae08877abf9b0d3dc0941f3a3",
                            "created_at": "2018-05-01T22:04:25.508Z",
                            "updated_at": "2018-07-06T00:20:07.089Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d99a1393dd3d29169e13",
                            "schema": "product",
                            "score": 0.912486400604248,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/3b/c6/3bc6315fed804da2e6f155dd103a1a6e_best.jpg",
                                    "width": 364,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "680250334_Bright blue:680250334:Bright blue:https://www.shopstyle.com/g/women/moschino/casual-pants",
                                "sku": "680250334",
                                "name": "Moschino Casual pants",
                                "url": "https://www.shopstyle.com/g/women/moschino/casual-pants",
                                "brand": "Moschino",
                                "color": "Bright blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "BOUTIQUE MOSCHINO Casual pants. plain weave, no appliqués, basic solid color, mid rise, comfort fit, wide leg, button, zip, multipockets, cuffed hems, tailored. 96% Cotton, 4% Other Fibres",
                                "category": "Women > Clothing > Pants > Casual |  | ",
                                "size": "4",
                                "age_group": "adult",
                                "stock": true,
                                "price": 369,
                                "currency": "USD"
                            },
                            "fingerprint": "9b12172ac1e451af4b070539eed07fee",
                            "created_at": "2018-05-01T21:18:18.097Z",
                            "updated_at": "2018-07-06T00:23:08.878Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b66c60cf5e48d63dd64e",
                            "schema": "product",
                            "score": 0.9112092971801757,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b5/65/b565d67169208bbad2afeae29211c1be_best.jpg",
                                    "width": 202,
                                    "height": 616
                                }
                            ],
                            "data": {
                                "id": "693388822_blue:693388822:blue:https://www.shopstyle.com/g/women/joseph/crepe-carrot-trousers",
                                "sku": "693388822",
                                "name": "Joseph Crepe Carrot Trousers",
                                "url": "https://www.shopstyle.com/g/women/joseph/crepe-carrot-trousers",
                                "brand": "Joseph",
                                "color": "blue",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Royal blue pants in a soft and comfortable carrot shape. Make sure to cinch your waist with the accompanying black belt. 64% Viscose, 33% Acetate, 3% Elastane",
                                "category": "Women > Clothing > Pants |  | ",
                                "size": "FR36 - US4",
                                "age_group": "adult",
                                "stock": true,
                                "price": 550,
                                "currency": "USD"
                            },
                            "fingerprint": "2cb3250353d67742528eb81721916b7d",
                            "created_at": "2018-05-01T00:35:56.554Z",
                            "updated_at": "2018-06-28T13:58:19.265Z"
                        }
                    ]
                },
                {
                    "_type": "SearchResult",
                    "_id": "90e2c440-14a2-4320-a415-2569215ce130",
                    "score": 0.9494697478684512,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 202.07461547851562,
                        "y": 170.6313934326172,
                        "width": 55.96148681640625,
                        "height": 16.0760498046875
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c26d6efb8d3d2f80ccba",
                            "schema": "product",
                            "score": 0.8650787830352783,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/56/7a/567a6cddec9b5b7b582d42e820acbe66_best.jpg",
                                    "width": 720,
                                    "height": 858
                                }
                            ],
                            "data": {
                                "id": "717496724_White:717496724:White:https://www.shopstyle.com/g/women/sonic-editions/john-cale-lou-reed-and-warhol-by-waring-abbott-framed",
                                "sku": "717496724",
                                "name": "Sonic Editions John Cale, Lou Reed and Warhol by Waring Abbott (Framed)",
                                "url": "https://www.shopstyle.com/g/women/sonic-editions/john-cale-lou-reed-and-warhol-by-waring-abbott-framed",
                                "brand": "Sonic Editions",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "John Cale, Lou Reed and Warhol Framed Print: *Framed art print *Photograph of John Cale and Lou Reed, at a photo shoot in New York City. Photo of Andy Warhol in background. *Photograph by Waring Abbott *Quality digital C-Type prints *High-quality handmade wooden frame *Acid-free and cut to order mount *Shatter resistant Perspex",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "12x16",
                                "age_group": "adult",
                                "stock": true,
                                "price": 195,
                                "currency": "USD"
                            },
                            "fingerprint": "ce7e97654f9748b989ec4140ecbb1aeb",
                            "created_at": "2018-05-01T19:39:25.326Z",
                            "updated_at": "2018-06-18T22:18:09.053Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae900666efb8d3d2f8f36fe",
                            "schema": "product",
                            "score": 0.8461506271362305,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/35/1c/351c70b19c5522e1c5e04450ebc7876f_best.jpg",
                                    "width": 272,
                                    "height": 273
                                }
                            ],
                            "data": {
                                "id": "648557865_Black:648557865:Black:https://www.shopstyle.com/g/men/christian-dior/walk-51mm-square-sunglasses",
                                "sku": "648557865",
                                "name": "Christian Dior Walk 51MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/christian-dior/walk-51mm-square-sunglasses",
                                "brand": "Christian Dior",
                                "color": "Black",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Transparent square frame with contrast arms.51mm lens width; 19mm bridge width; 150mm temple length.100% UV protection. Acetate. Made in Italy.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "ONE SIZE",
                                "age_group": "adult",
                                "stock": true,
                                "price": 320,
                                "currency": "USD"
                            },
                            "fingerprint": "ba88d3f527c7eb81659e20c9b172248a",
                            "created_at": "2018-05-02T00:03:50.172Z",
                            "updated_at": "2018-06-23T01:25:09.460Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d12a1393dd3d291213a2",
                            "schema": "product",
                            "score": 0.8415222072601318,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/dc/c3/dcc3d05785f618419e95a64ddb41cf81_best.jpg",
                                    "width": 720,
                                    "height": 344
                                }
                            ],
                            "data": {
                                "id": "689036426_BLACK:689036426:BLACK:https://www.shopstyle.com/g/women/tom-ford/womens-ft-9280-50f-59mm-sunglasses",
                                "sku": "689036426",
                                "name": "Tom Ford Women's Ft_9280_50F 59Mm Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/tom-ford/womens-ft-9280-50f-59mm-sunglasses",
                                "brand": "Tom Ford",
                                "color": "BLACK",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Frame shape: cat eye Frame color: striped brown Lens color: gradient brown These frames flatter those with an oval, round, diamond, triangle or oblong shaped face Plastic lenses with 100% UV protection Lens: 59mm wide Bridge: 16mm wide Arms: 135mm long Case included; case size and color may vary Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "NoSize",
                                "age_group": "adult",
                                "stock": true,
                                "price": 450,
                                "currency": "USD"
                            },
                            "fingerprint": "918350b9a0677055a8d8b10f8bbb6f72",
                            "created_at": "2018-05-01T20:42:18.071Z",
                            "updated_at": "2018-06-19T01:43:07.001Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b4c060cf5e48d63ce2c4",
                            "schema": "product",
                            "score": 0.8414638328552246,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/52/45/524587dc6baca3f3cadeaa65967b90db_best.jpg",
                                    "width": 490,
                                    "height": 328
                                }
                            ],
                            "data": {
                                "id": "664398819:664398819::https://www.shopstyle.com/p/rikki-knight-hipster-giraffes-with-colorful-glasses-design-compact-mirror/664398819",
                                "sku": "664398819",
                                "name": "Rikki Knight Hipster Giraffes with Colorful Glasses Design Compact Mirror",
                                "url": "https://www.shopstyle.com/p/rikki-knight-hipster-giraffes-with-colorful-glasses-design-compact-mirror/664398819",
                                "brand": "Rikki Knight",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "This Rikki Knight Hipster Giraffes with colorful glasses Compact Mirror is the perfect sized mirror to slip into your pocket, purse, bag, pencil case, or any bag and features quality vibrant Images that will not fade with time. The compact has two mirrors on the inside and features an easy hinged open close latch. If you are planning to buy this for someone else once you see it, it will be hard to part from it. When you purchase a Rikki Knight Brand you can count on satisfaction; This Rikki knight Hipster Giraffes with colorful glasses could be an ideal gift for all occasions; Disclaimer: All images are printed directly into the material - The Texture of the image will always be a flat surface. Any images that appears to be 3d, textured, or glittery are flat printed images and are proudly printed in the USA.",
                                "category": "Women > Beauty > Tools > Mirrors |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 17.95,
                                "currency": "USD"
                            },
                            "fingerprint": "6fbd2148d02f137fdb4df9e3a7eac338",
                            "created_at": "2018-05-01T00:28:48.506Z",
                            "updated_at": "2018-06-18T20:18:08.446Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e765b77b36100f19e9e0",
                            "schema": "product",
                            "score": 0.8406785488128662,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/7d/26/7d26d5011cb95aec276e4e17d606a687_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "716846003_Silver/Solid Grey:716846003:Silver/Solid Grey:https://www.shopstyle.com/g/women/saint-laurent/sl-182-metal-betty-sunglasses",
                                "sku": "716846003",
                                "name": "Saint Laurent SL 182 Metal Betty Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/sl-182-metal-betty-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "Silver/Solid Grey",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Mirrored frames Soft case Cleaning cloth included Shield frame Non-polarized lenses Made in Italy Style # YVESL20164 Measurements Width: 6in / 15cm Height: 2.5in / 6.5cm Lens Width: 68mm",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 530,
                                "currency": "USD"
                            },
                            "fingerprint": "ea9286cb4b85b77892c450bb6abf01f8",
                            "created_at": "2018-05-01T22:17:09.854Z",
                            "updated_at": "2018-06-26T23:42:19.889Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fadc6efb8d3d2f8c3c46",
                            "schema": "product",
                            "score": 0.8389666557312012,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/38/da/38daa07c656265015e558b0f9d9c150d_best.jpg",
                                    "width": 336,
                                    "height": 328
                                }
                            ],
                            "data": {
                                "id": "651083859_White:651083859:White:https://www.shopstyle.com/g/men/nike/legend-65mm-multi-sport-sunglasses",
                                "sku": "651083859",
                                "name": "Nike Legend 65mm Multi-Sport Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/nike/legend-65mm-multi-sport-sunglasses",
                                "brand": "Nike",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Lightweight, durable and versatile enough to keep the sun out of your eyes in any outdoor activity, these action-ready sunglasses are constructed with Nike Max Optics to provide enhanced clarity from every angle in an 8-base sport-wrap frame for maximum coverage. High-tension temple hinges ensure a stay-put fit that makes these shades an ideal choice for runs, workouts and more. Style Name:Nike Legend 65Mm Multi-Sport Sunglasses. Style Number: 5417633. Available in stores.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 79,
                                "currency": "USD"
                            },
                            "fingerprint": "70ea91c7ba2507d37a5933b3991212bc",
                            "created_at": "2018-05-01T23:40:12.209Z",
                            "updated_at": "2018-06-23T01:25:09.223Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0f1b77b36100f161f43",
                            "schema": "product",
                            "score": 0.8382883453369141,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5e/3f/5e3faee81a2349c095deb113b51fa4b8_best.jpg",
                                    "width": 496,
                                    "height": 224
                                }
                            ],
                            "data": {
                                "id": "437780163_White:437780163:White:https://www.shopstyle.com/p/kreed-mens-makin-it-big-square-sunglasses/437780163",
                                "sku": "437780163",
                                "name": "Kreed Men's Makin It Big Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/kreed-mens-makin-it-big-square-sunglasses/437780163",
                                "brand": "Kreed",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "You can never go wrong with a classic. You canâ€TMt help but feel great wearing this 80s-inspired wayfarer frame!",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "57 mm",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35,
                                "currency": "USD"
                            },
                            "fingerprint": "aa8f881b8362e3383c2e5c310039394e",
                            "created_at": "2018-05-01T21:49:37.971Z",
                            "updated_at": "2018-06-23T01:24:27.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b9e1b77b36100f148c61",
                            "schema": "product",
                            "score": 0.8378244590759277,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/8b/37/8b378c307289256ca668069717f71b7d_best.jpg",
                                    "width": 350,
                                    "height": 322
                                }
                            ],
                            "data": {
                                "id": "651083831_Green:651083831:Green:https://www.shopstyle.com/g/men/nike/legend-65mm-mirrored-multi-sport-sunglasses",
                                "sku": "651083831",
                                "name": "Nike Legend 65mm Mirrored Multi-Sport Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/nike/legend-65mm-mirrored-multi-sport-sunglasses",
                                "brand": "Nike",
                                "color": "Green",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Lightweight, durable and versatile enough to keep the sun out of your eyes in any outdoor activity, these cool reflective sunglasses are constructed with Nike Max Optics to provide enhanced clarity from every angle in an 8-base sport-wrap frame for maximum coverage. High-tension temple hinges ensure a stay-put fit that makes these shades an ideal choice for runs, workouts and more. Style Name:Nike Legend 65Mm Mirrored Multi-Sport Sunglasses. Style Number: 5417620. Available in stores.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 99,
                                "currency": "USD"
                            },
                            "fingerprint": "a3c548eaa4c0b639b6b8fd11ccd808a4",
                            "created_at": "2018-05-01T19:02:57.938Z",
                            "updated_at": "2018-06-23T01:24:23.167Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ba3eb77b36100f14a5a4",
                            "schema": "product",
                            "score": 0.8373895645141601,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/90/06/900657cf03bbf2df777cef167248808d_best.jpg",
                                    "width": 285,
                                    "height": 276
                                }
                            ],
                            "data": {
                                "id": "719127092_Grey:719127092:Grey:https://www.shopstyle.com/g/men/gucci/57mm-square-sunglasses",
                                "sku": "719127092",
                                "name": "Gucci 57MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/g/men/gucci/57mm-square-sunglasses",
                                "brand": "Gucci",
                                "color": "Grey",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Must-have sunglasses elevated with abstract design on temples 57mm lens width; 17mm bridge width; 145mm temple length Case and cleaning cloth included Gradient lenses Plastic Made in Italy",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 290,
                                "currency": "USD"
                            },
                            "fingerprint": "78da1add68eb7ed04ac2648a098b9634",
                            "created_at": "2018-05-01T19:04:30.788Z",
                            "updated_at": "2018-06-18T15:24:04.833Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8f13a6efb8d3d2f86d331",
                            "schema": "product",
                            "score": 0.8364857292175293,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/f4/24/f424f170028130dff8dd8e1fe794bf9a_best.jpg",
                                    "width": 720,
                                    "height": 245
                                }
                            ],
                            "data": {
                                "id": "642655114_CLEAR/BLACK PRIZM POLARIZED:642655114:CLEAR/BLACK PRIZM POLARIZED:https://www.shopstyle.com/g/men/oakley/polarized-turbine-rotor-prizm-sunglasses-oo9307-32",
                                "sku": "642655114",
                                "name": "Oakley Polarized Turbine Rotor Prizm Sunglasses, OO9307 32",
                                "url": "https://www.shopstyle.com/g/men/oakley/polarized-turbine-rotor-prizm-sunglasses-oo9307-32",
                                "brand": "Oakley",
                                "color": "CLEAR/BLACK PRIZM POLARIZED",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Devoted to making the best sunglasses on the planet by letting invention lead the way. With a passion to reinvent from scratch, Oakley sunglasses defy convention and set the standard for design, performance, and protection by wrapping innovation in art.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 203,
                                "currency": "USD"
                            },
                            "fingerprint": "45a0a8086aae0c556320c0e597f96e46",
                            "created_at": "2018-05-01T22:59:06.438Z",
                            "updated_at": "2018-06-25T18:20:08.836Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-17",
            "index": 17,
            "timestamp": 3400,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000018.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "3bba3bd4-2033-4073-9186-0d9ca2d3fa14",
                    "score": 0.9762849522673566,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 126.1185073852539,
                        "y": 176.7701873779297,
                        "width": 135.6821517944336,
                        "height": 62.09552001953125
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fe756efb8d3d2f8e281e",
                            "schema": "product",
                            "score": 0.9052010536193847,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/44/a3443255733002d9efc94df59b77be79_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679796721_White:679796721:White:https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "sku": "679796721",
                                "name": "Quay 20s Oversized Square Sunglasses In White",
                                "url": "https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Kylie Jenner, Oversized square frames, Moulded nose pads for added comfort, Dark tinted lenses, Branded arms with curved temple tips for a secure fit, Good UV protection. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 80,
                                "currency": "USD"
                            },
                            "fingerprint": "09ff363e17d04cb01af9d2d6c673c7e2",
                            "created_at": "2018-05-01T23:55:33.921Z",
                            "updated_at": "2018-06-23T01:24:21.222Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cc1f1393dd3d290f39c8",
                            "schema": "product",
                            "score": 0.9019440078735351,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/42/b5/42b5c0cbf4c22387219eaaaf97788c14_best.jpg",
                                    "width": 720,
                                    "height": 368
                                }
                            ],
                            "data": {
                                "id": "704685010_IVORY:704685010:IVORY:https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "sku": "704685010",
                                "name": "Saint Laurent Square Acetate Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "IVORY",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Saint Laurent acetate sunglasses. Square frames. Dimensions (in mm): 57-11-140. Thin, tapered arms. 100% UVA/UVB protection. Made in Italy.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 250,
                                "currency": "USD"
                            },
                            "fingerprint": "aed55f70d5281b3ee4bc83e9a83e3b48",
                            "created_at": "2018-05-01T20:20:47.778Z",
                            "updated_at": "2018-06-18T22:48:08.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0efb77b36100f161e01",
                            "schema": "product",
                            "score": 0.8987179946899414,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/15/35/1535cd937adbfe55b95dc478d4c05895_best.jpg",
                                    "width": 720,
                                    "height": 727
                                }
                            ],
                            "data": {
                                "id": "720021299_white:720021299:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "sku": "720021299",
                                "name": "Super by Retrosuperfuture Women's Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "04f62e9a4ae2f9447415128412e93a85",
                            "created_at": "2018-05-01T21:49:35.886Z",
                            "updated_at": "2018-06-23T01:25:18.179Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c3a96efb8d3d2f81425c",
                            "schema": "product",
                            "score": 0.8966365814208984,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/57/6a/576a8a7682f6aa2000e55b90fa797d89_best.jpg",
                                    "width": 264,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "660097270_White:660097270:White:https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "sku": "660097270",
                                "name": "Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "brand": "Super by Retrosuperfuture",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "3b0bc3185003eea9ae599bacb58a6732",
                            "created_at": "2018-05-01T19:44:41.008Z",
                            "updated_at": "2018-06-23T01:24:27.903Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c1bc6efb8d3d2f806363",
                            "schema": "product",
                            "score": 0.8945560836791993,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0c/b1/0cb174192aa75d63f1d740f027f665e5_best.jpg",
                                    "width": 336,
                                    "height": 335
                                }
                            ],
                            "data": {
                                "id": "713296552_Ivory:713296552:Ivory:https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "sku": "713296552",
                                "name": "Seafolly Tortola V2 51mm Polarized Cat Eye Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "brand": "Seafolly",
                                "color": "Ivory",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Bold, blocky frames add to the chic, futuristic look of cat-eye sunglasses fitted with glare-reducing polarized lenses. Style Name:Seafolly Tortola V2 51Mm Polarized Cat Eye Sunglasses. Style Number: 5499557. Available in stores.",
                                "category": "Women > Accessories |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 89,
                                "currency": "USD"
                            },
                            "fingerprint": "38fd2bc7af2879cdc0290c9f9b9c826c",
                            "created_at": "2018-05-01T19:36:28.331Z",
                            "updated_at": "2018-06-18T15:18:05.556Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ff2c6efb8d3d2f8e8a0c",
                            "schema": "product",
                            "score": 0.8936824131011962,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/97/49/97494e0c59a70aeee1f95eb5a4b4dcd3_best.jpg",
                                    "width": 720,
                                    "height": 402
                                }
                            ],
                            "data": {
                                "id": "613926118_WHITE:613926118:WHITE:https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "sku": "613926118",
                                "name": "Stella McCartney Square Plastic Sunglasses w/ Chain Arms, White",
                                "url": "https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "brand": "Stella McCartney",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Stella McCartney plastic sunglasses feature silvertone chain detail at arms. Opaque white square frames. Gradient smoke lenses. Wide, tapered arms. 100% UV protection.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "985424f7206b70676718c07c5c700fe5",
                            "created_at": "2018-05-01T23:58:36.338Z",
                            "updated_at": "2018-06-23T01:24:21.982Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8bbabb77b36100f150f5d",
                            "schema": "product",
                            "score": 0.89348952293396,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a8/01/a8014c544b60be07b756742f5b175d8a_best.jpg",
                                    "width": 720,
                                    "height": 321
                                }
                            ],
                            "data": {
                                "id": "642874259_WHITE:642874259:WHITE:https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "sku": "642874259",
                                "name": "Alexander McQueen Plastic Round Sunglasses, White",
                                "url": "https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "brand": "Alexander McQueen",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Alexander McQueen plastic sunglasses. Round frames. Gradient, brown lenses. Thin arms tapered at ends. Logo at temples. Imported.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "1d08b02aace2fdabfeafab79d90da7d4",
                            "created_at": "2018-05-01T19:10:35.518Z",
                            "updated_at": "2018-06-26T23:42:09.356Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cfe81393dd3d291162f7",
                            "schema": "product",
                            "score": 0.8912718391418457,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/16/11/161140d8ffb916ab75fc3354cf9beec0_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "718814514_White:718814514:White:https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "sku": "718814514",
                                "name": "Quay X Desi Don't At Me Sunglasses In White Smoke",
                                "url": "https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Quay Australia and Desi Perkins, We love a power couple, Chunky frames, Moulded nose pads for added comfort, Dark-tinted lenses, Tapered arms with curved temple tips for a secure fit, Presented in a branded sunglasses case, A safe place for your sunnies. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 65,
                                "currency": "USD"
                            },
                            "fingerprint": "483680d1a8f7812c7dca0f69a640e1d0",
                            "created_at": "2018-05-01T20:36:56.595Z",
                            "updated_at": "2018-06-23T01:25:18.119Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e4c2b77b36100f18605e",
                            "schema": "product",
                            "score": 0.8912650966644287,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/2d/70/2d70d521540bba5bd419509564563abb_best.jpg",
                                    "width": 720,
                                    "height": 715
                                }
                            ],
                            "data": {
                                "id": "720044806_white:720044806:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "sku": "720044806",
                                "name": "Super by Retrosuperfuture Women's Gals Brigitte 52MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Square frame with tinted lens and stud accents 52mm lens width; 22mm bridge width; 140mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 209,
                                "currency": "USD"
                            },
                            "fingerprint": "116e80778d12fb00c8a66034732cdfdf",
                            "created_at": "2018-05-01T22:05:54.117Z",
                            "updated_at": "2018-06-23T01:25:09.987Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cf751393dd3d2911212e",
                            "schema": "product",
                            "score": 0.8912115097045898,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5b/72/5b72ef6a825d866a0e39d77987bcd349_best.jpg",
                                    "width": 720,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "687048457_Matte clear:687048457:Matte clear:https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "sku": "687048457",
                                "name": "Oakley Oo9013 square-frame sunglasses",
                                "url": "https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "brand": "Oakley",
                                "color": "Matte clear",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Founded in 1975 by Jim Jannard, Oakley continues to provide dynamic and streamlined equipment, including a vast eyewear collection. Straight from the coveted Frogskin range, the Oo9013 sunglasses are cut to a square frame with the signature keyhole nose and showcase the American label's modern sensibilities with a rich two tone base and transparent rims.Oakley sunglassesSquare frame, brand lettering, keyhole nose, charcoal lenses, transparent edging, two tone 100% UV protectionFrame width: 13.5cm Fabric pouch",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 109,
                                "currency": "USD"
                            },
                            "fingerprint": "f7403ef1da4d27121ad8e0f15496325d",
                            "created_at": "2018-05-01T20:35:01.468Z",
                            "updated_at": "2018-06-25T17:34:19.798Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-18",
            "index": 18,
            "timestamp": 3600,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000019.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "b50b8b35-f371-464b-9142-d9339ffeadd1",
                    "score": 0.9762849522673566,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 144.9220428466797,
                        "y": 180.45181274414062,
                        "width": 146.46046447753906,
                        "height": 67.50668334960938
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fe756efb8d3d2f8e281e",
                            "schema": "product",
                            "score": 0.9052010536193847,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/44/a3443255733002d9efc94df59b77be79_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679796721_White:679796721:White:https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "sku": "679796721",
                                "name": "Quay 20s Oversized Square Sunglasses In White",
                                "url": "https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Kylie Jenner, Oversized square frames, Moulded nose pads for added comfort, Dark tinted lenses, Branded arms with curved temple tips for a secure fit, Good UV protection. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 80,
                                "currency": "USD"
                            },
                            "fingerprint": "09ff363e17d04cb01af9d2d6c673c7e2",
                            "created_at": "2018-05-01T23:55:33.921Z",
                            "updated_at": "2018-06-23T01:24:21.222Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cc1f1393dd3d290f39c8",
                            "schema": "product",
                            "score": 0.9019440078735351,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/42/b5/42b5c0cbf4c22387219eaaaf97788c14_best.jpg",
                                    "width": 720,
                                    "height": 368
                                }
                            ],
                            "data": {
                                "id": "704685010_IVORY:704685010:IVORY:https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "sku": "704685010",
                                "name": "Saint Laurent Square Acetate Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "IVORY",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Saint Laurent acetate sunglasses. Square frames. Dimensions (in mm): 57-11-140. Thin, tapered arms. 100% UVA/UVB protection. Made in Italy.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 250,
                                "currency": "USD"
                            },
                            "fingerprint": "aed55f70d5281b3ee4bc83e9a83e3b48",
                            "created_at": "2018-05-01T20:20:47.778Z",
                            "updated_at": "2018-06-18T22:48:08.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0efb77b36100f161e01",
                            "schema": "product",
                            "score": 0.8987179946899414,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/15/35/1535cd937adbfe55b95dc478d4c05895_best.jpg",
                                    "width": 720,
                                    "height": 727
                                }
                            ],
                            "data": {
                                "id": "720021299_white:720021299:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "sku": "720021299",
                                "name": "Super by Retrosuperfuture Women's Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "04f62e9a4ae2f9447415128412e93a85",
                            "created_at": "2018-05-01T21:49:35.886Z",
                            "updated_at": "2018-06-23T01:25:18.179Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c3a96efb8d3d2f81425c",
                            "schema": "product",
                            "score": 0.8966365814208984,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/57/6a/576a8a7682f6aa2000e55b90fa797d89_best.jpg",
                                    "width": 264,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "660097270_White:660097270:White:https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "sku": "660097270",
                                "name": "Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "brand": "Super by Retrosuperfuture",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "3b0bc3185003eea9ae599bacb58a6732",
                            "created_at": "2018-05-01T19:44:41.008Z",
                            "updated_at": "2018-06-23T01:24:27.903Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c1bc6efb8d3d2f806363",
                            "schema": "product",
                            "score": 0.8945560836791993,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0c/b1/0cb174192aa75d63f1d740f027f665e5_best.jpg",
                                    "width": 336,
                                    "height": 335
                                }
                            ],
                            "data": {
                                "id": "713296552_Ivory:713296552:Ivory:https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "sku": "713296552",
                                "name": "Seafolly Tortola V2 51mm Polarized Cat Eye Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "brand": "Seafolly",
                                "color": "Ivory",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Bold, blocky frames add to the chic, futuristic look of cat-eye sunglasses fitted with glare-reducing polarized lenses. Style Name:Seafolly Tortola V2 51Mm Polarized Cat Eye Sunglasses. Style Number: 5499557. Available in stores.",
                                "category": "Women > Accessories |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 89,
                                "currency": "USD"
                            },
                            "fingerprint": "38fd2bc7af2879cdc0290c9f9b9c826c",
                            "created_at": "2018-05-01T19:36:28.331Z",
                            "updated_at": "2018-06-18T15:18:05.556Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ff2c6efb8d3d2f8e8a0c",
                            "schema": "product",
                            "score": 0.8936824131011962,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/97/49/97494e0c59a70aeee1f95eb5a4b4dcd3_best.jpg",
                                    "width": 720,
                                    "height": 402
                                }
                            ],
                            "data": {
                                "id": "613926118_WHITE:613926118:WHITE:https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "sku": "613926118",
                                "name": "Stella McCartney Square Plastic Sunglasses w/ Chain Arms, White",
                                "url": "https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "brand": "Stella McCartney",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Stella McCartney plastic sunglasses feature silvertone chain detail at arms. Opaque white square frames. Gradient smoke lenses. Wide, tapered arms. 100% UV protection.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "985424f7206b70676718c07c5c700fe5",
                            "created_at": "2018-05-01T23:58:36.338Z",
                            "updated_at": "2018-06-23T01:24:21.982Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8bbabb77b36100f150f5d",
                            "schema": "product",
                            "score": 0.89348952293396,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a8/01/a8014c544b60be07b756742f5b175d8a_best.jpg",
                                    "width": 720,
                                    "height": 321
                                }
                            ],
                            "data": {
                                "id": "642874259_WHITE:642874259:WHITE:https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "sku": "642874259",
                                "name": "Alexander McQueen Plastic Round Sunglasses, White",
                                "url": "https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "brand": "Alexander McQueen",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Alexander McQueen plastic sunglasses. Round frames. Gradient, brown lenses. Thin arms tapered at ends. Logo at temples. Imported.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "1d08b02aace2fdabfeafab79d90da7d4",
                            "created_at": "2018-05-01T19:10:35.518Z",
                            "updated_at": "2018-06-26T23:42:09.356Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cfe81393dd3d291162f7",
                            "schema": "product",
                            "score": 0.8912718391418457,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/16/11/161140d8ffb916ab75fc3354cf9beec0_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "718814514_White:718814514:White:https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "sku": "718814514",
                                "name": "Quay X Desi Don't At Me Sunglasses In White Smoke",
                                "url": "https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Quay Australia and Desi Perkins, We love a power couple, Chunky frames, Moulded nose pads for added comfort, Dark-tinted lenses, Tapered arms with curved temple tips for a secure fit, Presented in a branded sunglasses case, A safe place for your sunnies. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 65,
                                "currency": "USD"
                            },
                            "fingerprint": "483680d1a8f7812c7dca0f69a640e1d0",
                            "created_at": "2018-05-01T20:36:56.595Z",
                            "updated_at": "2018-06-23T01:25:18.119Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e4c2b77b36100f18605e",
                            "schema": "product",
                            "score": 0.8912650966644287,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/2d/70/2d70d521540bba5bd419509564563abb_best.jpg",
                                    "width": 720,
                                    "height": 715
                                }
                            ],
                            "data": {
                                "id": "720044806_white:720044806:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "sku": "720044806",
                                "name": "Super by Retrosuperfuture Women's Gals Brigitte 52MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Square frame with tinted lens and stud accents 52mm lens width; 22mm bridge width; 140mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 209,
                                "currency": "USD"
                            },
                            "fingerprint": "116e80778d12fb00c8a66034732cdfdf",
                            "created_at": "2018-05-01T22:05:54.117Z",
                            "updated_at": "2018-06-23T01:25:09.987Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cf751393dd3d2911212e",
                            "schema": "product",
                            "score": 0.8912115097045898,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5b/72/5b72ef6a825d866a0e39d77987bcd349_best.jpg",
                                    "width": 720,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "687048457_Matte clear:687048457:Matte clear:https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "sku": "687048457",
                                "name": "Oakley Oo9013 square-frame sunglasses",
                                "url": "https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "brand": "Oakley",
                                "color": "Matte clear",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Founded in 1975 by Jim Jannard, Oakley continues to provide dynamic and streamlined equipment, including a vast eyewear collection. Straight from the coveted Frogskin range, the Oo9013 sunglasses are cut to a square frame with the signature keyhole nose and showcase the American label's modern sensibilities with a rich two tone base and transparent rims.Oakley sunglassesSquare frame, brand lettering, keyhole nose, charcoal lenses, transparent edging, two tone 100% UV protectionFrame width: 13.5cm Fabric pouch",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 109,
                                "currency": "USD"
                            },
                            "fingerprint": "f7403ef1da4d27121ad8e0f15496325d",
                            "created_at": "2018-05-01T20:35:01.468Z",
                            "updated_at": "2018-06-25T17:34:19.798Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-19",
            "index": 19,
            "timestamp": 3800,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000020.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "f1a63f06-33bf-41ec-95ee-8a4c8fee2c05",
                    "score": 0.9762849522673566,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 155.6902618408203,
                        "y": 193.31619262695312,
                        "width": 136.5027313232422,
                        "height": 70.06765747070312
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fe756efb8d3d2f8e281e",
                            "schema": "product",
                            "score": 0.9052010536193847,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/44/a3443255733002d9efc94df59b77be79_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679796721_White:679796721:White:https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "sku": "679796721",
                                "name": "Quay 20s Oversized Square Sunglasses In White",
                                "url": "https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Kylie Jenner, Oversized square frames, Moulded nose pads for added comfort, Dark tinted lenses, Branded arms with curved temple tips for a secure fit, Good UV protection. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 80,
                                "currency": "USD"
                            },
                            "fingerprint": "09ff363e17d04cb01af9d2d6c673c7e2",
                            "created_at": "2018-05-01T23:55:33.921Z",
                            "updated_at": "2018-06-23T01:24:21.222Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cc1f1393dd3d290f39c8",
                            "schema": "product",
                            "score": 0.9019440078735351,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/42/b5/42b5c0cbf4c22387219eaaaf97788c14_best.jpg",
                                    "width": 720,
                                    "height": 368
                                }
                            ],
                            "data": {
                                "id": "704685010_IVORY:704685010:IVORY:https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "sku": "704685010",
                                "name": "Saint Laurent Square Acetate Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "IVORY",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Saint Laurent acetate sunglasses. Square frames. Dimensions (in mm): 57-11-140. Thin, tapered arms. 100% UVA/UVB protection. Made in Italy.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 250,
                                "currency": "USD"
                            },
                            "fingerprint": "aed55f70d5281b3ee4bc83e9a83e3b48",
                            "created_at": "2018-05-01T20:20:47.778Z",
                            "updated_at": "2018-06-18T22:48:08.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0efb77b36100f161e01",
                            "schema": "product",
                            "score": 0.8987179946899414,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/15/35/1535cd937adbfe55b95dc478d4c05895_best.jpg",
                                    "width": 720,
                                    "height": 727
                                }
                            ],
                            "data": {
                                "id": "720021299_white:720021299:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "sku": "720021299",
                                "name": "Super by Retrosuperfuture Women's Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "04f62e9a4ae2f9447415128412e93a85",
                            "created_at": "2018-05-01T21:49:35.886Z",
                            "updated_at": "2018-06-23T01:25:18.179Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c3a96efb8d3d2f81425c",
                            "schema": "product",
                            "score": 0.8966365814208984,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/57/6a/576a8a7682f6aa2000e55b90fa797d89_best.jpg",
                                    "width": 264,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "660097270_White:660097270:White:https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "sku": "660097270",
                                "name": "Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "brand": "Super by Retrosuperfuture",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "3b0bc3185003eea9ae599bacb58a6732",
                            "created_at": "2018-05-01T19:44:41.008Z",
                            "updated_at": "2018-06-23T01:24:27.903Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c1bc6efb8d3d2f806363",
                            "schema": "product",
                            "score": 0.8945560836791993,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0c/b1/0cb174192aa75d63f1d740f027f665e5_best.jpg",
                                    "width": 336,
                                    "height": 335
                                }
                            ],
                            "data": {
                                "id": "713296552_Ivory:713296552:Ivory:https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "sku": "713296552",
                                "name": "Seafolly Tortola V2 51mm Polarized Cat Eye Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "brand": "Seafolly",
                                "color": "Ivory",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Bold, blocky frames add to the chic, futuristic look of cat-eye sunglasses fitted with glare-reducing polarized lenses. Style Name:Seafolly Tortola V2 51Mm Polarized Cat Eye Sunglasses. Style Number: 5499557. Available in stores.",
                                "category": "Women > Accessories |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 89,
                                "currency": "USD"
                            },
                            "fingerprint": "38fd2bc7af2879cdc0290c9f9b9c826c",
                            "created_at": "2018-05-01T19:36:28.331Z",
                            "updated_at": "2018-06-18T15:18:05.556Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ff2c6efb8d3d2f8e8a0c",
                            "schema": "product",
                            "score": 0.8936824131011962,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/97/49/97494e0c59a70aeee1f95eb5a4b4dcd3_best.jpg",
                                    "width": 720,
                                    "height": 402
                                }
                            ],
                            "data": {
                                "id": "613926118_WHITE:613926118:WHITE:https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "sku": "613926118",
                                "name": "Stella McCartney Square Plastic Sunglasses w/ Chain Arms, White",
                                "url": "https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "brand": "Stella McCartney",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Stella McCartney plastic sunglasses feature silvertone chain detail at arms. Opaque white square frames. Gradient smoke lenses. Wide, tapered arms. 100% UV protection.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "985424f7206b70676718c07c5c700fe5",
                            "created_at": "2018-05-01T23:58:36.338Z",
                            "updated_at": "2018-06-23T01:24:21.982Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8bbabb77b36100f150f5d",
                            "schema": "product",
                            "score": 0.89348952293396,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a8/01/a8014c544b60be07b756742f5b175d8a_best.jpg",
                                    "width": 720,
                                    "height": 321
                                }
                            ],
                            "data": {
                                "id": "642874259_WHITE:642874259:WHITE:https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "sku": "642874259",
                                "name": "Alexander McQueen Plastic Round Sunglasses, White",
                                "url": "https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "brand": "Alexander McQueen",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Alexander McQueen plastic sunglasses. Round frames. Gradient, brown lenses. Thin arms tapered at ends. Logo at temples. Imported.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "1d08b02aace2fdabfeafab79d90da7d4",
                            "created_at": "2018-05-01T19:10:35.518Z",
                            "updated_at": "2018-06-26T23:42:09.356Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cfe81393dd3d291162f7",
                            "schema": "product",
                            "score": 0.8912718391418457,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/16/11/161140d8ffb916ab75fc3354cf9beec0_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "718814514_White:718814514:White:https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "sku": "718814514",
                                "name": "Quay X Desi Don't At Me Sunglasses In White Smoke",
                                "url": "https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Quay Australia and Desi Perkins, We love a power couple, Chunky frames, Moulded nose pads for added comfort, Dark-tinted lenses, Tapered arms with curved temple tips for a secure fit, Presented in a branded sunglasses case, A safe place for your sunnies. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 65,
                                "currency": "USD"
                            },
                            "fingerprint": "483680d1a8f7812c7dca0f69a640e1d0",
                            "created_at": "2018-05-01T20:36:56.595Z",
                            "updated_at": "2018-06-23T01:25:18.119Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e4c2b77b36100f18605e",
                            "schema": "product",
                            "score": 0.8912650966644287,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/2d/70/2d70d521540bba5bd419509564563abb_best.jpg",
                                    "width": 720,
                                    "height": 715
                                }
                            ],
                            "data": {
                                "id": "720044806_white:720044806:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "sku": "720044806",
                                "name": "Super by Retrosuperfuture Women's Gals Brigitte 52MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Square frame with tinted lens and stud accents 52mm lens width; 22mm bridge width; 140mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 209,
                                "currency": "USD"
                            },
                            "fingerprint": "116e80778d12fb00c8a66034732cdfdf",
                            "created_at": "2018-05-01T22:05:54.117Z",
                            "updated_at": "2018-06-23T01:25:09.987Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cf751393dd3d2911212e",
                            "schema": "product",
                            "score": 0.8912115097045898,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5b/72/5b72ef6a825d866a0e39d77987bcd349_best.jpg",
                                    "width": 720,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "687048457_Matte clear:687048457:Matte clear:https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "sku": "687048457",
                                "name": "Oakley Oo9013 square-frame sunglasses",
                                "url": "https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "brand": "Oakley",
                                "color": "Matte clear",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Founded in 1975 by Jim Jannard, Oakley continues to provide dynamic and streamlined equipment, including a vast eyewear collection. Straight from the coveted Frogskin range, the Oo9013 sunglasses are cut to a square frame with the signature keyhole nose and showcase the American label's modern sensibilities with a rich two tone base and transparent rims.Oakley sunglassesSquare frame, brand lettering, keyhole nose, charcoal lenses, transparent edging, two tone 100% UV protectionFrame width: 13.5cm Fabric pouch",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 109,
                                "currency": "USD"
                            },
                            "fingerprint": "f7403ef1da4d27121ad8e0f15496325d",
                            "created_at": "2018-05-01T20:35:01.468Z",
                            "updated_at": "2018-06-25T17:34:19.798Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-20",
            "index": 20,
            "timestamp": 4000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000021.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "78bbf983-5eb0-4531-bd7c-a3d843e4e3d4",
                    "score": 0.9762849522673566,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 165.1616668701172,
                        "y": 198.08792114257812,
                        "width": 141.6417999267578,
                        "height": 69.58822631835938
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fe756efb8d3d2f8e281e",
                            "schema": "product",
                            "score": 0.9052010536193847,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/44/a3443255733002d9efc94df59b77be79_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679796721_White:679796721:White:https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "sku": "679796721",
                                "name": "Quay 20s Oversized Square Sunglasses In White",
                                "url": "https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Kylie Jenner, Oversized square frames, Moulded nose pads for added comfort, Dark tinted lenses, Branded arms with curved temple tips for a secure fit, Good UV protection. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 80,
                                "currency": "USD"
                            },
                            "fingerprint": "09ff363e17d04cb01af9d2d6c673c7e2",
                            "created_at": "2018-05-01T23:55:33.921Z",
                            "updated_at": "2018-06-23T01:24:21.222Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cc1f1393dd3d290f39c8",
                            "schema": "product",
                            "score": 0.9019440078735351,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/42/b5/42b5c0cbf4c22387219eaaaf97788c14_best.jpg",
                                    "width": 720,
                                    "height": 368
                                }
                            ],
                            "data": {
                                "id": "704685010_IVORY:704685010:IVORY:https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "sku": "704685010",
                                "name": "Saint Laurent Square Acetate Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "IVORY",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Saint Laurent acetate sunglasses. Square frames. Dimensions (in mm): 57-11-140. Thin, tapered arms. 100% UVA/UVB protection. Made in Italy.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 250,
                                "currency": "USD"
                            },
                            "fingerprint": "aed55f70d5281b3ee4bc83e9a83e3b48",
                            "created_at": "2018-05-01T20:20:47.778Z",
                            "updated_at": "2018-06-18T22:48:08.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0efb77b36100f161e01",
                            "schema": "product",
                            "score": 0.8987179946899414,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/15/35/1535cd937adbfe55b95dc478d4c05895_best.jpg",
                                    "width": 720,
                                    "height": 727
                                }
                            ],
                            "data": {
                                "id": "720021299_white:720021299:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "sku": "720021299",
                                "name": "Super by Retrosuperfuture Women's Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "04f62e9a4ae2f9447415128412e93a85",
                            "created_at": "2018-05-01T21:49:35.886Z",
                            "updated_at": "2018-06-23T01:25:18.179Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c3a96efb8d3d2f81425c",
                            "schema": "product",
                            "score": 0.8966365814208984,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/57/6a/576a8a7682f6aa2000e55b90fa797d89_best.jpg",
                                    "width": 264,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "660097270_White:660097270:White:https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "sku": "660097270",
                                "name": "Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "brand": "Super by Retrosuperfuture",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "3b0bc3185003eea9ae599bacb58a6732",
                            "created_at": "2018-05-01T19:44:41.008Z",
                            "updated_at": "2018-06-23T01:24:27.903Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c1bc6efb8d3d2f806363",
                            "schema": "product",
                            "score": 0.8945560836791993,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0c/b1/0cb174192aa75d63f1d740f027f665e5_best.jpg",
                                    "width": 336,
                                    "height": 335
                                }
                            ],
                            "data": {
                                "id": "713296552_Ivory:713296552:Ivory:https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "sku": "713296552",
                                "name": "Seafolly Tortola V2 51mm Polarized Cat Eye Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "brand": "Seafolly",
                                "color": "Ivory",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Bold, blocky frames add to the chic, futuristic look of cat-eye sunglasses fitted with glare-reducing polarized lenses. Style Name:Seafolly Tortola V2 51Mm Polarized Cat Eye Sunglasses. Style Number: 5499557. Available in stores.",
                                "category": "Women > Accessories |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 89,
                                "currency": "USD"
                            },
                            "fingerprint": "38fd2bc7af2879cdc0290c9f9b9c826c",
                            "created_at": "2018-05-01T19:36:28.331Z",
                            "updated_at": "2018-06-18T15:18:05.556Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ff2c6efb8d3d2f8e8a0c",
                            "schema": "product",
                            "score": 0.8936824131011962,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/97/49/97494e0c59a70aeee1f95eb5a4b4dcd3_best.jpg",
                                    "width": 720,
                                    "height": 402
                                }
                            ],
                            "data": {
                                "id": "613926118_WHITE:613926118:WHITE:https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "sku": "613926118",
                                "name": "Stella McCartney Square Plastic Sunglasses w/ Chain Arms, White",
                                "url": "https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "brand": "Stella McCartney",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Stella McCartney plastic sunglasses feature silvertone chain detail at arms. Opaque white square frames. Gradient smoke lenses. Wide, tapered arms. 100% UV protection.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "985424f7206b70676718c07c5c700fe5",
                            "created_at": "2018-05-01T23:58:36.338Z",
                            "updated_at": "2018-06-23T01:24:21.982Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8bbabb77b36100f150f5d",
                            "schema": "product",
                            "score": 0.89348952293396,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a8/01/a8014c544b60be07b756742f5b175d8a_best.jpg",
                                    "width": 720,
                                    "height": 321
                                }
                            ],
                            "data": {
                                "id": "642874259_WHITE:642874259:WHITE:https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "sku": "642874259",
                                "name": "Alexander McQueen Plastic Round Sunglasses, White",
                                "url": "https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "brand": "Alexander McQueen",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Alexander McQueen plastic sunglasses. Round frames. Gradient, brown lenses. Thin arms tapered at ends. Logo at temples. Imported.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "1d08b02aace2fdabfeafab79d90da7d4",
                            "created_at": "2018-05-01T19:10:35.518Z",
                            "updated_at": "2018-06-26T23:42:09.356Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cfe81393dd3d291162f7",
                            "schema": "product",
                            "score": 0.8912718391418457,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/16/11/161140d8ffb916ab75fc3354cf9beec0_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "718814514_White:718814514:White:https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "sku": "718814514",
                                "name": "Quay X Desi Don't At Me Sunglasses In White Smoke",
                                "url": "https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Quay Australia and Desi Perkins, We love a power couple, Chunky frames, Moulded nose pads for added comfort, Dark-tinted lenses, Tapered arms with curved temple tips for a secure fit, Presented in a branded sunglasses case, A safe place for your sunnies. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 65,
                                "currency": "USD"
                            },
                            "fingerprint": "483680d1a8f7812c7dca0f69a640e1d0",
                            "created_at": "2018-05-01T20:36:56.595Z",
                            "updated_at": "2018-06-23T01:25:18.119Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e4c2b77b36100f18605e",
                            "schema": "product",
                            "score": 0.8912650966644287,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/2d/70/2d70d521540bba5bd419509564563abb_best.jpg",
                                    "width": 720,
                                    "height": 715
                                }
                            ],
                            "data": {
                                "id": "720044806_white:720044806:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "sku": "720044806",
                                "name": "Super by Retrosuperfuture Women's Gals Brigitte 52MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Square frame with tinted lens and stud accents 52mm lens width; 22mm bridge width; 140mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 209,
                                "currency": "USD"
                            },
                            "fingerprint": "116e80778d12fb00c8a66034732cdfdf",
                            "created_at": "2018-05-01T22:05:54.117Z",
                            "updated_at": "2018-06-23T01:25:09.987Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cf751393dd3d2911212e",
                            "schema": "product",
                            "score": 0.8912115097045898,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5b/72/5b72ef6a825d866a0e39d77987bcd349_best.jpg",
                                    "width": 720,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "687048457_Matte clear:687048457:Matte clear:https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "sku": "687048457",
                                "name": "Oakley Oo9013 square-frame sunglasses",
                                "url": "https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "brand": "Oakley",
                                "color": "Matte clear",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Founded in 1975 by Jim Jannard, Oakley continues to provide dynamic and streamlined equipment, including a vast eyewear collection. Straight from the coveted Frogskin range, the Oo9013 sunglasses are cut to a square frame with the signature keyhole nose and showcase the American label's modern sensibilities with a rich two tone base and transparent rims.Oakley sunglassesSquare frame, brand lettering, keyhole nose, charcoal lenses, transparent edging, two tone 100% UV protectionFrame width: 13.5cm Fabric pouch",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 109,
                                "currency": "USD"
                            },
                            "fingerprint": "f7403ef1da4d27121ad8e0f15496325d",
                            "created_at": "2018-05-01T20:35:01.468Z",
                            "updated_at": "2018-06-25T17:34:19.798Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-21",
            "index": 21,
            "timestamp": 4200,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000022.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "28acf029-8ff0-4651-8820-5dd37eefea90",
                    "score": 0.9762849522673566,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 174.0760498046875,
                        "y": 186.66148376464844,
                        "width": 137.17935180664062,
                        "height": 67.6446533203125
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fe756efb8d3d2f8e281e",
                            "schema": "product",
                            "score": 0.9052010536193847,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/44/a3443255733002d9efc94df59b77be79_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679796721_White:679796721:White:https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "sku": "679796721",
                                "name": "Quay 20s Oversized Square Sunglasses In White",
                                "url": "https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Kylie Jenner, Oversized square frames, Moulded nose pads for added comfort, Dark tinted lenses, Branded arms with curved temple tips for a secure fit, Good UV protection. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 80,
                                "currency": "USD"
                            },
                            "fingerprint": "09ff363e17d04cb01af9d2d6c673c7e2",
                            "created_at": "2018-05-01T23:55:33.921Z",
                            "updated_at": "2018-06-23T01:24:21.222Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cc1f1393dd3d290f39c8",
                            "schema": "product",
                            "score": 0.9019440078735351,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/42/b5/42b5c0cbf4c22387219eaaaf97788c14_best.jpg",
                                    "width": 720,
                                    "height": 368
                                }
                            ],
                            "data": {
                                "id": "704685010_IVORY:704685010:IVORY:https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "sku": "704685010",
                                "name": "Saint Laurent Square Acetate Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "IVORY",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Saint Laurent acetate sunglasses. Square frames. Dimensions (in mm): 57-11-140. Thin, tapered arms. 100% UVA/UVB protection. Made in Italy.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 250,
                                "currency": "USD"
                            },
                            "fingerprint": "aed55f70d5281b3ee4bc83e9a83e3b48",
                            "created_at": "2018-05-01T20:20:47.778Z",
                            "updated_at": "2018-06-18T22:48:08.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0efb77b36100f161e01",
                            "schema": "product",
                            "score": 0.8987179946899414,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/15/35/1535cd937adbfe55b95dc478d4c05895_best.jpg",
                                    "width": 720,
                                    "height": 727
                                }
                            ],
                            "data": {
                                "id": "720021299_white:720021299:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "sku": "720021299",
                                "name": "Super by Retrosuperfuture Women's Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "04f62e9a4ae2f9447415128412e93a85",
                            "created_at": "2018-05-01T21:49:35.886Z",
                            "updated_at": "2018-06-23T01:25:18.179Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c3a96efb8d3d2f81425c",
                            "schema": "product",
                            "score": 0.8966365814208984,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/57/6a/576a8a7682f6aa2000e55b90fa797d89_best.jpg",
                                    "width": 264,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "660097270_White:660097270:White:https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "sku": "660097270",
                                "name": "Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "brand": "Super by Retrosuperfuture",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "3b0bc3185003eea9ae599bacb58a6732",
                            "created_at": "2018-05-01T19:44:41.008Z",
                            "updated_at": "2018-06-23T01:24:27.903Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c1bc6efb8d3d2f806363",
                            "schema": "product",
                            "score": 0.8945560836791993,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0c/b1/0cb174192aa75d63f1d740f027f665e5_best.jpg",
                                    "width": 336,
                                    "height": 335
                                }
                            ],
                            "data": {
                                "id": "713296552_Ivory:713296552:Ivory:https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "sku": "713296552",
                                "name": "Seafolly Tortola V2 51mm Polarized Cat Eye Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "brand": "Seafolly",
                                "color": "Ivory",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Bold, blocky frames add to the chic, futuristic look of cat-eye sunglasses fitted with glare-reducing polarized lenses. Style Name:Seafolly Tortola V2 51Mm Polarized Cat Eye Sunglasses. Style Number: 5499557. Available in stores.",
                                "category": "Women > Accessories |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 89,
                                "currency": "USD"
                            },
                            "fingerprint": "38fd2bc7af2879cdc0290c9f9b9c826c",
                            "created_at": "2018-05-01T19:36:28.331Z",
                            "updated_at": "2018-06-18T15:18:05.556Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ff2c6efb8d3d2f8e8a0c",
                            "schema": "product",
                            "score": 0.8936824131011962,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/97/49/97494e0c59a70aeee1f95eb5a4b4dcd3_best.jpg",
                                    "width": 720,
                                    "height": 402
                                }
                            ],
                            "data": {
                                "id": "613926118_WHITE:613926118:WHITE:https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "sku": "613926118",
                                "name": "Stella McCartney Square Plastic Sunglasses w/ Chain Arms, White",
                                "url": "https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "brand": "Stella McCartney",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Stella McCartney plastic sunglasses feature silvertone chain detail at arms. Opaque white square frames. Gradient smoke lenses. Wide, tapered arms. 100% UV protection.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "985424f7206b70676718c07c5c700fe5",
                            "created_at": "2018-05-01T23:58:36.338Z",
                            "updated_at": "2018-06-23T01:24:21.982Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8bbabb77b36100f150f5d",
                            "schema": "product",
                            "score": 0.89348952293396,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a8/01/a8014c544b60be07b756742f5b175d8a_best.jpg",
                                    "width": 720,
                                    "height": 321
                                }
                            ],
                            "data": {
                                "id": "642874259_WHITE:642874259:WHITE:https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "sku": "642874259",
                                "name": "Alexander McQueen Plastic Round Sunglasses, White",
                                "url": "https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "brand": "Alexander McQueen",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Alexander McQueen plastic sunglasses. Round frames. Gradient, brown lenses. Thin arms tapered at ends. Logo at temples. Imported.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "1d08b02aace2fdabfeafab79d90da7d4",
                            "created_at": "2018-05-01T19:10:35.518Z",
                            "updated_at": "2018-06-26T23:42:09.356Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cfe81393dd3d291162f7",
                            "schema": "product",
                            "score": 0.8912718391418457,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/16/11/161140d8ffb916ab75fc3354cf9beec0_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "718814514_White:718814514:White:https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "sku": "718814514",
                                "name": "Quay X Desi Don't At Me Sunglasses In White Smoke",
                                "url": "https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Quay Australia and Desi Perkins, We love a power couple, Chunky frames, Moulded nose pads for added comfort, Dark-tinted lenses, Tapered arms with curved temple tips for a secure fit, Presented in a branded sunglasses case, A safe place for your sunnies. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 65,
                                "currency": "USD"
                            },
                            "fingerprint": "483680d1a8f7812c7dca0f69a640e1d0",
                            "created_at": "2018-05-01T20:36:56.595Z",
                            "updated_at": "2018-06-23T01:25:18.119Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e4c2b77b36100f18605e",
                            "schema": "product",
                            "score": 0.8912650966644287,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/2d/70/2d70d521540bba5bd419509564563abb_best.jpg",
                                    "width": 720,
                                    "height": 715
                                }
                            ],
                            "data": {
                                "id": "720044806_white:720044806:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "sku": "720044806",
                                "name": "Super by Retrosuperfuture Women's Gals Brigitte 52MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Square frame with tinted lens and stud accents 52mm lens width; 22mm bridge width; 140mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 209,
                                "currency": "USD"
                            },
                            "fingerprint": "116e80778d12fb00c8a66034732cdfdf",
                            "created_at": "2018-05-01T22:05:54.117Z",
                            "updated_at": "2018-06-23T01:25:09.987Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cf751393dd3d2911212e",
                            "schema": "product",
                            "score": 0.8912115097045898,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5b/72/5b72ef6a825d866a0e39d77987bcd349_best.jpg",
                                    "width": 720,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "687048457_Matte clear:687048457:Matte clear:https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "sku": "687048457",
                                "name": "Oakley Oo9013 square-frame sunglasses",
                                "url": "https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "brand": "Oakley",
                                "color": "Matte clear",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Founded in 1975 by Jim Jannard, Oakley continues to provide dynamic and streamlined equipment, including a vast eyewear collection. Straight from the coveted Frogskin range, the Oo9013 sunglasses are cut to a square frame with the signature keyhole nose and showcase the American label's modern sensibilities with a rich two tone base and transparent rims.Oakley sunglassesSquare frame, brand lettering, keyhole nose, charcoal lenses, transparent edging, two tone 100% UV protectionFrame width: 13.5cm Fabric pouch",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 109,
                                "currency": "USD"
                            },
                            "fingerprint": "f7403ef1da4d27121ad8e0f15496325d",
                            "created_at": "2018-05-01T20:35:01.468Z",
                            "updated_at": "2018-06-25T17:34:19.798Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-22",
            "index": 22,
            "timestamp": 4400,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000023.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "0525a59e-68df-4816-b295-ed68ffdea268",
                    "score": 0.9762849522673566,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 187.52708435058594,
                        "y": 180.64468383789062,
                        "width": 134.40419006347656,
                        "height": 65.61135864257812
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fe756efb8d3d2f8e281e",
                            "schema": "product",
                            "score": 0.9052010536193847,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/44/a3443255733002d9efc94df59b77be79_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679796721_White:679796721:White:https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "sku": "679796721",
                                "name": "Quay 20s Oversized Square Sunglasses In White",
                                "url": "https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Kylie Jenner, Oversized square frames, Moulded nose pads for added comfort, Dark tinted lenses, Branded arms with curved temple tips for a secure fit, Good UV protection. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 80,
                                "currency": "USD"
                            },
                            "fingerprint": "09ff363e17d04cb01af9d2d6c673c7e2",
                            "created_at": "2018-05-01T23:55:33.921Z",
                            "updated_at": "2018-06-23T01:24:21.222Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cc1f1393dd3d290f39c8",
                            "schema": "product",
                            "score": 0.9019440078735351,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/42/b5/42b5c0cbf4c22387219eaaaf97788c14_best.jpg",
                                    "width": 720,
                                    "height": 368
                                }
                            ],
                            "data": {
                                "id": "704685010_IVORY:704685010:IVORY:https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "sku": "704685010",
                                "name": "Saint Laurent Square Acetate Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "IVORY",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Saint Laurent acetate sunglasses. Square frames. Dimensions (in mm): 57-11-140. Thin, tapered arms. 100% UVA/UVB protection. Made in Italy.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 250,
                                "currency": "USD"
                            },
                            "fingerprint": "aed55f70d5281b3ee4bc83e9a83e3b48",
                            "created_at": "2018-05-01T20:20:47.778Z",
                            "updated_at": "2018-06-18T22:48:08.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0efb77b36100f161e01",
                            "schema": "product",
                            "score": 0.8987179946899414,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/15/35/1535cd937adbfe55b95dc478d4c05895_best.jpg",
                                    "width": 720,
                                    "height": 727
                                }
                            ],
                            "data": {
                                "id": "720021299_white:720021299:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "sku": "720021299",
                                "name": "Super by Retrosuperfuture Women's Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "04f62e9a4ae2f9447415128412e93a85",
                            "created_at": "2018-05-01T21:49:35.886Z",
                            "updated_at": "2018-06-23T01:25:18.179Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c3a96efb8d3d2f81425c",
                            "schema": "product",
                            "score": 0.8966365814208984,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/57/6a/576a8a7682f6aa2000e55b90fa797d89_best.jpg",
                                    "width": 264,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "660097270_White:660097270:White:https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "sku": "660097270",
                                "name": "Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "brand": "Super by Retrosuperfuture",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "3b0bc3185003eea9ae599bacb58a6732",
                            "created_at": "2018-05-01T19:44:41.008Z",
                            "updated_at": "2018-06-23T01:24:27.903Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c1bc6efb8d3d2f806363",
                            "schema": "product",
                            "score": 0.8945560836791993,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0c/b1/0cb174192aa75d63f1d740f027f665e5_best.jpg",
                                    "width": 336,
                                    "height": 335
                                }
                            ],
                            "data": {
                                "id": "713296552_Ivory:713296552:Ivory:https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "sku": "713296552",
                                "name": "Seafolly Tortola V2 51mm Polarized Cat Eye Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "brand": "Seafolly",
                                "color": "Ivory",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Bold, blocky frames add to the chic, futuristic look of cat-eye sunglasses fitted with glare-reducing polarized lenses. Style Name:Seafolly Tortola V2 51Mm Polarized Cat Eye Sunglasses. Style Number: 5499557. Available in stores.",
                                "category": "Women > Accessories |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 89,
                                "currency": "USD"
                            },
                            "fingerprint": "38fd2bc7af2879cdc0290c9f9b9c826c",
                            "created_at": "2018-05-01T19:36:28.331Z",
                            "updated_at": "2018-06-18T15:18:05.556Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ff2c6efb8d3d2f8e8a0c",
                            "schema": "product",
                            "score": 0.8936824131011962,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/97/49/97494e0c59a70aeee1f95eb5a4b4dcd3_best.jpg",
                                    "width": 720,
                                    "height": 402
                                }
                            ],
                            "data": {
                                "id": "613926118_WHITE:613926118:WHITE:https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "sku": "613926118",
                                "name": "Stella McCartney Square Plastic Sunglasses w/ Chain Arms, White",
                                "url": "https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "brand": "Stella McCartney",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Stella McCartney plastic sunglasses feature silvertone chain detail at arms. Opaque white square frames. Gradient smoke lenses. Wide, tapered arms. 100% UV protection.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "985424f7206b70676718c07c5c700fe5",
                            "created_at": "2018-05-01T23:58:36.338Z",
                            "updated_at": "2018-06-23T01:24:21.982Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8bbabb77b36100f150f5d",
                            "schema": "product",
                            "score": 0.89348952293396,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a8/01/a8014c544b60be07b756742f5b175d8a_best.jpg",
                                    "width": 720,
                                    "height": 321
                                }
                            ],
                            "data": {
                                "id": "642874259_WHITE:642874259:WHITE:https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "sku": "642874259",
                                "name": "Alexander McQueen Plastic Round Sunglasses, White",
                                "url": "https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "brand": "Alexander McQueen",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Alexander McQueen plastic sunglasses. Round frames. Gradient, brown lenses. Thin arms tapered at ends. Logo at temples. Imported.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "1d08b02aace2fdabfeafab79d90da7d4",
                            "created_at": "2018-05-01T19:10:35.518Z",
                            "updated_at": "2018-06-26T23:42:09.356Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cfe81393dd3d291162f7",
                            "schema": "product",
                            "score": 0.8912718391418457,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/16/11/161140d8ffb916ab75fc3354cf9beec0_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "718814514_White:718814514:White:https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "sku": "718814514",
                                "name": "Quay X Desi Don't At Me Sunglasses In White Smoke",
                                "url": "https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Quay Australia and Desi Perkins, We love a power couple, Chunky frames, Moulded nose pads for added comfort, Dark-tinted lenses, Tapered arms with curved temple tips for a secure fit, Presented in a branded sunglasses case, A safe place for your sunnies. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 65,
                                "currency": "USD"
                            },
                            "fingerprint": "483680d1a8f7812c7dca0f69a640e1d0",
                            "created_at": "2018-05-01T20:36:56.595Z",
                            "updated_at": "2018-06-23T01:25:18.119Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e4c2b77b36100f18605e",
                            "schema": "product",
                            "score": 0.8912650966644287,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/2d/70/2d70d521540bba5bd419509564563abb_best.jpg",
                                    "width": 720,
                                    "height": 715
                                }
                            ],
                            "data": {
                                "id": "720044806_white:720044806:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "sku": "720044806",
                                "name": "Super by Retrosuperfuture Women's Gals Brigitte 52MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Square frame with tinted lens and stud accents 52mm lens width; 22mm bridge width; 140mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 209,
                                "currency": "USD"
                            },
                            "fingerprint": "116e80778d12fb00c8a66034732cdfdf",
                            "created_at": "2018-05-01T22:05:54.117Z",
                            "updated_at": "2018-06-23T01:25:09.987Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cf751393dd3d2911212e",
                            "schema": "product",
                            "score": 0.8912115097045898,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5b/72/5b72ef6a825d866a0e39d77987bcd349_best.jpg",
                                    "width": 720,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "687048457_Matte clear:687048457:Matte clear:https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "sku": "687048457",
                                "name": "Oakley Oo9013 square-frame sunglasses",
                                "url": "https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "brand": "Oakley",
                                "color": "Matte clear",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Founded in 1975 by Jim Jannard, Oakley continues to provide dynamic and streamlined equipment, including a vast eyewear collection. Straight from the coveted Frogskin range, the Oo9013 sunglasses are cut to a square frame with the signature keyhole nose and showcase the American label's modern sensibilities with a rich two tone base and transparent rims.Oakley sunglassesSquare frame, brand lettering, keyhole nose, charcoal lenses, transparent edging, two tone 100% UV protectionFrame width: 13.5cm Fabric pouch",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 109,
                                "currency": "USD"
                            },
                            "fingerprint": "f7403ef1da4d27121ad8e0f15496325d",
                            "created_at": "2018-05-01T20:35:01.468Z",
                            "updated_at": "2018-06-25T17:34:19.798Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-23",
            "index": 23,
            "timestamp": 4600,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000024.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "22ecee06-c550-4f4b-b8d1-4816b871b25b",
                    "score": 0.9762849522673566,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 181.0330810546875,
                        "y": 176.4443817138672,
                        "width": 139.85150146484375,
                        "height": 58.797119140625
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fe756efb8d3d2f8e281e",
                            "schema": "product",
                            "score": 0.9052010536193847,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/44/a3443255733002d9efc94df59b77be79_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679796721_White:679796721:White:https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "sku": "679796721",
                                "name": "Quay 20s Oversized Square Sunglasses In White",
                                "url": "https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Kylie Jenner, Oversized square frames, Moulded nose pads for added comfort, Dark tinted lenses, Branded arms with curved temple tips for a secure fit, Good UV protection. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 80,
                                "currency": "USD"
                            },
                            "fingerprint": "09ff363e17d04cb01af9d2d6c673c7e2",
                            "created_at": "2018-05-01T23:55:33.921Z",
                            "updated_at": "2018-06-23T01:24:21.222Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cc1f1393dd3d290f39c8",
                            "schema": "product",
                            "score": 0.9019440078735351,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/42/b5/42b5c0cbf4c22387219eaaaf97788c14_best.jpg",
                                    "width": 720,
                                    "height": 368
                                }
                            ],
                            "data": {
                                "id": "704685010_IVORY:704685010:IVORY:https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "sku": "704685010",
                                "name": "Saint Laurent Square Acetate Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "IVORY",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Saint Laurent acetate sunglasses. Square frames. Dimensions (in mm): 57-11-140. Thin, tapered arms. 100% UVA/UVB protection. Made in Italy.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 250,
                                "currency": "USD"
                            },
                            "fingerprint": "aed55f70d5281b3ee4bc83e9a83e3b48",
                            "created_at": "2018-05-01T20:20:47.778Z",
                            "updated_at": "2018-06-18T22:48:08.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0efb77b36100f161e01",
                            "schema": "product",
                            "score": 0.8987179946899414,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/15/35/1535cd937adbfe55b95dc478d4c05895_best.jpg",
                                    "width": 720,
                                    "height": 727
                                }
                            ],
                            "data": {
                                "id": "720021299_white:720021299:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "sku": "720021299",
                                "name": "Super by Retrosuperfuture Women's Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "04f62e9a4ae2f9447415128412e93a85",
                            "created_at": "2018-05-01T21:49:35.886Z",
                            "updated_at": "2018-06-23T01:25:18.179Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c3a96efb8d3d2f81425c",
                            "schema": "product",
                            "score": 0.8966365814208984,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/57/6a/576a8a7682f6aa2000e55b90fa797d89_best.jpg",
                                    "width": 264,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "660097270_White:660097270:White:https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "sku": "660097270",
                                "name": "Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "brand": "Super by Retrosuperfuture",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "3b0bc3185003eea9ae599bacb58a6732",
                            "created_at": "2018-05-01T19:44:41.008Z",
                            "updated_at": "2018-06-23T01:24:27.903Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c1bc6efb8d3d2f806363",
                            "schema": "product",
                            "score": 0.8945560836791993,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0c/b1/0cb174192aa75d63f1d740f027f665e5_best.jpg",
                                    "width": 336,
                                    "height": 335
                                }
                            ],
                            "data": {
                                "id": "713296552_Ivory:713296552:Ivory:https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "sku": "713296552",
                                "name": "Seafolly Tortola V2 51mm Polarized Cat Eye Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "brand": "Seafolly",
                                "color": "Ivory",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Bold, blocky frames add to the chic, futuristic look of cat-eye sunglasses fitted with glare-reducing polarized lenses. Style Name:Seafolly Tortola V2 51Mm Polarized Cat Eye Sunglasses. Style Number: 5499557. Available in stores.",
                                "category": "Women > Accessories |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 89,
                                "currency": "USD"
                            },
                            "fingerprint": "38fd2bc7af2879cdc0290c9f9b9c826c",
                            "created_at": "2018-05-01T19:36:28.331Z",
                            "updated_at": "2018-06-18T15:18:05.556Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ff2c6efb8d3d2f8e8a0c",
                            "schema": "product",
                            "score": 0.8936824131011962,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/97/49/97494e0c59a70aeee1f95eb5a4b4dcd3_best.jpg",
                                    "width": 720,
                                    "height": 402
                                }
                            ],
                            "data": {
                                "id": "613926118_WHITE:613926118:WHITE:https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "sku": "613926118",
                                "name": "Stella McCartney Square Plastic Sunglasses w/ Chain Arms, White",
                                "url": "https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "brand": "Stella McCartney",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Stella McCartney plastic sunglasses feature silvertone chain detail at arms. Opaque white square frames. Gradient smoke lenses. Wide, tapered arms. 100% UV protection.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "985424f7206b70676718c07c5c700fe5",
                            "created_at": "2018-05-01T23:58:36.338Z",
                            "updated_at": "2018-06-23T01:24:21.982Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8bbabb77b36100f150f5d",
                            "schema": "product",
                            "score": 0.89348952293396,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a8/01/a8014c544b60be07b756742f5b175d8a_best.jpg",
                                    "width": 720,
                                    "height": 321
                                }
                            ],
                            "data": {
                                "id": "642874259_WHITE:642874259:WHITE:https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "sku": "642874259",
                                "name": "Alexander McQueen Plastic Round Sunglasses, White",
                                "url": "https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "brand": "Alexander McQueen",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Alexander McQueen plastic sunglasses. Round frames. Gradient, brown lenses. Thin arms tapered at ends. Logo at temples. Imported.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "1d08b02aace2fdabfeafab79d90da7d4",
                            "created_at": "2018-05-01T19:10:35.518Z",
                            "updated_at": "2018-06-26T23:42:09.356Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cfe81393dd3d291162f7",
                            "schema": "product",
                            "score": 0.8912718391418457,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/16/11/161140d8ffb916ab75fc3354cf9beec0_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "718814514_White:718814514:White:https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "sku": "718814514",
                                "name": "Quay X Desi Don't At Me Sunglasses In White Smoke",
                                "url": "https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Quay Australia and Desi Perkins, We love a power couple, Chunky frames, Moulded nose pads for added comfort, Dark-tinted lenses, Tapered arms with curved temple tips for a secure fit, Presented in a branded sunglasses case, A safe place for your sunnies. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 65,
                                "currency": "USD"
                            },
                            "fingerprint": "483680d1a8f7812c7dca0f69a640e1d0",
                            "created_at": "2018-05-01T20:36:56.595Z",
                            "updated_at": "2018-06-23T01:25:18.119Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e4c2b77b36100f18605e",
                            "schema": "product",
                            "score": 0.8912650966644287,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/2d/70/2d70d521540bba5bd419509564563abb_best.jpg",
                                    "width": 720,
                                    "height": 715
                                }
                            ],
                            "data": {
                                "id": "720044806_white:720044806:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "sku": "720044806",
                                "name": "Super by Retrosuperfuture Women's Gals Brigitte 52MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Square frame with tinted lens and stud accents 52mm lens width; 22mm bridge width; 140mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 209,
                                "currency": "USD"
                            },
                            "fingerprint": "116e80778d12fb00c8a66034732cdfdf",
                            "created_at": "2018-05-01T22:05:54.117Z",
                            "updated_at": "2018-06-23T01:25:09.987Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cf751393dd3d2911212e",
                            "schema": "product",
                            "score": 0.8912115097045898,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5b/72/5b72ef6a825d866a0e39d77987bcd349_best.jpg",
                                    "width": 720,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "687048457_Matte clear:687048457:Matte clear:https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "sku": "687048457",
                                "name": "Oakley Oo9013 square-frame sunglasses",
                                "url": "https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "brand": "Oakley",
                                "color": "Matte clear",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Founded in 1975 by Jim Jannard, Oakley continues to provide dynamic and streamlined equipment, including a vast eyewear collection. Straight from the coveted Frogskin range, the Oo9013 sunglasses are cut to a square frame with the signature keyhole nose and showcase the American label's modern sensibilities with a rich two tone base and transparent rims.Oakley sunglassesSquare frame, brand lettering, keyhole nose, charcoal lenses, transparent edging, two tone 100% UV protectionFrame width: 13.5cm Fabric pouch",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 109,
                                "currency": "USD"
                            },
                            "fingerprint": "f7403ef1da4d27121ad8e0f15496325d",
                            "created_at": "2018-05-01T20:35:01.468Z",
                            "updated_at": "2018-06-25T17:34:19.798Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-24",
            "index": 24,
            "timestamp": 4800,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000025.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "6539b79a-8698-4ae5-beb7-2409540f22d1",
                    "score": 0.9762849522673566,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 172.0789337158203,
                        "y": 195.2423095703125,
                        "width": 142.5065155029297,
                        "height": 41.100677490234375
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fe756efb8d3d2f8e281e",
                            "schema": "product",
                            "score": 0.9052010536193847,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/44/a3443255733002d9efc94df59b77be79_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679796721_White:679796721:White:https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "sku": "679796721",
                                "name": "Quay 20s Oversized Square Sunglasses In White",
                                "url": "https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Kylie Jenner, Oversized square frames, Moulded nose pads for added comfort, Dark tinted lenses, Branded arms with curved temple tips for a secure fit, Good UV protection. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 80,
                                "currency": "USD"
                            },
                            "fingerprint": "09ff363e17d04cb01af9d2d6c673c7e2",
                            "created_at": "2018-05-01T23:55:33.921Z",
                            "updated_at": "2018-06-23T01:24:21.222Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cc1f1393dd3d290f39c8",
                            "schema": "product",
                            "score": 0.9019440078735351,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/42/b5/42b5c0cbf4c22387219eaaaf97788c14_best.jpg",
                                    "width": 720,
                                    "height": 368
                                }
                            ],
                            "data": {
                                "id": "704685010_IVORY:704685010:IVORY:https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "sku": "704685010",
                                "name": "Saint Laurent Square Acetate Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "IVORY",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Saint Laurent acetate sunglasses. Square frames. Dimensions (in mm): 57-11-140. Thin, tapered arms. 100% UVA/UVB protection. Made in Italy.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 250,
                                "currency": "USD"
                            },
                            "fingerprint": "aed55f70d5281b3ee4bc83e9a83e3b48",
                            "created_at": "2018-05-01T20:20:47.778Z",
                            "updated_at": "2018-06-18T22:48:08.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0efb77b36100f161e01",
                            "schema": "product",
                            "score": 0.8987179946899414,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/15/35/1535cd937adbfe55b95dc478d4c05895_best.jpg",
                                    "width": 720,
                                    "height": 727
                                }
                            ],
                            "data": {
                                "id": "720021299_white:720021299:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "sku": "720021299",
                                "name": "Super by Retrosuperfuture Women's Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "04f62e9a4ae2f9447415128412e93a85",
                            "created_at": "2018-05-01T21:49:35.886Z",
                            "updated_at": "2018-06-23T01:25:18.179Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c3a96efb8d3d2f81425c",
                            "schema": "product",
                            "score": 0.8966365814208984,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/57/6a/576a8a7682f6aa2000e55b90fa797d89_best.jpg",
                                    "width": 264,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "660097270_White:660097270:White:https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "sku": "660097270",
                                "name": "Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "brand": "Super by Retrosuperfuture",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "3b0bc3185003eea9ae599bacb58a6732",
                            "created_at": "2018-05-01T19:44:41.008Z",
                            "updated_at": "2018-06-23T01:24:27.903Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c1bc6efb8d3d2f806363",
                            "schema": "product",
                            "score": 0.8945560836791993,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0c/b1/0cb174192aa75d63f1d740f027f665e5_best.jpg",
                                    "width": 336,
                                    "height": 335
                                }
                            ],
                            "data": {
                                "id": "713296552_Ivory:713296552:Ivory:https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "sku": "713296552",
                                "name": "Seafolly Tortola V2 51mm Polarized Cat Eye Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "brand": "Seafolly",
                                "color": "Ivory",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Bold, blocky frames add to the chic, futuristic look of cat-eye sunglasses fitted with glare-reducing polarized lenses. Style Name:Seafolly Tortola V2 51Mm Polarized Cat Eye Sunglasses. Style Number: 5499557. Available in stores.",
                                "category": "Women > Accessories |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 89,
                                "currency": "USD"
                            },
                            "fingerprint": "38fd2bc7af2879cdc0290c9f9b9c826c",
                            "created_at": "2018-05-01T19:36:28.331Z",
                            "updated_at": "2018-06-18T15:18:05.556Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ff2c6efb8d3d2f8e8a0c",
                            "schema": "product",
                            "score": 0.8936824131011962,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/97/49/97494e0c59a70aeee1f95eb5a4b4dcd3_best.jpg",
                                    "width": 720,
                                    "height": 402
                                }
                            ],
                            "data": {
                                "id": "613926118_WHITE:613926118:WHITE:https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "sku": "613926118",
                                "name": "Stella McCartney Square Plastic Sunglasses w/ Chain Arms, White",
                                "url": "https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "brand": "Stella McCartney",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Stella McCartney plastic sunglasses feature silvertone chain detail at arms. Opaque white square frames. Gradient smoke lenses. Wide, tapered arms. 100% UV protection.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "985424f7206b70676718c07c5c700fe5",
                            "created_at": "2018-05-01T23:58:36.338Z",
                            "updated_at": "2018-06-23T01:24:21.982Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8bbabb77b36100f150f5d",
                            "schema": "product",
                            "score": 0.89348952293396,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a8/01/a8014c544b60be07b756742f5b175d8a_best.jpg",
                                    "width": 720,
                                    "height": 321
                                }
                            ],
                            "data": {
                                "id": "642874259_WHITE:642874259:WHITE:https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "sku": "642874259",
                                "name": "Alexander McQueen Plastic Round Sunglasses, White",
                                "url": "https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "brand": "Alexander McQueen",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Alexander McQueen plastic sunglasses. Round frames. Gradient, brown lenses. Thin arms tapered at ends. Logo at temples. Imported.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "1d08b02aace2fdabfeafab79d90da7d4",
                            "created_at": "2018-05-01T19:10:35.518Z",
                            "updated_at": "2018-06-26T23:42:09.356Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cfe81393dd3d291162f7",
                            "schema": "product",
                            "score": 0.8912718391418457,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/16/11/161140d8ffb916ab75fc3354cf9beec0_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "718814514_White:718814514:White:https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "sku": "718814514",
                                "name": "Quay X Desi Don't At Me Sunglasses In White Smoke",
                                "url": "https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Quay Australia and Desi Perkins, We love a power couple, Chunky frames, Moulded nose pads for added comfort, Dark-tinted lenses, Tapered arms with curved temple tips for a secure fit, Presented in a branded sunglasses case, A safe place for your sunnies. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 65,
                                "currency": "USD"
                            },
                            "fingerprint": "483680d1a8f7812c7dca0f69a640e1d0",
                            "created_at": "2018-05-01T20:36:56.595Z",
                            "updated_at": "2018-06-23T01:25:18.119Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e4c2b77b36100f18605e",
                            "schema": "product",
                            "score": 0.8912650966644287,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/2d/70/2d70d521540bba5bd419509564563abb_best.jpg",
                                    "width": 720,
                                    "height": 715
                                }
                            ],
                            "data": {
                                "id": "720044806_white:720044806:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "sku": "720044806",
                                "name": "Super by Retrosuperfuture Women's Gals Brigitte 52MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Square frame with tinted lens and stud accents 52mm lens width; 22mm bridge width; 140mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 209,
                                "currency": "USD"
                            },
                            "fingerprint": "116e80778d12fb00c8a66034732cdfdf",
                            "created_at": "2018-05-01T22:05:54.117Z",
                            "updated_at": "2018-06-23T01:25:09.987Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cf751393dd3d2911212e",
                            "schema": "product",
                            "score": 0.8912115097045898,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5b/72/5b72ef6a825d866a0e39d77987bcd349_best.jpg",
                                    "width": 720,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "687048457_Matte clear:687048457:Matte clear:https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "sku": "687048457",
                                "name": "Oakley Oo9013 square-frame sunglasses",
                                "url": "https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "brand": "Oakley",
                                "color": "Matte clear",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Founded in 1975 by Jim Jannard, Oakley continues to provide dynamic and streamlined equipment, including a vast eyewear collection. Straight from the coveted Frogskin range, the Oo9013 sunglasses are cut to a square frame with the signature keyhole nose and showcase the American label's modern sensibilities with a rich two tone base and transparent rims.Oakley sunglassesSquare frame, brand lettering, keyhole nose, charcoal lenses, transparent edging, two tone 100% UV protectionFrame width: 13.5cm Fabric pouch",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 109,
                                "currency": "USD"
                            },
                            "fingerprint": "f7403ef1da4d27121ad8e0f15496325d",
                            "created_at": "2018-05-01T20:35:01.468Z",
                            "updated_at": "2018-06-25T17:34:19.798Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-25",
            "index": 25,
            "timestamp": 5000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000026.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "32359179-e639-4278-83a4-2ef0f62bd6b0",
                    "score": 0.9762849522673566,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 168.02578735351562,
                        "y": 211.06715393066406,
                        "width": 138.52041625976562,
                        "height": 41.46771240234375
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fe756efb8d3d2f8e281e",
                            "schema": "product",
                            "score": 0.9052010536193847,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/44/a3443255733002d9efc94df59b77be79_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679796721_White:679796721:White:https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "sku": "679796721",
                                "name": "Quay 20s Oversized Square Sunglasses In White",
                                "url": "https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Kylie Jenner, Oversized square frames, Moulded nose pads for added comfort, Dark tinted lenses, Branded arms with curved temple tips for a secure fit, Good UV protection. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 80,
                                "currency": "USD"
                            },
                            "fingerprint": "09ff363e17d04cb01af9d2d6c673c7e2",
                            "created_at": "2018-05-01T23:55:33.921Z",
                            "updated_at": "2018-06-23T01:24:21.222Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cc1f1393dd3d290f39c8",
                            "schema": "product",
                            "score": 0.9019440078735351,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/42/b5/42b5c0cbf4c22387219eaaaf97788c14_best.jpg",
                                    "width": 720,
                                    "height": 368
                                }
                            ],
                            "data": {
                                "id": "704685010_IVORY:704685010:IVORY:https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "sku": "704685010",
                                "name": "Saint Laurent Square Acetate Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "IVORY",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Saint Laurent acetate sunglasses. Square frames. Dimensions (in mm): 57-11-140. Thin, tapered arms. 100% UVA/UVB protection. Made in Italy.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 250,
                                "currency": "USD"
                            },
                            "fingerprint": "aed55f70d5281b3ee4bc83e9a83e3b48",
                            "created_at": "2018-05-01T20:20:47.778Z",
                            "updated_at": "2018-06-18T22:48:08.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0efb77b36100f161e01",
                            "schema": "product",
                            "score": 0.8987179946899414,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/15/35/1535cd937adbfe55b95dc478d4c05895_best.jpg",
                                    "width": 720,
                                    "height": 727
                                }
                            ],
                            "data": {
                                "id": "720021299_white:720021299:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "sku": "720021299",
                                "name": "Super by Retrosuperfuture Women's Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "04f62e9a4ae2f9447415128412e93a85",
                            "created_at": "2018-05-01T21:49:35.886Z",
                            "updated_at": "2018-06-23T01:25:18.179Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c3a96efb8d3d2f81425c",
                            "schema": "product",
                            "score": 0.8966365814208984,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/57/6a/576a8a7682f6aa2000e55b90fa797d89_best.jpg",
                                    "width": 264,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "660097270_White:660097270:White:https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "sku": "660097270",
                                "name": "Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "brand": "Super by Retrosuperfuture",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "3b0bc3185003eea9ae599bacb58a6732",
                            "created_at": "2018-05-01T19:44:41.008Z",
                            "updated_at": "2018-06-23T01:24:27.903Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c1bc6efb8d3d2f806363",
                            "schema": "product",
                            "score": 0.8945560836791993,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0c/b1/0cb174192aa75d63f1d740f027f665e5_best.jpg",
                                    "width": 336,
                                    "height": 335
                                }
                            ],
                            "data": {
                                "id": "713296552_Ivory:713296552:Ivory:https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "sku": "713296552",
                                "name": "Seafolly Tortola V2 51mm Polarized Cat Eye Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "brand": "Seafolly",
                                "color": "Ivory",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Bold, blocky frames add to the chic, futuristic look of cat-eye sunglasses fitted with glare-reducing polarized lenses. Style Name:Seafolly Tortola V2 51Mm Polarized Cat Eye Sunglasses. Style Number: 5499557. Available in stores.",
                                "category": "Women > Accessories |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 89,
                                "currency": "USD"
                            },
                            "fingerprint": "38fd2bc7af2879cdc0290c9f9b9c826c",
                            "created_at": "2018-05-01T19:36:28.331Z",
                            "updated_at": "2018-06-18T15:18:05.556Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ff2c6efb8d3d2f8e8a0c",
                            "schema": "product",
                            "score": 0.8936824131011962,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/97/49/97494e0c59a70aeee1f95eb5a4b4dcd3_best.jpg",
                                    "width": 720,
                                    "height": 402
                                }
                            ],
                            "data": {
                                "id": "613926118_WHITE:613926118:WHITE:https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "sku": "613926118",
                                "name": "Stella McCartney Square Plastic Sunglasses w/ Chain Arms, White",
                                "url": "https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "brand": "Stella McCartney",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Stella McCartney plastic sunglasses feature silvertone chain detail at arms. Opaque white square frames. Gradient smoke lenses. Wide, tapered arms. 100% UV protection.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "985424f7206b70676718c07c5c700fe5",
                            "created_at": "2018-05-01T23:58:36.338Z",
                            "updated_at": "2018-06-23T01:24:21.982Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8bbabb77b36100f150f5d",
                            "schema": "product",
                            "score": 0.89348952293396,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a8/01/a8014c544b60be07b756742f5b175d8a_best.jpg",
                                    "width": 720,
                                    "height": 321
                                }
                            ],
                            "data": {
                                "id": "642874259_WHITE:642874259:WHITE:https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "sku": "642874259",
                                "name": "Alexander McQueen Plastic Round Sunglasses, White",
                                "url": "https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "brand": "Alexander McQueen",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Alexander McQueen plastic sunglasses. Round frames. Gradient, brown lenses. Thin arms tapered at ends. Logo at temples. Imported.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "1d08b02aace2fdabfeafab79d90da7d4",
                            "created_at": "2018-05-01T19:10:35.518Z",
                            "updated_at": "2018-06-26T23:42:09.356Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cfe81393dd3d291162f7",
                            "schema": "product",
                            "score": 0.8912718391418457,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/16/11/161140d8ffb916ab75fc3354cf9beec0_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "718814514_White:718814514:White:https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "sku": "718814514",
                                "name": "Quay X Desi Don't At Me Sunglasses In White Smoke",
                                "url": "https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Quay Australia and Desi Perkins, We love a power couple, Chunky frames, Moulded nose pads for added comfort, Dark-tinted lenses, Tapered arms with curved temple tips for a secure fit, Presented in a branded sunglasses case, A safe place for your sunnies. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 65,
                                "currency": "USD"
                            },
                            "fingerprint": "483680d1a8f7812c7dca0f69a640e1d0",
                            "created_at": "2018-05-01T20:36:56.595Z",
                            "updated_at": "2018-06-23T01:25:18.119Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e4c2b77b36100f18605e",
                            "schema": "product",
                            "score": 0.8912650966644287,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/2d/70/2d70d521540bba5bd419509564563abb_best.jpg",
                                    "width": 720,
                                    "height": 715
                                }
                            ],
                            "data": {
                                "id": "720044806_white:720044806:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "sku": "720044806",
                                "name": "Super by Retrosuperfuture Women's Gals Brigitte 52MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Square frame with tinted lens and stud accents 52mm lens width; 22mm bridge width; 140mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 209,
                                "currency": "USD"
                            },
                            "fingerprint": "116e80778d12fb00c8a66034732cdfdf",
                            "created_at": "2018-05-01T22:05:54.117Z",
                            "updated_at": "2018-06-23T01:25:09.987Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cf751393dd3d2911212e",
                            "schema": "product",
                            "score": 0.8912115097045898,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5b/72/5b72ef6a825d866a0e39d77987bcd349_best.jpg",
                                    "width": 720,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "687048457_Matte clear:687048457:Matte clear:https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "sku": "687048457",
                                "name": "Oakley Oo9013 square-frame sunglasses",
                                "url": "https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "brand": "Oakley",
                                "color": "Matte clear",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Founded in 1975 by Jim Jannard, Oakley continues to provide dynamic and streamlined equipment, including a vast eyewear collection. Straight from the coveted Frogskin range, the Oo9013 sunglasses are cut to a square frame with the signature keyhole nose and showcase the American label's modern sensibilities with a rich two tone base and transparent rims.Oakley sunglassesSquare frame, brand lettering, keyhole nose, charcoal lenses, transparent edging, two tone 100% UV protectionFrame width: 13.5cm Fabric pouch",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 109,
                                "currency": "USD"
                            },
                            "fingerprint": "f7403ef1da4d27121ad8e0f15496325d",
                            "created_at": "2018-05-01T20:35:01.468Z",
                            "updated_at": "2018-06-25T17:34:19.798Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-26",
            "index": 26,
            "timestamp": 5200,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000027.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "e2cb7256-ae75-4491-96a7-0c8d0396feca",
                    "score": 0.9762849522673566,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 165.96270751953125,
                        "y": 207.65811157226562,
                        "width": 143.79092407226562,
                        "height": 49.558990478515625
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fe756efb8d3d2f8e281e",
                            "schema": "product",
                            "score": 0.9052010536193847,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/44/a3443255733002d9efc94df59b77be79_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679796721_White:679796721:White:https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "sku": "679796721",
                                "name": "Quay 20s Oversized Square Sunglasses In White",
                                "url": "https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Kylie Jenner, Oversized square frames, Moulded nose pads for added comfort, Dark tinted lenses, Branded arms with curved temple tips for a secure fit, Good UV protection. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 80,
                                "currency": "USD"
                            },
                            "fingerprint": "09ff363e17d04cb01af9d2d6c673c7e2",
                            "created_at": "2018-05-01T23:55:33.921Z",
                            "updated_at": "2018-06-23T01:24:21.222Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cc1f1393dd3d290f39c8",
                            "schema": "product",
                            "score": 0.9019440078735351,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/42/b5/42b5c0cbf4c22387219eaaaf97788c14_best.jpg",
                                    "width": 720,
                                    "height": 368
                                }
                            ],
                            "data": {
                                "id": "704685010_IVORY:704685010:IVORY:https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "sku": "704685010",
                                "name": "Saint Laurent Square Acetate Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "IVORY",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Saint Laurent acetate sunglasses. Square frames. Dimensions (in mm): 57-11-140. Thin, tapered arms. 100% UVA/UVB protection. Made in Italy.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 250,
                                "currency": "USD"
                            },
                            "fingerprint": "aed55f70d5281b3ee4bc83e9a83e3b48",
                            "created_at": "2018-05-01T20:20:47.778Z",
                            "updated_at": "2018-06-18T22:48:08.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0efb77b36100f161e01",
                            "schema": "product",
                            "score": 0.8987179946899414,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/15/35/1535cd937adbfe55b95dc478d4c05895_best.jpg",
                                    "width": 720,
                                    "height": 727
                                }
                            ],
                            "data": {
                                "id": "720021299_white:720021299:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "sku": "720021299",
                                "name": "Super by Retrosuperfuture Women's Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "04f62e9a4ae2f9447415128412e93a85",
                            "created_at": "2018-05-01T21:49:35.886Z",
                            "updated_at": "2018-06-23T01:25:18.179Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c3a96efb8d3d2f81425c",
                            "schema": "product",
                            "score": 0.8966365814208984,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/57/6a/576a8a7682f6aa2000e55b90fa797d89_best.jpg",
                                    "width": 264,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "660097270_White:660097270:White:https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "sku": "660097270",
                                "name": "Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "brand": "Super by Retrosuperfuture",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "3b0bc3185003eea9ae599bacb58a6732",
                            "created_at": "2018-05-01T19:44:41.008Z",
                            "updated_at": "2018-06-23T01:24:27.903Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c1bc6efb8d3d2f806363",
                            "schema": "product",
                            "score": 0.8945560836791993,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0c/b1/0cb174192aa75d63f1d740f027f665e5_best.jpg",
                                    "width": 336,
                                    "height": 335
                                }
                            ],
                            "data": {
                                "id": "713296552_Ivory:713296552:Ivory:https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "sku": "713296552",
                                "name": "Seafolly Tortola V2 51mm Polarized Cat Eye Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "brand": "Seafolly",
                                "color": "Ivory",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Bold, blocky frames add to the chic, futuristic look of cat-eye sunglasses fitted with glare-reducing polarized lenses. Style Name:Seafolly Tortola V2 51Mm Polarized Cat Eye Sunglasses. Style Number: 5499557. Available in stores.",
                                "category": "Women > Accessories |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 89,
                                "currency": "USD"
                            },
                            "fingerprint": "38fd2bc7af2879cdc0290c9f9b9c826c",
                            "created_at": "2018-05-01T19:36:28.331Z",
                            "updated_at": "2018-06-18T15:18:05.556Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ff2c6efb8d3d2f8e8a0c",
                            "schema": "product",
                            "score": 0.8936824131011962,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/97/49/97494e0c59a70aeee1f95eb5a4b4dcd3_best.jpg",
                                    "width": 720,
                                    "height": 402
                                }
                            ],
                            "data": {
                                "id": "613926118_WHITE:613926118:WHITE:https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "sku": "613926118",
                                "name": "Stella McCartney Square Plastic Sunglasses w/ Chain Arms, White",
                                "url": "https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "brand": "Stella McCartney",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Stella McCartney plastic sunglasses feature silvertone chain detail at arms. Opaque white square frames. Gradient smoke lenses. Wide, tapered arms. 100% UV protection.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "985424f7206b70676718c07c5c700fe5",
                            "created_at": "2018-05-01T23:58:36.338Z",
                            "updated_at": "2018-06-23T01:24:21.982Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8bbabb77b36100f150f5d",
                            "schema": "product",
                            "score": 0.89348952293396,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a8/01/a8014c544b60be07b756742f5b175d8a_best.jpg",
                                    "width": 720,
                                    "height": 321
                                }
                            ],
                            "data": {
                                "id": "642874259_WHITE:642874259:WHITE:https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "sku": "642874259",
                                "name": "Alexander McQueen Plastic Round Sunglasses, White",
                                "url": "https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "brand": "Alexander McQueen",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Alexander McQueen plastic sunglasses. Round frames. Gradient, brown lenses. Thin arms tapered at ends. Logo at temples. Imported.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "1d08b02aace2fdabfeafab79d90da7d4",
                            "created_at": "2018-05-01T19:10:35.518Z",
                            "updated_at": "2018-06-26T23:42:09.356Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cfe81393dd3d291162f7",
                            "schema": "product",
                            "score": 0.8912718391418457,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/16/11/161140d8ffb916ab75fc3354cf9beec0_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "718814514_White:718814514:White:https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "sku": "718814514",
                                "name": "Quay X Desi Don't At Me Sunglasses In White Smoke",
                                "url": "https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Quay Australia and Desi Perkins, We love a power couple, Chunky frames, Moulded nose pads for added comfort, Dark-tinted lenses, Tapered arms with curved temple tips for a secure fit, Presented in a branded sunglasses case, A safe place for your sunnies. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 65,
                                "currency": "USD"
                            },
                            "fingerprint": "483680d1a8f7812c7dca0f69a640e1d0",
                            "created_at": "2018-05-01T20:36:56.595Z",
                            "updated_at": "2018-06-23T01:25:18.119Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e4c2b77b36100f18605e",
                            "schema": "product",
                            "score": 0.8912650966644287,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/2d/70/2d70d521540bba5bd419509564563abb_best.jpg",
                                    "width": 720,
                                    "height": 715
                                }
                            ],
                            "data": {
                                "id": "720044806_white:720044806:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "sku": "720044806",
                                "name": "Super by Retrosuperfuture Women's Gals Brigitte 52MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Square frame with tinted lens and stud accents 52mm lens width; 22mm bridge width; 140mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 209,
                                "currency": "USD"
                            },
                            "fingerprint": "116e80778d12fb00c8a66034732cdfdf",
                            "created_at": "2018-05-01T22:05:54.117Z",
                            "updated_at": "2018-06-23T01:25:09.987Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cf751393dd3d2911212e",
                            "schema": "product",
                            "score": 0.8912115097045898,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5b/72/5b72ef6a825d866a0e39d77987bcd349_best.jpg",
                                    "width": 720,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "687048457_Matte clear:687048457:Matte clear:https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "sku": "687048457",
                                "name": "Oakley Oo9013 square-frame sunglasses",
                                "url": "https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "brand": "Oakley",
                                "color": "Matte clear",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Founded in 1975 by Jim Jannard, Oakley continues to provide dynamic and streamlined equipment, including a vast eyewear collection. Straight from the coveted Frogskin range, the Oo9013 sunglasses are cut to a square frame with the signature keyhole nose and showcase the American label's modern sensibilities with a rich two tone base and transparent rims.Oakley sunglassesSquare frame, brand lettering, keyhole nose, charcoal lenses, transparent edging, two tone 100% UV protectionFrame width: 13.5cm Fabric pouch",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 109,
                                "currency": "USD"
                            },
                            "fingerprint": "f7403ef1da4d27121ad8e0f15496325d",
                            "created_at": "2018-05-01T20:35:01.468Z",
                            "updated_at": "2018-06-25T17:34:19.798Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-27",
            "index": 27,
            "timestamp": 5400,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000028.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-28",
            "index": 28,
            "timestamp": 5600,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000029.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "03c78fb3-871c-421f-b3b2-8d8a5d1b9c06",
                    "score": 0.9762849522673566,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 158.406005859375,
                        "y": 146.42872619628906,
                        "width": 176.07333374023438,
                        "height": 69.89971923828125
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fe756efb8d3d2f8e281e",
                            "schema": "product",
                            "score": 0.9052010536193847,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/44/a3443255733002d9efc94df59b77be79_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679796721_White:679796721:White:https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "sku": "679796721",
                                "name": "Quay 20s Oversized Square Sunglasses In White",
                                "url": "https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Kylie Jenner, Oversized square frames, Moulded nose pads for added comfort, Dark tinted lenses, Branded arms with curved temple tips for a secure fit, Good UV protection. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 80,
                                "currency": "USD"
                            },
                            "fingerprint": "09ff363e17d04cb01af9d2d6c673c7e2",
                            "created_at": "2018-05-01T23:55:33.921Z",
                            "updated_at": "2018-06-23T01:24:21.222Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cc1f1393dd3d290f39c8",
                            "schema": "product",
                            "score": 0.9019440078735351,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/42/b5/42b5c0cbf4c22387219eaaaf97788c14_best.jpg",
                                    "width": 720,
                                    "height": 368
                                }
                            ],
                            "data": {
                                "id": "704685010_IVORY:704685010:IVORY:https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "sku": "704685010",
                                "name": "Saint Laurent Square Acetate Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "IVORY",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Saint Laurent acetate sunglasses. Square frames. Dimensions (in mm): 57-11-140. Thin, tapered arms. 100% UVA/UVB protection. Made in Italy.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 250,
                                "currency": "USD"
                            },
                            "fingerprint": "aed55f70d5281b3ee4bc83e9a83e3b48",
                            "created_at": "2018-05-01T20:20:47.778Z",
                            "updated_at": "2018-06-18T22:48:08.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0efb77b36100f161e01",
                            "schema": "product",
                            "score": 0.8987179946899414,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/15/35/1535cd937adbfe55b95dc478d4c05895_best.jpg",
                                    "width": 720,
                                    "height": 727
                                }
                            ],
                            "data": {
                                "id": "720021299_white:720021299:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "sku": "720021299",
                                "name": "Super by Retrosuperfuture Women's Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "04f62e9a4ae2f9447415128412e93a85",
                            "created_at": "2018-05-01T21:49:35.886Z",
                            "updated_at": "2018-06-23T01:25:18.179Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c3a96efb8d3d2f81425c",
                            "schema": "product",
                            "score": 0.8966365814208984,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/57/6a/576a8a7682f6aa2000e55b90fa797d89_best.jpg",
                                    "width": 264,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "660097270_White:660097270:White:https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "sku": "660097270",
                                "name": "Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "brand": "Super by Retrosuperfuture",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "3b0bc3185003eea9ae599bacb58a6732",
                            "created_at": "2018-05-01T19:44:41.008Z",
                            "updated_at": "2018-06-23T01:24:27.903Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c1bc6efb8d3d2f806363",
                            "schema": "product",
                            "score": 0.8945560836791993,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0c/b1/0cb174192aa75d63f1d740f027f665e5_best.jpg",
                                    "width": 336,
                                    "height": 335
                                }
                            ],
                            "data": {
                                "id": "713296552_Ivory:713296552:Ivory:https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "sku": "713296552",
                                "name": "Seafolly Tortola V2 51mm Polarized Cat Eye Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "brand": "Seafolly",
                                "color": "Ivory",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Bold, blocky frames add to the chic, futuristic look of cat-eye sunglasses fitted with glare-reducing polarized lenses. Style Name:Seafolly Tortola V2 51Mm Polarized Cat Eye Sunglasses. Style Number: 5499557. Available in stores.",
                                "category": "Women > Accessories |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 89,
                                "currency": "USD"
                            },
                            "fingerprint": "38fd2bc7af2879cdc0290c9f9b9c826c",
                            "created_at": "2018-05-01T19:36:28.331Z",
                            "updated_at": "2018-06-18T15:18:05.556Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ff2c6efb8d3d2f8e8a0c",
                            "schema": "product",
                            "score": 0.8936824131011962,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/97/49/97494e0c59a70aeee1f95eb5a4b4dcd3_best.jpg",
                                    "width": 720,
                                    "height": 402
                                }
                            ],
                            "data": {
                                "id": "613926118_WHITE:613926118:WHITE:https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "sku": "613926118",
                                "name": "Stella McCartney Square Plastic Sunglasses w/ Chain Arms, White",
                                "url": "https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "brand": "Stella McCartney",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Stella McCartney plastic sunglasses feature silvertone chain detail at arms. Opaque white square frames. Gradient smoke lenses. Wide, tapered arms. 100% UV protection.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "985424f7206b70676718c07c5c700fe5",
                            "created_at": "2018-05-01T23:58:36.338Z",
                            "updated_at": "2018-06-23T01:24:21.982Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8bbabb77b36100f150f5d",
                            "schema": "product",
                            "score": 0.89348952293396,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a8/01/a8014c544b60be07b756742f5b175d8a_best.jpg",
                                    "width": 720,
                                    "height": 321
                                }
                            ],
                            "data": {
                                "id": "642874259_WHITE:642874259:WHITE:https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "sku": "642874259",
                                "name": "Alexander McQueen Plastic Round Sunglasses, White",
                                "url": "https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "brand": "Alexander McQueen",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Alexander McQueen plastic sunglasses. Round frames. Gradient, brown lenses. Thin arms tapered at ends. Logo at temples. Imported.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "1d08b02aace2fdabfeafab79d90da7d4",
                            "created_at": "2018-05-01T19:10:35.518Z",
                            "updated_at": "2018-06-26T23:42:09.356Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cfe81393dd3d291162f7",
                            "schema": "product",
                            "score": 0.8912718391418457,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/16/11/161140d8ffb916ab75fc3354cf9beec0_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "718814514_White:718814514:White:https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "sku": "718814514",
                                "name": "Quay X Desi Don't At Me Sunglasses In White Smoke",
                                "url": "https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Quay Australia and Desi Perkins, We love a power couple, Chunky frames, Moulded nose pads for added comfort, Dark-tinted lenses, Tapered arms with curved temple tips for a secure fit, Presented in a branded sunglasses case, A safe place for your sunnies. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 65,
                                "currency": "USD"
                            },
                            "fingerprint": "483680d1a8f7812c7dca0f69a640e1d0",
                            "created_at": "2018-05-01T20:36:56.595Z",
                            "updated_at": "2018-06-23T01:25:18.119Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e4c2b77b36100f18605e",
                            "schema": "product",
                            "score": 0.8912650966644287,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/2d/70/2d70d521540bba5bd419509564563abb_best.jpg",
                                    "width": 720,
                                    "height": 715
                                }
                            ],
                            "data": {
                                "id": "720044806_white:720044806:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "sku": "720044806",
                                "name": "Super by Retrosuperfuture Women's Gals Brigitte 52MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Square frame with tinted lens and stud accents 52mm lens width; 22mm bridge width; 140mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 209,
                                "currency": "USD"
                            },
                            "fingerprint": "116e80778d12fb00c8a66034732cdfdf",
                            "created_at": "2018-05-01T22:05:54.117Z",
                            "updated_at": "2018-06-23T01:25:09.987Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cf751393dd3d2911212e",
                            "schema": "product",
                            "score": 0.8912115097045898,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5b/72/5b72ef6a825d866a0e39d77987bcd349_best.jpg",
                                    "width": 720,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "687048457_Matte clear:687048457:Matte clear:https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "sku": "687048457",
                                "name": "Oakley Oo9013 square-frame sunglasses",
                                "url": "https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "brand": "Oakley",
                                "color": "Matte clear",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Founded in 1975 by Jim Jannard, Oakley continues to provide dynamic and streamlined equipment, including a vast eyewear collection. Straight from the coveted Frogskin range, the Oo9013 sunglasses are cut to a square frame with the signature keyhole nose and showcase the American label's modern sensibilities with a rich two tone base and transparent rims.Oakley sunglassesSquare frame, brand lettering, keyhole nose, charcoal lenses, transparent edging, two tone 100% UV protectionFrame width: 13.5cm Fabric pouch",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 109,
                                "currency": "USD"
                            },
                            "fingerprint": "f7403ef1da4d27121ad8e0f15496325d",
                            "created_at": "2018-05-01T20:35:01.468Z",
                            "updated_at": "2018-06-25T17:34:19.798Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-29",
            "index": 29,
            "timestamp": 5800,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000030.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "b6e8e2bc-131d-4e0c-9a0b-f3bba86ad05f",
                    "score": 0.9762849522673566,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 152.7135009765625,
                        "y": 173.0008544921875,
                        "width": 174.69119262695312,
                        "height": 56.6737060546875
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fe756efb8d3d2f8e281e",
                            "schema": "product",
                            "score": 0.9052010536193847,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/44/a3443255733002d9efc94df59b77be79_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679796721_White:679796721:White:https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "sku": "679796721",
                                "name": "Quay 20s Oversized Square Sunglasses In White",
                                "url": "https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Kylie Jenner, Oversized square frames, Moulded nose pads for added comfort, Dark tinted lenses, Branded arms with curved temple tips for a secure fit, Good UV protection. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 80,
                                "currency": "USD"
                            },
                            "fingerprint": "09ff363e17d04cb01af9d2d6c673c7e2",
                            "created_at": "2018-05-01T23:55:33.921Z",
                            "updated_at": "2018-06-23T01:24:21.222Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cc1f1393dd3d290f39c8",
                            "schema": "product",
                            "score": 0.9019440078735351,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/42/b5/42b5c0cbf4c22387219eaaaf97788c14_best.jpg",
                                    "width": 720,
                                    "height": 368
                                }
                            ],
                            "data": {
                                "id": "704685010_IVORY:704685010:IVORY:https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "sku": "704685010",
                                "name": "Saint Laurent Square Acetate Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "IVORY",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Saint Laurent acetate sunglasses. Square frames. Dimensions (in mm): 57-11-140. Thin, tapered arms. 100% UVA/UVB protection. Made in Italy.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 250,
                                "currency": "USD"
                            },
                            "fingerprint": "aed55f70d5281b3ee4bc83e9a83e3b48",
                            "created_at": "2018-05-01T20:20:47.778Z",
                            "updated_at": "2018-06-18T22:48:08.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0efb77b36100f161e01",
                            "schema": "product",
                            "score": 0.8987179946899414,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/15/35/1535cd937adbfe55b95dc478d4c05895_best.jpg",
                                    "width": 720,
                                    "height": 727
                                }
                            ],
                            "data": {
                                "id": "720021299_white:720021299:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "sku": "720021299",
                                "name": "Super by Retrosuperfuture Women's Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "04f62e9a4ae2f9447415128412e93a85",
                            "created_at": "2018-05-01T21:49:35.886Z",
                            "updated_at": "2018-06-23T01:25:18.179Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c3a96efb8d3d2f81425c",
                            "schema": "product",
                            "score": 0.8966365814208984,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/57/6a/576a8a7682f6aa2000e55b90fa797d89_best.jpg",
                                    "width": 264,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "660097270_White:660097270:White:https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "sku": "660097270",
                                "name": "Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "brand": "Super by Retrosuperfuture",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "3b0bc3185003eea9ae599bacb58a6732",
                            "created_at": "2018-05-01T19:44:41.008Z",
                            "updated_at": "2018-06-23T01:24:27.903Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c1bc6efb8d3d2f806363",
                            "schema": "product",
                            "score": 0.8945560836791993,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0c/b1/0cb174192aa75d63f1d740f027f665e5_best.jpg",
                                    "width": 336,
                                    "height": 335
                                }
                            ],
                            "data": {
                                "id": "713296552_Ivory:713296552:Ivory:https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "sku": "713296552",
                                "name": "Seafolly Tortola V2 51mm Polarized Cat Eye Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "brand": "Seafolly",
                                "color": "Ivory",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Bold, blocky frames add to the chic, futuristic look of cat-eye sunglasses fitted with glare-reducing polarized lenses. Style Name:Seafolly Tortola V2 51Mm Polarized Cat Eye Sunglasses. Style Number: 5499557. Available in stores.",
                                "category": "Women > Accessories |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 89,
                                "currency": "USD"
                            },
                            "fingerprint": "38fd2bc7af2879cdc0290c9f9b9c826c",
                            "created_at": "2018-05-01T19:36:28.331Z",
                            "updated_at": "2018-06-18T15:18:05.556Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ff2c6efb8d3d2f8e8a0c",
                            "schema": "product",
                            "score": 0.8936824131011962,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/97/49/97494e0c59a70aeee1f95eb5a4b4dcd3_best.jpg",
                                    "width": 720,
                                    "height": 402
                                }
                            ],
                            "data": {
                                "id": "613926118_WHITE:613926118:WHITE:https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "sku": "613926118",
                                "name": "Stella McCartney Square Plastic Sunglasses w/ Chain Arms, White",
                                "url": "https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "brand": "Stella McCartney",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Stella McCartney plastic sunglasses feature silvertone chain detail at arms. Opaque white square frames. Gradient smoke lenses. Wide, tapered arms. 100% UV protection.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "985424f7206b70676718c07c5c700fe5",
                            "created_at": "2018-05-01T23:58:36.338Z",
                            "updated_at": "2018-06-23T01:24:21.982Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8bbabb77b36100f150f5d",
                            "schema": "product",
                            "score": 0.89348952293396,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a8/01/a8014c544b60be07b756742f5b175d8a_best.jpg",
                                    "width": 720,
                                    "height": 321
                                }
                            ],
                            "data": {
                                "id": "642874259_WHITE:642874259:WHITE:https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "sku": "642874259",
                                "name": "Alexander McQueen Plastic Round Sunglasses, White",
                                "url": "https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "brand": "Alexander McQueen",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Alexander McQueen plastic sunglasses. Round frames. Gradient, brown lenses. Thin arms tapered at ends. Logo at temples. Imported.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "1d08b02aace2fdabfeafab79d90da7d4",
                            "created_at": "2018-05-01T19:10:35.518Z",
                            "updated_at": "2018-06-26T23:42:09.356Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cfe81393dd3d291162f7",
                            "schema": "product",
                            "score": 0.8912718391418457,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/16/11/161140d8ffb916ab75fc3354cf9beec0_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "718814514_White:718814514:White:https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "sku": "718814514",
                                "name": "Quay X Desi Don't At Me Sunglasses In White Smoke",
                                "url": "https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Quay Australia and Desi Perkins, We love a power couple, Chunky frames, Moulded nose pads for added comfort, Dark-tinted lenses, Tapered arms with curved temple tips for a secure fit, Presented in a branded sunglasses case, A safe place for your sunnies. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 65,
                                "currency": "USD"
                            },
                            "fingerprint": "483680d1a8f7812c7dca0f69a640e1d0",
                            "created_at": "2018-05-01T20:36:56.595Z",
                            "updated_at": "2018-06-23T01:25:18.119Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e4c2b77b36100f18605e",
                            "schema": "product",
                            "score": 0.8912650966644287,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/2d/70/2d70d521540bba5bd419509564563abb_best.jpg",
                                    "width": 720,
                                    "height": 715
                                }
                            ],
                            "data": {
                                "id": "720044806_white:720044806:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "sku": "720044806",
                                "name": "Super by Retrosuperfuture Women's Gals Brigitte 52MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Square frame with tinted lens and stud accents 52mm lens width; 22mm bridge width; 140mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 209,
                                "currency": "USD"
                            },
                            "fingerprint": "116e80778d12fb00c8a66034732cdfdf",
                            "created_at": "2018-05-01T22:05:54.117Z",
                            "updated_at": "2018-06-23T01:25:09.987Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cf751393dd3d2911212e",
                            "schema": "product",
                            "score": 0.8912115097045898,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5b/72/5b72ef6a825d866a0e39d77987bcd349_best.jpg",
                                    "width": 720,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "687048457_Matte clear:687048457:Matte clear:https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "sku": "687048457",
                                "name": "Oakley Oo9013 square-frame sunglasses",
                                "url": "https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "brand": "Oakley",
                                "color": "Matte clear",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Founded in 1975 by Jim Jannard, Oakley continues to provide dynamic and streamlined equipment, including a vast eyewear collection. Straight from the coveted Frogskin range, the Oo9013 sunglasses are cut to a square frame with the signature keyhole nose and showcase the American label's modern sensibilities with a rich two tone base and transparent rims.Oakley sunglassesSquare frame, brand lettering, keyhole nose, charcoal lenses, transparent edging, two tone 100% UV protectionFrame width: 13.5cm Fabric pouch",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 109,
                                "currency": "USD"
                            },
                            "fingerprint": "f7403ef1da4d27121ad8e0f15496325d",
                            "created_at": "2018-05-01T20:35:01.468Z",
                            "updated_at": "2018-06-25T17:34:19.798Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-30",
            "index": 30,
            "timestamp": 6000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000031.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "3812b60a-2f62-4eb1-af38-3d677b093ef8",
                    "score": 0.9762849522673566,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 124.958984375,
                        "y": 31.8829345703125,
                        "width": 175.0340576171875,
                        "height": 83.05319213867188
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fe756efb8d3d2f8e281e",
                            "schema": "product",
                            "score": 0.9052010536193847,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/44/a3443255733002d9efc94df59b77be79_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679796721_White:679796721:White:https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "sku": "679796721",
                                "name": "Quay 20s Oversized Square Sunglasses In White",
                                "url": "https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Kylie Jenner, Oversized square frames, Moulded nose pads for added comfort, Dark tinted lenses, Branded arms with curved temple tips for a secure fit, Good UV protection. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 80,
                                "currency": "USD"
                            },
                            "fingerprint": "09ff363e17d04cb01af9d2d6c673c7e2",
                            "created_at": "2018-05-01T23:55:33.921Z",
                            "updated_at": "2018-06-23T01:24:21.222Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cc1f1393dd3d290f39c8",
                            "schema": "product",
                            "score": 0.9019440078735351,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/42/b5/42b5c0cbf4c22387219eaaaf97788c14_best.jpg",
                                    "width": 720,
                                    "height": 368
                                }
                            ],
                            "data": {
                                "id": "704685010_IVORY:704685010:IVORY:https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "sku": "704685010",
                                "name": "Saint Laurent Square Acetate Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "IVORY",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Saint Laurent acetate sunglasses. Square frames. Dimensions (in mm): 57-11-140. Thin, tapered arms. 100% UVA/UVB protection. Made in Italy.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 250,
                                "currency": "USD"
                            },
                            "fingerprint": "aed55f70d5281b3ee4bc83e9a83e3b48",
                            "created_at": "2018-05-01T20:20:47.778Z",
                            "updated_at": "2018-06-18T22:48:08.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0efb77b36100f161e01",
                            "schema": "product",
                            "score": 0.8987179946899414,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/15/35/1535cd937adbfe55b95dc478d4c05895_best.jpg",
                                    "width": 720,
                                    "height": 727
                                }
                            ],
                            "data": {
                                "id": "720021299_white:720021299:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "sku": "720021299",
                                "name": "Super by Retrosuperfuture Women's Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "04f62e9a4ae2f9447415128412e93a85",
                            "created_at": "2018-05-01T21:49:35.886Z",
                            "updated_at": "2018-06-23T01:25:18.179Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c3a96efb8d3d2f81425c",
                            "schema": "product",
                            "score": 0.8966365814208984,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/57/6a/576a8a7682f6aa2000e55b90fa797d89_best.jpg",
                                    "width": 264,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "660097270_White:660097270:White:https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "sku": "660097270",
                                "name": "Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "brand": "Super by Retrosuperfuture",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "3b0bc3185003eea9ae599bacb58a6732",
                            "created_at": "2018-05-01T19:44:41.008Z",
                            "updated_at": "2018-06-23T01:24:27.903Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c1bc6efb8d3d2f806363",
                            "schema": "product",
                            "score": 0.8945560836791993,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0c/b1/0cb174192aa75d63f1d740f027f665e5_best.jpg",
                                    "width": 336,
                                    "height": 335
                                }
                            ],
                            "data": {
                                "id": "713296552_Ivory:713296552:Ivory:https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "sku": "713296552",
                                "name": "Seafolly Tortola V2 51mm Polarized Cat Eye Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "brand": "Seafolly",
                                "color": "Ivory",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Bold, blocky frames add to the chic, futuristic look of cat-eye sunglasses fitted with glare-reducing polarized lenses. Style Name:Seafolly Tortola V2 51Mm Polarized Cat Eye Sunglasses. Style Number: 5499557. Available in stores.",
                                "category": "Women > Accessories |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 89,
                                "currency": "USD"
                            },
                            "fingerprint": "38fd2bc7af2879cdc0290c9f9b9c826c",
                            "created_at": "2018-05-01T19:36:28.331Z",
                            "updated_at": "2018-06-18T15:18:05.556Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ff2c6efb8d3d2f8e8a0c",
                            "schema": "product",
                            "score": 0.8936824131011962,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/97/49/97494e0c59a70aeee1f95eb5a4b4dcd3_best.jpg",
                                    "width": 720,
                                    "height": 402
                                }
                            ],
                            "data": {
                                "id": "613926118_WHITE:613926118:WHITE:https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "sku": "613926118",
                                "name": "Stella McCartney Square Plastic Sunglasses w/ Chain Arms, White",
                                "url": "https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "brand": "Stella McCartney",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Stella McCartney plastic sunglasses feature silvertone chain detail at arms. Opaque white square frames. Gradient smoke lenses. Wide, tapered arms. 100% UV protection.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "985424f7206b70676718c07c5c700fe5",
                            "created_at": "2018-05-01T23:58:36.338Z",
                            "updated_at": "2018-06-23T01:24:21.982Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8bbabb77b36100f150f5d",
                            "schema": "product",
                            "score": 0.89348952293396,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a8/01/a8014c544b60be07b756742f5b175d8a_best.jpg",
                                    "width": 720,
                                    "height": 321
                                }
                            ],
                            "data": {
                                "id": "642874259_WHITE:642874259:WHITE:https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "sku": "642874259",
                                "name": "Alexander McQueen Plastic Round Sunglasses, White",
                                "url": "https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "brand": "Alexander McQueen",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Alexander McQueen plastic sunglasses. Round frames. Gradient, brown lenses. Thin arms tapered at ends. Logo at temples. Imported.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "1d08b02aace2fdabfeafab79d90da7d4",
                            "created_at": "2018-05-01T19:10:35.518Z",
                            "updated_at": "2018-06-26T23:42:09.356Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cfe81393dd3d291162f7",
                            "schema": "product",
                            "score": 0.8912718391418457,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/16/11/161140d8ffb916ab75fc3354cf9beec0_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "718814514_White:718814514:White:https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "sku": "718814514",
                                "name": "Quay X Desi Don't At Me Sunglasses In White Smoke",
                                "url": "https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Quay Australia and Desi Perkins, We love a power couple, Chunky frames, Moulded nose pads for added comfort, Dark-tinted lenses, Tapered arms with curved temple tips for a secure fit, Presented in a branded sunglasses case, A safe place for your sunnies. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 65,
                                "currency": "USD"
                            },
                            "fingerprint": "483680d1a8f7812c7dca0f69a640e1d0",
                            "created_at": "2018-05-01T20:36:56.595Z",
                            "updated_at": "2018-06-23T01:25:18.119Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e4c2b77b36100f18605e",
                            "schema": "product",
                            "score": 0.8912650966644287,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/2d/70/2d70d521540bba5bd419509564563abb_best.jpg",
                                    "width": 720,
                                    "height": 715
                                }
                            ],
                            "data": {
                                "id": "720044806_white:720044806:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "sku": "720044806",
                                "name": "Super by Retrosuperfuture Women's Gals Brigitte 52MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Square frame with tinted lens and stud accents 52mm lens width; 22mm bridge width; 140mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 209,
                                "currency": "USD"
                            },
                            "fingerprint": "116e80778d12fb00c8a66034732cdfdf",
                            "created_at": "2018-05-01T22:05:54.117Z",
                            "updated_at": "2018-06-23T01:25:09.987Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cf751393dd3d2911212e",
                            "schema": "product",
                            "score": 0.8912115097045898,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5b/72/5b72ef6a825d866a0e39d77987bcd349_best.jpg",
                                    "width": 720,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "687048457_Matte clear:687048457:Matte clear:https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "sku": "687048457",
                                "name": "Oakley Oo9013 square-frame sunglasses",
                                "url": "https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "brand": "Oakley",
                                "color": "Matte clear",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Founded in 1975 by Jim Jannard, Oakley continues to provide dynamic and streamlined equipment, including a vast eyewear collection. Straight from the coveted Frogskin range, the Oo9013 sunglasses are cut to a square frame with the signature keyhole nose and showcase the American label's modern sensibilities with a rich two tone base and transparent rims.Oakley sunglassesSquare frame, brand lettering, keyhole nose, charcoal lenses, transparent edging, two tone 100% UV protectionFrame width: 13.5cm Fabric pouch",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 109,
                                "currency": "USD"
                            },
                            "fingerprint": "f7403ef1da4d27121ad8e0f15496325d",
                            "created_at": "2018-05-01T20:35:01.468Z",
                            "updated_at": "2018-06-25T17:34:19.798Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-31",
            "index": 31,
            "timestamp": 6200,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000032.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "7d260303-2c8d-4536-8c98-da8a48850b77",
                    "score": 0.9762849522673566,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 132.07666015625,
                        "y": 64.7822036743164,
                        "width": 164.39666748046875,
                        "height": 88.12410736083984
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fe756efb8d3d2f8e281e",
                            "schema": "product",
                            "score": 0.9052010536193847,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/44/a3443255733002d9efc94df59b77be79_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679796721_White:679796721:White:https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "sku": "679796721",
                                "name": "Quay 20s Oversized Square Sunglasses In White",
                                "url": "https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Kylie Jenner, Oversized square frames, Moulded nose pads for added comfort, Dark tinted lenses, Branded arms with curved temple tips for a secure fit, Good UV protection. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 80,
                                "currency": "USD"
                            },
                            "fingerprint": "09ff363e17d04cb01af9d2d6c673c7e2",
                            "created_at": "2018-05-01T23:55:33.921Z",
                            "updated_at": "2018-06-23T01:24:21.222Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cc1f1393dd3d290f39c8",
                            "schema": "product",
                            "score": 0.9019440078735351,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/42/b5/42b5c0cbf4c22387219eaaaf97788c14_best.jpg",
                                    "width": 720,
                                    "height": 368
                                }
                            ],
                            "data": {
                                "id": "704685010_IVORY:704685010:IVORY:https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "sku": "704685010",
                                "name": "Saint Laurent Square Acetate Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "IVORY",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Saint Laurent acetate sunglasses. Square frames. Dimensions (in mm): 57-11-140. Thin, tapered arms. 100% UVA/UVB protection. Made in Italy.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 250,
                                "currency": "USD"
                            },
                            "fingerprint": "aed55f70d5281b3ee4bc83e9a83e3b48",
                            "created_at": "2018-05-01T20:20:47.778Z",
                            "updated_at": "2018-06-18T22:48:08.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0efb77b36100f161e01",
                            "schema": "product",
                            "score": 0.8987179946899414,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/15/35/1535cd937adbfe55b95dc478d4c05895_best.jpg",
                                    "width": 720,
                                    "height": 727
                                }
                            ],
                            "data": {
                                "id": "720021299_white:720021299:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "sku": "720021299",
                                "name": "Super by Retrosuperfuture Women's Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "04f62e9a4ae2f9447415128412e93a85",
                            "created_at": "2018-05-01T21:49:35.886Z",
                            "updated_at": "2018-06-23T01:25:18.179Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c3a96efb8d3d2f81425c",
                            "schema": "product",
                            "score": 0.8966365814208984,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/57/6a/576a8a7682f6aa2000e55b90fa797d89_best.jpg",
                                    "width": 264,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "660097270_White:660097270:White:https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "sku": "660097270",
                                "name": "Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "brand": "Super by Retrosuperfuture",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "3b0bc3185003eea9ae599bacb58a6732",
                            "created_at": "2018-05-01T19:44:41.008Z",
                            "updated_at": "2018-06-23T01:24:27.903Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c1bc6efb8d3d2f806363",
                            "schema": "product",
                            "score": 0.8945560836791993,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0c/b1/0cb174192aa75d63f1d740f027f665e5_best.jpg",
                                    "width": 336,
                                    "height": 335
                                }
                            ],
                            "data": {
                                "id": "713296552_Ivory:713296552:Ivory:https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "sku": "713296552",
                                "name": "Seafolly Tortola V2 51mm Polarized Cat Eye Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "brand": "Seafolly",
                                "color": "Ivory",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Bold, blocky frames add to the chic, futuristic look of cat-eye sunglasses fitted with glare-reducing polarized lenses. Style Name:Seafolly Tortola V2 51Mm Polarized Cat Eye Sunglasses. Style Number: 5499557. Available in stores.",
                                "category": "Women > Accessories |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 89,
                                "currency": "USD"
                            },
                            "fingerprint": "38fd2bc7af2879cdc0290c9f9b9c826c",
                            "created_at": "2018-05-01T19:36:28.331Z",
                            "updated_at": "2018-06-18T15:18:05.556Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ff2c6efb8d3d2f8e8a0c",
                            "schema": "product",
                            "score": 0.8936824131011962,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/97/49/97494e0c59a70aeee1f95eb5a4b4dcd3_best.jpg",
                                    "width": 720,
                                    "height": 402
                                }
                            ],
                            "data": {
                                "id": "613926118_WHITE:613926118:WHITE:https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "sku": "613926118",
                                "name": "Stella McCartney Square Plastic Sunglasses w/ Chain Arms, White",
                                "url": "https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "brand": "Stella McCartney",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Stella McCartney plastic sunglasses feature silvertone chain detail at arms. Opaque white square frames. Gradient smoke lenses. Wide, tapered arms. 100% UV protection.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "985424f7206b70676718c07c5c700fe5",
                            "created_at": "2018-05-01T23:58:36.338Z",
                            "updated_at": "2018-06-23T01:24:21.982Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8bbabb77b36100f150f5d",
                            "schema": "product",
                            "score": 0.89348952293396,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a8/01/a8014c544b60be07b756742f5b175d8a_best.jpg",
                                    "width": 720,
                                    "height": 321
                                }
                            ],
                            "data": {
                                "id": "642874259_WHITE:642874259:WHITE:https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "sku": "642874259",
                                "name": "Alexander McQueen Plastic Round Sunglasses, White",
                                "url": "https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "brand": "Alexander McQueen",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Alexander McQueen plastic sunglasses. Round frames. Gradient, brown lenses. Thin arms tapered at ends. Logo at temples. Imported.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "1d08b02aace2fdabfeafab79d90da7d4",
                            "created_at": "2018-05-01T19:10:35.518Z",
                            "updated_at": "2018-06-26T23:42:09.356Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cfe81393dd3d291162f7",
                            "schema": "product",
                            "score": 0.8912718391418457,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/16/11/161140d8ffb916ab75fc3354cf9beec0_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "718814514_White:718814514:White:https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "sku": "718814514",
                                "name": "Quay X Desi Don't At Me Sunglasses In White Smoke",
                                "url": "https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Quay Australia and Desi Perkins, We love a power couple, Chunky frames, Moulded nose pads for added comfort, Dark-tinted lenses, Tapered arms with curved temple tips for a secure fit, Presented in a branded sunglasses case, A safe place for your sunnies. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 65,
                                "currency": "USD"
                            },
                            "fingerprint": "483680d1a8f7812c7dca0f69a640e1d0",
                            "created_at": "2018-05-01T20:36:56.595Z",
                            "updated_at": "2018-06-23T01:25:18.119Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e4c2b77b36100f18605e",
                            "schema": "product",
                            "score": 0.8912650966644287,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/2d/70/2d70d521540bba5bd419509564563abb_best.jpg",
                                    "width": 720,
                                    "height": 715
                                }
                            ],
                            "data": {
                                "id": "720044806_white:720044806:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "sku": "720044806",
                                "name": "Super by Retrosuperfuture Women's Gals Brigitte 52MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Square frame with tinted lens and stud accents 52mm lens width; 22mm bridge width; 140mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 209,
                                "currency": "USD"
                            },
                            "fingerprint": "116e80778d12fb00c8a66034732cdfdf",
                            "created_at": "2018-05-01T22:05:54.117Z",
                            "updated_at": "2018-06-23T01:25:09.987Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cf751393dd3d2911212e",
                            "schema": "product",
                            "score": 0.8912115097045898,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5b/72/5b72ef6a825d866a0e39d77987bcd349_best.jpg",
                                    "width": 720,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "687048457_Matte clear:687048457:Matte clear:https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "sku": "687048457",
                                "name": "Oakley Oo9013 square-frame sunglasses",
                                "url": "https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "brand": "Oakley",
                                "color": "Matte clear",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Founded in 1975 by Jim Jannard, Oakley continues to provide dynamic and streamlined equipment, including a vast eyewear collection. Straight from the coveted Frogskin range, the Oo9013 sunglasses are cut to a square frame with the signature keyhole nose and showcase the American label's modern sensibilities with a rich two tone base and transparent rims.Oakley sunglassesSquare frame, brand lettering, keyhole nose, charcoal lenses, transparent edging, two tone 100% UV protectionFrame width: 13.5cm Fabric pouch",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 109,
                                "currency": "USD"
                            },
                            "fingerprint": "f7403ef1da4d27121ad8e0f15496325d",
                            "created_at": "2018-05-01T20:35:01.468Z",
                            "updated_at": "2018-06-25T17:34:19.798Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-32",
            "index": 32,
            "timestamp": 6400,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000033.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "2ec08e0a-6334-4866-b0cd-e43c61d279e3",
                    "score": 0.9762849522673566,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 149.08453369140625,
                        "y": 93.05093383789062,
                        "width": 139.57647705078125,
                        "height": 67.82713317871094
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fe756efb8d3d2f8e281e",
                            "schema": "product",
                            "score": 0.9052010536193847,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/44/a3443255733002d9efc94df59b77be79_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679796721_White:679796721:White:https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "sku": "679796721",
                                "name": "Quay 20s Oversized Square Sunglasses In White",
                                "url": "https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Kylie Jenner, Oversized square frames, Moulded nose pads for added comfort, Dark tinted lenses, Branded arms with curved temple tips for a secure fit, Good UV protection. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 80,
                                "currency": "USD"
                            },
                            "fingerprint": "09ff363e17d04cb01af9d2d6c673c7e2",
                            "created_at": "2018-05-01T23:55:33.921Z",
                            "updated_at": "2018-06-23T01:24:21.222Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cc1f1393dd3d290f39c8",
                            "schema": "product",
                            "score": 0.9019440078735351,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/42/b5/42b5c0cbf4c22387219eaaaf97788c14_best.jpg",
                                    "width": 720,
                                    "height": 368
                                }
                            ],
                            "data": {
                                "id": "704685010_IVORY:704685010:IVORY:https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "sku": "704685010",
                                "name": "Saint Laurent Square Acetate Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "IVORY",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Saint Laurent acetate sunglasses. Square frames. Dimensions (in mm): 57-11-140. Thin, tapered arms. 100% UVA/UVB protection. Made in Italy.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 250,
                                "currency": "USD"
                            },
                            "fingerprint": "aed55f70d5281b3ee4bc83e9a83e3b48",
                            "created_at": "2018-05-01T20:20:47.778Z",
                            "updated_at": "2018-06-18T22:48:08.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0efb77b36100f161e01",
                            "schema": "product",
                            "score": 0.8987179946899414,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/15/35/1535cd937adbfe55b95dc478d4c05895_best.jpg",
                                    "width": 720,
                                    "height": 727
                                }
                            ],
                            "data": {
                                "id": "720021299_white:720021299:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "sku": "720021299",
                                "name": "Super by Retrosuperfuture Women's Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "04f62e9a4ae2f9447415128412e93a85",
                            "created_at": "2018-05-01T21:49:35.886Z",
                            "updated_at": "2018-06-23T01:25:18.179Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c3a96efb8d3d2f81425c",
                            "schema": "product",
                            "score": 0.8966365814208984,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/57/6a/576a8a7682f6aa2000e55b90fa797d89_best.jpg",
                                    "width": 264,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "660097270_White:660097270:White:https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "sku": "660097270",
                                "name": "Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "brand": "Super by Retrosuperfuture",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "3b0bc3185003eea9ae599bacb58a6732",
                            "created_at": "2018-05-01T19:44:41.008Z",
                            "updated_at": "2018-06-23T01:24:27.903Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c1bc6efb8d3d2f806363",
                            "schema": "product",
                            "score": 0.8945560836791993,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0c/b1/0cb174192aa75d63f1d740f027f665e5_best.jpg",
                                    "width": 336,
                                    "height": 335
                                }
                            ],
                            "data": {
                                "id": "713296552_Ivory:713296552:Ivory:https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "sku": "713296552",
                                "name": "Seafolly Tortola V2 51mm Polarized Cat Eye Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "brand": "Seafolly",
                                "color": "Ivory",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Bold, blocky frames add to the chic, futuristic look of cat-eye sunglasses fitted with glare-reducing polarized lenses. Style Name:Seafolly Tortola V2 51Mm Polarized Cat Eye Sunglasses. Style Number: 5499557. Available in stores.",
                                "category": "Women > Accessories |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 89,
                                "currency": "USD"
                            },
                            "fingerprint": "38fd2bc7af2879cdc0290c9f9b9c826c",
                            "created_at": "2018-05-01T19:36:28.331Z",
                            "updated_at": "2018-06-18T15:18:05.556Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ff2c6efb8d3d2f8e8a0c",
                            "schema": "product",
                            "score": 0.8936824131011962,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/97/49/97494e0c59a70aeee1f95eb5a4b4dcd3_best.jpg",
                                    "width": 720,
                                    "height": 402
                                }
                            ],
                            "data": {
                                "id": "613926118_WHITE:613926118:WHITE:https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "sku": "613926118",
                                "name": "Stella McCartney Square Plastic Sunglasses w/ Chain Arms, White",
                                "url": "https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "brand": "Stella McCartney",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Stella McCartney plastic sunglasses feature silvertone chain detail at arms. Opaque white square frames. Gradient smoke lenses. Wide, tapered arms. 100% UV protection.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "985424f7206b70676718c07c5c700fe5",
                            "created_at": "2018-05-01T23:58:36.338Z",
                            "updated_at": "2018-06-23T01:24:21.982Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8bbabb77b36100f150f5d",
                            "schema": "product",
                            "score": 0.89348952293396,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a8/01/a8014c544b60be07b756742f5b175d8a_best.jpg",
                                    "width": 720,
                                    "height": 321
                                }
                            ],
                            "data": {
                                "id": "642874259_WHITE:642874259:WHITE:https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "sku": "642874259",
                                "name": "Alexander McQueen Plastic Round Sunglasses, White",
                                "url": "https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "brand": "Alexander McQueen",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Alexander McQueen plastic sunglasses. Round frames. Gradient, brown lenses. Thin arms tapered at ends. Logo at temples. Imported.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "1d08b02aace2fdabfeafab79d90da7d4",
                            "created_at": "2018-05-01T19:10:35.518Z",
                            "updated_at": "2018-06-26T23:42:09.356Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cfe81393dd3d291162f7",
                            "schema": "product",
                            "score": 0.8912718391418457,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/16/11/161140d8ffb916ab75fc3354cf9beec0_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "718814514_White:718814514:White:https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "sku": "718814514",
                                "name": "Quay X Desi Don't At Me Sunglasses In White Smoke",
                                "url": "https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Quay Australia and Desi Perkins, We love a power couple, Chunky frames, Moulded nose pads for added comfort, Dark-tinted lenses, Tapered arms with curved temple tips for a secure fit, Presented in a branded sunglasses case, A safe place for your sunnies. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 65,
                                "currency": "USD"
                            },
                            "fingerprint": "483680d1a8f7812c7dca0f69a640e1d0",
                            "created_at": "2018-05-01T20:36:56.595Z",
                            "updated_at": "2018-06-23T01:25:18.119Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e4c2b77b36100f18605e",
                            "schema": "product",
                            "score": 0.8912650966644287,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/2d/70/2d70d521540bba5bd419509564563abb_best.jpg",
                                    "width": 720,
                                    "height": 715
                                }
                            ],
                            "data": {
                                "id": "720044806_white:720044806:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "sku": "720044806",
                                "name": "Super by Retrosuperfuture Women's Gals Brigitte 52MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Square frame with tinted lens and stud accents 52mm lens width; 22mm bridge width; 140mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 209,
                                "currency": "USD"
                            },
                            "fingerprint": "116e80778d12fb00c8a66034732cdfdf",
                            "created_at": "2018-05-01T22:05:54.117Z",
                            "updated_at": "2018-06-23T01:25:09.987Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cf751393dd3d2911212e",
                            "schema": "product",
                            "score": 0.8912115097045898,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5b/72/5b72ef6a825d866a0e39d77987bcd349_best.jpg",
                                    "width": 720,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "687048457_Matte clear:687048457:Matte clear:https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "sku": "687048457",
                                "name": "Oakley Oo9013 square-frame sunglasses",
                                "url": "https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "brand": "Oakley",
                                "color": "Matte clear",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Founded in 1975 by Jim Jannard, Oakley continues to provide dynamic and streamlined equipment, including a vast eyewear collection. Straight from the coveted Frogskin range, the Oo9013 sunglasses are cut to a square frame with the signature keyhole nose and showcase the American label's modern sensibilities with a rich two tone base and transparent rims.Oakley sunglassesSquare frame, brand lettering, keyhole nose, charcoal lenses, transparent edging, two tone 100% UV protectionFrame width: 13.5cm Fabric pouch",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 109,
                                "currency": "USD"
                            },
                            "fingerprint": "f7403ef1da4d27121ad8e0f15496325d",
                            "created_at": "2018-05-01T20:35:01.468Z",
                            "updated_at": "2018-06-25T17:34:19.798Z"
                        }
                    ]
                },
                {
                    "_type": "SearchResult",
                    "_id": "060f246f-8e7a-4583-8dac-9f8e84165cba",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 0.825286865234375,
                        "y": 259.9095153808594,
                        "width": 388.8166198730469,
                        "height": 536.0024719238281
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-33",
            "index": 33,
            "timestamp": 6600,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000034.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "e33caa03-058e-4e91-9d75-0ca751c6a12b",
                    "score": 0.9762849522673566,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 151.1312713623047,
                        "y": 91.87510681152344,
                        "width": 141.46229553222656,
                        "height": 69.55287170410156
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fe756efb8d3d2f8e281e",
                            "schema": "product",
                            "score": 0.9052010536193847,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/44/a3443255733002d9efc94df59b77be79_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679796721_White:679796721:White:https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "sku": "679796721",
                                "name": "Quay 20s Oversized Square Sunglasses In White",
                                "url": "https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Kylie Jenner, Oversized square frames, Moulded nose pads for added comfort, Dark tinted lenses, Branded arms with curved temple tips for a secure fit, Good UV protection. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 80,
                                "currency": "USD"
                            },
                            "fingerprint": "09ff363e17d04cb01af9d2d6c673c7e2",
                            "created_at": "2018-05-01T23:55:33.921Z",
                            "updated_at": "2018-06-23T01:24:21.222Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cc1f1393dd3d290f39c8",
                            "schema": "product",
                            "score": 0.9019440078735351,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/42/b5/42b5c0cbf4c22387219eaaaf97788c14_best.jpg",
                                    "width": 720,
                                    "height": 368
                                }
                            ],
                            "data": {
                                "id": "704685010_IVORY:704685010:IVORY:https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "sku": "704685010",
                                "name": "Saint Laurent Square Acetate Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "IVORY",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Saint Laurent acetate sunglasses. Square frames. Dimensions (in mm): 57-11-140. Thin, tapered arms. 100% UVA/UVB protection. Made in Italy.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 250,
                                "currency": "USD"
                            },
                            "fingerprint": "aed55f70d5281b3ee4bc83e9a83e3b48",
                            "created_at": "2018-05-01T20:20:47.778Z",
                            "updated_at": "2018-06-18T22:48:08.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0efb77b36100f161e01",
                            "schema": "product",
                            "score": 0.8987179946899414,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/15/35/1535cd937adbfe55b95dc478d4c05895_best.jpg",
                                    "width": 720,
                                    "height": 727
                                }
                            ],
                            "data": {
                                "id": "720021299_white:720021299:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "sku": "720021299",
                                "name": "Super by Retrosuperfuture Women's Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "04f62e9a4ae2f9447415128412e93a85",
                            "created_at": "2018-05-01T21:49:35.886Z",
                            "updated_at": "2018-06-23T01:25:18.179Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c3a96efb8d3d2f81425c",
                            "schema": "product",
                            "score": 0.8966365814208984,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/57/6a/576a8a7682f6aa2000e55b90fa797d89_best.jpg",
                                    "width": 264,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "660097270_White:660097270:White:https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "sku": "660097270",
                                "name": "Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "brand": "Super by Retrosuperfuture",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "3b0bc3185003eea9ae599bacb58a6732",
                            "created_at": "2018-05-01T19:44:41.008Z",
                            "updated_at": "2018-06-23T01:24:27.903Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c1bc6efb8d3d2f806363",
                            "schema": "product",
                            "score": 0.8945560836791993,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0c/b1/0cb174192aa75d63f1d740f027f665e5_best.jpg",
                                    "width": 336,
                                    "height": 335
                                }
                            ],
                            "data": {
                                "id": "713296552_Ivory:713296552:Ivory:https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "sku": "713296552",
                                "name": "Seafolly Tortola V2 51mm Polarized Cat Eye Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "brand": "Seafolly",
                                "color": "Ivory",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Bold, blocky frames add to the chic, futuristic look of cat-eye sunglasses fitted with glare-reducing polarized lenses. Style Name:Seafolly Tortola V2 51Mm Polarized Cat Eye Sunglasses. Style Number: 5499557. Available in stores.",
                                "category": "Women > Accessories |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 89,
                                "currency": "USD"
                            },
                            "fingerprint": "38fd2bc7af2879cdc0290c9f9b9c826c",
                            "created_at": "2018-05-01T19:36:28.331Z",
                            "updated_at": "2018-06-18T15:18:05.556Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ff2c6efb8d3d2f8e8a0c",
                            "schema": "product",
                            "score": 0.8936824131011962,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/97/49/97494e0c59a70aeee1f95eb5a4b4dcd3_best.jpg",
                                    "width": 720,
                                    "height": 402
                                }
                            ],
                            "data": {
                                "id": "613926118_WHITE:613926118:WHITE:https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "sku": "613926118",
                                "name": "Stella McCartney Square Plastic Sunglasses w/ Chain Arms, White",
                                "url": "https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "brand": "Stella McCartney",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Stella McCartney plastic sunglasses feature silvertone chain detail at arms. Opaque white square frames. Gradient smoke lenses. Wide, tapered arms. 100% UV protection.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "985424f7206b70676718c07c5c700fe5",
                            "created_at": "2018-05-01T23:58:36.338Z",
                            "updated_at": "2018-06-23T01:24:21.982Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8bbabb77b36100f150f5d",
                            "schema": "product",
                            "score": 0.89348952293396,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a8/01/a8014c544b60be07b756742f5b175d8a_best.jpg",
                                    "width": 720,
                                    "height": 321
                                }
                            ],
                            "data": {
                                "id": "642874259_WHITE:642874259:WHITE:https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "sku": "642874259",
                                "name": "Alexander McQueen Plastic Round Sunglasses, White",
                                "url": "https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "brand": "Alexander McQueen",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Alexander McQueen plastic sunglasses. Round frames. Gradient, brown lenses. Thin arms tapered at ends. Logo at temples. Imported.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "1d08b02aace2fdabfeafab79d90da7d4",
                            "created_at": "2018-05-01T19:10:35.518Z",
                            "updated_at": "2018-06-26T23:42:09.356Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cfe81393dd3d291162f7",
                            "schema": "product",
                            "score": 0.8912718391418457,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/16/11/161140d8ffb916ab75fc3354cf9beec0_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "718814514_White:718814514:White:https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "sku": "718814514",
                                "name": "Quay X Desi Don't At Me Sunglasses In White Smoke",
                                "url": "https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Quay Australia and Desi Perkins, We love a power couple, Chunky frames, Moulded nose pads for added comfort, Dark-tinted lenses, Tapered arms with curved temple tips for a secure fit, Presented in a branded sunglasses case, A safe place for your sunnies. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 65,
                                "currency": "USD"
                            },
                            "fingerprint": "483680d1a8f7812c7dca0f69a640e1d0",
                            "created_at": "2018-05-01T20:36:56.595Z",
                            "updated_at": "2018-06-23T01:25:18.119Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e4c2b77b36100f18605e",
                            "schema": "product",
                            "score": 0.8912650966644287,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/2d/70/2d70d521540bba5bd419509564563abb_best.jpg",
                                    "width": 720,
                                    "height": 715
                                }
                            ],
                            "data": {
                                "id": "720044806_white:720044806:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "sku": "720044806",
                                "name": "Super by Retrosuperfuture Women's Gals Brigitte 52MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Square frame with tinted lens and stud accents 52mm lens width; 22mm bridge width; 140mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 209,
                                "currency": "USD"
                            },
                            "fingerprint": "116e80778d12fb00c8a66034732cdfdf",
                            "created_at": "2018-05-01T22:05:54.117Z",
                            "updated_at": "2018-06-23T01:25:09.987Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cf751393dd3d2911212e",
                            "schema": "product",
                            "score": 0.8912115097045898,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5b/72/5b72ef6a825d866a0e39d77987bcd349_best.jpg",
                                    "width": 720,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "687048457_Matte clear:687048457:Matte clear:https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "sku": "687048457",
                                "name": "Oakley Oo9013 square-frame sunglasses",
                                "url": "https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "brand": "Oakley",
                                "color": "Matte clear",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Founded in 1975 by Jim Jannard, Oakley continues to provide dynamic and streamlined equipment, including a vast eyewear collection. Straight from the coveted Frogskin range, the Oo9013 sunglasses are cut to a square frame with the signature keyhole nose and showcase the American label's modern sensibilities with a rich two tone base and transparent rims.Oakley sunglassesSquare frame, brand lettering, keyhole nose, charcoal lenses, transparent edging, two tone 100% UV protectionFrame width: 13.5cm Fabric pouch",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 109,
                                "currency": "USD"
                            },
                            "fingerprint": "f7403ef1da4d27121ad8e0f15496325d",
                            "created_at": "2018-05-01T20:35:01.468Z",
                            "updated_at": "2018-06-25T17:34:19.798Z"
                        }
                    ]
                },
                {
                    "_type": "SearchResult",
                    "_id": "dcbbe439-8d72-4190-bbbf-9b69d07bbf38",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 0,
                        "y": 254.924072265625,
                        "width": 402.0552978515625,
                        "height": 544.075927734375
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-34",
            "index": 34,
            "timestamp": 6800,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000035.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "1b33d7e0-0abf-4826-bda4-631ab1d08fc9",
                    "score": 0.9762849522673566,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 155.92172241210938,
                        "y": 91.73953247070312,
                        "width": 138.56118774414062,
                        "height": 70.03434753417969
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fe756efb8d3d2f8e281e",
                            "schema": "product",
                            "score": 0.9052010536193847,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/44/a3443255733002d9efc94df59b77be79_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679796721_White:679796721:White:https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "sku": "679796721",
                                "name": "Quay 20s Oversized Square Sunglasses In White",
                                "url": "https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Kylie Jenner, Oversized square frames, Moulded nose pads for added comfort, Dark tinted lenses, Branded arms with curved temple tips for a secure fit, Good UV protection. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 80,
                                "currency": "USD"
                            },
                            "fingerprint": "09ff363e17d04cb01af9d2d6c673c7e2",
                            "created_at": "2018-05-01T23:55:33.921Z",
                            "updated_at": "2018-06-23T01:24:21.222Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cc1f1393dd3d290f39c8",
                            "schema": "product",
                            "score": 0.9019440078735351,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/42/b5/42b5c0cbf4c22387219eaaaf97788c14_best.jpg",
                                    "width": 720,
                                    "height": 368
                                }
                            ],
                            "data": {
                                "id": "704685010_IVORY:704685010:IVORY:https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "sku": "704685010",
                                "name": "Saint Laurent Square Acetate Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "IVORY",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Saint Laurent acetate sunglasses. Square frames. Dimensions (in mm): 57-11-140. Thin, tapered arms. 100% UVA/UVB protection. Made in Italy.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 250,
                                "currency": "USD"
                            },
                            "fingerprint": "aed55f70d5281b3ee4bc83e9a83e3b48",
                            "created_at": "2018-05-01T20:20:47.778Z",
                            "updated_at": "2018-06-18T22:48:08.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0efb77b36100f161e01",
                            "schema": "product",
                            "score": 0.8987179946899414,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/15/35/1535cd937adbfe55b95dc478d4c05895_best.jpg",
                                    "width": 720,
                                    "height": 727
                                }
                            ],
                            "data": {
                                "id": "720021299_white:720021299:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "sku": "720021299",
                                "name": "Super by Retrosuperfuture Women's Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "04f62e9a4ae2f9447415128412e93a85",
                            "created_at": "2018-05-01T21:49:35.886Z",
                            "updated_at": "2018-06-23T01:25:18.179Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c3a96efb8d3d2f81425c",
                            "schema": "product",
                            "score": 0.8966365814208984,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/57/6a/576a8a7682f6aa2000e55b90fa797d89_best.jpg",
                                    "width": 264,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "660097270_White:660097270:White:https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "sku": "660097270",
                                "name": "Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "brand": "Super by Retrosuperfuture",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "3b0bc3185003eea9ae599bacb58a6732",
                            "created_at": "2018-05-01T19:44:41.008Z",
                            "updated_at": "2018-06-23T01:24:27.903Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c1bc6efb8d3d2f806363",
                            "schema": "product",
                            "score": 0.8945560836791993,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0c/b1/0cb174192aa75d63f1d740f027f665e5_best.jpg",
                                    "width": 336,
                                    "height": 335
                                }
                            ],
                            "data": {
                                "id": "713296552_Ivory:713296552:Ivory:https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "sku": "713296552",
                                "name": "Seafolly Tortola V2 51mm Polarized Cat Eye Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "brand": "Seafolly",
                                "color": "Ivory",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Bold, blocky frames add to the chic, futuristic look of cat-eye sunglasses fitted with glare-reducing polarized lenses. Style Name:Seafolly Tortola V2 51Mm Polarized Cat Eye Sunglasses. Style Number: 5499557. Available in stores.",
                                "category": "Women > Accessories |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 89,
                                "currency": "USD"
                            },
                            "fingerprint": "38fd2bc7af2879cdc0290c9f9b9c826c",
                            "created_at": "2018-05-01T19:36:28.331Z",
                            "updated_at": "2018-06-18T15:18:05.556Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ff2c6efb8d3d2f8e8a0c",
                            "schema": "product",
                            "score": 0.8936824131011962,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/97/49/97494e0c59a70aeee1f95eb5a4b4dcd3_best.jpg",
                                    "width": 720,
                                    "height": 402
                                }
                            ],
                            "data": {
                                "id": "613926118_WHITE:613926118:WHITE:https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "sku": "613926118",
                                "name": "Stella McCartney Square Plastic Sunglasses w/ Chain Arms, White",
                                "url": "https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "brand": "Stella McCartney",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Stella McCartney plastic sunglasses feature silvertone chain detail at arms. Opaque white square frames. Gradient smoke lenses. Wide, tapered arms. 100% UV protection.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "985424f7206b70676718c07c5c700fe5",
                            "created_at": "2018-05-01T23:58:36.338Z",
                            "updated_at": "2018-06-23T01:24:21.982Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8bbabb77b36100f150f5d",
                            "schema": "product",
                            "score": 0.89348952293396,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a8/01/a8014c544b60be07b756742f5b175d8a_best.jpg",
                                    "width": 720,
                                    "height": 321
                                }
                            ],
                            "data": {
                                "id": "642874259_WHITE:642874259:WHITE:https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "sku": "642874259",
                                "name": "Alexander McQueen Plastic Round Sunglasses, White",
                                "url": "https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "brand": "Alexander McQueen",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Alexander McQueen plastic sunglasses. Round frames. Gradient, brown lenses. Thin arms tapered at ends. Logo at temples. Imported.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "1d08b02aace2fdabfeafab79d90da7d4",
                            "created_at": "2018-05-01T19:10:35.518Z",
                            "updated_at": "2018-06-26T23:42:09.356Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cfe81393dd3d291162f7",
                            "schema": "product",
                            "score": 0.8912718391418457,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/16/11/161140d8ffb916ab75fc3354cf9beec0_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "718814514_White:718814514:White:https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "sku": "718814514",
                                "name": "Quay X Desi Don't At Me Sunglasses In White Smoke",
                                "url": "https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Quay Australia and Desi Perkins, We love a power couple, Chunky frames, Moulded nose pads for added comfort, Dark-tinted lenses, Tapered arms with curved temple tips for a secure fit, Presented in a branded sunglasses case, A safe place for your sunnies. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 65,
                                "currency": "USD"
                            },
                            "fingerprint": "483680d1a8f7812c7dca0f69a640e1d0",
                            "created_at": "2018-05-01T20:36:56.595Z",
                            "updated_at": "2018-06-23T01:25:18.119Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e4c2b77b36100f18605e",
                            "schema": "product",
                            "score": 0.8912650966644287,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/2d/70/2d70d521540bba5bd419509564563abb_best.jpg",
                                    "width": 720,
                                    "height": 715
                                }
                            ],
                            "data": {
                                "id": "720044806_white:720044806:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "sku": "720044806",
                                "name": "Super by Retrosuperfuture Women's Gals Brigitte 52MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Square frame with tinted lens and stud accents 52mm lens width; 22mm bridge width; 140mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 209,
                                "currency": "USD"
                            },
                            "fingerprint": "116e80778d12fb00c8a66034732cdfdf",
                            "created_at": "2018-05-01T22:05:54.117Z",
                            "updated_at": "2018-06-23T01:25:09.987Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cf751393dd3d2911212e",
                            "schema": "product",
                            "score": 0.8912115097045898,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5b/72/5b72ef6a825d866a0e39d77987bcd349_best.jpg",
                                    "width": 720,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "687048457_Matte clear:687048457:Matte clear:https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "sku": "687048457",
                                "name": "Oakley Oo9013 square-frame sunglasses",
                                "url": "https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "brand": "Oakley",
                                "color": "Matte clear",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Founded in 1975 by Jim Jannard, Oakley continues to provide dynamic and streamlined equipment, including a vast eyewear collection. Straight from the coveted Frogskin range, the Oo9013 sunglasses are cut to a square frame with the signature keyhole nose and showcase the American label's modern sensibilities with a rich two tone base and transparent rims.Oakley sunglassesSquare frame, brand lettering, keyhole nose, charcoal lenses, transparent edging, two tone 100% UV protectionFrame width: 13.5cm Fabric pouch",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 109,
                                "currency": "USD"
                            },
                            "fingerprint": "f7403ef1da4d27121ad8e0f15496325d",
                            "created_at": "2018-05-01T20:35:01.468Z",
                            "updated_at": "2018-06-25T17:34:19.798Z"
                        }
                    ]
                },
                {
                    "_type": "SearchResult",
                    "_id": "d30215c2-b3e6-43bc-926f-491dc2533840",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 0,
                        "y": 247.49835205078125,
                        "width": 400.44287109375,
                        "height": 538.446533203125
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-35",
            "index": 35,
            "timestamp": 7000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000036.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "b0bd1573-d829-43d9-bfef-9d020a87f800",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 0,
                        "y": 247.926513671875,
                        "width": 398.83056640625,
                        "height": 529.541259765625
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                },
                {
                    "_type": "SearchResult",
                    "_id": "6389cf0a-c4e1-40a2-927d-910853583b49",
                    "score": 0.9762849522673566,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 160.3145751953125,
                        "y": 89.93336486816406,
                        "width": 138.09707641601562,
                        "height": 70.82215881347656
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fe756efb8d3d2f8e281e",
                            "schema": "product",
                            "score": 0.9052010536193847,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/44/a3443255733002d9efc94df59b77be79_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679796721_White:679796721:White:https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "sku": "679796721",
                                "name": "Quay 20s Oversized Square Sunglasses In White",
                                "url": "https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Kylie Jenner, Oversized square frames, Moulded nose pads for added comfort, Dark tinted lenses, Branded arms with curved temple tips for a secure fit, Good UV protection. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 80,
                                "currency": "USD"
                            },
                            "fingerprint": "09ff363e17d04cb01af9d2d6c673c7e2",
                            "created_at": "2018-05-01T23:55:33.921Z",
                            "updated_at": "2018-06-23T01:24:21.222Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cc1f1393dd3d290f39c8",
                            "schema": "product",
                            "score": 0.9019440078735351,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/42/b5/42b5c0cbf4c22387219eaaaf97788c14_best.jpg",
                                    "width": 720,
                                    "height": 368
                                }
                            ],
                            "data": {
                                "id": "704685010_IVORY:704685010:IVORY:https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "sku": "704685010",
                                "name": "Saint Laurent Square Acetate Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "IVORY",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Saint Laurent acetate sunglasses. Square frames. Dimensions (in mm): 57-11-140. Thin, tapered arms. 100% UVA/UVB protection. Made in Italy.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 250,
                                "currency": "USD"
                            },
                            "fingerprint": "aed55f70d5281b3ee4bc83e9a83e3b48",
                            "created_at": "2018-05-01T20:20:47.778Z",
                            "updated_at": "2018-06-18T22:48:08.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0efb77b36100f161e01",
                            "schema": "product",
                            "score": 0.8987179946899414,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/15/35/1535cd937adbfe55b95dc478d4c05895_best.jpg",
                                    "width": 720,
                                    "height": 727
                                }
                            ],
                            "data": {
                                "id": "720021299_white:720021299:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "sku": "720021299",
                                "name": "Super by Retrosuperfuture Women's Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "04f62e9a4ae2f9447415128412e93a85",
                            "created_at": "2018-05-01T21:49:35.886Z",
                            "updated_at": "2018-06-23T01:25:18.179Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c3a96efb8d3d2f81425c",
                            "schema": "product",
                            "score": 0.8966365814208984,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/57/6a/576a8a7682f6aa2000e55b90fa797d89_best.jpg",
                                    "width": 264,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "660097270_White:660097270:White:https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "sku": "660097270",
                                "name": "Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "brand": "Super by Retrosuperfuture",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "3b0bc3185003eea9ae599bacb58a6732",
                            "created_at": "2018-05-01T19:44:41.008Z",
                            "updated_at": "2018-06-23T01:24:27.903Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c1bc6efb8d3d2f806363",
                            "schema": "product",
                            "score": 0.8945560836791993,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0c/b1/0cb174192aa75d63f1d740f027f665e5_best.jpg",
                                    "width": 336,
                                    "height": 335
                                }
                            ],
                            "data": {
                                "id": "713296552_Ivory:713296552:Ivory:https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "sku": "713296552",
                                "name": "Seafolly Tortola V2 51mm Polarized Cat Eye Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "brand": "Seafolly",
                                "color": "Ivory",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Bold, blocky frames add to the chic, futuristic look of cat-eye sunglasses fitted with glare-reducing polarized lenses. Style Name:Seafolly Tortola V2 51Mm Polarized Cat Eye Sunglasses. Style Number: 5499557. Available in stores.",
                                "category": "Women > Accessories |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 89,
                                "currency": "USD"
                            },
                            "fingerprint": "38fd2bc7af2879cdc0290c9f9b9c826c",
                            "created_at": "2018-05-01T19:36:28.331Z",
                            "updated_at": "2018-06-18T15:18:05.556Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ff2c6efb8d3d2f8e8a0c",
                            "schema": "product",
                            "score": 0.8936824131011962,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/97/49/97494e0c59a70aeee1f95eb5a4b4dcd3_best.jpg",
                                    "width": 720,
                                    "height": 402
                                }
                            ],
                            "data": {
                                "id": "613926118_WHITE:613926118:WHITE:https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "sku": "613926118",
                                "name": "Stella McCartney Square Plastic Sunglasses w/ Chain Arms, White",
                                "url": "https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "brand": "Stella McCartney",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Stella McCartney plastic sunglasses feature silvertone chain detail at arms. Opaque white square frames. Gradient smoke lenses. Wide, tapered arms. 100% UV protection.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "985424f7206b70676718c07c5c700fe5",
                            "created_at": "2018-05-01T23:58:36.338Z",
                            "updated_at": "2018-06-23T01:24:21.982Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8bbabb77b36100f150f5d",
                            "schema": "product",
                            "score": 0.89348952293396,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a8/01/a8014c544b60be07b756742f5b175d8a_best.jpg",
                                    "width": 720,
                                    "height": 321
                                }
                            ],
                            "data": {
                                "id": "642874259_WHITE:642874259:WHITE:https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "sku": "642874259",
                                "name": "Alexander McQueen Plastic Round Sunglasses, White",
                                "url": "https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "brand": "Alexander McQueen",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Alexander McQueen plastic sunglasses. Round frames. Gradient, brown lenses. Thin arms tapered at ends. Logo at temples. Imported.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "1d08b02aace2fdabfeafab79d90da7d4",
                            "created_at": "2018-05-01T19:10:35.518Z",
                            "updated_at": "2018-06-26T23:42:09.356Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cfe81393dd3d291162f7",
                            "schema": "product",
                            "score": 0.8912718391418457,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/16/11/161140d8ffb916ab75fc3354cf9beec0_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "718814514_White:718814514:White:https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "sku": "718814514",
                                "name": "Quay X Desi Don't At Me Sunglasses In White Smoke",
                                "url": "https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Quay Australia and Desi Perkins, We love a power couple, Chunky frames, Moulded nose pads for added comfort, Dark-tinted lenses, Tapered arms with curved temple tips for a secure fit, Presented in a branded sunglasses case, A safe place for your sunnies. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 65,
                                "currency": "USD"
                            },
                            "fingerprint": "483680d1a8f7812c7dca0f69a640e1d0",
                            "created_at": "2018-05-01T20:36:56.595Z",
                            "updated_at": "2018-06-23T01:25:18.119Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e4c2b77b36100f18605e",
                            "schema": "product",
                            "score": 0.8912650966644287,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/2d/70/2d70d521540bba5bd419509564563abb_best.jpg",
                                    "width": 720,
                                    "height": 715
                                }
                            ],
                            "data": {
                                "id": "720044806_white:720044806:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "sku": "720044806",
                                "name": "Super by Retrosuperfuture Women's Gals Brigitte 52MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Square frame with tinted lens and stud accents 52mm lens width; 22mm bridge width; 140mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 209,
                                "currency": "USD"
                            },
                            "fingerprint": "116e80778d12fb00c8a66034732cdfdf",
                            "created_at": "2018-05-01T22:05:54.117Z",
                            "updated_at": "2018-06-23T01:25:09.987Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cf751393dd3d2911212e",
                            "schema": "product",
                            "score": 0.8912115097045898,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5b/72/5b72ef6a825d866a0e39d77987bcd349_best.jpg",
                                    "width": 720,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "687048457_Matte clear:687048457:Matte clear:https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "sku": "687048457",
                                "name": "Oakley Oo9013 square-frame sunglasses",
                                "url": "https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "brand": "Oakley",
                                "color": "Matte clear",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Founded in 1975 by Jim Jannard, Oakley continues to provide dynamic and streamlined equipment, including a vast eyewear collection. Straight from the coveted Frogskin range, the Oo9013 sunglasses are cut to a square frame with the signature keyhole nose and showcase the American label's modern sensibilities with a rich two tone base and transparent rims.Oakley sunglassesSquare frame, brand lettering, keyhole nose, charcoal lenses, transparent edging, two tone 100% UV protectionFrame width: 13.5cm Fabric pouch",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 109,
                                "currency": "USD"
                            },
                            "fingerprint": "f7403ef1da4d27121ad8e0f15496325d",
                            "created_at": "2018-05-01T20:35:01.468Z",
                            "updated_at": "2018-06-25T17:34:19.798Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-36",
            "index": 36,
            "timestamp": 7200,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000037.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "a0f6da74-0846-4e86-8d02-02567754fd0f",
                    "score": 0.9762849522673566,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 162.32235717773438,
                        "y": 89.6460952758789,
                        "width": 142.11697387695312,
                        "height": 70.39269256591797
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fe756efb8d3d2f8e281e",
                            "schema": "product",
                            "score": 0.9052010536193847,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/44/a3443255733002d9efc94df59b77be79_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679796721_White:679796721:White:https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "sku": "679796721",
                                "name": "Quay 20s Oversized Square Sunglasses In White",
                                "url": "https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Kylie Jenner, Oversized square frames, Moulded nose pads for added comfort, Dark tinted lenses, Branded arms with curved temple tips for a secure fit, Good UV protection. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 80,
                                "currency": "USD"
                            },
                            "fingerprint": "09ff363e17d04cb01af9d2d6c673c7e2",
                            "created_at": "2018-05-01T23:55:33.921Z",
                            "updated_at": "2018-06-23T01:24:21.222Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cc1f1393dd3d290f39c8",
                            "schema": "product",
                            "score": 0.9019440078735351,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/42/b5/42b5c0cbf4c22387219eaaaf97788c14_best.jpg",
                                    "width": 720,
                                    "height": 368
                                }
                            ],
                            "data": {
                                "id": "704685010_IVORY:704685010:IVORY:https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "sku": "704685010",
                                "name": "Saint Laurent Square Acetate Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "IVORY",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Saint Laurent acetate sunglasses. Square frames. Dimensions (in mm): 57-11-140. Thin, tapered arms. 100% UVA/UVB protection. Made in Italy.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 250,
                                "currency": "USD"
                            },
                            "fingerprint": "aed55f70d5281b3ee4bc83e9a83e3b48",
                            "created_at": "2018-05-01T20:20:47.778Z",
                            "updated_at": "2018-06-18T22:48:08.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0efb77b36100f161e01",
                            "schema": "product",
                            "score": 0.8987179946899414,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/15/35/1535cd937adbfe55b95dc478d4c05895_best.jpg",
                                    "width": 720,
                                    "height": 727
                                }
                            ],
                            "data": {
                                "id": "720021299_white:720021299:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "sku": "720021299",
                                "name": "Super by Retrosuperfuture Women's Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "04f62e9a4ae2f9447415128412e93a85",
                            "created_at": "2018-05-01T21:49:35.886Z",
                            "updated_at": "2018-06-23T01:25:18.179Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c3a96efb8d3d2f81425c",
                            "schema": "product",
                            "score": 0.8966365814208984,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/57/6a/576a8a7682f6aa2000e55b90fa797d89_best.jpg",
                                    "width": 264,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "660097270_White:660097270:White:https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "sku": "660097270",
                                "name": "Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "brand": "Super by Retrosuperfuture",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "3b0bc3185003eea9ae599bacb58a6732",
                            "created_at": "2018-05-01T19:44:41.008Z",
                            "updated_at": "2018-06-23T01:24:27.903Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c1bc6efb8d3d2f806363",
                            "schema": "product",
                            "score": 0.8945560836791993,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0c/b1/0cb174192aa75d63f1d740f027f665e5_best.jpg",
                                    "width": 336,
                                    "height": 335
                                }
                            ],
                            "data": {
                                "id": "713296552_Ivory:713296552:Ivory:https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "sku": "713296552",
                                "name": "Seafolly Tortola V2 51mm Polarized Cat Eye Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "brand": "Seafolly",
                                "color": "Ivory",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Bold, blocky frames add to the chic, futuristic look of cat-eye sunglasses fitted with glare-reducing polarized lenses. Style Name:Seafolly Tortola V2 51Mm Polarized Cat Eye Sunglasses. Style Number: 5499557. Available in stores.",
                                "category": "Women > Accessories |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 89,
                                "currency": "USD"
                            },
                            "fingerprint": "38fd2bc7af2879cdc0290c9f9b9c826c",
                            "created_at": "2018-05-01T19:36:28.331Z",
                            "updated_at": "2018-06-18T15:18:05.556Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ff2c6efb8d3d2f8e8a0c",
                            "schema": "product",
                            "score": 0.8936824131011962,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/97/49/97494e0c59a70aeee1f95eb5a4b4dcd3_best.jpg",
                                    "width": 720,
                                    "height": 402
                                }
                            ],
                            "data": {
                                "id": "613926118_WHITE:613926118:WHITE:https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "sku": "613926118",
                                "name": "Stella McCartney Square Plastic Sunglasses w/ Chain Arms, White",
                                "url": "https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "brand": "Stella McCartney",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Stella McCartney plastic sunglasses feature silvertone chain detail at arms. Opaque white square frames. Gradient smoke lenses. Wide, tapered arms. 100% UV protection.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "985424f7206b70676718c07c5c700fe5",
                            "created_at": "2018-05-01T23:58:36.338Z",
                            "updated_at": "2018-06-23T01:24:21.982Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8bbabb77b36100f150f5d",
                            "schema": "product",
                            "score": 0.89348952293396,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a8/01/a8014c544b60be07b756742f5b175d8a_best.jpg",
                                    "width": 720,
                                    "height": 321
                                }
                            ],
                            "data": {
                                "id": "642874259_WHITE:642874259:WHITE:https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "sku": "642874259",
                                "name": "Alexander McQueen Plastic Round Sunglasses, White",
                                "url": "https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "brand": "Alexander McQueen",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Alexander McQueen plastic sunglasses. Round frames. Gradient, brown lenses. Thin arms tapered at ends. Logo at temples. Imported.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "1d08b02aace2fdabfeafab79d90da7d4",
                            "created_at": "2018-05-01T19:10:35.518Z",
                            "updated_at": "2018-06-26T23:42:09.356Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cfe81393dd3d291162f7",
                            "schema": "product",
                            "score": 0.8912718391418457,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/16/11/161140d8ffb916ab75fc3354cf9beec0_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "718814514_White:718814514:White:https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "sku": "718814514",
                                "name": "Quay X Desi Don't At Me Sunglasses In White Smoke",
                                "url": "https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Quay Australia and Desi Perkins, We love a power couple, Chunky frames, Moulded nose pads for added comfort, Dark-tinted lenses, Tapered arms with curved temple tips for a secure fit, Presented in a branded sunglasses case, A safe place for your sunnies. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 65,
                                "currency": "USD"
                            },
                            "fingerprint": "483680d1a8f7812c7dca0f69a640e1d0",
                            "created_at": "2018-05-01T20:36:56.595Z",
                            "updated_at": "2018-06-23T01:25:18.119Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e4c2b77b36100f18605e",
                            "schema": "product",
                            "score": 0.8912650966644287,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/2d/70/2d70d521540bba5bd419509564563abb_best.jpg",
                                    "width": 720,
                                    "height": 715
                                }
                            ],
                            "data": {
                                "id": "720044806_white:720044806:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "sku": "720044806",
                                "name": "Super by Retrosuperfuture Women's Gals Brigitte 52MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Square frame with tinted lens and stud accents 52mm lens width; 22mm bridge width; 140mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 209,
                                "currency": "USD"
                            },
                            "fingerprint": "116e80778d12fb00c8a66034732cdfdf",
                            "created_at": "2018-05-01T22:05:54.117Z",
                            "updated_at": "2018-06-23T01:25:09.987Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cf751393dd3d2911212e",
                            "schema": "product",
                            "score": 0.8912115097045898,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5b/72/5b72ef6a825d866a0e39d77987bcd349_best.jpg",
                                    "width": 720,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "687048457_Matte clear:687048457:Matte clear:https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "sku": "687048457",
                                "name": "Oakley Oo9013 square-frame sunglasses",
                                "url": "https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "brand": "Oakley",
                                "color": "Matte clear",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Founded in 1975 by Jim Jannard, Oakley continues to provide dynamic and streamlined equipment, including a vast eyewear collection. Straight from the coveted Frogskin range, the Oo9013 sunglasses are cut to a square frame with the signature keyhole nose and showcase the American label's modern sensibilities with a rich two tone base and transparent rims.Oakley sunglassesSquare frame, brand lettering, keyhole nose, charcoal lenses, transparent edging, two tone 100% UV protectionFrame width: 13.5cm Fabric pouch",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 109,
                                "currency": "USD"
                            },
                            "fingerprint": "f7403ef1da4d27121ad8e0f15496325d",
                            "created_at": "2018-05-01T20:35:01.468Z",
                            "updated_at": "2018-06-25T17:34:19.798Z"
                        }
                    ]
                },
                {
                    "_type": "SearchResult",
                    "_id": "21a6bc06-fa06-487b-b242-cdaa5e553465",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 0,
                        "y": 261.0885314941406,
                        "width": 412.3104553222656,
                        "height": 537.9114685058594
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-37",
            "index": 37,
            "timestamp": 7400,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000038.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "401bb5f1-142b-4c5d-a398-ea6b4c0e0586",
                    "score": 0.9762849522673566,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 164.24424743652344,
                        "y": 88.81584167480469,
                        "width": 145.1995391845703,
                        "height": 71.19248962402344
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fe756efb8d3d2f8e281e",
                            "schema": "product",
                            "score": 0.9052010536193847,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/44/a3443255733002d9efc94df59b77be79_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679796721_White:679796721:White:https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "sku": "679796721",
                                "name": "Quay 20s Oversized Square Sunglasses In White",
                                "url": "https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Kylie Jenner, Oversized square frames, Moulded nose pads for added comfort, Dark tinted lenses, Branded arms with curved temple tips for a secure fit, Good UV protection. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 80,
                                "currency": "USD"
                            },
                            "fingerprint": "09ff363e17d04cb01af9d2d6c673c7e2",
                            "created_at": "2018-05-01T23:55:33.921Z",
                            "updated_at": "2018-06-23T01:24:21.222Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cc1f1393dd3d290f39c8",
                            "schema": "product",
                            "score": 0.9019440078735351,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/42/b5/42b5c0cbf4c22387219eaaaf97788c14_best.jpg",
                                    "width": 720,
                                    "height": 368
                                }
                            ],
                            "data": {
                                "id": "704685010_IVORY:704685010:IVORY:https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "sku": "704685010",
                                "name": "Saint Laurent Square Acetate Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "IVORY",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Saint Laurent acetate sunglasses. Square frames. Dimensions (in mm): 57-11-140. Thin, tapered arms. 100% UVA/UVB protection. Made in Italy.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 250,
                                "currency": "USD"
                            },
                            "fingerprint": "aed55f70d5281b3ee4bc83e9a83e3b48",
                            "created_at": "2018-05-01T20:20:47.778Z",
                            "updated_at": "2018-06-18T22:48:08.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0efb77b36100f161e01",
                            "schema": "product",
                            "score": 0.8987179946899414,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/15/35/1535cd937adbfe55b95dc478d4c05895_best.jpg",
                                    "width": 720,
                                    "height": 727
                                }
                            ],
                            "data": {
                                "id": "720021299_white:720021299:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "sku": "720021299",
                                "name": "Super by Retrosuperfuture Women's Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "04f62e9a4ae2f9447415128412e93a85",
                            "created_at": "2018-05-01T21:49:35.886Z",
                            "updated_at": "2018-06-23T01:25:18.179Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c3a96efb8d3d2f81425c",
                            "schema": "product",
                            "score": 0.8966365814208984,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/57/6a/576a8a7682f6aa2000e55b90fa797d89_best.jpg",
                                    "width": 264,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "660097270_White:660097270:White:https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "sku": "660097270",
                                "name": "Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "brand": "Super by Retrosuperfuture",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "3b0bc3185003eea9ae599bacb58a6732",
                            "created_at": "2018-05-01T19:44:41.008Z",
                            "updated_at": "2018-06-23T01:24:27.903Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c1bc6efb8d3d2f806363",
                            "schema": "product",
                            "score": 0.8945560836791993,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0c/b1/0cb174192aa75d63f1d740f027f665e5_best.jpg",
                                    "width": 336,
                                    "height": 335
                                }
                            ],
                            "data": {
                                "id": "713296552_Ivory:713296552:Ivory:https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "sku": "713296552",
                                "name": "Seafolly Tortola V2 51mm Polarized Cat Eye Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "brand": "Seafolly",
                                "color": "Ivory",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Bold, blocky frames add to the chic, futuristic look of cat-eye sunglasses fitted with glare-reducing polarized lenses. Style Name:Seafolly Tortola V2 51Mm Polarized Cat Eye Sunglasses. Style Number: 5499557. Available in stores.",
                                "category": "Women > Accessories |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 89,
                                "currency": "USD"
                            },
                            "fingerprint": "38fd2bc7af2879cdc0290c9f9b9c826c",
                            "created_at": "2018-05-01T19:36:28.331Z",
                            "updated_at": "2018-06-18T15:18:05.556Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ff2c6efb8d3d2f8e8a0c",
                            "schema": "product",
                            "score": 0.8936824131011962,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/97/49/97494e0c59a70aeee1f95eb5a4b4dcd3_best.jpg",
                                    "width": 720,
                                    "height": 402
                                }
                            ],
                            "data": {
                                "id": "613926118_WHITE:613926118:WHITE:https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "sku": "613926118",
                                "name": "Stella McCartney Square Plastic Sunglasses w/ Chain Arms, White",
                                "url": "https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "brand": "Stella McCartney",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Stella McCartney plastic sunglasses feature silvertone chain detail at arms. Opaque white square frames. Gradient smoke lenses. Wide, tapered arms. 100% UV protection.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "985424f7206b70676718c07c5c700fe5",
                            "created_at": "2018-05-01T23:58:36.338Z",
                            "updated_at": "2018-06-23T01:24:21.982Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8bbabb77b36100f150f5d",
                            "schema": "product",
                            "score": 0.89348952293396,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a8/01/a8014c544b60be07b756742f5b175d8a_best.jpg",
                                    "width": 720,
                                    "height": 321
                                }
                            ],
                            "data": {
                                "id": "642874259_WHITE:642874259:WHITE:https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "sku": "642874259",
                                "name": "Alexander McQueen Plastic Round Sunglasses, White",
                                "url": "https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "brand": "Alexander McQueen",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Alexander McQueen plastic sunglasses. Round frames. Gradient, brown lenses. Thin arms tapered at ends. Logo at temples. Imported.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "1d08b02aace2fdabfeafab79d90da7d4",
                            "created_at": "2018-05-01T19:10:35.518Z",
                            "updated_at": "2018-06-26T23:42:09.356Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cfe81393dd3d291162f7",
                            "schema": "product",
                            "score": 0.8912718391418457,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/16/11/161140d8ffb916ab75fc3354cf9beec0_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "718814514_White:718814514:White:https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "sku": "718814514",
                                "name": "Quay X Desi Don't At Me Sunglasses In White Smoke",
                                "url": "https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Quay Australia and Desi Perkins, We love a power couple, Chunky frames, Moulded nose pads for added comfort, Dark-tinted lenses, Tapered arms with curved temple tips for a secure fit, Presented in a branded sunglasses case, A safe place for your sunnies. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 65,
                                "currency": "USD"
                            },
                            "fingerprint": "483680d1a8f7812c7dca0f69a640e1d0",
                            "created_at": "2018-05-01T20:36:56.595Z",
                            "updated_at": "2018-06-23T01:25:18.119Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e4c2b77b36100f18605e",
                            "schema": "product",
                            "score": 0.8912650966644287,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/2d/70/2d70d521540bba5bd419509564563abb_best.jpg",
                                    "width": 720,
                                    "height": 715
                                }
                            ],
                            "data": {
                                "id": "720044806_white:720044806:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "sku": "720044806",
                                "name": "Super by Retrosuperfuture Women's Gals Brigitte 52MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Square frame with tinted lens and stud accents 52mm lens width; 22mm bridge width; 140mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 209,
                                "currency": "USD"
                            },
                            "fingerprint": "116e80778d12fb00c8a66034732cdfdf",
                            "created_at": "2018-05-01T22:05:54.117Z",
                            "updated_at": "2018-06-23T01:25:09.987Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cf751393dd3d2911212e",
                            "schema": "product",
                            "score": 0.8912115097045898,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5b/72/5b72ef6a825d866a0e39d77987bcd349_best.jpg",
                                    "width": 720,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "687048457_Matte clear:687048457:Matte clear:https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "sku": "687048457",
                                "name": "Oakley Oo9013 square-frame sunglasses",
                                "url": "https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "brand": "Oakley",
                                "color": "Matte clear",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Founded in 1975 by Jim Jannard, Oakley continues to provide dynamic and streamlined equipment, including a vast eyewear collection. Straight from the coveted Frogskin range, the Oo9013 sunglasses are cut to a square frame with the signature keyhole nose and showcase the American label's modern sensibilities with a rich two tone base and transparent rims.Oakley sunglassesSquare frame, brand lettering, keyhole nose, charcoal lenses, transparent edging, two tone 100% UV protectionFrame width: 13.5cm Fabric pouch",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 109,
                                "currency": "USD"
                            },
                            "fingerprint": "f7403ef1da4d27121ad8e0f15496325d",
                            "created_at": "2018-05-01T20:35:01.468Z",
                            "updated_at": "2018-06-25T17:34:19.798Z"
                        }
                    ]
                },
                {
                    "_type": "SearchResult",
                    "_id": "a6835e86-73dd-442e-b41a-11778418f114",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 0,
                        "y": 249.38897705078125,
                        "width": 429.5576171875,
                        "height": 548.8988037109375
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-38",
            "index": 38,
            "timestamp": 7600,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000039.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "e9907851-3449-4c7d-810a-e63d8ae72386",
                    "score": 0.9762849522673566,
                    "category": {
                        "_type": "Category",
                        "_id": "glasses",
                        "name": "glasses"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 166.7034912109375,
                        "y": 88.05882263183594,
                        "width": 144.557373046875,
                        "height": 71.2357177734375
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8fe756efb8d3d2f8e281e",
                            "schema": "product",
                            "score": 0.9052010536193847,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/44/a3443255733002d9efc94df59b77be79_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "679796721_White:679796721:White:https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "sku": "679796721",
                                "name": "Quay 20s Oversized Square Sunglasses In White",
                                "url": "https://www.shopstyle.com/g/men/quay/20s-oversized-square-sunglasses-in-white",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Kylie Jenner, Oversized square frames, Moulded nose pads for added comfort, Dark tinted lenses, Branded arms with curved temple tips for a secure fit, Good UV protection. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 80,
                                "currency": "USD"
                            },
                            "fingerprint": "09ff363e17d04cb01af9d2d6c673c7e2",
                            "created_at": "2018-05-01T23:55:33.921Z",
                            "updated_at": "2018-06-23T01:24:21.222Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cc1f1393dd3d290f39c8",
                            "schema": "product",
                            "score": 0.9019440078735351,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/42/b5/42b5c0cbf4c22387219eaaaf97788c14_best.jpg",
                                    "width": 720,
                                    "height": 368
                                }
                            ],
                            "data": {
                                "id": "704685010_IVORY:704685010:IVORY:https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "sku": "704685010",
                                "name": "Saint Laurent Square Acetate Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/saint-laurent/square-acetate-sunglasses",
                                "brand": "Saint Laurent",
                                "color": "IVORY",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Saint Laurent acetate sunglasses. Square frames. Dimensions (in mm): 57-11-140. Thin, tapered arms. 100% UVA/UVB protection. Made in Italy.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 250,
                                "currency": "USD"
                            },
                            "fingerprint": "aed55f70d5281b3ee4bc83e9a83e3b48",
                            "created_at": "2018-05-01T20:20:47.778Z",
                            "updated_at": "2018-06-18T22:48:08.502Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e0efb77b36100f161e01",
                            "schema": "product",
                            "score": 0.8987179946899414,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/15/35/1535cd937adbfe55b95dc478d4c05895_best.jpg",
                                    "width": 720,
                                    "height": 727
                                }
                            ],
                            "data": {
                                "id": "720021299_white:720021299:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "sku": "720021299",
                                "name": "Super by Retrosuperfuture Women's Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-mona-pool-54mm-round-sunglasses/720021299",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "04f62e9a4ae2f9447415128412e93a85",
                            "created_at": "2018-05-01T21:49:35.886Z",
                            "updated_at": "2018-06-23T01:25:18.179Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c3a96efb8d3d2f81425c",
                            "schema": "product",
                            "score": 0.8966365814208984,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/57/6a/576a8a7682f6aa2000e55b90fa797d89_best.jpg",
                                    "width": 264,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "660097270_White:660097270:White:https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "sku": "660097270",
                                "name": "Mona Pool 54MM Round Sunglasses",
                                "url": "https://www.shopstyle.com/p/mona-pool-54mm-round-sunglasses/660097270",
                                "brand": "Super by Retrosuperfuture",
                                "color": "White",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Marbled round frame featuring solid tinted lens 54mm lens width; 21mm bridge width; 145mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 229,
                                "currency": "USD"
                            },
                            "fingerprint": "3b0bc3185003eea9ae599bacb58a6732",
                            "created_at": "2018-05-01T19:44:41.008Z",
                            "updated_at": "2018-06-23T01:24:27.903Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8c1bc6efb8d3d2f806363",
                            "schema": "product",
                            "score": 0.8945560836791993,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/0c/b1/0cb174192aa75d63f1d740f027f665e5_best.jpg",
                                    "width": 336,
                                    "height": 335
                                }
                            ],
                            "data": {
                                "id": "713296552_Ivory:713296552:Ivory:https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "sku": "713296552",
                                "name": "Seafolly Tortola V2 51mm Polarized Cat Eye Sunglasses",
                                "url": "https://www.shopstyle.com/g/women/seafolly/tortola-v2-51mm-polarized-cat-eye-sunglasses",
                                "brand": "Seafolly",
                                "color": "Ivory",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Bold, blocky frames add to the chic, futuristic look of cat-eye sunglasses fitted with glare-reducing polarized lenses. Style Name:Seafolly Tortola V2 51Mm Polarized Cat Eye Sunglasses. Style Number: 5499557. Available in stores.",
                                "category": "Women > Accessories |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 89,
                                "currency": "USD"
                            },
                            "fingerprint": "38fd2bc7af2879cdc0290c9f9b9c826c",
                            "created_at": "2018-05-01T19:36:28.331Z",
                            "updated_at": "2018-06-18T15:18:05.556Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ff2c6efb8d3d2f8e8a0c",
                            "schema": "product",
                            "score": 0.8936824131011962,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/97/49/97494e0c59a70aeee1f95eb5a4b4dcd3_best.jpg",
                                    "width": 720,
                                    "height": 402
                                }
                            ],
                            "data": {
                                "id": "613926118_WHITE:613926118:WHITE:https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "sku": "613926118",
                                "name": "Stella McCartney Square Plastic Sunglasses w/ Chain Arms, White",
                                "url": "https://www.shopstyle.com/g/women/stella-mccartney/square-plastic-sunglasses-w-chain-arms-white",
                                "brand": "Stella McCartney",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Stella McCartney plastic sunglasses feature silvertone chain detail at arms. Opaque white square frames. Gradient smoke lenses. Wide, tapered arms. 100% UV protection.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "985424f7206b70676718c07c5c700fe5",
                            "created_at": "2018-05-01T23:58:36.338Z",
                            "updated_at": "2018-06-23T01:24:21.982Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8bbabb77b36100f150f5d",
                            "schema": "product",
                            "score": 0.89348952293396,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a8/01/a8014c544b60be07b756742f5b175d8a_best.jpg",
                                    "width": 720,
                                    "height": 321
                                }
                            ],
                            "data": {
                                "id": "642874259_WHITE:642874259:WHITE:https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "sku": "642874259",
                                "name": "Alexander McQueen Plastic Round Sunglasses, White",
                                "url": "https://www.shopstyle.com/g/women/alexander-mcqueen/plastic-round-sunglasses-white",
                                "brand": "Alexander McQueen",
                                "color": "WHITE",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Alexander McQueen plastic sunglasses. Round frames. Gradient, brown lenses. Thin arms tapered at ends. Logo at temples. Imported.",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 169,
                                "currency": "USD"
                            },
                            "fingerprint": "1d08b02aace2fdabfeafab79d90da7d4",
                            "created_at": "2018-05-01T19:10:35.518Z",
                            "updated_at": "2018-06-26T23:42:09.356Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cfe81393dd3d291162f7",
                            "schema": "product",
                            "score": 0.8912718391418457,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/16/11/161140d8ffb916ab75fc3354cf9beec0_best.jpg",
                                    "width": 705,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "718814514_White:718814514:White:https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "sku": "718814514",
                                "name": "Quay X Desi Don't At Me Sunglasses In White Smoke",
                                "url": "https://www.shopstyle.com/g/men/quay/x-desi-dont-at-me-sunglasses-in-white-smoke",
                                "brand": "Quay",
                                "color": "White",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Sunglasses by Quay Eyeware, Designed in collaboration with Quay Australia and Desi Perkins, We love a power couple, Chunky frames, Moulded nose pads for added comfort, Dark-tinted lenses, Tapered arms with curved temple tips for a secure fit, Presented in a branded sunglasses case, A safe place for your sunnies. Based in Australia, Quay Eyeware was founded by Allen and Linda Hammond in 2004 as a sunglasses label dedicated to the recreation of vintage styles. Specialising in '80s-inspired shades, expect slick visor, flatbrow and rounded designs, with dark tinted lenses, retro-cool neon frames and geometric print detail.",
                                "category": "Men > Accessories > Eyewear > Sunglasses |  | ",
                                "size": "One Size",
                                "age_group": "adult",
                                "stock": true,
                                "price": 65,
                                "currency": "USD"
                            },
                            "fingerprint": "483680d1a8f7812c7dca0f69a640e1d0",
                            "created_at": "2018-05-01T20:36:56.595Z",
                            "updated_at": "2018-06-23T01:25:18.119Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8e4c2b77b36100f18605e",
                            "schema": "product",
                            "score": 0.8912650966644287,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/2d/70/2d70d521540bba5bd419509564563abb_best.jpg",
                                    "width": 720,
                                    "height": 715
                                }
                            ],
                            "data": {
                                "id": "720044806_white:720044806:white:https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "sku": "720044806",
                                "name": "Super by Retrosuperfuture Women's Gals Brigitte 52MM Square Sunglasses",
                                "url": "https://www.shopstyle.com/p/super-by-retrosuperfuture-womens-gals-brigitte-52mm-square-sunglasses/720044806",
                                "brand": "Super by Retrosuperfuture",
                                "color": "white",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Square frame with tinted lens and stud accents 52mm lens width; 22mm bridge width; 140mm temple length Solid tinted lens Saddle nose bridge Case and cleaning cloth included Metal/plastic Made in Italy",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 209,
                                "currency": "USD"
                            },
                            "fingerprint": "116e80778d12fb00c8a66034732cdfdf",
                            "created_at": "2018-05-01T22:05:54.117Z",
                            "updated_at": "2018-06-23T01:25:09.987Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cf751393dd3d2911212e",
                            "schema": "product",
                            "score": 0.8912115097045898,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/5b/72/5b72ef6a825d866a0e39d77987bcd349_best.jpg",
                                    "width": 720,
                                    "height": 259
                                }
                            ],
                            "data": {
                                "id": "687048457_Matte clear:687048457:Matte clear:https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "sku": "687048457",
                                "name": "Oakley Oo9013 square-frame sunglasses",
                                "url": "https://www.shopstyle.com/g/women/oakley/oo9013-square-frame-sunglasses",
                                "brand": "Oakley",
                                "color": "Matte clear",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Founded in 1975 by Jim Jannard, Oakley continues to provide dynamic and streamlined equipment, including a vast eyewear collection. Straight from the coveted Frogskin range, the Oo9013 sunglasses are cut to a square frame with the signature keyhole nose and showcase the American label's modern sensibilities with a rich two tone base and transparent rims.Oakley sunglassesSquare frame, brand lettering, keyhole nose, charcoal lenses, transparent edging, two tone 100% UV protectionFrame width: 13.5cm Fabric pouch",
                                "category": "Women > Accessories > Eyewear > Sunglasses |  | ",
                                "age_group": "adult",
                                "stock": true,
                                "price": 109,
                                "currency": "USD"
                            },
                            "fingerprint": "f7403ef1da4d27121ad8e0f15496325d",
                            "created_at": "2018-05-01T20:35:01.468Z",
                            "updated_at": "2018-06-25T17:34:19.798Z"
                        }
                    ]
                },
                {
                    "_type": "SearchResult",
                    "_id": "ea29d55f-3ec7-4e34-9ed3-031be56062c8",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 0,
                        "y": 248.38888549804688,
                        "width": 431.49884033203125,
                        "height": 550.6111145019531
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-39",
            "index": 39,
            "timestamp": 7800,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000040.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-40",
            "index": 40,
            "timestamp": 8000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000041.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-41",
            "index": 41,
            "timestamp": 8200,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000042.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-42",
            "index": 42,
            "timestamp": 8400,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000043.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-43",
            "index": 43,
            "timestamp": 8600,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000044.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-44",
            "index": 44,
            "timestamp": 8800,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000045.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-45",
            "index": 45,
            "timestamp": 9000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000046.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-46",
            "index": 46,
            "timestamp": 9200,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000047.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-47",
            "index": 47,
            "timestamp": 9400,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000048.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-48",
            "index": 48,
            "timestamp": 9600,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000049.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-49",
            "index": 49,
            "timestamp": 9800,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000050.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-50",
            "index": 50,
            "timestamp": 10000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000051.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-51",
            "index": 51,
            "timestamp": 10200,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000052.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "3089c60f-9078-4592-8e16-6ca584e8673c",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 1.4641876220703125,
                        "y": 203.10311889648438,
                        "width": 342.0831756591797,
                        "height": 513.4745178222656
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-52",
            "index": 52,
            "timestamp": 10400,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000053.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "eb7da237-45ef-4a46-989d-84476491aa3f",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 4.376861572265625,
                        "y": 206.08840942382812,
                        "width": 347.8289489746094,
                        "height": 506.2951354980469
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-53",
            "index": 53,
            "timestamp": 10600,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000054.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "b39eaebd-4524-42bd-97c9-fc8247903b27",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 4.678680419921875,
                        "y": 206.2169189453125,
                        "width": 351.7445068359375,
                        "height": 511.4404296875
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-54",
            "index": 54,
            "timestamp": 10800,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000055.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-55",
            "index": 55,
            "timestamp": 11000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000056.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-56",
            "index": 56,
            "timestamp": 11200,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000057.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-57",
            "index": 57,
            "timestamp": 11400,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000058.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-58",
            "index": 58,
            "timestamp": 11600,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000059.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "b99322f9-0de7-4d64-8ce7-e29528e34a67",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 89.09927368164062,
                        "y": 127.25717163085938,
                        "width": 233.30209350585938,
                        "height": 306.88751220703125
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-59",
            "index": 59,
            "timestamp": 11800,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000060.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "960f6f90-b1fc-439e-bba8-a9f4efd2f590",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 107.864990234375,
                        "y": 172.0580291748047,
                        "width": 197.39004516601562,
                        "height": 269.26063537597656
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-60",
            "index": 60,
            "timestamp": 12000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000061.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "fe534330-52fd-43a8-88fe-a7d2de1cb618",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 107.89710235595703,
                        "y": 172.40338134765625,
                        "width": 197.9748764038086,
                        "height": 268.0802001953125
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-61",
            "index": 61,
            "timestamp": 12200,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000062.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "77a49f84-39d3-44a4-89fa-fff485e68d15",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 108.11241149902344,
                        "y": 171.84275817871094,
                        "width": 198.38954162597656,
                        "height": 268.81776428222656
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-62",
            "index": 62,
            "timestamp": 12400,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000063.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "5c03aa74-6fc9-4950-8cf8-c6ed357bfb8a",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 109.62472534179688,
                        "y": 164.9254150390625,
                        "width": 195.58285522460938,
                        "height": 277.3858642578125
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-63",
            "index": 63,
            "timestamp": 12600,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000064.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "92004c90-c73e-4bad-9163-fe8963052f12",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 110.84778594970703,
                        "y": 164.97776794433594,
                        "width": 196.09944915771484,
                        "height": 276.41419982910156
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-64",
            "index": 64,
            "timestamp": 12800,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000065.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "dcaa7b9b-ac27-4ffe-8874-e41257c239c6",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 111.98617553710938,
                        "y": 172.53521728515625,
                        "width": 197.17413330078125,
                        "height": 263.72601318359375
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-65",
            "index": 65,
            "timestamp": 13000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000066.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "0dc1262e-2c29-412c-971e-5d8c3af1ed78",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 112.0070571899414,
                        "y": 172.361572265625,
                        "width": 198.7431869506836,
                        "height": 267.4534912109375
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-66",
            "index": 66,
            "timestamp": 13200,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000067.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "d5e4224d-20b6-4f25-8fc3-ab0782197293",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 115.36922454833984,
                        "y": 172.07424926757812,
                        "width": 199.19464874267578,
                        "height": 274.89208984375
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-67",
            "index": 67,
            "timestamp": 13400,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000068.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "34440ec3-4fb7-46fa-90db-8727512499bd",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 114.81013488769531,
                        "y": 170.1726837158203,
                        "width": 201.1716766357422,
                        "height": 281.26585388183594
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-68",
            "index": 68,
            "timestamp": 13600,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000069.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "20ff1962-8e8f-40be-afd0-39b5449214b9",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 111.71623229980469,
                        "y": 163.6623077392578,
                        "width": 201.4230499267578,
                        "height": 291.14463806152344
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-69",
            "index": 69,
            "timestamp": 13800,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000070.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "0c3b8f09-8773-45a5-b287-fca0bda76030",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 115.82730865478516,
                        "y": 170.16311645507812,
                        "width": 202.2790756225586,
                        "height": 288.46893310546875
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-70",
            "index": 70,
            "timestamp": 14000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000071.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-71",
            "index": 71,
            "timestamp": 14200,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000072.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-72",
            "index": 72,
            "timestamp": 14400,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000073.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-73",
            "index": 73,
            "timestamp": 14600,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000074.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-74",
            "index": 74,
            "timestamp": 14800,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000075.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "2115d08c-a9ce-41e9-b4b6-1e56d871f4aa",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 85.65214538574219,
                        "y": 394.8828125,
                        "width": 272.8250274658203,
                        "height": 235.8450927734375
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-75",
            "index": 75,
            "timestamp": 15000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000076.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "381ab803-c344-43e2-a6b6-82cfef4bbbce",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 85.521728515625,
                        "y": 394.61016845703125,
                        "width": 272.70806884765625,
                        "height": 236.5867919921875
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-76",
            "index": 76,
            "timestamp": 15200,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000077.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "6cef77fa-5b9e-4fb8-8754-1511deceb7de",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 85.86282348632812,
                        "y": 395.6938171386719,
                        "width": 272.7335510253906,
                        "height": 235.78274536132812
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-77",
            "index": 77,
            "timestamp": 15400,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000078.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "c8683a91-5977-4100-b9a4-8c8e9505e31c",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 85.25741577148438,
                        "y": 397.43841552734375,
                        "width": 273.6850280761719,
                        "height": 235.256103515625
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-78",
            "index": 78,
            "timestamp": 15600,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000079.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "79e03a9d-f462-42ac-b2d7-97cb6f5dca25",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 80.23211669921875,
                        "y": 394.0393981933594,
                        "width": 280.468994140625,
                        "height": 239.10482788085938
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-79",
            "index": 79,
            "timestamp": 15800,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000080.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "7f73ed28-c030-4718-9eb0-3431a0f779bf",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 76.74771118164062,
                        "y": 403.52349853515625,
                        "width": 288.07958984375,
                        "height": 225.553955078125
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-80",
            "index": 80,
            "timestamp": 16000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000081.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "07209eb0-4b74-4fe1-8dde-d49a78b657d9",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 80.7041015625,
                        "y": 390.6788330078125,
                        "width": 280.1731262207031,
                        "height": 251.1312255859375
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-81",
            "index": 81,
            "timestamp": 16200,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000082.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "7a631a9b-bde8-4e47-b94d-99c1ba4950ea",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 83.73085021972656,
                        "y": 403.9653625488281,
                        "width": 276.64073181152344,
                        "height": 234.51052856445312
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-82",
            "index": 82,
            "timestamp": 16400,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000083.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "f7d47818-f3e9-4259-93dc-f70d1ab3cea0",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 80.23699951171875,
                        "y": 393.15167236328125,
                        "width": 281.79388427734375,
                        "height": 255.1285400390625
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-83",
            "index": 83,
            "timestamp": 16600,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000084.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "fdcff671-83e9-499a-919b-66563a4212db",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 82.75474548339844,
                        "y": 404.36224365234375,
                        "width": 277.52333068847656,
                        "height": 239.69482421875
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-84",
            "index": 84,
            "timestamp": 16800,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000085.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "ed862147-4279-4f48-888a-24d2d22e672c",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 79.00843811035156,
                        "y": 400.9175109863281,
                        "width": 286.01658630371094,
                        "height": 250.53170776367188
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-85",
            "index": 85,
            "timestamp": 17000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000086.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "4a9f94c2-b7d9-4849-b4a8-2f150892d2cf",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 73.6221923828125,
                        "y": 397.07940673828125,
                        "width": 290.96539306640625,
                        "height": 250.35595703125
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-86",
            "index": 86,
            "timestamp": 17200,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000087.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "238b901b-73ee-4d80-a97c-877b31ef6737",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 78.74015808105469,
                        "y": 409.12457275390625,
                        "width": 283.2360382080078,
                        "height": 236.64453125
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-87",
            "index": 87,
            "timestamp": 17400,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000088.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "7cd6f1c2-ab21-48d9-a449-7a24393a9fa3",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 45.06048583984375,
                        "y": 403.13861083984375,
                        "width": 347.3912658691406,
                        "height": 332.1175537109375
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-88",
            "index": 88,
            "timestamp": 17600,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000089.png",
                "width": 450,
                "height": 800
            },
            "results": [
                {
                    "_type": "SearchResult",
                    "_id": "462aec99-1183-4fd6-96e2-3e193c11e0e4",
                    "score": 0.9150218174264237,
                    "category": {
                        "_type": "Category",
                        "_id": "tees",
                        "name": "tees"
                    },
                    "bounding_box": {
                        "_type": "BoundingBox",
                        "x": 45.06944274902344,
                        "y": 407.4296875,
                        "width": 332.71693420410156,
                        "height": 302.050048828125
                    },
                    "attributes": null,
                    "matches": [
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8aabd8d09a248dc68af86",
                            "schema": "product",
                            "score": 0.8954853916168213,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/91/9a/919ae27964275d82e78edec1b036e04b_best.jpg",
                                    "width": 642,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "652174178_Ocher:652174178:Ocher:https://www.shopstyle.com/g/men/vans/t-shirts",
                                "sku": "652174178",
                                "name": "Vans T-shirts",
                                "url": "https://www.shopstyle.com/g/men/vans/t-shirts",
                                "brand": "Vans",
                                "color": "Ocher",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "VANS T-shirts. jersey, print, logo, cartoon print, round collar, short sleeves, lifestyle, large sized. 100% Cotton",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "XL",
                                "age_group": "adult",
                                "stock": true,
                                "price": 49,
                                "currency": "USD"
                            },
                            "fingerprint": "1fbb6bdffc1880592bf480ed441eabf0",
                            "created_at": "2018-05-01T17:58:21.673Z",
                            "updated_at": "2018-06-25T16:20:07.144Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae7b96aa2773810154ef2f6",
                            "schema": "product",
                            "score": 0.8927665615081787,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/4b/67/4b67e3832859e551bf363687099647c8_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Beach Bound:689786507:Banana - Beach Bound:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Beach Bound",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Large",
                                "age_group": "adult",
                                "stock": true,
                                "price": 28.51,
                                "currency": "USD"
                            },
                            "fingerprint": "6316fe537f2ad8c2f4efaf781d877362",
                            "created_at": "2018-05-01T00:48:42.581Z",
                            "updated_at": "2018-06-23T01:12:06.928Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae9024ca2773810155a45e9",
                            "schema": "product",
                            "score": 0.8891032218933106,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a3/9f/a39f44084cb5945232a1460fd36f4df1_best.jpg",
                                    "width": 463,
                                    "height": 497
                                }
                            ],
                            "data": {
                                "id": "689786507_Banana - Voyager:689786507:Banana - Voyager:https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "sku": "689786507",
                                "name": "Reyn Spooner Men's Graphic T-Shirt",
                                "url": "https://www.shopstyle.com/g/women/reyn-spooner/mens-graphic-t-shirt",
                                "brand": "Reyn Spooner",
                                "color": "Banana - Voyager",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "Regular fit fashion tee made of 100 percent comb ring spun cotton screen printed with Reyn Spooner exclusive design.",
                                "category": "Women > Clothing |  | ",
                                "size": "Small",
                                "age_group": "adult",
                                "stock": true,
                                "price": 22.83,
                                "currency": "USD"
                            },
                            "fingerprint": "f2271721964c7bd1108192548cf8d30e",
                            "created_at": "2018-05-02T00:11:56.581Z",
                            "updated_at": "2018-06-26T23:02:11.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ec216efb8d3d2f83da64",
                            "schema": "product",
                            "score": 0.8870614051818848,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/37/34/373455449b44d5f31a897ae33e876a19_best.jpg",
                                    "width": 720,
                                    "height": 821
                                }
                            ],
                            "data": {
                                "id": "712832653_Yellow & Orange:712832653:Yellow & Orange:https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "sku": "712832653",
                                "name": "MSGM crew neck T-shirt",
                                "url": "https://www.shopstyle.com/g/men/msgm/crew-neck-t-shirt",
                                "brand": "MSGM",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "MSGM create pieces with a sporty aesthetic and a streetwear-inspired edge. With athleisure being a major trend last season and no sign of it dissipating, this SS18 the brand has continued with a blend of luxury fashion with an element of casual leisure-wear in it’s collection. This yellow cotton T-shirt from MSGM features a ribbed crew neck, short sleeves, a straight hem, a relaxed fit, and the words 'will happiness find me?' printed to the chest and back.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 140,
                                "currency": "USD"
                            },
                            "fingerprint": "d324e8447e0ad8e97a35906394ac277d",
                            "created_at": "2018-05-01T22:37:21.614Z",
                            "updated_at": "2018-06-23T00:28:07.088Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8ee5b6efb8d3d2f852889",
                            "schema": "product",
                            "score": 0.8844386768341065,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/a0/4d/a04d4ad0945a8ef9fc7ad61e0d4b62fe_best.jpg",
                                    "width": 692,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "705039891_Yellow & Orange:705039891:Yellow & Orange:https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "sku": "705039891",
                                "name": "McQ embroidered patch T-shirt",
                                "url": "https://www.shopstyle.com/g/women/mcq/embroidered-patch-t-shirt",
                                "brand": "McQ",
                                "color": "Yellow & Orange",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "British designer is known for his groundbreaking clothes, shocking catwalk shows and precise tailoring. He is the one who pushed fashion limit into the extreme of drama. Integral to McQueen culture is the juxtaposition between contrasting elements: fragility and strengths, tradition and modernity. This peach cotton embroidered patch T-shirt from McQ Alexander Mcqueen featuring elongated short sleeves.",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 154,
                                "currency": "USD"
                            },
                            "fingerprint": "411ce7a107466ca4c455ee37e910cfc8",
                            "created_at": "2018-05-01T22:46:51.440Z",
                            "updated_at": "2018-06-26T20:44:06.808Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90945a2773810155e66a3",
                            "schema": "product",
                            "score": 0.8841763782501221,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/b9/a5/b9a5960631cc8eb3ff76eefb5ba97b8c_best.jpg",
                                    "width": 400,
                                    "height": 370
                                }
                            ],
                            "data": {
                                "id": "721441642_Cress Yellow:721441642:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "sku": "721441642",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/721441642",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e4f4d6eb84e5852fb0690ccf2d0a99de",
                            "created_at": "2018-05-02T00:41:41.508Z",
                            "updated_at": "2018-06-22T23:48:08.295Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8b3deb77b36100f12d2a7",
                            "schema": "product",
                            "score": 0.882965202331543,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/9c/6e/9c6ee57e11f9ce408236fe1ce69b58c9_best.jpg",
                                    "width": 720,
                                    "height": 804
                                }
                            ],
                            "data": {
                                "id": "715709143_Yellow:715709143:Yellow:https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "sku": "715709143",
                                "name": "Nicce London TALL T-Shirt In Yellow With Split Logo Exclusive To ASOS",
                                "url": "https://www.shopstyle.com/p/nicce-london-tall-t-shirt-in-yellow-with-split-logo-exclusive-to-asos/715709143",
                                "brand": "Nicce London",
                                "color": "Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Tall T -shirt by Nicce London, Crew neck, It's classic you, Nicce London chest logo, Regular cut, Fits you just right, Exclusive to ASOS. Clean, contemporary design is at the forefront of Nicce London's vision. Founded in 2013 and inspired by London's ever-changing music scene, the British label applies its contemporary cuts to a functional line of streetwear. Branded sweats and jackets sit alongside a range of printed T-shirts, with some styles exclusive to ASOS.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "S",
                                "age_group": "adult",
                                "stock": true,
                                "price": 39,
                                "currency": "USD"
                            },
                            "fingerprint": "226fdf0541f8f332f93aa47c7172d5ed",
                            "created_at": "2018-05-01T18:37:18.013Z",
                            "updated_at": "2018-06-23T00:02:07.773Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8cb6a1393dd3d290ed1f9",
                            "schema": "product",
                            "score": 0.8828424549102784,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/92/7d/927d2a86d3c84a96a4ff4102208960c3_best.jpg",
                                    "width": 508,
                                    "height": 900
                                }
                            ],
                            "data": {
                                "id": "712703758_Canary:712703758:Canary:https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "sku": "712703758",
                                "name": "Mother The Boxy Goodie Goodie Tee",
                                "url": "https://www.shopstyle.com/g/women/mother/the-boxy-goodie-goodie-tee",
                                "brand": "Mother",
                                "color": "Canary",
                                "gender": "women",
                                "vendor": "shopstyle",
                                "description": "MOTHER's classic Boxy Goodie Goodie tee has the retro feel we love so much, with faded stripes and lettering. Throw on a track jacket and high-waisted jeans for a total look. Fabric: cotton Jersey Faded 'Tough Cookie 25' lettering Graphic print Waist-length style Scoop neck Short sleeves Shell: 100% cotton Wash cold Made in the USA of imported materials Style # MOTHR20705 Measurements from size s Length: 22.75in / 58cm, from shoulder",
                                "category": "Women > Clothing > Tops > Tees |  | ",
                                "size": "XS",
                                "age_group": "adult",
                                "stock": true,
                                "price": 105,
                                "currency": "USD"
                            },
                            "fingerprint": "dc19f33c8b2647a78d8b5617c1b1b0f0",
                            "created_at": "2018-05-01T20:17:46.496Z",
                            "updated_at": "2018-06-25T17:32:18.315Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae8d4d31393dd3d29140ee7",
                            "schema": "product",
                            "score": 0.8814574146270752,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/50/97/50978d49901466f04eaba5ea395965cc_best.jpg",
                                    "width": 720,
                                    "height": 642
                                }
                            ],
                            "data": {
                                "id": "661287554_Cress Yellow:661287554:Cress Yellow:https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "sku": "661287554",
                                "name": "United by Blue Adventure Mobile T-Shirt - Men's",
                                "url": "https://www.shopstyle.com/p/united-by-blue-adventure-mobile-t-shirt-mens/661287554",
                                "brand": "United by Blue",
                                "color": "Cress Yellow",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Pack up the truck and hit the dusty trail into the mountains with the United by Blue Men's Adventure Mobile T-Shirt. It's constructed with buttery-soft organic cotton for blissful comfort when you're traversing rugged ranges and crossing dusty deserts. Explore inhospitable terrain in blissful comfort Organic cotton provides a buttery-smooth feel Material organic cotton Fit regular Style crew Recommended Use casual Manufacturer Warranty 60 days",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "M",
                                "age_group": "adult",
                                "stock": true,
                                "price": 35.95,
                                "currency": "USD"
                            },
                            "fingerprint": "e578ecceb6eaf9b7f8ef8e8483c216c7",
                            "created_at": "2018-05-01T20:57:55.728Z",
                            "updated_at": "2018-06-22T23:35:07.786Z"
                        },
                        {
                            "_type": "CatalogItem",
                            "_id": "5ae90340a2773810155ad91f",
                            "schema": "product",
                            "score": 0.8795506095886231,
                            "catalog": {
                                "_type": "Catalog",
                                "_id": "5ae7af541393dd3d2907816d",
                                "name": "catalog-shopstyle"
                            },
                            "images": [
                                {
                                    "_type": "Image",
                                    "uri": "https://img.shopstyle-cdn.com/pim/23/53/2353fa1b6e85669fe45e8a692f58606e_best.jpg",
                                    "width": 720,
                                    "height": 857
                                }
                            ],
                            "data": {
                                "id": "720869281_Yellow & Orange:720869281:Yellow & Orange:https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "sku": "720869281",
                                "name": "Undercover logo detail T-shirt",
                                "url": "https://www.shopstyle.com/g/men/undercover/logo-detail-t-shirt",
                                "brand": "Undercover",
                                "color": "Yellow & Orange",
                                "gender": "men",
                                "vendor": "shopstyle",
                                "description": "Mustard yellow cotton logo detail T-shirt from Undercover.",
                                "category": "Men > Clothing > Shirts > Tees |  | ",
                                "size": "2",
                                "age_group": "adult",
                                "stock": true,
                                "price": 190,
                                "currency": "USD"
                            },
                            "fingerprint": "ff8ba710212c6e74bc33634ea599159b",
                            "created_at": "2018-05-02T00:16:00.356Z",
                            "updated_at": "2018-06-23T00:09:07.487Z"
                        }
                    ]
                }
            ]
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-89",
            "index": 89,
            "timestamp": 17800,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000090.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-90",
            "index": 90,
            "timestamp": 18000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000091.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-91",
            "index": 91,
            "timestamp": 18200,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000092.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-92",
            "index": 92,
            "timestamp": 18400,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000093.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-93",
            "index": 93,
            "timestamp": 18600,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000094.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-94",
            "index": 94,
            "timestamp": 18800,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000095.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-95",
            "index": 95,
            "timestamp": 19000,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000096.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-96",
            "index": 96,
            "timestamp": 19200,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000097.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-97",
            "index": 97,
            "timestamp": 19400,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000098.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-98",
            "index": 98,
            "timestamp": 19600,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000099.png",
                "width": 450,
                "height": 800
            },
            "results": []
        },
        {
            "_type": "Frame",
            "_id": "frame-42b3de649f8204ddb8036b179eb9e512-99",
            "index": 99,
            "timestamp": 19800,
            "image": {
                "_type": "Image",
                "uri": "//localhost:1331/video/cache/uri-youtube-Ed6L5GugkcQ/c-baa8b737b9fd5a82da8bb585eea279b7-fps-5-tdt-20/process/snapshot/fps5-000100.png",
                "width": 450,
                "height": 800
            },
            "results": []
        }
    ]
}
