
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { PureComponent } from 'react'
// import classnames from 'classnames'

import Video from './DefaultVideo'

import VimeoPlayer from '@vimeo/player'


/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/DemoPlayer/Video/VimeoPlayer')


/* =========================================
      COMPONENTS
-------------------------------------- */

// API: https://github.com/vimeo/player.js

class VimeoVideo extends PureComponent {

    state = {}

    install = () => {
        // return Video.installScript('Vimeo', 'https://player.vimeo.com/api/player.js')

        // NOTE: using Webpack/NPM import

        return Promise.resolve()
    }

    uninstall = () => {
        return new Promise((resolve, reject) => {
            try {
                if (this.api) {
                    // TODO: cleanup
                }

                resolve()

            } catch (err) {
                reject(err)
            }
        })
    }

    setup = () => {
        return new Promise((resolve, reject) => {
            Promise.all([
                Video.waitForProperty(window, 'Vimeo'),
                Video.waitForProperty(this, 'player'),
                // Video.waitForProperty(this, 'video')
            ]).then(() => {
                if (!this.player) return reject(new Error(`Player element is undefined`))
                // if (!this.video) return reject(new Error(`Video element is undefined`))

                // const { Vimeo } = window

                // const _vimeo = new Vimeo.Player(this.state.id, {
                const _vimeo = new VimeoPlayer(this.state.id, {
                    id: this.state.videoId,
                    autoplay: this.props.autoplay,
                    muted: this.props.muted,
                    controls: this.props.controls,
                    loop: this.props.loop,
                    width: this.props.width || this.state.width || 'auto',
                    height: this.props.height || this.state.height || 'auto',
                })

                _vimeo.on('error', (err) => {
                    this.event('onError')(err)
                })

                // playback
                _vimeo.on('play', () => {
                    this.event('onPlay')()
                })
                _vimeo.on('pause', () => {
                    this.event('onPause')()
                })
                _vimeo.on('progress', () => {
                    this.event('onProgress')()
                })
                _vimeo.on('timeupdate', () => {
                    this.currentTime = _vimeo.getCurrentTime()

                    this.event('onTimeUpdate')(this.currentTime)
                })
                // ~dataloaded
                _vimeo.on('loaded', () => {
                    this.event('onLoad')()
                    this.event('onDataLoaded')()
                })
                _vimeo.on('ended', () => {
                    this.event('onEnded')()
                })

                // TODO: fullscreen event
                // TODO: resize event

                this.api = _vimeo
                this.vimeoPlayer = _vimeo

                resolve(this.api)
            })
        })
    }

    get playing () {
        return this.state.play || this.props.autoplay
    }

    play () {
        if (this.vimeoPlayer) {
            this.setState({
                play: true,
                pause: false,
                stop: false,
            }, () => {
                try {
                    this.vimeoPlayer.api('play')
                } catch (err) {}
            })
        }
    }

    pause () {
        if (this.vimeoPlayer) {
            this.setState({
                play: false,
                pause: true,
                stop: false,
            }, () => {
                try {
                    this.vimeoPlayer.api('pause')
                } catch (err) {}
            })
        }
    }

    stop () {
        if (this.vimeoPlayer) {
            this.setState({
                play: false,
                pause: false,
                stop: true,
            }, () => {
                try {
                    this.vimeoPlayer.api('stop')
                } catch (err) {}
            })
        }
    }

    mute () {
        if (this.vimeoPlayer) {
            this.setState({
                mute: true,
            }, () => {
                try {
                    this.vimeoPlayer.api('setVolume', 0)
                } catch (err) {}
            })
        }
    }

    unmute () {
        if (this.vimeoPlayer) {
            this.setState({
                mute: false,
            }, () => {
                try {
                    this.vimeoPlayer.api('setVolume', 1)
                } catch (err) {}
            })
        }
    }

    seek (seconds = 0) {
        if (this.youtubePlayer) {
            this.setState({
                // seek: seconds,
            }, () => {
                // not possible
            })
        }
    }

    event = (name) => {
        const props = this.props

        return (...args) => {
            log('event', name, ...args)

            if (props[name]) {
                props[name](...args)
            }
        }
    }

    componentDidMount() {
        VimeoVideo.index = VimeoVideo.index || 0
        VimeoVideo.index++

        this.setState({
            index: VimeoVideo.index,
            id: `vimeo-video-${VimeoVideo.index}`,
            videoId: `${this.props.src}`.match(/\/([_\d\w-]+)/mi)[1],
        })

        this.install()
            .then(() => {
                this.setup()
            })
            .catch((error) => {
                log('install', error)
            })
    }

    componentWillUnmount() {
        this.uninstall()
            .then(() => {
                // noop
            })
            .catch((error) => {
                log('uninstall', error)
            })
    }

    render() {
        return (
            <div {...{
                ref: (ref) => this.player = ref,
                id: this.state.id,
                className: this.props.className,
                style: this.props.style,
            }} />
        )
    }
}

VimeoVideo.propTypes = {
    ...Video.propTypes
}

VimeoVideo.defaultProps = {
    ...Video.defaultProps
}


/* =========================================
      EXPORTS
-------------------------------------- */

export default VimeoVideo
