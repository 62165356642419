
/* =========================================
      IMPORTS
-------------------------------------- */

import debug from 'debug'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

// import {
//     FaPlay as PlayIcon,

//     FaPause as PauseIcon,

//     FaStop as StopIcon,

//     FaFastBackward as NextFrameIcon,
//     FaFastForward as PreviousFrameIcon,

//     FaInfinity as LoopEnabledIcon,
//     FaCircleNotch as LoopDisabledIcon,

//     FaVolumeOff as AudioEnabledIcon,
//     FaVolumeUp as AudioDisabledIcon,

//     FaDesktop as FullscreenEnabledIcon,
//     FaWindowClose as FullscreenDisabledIcon,
//     // FaExpand as FullscreenEnabledIcon,
//     // FaCompress as FullscreenDisabledIcon,

//     // FaEye as OverlayDetectionsEnabledIcon,
//     // FaEyeSlash as OverlayDetectionsDisabledIcon,

//     // FaImage as OverlaySnapshotEnabledIcon,

//     // FaBarcode as FrameTickerEnabledIcon,

//     // FaListOl as OverlayCategoriesEnabledIcon,
// } from 'react-icons/fa'


import {
    IoMdPlay as PlayIcon, // IoIosPlay

    IoMdPause as PauseIcon, // IoIosPause

    // IoMdSquare as StopIcon, // IoIosSquare

    // IoMdSkipBackward as NextFrameIcon, // IoIosSkipBackward
    // IoMdSkipForward as PreviousFrameIcon, // IoIosSkipForward

    // IoMdInfinite as LoopEnabledIcon, // IoIosInfinite
    // IoMdArrowRoundForward as LoopDisabledIcon, // IoIosArrowRoundForward

    // IoMdVolumeOff as AudioEnabledIcon, // IoIosVolumeOff
    // IoMdVolumeHigh as AudioDisabledIcon, // IoIosVolumeHigh

    // IoMdDesktop as FullscreenEnabledIcon, // IoIosDesktop
    // IoMdClose as FullscreenDisabledIcon, // IoIosClose
    // IoMdExpand as FullscreenEnabledIcon, // IoIosExpand
    // IoMdContract as FullscreenDisabledIcon, // IoIosContract

    // IoMdEye as OverlayDetectionsEnabledIcon, // IoIosEye
    // IoMdEyeOff as OverlayDetectionsDisabledIcon, // IoIosEyeOff

    // IoMdImage as OverlaySnapshotEnabledIcon, // IoIosImage

    // IoMdBarcode as FrameTickerEnabledIcon, // IoIosBarcode

    // IoMdList as OverlayCategoriesEnabledIcon, // IoIosList
} from 'react-icons/io'



/* =========================================
      LOGGER
-------------------------------------- */

const log = debug('src/ui/components/DemoPlayer/LensVideoControls')


/* =========================================
      COMPONENTS
-------------------------------------- */

class LensVideoControls extends Component {

    // NOTE: `shallowEqual` don't work here, cannot get video state to sync fully
    // shouldComponentUpdate (nextProps) {
    //     const { props } = this

    //     const video = props.video || {}
    //     const nextVideo = nextProps.video || {}

    //     const videoStateChanged = (
    //         video.paused === nextVideo.paused &&
    //         video.playing === nextVideo.playing &&
    //         video.stopped === nextVideo.stopped
    //     )

    //     if (shallowEqual(props, nextProps) && videoStateChanged) {
    //         return false
    //     }

    //     return true
    // }

    render () {
        const { props } = this

        const video = props.video || {}

        const hidden = !!props.hidden
        const disabled = !!props.disabled

        // REVIEW
        const playEnabled = !video.playing || video.stopped
        const playDisabled = !playEnabled

        // REVIEW
        const pauseEnabled = video.playing
        const pauseDisabled = !pauseEnabled

        // REVIEW
        const stopEnabled = !!video.stopped
        // const stopDisabled = !stopEnabled

        // const audioDisabled = !!video.muted
        // const audioEnabled = !audioDisabled

        // const loopDisabled = !!video.loop
        // const loopEnabled = !loopDisabled

        // const fullscreenDisabled = !!video.fullscreen
        // const fullscreenEnabled = !fullscreenDisabled

        // const overlayDisabled = !!video.overlay
        // const overlayEnabled = !overlayDisabled

        // const autoplayDisabled = !!props.autplay
        // const autoplayEnabled = !autoplayDisabled

        return (
            <div className={classnames('LensVideoControls', {hidden, disabled})}>

                <div className={classnames('LensVideoControlGroup')}>
                    <button className={classnames('LensVideoControl', 'LensVideoControl-play')}
                        title={`Play`}
                        disabled={playDisabled}
                        hidden={playDisabled}

                        data-type={'play'}
                        data-value={false}

                        onClick={() => {
                            try {
                                video.play()

                            } catch (error) {
                                console.error(error)
                            }
                        }}
                    >
                        <PlayIcon
                            className={classnames({
                                hidden: false,
                            })}
                        />
                    </button>

                    <button className={classnames('LensVideoControl', 'LensVideoControl-pause')}
                        title={`Pause`}
                        disabled={pauseDisabled}
                        hidden={pauseDisabled}

                        data-type={'pause'}
                        data-value={false}

                        onClick={() => {
                            try {
                                video.pause()

                            } catch (error) {}
                        }}
                    >
                        <PauseIcon
                            className={classnames({
                                hidden: false,
                            })}
                        />
                    </button>

                    {/*
                        <button className={classnames('LensVideoControl', 'LensVideoControl-stop')}
                            title={`Stop`}
                            disabled={true}
                            hidden={true}

                            data-type={'stop'}
                            data-value={false}

                            onClick={() => {
                                try {
                                    video.stop()

                                } catch (error) {}
                            }}
                        >
                            <StopIcon
                                className={classnames({
                                    hidden: stopEnabled,
                                })}
                            />
                        </button>
                    */}

                </div>

                <div className={classnames('LensVideoControlGroup', {hidden: true})}>

                    {/*
                        <button className={classnames('LensVideoControl', 'LensVideoControl-mute')}
                            title={`Mute`}
                            disabled={true}
                            hidden={true}

                            data-type={'mute'}
                            data-value={true}

                            onClick={() => {
                                try {
                                    video.mute()
                                    // video.mute(!video.muted)

                                } catch (error) {}
                            }}
                        >
                            <AudioEnabledIcon
                                className={classnames({
                                    hidden: audioDisabled,
                                })}
                            />
                            <AudioDisabledIcon
                                 className={classnames({
                                    hidden: audioEnabled,
                                })}
                            />
                        </button>
                    */}

                    {/*
                        <button className={classnames('LensVideoControl', 'LensVideoControl-loop')}
                            title={`Loop`}
                            disabled={true}
                            hidden={true}

                            data-type={'loop'}
                            data-value={false}

                            onClick={() => {
                                try {
                                    video.loop(!video.loop)

                                } catch (error) {}
                            }}
                        >
                            <LoopEnabledIcon
                                className={classnames({
                                    hidden: loopDisabled,
                                })}
                            />
                            <LoopDisabledIcon
                                className={classnames({
                                    hidden: loopEnabled,
                                })}
                            />
                        </button>
                    */}

                    {/*
                        <button className={classnames('LensVideoControl', 'LensVideoControl-fullscreen')}
                            title={`Fullscreen`}
                            disabled={true}
                            hidden={true}

                            data-type={'fullscreen'}
                            data-value={false}

                            onClick={() => {
                                try {
                                    video.fullscreen()
                                    // video.fullscreen(!video.fullscreen)

                                } catch (error) {}
                            }}
                        >
                            <FullscreenEnabledIcon
                                className={classnames({
                                    hidden: fullscreenDisabled,
                                })}
                            />
                            <FullscreenDisabledIcon
                                className={classnames({
                                    hidden: fullscreenEnabled,
                                })}
                            />
                        </button>
                    */}

                </div>

            </div>
        )
    }

}

LensVideoControls.propTypes = {
    video: PropTypes.object,

    hidden: PropTypes.bool,
    disabled: PropTypes.bool,
}

LensVideoControls.defaultProps = {
    video: undefined,

    hidden: false,
    disabled: false,
}


/* =========================================
      EXPORT
-------------------------------------- */

export default LensVideoControls
